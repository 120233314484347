import { EmitterType, Vessel } from 'Models';
import { SensorGroup, SensorGroupMetadataVessel } from '../interfaces';
import { transformIntoAis } from './ais.transformer';
import { transformIntoSensors } from './sensor.transformer';
import { expandLayout } from './layout.transformer';
import { resolveEmitterRotation } from 'Services/emitters/helpers';
import { getSensorsWithName } from 'Helpers/sensors';
import { SensorName } from 'Constants';

export const transformIntoVessel = (record: SensorGroup<SensorGroupMetadataVessel>): Vessel => {
  const sensors = record.sensorSchema ? transformIntoSensors(record.sensorSchema, record._id) : [];
  const ais = record.metadata.ais && transformIntoAis(record.metadata.ais);

  const vessel = {
    id: record._id,
    type: record.groupType as EmitterType,
    coordinate: record.position && {
      latitude: record.position.coordinates[1],
      longitude: record.position.coordinates[0],
    },
    name: record.name,
    namespace: record.ns,
    sensors,
    lastCog: record.metadata.lastCog,
    lastSog: record.metadata.lastSog,
    systemTime: record.metadata.systemTime ?? undefined,
    phone: record.metadata.phone,
    mmsi: record.metadata.mmsi ? Number(record.metadata.mmsi) : undefined,
    ais: record.metadata.ais && transformIntoAis(record.metadata.ais),
    inProgress: !!record.inProgress,
    isManager: !!record.isManager,
    isReadAll: !!record.isReadAll,
    layout: expandLayout(record.layout, sensors),
    email: record.metadata.email,
    captain: record.metadata.captain,
    rotation: resolveEmitterRotation({
      sog: record.metadata.lastSog,
      cog: record.metadata.lastCog,
      trueHeading: ais?.trueHeading,
    }),
    hardware: (record as any).hardware as Vessel['hardware'],
  } as Vessel;

  const hasGpsSensor = getSensorsWithName(SensorName.GPS, vessel).length > 0;

  vessel.usesUnifiedSensor = !hasGpsSensor;

  return vessel;
};
