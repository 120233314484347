import { AbsoluteInterval, IPropertyName } from 'Constants';
import { Property, Sensor } from 'Models';
import { Aggregation, PropertyValue, Sample, SampleUnit, SensorRecords } from './interfaces';
import {
  generateProperyByName,
  getFirstPropertyWithName,
  getPropertiesWithNames,
  haveSameName,
} from 'Helpers/sensors';
import { getAccessTokenSilently } from 'Services/auth0';
import axios from 'axios';
import { urlConfiguration } from 'Config';
import { generateSensorPayloads } from './helpers';
import { OptionalTuple } from 'Types/utility';

export interface FetchMultiSampleSeriesArgument {
  propertyNames: IPropertyName[];
  sensors: Sensor[];
  interval: AbsoluteInterval;
  aggregation?: Aggregation;
  sample?: {
    unit: SampleUnit;
    rate: number;
  };
  densify?: boolean;
}

export interface FetchMultiSampleSeriesOptions {
  includeIsSatcomm: boolean;
  includeErrors: boolean;
}

const DEFAULT_OPTIONS: FetchMultiSampleSeriesOptions = {
  includeIsSatcomm: true,
  includeErrors: false,
};

async function fetchMultiSampleSeries<Values extends Array<PropertyValue>>(
  arg: FetchMultiSampleSeriesArgument,
  options: FetchMultiSampleSeriesOptions = DEFAULT_OPTIONS
) {
  const { sensors, propertyNames, interval, aggregation, sample, densify } = arg;

  console.assert(haveSameName(sensors), 'sensors must have same name');

  const appendedMultiProperties = sensors.map((sensor) => {
    const properties = propertyNames.map((propertyName) =>
      getFirstPropertyWithName(propertyName, sensor)
    );

    if (properties.length > 0) {
      const siblingProperty = properties.find((property) => !!property) as Property;
      const systemTimeProperty = generateProperyByName(siblingProperty, 'systemTime');

      properties.push(systemTimeProperty);

      if (options.includeIsSatcomm) {
        const isSatcommProperty = generateProperyByName(siblingProperty, 'metadata.isSatcomm');
        properties.push(isSatcommProperty);
      }

      if (options.includeErrors) {
        const errorCodesProperty = generateProperyByName(siblingProperty, 'errorCodes');
        properties.push(errorCodesProperty);
      }
    }

    return properties;
  });

  const requestedProperties = appendedMultiProperties.reduce<Property[]>(
    (acc, properties) => acc.concat(properties.filter((p): p is Property => !!p)),
    []
  );

  if (!requestedProperties.length) {
    return null;
  }

  const token = await getAccessTokenSilently();

  const { data: sensorsRecords } = await axios.post<SensorRecords[]>(
    `${urlConfiguration.api}/sensors`,
    generateRequestBody({
      properties: requestedProperties,
      interval,
      aggregation,
      sample,
      densify,
    }),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const multiSamples = sensors.map((sensor, index) => {
    const sensorRecordsForSensor = sensorsRecords.find(
      (sensorRecords) => sensorRecords.sensorId === sensor.id
    );

    if (!sensorRecordsForSensor) {
      return [];
    }

    const appendedProperties = appendedMultiProperties[index];

    const systemTimeIndex = propertyNames.length;

    return sensorRecordsForSensor.records
      .map((record) => {
        const values = appendedProperties.map((p) => p && record[p.name.hash]);
        return [...values] as Sample<OptionalTuple<Values>>;
      })
      .sort((a, b) => {
        const firstSystemTime = a[systemTimeIndex] as string;
        const secondSystemTime = b[systemTimeIndex] as string;

        if (firstSystemTime > secondSystemTime) {
          return -1;
        } else if (firstSystemTime < secondSystemTime) {
          return 1;
        } else {
          return 0; // Return 0 to leave the order unchanged
        }
      });
  });

  return multiSamples;
}

fetchMultiSampleSeries.generateQueryKey = ({
  propertyNames,
  sensors,
  interval,
  aggregation,
  sample,
  densify,
}: FetchMultiSampleSeriesArgument) => {
  const properties = getPropertiesWithNames(propertyNames, sensors);

  const propertiesHash = properties
    .map((p) => p.hash)
    .sort()
    .join();

  return [
    'multiSamples',
    propertiesHash,
    { earliest: interval.earliest, latest: interval.latest, aggregation, sample, densify },
  ];
};

function generateRequestBody({
  properties,
  interval,
  aggregation,
  sample,
  densify,
}: {
  properties: Property[];
  interval: AbsoluteInterval;
  aggregation?: Aggregation;
  sample?: {
    unit: SampleUnit;
    rate: number;
  };
  densify?: boolean;
}) {
  const body: Record<string, any> = {
    earliest: interval.earliest,
    latest: interval.latest,
    sensors: generateSensorPayloads(properties),
  };

  if (aggregation) {
    body.aggregation = aggregation;
  }

  if (sample) {
    body.sampleUnit = sample.unit;
    body.sampleRate = sample.rate;
  }

  if (densify !== undefined) {
    body.densify = densify;
  }

  return body;
}

export { fetchMultiSampleSeries };
