import { EmitterDetailsBarSectionHeaderRegular } from '../EmitterDetailsBarSectionHeaderRegular/EmitterDetailsBarSectionHeaderRegular';
import { EmitterDetailsBarSection } from '../EmitterDetailsBarSection';
import { ComprehensiveCellExpanded, ComprehensiveCellType, FormattingConfig } from 'Models';
import {
  EmitterDetailsBarCellSet,
  EmitterDetailsBarFuelCell,
  EmitterDetailsBarKeyValueCell,
  EmitterDetailsBarOnOffCell,
} from '../EmitterDetailsBarCellSet';

type CellsSectionProps = {
  title: string;
  cells: ComprehensiveCellExpanded[];
  formatting: FormattingConfig;
  containedWithinSection?: boolean;
};

function getCellComponent(
  cell: ComprehensiveCellExpanded,
  formatting: FormattingConfig,
  index: number
) {
  if (cell.type === ComprehensiveCellType.KeyValue) {
    const formatter = formatting[cell.property.sensorId]?.[cell.property.name.name];
    return (
      <EmitterDetailsBarKeyValueCell
        key={index}
        title={cell.title || cell.property.displayName}
        property={cell.property}
        formatter={formatter}
      />
    );
  }

  if (cell.type === ComprehensiveCellType.OnOff) {
    return (
      <EmitterDetailsBarOnOffCell
        key={index}
        title={cell.title || cell.property.displayName}
        property={cell.property}
        onValue={cell.onValue}
        offValue={cell.offValue}
      />
    );
  }

  if (cell.type === ComprehensiveCellType.FuelType) {
    return <EmitterDetailsBarFuelCell key={index} title={cell.title} fuelProperties={cell.fuels} />;
  }

  return null;
}

export const CellsSection: React.FC<CellsSectionProps> = ({
  title,
  cells,
  formatting,
  containedWithinSection = false,
}) => {
  return containedWithinSection ? (
    <EmitterDetailsBarCellSet>
      {cells.map((cell, i) => getCellComponent(cell, formatting, i))}
    </EmitterDetailsBarCellSet>
  ) : (
    <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title={title} />}>
      <EmitterDetailsBarCellSet>
        {cells.map((cell, i) => getCellComponent(cell, formatting, i))}
      </EmitterDetailsBarCellSet>
    </EmitterDetailsBarSection>
  );
};
