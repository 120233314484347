import { useEffect, useMemo } from 'react';
import { useSpatioTemporalCache } from '../../../../hooks';
import { getFirstPropertyWithName, getFirstSensorWithName } from 'Helpers/sensors';
import { SensorName } from 'Constants';
import { Vessel } from 'Models';
import { MapRef } from 'react-map-gl';
import { FeatureCollection, Feature, LineString, Position } from 'geojson';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import { calculatePositionDate } from 'Helpers/timeline';

export const useGetPositionHistory = (vessel: Vessel, mapRef: MapRef) => {
  const controlBarManager = useControlBarManager();

  const { absoluteInterval, refresh } = useSelectedInterval();

  const { thumbPosition } = useTimelineThumb();

  const thumbPositionDate = useMemo(
    () => calculatePositionDate(thumbPosition, controlBarManager.getTimelineBarInterval()),
    [controlBarManager, thumbPosition]
  );

  const adjustedThumbPositionDate = new Date(
    new Date(thumbPositionDate).getTime() + controlBarManager.getTimelineBarSampleRateMs()
  ).toISOString();

  const viewMode = useViewMode();

  const layerInterval =
    viewMode === 'live'
      ? absoluteInterval
      : {
          earliest: absoluteInterval.earliest,
          latest: adjustedThumbPositionDate,
        };

  useEffect(refresh, [refresh, vessel.id]);

  useEffect(() => {
    // Wrapping it is necessary to prevent global conflict
    const listener = () => {
      refresh();
    };

    window.addEventListener('focus', listener);
    window.addEventListener('online', listener);

    return () => {
      window.removeEventListener('focus', listener);
      window.removeEventListener('online', listener);
    };
  }, [refresh]);

  const requestParams = useMemo(() => {
    const gps = getFirstSensorWithName(SensorName.GPS, vessel);
    if (!gps) {
      return;
    }

    const latitude = getFirstPropertyWithName(SensorName.GPS.PropertyName.Latitude, gps);
    const longitude = getFirstPropertyWithName(SensorName.GPS.PropertyName.Longitude, gps);
    const systemTime = getFirstPropertyWithName(SensorName.GPS.PropertyName.SystemTime, gps);

    if (latitude && longitude && systemTime) {
      return {
        latitude,
        longitude,
        systemTime,
      };
    }
  }, [vessel]);

  if (!requestParams) {
    console.assert('gps sensor is missing or missing required properties');
  }

  const { isFetching, entries, largestSampleRateMs } = useSpatioTemporalCache({
    interval: layerInterval,
    mapRef,
    latitudeProperty: requestParams?.latitude,
    longitudeProperty: requestParams?.longitude,
    systemTimeProperty: requestParams?.systemTime,
  });

  const entriesAsGeoJson = useMemo<FeatureCollection<LineString>>(() => {
    const features: Feature<LineString>[] = [];
    let lastLng: number | null = null;
    let lastLat: number | null = null;
    let lastSystemTimeMs: number | null = null;
    let coordinates: Position[] = [];

    for (const entry of entries) {
      if (lastSystemTimeMs === null) {
        lastSystemTimeMs = new Date(entry.timestamp).getTime();
      }

      if (lastLng === null || lastLat == null) {
        lastLng = entry.longitude;
        lastLat = entry.latitude;
        coordinates.push([entry.longitude, entry.latitude]);
        continue;
      }

      const startLng = entry.longitude;

      // When data crosses the anti meridian we need to apply a transformation to ensure
      // it doesnt wrap across the entire world e.g 179, -179.
      if (lastLng - startLng >= 180) {
        coordinates.push([entry.longitude + 360, entry.latitude]);
        lastLng = entry.longitude + 360;
      } else if (lastLng - startLng < -180) {
        coordinates.push([entry.longitude - 360, entry.latitude]);
        lastLng = entry.longitude - 360;
      } else {
        coordinates.push([entry.longitude, entry.latitude]);
        lastLng = entry.longitude;
      }
      lastLat = entry.latitude;
      //If there is a timegap (6 consecutive data points missing in the timeseries) in between points, do not connect them in
      //a single line. This is accomplished by creating two distinct linestring features.
      if (new Date(entry.timestamp).getTime() - lastSystemTimeMs > largestSampleRateMs * 6) {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates,
          },
          properties: {},
        });
        lastSystemTimeMs = null;
        lastLng = null;
        coordinates = [];
      }
      lastSystemTimeMs = new Date(entry.timestamp).getTime();
    }

    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: {},
    });

    return {
      type: 'FeatureCollection',
      features: features,
    };
  }, [entries, largestSampleRateMs]);

  return {
    isFetching,
    entriesAsGeoJson,
  };
};
