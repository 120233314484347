const SNAPS = [
  1 * 60 * 1000,
  5 * 60 * 1000,
  10 * 60 * 1000,
  30 * 60 * 1000,
  60 * 60 * 1000,
  24 * 60 * 60 * 1000,
];

export function generateIntermediaryTickTimestamps({
  earliest,
  latest,
  spacingScale,
}: {
  earliest: number;
  latest: number;
  spacingScale: number;
}) {
  const spacing = (latest - earliest) * spacingScale;

  const highestSnap =
    SNAPS.slice()
      .reverse()
      .find((snap) => spacing >= snap) || 1;

  const snappedSpacing = Math.floor(spacing / highestSnap) * highestSnap;

  const timestamps = [];

  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

  // We need to apply timezone offset only if highest snap is more than a day
  let timestamp =
    Math.floor(latest / highestSnap) * highestSnap +
    (highestSnap >= 24 * 60 * 60 * 1000 ? timezoneOffset : 0);

  while (timestamp > earliest) {
    timestamps.push(timestamp);
    timestamp -= snappedSpacing;
  }

  timestamps.reverse();

  return timestamps;
}
