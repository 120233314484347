import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ReportingState = {
  isLoading: boolean;
};

const defaultState: ReportingState = {
  isLoading: false,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: defaultState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<ReportingState['isLoading']>) => {
      state.isLoading = action.payload;
    },
  },
});

const { reducer, actions } = reportsSlice;

export const { setIsLoading } = actions;
export const reportsReducer = reducer;
