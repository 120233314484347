import styles from '../../../../SideBar.module.scss';
import { FC, PropsWithChildren } from 'react';
import { ReactComponent as IconEye } from './resources/graphics/icon-eye.svg';
import { ReactComponent as IconEyeCrossed } from './resources/graphics/icon-eye-crossed.svg';

type FleetNavigatorSectionProps = {
  title: string;
  visibilityIndicatorType: 'visible' | 'hidden';
  onClickVisibilityIndicator: () => void;
};

export const FleetNavigatorSection: FC<PropsWithChildren<FleetNavigatorSectionProps>> = ({
  title,
  visibilityIndicatorType,
  onClickVisibilityIndicator,
  children,
}) => {
  return (
    <div className={styles.fleetNavigatorSection}>
      <div className={styles.fleetNavigatorHeader}>
        <div className={styles.fleetNavigatorHeaderContent}>
          {title}
          <button onClick={onClickVisibilityIndicator}>
            {visibilityIndicatorType === 'visible' ? <IconEye /> : <IconEyeCrossed />}
          </button>
        </div>
        <hr />
      </div>
      <div className={styles.fleetNavigator}>{children}</div>
    </div>
  );
};
