import { useMemo, forwardRef, useCallback, useImperativeHandle } from 'react';
import { PdfExportRef } from '../../../../interfaces';
import { ColoredEmitter } from '../../interfaces';
import { Header, Summary, Breakdown } from './components';
import { EntriesByEmitter } from '../../hooks/useGetEntriesByEmitter';
import { StaticInterval } from 'Constants';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { pdf } from '@react-pdf/renderer';
import FileSaver from 'file-saver';
import { Conversion } from 'Interfaces';

type PdfReportProps = {
  emissionName: string;
  coloredEmitters: ColoredEmitter[];
  entriesByEmitter: EntriesByEmitter;
  interval: StaticInterval;
  conversion?: Conversion;
};

const styles = StyleSheet.create({
  a4: {
    width: '595px',
    padding: '20px',
  },
  sub: {
    top: '8px',
    fontSize: '8px',
    position: 'relative',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
  body: {
    flexDirection: 'column',
    fontSize: '11px',
  },
  note: {
    fontSize: '10px',
    fontFamily: 'Helvetica-Oblique',
  },
  sectionTitle: {
    flexDirection: 'row',
    fontSize: '13px',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '50px',
  },
});

export const PdfReport = forwardRef<PdfExportRef, PdfReportProps>(
  ({ emissionName, coloredEmitters, entriesByEmitter, interval, conversion }, ref) => {
    const document = useMemo(() => {
      const hasEntries = Object.values(entriesByEmitter).flat().length > 0;
      return !hasEntries ? (
        <> </>
      ) : (
        <Document>
          <Page size="A4" style={styles.a4}>
            <Header interval={interval}>
              <View style={styles.title}>
                <Text>{emissionName}</Text>
                <Text> Emissions (kg)</Text>
              </View>
            </Header>
            <View style={styles.body}>
              <View style={styles.sectionTitle}>
                <Text>Summary</Text>
              </View>
              <Summary
                emissionName={emissionName}
                coloredEmitters={coloredEmitters}
                entriesByEmitter={entriesByEmitter}
                conversion={conversion}
              />
            </View>
          </Page>
          {coloredEmitters.map((c) => (
            <Page size="A4" style={styles.a4} key={c.emitter.id}>
              <View style={styles.body}>
                <Text style={styles.note}>
                  * Days with highest {emissionName} production for a given vessel are in bold
                  below.
                </Text>
                <View style={styles.sectionTitle}>
                  <Text id={c.emitter.id}>{c.emitter.name}</Text>
                </View>
                <Breakdown
                  emissionName={emissionName}
                  coloredEmitter={c}
                  entriesByEmitter={entriesByEmitter}
                  conversion={conversion}
                />
              </View>
            </Page>
          ))}
        </Document>
      );
    }, [entriesByEmitter, interval, emissionName, coloredEmitters, conversion]);

    const fetchPdf = useCallback(
      (cb: () => void) => {
        const asPdf = pdf();
        asPdf.updateContainer(document);
        asPdf
          .toBlob()
          .then((blob) => {
            FileSaver.saveAs(blob, `sp${Date.now()}.pdf`);
            cb();
          })
          .catch((e) => {
            console.error(e);
            cb();
          });
      },
      [document]
    );

    useImperativeHandle(
      ref,
      () => ({
        fetchPdf,
      }),
      [fetchPdf]
    );

    return <></>;
  }
);
