import styles from './ToasterWrapper.module.scss';
import { PropsWithChildren, useCallback } from 'react';

type ToastWrapperProps = {
  offsetY: number;
  onHeightUpdate: (height: number) => void;
};

export const ToastWrapper: React.FC<PropsWithChildren<ToastWrapperProps>> = ({
  offsetY,
  onHeightUpdate,
  children,
}) => {
  const ref = useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        const updateHeight = () => {
          const height = el.getBoundingClientRect().height;
          onHeightUpdate(height);
        };
        updateHeight();
        new MutationObserver(updateHeight).observe(el, {
          subtree: true,
          childList: true,
          characterData: true,
        });
      }
    },
    [onHeightUpdate]
  );

  return (
    <div
      ref={ref}
      className={styles.toasterWrapper}
      style={{ transform: `translateY(${offsetY}px)` }}
    >
      {children}
    </div>
  );
};
