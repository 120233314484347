import styles from './ControlBar.module.scss';
import { useCallback, useEffect } from 'react';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { IntervalSelector, TimelineBar, ViewModeSelector } from './components';
import { generateStaticInterval, getAbsoluteInterval, updateIntervalInUrl } from 'Helpers/interval';
import { useNodeServerObserver } from 'Networking/socket';
import { AbsoluteInterval, DEFAULT_INTERVAL, Interval } from 'Constants';
import { useControlBarManager, useTimelineInterval, useTimelineThumb, useViewMode } from './hooks';
import { ViewMode } from './components/ViewModeSelector/components';
import { getAnchoredDate } from 'Helpers/date';
import {
  generateTimelineBarInterval,
  generateTimelineBarSampleRateMs,
  updateTimelineBarThumbPositionInUrl,
} from 'Helpers/timeline';
import { ControlBarManager } from './ControlBarManager';

export const ControlBar: React.FC = () => {
  const manager = useControlBarManager();

  const updateInterval = useCallback(
    (value: Interval) => {
      const absoluteInterval = getAbsoluteInterval(generateStaticInterval(value));

      manager.setInterval(value);
      setTimelineBarIntervalAndSampleRateMs(absoluteInterval, manager);

      updateIntervalInUrl(value);
    },
    [manager]
  );

  const resetInterval = useCallback(() => {
    updateInterval(DEFAULT_INTERVAL);
  }, [updateInterval]);

  const observer = useNodeServerObserver();
  const viewMode = useViewMode();

  const { absoluteInterval, refresh: refreshInterval, interval } = useSelectedInterval();

  useEffect(() => {
    setTimelineBarIntervalAndSampleRateMs(absoluteInterval, manager);
  }, [absoluteInterval, manager]);

  useEffect(() => {
    // Use `manager.getViewMode()` instead of viewMode from `useViewMode`
    // hook because it's not captured by useEffect meaning that it's
    // always up to date.
    if (manager.getViewMode() === 'live') {
      requestAnimationFrame(function updateTimelineBarInterval() {
        refreshInterval();
        // We use `manager.getViewMode()` here for the same reason as above.
        if (manager.getViewMode() === 'live') {
          requestAnimationFrame(updateTimelineBarInterval);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  useEffect(() => {
    observer.acceptIncomingMessages = viewMode === 'live';
  }, [observer, viewMode]);

  const handleToggleViewModeSelector = useCallback(() => {
    const newViewMode: ViewMode = manager.getViewMode() === 'live' ? 'replay' : 'live';

    if (newViewMode === 'live') {
      resetInterval();
    } else {
      updateInterval(manager.getTimelineBarInterval());
    }
  }, [manager, resetInterval, updateInterval]);

  useEffect(() => {
    if (viewMode !== 'replay') {
      manager.setThumbPosition(1.0);
    }
  }, [manager, viewMode]);

  const handleChangeThumbPosition = useCallback(
    (position: number, released: boolean) => {
      if (manager.getViewMode() !== 'replay') {
        updateInterval(manager.getTimelineBarInterval());
      }

      const anchoredPosition = getAnchoredPosition(
        position,
        manager.getTimelineBarInterval(),
        manager.getTimelineBarSampleRateMs()
      );

      if (released) {
        manager.setReleasedThumbPosition(anchoredPosition);

        // We need to listen to releasedThumbPosition instead of
        // thumbPosition because of Chrome's ipc flooding protection
        // feature.
        // @see: https://bugs.chromium.org/p/chromium/issues/detail?id=1038223
        updateTimelineBarThumbPositionInUrl(anchoredPosition);
      } else {
        manager.setThumbPosition(anchoredPosition);
      }
    },
    [manager, updateInterval]
  );

  const timelineBarInterval = useTimelineInterval();
  const { thumbPosition } = useTimelineThumb();

  return (
    <div className={styles.controlBar}>
      <div className={styles.controlBarHeader}>
        <IntervalSelector
          selectedInterval={interval}
          onSelectOption={updateInterval}
          onSelectReset={resetInterval}
        />
        <ViewModeSelector mode={viewMode} onToggle={handleToggleViewModeSelector} />
      </div>
      <TimelineBar
        earliest={timelineBarInterval.earliest}
        latest={timelineBarInterval.latest}
        thumbPosition={thumbPosition}
        onChangeThumbPosition={handleChangeThumbPosition}
      />
    </div>
  );
};

function getAnchoredPosition(
  position: number,
  interval: AbsoluteInterval,
  sampleRateMs: number
): number {
  const earliestDate = new Date(interval.earliest);
  const latestDate = new Date(interval.latest);

  const positionDate = new Date(
    earliestDate.getTime() + (latestDate.getTime() - earliestDate.getTime()) * position
  );

  const anchoredDate = getAnchoredDate(positionDate, sampleRateMs);

  const anchoredPosition =
    (anchoredDate.getTime() - earliestDate.getTime()) /
    (latestDate.getTime() - earliestDate.getTime());

  return anchoredPosition;
}

function setTimelineBarIntervalAndSampleRateMs(
  interval: AbsoluteInterval,
  manager: ControlBarManager
) {
  const timelineBarSampleRateMs = generateTimelineBarSampleRateMs(interval);
  const timelineBarInterval = generateTimelineBarInterval(interval, timelineBarSampleRateMs);

  manager.setTimelineBarSampleRateMs(timelineBarSampleRateMs);
  manager.setTimelineBarInterval(timelineBarInterval);
}
