import { useRef } from 'react';
import { Property } from 'Models';
import {
  GetSensorsRecordsQueryArgument,
  SensorRecords,
  useLazyGetSensorsRecordsQuery,
  VariableProperty,
} from 'Services/redux/api/sensor';
import { FramesByProperty, PropertyFrame } from './useGetPropertiesFramesQuery';
import { createPropertyName, generatePropertyHash } from 'Helpers/sensors';
import { isVariableSensorRecordsArgument } from 'Services/redux/api/sensor/util';
import { SubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState';

const EMPTY_PROPERTIES_FRAMES: FramesByProperty = {};

export const useLazyGetPropertiesFramesQuery = (options?: SubscriptionOptions) => {
  const [getSensorRecordsQuery, result] = useLazyGetSensorsRecordsQuery({
    ...options,
    selectFromResult: ({
      originalArgs,
      currentData,
      isUninitialized,
      isLoading,
      isFetching,
      isSuccess,
      isError,
      error,
    }) => {
      const recordsBySensors = currentData ?? [];

      const propertiesFrames = originalArgs
        ? isVariableSensorRecordsArgument(originalArgs)
          ? getFramesByVariableProperty(originalArgs.properties, recordsBySensors)
          : getFramesByProperty(originalArgs.properties, recordsBySensors)
        : {};

      return {
        propertiesFrames: Object.keys(propertiesFrames).length
          ? propertiesFrames
          : EMPTY_PROPERTIES_FRAMES,
        isUninitialized,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
      };
    },
  });

  const externalTrigger = (arg: GetSensorsRecordsQueryArgument, includeErrors?: boolean) => {
    const argWithTimestamps = (() => {
      if (isVariableSensorRecordsArgument(arg)) {
        const seenSensorIds: Record<string, boolean> = {};
        const metadataProperties: VariableProperty[] = [];
        for (const variableProperty of arg.properties) {
          if (seenSensorIds.hasOwnProperty(variableProperty.property.sensorId)) {
            continue;
          }

          seenSensorIds[variableProperty.property.sensorId] = true;

          const systemTimeProperty = getPropertyByName(variableProperty.property, 'systemTime');
          const errorCodesProperty = getPropertyByName(variableProperty.property, 'errorCodes');
          const isSatcommProperty = getPropertyByName(
            variableProperty.property,
            'metadata.isSatcomm'
          );

          if (errorCodesProperty && includeErrors) {
            metadataProperties.push({
              property: errorCodesProperty,
              variants: [
                {
                  alias: errorCodesProperty.name.hash,
                  aggregation: 'max',
                },
              ],
            });
          }

          if (isSatcommProperty) {
            metadataProperties.push({
              property: isSatcommProperty,
              variants: [
                {
                  alias: isSatcommProperty.name.hash,
                  aggregation: 'min',
                },
              ],
            });
          }

          metadataProperties.push({
            property: systemTimeProperty,
            variants: [
              {
                alias: systemTimeProperty.name.hash,
                aggregation: 'first',
              },
            ],
          });
        }

        return { ...arg, properties: arg.properties.concat(metadataProperties) };
      } else {
        const seenSensorIds: Record<string, boolean> = {};
        const metadataProperties: Property[] = [];
        for (const property of arg.properties) {
          if (seenSensorIds.hasOwnProperty(property.sensorId)) {
            continue;
          }

          seenSensorIds[property.sensorId] = true;

          const systemTimeProperty = getPropertyByName(property, 'systemTime');
          const errorCodesProperty = getPropertyByName(property, 'errorCodes');
          const isSatcommProperty = getPropertyByName(property, 'metadata.isSatcomm');

          if (errorCodesProperty && includeErrors) {
            metadataProperties.push(errorCodesProperty);
          }

          if (isSatcommProperty) {
            metadataProperties.push(isSatcommProperty);
          }

          metadataProperties.push(systemTimeProperty);
        }

        return {
          ...arg,
          properties: arg.properties.concat(metadataProperties),
        };
      }
    })();

    getSensorRecordsQuery(argWithTimestamps);
  };

  const externalTriggerRef = useRef(externalTrigger);

  return [result, externalTriggerRef.current] as const;
};

const getFramesByProperty = (
  properties: Property[],
  recordsBySensors: SensorRecords[]
): FramesByProperty => {
  const framesByProperty: FramesByProperty = {};

  for (const recordsBySensor of recordsBySensors) {
    for (const property of properties) {
      if (recordsBySensor.sensorId === property.sensorId) {
        framesByProperty[property.hash] = recordsBySensor.records.reduce<PropertyFrame[]>(
          (acc, record) => {
            const value = record[property.name.hash];
            const date = record[getPropertyByName(property, 'systemTime').name.hash];
            const errors = record[getPropertyByName(property, 'errorCodes').name.hash] as
              | number[]
              | null;
            const isSatcomm = record[getPropertyByName(property, 'metadata.isSatcomm').name.hash];

            if (date) {
              const frame: PropertyFrame = {
                value: value === undefined ? null : value,
                date: date as string,
                isSatcomm,
                errors,
              };

              return acc.concat(frame);
            }

            return acc;
          },
          []
        );
      }
    }
  }

  return framesByProperty;
};

const getFramesByVariableProperty = (
  variableProperties: VariableProperty[],
  recordsBySensors: SensorRecords[]
): FramesByProperty => {
  const framesByProperty: FramesByProperty = {};

  for (const recordsBySensor of recordsBySensors) {
    for (const variableProperty of variableProperties) {
      if (recordsBySensor.sensorId === variableProperty.property.sensorId) {
        for (const variant of variableProperty.variants) {
          framesByProperty[variant.alias] = recordsBySensor.records.reduce<PropertyFrame[]>(
            (acc, record) => {
              const value = record[variant.alias];
              const date =
                record[getPropertyByName(variableProperty.property, 'systemTime').name.hash];
              const errors = record[
                getPropertyByName(variableProperty.property, 'errorCodes').name.hash
              ] as number[] | null;
              const isSatcomm =
                record[
                  getPropertyByName(variableProperty.property, 'metadata.isSatcomm').name.hash
                ];

              if (date) {
                const frame: PropertyFrame = {
                  value: value === undefined ? null : value,
                  date: date as string,
                  errors,
                  isSatcomm,
                };

                return acc.concat(frame);
              }

              return acc;
            },
            []
          );
        }
      }
    }
  }

  return framesByProperty;
};

const getPropertyByName = (
  property: Property,
  name: 'metadata.isSatcomm' | 'systemTime' | 'errorCodes'
): Property => {
  const path = property.name.path.replace(/\/[^/]*$/, name);

  const propertyName = createPropertyName(name, path);

  return {
    name: propertyName,
    displayName: name,
    sensorGroupId: property.sensorGroupId,
    sensorId: property.sensorId,
    sensorName: property.sensorName,
    sensorDisplayName: property.sensorDisplayName,
    sampleRate: property.sampleRate,
    hash: generatePropertyHash(property.sensorId, propertyName),
  };
};

export { getFramesForProperty } from './useGetPropertiesFramesQuery';
export type { PropertyFrame } from './useGetPropertiesFramesQuery';
