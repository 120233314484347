import { Property } from 'Models';
import { Aggregation, PropertyValue, Sample, SampleUnit, SensorRecords } from './interfaces';
import { AbsoluteInterval } from 'Constants';
import { belongsToSameSensor, generateProperyByName } from 'Helpers/sensors';
import { getAccessTokenSilently } from 'Services/auth0';
import { urlConfiguration } from 'Config';
import { generateSensorPayloads } from './helpers';
import axios from 'axios';
import { OptionalTuple } from 'Types/utility';

export interface FetchSampleSeriesQueryArgument {
  properties: Property[];
  interval: AbsoluteInterval;
  aggregation?: Aggregation;
  sample?: {
    unit: SampleUnit;
    rate: number;
  };
  densify?: boolean;
}

export interface FetchSampleSeriesOptions {
  includeIsSatcomm: boolean;
  includeErrors: boolean;
}

const DEFAULT_OPTIONS: FetchSampleSeriesOptions = {
  includeIsSatcomm: true,
  includeErrors: false,
};

async function fetchSampleSeries<Values extends Array<PropertyValue>>(
  arg: FetchSampleSeriesQueryArgument,
  options: FetchSampleSeriesOptions = DEFAULT_OPTIONS
) {
  const properties = arg.properties;

  console.assert(properties.length > 0, "Properties can't be empty");
  console.assert(belongsToSameSensor(properties), 'Properties must belong to the same sensor');

  const token = await getAccessTokenSilently();
  const systemTimeProperty = generateProperyByName(properties[0], 'systemTime');
  const isSatcommProperty = generateProperyByName(properties[0], 'metadata.isSatcomm');
  const errorCodesProperty = generateProperyByName(properties[0], 'errorCodes');

  const requestedProperties = [...properties, systemTimeProperty];

  if (options.includeIsSatcomm) {
    requestedProperties.push(isSatcommProperty);
  }

  if (options.includeErrors) {
    requestedProperties.push(errorCodesProperty);
  }

  if (!requestedProperties.length) {
    return null;
  }

  const { data: sensorsRecords } = await axios.post<SensorRecords[]>(
    `${urlConfiguration.api}/sensors`,
    generateRequestBody(arg),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const records = sensorsRecords[0]?.records ?? [];

  const samples = records.map((record) => {
    const values = properties.map((p) => record[p.name.hash]) as Values;
    const timestamp = record[systemTimeProperty.name.hash];
    const isSatcomm = record[isSatcommProperty.name.hash] as boolean;
    const errorCodes = (record[errorCodesProperty.name.hash] as number[] | null) ?? [];

    return [...values, timestamp, isSatcomm, errorCodes] as Sample<OptionalTuple<Values>>;
  });

  return samples;
}

fetchSampleSeries.generateQueryKey = ({
  properties,
  interval,
  aggregation,
  sample,
  densify,
}: FetchSampleSeriesQueryArgument) => {
  const propertiesHash = properties
    .map((p) => p.hash)
    .sort()
    .join();

  return [
    'samples',
    propertiesHash,
    { earliest: interval.earliest, latest: interval.latest, aggregation, sample, densify },
  ];
};

function generateRequestBody({
  properties,
  interval,
  aggregation,
  sample,
  densify,
}: FetchSampleSeriesQueryArgument) {
  const body: Record<string, any> = {
    earliest: interval.earliest,
    latest: interval.latest,
    sensors: generateSensorPayloads(properties),
  };

  if (aggregation) {
    body.aggregation = aggregation;
  }

  if (sample) {
    body.sampleUnit = sample.unit;
    body.sampleRate = sample.rate;
  }

  if (densify !== undefined) {
    body.densify = densify;
  }

  return body;
}

export { fetchSampleSeries };
