import { Calendar, CalendarPosition } from './types';
import { DateRange } from 'Components/DateTimeRangePickerModal/types';
import { OptionalTuple } from 'Types/utility';

export function getInitialCalendars(
  selectedDateRange?: [earliest: Date, latest: Date]
): Calendar[] {
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const initialDate = selectedDateRange ? selectedDateRange[0] : lastMonth;

  const earliestCalendarPosition: CalendarPosition = {
    year: initialDate.getFullYear(),
    month: initialDate.getMonth(),
  };

  const calendars: Calendar[] = [];

  /**
   * By preloading 2 calendars to the left and 2 calendars
   * to the right, we can improve performance and make sure
   * the animation runs smoothly.
   *
   * The same approach is used by Apple in their UIKit
   * framework.
   */
  for (let i = -2; i < 4; i++) {
    let calendarPosition = earliestCalendarPosition;

    for (let j = 0; j < Math.abs(i); j++) {
      if (i < 0) {
        calendarPosition = getPreviousCalendarPosition(calendarPosition);
      } else {
        calendarPosition = getNextCalendarPosition(calendarPosition);
      }
    }

    calendars.push({
      position: calendarPosition,
      order: i,
    });
  }

  return calendars;
}

export function pushCalendar(calendars: Calendar[]) {
  const rightMostCalendar = calendars[calendars.length - 1];
  const newCalendars: Calendar[] = [
    ...calendars.map((calendar) => ({
      ...calendar,
      order: calendar.order - 1,
    })),
    {
      order: rightMostCalendar.order,
      position: getNextCalendarPosition(rightMostCalendar.position),
    },
  ].slice(1);

  return newCalendars;
}

export function unshiftCalendar(calendars: Calendar[]) {
  const leftMostCalendar = calendars[0];
  const newCalendars: Calendar[] = [
    {
      position: getPreviousCalendarPosition(leftMostCalendar.position),
      order: leftMostCalendar.order,
    },
    ...calendars.map((calendar) => ({
      ...calendar,
      order: calendar.order + 1,
    })),
  ].slice(0, -1);

  return newCalendars;
}

export function getCalendarOffsetX(calendar: Calendar) {
  const PADDING = 20;
  const SPACING = 40;
  const CALENDAR_WIDTH = 36 * 7;

  return PADDING + calendar.order * (CALENDAR_WIDTH + SPACING);
}

export function getDisplayedDateRange(selectedDateRange?: DateRange): OptionalTuple<DateRange> {
  return selectedDateRange ?? [undefined, undefined];
}

function getPreviousCalendarPosition(calendarPosition: CalendarPosition) {
  const year = calendarPosition.month === 0 ? calendarPosition.year - 1 : calendarPosition.year;
  const month = calendarPosition.month === 0 ? 11 : calendarPosition.month - 1;

  return { year, month };
}

function getNextCalendarPosition(calendarPosition: CalendarPosition) {
  const year = calendarPosition.month === 11 ? calendarPosition.year + 1 : calendarPosition.year;
  const month = (calendarPosition.month + 1) % 12;

  return { year, month };
}
