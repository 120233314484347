import clsx from 'clsx';
import styles from './Toast.module.scss';
import { ToastLevel } from '../../interfaces';
import { ReactComponent as IconCheck } from './resources/graphics/icon-check.svg';
import { ReactComponent as IconExclamation } from './resources/graphics/icon-exclamation.svg';
import { ReactComponent as IconCross } from './resources/graphics/icon-cross.svg';

type ToastProps = {
  level: `${ToastLevel}`;
  title: string;
  width: number;
  visible: boolean;
  onDismiss: () => void;
};

export const Toast: React.FC<ToastProps> = ({ level, title, width, visible, onDismiss }) => {
  return (
    <div
      className={clsx(styles.toast, styles[level], {
        [styles.slidein]: visible,
        [styles.slideout]: !visible,
      })}
      style={{ width: `${width}px` }}
    >
      <div className={styles.toastContent}>
        <div className={styles.prefix}>
          {level === 'success' ? <IconCheck /> : <IconExclamation />}
        </div>
        <div className={styles.body}>{title}</div>
        <div className={styles.suffix}>
          <button onClick={onDismiss}>
            <IconCross />
          </button>
        </div>
      </div>
    </div>
  );
};
