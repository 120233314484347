import styles from '../../UserAccessesTable.module.scss';
import { UserAccess } from 'Models';
import { ReactComponent as IconDots } from './resources/graphics/icon-dots.svg';
import Moment from 'react-moment';
import { MenuPickerPopup } from 'Components/MenuPickerPopup';
import { usePopup } from 'Hooks/popup/usePopup';
import { forwardRef, useCallback } from 'react';

const MENU_ITEMS = [
  { label: 'Edit', id: 'edit' },
  { label: 'Revoke', id: 'revoke' },
] as const;

const menuLabels = MENU_ITEMS.map((i) => i.label);

type UserAccessesTableRowProps = {
  userAccess: UserAccess;
  onSelectEdit: () => void;
  onSelectRevoke: () => void;
};

export const UserAccessesTableRow = forwardRef<HTMLDivElement, UserAccessesTableRowProps>(
  ({ userAccess, onSelectEdit, onSelectRevoke }, ref) => {
    const [isMenuVisible, showMenu, hideMenu, menuRef] = usePopup();

    const handleSelectMenuItem = useCallback(
      (selectedIndex: number) => {
        const selectedMenuItem = MENU_ITEMS[selectedIndex];

        if (selectedMenuItem.id === 'edit') {
          onSelectEdit();
        } else if (selectedMenuItem.id === 'revoke') {
          onSelectRevoke();
        }
      },
      [onSelectEdit, onSelectRevoke]
    );

    return (
      <div key={userAccess.id} className={styles.tableRow} ref={ref}>
        <div className={styles.name}>
          <span>
            {userAccess.user.firstName} {userAccess.user.lastName}
          </span>
        </div>
        <div className={styles.email}>
          <span>
            <span>{userAccess.user.email}</span>
          </span>
        </div>
        <div className={styles.dateCreated}>
          <span>
            <Moment format="MMM D YYYY" date={userAccess.createdAt} />
            <br />
            <Moment format="h:mm a" date={userAccess.createdAt} />
          </span>
        </div>
        <div className={styles.menu}>
          <IconDots onClick={showMenu} className={styles.iconDots} />
          {isMenuVisible && (
            <MenuPickerPopup
              ref={menuRef}
              items={menuLabels}
              onSelect={(menuIndex) => handleSelectMenuItem(menuIndex)}
              onClickItem={hideMenu}
            />
          )}
        </div>
      </div>
    );
  }
);
