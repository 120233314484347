import { Emitter, Sensor } from 'Models';
import { SensorName } from 'Constants';
import { SensorUpdateMessagePayload } from '../interfaces';
import { BaseMessageTest } from '../BaseMessageTest';
import { getSensorsWithName } from 'Helpers/sensors';
import { NodeServerObserver } from '..';

type EmissionV2Sensor = Sensor<typeof SensorName.EmissionV2.name>;

export class EmissionV2MessageTest extends BaseMessageTest {
  constructor(protected observer: NodeServerObserver, private emitters: Emitter[]) {
    super(SensorName.EmissionV2.name, observer, 3000);
  }

  protected getMessagePayloads(cycleCount: number): SensorUpdateMessagePayload[] {
    return this.emitters.reduce<SensorUpdateMessagePayload[]>((acc, emitter) => {
      const emissionV2Sensors = getSensorsWithName(SensorName.EmissionV2, emitter);

      return emissionV2Sensors.length
        ? acc.concat(
            emissionV2Sensors.map((sensor) =>
              this.generatePayloadForEmitter(sensor, emitter, cycleCount)
            )
          )
        : acc;
    }, []);
  }

  private generatePayloadForEmitter(
    sensor: EmissionV2Sensor,
    emitter: Emitter,
    cycleCount: number
  ): SensorUpdateMessagePayload {
    return {
      metadata: { sensorId: sensor.id, ns: emitter.namespace, isSatcomm: false },
      latitude: emitter.coordinate && emitter.coordinate.latitude + cycleCount * 0.0007,
      longitude: emitter.coordinate && emitter.coordinate.longitude + cycleCount * 0.0007,
      H2O: Math.random() * 150.0,
      CO2: Math.random() * 100.0,
      CH4Dual: Math.random() * 5000.0,
      NOXDual: Math.random() * 5000.0,
      SO2: Math.random() * 150.0,
      temperature: Math.random() * 120.0,
      pressure: Math.random() * 3000.0,
      CO2MassFlow: Math.random() * 100.0,
      SO2MassFlow: Math.random() * 100.0,
      NOXMassFlow: Math.random() * 100.0,
      CH4MassFlow: Math.random() * 100.0,
      CO2Flow: Math.random() * 1000.0,
      NOXFlow: Math.random() * 1000.0,
      SO2Flow: Math.random() * 1000.0,
      CH4Flow: Math.random() * 1000.0,
      dust: Math.random() * 500.0,
      flow: Math.random() * 2000.0,
      systemTime: new Date().toISOString(),
    };
  }
}
