import styles from './ReportsPage.module.scss';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { SideBar } from './components';
import { ReportsPageContent } from './components/ReportsPageContent/ReportsPageContent';
import { EmitterOption } from './interfaces';
import { hasCO2Properties } from './helpers/sensor';
import { isVessel } from 'Helpers/emitter';
import { Vessel } from 'Models';
import { useQuery } from '@tanstack/react-query';
import { fetchEmitters } from 'Networking/http';

export const ReportsPage: React.FC = () => {
  const [enableAllEmitters, setEnableAllEmitters] = useState(false);
  const [emitterOptions, setEmitterOptions] = useState<EmitterOption[]>([]);

  const emittersQuery = useQuery(fetchEmitters.generateQueryKey(), () => fetchEmitters());

  const exportPdf = useRef(null);

  useEffect(() => {
    if (!emittersQuery.isSuccess) return;

    const vessels = emittersQuery.data.filter((e): e is Vessel => isVessel(e));

    const emitterOptions: EmitterOption[] = vessels
      .map((vessel) => ({
        emitter: vessel,
        isSelected: false,
        isDisabled: !hasCO2Properties(vessel),
      }))
      .sort((a, b) => {
        if (!a.isDisabled && b.isDisabled) {
          return -1;
        } else if (a.isDisabled && !b.isDisabled) {
          return 1;
        } else {
          return 0; // maintain the order
        }
      });

    setEmitterOptions(emitterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emittersQuery.isSuccess]);

  const handleToggleAllEmitterOption = useCallback(() => {
    const newEnableAllEmitters = !enableAllEmitters;

    setEnableAllEmitters(newEnableAllEmitters);
    setEmitterOptions((emitterOptions) =>
      emitterOptions.map((emitterOption) => ({
        ...emitterOption,
        isSelected: emitterOption.isDisabled ? false : newEnableAllEmitters,
      }))
    );
  }, [enableAllEmitters]);

  const handleToggleEmitterOption = useCallback(
    (toggleIndex: number) => {
      setEnableAllEmitters(false);
      setEmitterOptions(
        emitterOptions.map((emitterOption, index) =>
          index === toggleIndex
            ? {
                ...emitterOption,
                isSelected: emitterOption.isDisabled ? false : !emitterOption.isSelected,
              }
            : emitterOption
        )
      );
    },
    [emitterOptions]
  );

  const selectedEmitters = useMemo(
    () => emitterOptions.filter((o) => o.isSelected).map((o) => o.emitter),
    [emitterOptions]
  );

  return (
    <div className={styles.reportsPage}>
      <SideBar
        emitterOptions={emitterOptions}
        isFetchingEmitters={emittersQuery.isLoading}
        enableAllEmitter={enableAllEmitters}
        onToggleAllEmitterOption={handleToggleAllEmitterOption}
        onToggleEmitterOption={handleToggleEmitterOption}
        ref={exportPdf}
      />
      <ReportsPageContent emitters={selectedEmitters} ref={exportPdf} />
    </div>
  );
};
