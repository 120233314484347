import { SensorName } from 'Constants';

export const SectionConfig = {
  weather: [
    SensorName.Weather.PropertyName.AirTemperature,
    SensorName.Weather.PropertyName.TrueWindDirection,
    SensorName.Weather.PropertyName.TrueWindSpeed,
    SensorName.Weather.PropertyName.RelativeHumidity,
    SensorName.Weather.PropertyName.AtmosphericPressure,
  ],
  emissionsV2: [
    SensorName.EmissionV2.PropertyName.CO2,
    SensorName.EmissionV2.PropertyName.CO2MassFlow,
    SensorName.EmissionV2.PropertyName.CO,
    SensorName.EmissionV2.PropertyName.COMassFlow,
    SensorName.EmissionV2.PropertyName.CH4,
    SensorName.EmissionV2.PropertyName.CH4MassFlow,
    SensorName.EmissionV2.PropertyName.SO2,
    SensorName.EmissionV2.PropertyName.SO2MassFlow,
    SensorName.EmissionV2.PropertyName.NOX,
    SensorName.EmissionV2.PropertyName.NOXMassFlow,
    SensorName.EmissionV2.PropertyName.H2O,
    SensorName.EmissionV2.PropertyName.Flow,
    SensorName.EmissionV2.PropertyName.Dust,
  ],
  gps: [SensorName.GPS.PropertyName.SOG, SensorName.GPS.PropertyName.COG],
  imu: [SensorName.IMU.PropertyName.Pitch, SensorName.IMU.PropertyName.Roll],
  engine: [
    SensorName.Engine.PropertyName.FuelConsumption,
    SensorName.Engine.PropertyName.FuelOutTemperature,
  ],
  generator: [
    SensorName.Generator.PropertyName.ApparentPower,
    SensorName.Generator.PropertyName.Load,
  ],
  airQuality: [
    SensorName.AirQuality.PropertyName.PM25Aqi,
    SensorName.AirQuality.PropertyName.PM10Aqi,
  ],
} as const;

export type SectionConfigType = keyof typeof SectionConfig;
