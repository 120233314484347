import { useEffect, useMemo, useState } from 'react';
import { Source, Layer, LayerProps } from 'react-map-gl';
import { FeatureCollection, Point } from 'geojson';
import { Alert, AlertLevel, Vessel } from 'Models';
import { useGetAlertsQuery } from 'Services/redux/alerts/alerts';
import { useAppDispatch } from 'Services/redux';
import { setIsLoadingAlertLayer } from 'Services/redux/map';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import IconAlertHigh from './resources/graphics/icon-alert-high.png';
import IconAlertMedium from './resources/graphics/icon-alert-medium.png';
import IconAlertLow from './resources/graphics/icon-alert-low.png';
import IconAlertUnknown from './resources/graphics/icon-alert-unknown.png';
import { MapFeature, MapLayerProps } from '../../../interfaces';
import { useMapImage } from '../../../hooks';
import { useObserveNewAlert, useObserveAlertResolved } from 'Networking/socket/alerts';
import { Hashable, OrderedSet } from 'Stdlibs/OrderedSet/OrderedSet';

const ALERT_HIGH_IMAGE_KEY = 'icon-alert-high';
const ALERT_MEDIUM_IMAGE_KEY = 'icon-alert-medium';
const ALERT_LOW_IMAGE_KEY = 'icon-alert-low';
const ALERT_UNKNOWN_IMAGE_KEY = 'icon-alert-unknown';

const BASE_LAYER_PROPS: LayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': [
      'match',
      ['get', 'alertLevel'],
      AlertLevel.Low,
      ALERT_LOW_IMAGE_KEY,
      AlertLevel.Medium,
      ALERT_MEDIUM_IMAGE_KEY,
      AlertLevel.High,
      ALERT_HIGH_IMAGE_KEY,
      ALERT_UNKNOWN_IMAGE_KEY,
    ],
    'icon-size': 0.6,
    'icon-allow-overlap': true,
  },
};

const getFeature = (alert: Alert): MapFeature | undefined =>
  alert.lastCoordinate && {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [alert.lastCoordinate.longitude, alert.lastCoordinate.latitude],
    },
    id: alert.id,
    properties: {
      alertLevel: alert.level,
      element: JSON.stringify({
        value: alert,
        type: 'alert',
      }),
    },
  };

type AlertLayerProps = MapLayerProps & { vessel: Vessel };

type HashableAlert = Alert & Hashable;

export const AlertLayer: React.FC<AlertLayerProps> = ({ id, beforeId, vessel }) => {
  const [alerts, setAlerts] = useState(new OrderedSet<HashableAlert>());

  useMapImage(IconAlertHigh, ALERT_HIGH_IMAGE_KEY);
  useMapImage(IconAlertMedium, ALERT_MEDIUM_IMAGE_KEY);
  useMapImage(IconAlertLow, ALERT_LOW_IMAGE_KEY);
  useMapImage(IconAlertUnknown, ALERT_UNKNOWN_IMAGE_KEY);

  const {
    absoluteInterval: { earliest, latest },
  } = useSelectedInterval();

  const newAlert = useObserveNewAlert();
  const resolvedAlert = useObserveAlertResolved();

  useEffect(() => {
    if (!newAlert) {
      return;
    }
    setAlerts((previousAlerts) =>
      previousAlerts.concat(
        new OrderedSet({
          ...newAlert,
          hash: newAlert.id,
        })
      )
    );
  }, [newAlert]);

  useEffect(() => {
    if (!resolvedAlert) {
      return;
    }
    setAlerts((previousAlerts) =>
      previousAlerts.concat(
        new OrderedSet({
          ...resolvedAlert,
          hash: resolvedAlert.id,
        })
      )
    );
  }, [resolvedAlert]);

  const { data: response, isFetching } = useGetAlertsQuery({
    type: 'all',
    emitter: vessel,
    earliest,
    latest,
    pageSize: 400,
  });

  useEffect(() => {
    if (!response?.alerts) {
      return;
    }
    setAlerts(new OrderedSet(...response.alerts.map((a) => ({ ...a, hash: a.id }))));
  }, [response?.alerts]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsLoadingAlertLayer(isFetching));

    return () => {
      dispatch(setIsLoadingAlertLayer(false));
    };
  }, [isFetching, dispatch]);

  const memoizedFeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: alerts.map((a) => getFeature(a)).filter((a): a is MapFeature => !!a),
    } as FeatureCollection<Point>;
  }, [alerts]);

  const layerProps = { id, ...BASE_LAYER_PROPS };

  if (beforeId) {
    layerProps.beforeId = beforeId;
  }

  return (
    <Source id={id} type="geojson" data={memoizedFeatureCollection}>
      <Layer {...layerProps} />
    </Source>
  );
};
