import styles from './ReportMenu.module.scss';
import { ReactComponent as IconReport } from './resources/graphics/icon-report.svg';
import { ReactComponent as IconEye } from './resources/graphics/icon-eye.svg';
import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'Services/redux';
import { sessionUserSelector } from 'Services/redux/session';

type ReportMenuProps = {
  onClickItem?: () => void;
};

export const ReportMenu = forwardRef<HTMLDivElement, ReportMenuProps>(({ onClickItem }, ref) => {
  const currentUser = useAppSelector(sessionUserSelector);

  const hasLookerPermission = useMemo(() => {
    if (!currentUser || !currentUser.permissions) return false;
    return currentUser.permissions.some((permission) => permission.name === 'looker:read');
  }, [currentUser]);

  return (
    <div className={styles.userMenu} ref={ref}>
      <div className={styles.leftTriangle} />
      <ul>
        <li onClick={onClickItem}>
          <Link
            to="/emissionspdf"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.sideBarMenu}
          >
            <div>
              <IconReport />
            </div>
            <span>Emissions PDF</span>
          </Link>
        </li>
        {hasLookerPermission && (
          <li onClick={onClickItem}>
            <Link
              to="/discover"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.sideBarMenu}
            >
              <div>
                <IconEye />
              </div>
              <span>Discover</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
});
