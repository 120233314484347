import { Group } from '@visx/group';

type DotProps = { cx: number; cy: number; color: string };

export const Dot: React.FC<DotProps> = ({ cx, cy, color }) => {
  return (
    <Group>
      <circle
        cx={cx + 1}
        cy={cy + 1}
        r={4}
        fill="black"
        fillOpacity={0.1}
        stroke="black"
        strokeOpacity={0.1}
        strokeWidth={2}
        pointerEvents="none"
      />
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={color}
        stroke="white"
        strokeWidth={2}
        pointerEvents="none"
      />
    </Group>
  );
};
