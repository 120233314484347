import styles from './EmitterDetailsBarKeyValueCell.module.scss';
import { useMemo, useEffect, useCallback, useState, useRef } from 'react';
import { Property, SingleFormatter } from 'Models';
import PuffLoader from 'react-spinners/PuffLoader';
import { formatNumber } from 'Helpers/number';
import { fill, mergeSamples } from 'Helpers/sample';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import { generateTimelineBarDataInterval, getNewDataIndex } from 'Helpers/timeline';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { useQuery } from '@tanstack/react-query';
import { useLatestObservedSample } from 'Networking/socket';
import {
  FetchSampleSeriesQueryArgument,
  PropertyValue,
  fetchLatestSample,
  fetchSampleSeries,
} from 'Networking/http';
import { exp } from 'array-expression';
import clsx from 'clsx';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import { EMITTER_DETAILS_BAR_ID } from 'Pages/DashboardPage/components/EmitterDetailsBar/constants/layout';
import { OptionPopup } from 'Components/OptionPopup';
import { Conversion } from 'Interfaces';
import { getConversionConfig } from 'Helpers/conversion';
import { DEFAULT_MAX_FRACTION_DIGITS, DEFAULT_MIN_FRACTION_DIGITS } from 'Constants';

type EmitterDetailsBarKeyValueCellProps = {
  title?: string;
  property: Property;
  formatter?: SingleFormatter;
};

export const EmitterDetailsBarKeyValueCell: React.FC<EmitterDetailsBarKeyValueCellProps> = ({
  title,
  property,
  formatter,
}) => {
  const { absoluteInterval, refresh } = useSelectedInterval();

  useEffect(refresh, [property, refresh]);

  const controlBarManager = useControlBarManager();

  const dataInterval = useMemo(() => {
    return generateTimelineBarDataInterval(
      absoluteInterval,
      controlBarManager.getTimelineBarSampleRateMs()
    );
  }, [absoluteInterval, controlBarManager]);

  const latestSampleQueryArg = { properties: [property], interval: dataInterval };
  const latestSampleQuery = useQuery(fetchLatestSample.generateQueryKey(latestSampleQueryArg), () =>
    fetchLatestSample(latestSampleQueryArg)
  );

  const sampleSeriesQueryArg: FetchSampleSeriesQueryArgument = {
    properties: [property],
    interval: dataInterval,
    aggregation: 'first',
    sample: {
      unit: 'second',
      rate: Math.floor(controlBarManager.getTimelineBarSampleRateMs() / 1000),
    },
    densify: true,
  };

  const sampleSeriesQuery = useQuery(fetchSampleSeries.generateQueryKey(sampleSeriesQueryArg), () =>
    fetchSampleSeries(sampleSeriesQueryArg)
  );

  const sample = latestSampleQuery.data;
  const observedSample = useLatestObservedSample([property]);

  const mergedSample = useMemo(
    () => mergeSamples(sample, observedSample),
    [sample, observedSample]
  );

  const viewMode = useViewMode();
  const { thumbPosition } = useTimelineThumb();

  const historicalSamples = useMemo(
    () => sampleSeriesQuery.data && fill(sampleSeriesQuery.data, 'forward'),
    [sampleSeriesQuery.data]
  );

  const historicalSample = useMemo(() => {
    const samples = historicalSamples;

    if (samples && samples.length) {
      const dataIndex = getNewDataIndex(
        thumbPosition,
        dataInterval,
        controlBarManager.getTimelineBarSampleRateMs()
      );
      const invertedIndex = Math.min(
        Math.max(samples.length - 1 - dataIndex, 0),
        samples.length - 1
      );
      return samples[invertedIndex];
    }

    return undefined;
  }, [controlBarManager, dataInterval, historicalSamples, thumbPosition]);

  const sampleToDisplay = useMemo(() => {
    return viewMode === 'replay' ? historicalSample : mergedSample;
  }, [historicalSample, mergedSample, viewMode]);

  const value = useMemo(() => sampleToDisplay?.[0] as PropertyValue, [sampleToDisplay]);

  const [isUnitMenuVisible, setIsUnitMenuVisible] = useState(false);

  const handleClickUnitContainer = useCallback(() => {
    setIsUnitMenuVisible((v) => !v);
  }, []);

  useEffect(() => {
    const emitterDetailsBar = document.getElementById(EMITTER_DETAILS_BAR_ID);

    if (!emitterDetailsBar) return;

    const listener = () => {
      setIsUnitMenuVisible(false);
    };

    emitterDetailsBar.addEventListener('scroll', listener);
    window.addEventListener('resize', listener);

    return () => {
      emitterDetailsBar.removeEventListener('scroll', listener);
      window.removeEventListener('resize', listener);
    };
  }, []);

  const conversions: Conversion[] | undefined = useMemo(() => {
    if (!property.unit) return undefined;

    const conversionConfig = getConversionConfig(property.unit);

    if (!conversionConfig) return undefined;

    return conversionConfig.conversions;
  }, [property.unit]);

  const [selectedConversionIndex, setSelectedConversionIndex] = useState<number>(0);

  const selectedConversion = useMemo(
    () => conversions?.[selectedConversionIndex],
    [conversions, selectedConversionIndex]
  );

  const handleSelectUnitMenuItem = useCallback((index: number) => {
    setSelectedConversionIndex(index);
    setIsUnitMenuVisible(false);
  }, []);

  const unitContaintainerRef = useRef<HTMLElement>(null);

  const formattedValue = useMemo(() => {
    if (selectedConversion) {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        const formattedValue = exp(selectedConversion.exp, { value });
        if (typeof formattedValue === 'number') {
          return formatNumber(formattedValue, {
            minFractionDigits: selectedConversion.minFractionDigits ?? DEFAULT_MIN_FRACTION_DIGITS,
            maxFractionDigits: selectedConversion.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
          });
        }
      }
    } else {
      if (typeof value === 'number') {
        return formatNumber(value, {
          minFractionDigits: formatter?.minFractionDigits ?? DEFAULT_MIN_FRACTION_DIGITS,
          maxFractionDigits: formatter?.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
        });
      }
    }

    return value;
  }, [selectedConversion, value, formatter?.minFractionDigits, formatter?.maxFractionDigits]);

  return (
    <div key={property.hash} className={styles.emitterDetailsBarKeyValueCell}>
      <p className={styles.title}>{title ?? property.displayName}</p>
      <div className={styles.body}>
        {latestSampleQuery.isLoading ? (
          <div className={styles.loadingIndicator}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        ) : selectedConversion && value !== undefined && value !== null ? (
          <p className={clsx(styles.value)}>
            {formattedValue}{' '}
            {conversions && (
              <span
                ref={unitContaintainerRef}
                className={clsx(styles.unit, styles.dropdown)}
                onClick={handleClickUnitContainer}
              >
                {selectedConversion.unit}{' '}
                <span className={styles.arrow}>
                  <IconArrowDown
                    style={{ transform: isUnitMenuVisible ? 'rotate(180deg)' : undefined }}
                  />
                </span>
              </span>
            )}
            {conversions && isUnitMenuVisible && (
              <OptionPopup
                options={conversions.map((c) => c.unit)}
                selectedIndex={selectedConversionIndex}
                onSelectOption={handleSelectUnitMenuItem}
                onClickOutside={() => {
                  setIsUnitMenuVisible(false);
                }}
                sourceRef={unitContaintainerRef}
              />
            )}
          </p>
        ) : formattedValue !== undefined && formattedValue !== null ? (
          <p className={styles.value}>
            {formattedValue} {property.unit && <span className={styles.unit}>{property.unit}</span>}
          </p>
        ) : (
          <p className={styles.noData}>n/a</p>
        )}
      </div>
    </div>
  );
};
