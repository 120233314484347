import { formatNumber } from 'Helpers/number';
import { TICK_FONT_FAMILY, TICK_FONT_SIZE, TICK_FONT_WEIGHT } from '../constants';
import { ChartDomain } from '../interfaces';

export const calculateTickLabelWidth = ({
  domain,
  maximumFractionDigits,
  unit,
}: {
  domain: ChartDomain;
  maximumFractionDigits: number;
  unit?: string;
}) => {
  const maxValue = domain[1];

  const numberText = formatNumber(maxValue, {
    minFractionDigits: maximumFractionDigits,
    maxFractionDigits: maximumFractionDigits,
  });
  const normalizedNumberText = numberText.replace(new RegExp('\\d', 'g'), '0');

  const fullText = normalizedNumberText + (unit ?? '');

  const context = document.createElement('canvas').getContext('2d')!;
  context.font = `${TICK_FONT_WEIGHT} ${TICK_FONT_SIZE} ${TICK_FONT_FAMILY}`;

  const TICK_LINE_WIDTH = 8;

  return context.measureText(fullText).width + TICK_LINE_WIDTH;
};
