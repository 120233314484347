import { Sensor } from 'Models';
import { SensorName } from 'Constants';
import { VideoPlayerPopup } from 'Components/VideoPlayerPopup';
import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CameraUnreachableError, fetchVideoUrl } from 'Networking/http';

type VideoFeedPlayerPopupProps = {
  cameraSensor: Sensor<typeof SensorName.Camera.name>;
  onClose: () => void;
};

export const VideoFeedPlayerPopup: React.FC<VideoFeedPlayerPopupProps> = ({
  cameraSensor,
  onClose,
}) => {
  const { data: videoUrl, error } = useQuery(fetchVideoUrl.generateQueryKey({ cameraSensor }), () =>
    fetchVideoUrl({ cameraSensor })
  );

  const errorMessage = useMemo(() => {
    if (!error) return undefined;

    return error instanceof CameraUnreachableError
      ? 'Camera is not currently reachable. Try again later.'
      : 'Unknown error. Try again later.';
  }, [error]);

  useEffect(() => {
    if (!videoUrl) return;

    let handle: NodeJS.Timeout;

    (async function ping() {
      try {
        await fetchVideoUrl({ cameraSensor });
        handle = setTimeout(ping, 25000);
      } catch (err) {}
    })();

    return () => {
      clearTimeout(handle);
    };
  }, [cameraSensor, videoUrl]);

  return (
    <VideoPlayerPopup
      title={cameraSensor.displayName}
      url={videoUrl}
      errorMessage={errorMessage}
      onClose={onClose}
    />
  );
};
