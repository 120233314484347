import styles from './DatePicker.module.scss';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ReactComponent as IconArrowLeft } from './resources/graphics/icon-arrow-left.svg';
import { ReactComponent as IconArrowLeftDisabled } from './resources/graphics/icon-arrow-left-disabled.svg';
import { ReactComponent as IconArrowRight } from './resources/graphics/icon-arrow-right.svg';
import { ReactComponent as IconArrowRightDisabled } from './resources/graphics/icon-arrow-right-disabled.svg';
import { ReactComponent as IconArrowDownSmall } from 'Resources/graphics/icon-arrow-down.svg';
import clsx from 'clsx';

export type DatePickerProps = {
  earliestDate?: Date;
  initialDate: Date;
  onChange: (selectedDate: Date) => void;
  upperLimit?: Date;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  earliestDate = generateEarliestDate(),
  initialDate,
  onChange,
  upperLimit,
}) => {
  // We don't use Date class here to avoid timezone issue
  // We can actually use UTC variant of the setter but
  // plain object is safer.
  const [selectedDate, setSelectedDate] = useState<{
    year: number;
    monthIndex: number;
    date: number;
  }>({
    year: initialDate.getFullYear(),
    monthIndex: initialDate.getMonth(),
    date: initialDate.getDate(),
  });

  useEffect(() => {
    onChange(new Date(selectedDate.year, selectedDate.monthIndex, selectedDate.date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const upperLimitDate = useMemo(() => upperLimit ?? new Date(), [upperLimit]);

  const handleClickPreviousMonthButton = useCallback(() => {
    const prevMonthIndex = selectedDate.monthIndex - 1;
    const newMonthIndex = prevMonthIndex < 0 ? 11 : prevMonthIndex;

    const newYear = prevMonthIndex < 0 ? selectedDate.year - 1 : selectedDate.year;

    if (
      newYear < earliestDate.getFullYear() ||
      (newYear === earliestDate.getFullYear() && newMonthIndex < earliestDate.getMonth())
    ) {
      return;
    }

    setSelectedDate(({ date }) => ({
      year: newYear,
      monthIndex: newMonthIndex,
      date: Math.min(date, getNumberOfDays(newMonthIndex, newYear)),
    }));
  }, [earliestDate, selectedDate.monthIndex, selectedDate.year]);

  const handleChangeYear: ChangeEventHandler<HTMLSelectElement> = useCallback((event) => {
    const newYear = +event.target.value;

    setSelectedDate(({ monthIndex, date }) => {
      const currentYear = new Date().getFullYear();
      const newMonthIndex =
        currentYear === newYear ? Math.min(monthIndex, new Date().getMonth()) : monthIndex;

      return {
        year: newYear,
        monthIndex: newMonthIndex,
        date: Math.min(date, getNumberOfDays(newMonthIndex, newYear)),
      };
    });
  }, []);

  const handleClickDate: MouseEventHandler<HTMLTableCellElement> = useCallback(
    (event) => {
      const dataset = event.currentTarget.dataset;
      const yearData = dataset.year;
      const monthIndexData = dataset.monthIndex;
      const dateData = dataset.date;

      if (yearData && monthIndexData && dateData) {
        const year = +yearData;
        const monthIndex = +monthIndexData;
        const date = +dateData;

        if (!isNaN(year) && !isNaN(monthIndex) && !isNaN(date)) {
          if (!isDateGreaterThan(new Date(year, monthIndex, date), upperLimitDate)) {
            setSelectedDate((selectedDate) => ({ ...selectedDate, date }));
          }
        }
      }
    },
    [upperLimitDate]
  );

  const availableYears = useMemo(() => {
    const maxYear = upperLimitDate.getFullYear();

    const ret: number[] = [];

    for (let year = maxYear; year >= earliestDate.getFullYear(); year--) {
      ret.push(year);
    }

    return ret;
  }, [earliestDate, upperLimitDate]);

  const monthName = useMemo(() => {
    return getMonthName(selectedDate.monthIndex);
  }, [selectedDate]);

  const calendarRows = useMemo(() => {
    return getCalendarRows(selectedDate.monthIndex, selectedDate.year);
  }, [selectedDate]);

  const shouldEnablePreviousMonthButton = useMemo(
    () =>
      !(
        selectedDate.year === earliestDate.getFullYear() &&
        selectedDate.monthIndex === earliestDate.getMonth()
      ),
    [earliestDate, selectedDate.monthIndex, selectedDate.year]
  );

  const handleClickNextMonthButton = useCallback(() => {
    const maxYear = upperLimitDate.getFullYear();
    const currentMonth = upperLimitDate.getMonth();
    const nextMonthIndex = selectedDate.monthIndex + 1;
    const newMonthIndex = nextMonthIndex > 11 ? 0 : nextMonthIndex;

    const newYear = nextMonthIndex > 11 ? selectedDate.year + 1 : selectedDate.year;

    if ((newYear === maxYear && newMonthIndex > currentMonth) || newYear > maxYear) {
      return;
    }

    setSelectedDate(({ date }) => {
      return {
        year: newYear,
        monthIndex: newMonthIndex,
        date: Math.min(date, getNumberOfDaysUpToNow(newMonthIndex, newYear)),
      };
    });
  }, [selectedDate.monthIndex, selectedDate.year, upperLimitDate]);

  const shouldEnableNextMonthButton = useMemo(() => {
    return !(
      selectedDate.year === upperLimitDate.getFullYear() &&
      selectedDate.monthIndex === upperLimitDate.getMonth()
    );
  }, [selectedDate.monthIndex, selectedDate.year, upperLimitDate]);

  return (
    <div className={styles.datePicker}>
      <div className={styles.datePickerHeader}>
        <div className={styles.monthPicker}>
          {shouldEnablePreviousMonthButton ? (
            <button onClick={handleClickPreviousMonthButton}>
              <IconArrowLeft />
            </button>
          ) : (
            <button disabled>
              <IconArrowLeftDisabled />
            </button>
          )}
          {monthName}
          {shouldEnableNextMonthButton ? (
            <button onClick={handleClickNextMonthButton}>
              <IconArrowRight />
            </button>
          ) : (
            <button disabled>
              <IconArrowRightDisabled />
            </button>
          )}
        </div>
        <div className={styles.yearPicker}>
          <select value={String(selectedDate.year)} onChange={handleChangeYear}>
            {availableYears.map((year) => (
              <option key={year} value={String(year)}>
                {year}
              </option>
            ))}
          </select>
          <IconArrowDownSmall />
        </div>
      </div>
      <div className={styles.datePickerBody}>
        <div className={styles.dateCalendarPicker}>
          <table>
            <thead>
              <tr>
                <th>
                  <span>
                    <abbr title="Sunday">Sun</abbr>
                  </span>
                </th>
                <th>
                  <span>
                    <abbr title="Monday">Mon</abbr>
                  </span>
                </th>
                <th>
                  <span>
                    <abbr title="Tuesday">Tue</abbr>
                  </span>
                </th>
                <th>
                  <span>
                    <abbr title="Wednesday">Wed</abbr>
                  </span>
                </th>
                <th>
                  <span>
                    <abbr title="Thursday">Thu</abbr>
                  </span>
                </th>
                <th>
                  <span>
                    <abbr title="Friday">Fri</abbr>
                  </span>
                </th>
                <th>
                  <span>
                    <abbr title="Saturday">Sat</abbr>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {calendarRows.map((calendarRow, index) => (
                <tr key={index}>
                  {calendarRow.map((calendarColumn, index) => (
                    <td
                      key={index}
                      className={clsx({
                        [styles.disabled]:
                          !calendarColumn.isInMonth ||
                          isDateGreaterThan(
                            new Date(
                              calendarColumn.year,
                              calendarColumn.monthIndex,
                              calendarColumn.date
                            ),
                            upperLimitDate
                          ),
                        [styles.selected]:
                          calendarColumn.isInMonth &&
                          calendarColumn.year === selectedDate.year &&
                          calendarColumn.monthIndex === selectedDate.monthIndex &&
                          calendarColumn.date === selectedDate.date,
                      })}
                      onClick={calendarColumn.isInMonth ? handleClickDate : undefined}
                      data-year={String(calendarColumn.year)}
                      data-month-index={String(calendarColumn.monthIndex)}
                      data-date={String(calendarColumn.date)}
                    >
                      <span>{calendarColumn.date}</span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const getMonthName = (monthIndex: number) => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][monthIndex];
};

const getNumberOfDays = (monthIndex: number, year: number) => {
  return new Date(year, monthIndex + 1, 0).getDate();
};

const getNumberOfDaysUpToNow = (monthIndex: number, year: number) => {
  const today = new Date();

  if (monthIndex === today.getMonth()) {
    return today.getDate();
  }

  return getNumberOfDays(monthIndex, year);
};

const getStartDay = (monthIndex: number, year: number) => {
  return new Date(year, monthIndex, 1).getDay();
};

type CalendarColumn = {
  year: number;
  monthIndex: number;
  date: number;
  isInMonth: boolean;
};

const getCalendarRows = (monthIndex: number, year: number): CalendarColumn[][] => {
  const startDay = getStartDay(monthIndex, year);
  const numberOfDaysInMonth = getNumberOfDays(monthIndex, year);
  const numberOfDaysInPreviousMonth = getNumberOfDays(monthIndex - 1, year);

  const NUMBER_DAYS_IN_A_WEEK = 7;

  let row = 0;

  const rows: CalendarColumn[][] = [];

  const addColumn = (column: CalendarColumn) => {
    const numberOfColumnsInRow = rows[row]?.length ?? 0;

    if (numberOfColumnsInRow === NUMBER_DAYS_IN_A_WEEK) {
      row++;
    }

    rows[row] = (rows[row] ?? []).concat(column);
  };

  for (let i = 0; i < startDay; i++) {
    const date = numberOfDaysInPreviousMonth - (startDay - 1) + i;
    addColumn({ year, monthIndex, date, isInMonth: false });
  }

  for (let i = 1; i <= numberOfDaysInMonth; i++) {
    addColumn({ year, monthIndex, date: i, isInMonth: true });
  }

  if (rows[row].length < NUMBER_DAYS_IN_A_WEEK) {
    const remainingColumns = NUMBER_DAYS_IN_A_WEEK - rows[row].length;

    for (let i = 1; i <= remainingColumns; i++) {
      addColumn({ year, monthIndex, date: i, isInMonth: false });
    }
  }

  return rows;
};

const isDateGreaterThan = (date: Date, upperLimit: Date) => {
  const startOfTomorrow = new Date(new Date(upperLimit).setHours(0, 0, 0, 0));
  startOfTomorrow.setDate(startOfTomorrow.getDate() + 1);

  return date.getTime() >= startOfTomorrow.getTime();
};

const generateEarliestDate = () => {
  return new Date(new Date().getFullYear() - 4, 0, 1);
};
