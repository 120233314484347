import { createContext } from 'react';

export type EmitterDetailsBarContextType = {
  isSectionPopupDisabled: boolean;
  handleEnableSectionPopup: () => void;
};

export const EmitterDetailsBarContext = createContext<EmitterDetailsBarContextType>({
  isSectionPopupDisabled: false,
  handleEnableSectionPopup: () => {},
});
