import ContentLoader from 'react-content-loader';

export const FleetNavigatorLoader: React.FC = () => (
  <ContentLoader
    speed={2}
    width={185}
    height={180}
    viewBox="0 0 185 180"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="23" cy="66" r="3" />
    <circle cx="23" cy="101" r="3" />
    <circle cx="23" cy="136" r="3" />
    <circle cx="23" cy="171" r="3" />
    <path d="M 20 14 h 68 v 15 H 20 z" />
    <path d="M 20 39 h 165 v 1 H 20 z" />
    <path d="M 32 56 h 137 v 19 H 32 z M 32 91 h 137 v 19 H 32 z M 32 126 h 137 v 19 H 32 z M 32 161 h 137 v 19 H 32 z" />
  </ContentLoader>
);
