import styles from './CreateUserModal.module.scss';
import { Modal } from 'Components/Modal';
import { Button } from 'Components/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CreateUserArguments, useCreateUserMutation } from 'Services/redux/api/users';
import { useRolesAndNamespaces } from 'Hooks/data/useRolesAndNamespaces';
import { SingleValue } from 'react-select';
import { SelectsRow } from './SelectsRow';
import { PuffLoader } from 'react-spinners';
import { User } from 'Models/user';
import { CompliantPopup } from 'Components/CompliantPopup';
import { Dialog, DialogDetails } from 'Components/Dialog';
import { capitalize } from 'Helpers/capitalize';
import { checkPassword, isEmailFormat } from 'Helpers/validator';
import {
  Option,
  OptionWithId,
  selectIdsTransformer,
  SelectInput,
  selectOptionIdTransformer,
} from 'Components/SelectInput';
import { useToastPresenter } from 'Components/Toaster';

type Role = {
  name: string;
  namespace: string;
};

type Roles = {
  [identifier: string]: Role;
};

type CreateUserModalProps = {
  onClose: () => void;
  onSave: (savedUser: User) => void;
  currentTab: string;
  currentUserNamespace: string;
};

export const CreateUserModal: React.FC<CreateUserModalProps> = ({
  onClose,
  onSave,
  currentTab,
  currentUserNamespace,
}) => {
  const toastPresenter = useToastPresenter();

  const { namespacesOptions, rolesOptions, isLoading, isError } = useRolesAndNamespaces();
  const [createUser] = useCreateUserMutation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [namespace, setNamespace] = useState(currentTab);

  const [isPasswordFieldOnFocus, setIsPasswordFieldOnFocus] = useState(false);

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [isOnSubmit, setIsOnSubmit] = useState(false);

  const [selectComponents, setSelectComponents] = useState<JSX.Element[]>([]);

  const [roles, setRoles] = useState<Roles>();

  const [dialogDetails, setDialogDetails] = useState<DialogDetails>();

  const passwordDetails = useMemo(() => {
    const result = checkPassword(password);
    return {
      result,
      isComplete: !result?.find((result) => !result.level),
    };
  }, [password]);

  const [showsEmailInUseError, setShowsEmailInUseError] = useState(false);

  const emailErrorMessage = useMemo(() => {
    if (showsEmailInUseError) {
      return 'Email is already in use';
    }

    if (email && !isEmailFormat(email)) return 'Invalid email address';
  }, [email, showsEmailInUseError]);

  const passwordErrorMessage = useMemo(() => {
    if (password && !passwordDetails.isComplete) return 'Password too weak';
  }, [password, passwordDetails.isComplete]);

  const confirmPasswordErrorMessage = useMemo(() => {
    if (password && !confirmPassword) {
      return 'Required';
    } else if (password !== confirmPassword) return 'Password does not match';
  }, [password, confirmPassword]);

  const handleOnFocusPasswordField = useCallback(() => {
    setIsPasswordFieldOnFocus(true);
  }, []);

  const handleOnBlurPasswordField = useCallback(() => {
    setIsPasswordFieldOnFocus(false);
  }, []);

  const handleChangeSelectValue = useCallback((selectedOption: SingleValue<Option>) => {
    if (!selectedOption) return;

    setNamespace(selectedOption.value);
  }, []);

  const handleChangeFirstName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  }, []);

  const handleChangeLastName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  }, []);

  const handleChangeEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setShowsEmailInUseError(false);
    setEmail(e.target.value);
  }, []);

  const handleChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value.replace(/\s/g, ''));
  }, []);

  const handleChangeConfirmPassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value.replace(/\s/g, ''));
  }, []);

  const handleOnOptionChange = (selectedOption: SingleValue<OptionWithId>) => {
    if (!selectedOption) return;

    const selectedRole = {
      name: selectedOption.value,
      namespace: selectedOption.id,
    };

    setRoles((prevRoles) => {
      return {
        ...prevRoles,
        [selectedRole.namespace]: selectedRole,
      };
    });
  };

  const handleDisableNamespace = (namespace: string) => {
    setRoles((prevRoles) => {
      const currentRoles = { ...prevRoles };

      delete currentRoles[namespace];

      return currentRoles;
    });
  };

  const handleSubmit = useCallback(() => {
    if (!namespace) return;

    const createUserArguments: CreateUserArguments = {
      body: {
        email,
        firstName,
        lastName,
        namespace,
        password,
        roles: (roles && Object.values(roles)) ?? [],
      },
      params: {
        skipPasswordEmail: !!password,
      },
    };

    (async (): Promise<void> => {
      setIsOnSubmit(true);

      try {
        const user = await createUser(createUserArguments).unwrap();

        const savedUser: User = {
          id: user.id,
          altId: user.altId,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          namespace: user.namespace,
          roles: user.roles,
          createdAt: user.createdAt,
          updatedAt: user.createdAt,
        };

        onSave(savedUser);
      } catch (error) {
        const data = (error as any)['data'] as Record<string, any> | undefined;

        if (data && data.code === 'EMAIL_IN_USE') {
          setShowsEmailInUseError(true);
          setIsOnSubmit(false);
        } else {
          setIsOnSubmit(false);
          onClose();
          toastPresenter.present({
            level: 'danger',
            title: 'Something went wrong. Please try again',
          });
        }
      }
    })();
  }, [
    namespace,
    email,
    firstName,
    lastName,
    password,
    roles,
    createUser,
    onSave,
    onClose,
    toastPresenter,
  ]);

  const handleCheckSubmit = useCallback(() => {
    if (!roles || !Object.keys(roles).length) {
      setDialogDetails({
        level: 'info',
        title: 'Create user without a role?',
        message: 'This user will not be able to see anything in their dashboard.',
        cancelButtonTitle: 'Cancel',
        submitButtonTitle: 'Create user',
      });
      return;
    }

    if (namespace && Object.keys(roles).find((role) => role !== namespace)) {
      let rolesValues = Object.values(roles)
        .filter((role) => role.namespace !== namespace)
        .map((role) => capitalize(role.name) + ' : ' + capitalize(role.namespace));

      const rolesDescription =
        rolesValues.length > 3
          ? rolesValues.slice(0, 3).join(' , ') + ' ...'
          : rolesValues.join(' , ');

      setDialogDetails({
        level: 'info',
        title: 'Allow access outside namespace?',
        message: `This user will be able to access data outside of their namespace. Users’s namespace is ${capitalize(
          namespace
        )} assigned role is ${rolesDescription}`,
        cancelButtonTitle: 'Cancel',
        submitButtonTitle: 'Create user',
      });

      return;
    }

    handleSubmit();
  }, [handleSubmit, roles, namespace]);

  const handleCancelSubmit = useCallback(() => {
    setDialogDetails(undefined);
  }, []);

  const handleKeepOnSubmit = useCallback(() => {
    setDialogDetails(undefined);
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    if (isError || !namespacesOptions || !rolesOptions) return;

    const namespacesOptionsFilter =
      currentUserNamespace !== 'sailplan'
        ? namespacesOptions.filter(
            (namespaceOption) => namespaceOption.value === currentUserNamespace
          )
        : namespacesOptions;

    const selectElements = namespacesOptionsFilter.map((option, index) => (
      <SelectsRow
        namespaceDisplay={option.label}
        namespaceCode={option.value}
        rolesOptions={selectIdsTransformer(rolesOptions, option.value)}
        onOptionChange={handleOnOptionChange}
        onDisableNamespace={handleDisableNamespace}
        menuPlacement="top"
        key={index}
      />
    ));

    namespacesOptions.length === 1 && handleChangeSelectValue(namespacesOptions[0]);

    setSelectComponents(selectElements);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namespacesOptions, rolesOptions]);

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      namespace &&
      !emailErrorMessage &&
      !passwordErrorMessage &&
      !confirmPasswordErrorMessage
    ) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  }, [
    firstName,
    lastName,
    email,
    namespace,
    roles,
    emailErrorMessage,
    passwordErrorMessage,
    confirmPasswordErrorMessage,
  ]);

  const defaultOwnerOption = useMemo(
    () => namespacesOptions?.find((namespaceOption) => namespaceOption.value === namespace),
    [namespacesOptions, namespace]
  );

  return (
    <>
      <Modal
        title="Create new user"
        subtitle="Fill out the information below"
        body={
          <div className={styles.body}>
            <div className={styles.rowInput}>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>First Name</span>
                  </div>
                </div>
                <input
                  type="text"
                  value={firstName}
                  onChange={handleChangeFirstName}
                  autoComplete="off"
                />
              </div>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Last Name</span>
                  </div>
                </div>
                <input
                  type="text"
                  value={lastName}
                  onChange={handleChangeLastName}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className={styles.rowInput}>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Email</span>
                  </div>
                  <span className={styles.errorMessage}>{emailErrorMessage}</span>
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={handleChangeEmail}
                  autoComplete="off"
                  className={showsEmailInUseError ? 'error' : undefined}
                />
              </div>
              <div className={styles.select}>
                {!isLoading && namespacesOptions ? (
                  <>
                    <p className={styles.rowSelectTitle}>Owner</p>
                    <SelectInput
                      value={
                        namespace && defaultOwnerOption
                          ? selectOptionIdTransformer(defaultOwnerOption)
                          : null
                      }
                      options={selectIdsTransformer(namespacesOptions)}
                      onChange={handleChangeSelectValue}
                      placeholder="Select owner"
                    />
                  </>
                ) : (
                  <div className={styles.loadingSelect}>
                    <PuffLoader color="#44ABDF" size={40} />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.rowInput}>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Password</span>
                    <span className={styles.optional}> - Optional</span>
                  </div>
                  <span className={styles.errorMessage}>{passwordErrorMessage}</span>
                </div>
                <input
                  type="password"
                  value={password}
                  onChange={handleChangePassword}
                  onFocus={handleOnFocusPasswordField}
                  onBlur={handleOnBlurPasswordField}
                  autoComplete="new-password"
                />
                {!passwordDetails.isComplete &&
                  passwordDetails.result &&
                  isPasswordFieldOnFocus && <CompliantPopup items={passwordDetails.result} />}
              </div>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Confirm Password</span>
                    {!password && !confirmPasswordErrorMessage && (
                      <span className={styles.optional}> - Optional</span>
                    )}
                  </div>
                  <span className={styles.errorMessage}>{confirmPasswordErrorMessage}</span>
                </div>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={handleChangeConfirmPassword}
                  autoComplete="new-password"
                />
              </div>
            </div>

            {!isLoading && namespacesOptions && rolesOptions ? (
              <div className={styles.selectRoles}>
                <p className={styles.selectRolesTitle}>Roles</p>
                <div className={styles.selectComponents}>{selectComponents}</div>
              </div>
            ) : (
              <div className={styles.loadingSelect}>
                <PuffLoader color="#44ABDF" size={40} />
              </div>
            )}
          </div>
        }
        footer={
          <div className={styles.controlBar}>
            <Button
              onClick={handleCheckSubmit}
              isLoading={isOnSubmit}
              disabled={isSubmitButtonDisabled}
            >
              Create
            </Button>
          </div>
        }
        onClose={onClose}
        disableBody={isOnSubmit}
      />
      {dialogDetails && (
        <Dialog
          {...dialogDetails}
          onSelectCancel={handleCancelSubmit}
          onSelectSubmit={handleKeepOnSubmit}
        />
      )}
    </>
  );
};
