import styles from './IntervalSelector.module.scss';
import { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import { AbsoluteInterval, DynamicInterval, Interval } from 'Constants';
import moment from 'moment';
import clsx from 'clsx';
import { DateTimeRangePickerInput } from 'Components/DateTimeRangePickerInput';

const OPTIONS = [
  { interval: DynamicInterval.Last30Minutes, label: 'Last 30 minutes' },
  { interval: DynamicInterval.Last1Hour, label: 'Last 1 hour' },
  { interval: DynamicInterval.Last12Hours, label: 'Last 12 hours' },
  { interval: DynamicInterval.Last24Hours, label: 'Last 24 hours' },
  { interval: DynamicInterval.Last2Days, label: 'Last 2 days' },
  { interval: DynamicInterval.Last10Days, label: 'Last 10 days' },
];

export type IntervalSelectorProps = {
  selectedInterval: Interval;
  onSelectOption: (interval: Interval) => void;
  onSelectReset: () => void;
};

export const IntervalSelector: React.FC<IntervalSelectorProps> = ({
  selectedInterval,
  onSelectOption,
  onSelectReset,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const [customStartDate, setCustomStartDate] = useState<Date>();
  const [customEndDate, setCustomEndDate] = useState<Date>();

  const customStartDateError = useMemo(() => {
    if (customStartDate && customEndDate && customStartDate.getTime() >= customEndDate.getTime()) {
      return 'Start date cannot be after the end date';
    }

    if (customEndDate && !customStartDate) {
      return 'Start date is required';
    }
  }, [customEndDate, customStartDate]);

  const customEndDateError = useMemo(() => {
    if (customStartDate && customEndDate && customStartDate.getTime() >= customEndDate.getTime()) {
      return 'End date cannot be before the start date';
    }
  }, [customStartDate, customEndDate]);

  const shouldShowNoRealtimeDataInfo = useMemo(() => {
    return !!customStartDate && !!customEndDate && !customStartDateError && !customEndDateError;
  }, [customEndDate, customEndDateError, customStartDate, customStartDateError]);

  useEffect(() => {
    setCustomStartDate(getCustomStartDate(selectedInterval));
    setCustomEndDate(getCustomEndDate(selectedInterval));
  }, [selectedInterval]);

  const title = useMemo(
    () =>
      typeof selectedInterval === 'string'
        ? (OPTIONS.find((o) => o.interval === selectedInterval)?.label as string)
        : selectedInterval.earliest && selectedInterval.latest
        ? `${moment(new Date(selectedInterval.earliest)).format('MMM D YYYY, HH:mm')} - ${moment(
            new Date(selectedInterval.latest)
          ).format('MMM D YYYY, HH:mm')}`
        : selectedInterval.earliest
        ? `Since ${moment(new Date(selectedInterval.earliest)).format('MMM D YYYY, HH:mm')}`
        : '', // Should not happen
    [selectedInterval]
  );

  useEffect(() => {
    onSelectOption(selectedInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInterval]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const container = containerRef.current;

      // This is a temporary fix, we will revisit later
      const portal = document.getElementById('portal');

      if (
        !container ||
        !(e.target instanceof Element) ||
        container.contains(e.target) ||
        portal?.contains(e.target)
      ) {
        return;
      }

      setIsExpanded(false);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickControl = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleClickOption: MouseEventHandler = useCallback(
    (event) => {
      const target = event.target as HTMLElement;

      const interval = target.dataset.interval as DynamicInterval;

      onSelectOption(interval);
      setIsExpanded(false);
    },
    [onSelectOption]
  );

  const handleChangeTimeRangePickerInput = useCallback(
    (interval: AbsoluteInterval) => {
      setCustomStartDate(new Date(interval.earliest));
      setCustomEndDate(new Date(interval.latest));

      onSelectOption(interval);
    },
    [onSelectOption]
  );

  const dateRangeInputInterval: AbsoluteInterval | undefined = useMemo(() => {
    return (
      customStartDate &&
      customEndDate && {
        earliest: customStartDate.toISOString(),
        latest: customEndDate.toISOString(),
      }
    );
  }, [customStartDate, customEndDate]);

  return (
    <div ref={containerRef} className={styles.intervalSelector}>
      <div className={styles.control} onClick={handleClickControl}>
        {title} <IconArrowDown style={{ transform: isExpanded ? 'rotate(180deg)' : undefined }} />
      </div>
      {isExpanded && (
        <div className={styles.menu}>
          <div className={styles.menuContent}>
            {OPTIONS.map((option) => (
              <div
                key={option.interval}
                data-interval={option.interval}
                className={clsx(styles.option, {
                  [styles.selected]: option.interval === selectedInterval,
                })}
                onClick={handleClickOption}
              >
                {option.label}
              </div>
            ))}
            <div
              className={clsx(styles.customDateRangeOption, {
                [styles.selected]: typeof selectedInterval === 'object',
              })}
            >
              <div className={styles.customDateRangeOptionHeader}>Custom range</div>
              <div className="form-control">
                <DateTimeRangePickerInput
                  maxDate={new Date()}
                  interval={dateRangeInputInterval}
                  placeholder="Select date range"
                  className="expanded"
                  onChange={handleChangeTimeRangePickerInput}
                />
              </div>
              {shouldShowNoRealtimeDataInfo && (
                <div className={styles.noRealtimeDataInfo}>
                  Realtime data is not shown when viewing a fixed interval of time.
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function getCustomStartDate(interval: Interval): Date | undefined {
  return typeof interval === 'object' ? new Date(interval.earliest) : undefined;
}

function getCustomEndDate(interval: Interval): Date | undefined {
  return typeof interval === 'object'
    ? interval.latest
      ? new Date(interval.latest)
      : undefined
    : undefined;
}
