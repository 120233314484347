import { useEffect, useMemo } from 'react';
import { SensorName } from 'Constants';
import { getFirstPropertyWithName, getFirstSensorWithName } from 'Helpers/sensors';
import { Property, Vessel } from 'Models';
import { RotationPropertiesByVessel } from '../interfaces';
import { useNodeServerObserver } from 'Networking/socket';
import { Degrees } from 'Interfaces';
import { resolveEmitterRotation } from '../helpers';

export const useObserveVesselRotation = (
  vessels: Vessel[],
  listener: (rotation: Degrees, timestamp: string, vessel: Vessel) => void
) => {
  const vesselsRotationProperties = useMemo(() => getVesselsRotationProperties(vessels), [vessels]);

  const requiredProperties = useMemo(
    () =>
      Object.values(vesselsRotationProperties).reduce<Property[]>(
        (acc, vrp) => acc.concat(Object.values(vrp).filter((p) => !!p)),
        []
      ),
    [vesselsRotationProperties]
  );

  const observer = useNodeServerObserver();

  useEffect(() => {
    if (!Object.keys(vesselsRotationProperties).length) {
      return;
    }

    const subscription = observer.subscribe(requiredProperties, (container) => {
      for (const vessel of vessels) {
        const rotationProperties = vesselsRotationProperties[vessel.id];

        if (rotationProperties) {
          const systemTime = container.getValueForProperty(rotationProperties.systemTimeProperty);

          if (typeof systemTime === 'string') {
            const sog =
              rotationProperties.sogProperty &&
              container.getValueForProperty(rotationProperties.sogProperty);

            const cog =
              rotationProperties.cogProperty &&
              container.getValueForProperty(rotationProperties.cogProperty);

            const trueHeading = vessel.ais?.trueHeading;

            const rotation = resolveEmitterRotation({
              sog: typeof sog === 'number' ? sog : undefined,
              cog: typeof cog === 'number' ? cog : undefined,
              trueHeading: typeof trueHeading === 'number' ? trueHeading : undefined,
            });

            if (typeof rotation === 'number') {
              listener(rotation, systemTime, vessel);
            }
          }
        }
      }
    });

    return () => observer.unsubscribe(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vessels, vesselsRotationProperties, observer, requiredProperties]);
};

function getVesselsRotationProperties(vessels: Vessel[]) {
  return vessels.reduce<RotationPropertiesByVessel>((acc, emitter) => {
    const gpsSensor = getFirstSensorWithName(SensorName.GPS, emitter);
    const aisSensor = getFirstSensorWithName(SensorName.AISPositional, emitter);

    const sogProperty =
      gpsSensor && getFirstPropertyWithName(SensorName.GPS.PropertyName.SOG, gpsSensor);
    const cogProperty =
      gpsSensor && getFirstPropertyWithName(SensorName.GPS.PropertyName.COG, gpsSensor);
    const trueHeadingProperty =
      aisSensor &&
      getFirstPropertyWithName(SensorName.AISPositional.PropertyName.TrueHeading, aisSensor);
    const systemTimeProperty =
      gpsSensor && getFirstPropertyWithName(SensorName.GPS.PropertyName.SystemTime, gpsSensor);

    if (systemTimeProperty) {
      acc[emitter.id] = {
        sogProperty,
        cogProperty,
        trueHeadingProperty,
        systemTimeProperty,
      };
    }

    return acc;
  }, {});
}
