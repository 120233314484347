import styles from './DateTimeRangePickerInput.module.scss';
import React, { useCallback, useMemo, useState } from 'react';
import { AbsoluteInterval } from 'Constants';
import { DateTimeRangePickerModal } from 'Components/DateTimeRangePickerModal/DateTimeRangePickerModal';
import { DateRange } from 'Components/DateTimeRangePickerModal/types';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import moment from 'moment';
import clsx from 'clsx';

const DATE_FORMAT = 'D/M/YYYY, HH:mm';

type DateTimeRangePickerInputProps = {
  interval?: AbsoluteInterval;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  onChange: (interval: AbsoluteInterval) => void;
};

export const DateTimeRangePickerInput: React.FC<DateTimeRangePickerInputProps> = React.memo(
  ({ interval, minDate, maxDate, placeholder, disabled, error, className, onChange }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleClickInput = useCallback(() => setIsModalVisible(true), []);
    const handleCloseModal = useCallback(() => setIsModalVisible(false), []);

    const handleSubmitModal = useCallback(
      (dateRange: DateRange) => {
        const interval: AbsoluteInterval = {
          earliest: dateRange[0].toISOString(),
          latest: dateRange[1].toISOString(),
        };

        onChange(interval);

        setIsModalVisible(false);
      },
      [onChange]
    );

    const text = useMemo(() => {
      if (!interval) return undefined;

      return `${moment(interval.earliest).format(DATE_FORMAT)} - ${moment(interval.latest).format(
        DATE_FORMAT
      )}`;
    }, [interval]);

    const dateRange = useMemo(
      () =>
        interval
          ? ([new Date(interval.earliest), new Date(interval.latest)] as DateRange)
          : undefined,
      [interval]
    );

    return (
      <>
        <div
          className={clsx(
            styles.dateTimeRangePickerInput,
            disabled && styles.disabled,
            error && styles.error,
            className
          )}
          onClick={handleClickInput}
        >
          {text ? (
            text
          ) : placeholder ? (
            <span className={styles.placeholder}>{placeholder}</span>
          ) : (
            ''
          )}
          <IconArrowDown
            className={styles.expandIndicator}
            style={{ transform: isModalVisible ? 'rotate(180deg)' : undefined }}
          />
        </div>
        {isModalVisible && (
          <DateTimeRangePickerModal
            dateRange={dateRange}
            minDate={minDate}
            maxDate={maxDate}
            onSubmit={handleSubmitModal}
            onClose={handleCloseModal}
          />
        )}
      </>
    );
  }
);
