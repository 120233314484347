import React, { useEffect, useRef, useState } from 'react';

type UsePopupReturn = [boolean, () => void, () => void, React.RefObject<any>, React.RefObject<any>];

export const usePopup = (initial = false): UsePopupReturn => {
  const [isPopupVisible, setIsPopupVisible] = useState(initial);

  const popupRef = useRef<HTMLElement>(null);
  const toggleRef = useRef<HTMLElement>(null);

  const setPopupToVisible = () => {
    setIsPopupVisible(true);
  };

  const setPopupToNotVisible = () => {
    setIsPopupVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const popup = popupRef.current;
      const toggle = toggleRef.current;

      if (
        !popup ||
        !(e.target instanceof Element) ||
        popup.contains(e.target) ||
        (toggle && toggle.contains(e.target))
      ) {
        return;
      }

      setIsPopupVisible(false);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return [isPopupVisible, setPopupToVisible, setPopupToNotVisible, popupRef, toggleRef];
};
