import { StaticInterval } from 'Constants';
import { StyleSheet, Text, View, Svg, Rect, Path } from '@react-pdf/renderer';
import moment from 'moment';

type HeaderProps = {
  interval: StaticInterval;
  children: React.ReactNode;
};

const styles = StyleSheet.create({
  header: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: '13px',
    fontWeight: 600,
  },
});

export const Header: React.FC<HeaderProps> = ({ interval, children }) => {
  return (
    <View style={styles.header}>
      <View>
        <Svg width="110" height="12" viewBox="24 17 110 12">
          <Rect x="24" y="17" width="8" height="12" fill="#FFF500" />
          <Rect x="32" y="17" width="8" height="12" fill="#0093DD" />
          <Path
            d="M53.9037 21.887C54.3356 21.887 54.7342 22.0532 55.0665 22.3854C55.3987 22.7176 55.5648 23.0831 55.5648 23.5482V25.907C55.5648 26.4718 55.3655 26.9701 54.9668 27.402C54.5681 27.8007 54.0698 28 53.505 28H47.1263C46.5615 28 46.0631 27.8007 45.6312 27.402C45.1994 27.0033 45 26.505 45 25.907V24.9435H47.3588V25.4419C47.3588 25.5083 47.392 25.5415 47.4253 25.5748C47.4585 25.608 47.5249 25.6412 47.5582 25.6412H52.9734C53.0399 25.6412 53.0731 25.608 53.1396 25.5748C53.1728 25.5415 53.206 25.4751 53.206 25.4419V24.3455C53.206 24.2791 53.1728 24.2458 53.1396 24.2126C53.1063 24.1794 53.0399 24.1462 53.0067 24.1462H46.6279C46.196 24.1462 45.7974 23.9801 45.4651 23.6478C45.1329 23.3156 44.9668 22.9502 44.9668 22.485V20.093C44.9668 19.5282 45.1661 19.0299 45.5648 18.6312C46.0299 18.1993 46.5283 18 47.093 18H53.4718C54.0366 18 54.5349 18.1993 54.9336 18.598C55.3322 18.9967 55.5316 19.495 55.5316 20.0598V21.0233H53.1728V20.5581C53.1728 20.4917 53.1396 20.4585 53.1063 20.4252C53.0731 20.392 53.0067 20.3588 52.9402 20.3588H47.5582C47.4917 20.3588 47.4585 20.392 47.392 20.4252C47.392 20.4585 47.3588 20.5249 47.3588 20.5581V21.6877C47.3588 21.7542 47.392 21.7874 47.4253 21.8206C47.4585 21.8538 47.5249 21.887 47.5914 21.887H53.9037Z"
            fill="black"
          />
          <Path
            d="M65.3988 28L64.7011 26.4718H59.7177L59.02 28H56.5283L60.7808 18H63.7044L67.9237 28H65.3988ZM60.7476 24.2458H63.7376L62.2426 20.4585L60.7476 24.2458Z"
            fill="black"
          />
          <Path d="M72.0767 28H69.585V18H72.0767V28Z" fill="black" />
          <Path d="M76.7944 18V25.6412H82.7081V28H74.2695V18H76.7944Z" fill="black" />
          <Path
            d="M92.5085 18C93.0733 18 93.5716 18.1993 93.9703 18.598C94.369 18.9967 94.5683 19.495 94.5683 20.093V23.1495C94.5683 23.7143 94.369 24.2126 93.9703 24.6113C93.5716 25.01 93.0733 25.2093 92.5085 25.2093H86.9604V27.9668H84.4688V18H92.5085ZM92.2095 22.6512V20.5581C92.2095 20.4917 92.1764 20.4585 92.1432 20.392C92.1099 20.3588 92.0434 20.3256 92.0102 20.3256H86.9604V22.8173H92.0102C92.0766 22.8173 92.1099 22.7841 92.1432 22.7508C92.2096 22.7508 92.2095 22.7176 92.2095 22.6512Z"
            fill="black"
          />
          <Path d="M98.854 18V25.6412H104.801V28H96.3623V18H98.854Z" fill="black" />
          <Path
            d="M114.834 28L114.137 26.4718H109.153L108.456 28H105.964L110.216 18H113.14L117.359 28H114.834ZM110.183 24.2458H113.173L111.678 20.4585L110.183 24.2458Z"
            fill="black"
          />
          <Path
            d="M126.329 24.3455V18H128.82V28H125.863L121.478 21.4551V28H118.986V18H122.109L126.329 24.3455Z"
            fill="black"
          />
        </Svg>
      </View>
      <View>{children}</View>
      <View>
        <Text>
          {moment(interval.earliest).format('MMM Do')} {' - '}
          {moment(interval.latest).format('MMM Do YYYY')}
        </Text>
      </View>
    </View>
  );
};
