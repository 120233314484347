/**
 * DEPRECATED
 * Please use OptionPopup component instead.
 */
import styles from './SelectPopup.module.scss';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Highlighter from 'react-highlight-words';
import clsx from 'clsx';

type SelectPopupProps = {
  items: string[];
  onSelect: (index: number) => void;
  selectedItemIndex?: number;
  searchable?: boolean;
  cappedCount?: number;
  scrollableHeight?: number;
  selectedItemColor?: string;
  selectedItemBackground?: string;
  hoveredItemColor?: string;
  hoveredItemBackground?: string;
};

type ItemWithId = {
  name: string;
  id: number;
};

export const SelectPopup: React.FC<SelectPopupProps> = ({
  items,
  onSelect,
  selectedItemIndex,
  searchable = true,
  cappedCount = 5,
  scrollableHeight = 200,
  selectedItemColor = '#000',
  selectedItemBackground = '#C6ECFF',
  hoveredItemColor = '#000',
  hoveredItemBackground = '#f1f3f7',
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState<ItemWithId[]>();

  const itemsWithId = useMemo(
    () => items.map((item, index) => ({ name: item, id: index })),
    [items]
  );

  useEffect(() => {
    setFilteredItems(itemsWithId);
  }, [itemsWithId]);

  const handleOnSelectItem: MouseEventHandler<HTMLLIElement> = useCallback(
    (e) => {
      const menuIndexString = e.currentTarget.dataset.itemIndex;

      if (typeof menuIndexString !== 'string') return;

      const menuIndex = parseInt(menuIndexString);

      onSelect(menuIndex);
    },
    [onSelect]
  );

  const handleChangeSearchValue: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  useEffect(() => {
    setFilteredItems(
      itemsWithId.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const scrollableStyles = useMemo(() => {
    const height = cappedCount >= items.length ? 'max-content' : scrollableHeight + 'px';

    return {
      height,
      '--select-color': selectedItemColor,
      '--select-background': selectedItemBackground,
      '--hover-color': hoveredItemColor,
      '--hover-background': hoveredItemBackground,
    };
  }, [
    scrollableHeight,
    cappedCount,
    items.length,
    selectedItemBackground,
    selectedItemColor,
    hoveredItemBackground,
    hoveredItemColor,
  ]);

  return (
    <div className={clsx(styles.selectPopup, !searchable && styles.noSearchField)}>
      {searchable && (
        <div className={styles.searchField}>
          <input
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={handleChangeSearchValue}
          />
        </div>
      )}
      <ul className={styles.scrollableItems} style={scrollableStyles}>
        {filteredItems &&
          filteredItems.map((item) => (
            <li
              key={item.id}
              data-item-index={item.id}
              className={clsx(styles.item, selectedItemIndex === item.id && styles.selected)}
              onClick={handleOnSelectItem}
            >
              <Highlighter
                searchWords={[searchValue]}
                textToHighlight={item.name}
                highlightClassName={styles.highlight}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};
