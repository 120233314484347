import moment from 'moment';

export const formatValue = (
  value: number,
  unit: string | null | undefined,
  maximumFractionDigits: number
) => {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits,
  });

  return `${numberFormatter.format(value).replace(/^-0$/, '0')}${unit ? ' ' + unit : ''}`;
};

export const formatTimestamp = (timestamp: string) => {
  return moment(timestamp).format('MMMM DD YYYY, HH:mm');
};
