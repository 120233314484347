import styles from '../../StabilitySection.module.scss';
import { useEffect, useMemo } from 'react';
import { Property } from 'Models';
import { PitchGraphView } from './components';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import { generateTimelineBarDataInterval, getNewDataIndex } from 'Helpers/timeline';
import { useLatestObservedSample } from 'Networking/socket';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { fill, mergeSamples } from 'Helpers/sample';
import { useQuery } from '@tanstack/react-query';
import {
  FetchSampleSeriesQueryArgument,
  fetchLatestSample,
  fetchSampleSeries,
} from 'Networking/http';

type PitchColumnProps = {
  property: Property;
};

export const PitchColumn: React.FC<PitchColumnProps> = ({ property }) => {
  const { absoluteInterval, refresh } = useSelectedInterval();

  useEffect(refresh, [property, refresh]);

  const controlBarManager = useControlBarManager();

  const dataInterval = useMemo(() => {
    return generateTimelineBarDataInterval(
      absoluteInterval,
      controlBarManager.getTimelineBarSampleRateMs()
    );
  }, [absoluteInterval, controlBarManager]);

  const latestSampleQueryArg = { properties: [property], interval: dataInterval };
  const latestSampleQuery = useQuery(fetchLatestSample.generateQueryKey(latestSampleQueryArg), () =>
    fetchLatestSample(latestSampleQueryArg)
  );

  const sampleSeriesQueryArg: FetchSampleSeriesQueryArgument = {
    properties: [property],
    interval: dataInterval,
    aggregation: 'first',
    sample: {
      unit: 'second',
      rate: Math.floor(controlBarManager.getTimelineBarSampleRateMs() / 1000),
    },
    densify: true,
  };

  const sampleSeriesQuery = useQuery(fetchSampleSeries.generateQueryKey(sampleSeriesQueryArg), () =>
    fetchSampleSeries(sampleSeriesQueryArg)
  );

  const sample = latestSampleQuery.data;
  const observedSample = useLatestObservedSample([property]);

  const mergedSample = useMemo(
    () => mergeSamples(sample, observedSample),
    [sample, observedSample]
  );

  const viewMode = useViewMode();
  const { thumbPosition } = useTimelineThumb();

  const historicalSamples = useMemo(
    () => sampleSeriesQuery.data && fill(sampleSeriesQuery.data, 'forward'),
    [sampleSeriesQuery.data]
  );

  const historicalSample = useMemo(() => {
    const samples = historicalSamples;

    if (samples && samples.length) {
      const dataIndex = getNewDataIndex(
        thumbPosition,
        dataInterval,
        controlBarManager.getTimelineBarSampleRateMs()
      );
      const invertedIndex = Math.min(
        Math.max(samples.length - 1 - dataIndex, 0),
        samples.length - 1
      );
      return samples[invertedIndex];
    }

    return undefined;
  }, [controlBarManager, dataInterval, historicalSamples, thumbPosition]);

  const sampleToDisplay = useMemo(() => {
    return viewMode === 'replay' ? historicalSample : mergedSample;
  }, [historicalSample, mergedSample, viewMode]);

  const pitch = useMemo(() => {
    const value = sampleToDisplay?.[0];

    if (typeof value === 'number') {
      return Math.round(value);
    }

    return undefined;
  }, [sampleToDisplay]);

  return (
    <div className={styles.column}>
      <p className={styles.title}>Pitch</p>
      {typeof pitch === 'number' ? (
        <p className={styles.value}>
          {pitch} <span className={styles.unit}>°</span>
        </p>
      ) : (
        <p className={styles.noData}>n/a</p>
      )}
      <div className={styles.graph}>
        <PitchGraphView pitch={pitch} animate={viewMode !== 'replay'} />
      </div>
    </div>
  );
};
