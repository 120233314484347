export const LayerId = {
  vessel: 'vessel',
  dock: 'dock',
  ais: 'ais',
  positionHistory: 'position-history',
  emission: 'emission',
  aqi: 'aqi',
  eca: 'eca',
  alert: 'alert',
};
