export enum DynamicInterval {
  Last30Minutes = 'last30minutes',
  Last1Hour = 'last1hour',
  Last12Hours = 'last12hours',
  Last24Hours = 'last24hours',
  Last2Days = 'last2days',
  Last7Days = 'last7days',
  Last10Days = 'last10days',
  Last14Days = 'last14days',
  Last1Month = 'last1month',
  Last3Months = 'last3months',
}

export type StaticInterval = { earliest: string; latest?: string };
export type AbsoluteInterval = Required<StaticInterval>;

export type Interval = DynamicInterval | StaticInterval | AbsoluteInterval;

export const DEFAULT_INTERVAL: Interval = DynamicInterval.Last12Hours;
