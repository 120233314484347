import styles from './ShareEmitterAccessModal.module.scss';
import { useCallback, useMemo, useState } from 'react';
import { StaticInterval } from 'Constants';
import { Emitter, Property, User, UserAccess } from 'Models';
import { StepBar } from 'Components/StepBar';
import { Modal } from 'Components/Modal';
import { Button } from 'Components/Button';
import { StepEmail, StepInterval, StepProperties } from './components';
import { SubmitData } from './interfaces';
import { IShareEmitterContext, ShareEmitterContext } from './contexts';
import { CheckBox } from 'Components/CheckBox';
import { Option } from 'Components/Option';

type ShareEmitterAccessModalProps = {
  emitter: Emitter;
  userAccess?: UserAccess;
  header: string;
  submitButtonTitle: string;
  showsStepEmail?: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (data: SubmitData) => void;
};

export const ShareEmitterAccessModal: React.FC<ShareEmitterAccessModalProps> = ({
  emitter,
  userAccess,
  header,
  submitButtonTitle,
  showsStepEmail = true,
  isSubmitting = false,
  onClose,
  onSubmit,
}) => {
  const displayAllowReadAllPropertiesControl = useMemo(() => emitter.isManager, [emitter]);

  const [allowReadAllProperties, setAllowReadAllProperties] = useState(
    userAccess ? userAccess.isReadAll : false
  );

  const handleClickAllProperties = useCallback(() => {
    setAllowReadAllProperties((v) => !v);
  }, []);

  const steps = useMemo(
    () =>
      (
        [
          {
            id: 'email',
            title: 'Select Users',
            modalSubtitle: 'Fill out the information below',
            height: 440,
            show: showsStepEmail,
            component: <StepEmail emitter={emitter} />,
          },
          {
            id: 'interval',
            title: 'Select Dates',
            modalSubtitle: 'Fill out the information below',
            height: 480,
            show: true,
            component: <StepInterval userAccess={userAccess} />,
          },
          {
            id: 'properties',
            title: 'Select Properties',
            modalSubtitle: 'Assign users full or partial access to available data',
            height: 789,
            show: true,
            component: (
              <StepProperties
                emitter={emitter}
                userAccess={userAccess}
                selectAll={allowReadAllProperties}
              />
            ),
          },
        ] as const
      ).filter((step) => step.show),
    [emitter, allowReadAllProperties, showsStepEmail, userAccess]
  );

  const stepTitles = steps.map((step) => step.title);

  const [users, setUsers] = useState<User[]>([]);
  const [interval, setInterval] = useState<Partial<StaticInterval>>({
    earliest: undefined,
    latest: undefined,
  });
  const [noIntervalRestriction, setNoIntervalRestriction] = useState(false);
  const [properties, setProperties] = useState<Property[]>([]);

  const contextValue: IShareEmitterContext = useMemo(
    () => ({
      users,
      interval,
      noIntervalRestriction,
      properties,
      setUsers,
      setInterval,
      setNoIntervalRestriction,
      setProperties,
    }),
    [users, interval, noIntervalRestriction, properties]
  );

  const [stepIndex, setStepIndex] = useState(0);

  const handleClickBackButton = useCallback(() => {
    setStepIndex((stepIndex) => stepIndex - 1);
  }, []);

  const handleClickNextButton = useCallback(() => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
    } else {
      const normalizedInterval = noIntervalRestriction ? undefined : (interval as StaticInterval);
      onSubmit({
        users,
        interval: normalizedInterval,
        properties,
        readAll: allowReadAllProperties,
      });
    }
  }, [
    interval,
    noIntervalRestriction,
    onSubmit,
    properties,
    allowReadAllProperties,
    stepIndex,
    steps.length,
    users,
  ]);

  const shouldEnableNextButton = useMemo(() => {
    const currentStep = steps[stepIndex];

    switch (currentStep.id) {
      case 'email':
        return !!users.length;
      case 'interval':
        return (
          (interval.earliest &&
            interval.latest &&
            new Date(interval.earliest).getTime() <= new Date(interval.latest).getTime()) ||
          (interval.earliest && !interval.latest) ||
          noIntervalRestriction
        );
      case 'properties':
        return !!properties.length;
      default:
        const _exhaustiveCheck: never = currentStep;
        throw new Error(_exhaustiveCheck);
    }
  }, [steps, stepIndex, users, interval, noIntervalRestriction, properties]);

  const shouldDisableBody = useMemo(() => {
    return (
      isSubmitting ||
      (steps[stepIndex].id === 'properties' &&
        displayAllowReadAllPropertiesControl &&
        allowReadAllProperties)
    );
  }, [
    allowReadAllProperties,
    displayAllowReadAllPropertiesControl,
    isSubmitting,
    stepIndex,
    steps,
  ]);

  return (
    <Modal
      title={header}
      subtitle={steps[stepIndex].modalSubtitle}
      width={800}
      height={steps[stepIndex].height}
      disableCloseButton={isSubmitting}
      disableBody={shouldDisableBody}
      onClose={onClose}
      body={
        <div className={styles.shareEmitterAccessModalBody}>
          <ShareEmitterContext.Provider value={contextValue}>
            <StepBar titles={stepTitles} activeIndex={stepIndex} />
            <div>
              <div className={styles.stepContent}>{steps[stepIndex].component}</div>
            </div>
          </ShareEmitterContext.Provider>
        </div>
      }
      footer={
        <div className={styles.shareEmitterAccessModalBodyFooter}>
          <span className={styles.stepNumber}>
            Step {stepIndex + 1} of {steps.length}
          </span>
          <span className={styles.stepControl}>
            {steps[stepIndex].id === 'properties' && displayAllowReadAllPropertiesControl && (
              <Option
                label="Full access"
                className={styles.option}
                onClick={handleClickAllProperties}
              >
                <CheckBox checked={allowReadAllProperties} />
              </Option>
            )}
            {stepIndex === 0 ? (
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
            ) : (
              <Button color="secondary" onClick={handleClickBackButton} disabled={isSubmitting}>
                Back
              </Button>
            )}
            <Button
              onClick={handleClickNextButton}
              disabled={!shouldEnableNextButton}
              isLoading={isSubmitting}
            >
              {stepIndex < steps.length - 1 ? 'Next' : submitButtonTitle}
            </Button>
          </span>
        </div>
      }
    />
  );
};
