import styles from '../../LayerSelector.module.scss';
import { RootState, useAppDispatch, useAppSelector } from 'Services/redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Option } from 'Components/Option';
import { RadioButton } from 'Components/RadioButton';

type RadioButtonOptionProps<T> = {
  identifier: T | undefined;
  label: string;
  selectedIdentifierSelector: (state: RootState) => T | undefined;
  selectIdentifierAction: ActionCreatorWithPayload<T | undefined>;
};

export function RadioButtonOption<T>({
  identifier,
  label,
  selectedIdentifierSelector,
  selectIdentifierAction,
}: RadioButtonOptionProps<T>) {
  const dispatch = useAppDispatch();

  const selectedIdentifier = useAppSelector(selectedIdentifierSelector);

  const handleClickOption = () => {
    dispatch(selectIdentifierAction(identifier));
  };

  return (
    <Option label={label} className={styles.option} onClick={handleClickOption}>
      <RadioButton selected={selectedIdentifier === identifier} />
    </Option>
  );
}
