import { useEffect, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import { MapboxInterpolateHeatmapLayer } from 'mapbox-gl-interpolate-heatmap';
import { Dock } from 'Models';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import {
  calculatePositionDate,
  generateTimelineBarDataInterval,
  generateTimelineBarSampleRateMs,
  getDataIndex,
} from 'Helpers/timeline';
import { useAqiSampleSeriesByDock } from './hooks';
import { useSyncedLatestAqiSampleByDock } from './hooks/useSyncedLatestAqiSampleByDock';
import { SensorName } from 'Constants';
import { AvailableMapAqiName, setIsLoadingAqiLayer } from 'Services/redux/map';
import { MapLayerProps } from '../../../interfaces';
import { useAppDispatch } from 'Services/redux';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';

const MASK: MapboxInterpolateHeatmapLayer['data'] = [
  { lat: 29.06, lon: -90.26, val: -100 },
  { lat: 29.06, lon: -90.14, val: -100 },
  { lat: 29.08, lon: -90.26, val: -100 },
  { lat: 29.08, lon: -90.14, val: -100 },
  { lat: 29.1, lon: -90.26, val: -100 },
  { lat: 29.1, lon: -90.14, val: -100 },
  { lat: 29.12, lon: -90.26, val: -100 },
  { lat: 29.12, lon: -90.14, val: -100 },
  { lat: 29.14, lon: -90.26, val: -100 },
  { lat: 29.14, lon: -90.14, val: -100 },
  { lat: 29.16, lon: -90.26, val: -100 },
  { lat: 29.16, lon: -90.14, val: -100 },
  { lat: 29.18, lon: -90.26, val: -100 },
  { lat: 29.18, lon: -90.14, val: -100 },
  { lat: 29.2, lon: -90.26, val: -100 },
  { lat: 29.2, lon: -90.14, val: -100 },
  { lat: 29.06, lon: -90.26, val: -100 },
  { lat: 29.2, lon: -90.26, val: -100 },
  { lat: 29.06, lon: -90.24, val: -100 },
  { lat: 29.2, lon: -90.24, val: -100 },
  { lat: 29.06, lon: -90.22, val: -100 },
  { lat: 29.2, lon: -90.22, val: -100 },
  { lat: 29.06, lon: -90.2, val: -100 },
  { lat: 29.2, lon: -90.2, val: -100 },
  { lat: 29.06, lon: -90.18, val: -100 },
  { lat: 29.2, lon: -90.18, val: -100 },
  { lat: 29.06, lon: -90.16, val: -100 },
  { lat: 29.2, lon: -90.16, val: -100 },
  { lat: 29.06, lon: -90.14, val: -100 },
  { lat: 29.2, lon: -90.14, val: -100 },
];

type AqiLayerProps = {
  docks: Dock[];
  aqiType: AvailableMapAqiName;
} & MapLayerProps;

export const AqiLayer: React.FC<AqiLayerProps> = ({ docks, aqiType, id, beforeId }) => {
  const { current: map } = useMap();
  const dispatch = useAppDispatch();

  const { absoluteInterval } = useSelectedInterval();
  const sampleRateMs = useMemo(
    () => generateTimelineBarSampleRateMs(absoluteInterval),
    [absoluteInterval]
  );

  const propertyName = SensorName.AirQuality.PropertyName[aqiType];

  const { isFetching: isFetchingSyncedLatestAqiSampleByDock, syncedLatestAqiSampleByDock } =
    useSyncedLatestAqiSampleByDock({
      docks,
      interval: absoluteInterval,
      propertyName,
    });

  const { isFetching: isFetchingAqiSampleSeriesByDock, aqiSampleSeriesByDock } =
    useAqiSampleSeriesByDock({ docks, interval: absoluteInterval, propertyName, sampleRateMs });

  const viewMode = useViewMode();

  const isFetching = useMemo(() => {
    return viewMode === 'replay'
      ? isFetchingAqiSampleSeriesByDock
      : isFetchingSyncedLatestAqiSampleByDock;
  }, [isFetchingAqiSampleSeriesByDock, isFetchingSyncedLatestAqiSampleByDock, viewMode]);

  useEffect(() => {
    dispatch(setIsLoadingAqiLayer(isFetching));

    return () => {
      dispatch(setIsLoadingAqiLayer(false));
    };
  }, [dispatch, isFetching]);

  const { thumbPosition } = useTimelineThumb();
  const controlBarManager = useControlBarManager();

  const thumbPositionDate = useMemo(
    () => calculatePositionDate(thumbPosition, controlBarManager.getTimelineBarInterval()),
    [controlBarManager, thumbPosition]
  );

  const points: MapboxInterpolateHeatmapLayer['data'] = useMemo(() => {
    const ret: MapboxInterpolateHeatmapLayer['data'] = [];

    if (viewMode === 'replay') {
      const dataInterval = generateTimelineBarDataInterval(
        absoluteInterval,
        controlBarManager.getTimelineBarSampleRateMs()
      );

      const multiSampleSeries = Object.values(aqiSampleSeriesByDock);
      const dataIndex = getDataIndex(
        thumbPositionDate,
        dataInterval,
        controlBarManager.getTimelineBarSampleRateMs()
      );

      for (const samples of multiSampleSeries) {
        const invertedIndex = Math.min(
          Math.max(samples.length - 1 - dataIndex, 0),
          samples.length - 1
        );

        const sample = samples[invertedIndex];

        if (sample && sample.data) {
          ret.push({
            lat: sample.data.coordinate.latitude,
            lon: sample.data.coordinate.longitude,
            val: sample.data.value,
          });
        }
      }
    } else {
      const multiLatestSample = Object.values(syncedLatestAqiSampleByDock);

      for (const sample of multiLatestSample) {
        if (sample.data) {
          ret.push({
            lat: sample.data.coordinate.latitude,
            lon: sample.data.coordinate.longitude,
            val: sample.data.value,
          });
        }
      }
    }

    ret.push(...MASK);

    return ret;
  }, [
    absoluteInterval,
    aqiSampleSeriesByDock,
    controlBarManager,
    syncedLatestAqiSampleByDock,
    thumbPositionDate,
    viewMode,
  ]);

  useEffect(() => {
    if (!map) return;

    const underlyingMap = map.getMap();

    const layer = new MapboxInterpolateHeatmapLayer({
      id,
      data: points,
      opacity: 0.37,
      minValue: 0,
      maxValue: 500,
      valueToColor: '\n vec3 valueToColor(float value) \n{\nreturn vec3(0,0,0);\n}\n',
      valueToColor4:
        '\n\nfloat getGreenOpacityFactor()\n{\n  return 0.3;\n}\nvec4 lerp(vec4 a, vec4 b, float t)\n{\n  //t = max(0.0,min(t,1.0));\n  return a * (1.0-t) + b * t;\n}\n\nfloat lerp_f(float a,float b, float t)\n{\n  return a * (1.0-t) + b * t;\n}\n\nvec4 valueToColor4(float value, float defaultOpacity) \n{\n\n\n  float blendValueDiff = 10.0;\n  float blendValueDiffInverse = 1.0/blendValueDiff;\n  value = value * 600.0 - 100.0;\n\n  if(value < 0.0)\n  {\n    float lerpVal = 1.0 - min(abs(value),blendValueDiff)*blendValueDiffInverse;\n    return vec4(0.0,0.90980392156,0.0,lerp_f(0.0,1.0,lerpVal) * defaultOpacity * getGreenOpacityFactor());\n  }\n  else\n  {\n   \n\n\n    if(value <= 50.0) \n    {\n        return lerp(vec4(0,0.90980392156,0,defaultOpacity * getGreenOpacityFactor()),vec4(1.0,1.0,0,defaultOpacity),1.0-min(abs(value-50.0),blendValueDiff) * blendValueDiffInverse);\n    }\n    else if(value <= 100.0) \n    {\n        return lerp(vec4(1.0,1.0,0,defaultOpacity),vec4(1.0,0.54117647058,0,defaultOpacity),1.0-min(abs(value-100.0),blendValueDiff) * blendValueDiffInverse);\n    }\n    else if(value <= 150.0) \n    {\n      return lerp(vec4(1.0,0.54117647058,0,defaultOpacity),vec4(1.0,0,0,defaultOpacity),1.0-min(abs(value-150.0),blendValueDiff) * blendValueDiffInverse);\n    }\n    else if(value <= 200.0)  \n    {\n      return  lerp(vec4(1.0,0,0,defaultOpacity),vec4(0.60392156862,0.28235294117,0.63137254902,defaultOpacity ),1.0-min(abs(value-200.0),blendValueDiff) * blendValueDiffInverse);\n    }\n    else if(value <= 300.0) \n    {\n      return lerp(vec4(0.60392156862,0.28235294117,0.63137254902,defaultOpacity ),vec4(0.53725490196,0.0,0.15294117647,defaultOpacity ),1.0-min(abs(value-300.0),blendValueDiff) * blendValueDiffInverse);\n    }\n    return vec4(0.53725490196,0.0,0.15294117647,defaultOpacity );\n  }\n  \n}\n\n',
    });

    underlyingMap.addLayer(layer, beforeId);

    return () => {
      underlyingMap.removeLayer(id);
    };
  }, [beforeId, id, map, points]);

  return null;
};
