import { Property, Vessel } from 'Models';
import { EmitterDetailsBarSection } from 'Pages/DashboardPage/components/EmitterDetailsBar/components';
import { EmitterDetailsBarCellSet } from 'Pages/DashboardPage/components/EmitterDetailsBar/components/EmitterDetailsBarCellSet';
import { ReactElement, useMemo, useState } from 'react';
import { KeyValueCell } from '../../KeyValueCell';
import { OptionSectionHeader } from 'Pages/DashboardPage/components/EmitterDetailsBar/components/Sections/OptionSection/components/OptionSectionHeader';
import { getPropertiesWithName } from 'Helpers/sensors';
import { SensorName } from 'Constants';
import { PropertyCell } from '../../PropertyCell';

interface EnginePerformanceSectionProps {
  vessel: Vessel;
}

export function EnginePerformanceSection({ vessel }: EnginePerformanceSectionProps): ReactElement {
  const engines = useMemo(() => {
    return vessel.hardware!.subsystems.filter((v) => v.type === 'engine');
  }, [vessel.hardware]);

  const engineNames = useMemo(() => {
    return engines.map((e) => e.label);
  }, [engines]);

  const [selectedEngineIndex, setSelectedEngineIndex] = useState(0);

  const selectedEngine = engines[selectedEngineIndex];

  const fuelMassFlowProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(
      SensorName.Engine.PropertyName.FlowDifferential,
      vessel.sensors
    ).find((p) => p.name.name.startsWith(selectedEngine.id));
  }, [selectedEngine.id, vessel.sensors]);

  const dustProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(SensorName.EmissionV2.PropertyName.Dust, vessel.sensors).find(
      (p) => p.name.name.startsWith(selectedEngine.id)
    );
  }, [selectedEngine.id, vessel.sensors]);

  const engineLoadProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(SensorName.Generator.PropertyName.Load, vessel.sensors).find((p) =>
      p.name.name.startsWith(selectedEngine.id)
    );
  }, [selectedEngine.id, vessel.sensors]);

  const engineRpmProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(SensorName.Generator.PropertyName.RPM, vessel.sensors).find((p) =>
      p.name.name.startsWith(selectedEngine.id)
    );
  }, [selectedEngine.id, vessel.sensors]);

  const activePowerProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(
      SensorName.Generator.PropertyName.ActivePower,
      vessel.sensors
    ).find((p) => p.name.name.startsWith(selectedEngine.id));
  }, [selectedEngine.id, vessel.sensors]);

  const reactivePowerProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(
      SensorName.Generator.PropertyName.ReactivePower,
      vessel.sensors
    ).find((p) => p.name.name.startsWith(selectedEngine.id));
  }, [selectedEngine.id, vessel.sensors]);

  const co2PerDayProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(
      SensorName.Virt.PropertyName.CO2MassPerDay,
      vessel.sensors
    ).find((p) => p.name.name.startsWith(selectedEngine.id));
  }, [selectedEngine.id, vessel.sensors]);

  const noxPerDayProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(
      SensorName.Virt.PropertyName.NOXMassPerDay,
      vessel.sensors
    ).find((p) => p.name.name.startsWith(selectedEngine.id));
  }, [selectedEngine.id, vessel.sensors]);

  const so2PerDayProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(
      SensorName.Virt.PropertyName.SO2MassPerDay,
      vessel.sensors
    ).find((p) => p.name.name.startsWith(selectedEngine.id));
  }, [selectedEngine.id, vessel.sensors]);

  const ch4PerDayProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(
      SensorName.Virt.PropertyName.CH4MassPerDay,
      vessel.sensors
    ).find((p) => p.name.name.startsWith(selectedEngine.id));
  }, [selectedEngine.id, vessel.sensors]);

  return (
    <EmitterDetailsBarSection
      header={
        <OptionSectionHeader
          title="Engine Performance"
          options={engineNames}
          selectedOptionIndex={selectedEngineIndex}
          onSelectOption={setSelectedEngineIndex}
        />
      }
    >
      <EmitterDetailsBarCellSet>
        {co2PerDayProperty ? (
          <PropertyCell
            key={co2PerDayProperty.hash}
            title="CO2 per day"
            property={co2PerDayProperty}
          />
        ) : (
          <KeyValueCell title="CO2 per day" showPlaceholder={true}/>
        )}
        {noxPerDayProperty ? (
          <PropertyCell
            key={noxPerDayProperty.hash}
            title="NOx per day"
            property={noxPerDayProperty}
          />
        ) : (
          <KeyValueCell title="NOx per day" showPlaceholder={true}/>
        )}
        {so2PerDayProperty ? (
          <PropertyCell
            key={so2PerDayProperty.hash}
            title="SO2 per day"
            property={so2PerDayProperty}
          />
        ) : (
          <KeyValueCell title="SO2 per day" showPlaceholder={true}/>
        )}
        {ch4PerDayProperty ? (
          <PropertyCell
            key={ch4PerDayProperty.hash}
            title="CH4 per day"
            property={ch4PerDayProperty}
          />
        ) : (
          <KeyValueCell title="CH4 per day" showPlaceholder={true}/>
        )}
        <KeyValueCell title="Efficiency" showPlaceholder={true} />
        {fuelMassFlowProperty ? (
          <PropertyCell
            key={fuelMassFlowProperty.hash}
            title="Fuel mass flow"
            property={fuelMassFlowProperty}
          />
        ) : (
          <KeyValueCell title="Fuel mass flow" />
        )}
        <KeyValueCell title="Fuel per day" showPlaceholder={true} />
        {dustProperty ? (
          <PropertyCell
            key={dustProperty.hash}
            title="Dust concentration"
            property={dustProperty}
          />
        ) : (
          <KeyValueCell title="Dust concentration" />
        )}
        {engineLoadProperty ? (
          <PropertyCell
            key={engineLoadProperty.hash}
            title="Engine load"
            property={engineLoadProperty}
          />
        ) : (
          <KeyValueCell title='="Engine load' />
        )}
        {engineRpmProperty ? (
          <PropertyCell
            key={engineRpmProperty.hash}
            title="Engine RPM"
            property={engineRpmProperty}
          />
        ) : (
          <KeyValueCell title='="Engine RPM' />
        )}
        {activePowerProperty ? (
          <PropertyCell
            key={activePowerProperty.hash}
            title="Active power"
            property={activePowerProperty}
          />
        ) : (
          <KeyValueCell title="Active power" />
        )}
        {reactivePowerProperty ? (
          <PropertyCell
            key={reactivePowerProperty.hash}
            title="Reactive power"
            property={reactivePowerProperty}
          />
        ) : (
          <KeyValueCell title="Reactive power" />
        )}
      </EmitterDetailsBarCellSet>
    </EmitterDetailsBarSection>
  );
}
