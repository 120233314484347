import styles from './Option.module.scss';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import clsx from 'clsx';

interface OptionProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  spacing?: number;
  disabled?: boolean;
}

export const Option: FC<PropsWithChildren<OptionProps>> = ({
  label,
  spacing,
  disabled: isDisabled = false,
  children,
  className,
  ...remainingProps
}) => {
  return (
    <div
      className={clsx(styles.option, isDisabled && styles.disabled, className)}
      {...remainingProps}
    >
      {children}{' '}
      <label style={spacing ? { marginLeft: `${spacing / 16}rem` } : undefined}>{label}</label>
    </div>
  );
};
