import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { getAccessTokenSilently } from 'Services/auth0';
import { Context, IContext } from './Context';
import { SubscriptionManager } from './SubscriptionManager';

type ProviderProps = {
  url: string;
  path: string;
};

export const Provider: FC<PropsWithChildren<ProviderProps>> = ({ url, path, children }) => {
  const [token, setToken] = useState<string>();
  const [context, setContext] = useState<IContext>();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    })();
  }, []);

  useEffect(() => {
    if (!url || !path || !token) return;

    let currentContext = context;

    if (!currentContext) {
      currentContext = {
        subscriptionManager: new SubscriptionManager(url, path, token),
      };

      setContext(currentContext);
    }

    currentContext.subscriptionManager.updateToken(token);
  }, [context, url, path, token]);

  return <Context.Provider value={context as IContext}> {children} </Context.Provider>;
};
