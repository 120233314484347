import { useCallback, useMemo, useState } from 'react';
import { EmitterDetailsBarSectionHeaderRegular } from '../EmitterDetailsBarSectionHeaderRegular/EmitterDetailsBarSectionHeaderRegular';
import { EmitterDetailsBarSection } from '../EmitterDetailsBarSection';
// import { EmitterDetailsBarMenu } from '../EmitterDetailsBarMenu';
import { EmitterDetailsBarTabNavigator } from '../EmitterDetailsBarTabNavigator';

type Tab = {
  title: string;
  sectionComponent: React.ReactNode;
};

type EmissionSectionProps = {
  title: string;
  tabs: Tab[];
  containedWithinSection?: boolean;
};

export const TabbedSection: React.FC<EmissionSectionProps> = ({
  title,
  tabs,
  containedWithinSection = false,
}) => {
  console.assert(tabs.length > 0, 'TabbedSection requires tabs to be not empty');

  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState(0);

  const handleSelectMenuItem = useCallback((index: number) => {
    setSelectedMenuItemIndex(index);
  }, []);

  const selectedTab = useMemo(() => tabs[selectedMenuItemIndex], [selectedMenuItemIndex, tabs]);

  const menuItems = useMemo(() => tabs.map((s) => s.title), [tabs]);

  return containedWithinSection ? (
    <>
      <EmitterDetailsBarTabNavigator tabs={menuItems} onSelectTab={handleSelectMenuItem} />
      {selectedTab.sectionComponent}
    </>
  ) : (
    <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title={title} />}>
      <EmitterDetailsBarTabNavigator tabs={menuItems} onSelectTab={handleSelectMenuItem} />
      {selectedTab.sectionComponent}
    </EmitterDetailsBarSection>
  );
};
