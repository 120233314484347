import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SensorName } from 'Constants';
import { SelectableMapElement } from 'Pages/DashboardPage/components/Map/interfaces';

export type AvailableMapEmissionName = keyof Pick<
  typeof SensorName.DerivedEmission.PropertyName,
  'CO2Average' | 'CH4DualAverage' | 'SO2Average' | 'NOXDualAverage'
>;

export type AvailableMapAqiName = keyof Pick<
  typeof SensorName.AirQuality.PropertyName,
  'NO2Aqi' | 'PM10Aqi' | 'PM25Aqi'
>;

type MapState = {
  selectedElement?: SelectableMapElement;
  showsSelection: boolean;
  showsVesselLayer: boolean;
  showsDockLayer: boolean;
  showsAisLayer: boolean;
  showsPositionHistoryLayer: boolean;
  showsEcaLayer: boolean;
  showsAlertLayer: boolean;
  selectedEmissionLayer?: AvailableMapEmissionName;
  selectedAqiLayer?: AvailableMapAqiName;
  isLoadingVesselLayer: boolean;
  isLoadingDockLayer: boolean;
  isLoadingAisLayer: boolean;
  isLoadingPositionHistoryLayer: boolean;
  isLoadingEmissionLayer: boolean;
  isLoadingAqiLayer: boolean;
  isLoadingAlertLayer: boolean;
};

const defaultState: MapState = {
  selectedElement: undefined,
  showsSelection: true,
  showsVesselLayer: true,
  showsDockLayer: true,
  showsAisLayer: true,
  showsPositionHistoryLayer: false,
  showsEcaLayer: false,
  showsAlertLayer: false,
  selectedEmissionLayer: 'CO2Average',
  selectedAqiLayer: undefined,
  isLoadingVesselLayer: false,
  isLoadingDockLayer: false,
  isLoadingAisLayer: false,
  isLoadingPositionHistoryLayer: false,
  isLoadingEmissionLayer: false,
  isLoadingAqiLayer: false,
  isLoadingAlertLayer: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState: defaultState,
  reducers: {
    setSelectedElement: (state, action: PayloadAction<MapState['selectedElement']>) => {
      return {
        ...state,
        selectedElement: action.payload,
      };
    },
    setShowsSelection: (state, action: PayloadAction<MapState['showsSelection']>) => {
      state.showsSelection = action.payload;
    },
    setShowsVesselLayer: (state, action: PayloadAction<MapState['showsVesselLayer']>) => {
      state.showsVesselLayer = action.payload;
    },
    setShowsDockLayer: (state, action: PayloadAction<MapState['showsDockLayer']>) => {
      state.showsDockLayer = action.payload;
    },
    setShowsAisLayer: (state, action: PayloadAction<MapState['showsAisLayer']>) => {
      state.showsAisLayer = action.payload;
    },
    setShowsPositionHistoryLayer: (
      state,
      action: PayloadAction<MapState['showsPositionHistoryLayer']>
    ) => {
      state.showsPositionHistoryLayer = action.payload;
    },
    setShowsEcaLayer: (state, action: PayloadAction<MapState['showsEcaLayer']>) => {
      state.showsEcaLayer = action.payload;
    },
    setShowsAlertLayer: (state, action: PayloadAction<MapState['showsAlertLayer']>) => {
      state.showsAlertLayer = action.payload;
    },
    setSelectedEmissionLayer: (state, action: PayloadAction<MapState['selectedEmissionLayer']>) => {
      state.selectedEmissionLayer = action.payload;
    },
    setSelectedAqiLayer: (state, action: PayloadAction<MapState['selectedAqiLayer']>) => {
      state.selectedAqiLayer = action.payload;
    },
    setIsLoadingVesselLayer: (state, action: PayloadAction<MapState['isLoadingVesselLayer']>) => {
      state.isLoadingVesselLayer = action.payload;
    },
    setIsLoadingDockLayer: (state, action: PayloadAction<MapState['isLoadingDockLayer']>) => {
      state.isLoadingDockLayer = action.payload;
    },
    setIsLoadingAisLayer: (state, action: PayloadAction<MapState['isLoadingAisLayer']>) => {
      state.isLoadingAisLayer = action.payload;
    },
    setIsLoadingPositionHistoryLayer: (
      state,
      action: PayloadAction<MapState['isLoadingPositionHistoryLayer']>
    ) => {
      state.isLoadingPositionHistoryLayer = action.payload;
    },
    setIsLoadingEmissionLayer: (
      state,
      action: PayloadAction<MapState['isLoadingEmissionLayer']>
    ) => {
      state.isLoadingEmissionLayer = action.payload;
    },
    setIsLoadingAqiLayer: (state, action: PayloadAction<MapState['isLoadingAqiLayer']>) => {
      state.isLoadingAqiLayer = action.payload;
    },
    setIsLoadingAlertLayer: (state, action: PayloadAction<MapState['isLoadingAlertLayer']>) => {
      state.isLoadingAlertLayer = action.payload;
    },
  },
});

const { reducer, actions } = mapSlice;

export const {
  setSelectedElement,
  setShowsSelection,
  setShowsVesselLayer,
  setShowsDockLayer,
  setShowsAisLayer,
  setShowsPositionHistoryLayer,
  setShowsEcaLayer,
  setShowsAlertLayer,
  setSelectedEmissionLayer,
  setSelectedAqiLayer,
  setIsLoadingVesselLayer,
  setIsLoadingDockLayer,
  setIsLoadingAisLayer,
  setIsLoadingPositionHistoryLayer,
  setIsLoadingEmissionLayer,
  setIsLoadingAqiLayer,
  setIsLoadingAlertLayer,
} = actions;
export const mapReducer = reducer;
