import { UNAVAILABLE_COG, UNAVAILABLE_SOG, UNAVAILABLE_TRUE_HEADING } from 'Constants';
import { Degrees } from 'Interfaces';

export const resolveEmitterRotation = ({
  sog,
  cog,
  trueHeading,
}: {
  sog?: number;
  cog?: number;
  trueHeading?: number;
}): Degrees | undefined => {
  let rotation: Degrees | undefined;

  const normalizedSog =
    typeof sog === 'number' ? (sog !== UNAVAILABLE_SOG ? sog : undefined) : undefined;
  const normalizedCog =
    typeof cog === 'number' ? (cog !== UNAVAILABLE_COG ? cog : undefined) : undefined;
  const normalizedTrueHeading =
    typeof trueHeading === 'number'
      ? trueHeading !== UNAVAILABLE_TRUE_HEADING
        ? trueHeading
        : undefined
      : undefined;

  if (typeof normalizedTrueHeading === 'number' && typeof normalizedCog === 'number') {
    rotation = (normalizedSog ?? 0) < 0.5 ? normalizedTrueHeading : normalizedCog;
  } else if (typeof normalizedTrueHeading === 'number') {
    rotation = normalizedTrueHeading;
  } else if (typeof normalizedCog === 'number') {
    rotation = normalizedCog;
  }

  return rotation;
};
