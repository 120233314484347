import styles from './LayerSelector.module.scss';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as IconStackEnabled } from './resources/graphics/icon-stack-enabled.svg';
import { ReactComponent as IconStackDisabled } from './resources/graphics/icon-stack-disabled.svg';
import { useAppSelector } from 'Services/redux';
import {
  selectedAqiLayerSelector,
  selectedElementSelector,
  selectedEmissionLayerSelector,
  showsAisLayerSelector,
  showsAlertLayerSelector,
  showsEcaLayerSelector,
  showsPositionHistoryLayerSelector,
} from 'Services/redux/map';
import { isDockElement, isVesselElement } from '../Map/helpers';
import { DefaultMenu, DockMenu, VesselMenu } from './components';
import { getPropertiesWithName, getSensorsWithName } from 'Helpers/sensors';
import { SensorName } from 'Constants';
import { Emitter } from 'Models';
import { LayersAvailability } from './components/Menus/type';

type LayersDetails = {
  availability: LayersAvailability;
  count: number;
};

type LayerSelectorProps = {
  emitters: Emitter[];
};

export const LayerSelector: React.FC<LayerSelectorProps> = ({ emitters }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const selectedElement = useAppSelector(selectedElementSelector);

  const handleClickControl = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const isCheckedPositionHistoryLayer = useAppSelector(showsPositionHistoryLayerSelector);
  const isCheckedAisLayer = useAppSelector(showsAisLayerSelector);
  const isCheckedAlertLayer = useAppSelector(showsAlertLayerSelector);
  const isCheckedEcaLayer = useAppSelector(showsEcaLayerSelector);
  const selectedEmissionLayer = useAppSelector(selectedEmissionLayerSelector);
  const selectedAqiLayer = useAppSelector(selectedAqiLayerSelector);

  const layersDetails: LayersDetails | undefined = useMemo(() => {
    const layersAvailability: LayersAvailability = {
      hasGpsSensors: false,
      hasAisPositionalSensors: false,
      hasCo2Property: false,
      hasCh4Property: false,
      hasSo2Property: false,
      hasNoxProperty: false,
      hasPm25Property: false,
      hasPm10Property: false,
      hasNo2Property: false,
    };

    let activeLayersCount = 0;

    const selectedElementValue = selectedElement?.value;

    if (selectedElementValue) {
      layersAvailability.hasGpsSensors = !!getSensorsWithName(SensorName.GPS, selectedElementValue)
        .length;

      layersAvailability.hasAisPositionalSensors = !!getSensorsWithName(
        SensorName.AISPositional,
        selectedElementValue
      ).length;

      layersAvailability.hasCo2Property = !!getPropertiesWithName(
        SensorName.DerivedEmission.PropertyName.CO2Average,
        selectedElementValue.sensors
      ).length;

      layersAvailability.hasCh4Property = !!getPropertiesWithName(
        SensorName.DerivedEmission.PropertyName.CH4DualAverage,
        selectedElementValue.sensors
      ).length;

      layersAvailability.hasSo2Property = !!getPropertiesWithName(
        SensorName.DerivedEmission.PropertyName.SO2Average,
        selectedElementValue.sensors
      ).length;

      layersAvailability.hasNoxProperty = !!getPropertiesWithName(
        SensorName.DerivedEmission.PropertyName.NOXDualAverage,
        selectedElementValue.sensors
      ).length;

      layersAvailability.hasPm25Property = !!getPropertiesWithName(
        SensorName.AirQuality.PropertyName.PM25Aqi,
        selectedElementValue.sensors
      ).length;

      layersAvailability.hasPm10Property = !!getPropertiesWithName(
        SensorName.AirQuality.PropertyName.PM10Aqi,
        selectedElementValue.sensors
      ).length;

      layersAvailability.hasNo2Property = !!getPropertiesWithName(
        SensorName.AirQuality.PropertyName.NO2Aqi,
        selectedElementValue.sensors
      ).length;
    }

    // navigation layers count
    if (layersAvailability.hasGpsSensors && isCheckedPositionHistoryLayer) {
      activeLayersCount++;
    }
    if (layersAvailability.hasAisPositionalSensors && isCheckedAisLayer) {
      activeLayersCount++;
    }
    if (isCheckedAlertLayer) {
      activeLayersCount++;
    }
    if (isCheckedEcaLayer) {
      activeLayersCount++;
    }

    // emission layers count
    if (selectedEmissionLayer === 'CO2Average' && layersAvailability.hasCo2Property) {
      activeLayersCount++;
    }
    if (selectedEmissionLayer === 'CH4DualAverage' && layersAvailability.hasCh4Property) {
      activeLayersCount++;
    }
    if (selectedEmissionLayer === 'SO2Average' && layersAvailability.hasSo2Property) {
      activeLayersCount++;
    }
    if (selectedEmissionLayer === 'NOXDualAverage' && layersAvailability.hasNoxProperty) {
      activeLayersCount++;
    }

    // aqi layers count
    if (selectedAqiLayer === 'PM25Aqi' && layersAvailability.hasPm25Property) {
      activeLayersCount++;
    }

    if (selectedAqiLayer === 'PM10Aqi' && layersAvailability.hasPm10Property) {
      activeLayersCount++;
    }

    if (selectedAqiLayer === 'NO2Aqi' && layersAvailability.hasNo2Property) {
      activeLayersCount++;
    }

    return {
      availability: layersAvailability,
      count: activeLayersCount,
    };
  }, [
    isCheckedAisLayer,
    isCheckedAlertLayer,
    isCheckedEcaLayer,
    isCheckedPositionHistoryLayer,
    selectedElement,
    selectedEmissionLayer,
    selectedAqiLayer,
  ]);

  const menu = useMemo(() => {
    let ret = <DefaultMenu layersAvailability={layersDetails.availability} />;

    if (selectedElement && layersDetails) {
      if (isVesselElement(selectedElement)) {
        ret = <VesselMenu layersAvailability={layersDetails.availability} />;
      } else if (isDockElement(selectedElement)) {
        ret = <DockMenu layersAvailability={layersDetails.availability} />;
      }
    }

    return ret;
  }, [layersDetails, selectedElement]);

  return (
    <div className={styles.layerSelector}>
      <div className={styles.control} onClick={handleClickControl}>
        {layersDetails?.count || isCheckedEcaLayer ? <IconStackEnabled /> : <IconStackDisabled />}
      </div>
      {isExpanded && menu}
    </div>
  );
};
