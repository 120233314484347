import styles from '../../LayerSelector.module.scss';
import { RootState, useAppDispatch, useAppSelector } from 'Services/redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Option } from 'Components/Option';
import { CheckBox } from 'Components/CheckBox';

type CheckBoxOptionProps = {
  label: string;
  checkedSelector: (state: RootState) => boolean;
  checkedAction: ActionCreatorWithPayload<boolean>;
};

export const CheckBoxOption: React.FC<CheckBoxOptionProps> = ({
  label,
  checkedSelector,
  checkedAction,
}) => {
  const dispatch = useAppDispatch();

  const isChecked = useAppSelector(checkedSelector);

  const handleClickOption = () => {
    dispatch(checkedAction(!isChecked));
  };

  return (
    <Option label={label} className={styles.option} onClick={handleClickOption}>
      <CheckBox checked={isChecked} />
    </Option>
  );
};
