import { Property, Sensor } from 'Models';
import { createPropertyName, generatePropertyHash } from 'Helpers/sensors';

export const DEFAULT_SAMPLE_RATE = 120;

export const transformIntoSensors = (
  sensorSchemas: Record<string, any>[],
  sensorGroupId: string
): Sensor[] => {
  return sensorSchemas.map((s) => transformIntoSensor(s, sensorGroupId));
};

export const transformIntoSensor = (
  record: Record<string, any>,
  sensorGroupId: string
): Sensor => ({
  id: record._id as string,
  name: record.name,
  sensorGroupId,
  displayName: record.displayName,
  properties: (record.value ?? [])
    .map((s: Record<string, any>) =>
      transformIntoProperties({ sensorSchema: s, parentSensorSchema: record, sensorGroupId })
    )
    .flat(),
  engineId: record.metadata?.engineId,
  sampleRate: record.metadata?.sampleInterval ?? DEFAULT_SAMPLE_RATE,
});

const transformIntoProperties = ({
  sensorSchema,
  parentSensorSchema,
  sensorGroupId,
}: {
  sensorSchema: Record<string, any>;
  parentSensorSchema: Record<string, any>;
  sensorGroupId: string;
}): Property[] => {
  const path = `${parentSensorSchema.name}/${sensorSchema.name}`.replace(/\./g, '/');
  const sensorId = parentSensorSchema._id as string;

  const propertyName = createPropertyName(sensorSchema.name, path);

  return [
    {
      name: propertyName,
      displayName: sensorSchema.displayName,
      sensorGroupId,
      sensorId,
      sensorName: parentSensorSchema.name,
      sensorDisplayName: parentSensorSchema.displayName,
      engineId: parentSensorSchema.metadata?.engineId,
      unit: sensorSchema.metadata?.unit,
      sampleRate: parentSensorSchema.metadata?.sampleInterval ?? DEFAULT_SAMPLE_RATE,
      hash: generatePropertyHash(sensorId, propertyName),
    },
  ];
};
