import { TimeInputValue } from './types';

export function getTimeTextFromValue(value?: TimeInputValue): string {
  return value ? encodeTimeText(value) : '';
}

export function encodeTimeText(value: TimeInputValue): string {
  return `${String(value.hour).padStart(2, '0')} : ${String(value.minute).padStart(2, '0')}`;
}

export function decodeTimeText(text: string): TimeInputValue | undefined {
  const match = /([0-2][0-9])\s:\s([0-5][0-9])/.exec(text);

  if (!match) return;

  return { hour: +match[1], minute: +match[2] };
}

export function generateTimeText(text: string, previousText: string) {
  if (text + ' ' === previousText) {
    return text.replace(' :', '');
  }

  const sanitizedText = text.replace(/[^0-9]/g, '').slice(0, 4);

  if (!sanitizedText.length) return '';

  let result = '';

  let hourPart = sanitizedText.slice(0, 2);

  if (isValidHour(hourPart)) {
    result +=
      hourPart.length === 1 && hourIsPartOfTwoDigits(Number(hourPart))
        ? hourPart
        : hourPart.padStart(2, '0') + ' : ';
  } else {
    // This is necessary for copy and paste support
    hourPart = hourPart.slice(0, -1);
    result += hourPart.padStart(2, '0') + ' : ';
  }

  let minutePart =
    hourPart.length < sanitizedText.length
      ? sanitizedText.slice(-(sanitizedText.length - hourPart.length))
      : '';

  if (isValidMinute(minutePart)) {
    result +=
      minutePart.length === 1 && minuteIsPartOfTwoDigits(Number(minutePart))
        ? minutePart
        : minutePart.padStart(2, '0');
  }

  return result;
}

export function hourIsPartOfTwoDigits(hour: number) {
  return hour <= 2;
}

export function minuteIsPartOfTwoDigits(minute: number) {
  return minute <= 5;
}

export function isValidHour(hourText: string) {
  return getHour(hourText) !== undefined;
}

export function isValidMinute(minuteText: string) {
  return getMinute(minuteText) !== undefined;
}

export function getHour(hourText: string): number | undefined {
  if (!hourText.length) return undefined;

  const hour = +hourText;

  return hour < 24 ? hour : undefined;
}

export function getMinute(minuteText: string): number | undefined {
  if (!minuteText.length) return undefined;

  const minute = +minuteText;

  return minute < 60 ? minute : undefined;
}
