import { useCallback, useMemo } from 'react';
import { Section } from '../../interfaces';
import { Accordion } from 'Components/Accordion/Accordion';
import { OptionGrid } from 'Components/OptionGrid';

type StepPropertiesSectionProps = {
  section: Section;
  onToggleOption: (index: number) => void;
};

export const StepPropertiesAccordion: React.FC<StepPropertiesSectionProps> = ({
  section,
  onToggleOption,
}) => {
  const optionNames = useMemo(() => section.options.map((option) => option.name), [section]);

  const selectedIndexes = useMemo(() => {
    return section.options.reduce<number[]>(
      (acc, option, index) => (option.isSelected ? acc.concat(index) : acc),
      []
    );
  }, [section]);

  const disabledIndexes = useMemo(() => {
    return section.options.reduce<number[]>(
      (acc, option, index) => (option.isRequired ? acc.concat(index) : acc),
      []
    );
  }, [section]);

  const handleToggleOption = useCallback(
    (index: number) => {
      const option = section.options[index];
      if (option.isRequired) return;

      onToggleOption(index);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section.options]
  );

  return (
    <Accordion header={section.name}>
      <OptionGrid
        options={optionNames}
        selectedIndexes={selectedIndexes}
        disabledIndexes={disabledIndexes}
        onToggleOption={handleToggleOption}
      />
    </Accordion>
  );
};
