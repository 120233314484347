import styles from './CalendarNavigator.module.scss';
import { ReactComponent as IconArrowLeft } from './resources/graphics/icon-arrow-left.svg';
import { ReactComponent as IconArrowRight } from './resources/graphics/icon-arrow-right.svg';
import clsx from 'clsx';

type CalendarNavigatorProps = {
  year: number;
  month: number;
  navigationButtonType: 'previous' | 'next';
  onClickNavigationButton: () => void;
};

export const CalendarNavigator: React.FC<CalendarNavigatorProps> = ({
  year,
  month,
  navigationButtonType,
  onClickNavigationButton,
}) => {
  return (
    <div className={styles.calendarNavigator}>
      <button
        className={clsx(
          styles.calendarNavigatorButton,
          navigationButtonType === 'previous' ? styles.left : styles.right
        )}
        onClick={onClickNavigationButton}
      >
        {navigationButtonType === 'previous' ? <IconArrowLeft /> : <IconArrowRight />}
      </button>
      {getMonthName(month)} {year}
    </div>
  );
};

function getMonthName(monthIndex: number) {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][monthIndex];
}
