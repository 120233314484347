import { useEffect, useState } from 'react';
import { Property } from 'Models';
import { ValueContainer } from '../ValueContainer';
import { useNodeServerObserver } from './useNodeServerObserver';

const EMPTY_VALUE_CONTAINER = new ValueContainer();

type UseLatestObservePropertiesValue = { merge: boolean };

export function useLatestObservedPropertiesValue(
  properties: Property[],
  options: UseLatestObservePropertiesValue = { merge: true }
) {
  const [valueContainer, setValueContainer] = useState<ValueContainer>(EMPTY_VALUE_CONTAINER);

  const observer = useNodeServerObserver();

  const propertiesHash = properties
    .map((p) => p.hash)
    .sort()
    .join();

  useEffect(() => {
    setValueContainer(EMPTY_VALUE_CONTAINER);

    if (!properties.length) return;

    const subscription = observer.subscribe(properties, (newValueContainer) => {
      setValueContainer((valueContainer) =>
        options.merge ? valueContainer.merge(newValueContainer) : newValueContainer
      );
    });

    return () => observer.unsubscribe(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer, propertiesHash]);

  return valueContainer;
}
