import { Coordinate } from 'Models';
import { useMemo } from 'react';
import formatcoords from 'formatcoords';

type DMSCoordinateProps = {
  coordinate: Coordinate;
  className?: string;
  style?: React.CSSProperties;
};

export const DMSCoordinate: React.FC<DMSCoordinateProps> = ({ coordinate, className, style }) => {
  const DMSCoordinate = useMemo(() => getDMSCoordinate(coordinate), [coordinate]);

  return (
    <p className={className} style={style}>
      {DMSCoordinate}
    </p>
  );
};

function getDMSCoordinate(coordinate: Coordinate): string {
  const { latitude, longitude } = coordinate;

  return formatcoords(latitude, longitude).format('DD MM ss X', {
    latLonSeparator: ', ',
    decimalPlaces: 0,
  });
}
