import { formatNumber } from 'Helpers/number';

export const getValueFormatter = ({
  unit,
  maximumFractionDigits,
}: {
  unit?: string;
  maximumFractionDigits: number;
}) => {
  const formatter = (value: number) => {
    if (value === null) return '';

    return (
      formatNumber(value, {
        minFractionDigits: 0,
        maxFractionDigits: maximumFractionDigits,
      }) + (unit ? ` ${unit}` : '')
    );
  };

  return formatter;
};
