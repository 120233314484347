import { SensorName } from 'Constants';
import {
  getPropertiesWithName,
  getSensorsWithName,
  getPropertiesWithSuffix,
} from 'Helpers/sensors';
import { Emitter } from 'Models';

function fromEmissionsV2(emitter: Emitter) {
  return getPropertiesWithSuffix(SensorName.EmissionV2.PropertyName.CO2MassFlow, emitter.sensors);
}

function fromVirtual(emitter: Emitter) {
  const emissionSensors = getSensorsWithName(SensorName.Virt, emitter);
  return getPropertiesWithName(SensorName.Virt.PropertyName.CO2MassFlowFromConc, emissionSensors);
}

export function hasCO2Properties(emitter: Emitter) {
  const emissionsV2Properties = fromEmissionsV2(emitter);
  const virtualProperties = fromVirtual(emitter);
  return emissionsV2Properties.length || virtualProperties.length;
}
