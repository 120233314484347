import { StylesConfig } from 'react-select';

export const stylesConfig: StylesConfig<any, false> = {
  container: (styles) => ({
    ...styles,
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    cursor: 'pointer',
    border: isFocused ? '1px solid #009fe3' : '1px solid #D2D9E8',
    boxShadow: 'none',
    ':hover': {
      border: isFocused ? '1px solid #009fe3' : '1px solid #9397a1',
    },
    overflow: 'hidden',
  }),
  menu: (style) => ({
    ...style,
    overflow: 'hidden',
  }),
  menuPortal: (style) => ({ ...style, zIndex: '1000' }),
  option: (styles, { isFocused }) => ({
    ...styles,
    background: isFocused ? '#f1f3f7' : '#fff',
    color: '#000',
    cursor: 'pointer',
    zIndex: 1000,
    height: `${42 / 16}rem`,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '&:active': {
      backgroundColor: '#f1f3f7',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  input: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
    height: `${40 / 16}rem`,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
};
