import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

export const useMapImage = (url: string, name: string) => {
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    if (!map.hasImage(name)) {
      map.loadImage(url, (error, image) => {
        if (!error && !map.hasImage(name) && image) {
          map.addImage(name, image);
        }
      });
    }
  }, [map, name, url]);
};
