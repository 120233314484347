import { Property } from 'Models';
import { PropertyValue } from 'Services/redux/api/sensor';
import { EventEmitterPayload } from './interfaces';

export class ValueContainer {
  constructor(private payload?: EventEmitterPayload) {}

  getValueForProperty(property: Property): PropertyValue | undefined {
    return this.payload?.[property.hash];
  }

  merge(valueContainer: ValueContainer): ValueContainer {
    const mergedPayload = Object.assign({}, this.payload ?? {}, valueContainer.payload ?? {});

    return new ValueContainer(mergedPayload);
  }
}
