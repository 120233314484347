import styles from './EmitterDetailsBarOnOffCell.module.scss';
import { useMemo, useEffect } from 'react';
import { Property } from 'Models';
import PuffLoader from 'react-spinners/PuffLoader';
import { fill, mergeSamples } from 'Helpers/sample';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import { generateTimelineBarDataInterval, getNewDataIndex } from 'Helpers/timeline';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { useQuery } from '@tanstack/react-query';
import { useLatestObservedSample } from 'Networking/socket';
import {
  FetchSampleSeriesQueryArgument,
  fetchLatestSample,
  fetchSampleSeries,
} from 'Networking/http';
import { ReactComponent as IconOn } from './resources/graphics/icon-on.svg';
import { ReactComponent as IconOff } from './resources/graphics/icon-off.svg';

const ON_TEXT = 'ON';
const OFF_TEXT = 'OFF';

type EmitterDetailsBarKeyValueCellProps = {
  title?: string;
  property: Property;
  onValue: string | number | boolean;
  offValue: string | number | boolean;
};

export const EmitterDetailsBarOnOffCell: React.FC<EmitterDetailsBarKeyValueCellProps> = ({
  title,
  property,
  onValue,
  offValue,
}) => {
  const { absoluteInterval, refresh } = useSelectedInterval();

  useEffect(refresh, [property, refresh]);

  const controlBarManager = useControlBarManager();

  const dataInterval = useMemo(() => {
    return generateTimelineBarDataInterval(
      absoluteInterval,
      controlBarManager.getTimelineBarSampleRateMs()
    );
  }, [absoluteInterval, controlBarManager]);

  const latestSampleQueryArg = { properties: [property], interval: dataInterval };
  const latestSampleQuery = useQuery(fetchLatestSample.generateQueryKey(latestSampleQueryArg), () =>
    fetchLatestSample(latestSampleQueryArg)
  );

  const sampleSeriesQueryArg: FetchSampleSeriesQueryArgument = {
    properties: [property],
    interval: dataInterval,
    aggregation: 'first',
    sample: {
      unit: 'second',
      rate: Math.floor(controlBarManager.getTimelineBarSampleRateMs() / 1000),
    },
    densify: true,
  };

  const sampleSeriesQuery = useQuery(fetchSampleSeries.generateQueryKey(sampleSeriesQueryArg), () =>
    fetchSampleSeries(sampleSeriesQueryArg)
  );

  const sample = latestSampleQuery.data;
  const observedSample = useLatestObservedSample([property]);

  const mergedSample = useMemo(
    () => mergeSamples(sample, observedSample),
    [sample, observedSample]
  );

  const viewMode = useViewMode();
  const { thumbPosition } = useTimelineThumb();

  const historicalSamples = useMemo(
    () => sampleSeriesQuery.data && fill(sampleSeriesQuery.data, 'forward'),
    [sampleSeriesQuery.data]
  );

  const historicalSample = useMemo(() => {
    const samples = historicalSamples;

    if (samples && samples.length) {
      const dataIndex = getNewDataIndex(
        thumbPosition,
        dataInterval,
        controlBarManager.getTimelineBarSampleRateMs()
      );
      const invertedIndex = Math.min(
        Math.max(samples.length - 1 - dataIndex, 0),
        samples.length - 1
      );
      return samples[invertedIndex];
    }

    return undefined;
  }, [controlBarManager, dataInterval, historicalSamples, thumbPosition]);

  const sampleToDisplay = useMemo(() => {
    return viewMode === 'replay' ? historicalSample : mergedSample;
  }, [historicalSample, mergedSample, viewMode]);

  const isOn = useMemo(() => {
    const value = sampleToDisplay?.[0];

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      if (value === onValue) {
        return true;
      }

      if (value === offValue) {
        return false;
      }
    }

    return undefined;
  }, [offValue, onValue, sampleToDisplay]);

  return (
    <div key={property.hash} className={styles.emitterDetailsBarOnOffCell}>
      <p className={styles.title}>{title ?? property.displayName}</p>
      <div className={styles.body}>
        {latestSampleQuery.isLoading ? (
          <div className={styles.loadingIndicator}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        ) : typeof isOn === 'boolean' ? (
          <p className={styles.value}>
            <span className={styles.icon}>{isOn ? <IconOn /> : <IconOff />}</span>
            {isOn ? ON_TEXT : OFF_TEXT}
          </p>
        ) : (
          <p className={styles.noData}>n/a</p>
        )}
      </div>
    </div>
  );
};
