import styles from './OptionGrid.module.scss';
import { Option } from 'Components/Option';
import { CheckBox } from 'Components/CheckBox';
import clsx from 'clsx';

export type OptionGridProps = {
  options: string[];
  selectedIndexes: number[];
  disabledIndexes?: number[];
  onToggleOption: (index: number) => void;
};

export const OptionGrid: React.FC<OptionGridProps> = ({
  options,
  selectedIndexes,
  disabledIndexes = [],
  onToggleOption,
}) => {
  return (
    <div className={styles.optionGrid}>
      <div className={styles.optionGridContent}>
        {options.map((option, index) => {
          const shouldDisableOption = disabledIndexes.includes(index);

          return (
            <div
              key={index}
              className={clsx(styles.option, shouldDisableOption && styles.disabled)}
              onClick={() => onToggleOption(index)}
            >
              <Option label={option} spacing={10} disabled={shouldDisableOption}>
                <CheckBox
                  checked={selectedIndexes.includes(index)}
                  disabled={shouldDisableOption}
                />
              </Option>
            </div>
          );
        })}
      </div>
    </div>
  );
};
