import styles from './TabNavigator.module.scss';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import clsx from 'clsx';
import { MenuPickerPopup } from 'Components/MenuPickerPopup';

export type TabNavigatorComprehensiveElement = { title: string; badge?: string };
export type TabNavigatorElement = TabNavigatorComprehensiveElement | string;

type TabNavigatorProps = {
  tabs: TabNavigatorElement[];
  initialTabIndex?: number;
  currentTabIndex?: number;
  normalTextColor?: string;
  selectedTextColor?: string;
  underlineColor?: string;
  onSelectTab: (index: number) => void;
};

export const TabNavigator: React.FC<TabNavigatorProps> = ({
  tabs,
  initialTabIndex = 0,
  currentTabIndex = 0,
  normalTextColor = '#6e6e6e',
  selectedTextColor = '#000',
  underlineColor = 'rgba(0, 147, 221, 0.7)',
  onSelectTab,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialTabIndex);

  useEffect(() => {
    onSelectTab(selectedIndex);
  }, [selectedIndex, onSelectTab]);

  useEffect(() => {
    setSelectedIndex(currentTabIndex);
  }, [currentTabIndex]);

  const handleClickTab = (index: number) => {
    setSelectedIndex(index);
  };

  const navigatorElements = tabs.map<TabNavigatorComprehensiveElement>((tab) =>
    typeof tab === 'string' ? { title: tab } : tab
  );

  const [isMoreTabVisible, setIsMoreTabVisible] = useState(true);
  const [hiddenTabStartIndex, setHiddenTabMenuStartIndex] = useState(tabs.length);

  const tabsMap = useRef(new Map<number, HTMLDivElement>());

  const tabNavigatorRef = useRef<HTMLDivElement>(null);
  const moreTabRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const tabNavigator = tabNavigatorRef.current;
    const moreTab = moreTabRef.current;

    if (!tabNavigator || !moreTab) return;

    const tabNavigatorRight = tabNavigator.getBoundingClientRect().right;
    const moreTabLeft = moreTab.getBoundingClientRect().left;

    const shouldDisplayMoreTab = moreTabLeft > tabNavigatorRight;

    let newHiddenTabStartIndex = tabs.length;

    if (shouldDisplayMoreTab) {
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabsMap.current.get(i);

        if (!tab) return;

        const moreTabWidth = moreTab.getBoundingClientRect().width;
        const safeRight = tabNavigatorRight - moreTabWidth;
        const tabRight = tab.getBoundingClientRect().right;

        if (tabRight > safeRight) {
          newHiddenTabStartIndex = i;
          break;
        }
      }
    }

    setIsMoreTabVisible(shouldDisplayMoreTab);
    setHiddenTabMenuStartIndex(newHiddenTabStartIndex);
  }, [tabs.length]);

  const hiddenNavigatorElements = useMemo(
    () => navigatorElements.slice(hiddenTabStartIndex),
    [hiddenTabStartIndex, navigatorElements]
  );

  const menuPickerItems = useMemo(
    () => hiddenNavigatorElements.map((t) => t.title),
    [hiddenNavigatorElements]
  );

  const handleSelectMoreItem = useCallback(
    (index: number) => {
      setSelectedIndex(hiddenTabStartIndex + index);
    },
    [hiddenTabStartIndex]
  );

  return (
    <div
      ref={tabNavigatorRef}
      className={styles.tabNavigator}
      style={
        {
          '--normal-text-color': normalTextColor,
          '--selected-text-color': selectedTextColor,
          '--underline-color': underlineColor,
        } as CSSProperties
      }
    >
      {navigatorElements.map((navigatorElement, index) => (
        <div
          ref={(node) => {
            if (node) {
              tabsMap.current.set(index, node);
            } else {
              tabsMap.current.delete(index);
            }
          }}
          key={index}
          className={clsx(styles.tab, { [styles.selected]: index === selectedIndex })}
          onClick={() => handleClickTab(index)}
          style={index >= hiddenTabStartIndex ? { display: 'none' } : undefined}
        >
          {navigatorElement.title}
          {navigatorElement.badge && <span className={styles.badge}>{navigatorElement.badge}</span>}
        </div>
      ))}
      <div
        ref={moreTabRef}
        className={clsx(styles.tab, styles.more)}
        style={{ opacity: isMoreTabVisible ? 1 : 0 }}
      >
        More
        <IconArrowDown />
        <MenuPickerPopup
          items={menuPickerItems}
          selectedIndex={selectedIndex - hiddenTabStartIndex}
          onSelect={handleSelectMoreItem}
          onClickItem={() => {}}
          className={styles.moreMenu}
        />
      </div>
    </div>
  );
};
