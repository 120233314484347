import { useCallback } from 'react';
import { motion } from 'framer-motion';
import styles from './Switch.module.scss';
import { Transition } from 'framer-motion';
import clsx from 'clsx';

const TRANSITION_DURATION = 0.3;

const spring: Transition = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
  duration: TRANSITION_DURATION,
};

const switchVariants = {
  on: {
    backgroundColor: '#00dd8d',
    transition: {
      duration: TRANSITION_DURATION,
    },
  },
  off: {
    backgroundColor: '#d3d9e7',
    transition: {
      duration: TRANSITION_DURATION,
    },
  },
};

const handleVariants = {
  on: { x: 16, transition: spring },
  off: { x: 0, transition: spring },
};

type SwitchProps = {
  on: boolean;
  disabled?: boolean;
  onToggle: () => void;
};

export const Switch: React.FC<SwitchProps> = ({
  on: isOn,
  disabled: isDisabled = false,
  onToggle,
}) => {
  const handleClickSwitch = useCallback(() => {
    if (!isDisabled) onToggle();
  }, [isDisabled, onToggle]);

  return (
    <motion.div
      className={clsx(styles.switch, isOn && styles.on, isDisabled && styles.disabled)}
      onClick={handleClickSwitch}
      variants={switchVariants}
      animate={isOn ? 'on' : 'off'}
      initial={false}
    >
      <motion.div className={styles.handle} variants={handleVariants} />
    </motion.div>
  );
};
