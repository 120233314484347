import styles from './DateTimeRangePickerModalBody.module.scss';
import { CalendarNavigator } from '../CalendarNavigator';
import { CalendarHeader } from '../CalendarHeader';
import { CalendarBody } from '../CalendarBody';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar } from './types';
import {
  getCalendarOffsetX,
  getDisplayedDateRange,
  getInitialCalendars,
  pushCalendar,
  unshiftCalendar,
} from './helpers';
import { DateRange } from 'Components/DateTimeRangePickerModal/types';
import { OptionalTuple } from 'Types/utility';
import { TimeInput, TimeInputValue } from 'Components/TimeInput';

type DateTimeRangePickerModalBodyProps = {
  minDate?: Date;
  maxDate?: Date;
  selectedDateRange?: DateRange;
  selectedStartTime?: TimeInputValue;
  selectedEndTime?: TimeInputValue;
  startTimeError?: string;
  endTimeError?: string;
  onSelectDateRange: (dateRange: DateRange) => void;
  onChangeDisplayedDateRange: (dateRange: OptionalTuple<DateRange>) => void;
  onChangeStartTime: (value?: TimeInputValue) => void;
  onChangeEndTime: (value?: TimeInputValue) => void;
};

export const DateTimeRangePickerModalBody: React.FC<DateTimeRangePickerModalBodyProps> = ({
  minDate,
  maxDate,
  selectedDateRange,
  selectedStartTime,
  selectedEndTime,
  startTimeError,
  endTimeError,
  onSelectDateRange,
  onChangeDisplayedDateRange,
  onChangeStartTime,
  onChangeEndTime,
}) => {
  const [calendars, setCalendars] = useState<Calendar[]>(getInitialCalendars(selectedDateRange));

  const leftCalendar = useMemo(
    () => calendars.find((calendar) => calendar.order === 0) as Calendar,
    [calendars]
  );

  const rightCalendar = useMemo(
    () => calendars.find((calendar) => calendar.order === 1) as Calendar,
    [calendars]
  );

  const [hoveredDate, setHoveredDate] = useState<Date>();

  const [displayedDateRange, setDisplayedDateRange] = useState<OptionalTuple<DateRange>>(
    getDisplayedDateRange(selectedDateRange)
  );

  useEffect(() => {
    setDisplayedDateRange(getDisplayedDateRange(selectedDateRange));
  }, [selectedDateRange]);

  const handleClickPreviousNavigationButton = useCallback(() => {
    setCalendars((calendars) => unshiftCalendar(calendars));
  }, []);

  const handleClickNextNavigationButton = useCallback(() => {
    setCalendars((calendars) => pushCalendar(calendars));
  }, []);

  const handleSelectDate = useCallback(
    (date: Date) => {
      const [earliest, latest] = displayedDateRange;

      const newDisplayedDateRange = (
        earliest && latest
          ? [date, undefined]
          : earliest
          ? date > earliest
            ? [earliest, date]
            : date.getTime() === earliest.getTime()
            ? [date, new Date(date)]
            : [date, undefined]
          : [date, undefined]
      ) as DateRange;

      setDisplayedDateRange(newDisplayedDateRange);

      onChangeDisplayedDateRange(newDisplayedDateRange);

      if (newDisplayedDateRange[0] && newDisplayedDateRange[1]) {
        onSelectDateRange(newDisplayedDateRange);
      }
    },
    [displayedDateRange, onChangeDisplayedDateRange, onSelectDateRange]
  );

  const handleHoverDate = useCallback((date: Date) => {
    setHoveredDate(date);
  }, []);

  const handleUnhoverDate = useCallback(() => {
    setHoveredDate(undefined);
  }, []);

  const selectedDates = useMemo(
    () => displayedDateRange.filter((d): d is Date => !!d),
    [displayedDateRange]
  );

  const highlightedDateRange = useMemo(
    () => (selectedDates.length === 2 ? (selectedDates as DateRange) : undefined),
    [selectedDates]
  );

  const previewedDateRange = useMemo(() => {
    if (selectedDates.length !== 1 || !hoveredDate) {
      return;
    }

    if (hoveredDate <= selectedDates[0]) {
      return;
    }

    return [selectedDates[0], hoveredDate] as DateRange;
  }, [hoveredDate, selectedDates]);

  const handleChangeStartTimeInput = useCallback(
    (value?: TimeInputValue) => {
      onChangeStartTime(value);
    },
    [onChangeStartTime]
  );

  const handleChangeEndTimeInput = useCallback(
    (value?: TimeInputValue) => {
      onChangeEndTime(value);
    },
    [onChangeEndTime]
  );

  return (
    <div className={styles.dateTimeRangePickerModalBody}>
      <div className={styles.dateTimeRangePickerModalBodyCalendarSection}>
        <div className={styles.separator}></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <CalendarNavigator
              year={leftCalendar.position.year}
              month={leftCalendar.position.month}
              navigationButtonType="previous"
              onClickNavigationButton={handleClickPreviousNavigationButton}
            />
            <CalendarHeader />
          </div>
          <div className={styles.column}>
            <CalendarNavigator
              year={rightCalendar.position.year}
              month={rightCalendar.position.month}
              navigationButtonType="next"
              onClickNavigationButton={handleClickNextNavigationButton}
            />
            <CalendarHeader />
          </div>
        </div>
        <div className={styles.calendarBodies}>
          {calendars.map((calendar) => {
            const offsetX = getCalendarOffsetX(calendar);

            return (
              <div
                key={`${calendar.position.year}-${calendar.position.month}`}
                className={styles.calendarBodyWrapper}
                style={{ transform: `translate(${offsetX / 16}rem)` }}
              >
                <CalendarBody
                  minDate={minDate}
                  maxDate={maxDate}
                  visibleMonth={calendar.position.month}
                  visibleYear={calendar.position.year}
                  selectedDates={selectedDates}
                  highlightedDateRange={highlightedDateRange}
                  previewedDateRange={previewedDateRange}
                  onSelectDate={handleSelectDate}
                  onHoverDate={handleHoverDate}
                  onUnhoverDate={handleUnhoverDate}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.sectionSeparator}></div>
      <div className={styles.dateTimeRangePickerModalBodyTimeSection}>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.timeInputControl}>
              <p>Start time</p>
              <TimeInput
                value={selectedStartTime}
                className="expanded"
                onChange={handleChangeStartTimeInput}
              />
              {startTimeError && <p className={styles.error}>{startTimeError}</p>}
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.timeInputControl}>
              <p>End time</p>
              <TimeInput
                value={selectedEndTime}
                className="expanded"
                onChange={handleChangeEndTimeInput}
              />
              {endTimeError && <p className={styles.error}>{endTimeError}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
