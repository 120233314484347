import styles from './AqiLegend.module.scss';

type AqiLegendProps = {
  style?: React.CSSProperties;
};

export const AqiLegend: React.FC<AqiLegendProps> = ({ style }) => {
  return (
    <div className={styles.aqiLegend} style={style}>
      <div className={styles.title}>AQI</div>
      <div className={styles.scoreBar}>
        <div className={styles.scoreBarItem}>
          <span className={styles.scrollBarItemLabel}>0</span>
          <span className={styles.scrollBarItemTooltip}>Very Good</span>
        </div>
        <div className={styles.scoreBarItem}>
          <span className={styles.scrollBarItemLabel}>50</span>
          <span className={styles.scrollBarItemTooltip}>Good</span>
        </div>
        <div className={styles.scoreBarItem}>
          <span className={styles.scrollBarItemLabel}>100</span>
          <span className={styles.scrollBarItemTooltip}>Moderate</span>
        </div>
        <div className={styles.scoreBarItem}>
          <span className={styles.scrollBarItemLabel}>150</span>
          <span className={styles.scrollBarItemTooltip}>Ultra Sensitive Groups</span>
        </div>
        <div className={styles.scoreBarItem}>
          <span className={styles.scrollBarItemLabel}>200</span>
          <span className={styles.scrollBarItemTooltip}>Unhealthy</span>
        </div>
      </div>
    </div>
  );
};
