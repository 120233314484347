import { Emitter, Sensor } from 'Models';
import { SensorName } from 'Constants';
import { SensorUpdateMessagePayload } from '../interfaces';
import { BaseMessageTest } from '../BaseMessageTest';
import { getFirstSensorWithName } from 'Helpers/sensors';
import { NodeServerObserver } from '..';

type ImuSensor = Sensor<typeof SensorName.IMU.name>;

export class ImuMessageTest extends BaseMessageTest {
  constructor(protected observer: NodeServerObserver, private emitters: Emitter[]) {
    super(SensorName.IMU.name, observer, 3000);
  }

  protected getMessagePayloads(cycleCount: number): SensorUpdateMessagePayload[] {
    return this.emitters.reduce<SensorUpdateMessagePayload[]>((acc, emitter) => {
      const imuSensor = getFirstSensorWithName(SensorName.IMU, emitter);

      return imuSensor
        ? acc.concat(this.generatePayloadForEmitter(imuSensor, emitter, cycleCount))
        : acc;
    }, []);
  }

  private generatePayloadForEmitter(
    sensor: ImuSensor,
    emitter: Emitter,
    cycleCount: number
  ): SensorUpdateMessagePayload {
    return {
      metadata: { sensorId: sensor.id, ns: emitter.namespace, isSatcomm: false },
      roll: Math.random() * 30.0 * 2 - 30.0,
      pitch: Math.random() * 30.0 * 2 - 30.0,
      rollPeriod: 0,
      offset: 0,
      heave: 0,
      systemTime: new Date().toISOString(),
    };
  }
}
