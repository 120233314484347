import styles from './SideBar.module.scss';
import { ReactComponent as Logo } from 'Resources/graphics/logo.svg';
import { ReactComponent as IconDownload } from './resources/graphics/icon-download.svg';
import { EmitterSelector } from './components';
import { forwardRef, useCallback, useState } from 'react';
import { EmitterOption } from 'Pages/ReportsPage/interfaces';
import { PdfExportRef } from '../../interfaces';
import { isLoading } from 'Services/redux/reports';
import { useAppSelector } from 'Services/redux';

type SideBarProps = {
  emitterOptions: EmitterOption[];
  isFetchingEmitters: boolean;
  enableAllEmitter: boolean;
  onToggleAllEmitterOption: () => void;
  onToggleEmitterOption: (index: number) => void;
};

export const SideBar = forwardRef<PdfExportRef, SideBarProps>(
  (
    {
      emitterOptions,
      isFetchingEmitters,
      enableAllEmitter,
      onToggleAllEmitterOption,
      onToggleEmitterOption,
    },
    pdfExportRef
  ) => {
    const isFetchingEntries = useAppSelector(isLoading);
    const [isFetchtingPdf, setIsFetchtingPds] = useState(false);
    const exportPdfDocument = useCallback(() => {
      if (!pdfExportRef) {
        return;
      }

      if (typeof pdfExportRef === 'function') {
        return;
      }

      if (!pdfExportRef.current) {
        return;
      }
      setIsFetchtingPds(true);
      return pdfExportRef.current?.fetchPdf(() => {
        setIsFetchtingPds(false);
      });
    }, [pdfExportRef]);

    const someEmittersSelected = emitterOptions.some((option) => option.isSelected);
    return (
      <div className={styles.sideBar}>
        <div className={styles.sideBarHeader}>
          <div className={styles.yellowBar} />
          <div className={styles.blueBar} />
          <div className={styles.logoContainer}>
            <Logo />
            <sub>Beta</sub>
          </div>
        </div>
        <div className={styles.sideBarBody}>
          <EmitterSelector
            emitterOptions={emitterOptions}
            showPlaceholder={isFetchingEmitters}
            enableAllEmitter={enableAllEmitter}
            onToggleAllEmitterOption={onToggleAllEmitterOption}
            onToggleEmitterOption={onToggleEmitterOption}
          />
        </div>
        <div className={styles.sideBarFooter}>
          <button
            className={styles.downloadReportButton}
            onClick={exportPdfDocument}
            disabled={
              !someEmittersSelected || isFetchingEntries || isFetchingEmitters || isFetchtingPdf
            }
          >
            Download PDF
            <IconDownload />
          </button>
        </div>
      </div>
    );
  }
);
