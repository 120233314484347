import { SensorName } from 'Constants';
import { Sensor } from 'Models';
import { usePropertiesSumSample } from 'Pages/DashboardPage/components/RedesignedEmitterDetailsBar/hooks/usePropertiesSumSample';
import { ReactElement, useMemo } from 'react';
import { KeyValueCell } from '../../../../KeyValueCell';

interface SummarySectionPowerFactorCellProps {
  title: string;
  sensor: Sensor;
}

export function SummarySectionPowerFactorCell({
  title,
  sensor,
}: SummarySectionPowerFactorCellProps): ReactElement {
  const {
    sample: activePowerSumSample,
    isLoading: isLoadingActivePowerSum,
    properties: activePowerSumProperties,
  } = usePropertiesSumSample(sensor, SensorName.Generator.PropertyName.ActivePower);

  const {
    sample: reactivePowerSumSample,
    isLoading: isLoadingReactivePowerSum,
    properties: reactivePowerSumProperties,
  } = usePropertiesSumSample(sensor, SensorName.Generator.PropertyName.ReactivePower);

  const activePowerSum = useMemo(() => {
    if (activePowerSumProperties.length === 0) {
      return undefined;
    }

    let sumValue: number | undefined;

    for (let i = 0; i < activePowerSumProperties.length; i++) {
      const v = activePowerSumSample?.[i];
      if (typeof v === 'number') {
        if (typeof sumValue === 'number') {
          sumValue += v;
        } else {
          sumValue = v;
        }
      }
    }

    return sumValue;
  }, [activePowerSumProperties, activePowerSumSample]);

  const reactivePowerSum = useMemo(() => {
    if (reactivePowerSumProperties.length === 0) {
      return undefined;
    }

    let sumValue: number | undefined;

    for (let i = 0; i < reactivePowerSumProperties.length; i++) {
      const v = reactivePowerSumSample?.[i];
      if (typeof v === 'number') {
        if (typeof sumValue === 'number') {
          sumValue += v;
        } else {
          sumValue = v;
        }
      }
    }

    return sumValue;
  }, [reactivePowerSumProperties, reactivePowerSumSample]);

  const value = useMemo(() => {
    if (activePowerSum === undefined || reactivePowerSum === undefined) {
      return undefined;
    }

    const apparentPowerSum = Math.sqrt(activePowerSum ** 2 + reactivePowerSum ** 2);
    let powerFactor = activePowerSum / apparentPowerSum;

    if (isNaN(powerFactor)) {
      powerFactor = 0;
    }

    if (!isFinite(powerFactor)) {
      powerFactor = 0;
    }

    return { score: powerFactor };
  }, [activePowerSum, reactivePowerSum]);

  return (
    <KeyValueCell
      title={title}
      value={value}
      isLoading={isLoadingActivePowerSum || isLoadingReactivePowerSum}
    />
  );
}
