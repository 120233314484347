import { UserAccess } from 'Models';
import { UserAccess as UserAccessRecord } from '../interfaces';
import { transformIntoUser } from '../../users/transformers';

export const transformIntoUserAccess = (record: UserAccessRecord): UserAccess => {
  return {
    id: record._id,
    sensors: record.sensors,
    restrictions: record.restrictions,
    user: transformIntoUser(record.user),
    isManager: record.isManager ?? false,
    isReadAll: record.isReadAll ?? false,
    createdAt: record.createdAt,
  };
};
