import { Emitter, EmitterType } from 'Models';
import { transformIntoSensors } from './sensor.transformer';
import { expandLayout } from 'Services/redux/api/sensorGroup/transformers/layout.transformer';
import { getSensorsWithName } from 'Helpers/sensors';
import { SensorName } from 'Constants';

export const transformIntoEmitter = (record: Record<string, any>): Emitter => {
  const sensors = record.sensorSchema ? transformIntoSensors(record.sensorSchema, record._id) : [];

  const emitter: Emitter = {
    id: record._id,
    type: record.groupType as EmitterType,
    coordinate: record.position && {
      latitude: record.position.coordinates[1],
      longitude: record.position.coordinates[0],
    },
    name: record.name,
    namespace: record.ns,
    sensors,
    inProgress: !!record.inProgress,
    isManager: !!record.isManager,
    isReadAll: !!record.isReadAll,
    layout: expandLayout(record.layout, sensors),
  } as Emitter;

  emitter.usesUnifiedSensor = usesUnifiedSensors(emitter);

  return emitter;
};

function usesUnifiedSensors(emitter: Emitter): boolean {
  const hasGpsSensor = getSensorsWithName(SensorName.GPS, emitter).length > 0;
  const hasAqiSensor = getSensorsWithName(SensorName.AirQuality, emitter).length > 0;

  return !(hasGpsSensor || hasAqiSensor);
}
