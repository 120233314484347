import { SensorName } from 'Constants';
import { Property, Vessel } from 'Models';
import { Subsystem } from 'Models/subsystem';
import { useGranularPropertiesSumSample } from 'Pages/DashboardPage/components/RedesignedEmitterDetailsBar/hooks/useGranularPropertiesSumSample';
import { ReactElement, useMemo } from 'react';
import { KeyValueCell } from '../../../../KeyValueCell';

interface SummarySectionFuelMassFlowCellProps {
  title: string;
  vessel: Vessel;
}

export function SummarySectionFuelMassFlowCell({
  title,
  vessel,
}: SummarySectionFuelMassFlowCellProps): ReactElement {
  const properties = useMemo(() => {
    const engineSubsystems = vessel.hardware!.subsystems.filter((s) => s.type === 'engine');
    const boilerSubsystems = vessel.hardware!.subsystems.filter((s) => s.type === 'boiler');
    const incineratorSubsystems = vessel.hardware!.subsystems.filter(
      (s) => s.type === 'incinerator'
    );

    const ret: Property[] = [];

    ret.push(...getProperties(vessel, engineSubsystems, 'flowDifferential'));
    ret.push(...getProperties(vessel, boilerSubsystems, 'massFlowRate'));
    ret.push(...getProperties(vessel, incineratorSubsystems, 'flowDifferential'));

    return ret;
  }, [vessel]);

  const { sample: sampleToDisplay, isLoading } = useGranularPropertiesSumSample(properties);

  const value = useMemo(() => {
    if (properties.length === 0) {
      return undefined;
    }

    let sumValue: number | undefined;

    for (let i = 0; i < properties.length; i++) {
      const v = sampleToDisplay?.[i];
      if (typeof v === 'number') {
        if (typeof sumValue === 'number') {
          sumValue += v;
        } else {
          sumValue = v;
        }
      }
    }

    if (sumValue === undefined) {
      return undefined;
    } else {
      return { score: sumValue, unit: properties[0].unit };
    }
  }, [properties, sampleToDisplay]);

  return (
    <KeyValueCell
      title={title}
      value={value}
      isLoading={isLoading}
      showPlaceholder={properties.length === 0}
    />
  );
}

function getProperties(vessel: Vessel, subsystems: Subsystem[], propertyName: string): Property[] {
  const virtSensor = vessel.sensors.find((s) => s.name === SensorName.Virt.name);

  return (virtSensor?.properties ?? []).filter((property) => {
    for (const subsystem of subsystems) {
      if (property.name.name === `${subsystem.id}.${propertyName}`) {
        return true;
      }
    }
    return false;
  });
}
