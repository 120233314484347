import styles from './MultiSection.module.scss';
import { FC } from 'react';
import { EmitterDetailsBarSection } from '../../EmitterDetailsBarSection';
import { EmitterDetailsBarSectionHeaderRegular } from '../../EmitterDetailsBarSectionHeaderRegular';

type MultiSectionProps = {
  title: string;
  elements: { title?: string; component: React.ReactNode }[];
  containedWithinSection?: boolean;
};

export const MultiSection: FC<MultiSectionProps> = ({
  title,
  elements,
  containedWithinSection = false,
}) => {
  return containedWithinSection ? (
    <>
      {elements.map((e, i) => (
        <div key={i} className={styles.multiSectionElement}>
          {e.title && <div className={styles.multiSectionElementHeader}>{e.title}</div>}
          <div className={styles.multiSectionElementBody}>{e.component}</div>
        </div>
      ))}
    </>
  ) : (
    <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title={title} />}>
      {elements.map((e, i) => (
        <div key={i} className={styles.multiSectionElement}>
          {e.title && <div className={styles.multiSectionElementHeader}>{e.title}</div>}
          <div className={styles.multiSectionElementBody}>{e.component}</div>
        </div>
      ))}
    </EmitterDetailsBarSection>
  );
};
