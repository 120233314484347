import { throttle } from 'lodash';
import { Socket } from 'socket.io-client';
import { Property } from 'Models';
import { getUniqueProperties } from 'Services/redux/api/sensor/util';
import { SubscriptionMessagePayload } from './interfaces/subscription-message-payload';

const MESSSAGE_THROTTLE_INTERVAL = 1000;

interface DispatchItem {
  property: Property;
  createdAt: Date;
}

interface DispatchQueue {
  [propertyHash: Property['hash']]: DispatchItem;
}
export class MessageDispatcher {
  private subscribeDispatchQueue: DispatchQueue = {};
  private unsubscribeDispatchQueue: DispatchQueue = {};

  private throttledSendSubscribeMessage: typeof this.sendSubscribeMessage;
  private throttledSendUnsubscribeMessage: typeof this.sendUnsubscribeMessage;

  constructor(readonly socket: Socket) {
    this.throttledSendSubscribeMessage = throttle(
      this.sendSubscribeMessage.bind(this),
      MESSSAGE_THROTTLE_INTERVAL,
      { leading: false, trailing: true }
    );

    this.throttledSendUnsubscribeMessage = throttle(
      this.sendUnsubscribeMessage.bind(this),
      MESSSAGE_THROTTLE_INTERVAL,
      { leading: false, trailing: true }
    );
  }

  /**
   * Queue send subscribe message to server.
   *
   * @param properties - Properties to subscribe
   */
  queueSendSubscribeMessage(properties: Property[]) {
    if (!properties.length) return;

    for (const property of properties) {
      this.subscribeDispatchQueue[property.hash] = { property, createdAt: new Date() };
    }

    this.throttledSendSubscribeMessage();
  }

  /**
   * Queue send unsubscribe message to server.
   *
   * @param properties - Properties to unsubscribe
   */
  queueSendUnsubscribeMessage(properties: Property[]) {
    if (!properties.length) return;

    for (const property of properties) {
      this.unsubscribeDispatchQueue[property.hash] = { property, createdAt: new Date() };
    }

    this.throttledSendUnsubscribeMessage();
  }

  /**
   * Send subscribe message to server.
   */
  private sendSubscribeMessage() {
    const subscribeDispatchItems: DispatchItem[] = Object.values(this.subscribeDispatchQueue);

    if (!subscribeDispatchItems.length) return;

    const propertiesToSubscribe = Object.values(subscribeDispatchItems).reduce<Property[]>(
      (acc, subscribeDispatchItem) => {
        const property = subscribeDispatchItem.property;
        const unsubscribeDispatchItem = this.unsubscribeDispatchQueue[property.hash];

        return !unsubscribeDispatchItem ||
          subscribeDispatchItem.createdAt > unsubscribeDispatchItem.createdAt
          ? acc.concat(property)
          : acc;
      },
      []
    );

    const payload = this.generateMessagePayload(propertiesToSubscribe);

    this.subscribeDispatchQueue = {};

    this.socket.emit('subscribe', payload);
  }

  /**
   * Send unsubscribe message to server.
   */
  private sendUnsubscribeMessage() {
    const unsubscribeDispatchItems: DispatchItem[] = Object.values(this.unsubscribeDispatchQueue);

    if (!unsubscribeDispatchItems.length) return;

    const propertiesToUnsubscribe = unsubscribeDispatchItems.reduce<Property[]>(
      (acc, unsubscribeDispatchItem) => {
        const property = unsubscribeDispatchItem.property;
        const subscribeDispatchItem = this.subscribeDispatchQueue[property.hash];

        return !subscribeDispatchItem ||
          unsubscribeDispatchItem.createdAt > subscribeDispatchItem.createdAt
          ? acc.concat(property)
          : acc;
      },
      []
    );

    const payload = this.generateMessagePayload(propertiesToUnsubscribe);

    this.unsubscribeDispatchQueue = {};

    this.socket.emit('unsubscribe', payload);
  }

  /**
   * Generate socket message payload for subscription.
   *
   * @param properties - Properties to subscribe or unsubscribe
   * @returns `SubscriptionMessagePayload`
   */
  private generateMessagePayload(properties: Property[]): SubscriptionMessagePayload {
    const uniqueProperties = getUniqueProperties(properties);

    return uniqueProperties.reduce<SubscriptionMessagePayload>(
      (acc, property) => {
        return {
          sensors: {
            ...acc.sensors,
            [property.sensorId]: (acc.sensors[property.sensorId] ?? []).concat(property.name.name),
          },
          sensorGroupIds: Array.from(new Set([...acc.sensorGroupIds, property.sensorGroupId])),
        };
      },
      { sensors: {}, sensorGroupIds: [] }
    );
  }
}
