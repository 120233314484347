import { FC, PropsWithChildren, useRef } from 'react';
import { AbsoluteInterval, Interval } from 'Constants';
import { ControlBarContext, IControlBarContext } from './ControlBarContext';
import { ControlBarManager } from '../ControlBarManager';

type ControlBarProviderProps = {
  initialInterval: Interval;
  initialTimelineBarInterval: AbsoluteInterval;
  initialTimelineBarSampleRateMs: number;
  initialThumbPosition: number;
};

export const ControlBarProvider: FC<PropsWithChildren<ControlBarProviderProps>> = ({
  initialInterval,
  initialTimelineBarInterval,
  initialTimelineBarSampleRateMs,
  initialThumbPosition,
  children,
}) => {
  const contextRef = useRef<IControlBarContext>({
    manager: new ControlBarManager(
      initialInterval,
      initialTimelineBarInterval,
      initialTimelineBarSampleRateMs,
      initialThumbPosition
    ),
  });

  return (
    <ControlBarContext.Provider value={contextRef.current}>{children}</ControlBarContext.Provider>
  );
};
