import styles from './CompliantPopup.module.scss';
import { ReactComponent as CheckIcon } from './resources/graphics/check-icon.svg';
import { ReactComponent as CrossIcon } from './resources/graphics/cross-icon.svg';

export const compliantItemCreator = (level: boolean, message: string): CompliantPopupItem => ({
  level,
  message,
});

export type CompliantPopupItem = {
  level: boolean;
  message: string;
};

type CompliantPopupProps = {
  items: CompliantPopupItem[];
};

export const CompliantPopup: React.FC<CompliantPopupProps> = ({ items }) => {
  return (
    <div className={styles.compliantPopup}>
      {items.map(({ level, message }) => (
        <div className={styles.checklist}>
          <div className={styles.icon}>{level ? <CheckIcon /> : <CrossIcon />}</div>
          <p>{message}</p>
        </div>
      ))}
    </div>
  );
};
