import React, { useLayoutEffect, useRef, useState } from 'react';
import { TicksRendererProps, AxisScale } from '@visx/axis';
import { Group } from '@visx/group';
import { Line } from '@visx/shape';

export const TimestampTicks: React.FC<TicksRendererProps<AxisScale>> = (props) => {
  const tickGroupsMap = useRef(new Map<number, SVGElement>());

  const [tickIndexesToHide, setTickIndexesToHide] = useState<number[]>([]);

  useLayoutEffect(() => {
    const tickGroups = Array.from(tickGroupsMap.current.values());

    const hiddenTickIndexes: number[] = [];

    // There's no chance for 1 tick group or 0 tick group to overlap.
    if (tickGroups.length > 1) {
      for (let i = 1; i < tickGroups.length; i++) {
        const curTickGroup = tickGroups[i];

        for (let j = i - 1; j >= 0; j--) {
          const prevTickGroup = tickGroups[j];

          if (hiddenTickIndexes.includes(j)) {
            continue;
          }

          const curTickGroupRect = curTickGroup.getBoundingClientRect();
          const prevTickGroupRect = prevTickGroup.getBoundingClientRect();

          const overlapsWithPrevTickGroup = curTickGroupRect.left <= prevTickGroupRect.right;

          if (overlapsWithPrevTickGroup) {
            hiddenTickIndexes.push(i);
            break;
          }
        }
      }
    }

    setTickIndexesToHide(hiddenTickIndexes);
  }, [props.ticks]);

  return (
    <>
      {props.ticks.map((tick, index) => {
        const hidden = tickIndexesToHide.includes(index);

        return (
          <Group
            key={index}
            innerRef={(node) => {
              if (node) {
                tickGroupsMap.current.set(index, node);
              } else {
                tickGroupsMap.current.delete(index);
              }
            }}
            style={{ opacity: hidden ? 0 : 1.0 }}
          >
            <Line
              from={tick.from}
              to={tick.to}
              stroke="#D3D9E7"
              strokeWidth={1}
              strokeLinecap="square"
            />
            {props.tickComponent!({
              x: tick.from.x,
              y: tick.to.y + 10,
              formattedValue: tick.formattedValue,
            })}
          </Group>
        );
      })}
    </>
  );
};
