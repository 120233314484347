import { LayersAvailability } from './type';
import {
  AvailableMapAqiName,
  selectedAqiLayerSelector,
  setSelectedAqiLayer,
} from 'Services/redux/map';
import { RadioButtonOption } from '../Options';
import { SensorName } from 'Constants';

export const getAqiSectionOptions = (layersAvalability: LayersAvailability) => {
  const options: JSX.Element[] = [];

  if (layersAvalability.hasNo2Property) {
    options.push(
      <RadioButtonOption<AvailableMapAqiName>
        key={SensorName.AirQuality.PropertyName.NO2Aqi.hash}
        identifier="NO2Aqi"
        label="NO2"
        selectedIdentifierSelector={selectedAqiLayerSelector}
        selectIdentifierAction={setSelectedAqiLayer}
      />
    );
  }

  if (layersAvalability.hasPm25Property) {
    options.push(
      <RadioButtonOption<AvailableMapAqiName>
        key={SensorName.AirQuality.PropertyName.PM25Aqi.hash}
        identifier="PM25Aqi"
        label="PM25"
        selectedIdentifierSelector={selectedAqiLayerSelector}
        selectIdentifierAction={setSelectedAqiLayer}
      />
    );
  }

  if (layersAvalability.hasPm10Property) {
    options.push(
      <RadioButtonOption<AvailableMapAqiName>
        key={SensorName.AirQuality.PropertyName.PM10Aqi.hash}
        identifier="PM10Aqi"
        label="PM10"
        selectedIdentifierSelector={selectedAqiLayerSelector}
        selectIdentifierAction={setSelectedAqiLayer}
      />
    );
  }

  if (options.length) {
    options.unshift(
      <RadioButtonOption<AvailableMapAqiName>
        key="no_aqi"
        identifier={undefined}
        label="None"
        selectedIdentifierSelector={selectedAqiLayerSelector}
        selectIdentifierAction={setSelectedAqiLayer}
      />
    );
  }

  return options;
};
