import styles from './EmitterDetailsBar.module.scss';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import {
  BiaxialChartSectionExpanded,
  ImuSectionExpanded,
  LoadProfileSectionExpanded,
  Connection,
  Emitter,
  SectionType,
  TabbedSectionExpanded,
  FormattingConfig,
  MultiSectionExpanded,
  SectionTypeExpanded,
  CamerasSectionExpanded,
  ComprehensiveCellsSectionExpanded,
  ComprehensiveCellsNoTitleSectionExpanded,
  OptionSectionExpanded,
} from 'Models';
import { Dialog } from 'Components/Dialog';
import { isDock, isVessel } from 'Helpers/emitter';
import { removeNulls } from 'Helpers/array';

import {
  EmitterDetailsBarHeader,
  OperationalProfileSection,
  StabilitySection,
  ChartSection,
  TabbedSection,
  MultiSection,
  CellsSection,
  CellsNoTitleSection,
  VideoFeedSection,
  OptionSection,
} from './components';
import { EmitterDetailsBarContextType, EmitterDetailsBarContext } from './EmitterDetailsBarContext';
import { EditVesselModal } from './components/EditVesselModal';
import { EmitterAccessorsModal } from './components/EmitterAccessorsModal';
import { useGetConnectionsQuery } from 'Services/redux/sensorStream/activity';
import { EditDockModal } from './components/EditDockModal';
import { ExportSensorModal } from './components/ExportSensorModal/ExportSensorModal';
import { EMITTER_DETAILS_BAR_ID } from './constants/layout';

const EMPTY_CONNECTIONS: Connection[] = [];

type EmitterDetailsBarProps = {
  emitter: Emitter;
  onClickCollapseButton: () => void;
};

function sectionToComponent(
  parentSection: SectionTypeExpanded | undefined,
  section: SectionTypeExpanded,
  key: number,
  formatting: FormattingConfig,
  conn?: Connection
): ReactElement | undefined {
  switch (section.type) {
    case SectionType.BiaxialChart:
      const biaxialSection = section as BiaxialChartSectionExpanded;
      return (
        <ChartSection
          key={key}
          menuItems={biaxialSection.menu}
          selected1={biaxialSection.initialSelection1}
          selected2={biaxialSection.initialSelection2}
          formatting={formatting}
        />
      );
    case SectionType.ComprehensiveCells:
      const comprehensiveCellsSection = section as ComprehensiveCellsSectionExpanded;
      return (
        <CellsSection
          key={key}
          title={comprehensiveCellsSection.title}
          cells={comprehensiveCellsSection.cells}
          formatting={formatting}
          containedWithinSection={!!parentSection}
        />
      );
    case SectionType.ComprehensiveCellsNoTitle:
      const comprehensiveCellsNoTitleSection = section as ComprehensiveCellsNoTitleSectionExpanded;
      return (
        <CellsNoTitleSection
          key={key}
          cells={comprehensiveCellsNoTitleSection.cells}
          formatting={formatting}
        />
      );
    case SectionType.Imu:
      const imuSection = section as ImuSectionExpanded;
      return (
        <StabilitySection
          key={key}
          title={imuSection.title}
          roll={imuSection.roll}
          pitch={imuSection.pitch}
        />
      );
    case SectionType.Cameras:
      const camerasSection = section as CamerasSectionExpanded;
      return (
        <VideoFeedSection key={key} title={camerasSection.title} cameras={camerasSection.cameras} />
      );
    case SectionType.LoadProfile:
      const loadProfileSection = section as LoadProfileSectionExpanded;
      return (
        <OperationalProfileSection
          key={key}
          title={loadProfileSection.title}
          generatorSensors={loadProfileSection.sensors}
          connectionType={conn?.type}
        />
      );
    case SectionType.Tabbed:
      const tabbedSection = section as TabbedSectionExpanded;
      return (
        <TabbedSection
          key={key}
          title={tabbedSection.title}
          tabs={tabbedSection.sections
            .map((s, i) => ({
              title: s.title,
              sectionComponent: sectionToComponent(tabbedSection, s as any, i, formatting, conn),
            }))
            .filter((s) => s.sectionComponent)}
          containedWithinSection={!!parentSection}
        />
      );
    case SectionType.Multi:
      const multiSection = section as MultiSectionExpanded;
      return (
        <MultiSection
          title={multiSection.title}
          key={key}
          elements={multiSection.sections
            .map((s, i) => {
              return {
                title: (s as any)['title'] as string,
                component: sectionToComponent(multiSection, s, i, formatting, conn),
              };
            })
            .filter((s) => s.component)}
          containedWithinSection={!!parentSection}
        />
      );
    case SectionType.Option:
      const optionSection = section as OptionSectionExpanded;
      return (
        <OptionSection
          key={key}
          title={optionSection.title}
          sections={optionSection.sections}
          componentForSection={(section) => {
            return sectionToComponent(optionSection, section, 1, formatting);
          }}
          containedWithinSection={!!parentSection}
        />
      );
  }
}
export const EmitterDetailsBar: React.FC<EmitterDetailsBarProps> = ({
  emitter,
  onClickCollapseButton,
}) => {
  const [showsInstallationUnderwayDialog, setShowsInstallationUnderwayDialog] = useState(
    emitter.inProgress
  );
  const [isEditVesselModalVisible, setIsEditVesselModalVisible] = useState(false);
  const [isEmitterAccessorsModalVisible, setIsEmitterAccessorsModalVisible] = useState(false);
  const [isExportSensorModalVisible, setIsExportSensorModalVisible] = useState(false);

  const [isEditDockModalVisible, setIsEditDockModalVisible] = useState(false);

  const [isSectionPopupDisabled, setIsSectionPopupDisabled] = useState(false);

  // We want to get all the connections, not only the selected
  // selected emitter connection. This way, we can take advantage
  // of cache.
  const { data: connections = EMPTY_CONNECTIONS } = useGetConnectionsQuery();

  const connection = useMemo(
    () => connections.find((c) => c.emitterId === emitter.id),
    [connections, emitter.id]
  );

  const handleScroll = useCallback(() => {
    setIsSectionPopupDisabled(true);
  }, []);

  const handleEnableSectionPopup = useCallback(() => {
    setIsSectionPopupDisabled(false);
  }, []);

  const handleShowEditEmitterModal = useCallback(() => {
    if (isVessel(emitter)) {
      setIsEditVesselModalVisible(true);
    } else {
      setIsEditDockModalVisible(true);
    }
  }, [emitter]);

  const handleCloseEditVesselModal = useCallback(() => {
    setIsEditVesselModalVisible(false);
  }, []);

  const handleCloseEditDockModal = useCallback(() => {
    setIsEditDockModalVisible(false);
  }, []);

  const handleShowEmitterAccessorsModal = useCallback(() => {
    setIsEmitterAccessorsModalVisible(true);
  }, []);

  const handleCloseEmitterAccessorsModal = useCallback(() => {
    setIsEmitterAccessorsModalVisible(false);
  }, []);

  const handleShowExportSensorModal = useCallback(() => {
    setIsExportSensorModalVisible(true);
  }, []);

  const handleCloseExportSensorModal = useCallback(() => {
    setIsExportSensorModalVisible(false);
  }, []);

  const layoutComponents: ReactElement[] = useMemo(() => {
    return emitter.layout.sections
      .map((s, i) => sectionToComponent(undefined, s, i, emitter.layout.formatting, connection))
      .filter(removeNulls);
  }, [emitter.layout.sections, emitter.layout.formatting, connection]);

  const handleSelectCancelInInstallationUnderwayDialog = useCallback(
    () => setShowsInstallationUnderwayDialog(false),
    []
  );

  const contextValue: EmitterDetailsBarContextType = {
    isSectionPopupDisabled,
    handleEnableSectionPopup,
  };

  return (
    <>
      <div className={styles.emitterDetailsBar}>
        <EmitterDetailsBarHeader
          title={emitter.name}
          isManager={emitter.isManager}
          connectionType={connection?.type}
          sections={[]} //TODO we will define what goes in here, right now nothing
          onClickCollapseButton={onClickCollapseButton}
          coordinate={emitter.coordinate}
          onClickEdit={handleShowEditEmitterModal}
          onClickShare={handleShowEmitterAccessorsModal}
          onClickExport={handleShowExportSensorModal}
        />
        <div
          className={styles.emitterDetailsBarBody}
          onScroll={handleScroll}
          id={EMITTER_DETAILS_BAR_ID}
        >
          <EmitterDetailsBarContext.Provider value={contextValue}>
            {layoutComponents}
          </EmitterDetailsBarContext.Provider>
        </div>
        {showsInstallationUnderwayDialog && (
          <Dialog
            level="info"
            title="Notice"
            message="This vessel's hardware installation is currently underway. Please refrain from using this data for any sensitive analysis. A notification will be issued once the installation has been completed."
            cancelButtonTitle="OK"
            onSelectCancel={handleSelectCancelInInstallationUnderwayDialog}
          />
        )}
      </div>
      {isEditVesselModalVisible && isVessel(emitter) && (
        <EditVesselModal vessel={emitter} onClose={handleCloseEditVesselModal} />
      )}
      {isEditDockModalVisible && isDock(emitter) && (
        <EditDockModal dock={emitter} onClose={handleCloseEditDockModal} />
      )}
      {isEmitterAccessorsModalVisible && (
        <EmitterAccessorsModal emitter={emitter} onClose={handleCloseEmitterAccessorsModal} />
      )}
      {isExportSensorModalVisible && (
        <ExportSensorModal emitter={emitter} onClose={handleCloseExportSensorModal} />
      )}
    </>
  );
};
