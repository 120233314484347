import styles from './ReportsPageContent.module.scss';
import { useCallback, useEffect, useMemo, useState, forwardRef } from 'react';
import { Select } from 'Components/Select';
import { EMISSION_OPTIONS, EmissionOption, MONTH_OPTIONS } from './constants';
import { DetailSection, SummarySection, SummarySectionLoader, PdfReport } from './components';
import { PdfExportRef } from '../../interfaces';
import { Emitter } from 'Models';
import { StaticInterval } from 'Constants';
import { useGetEntriesByEmitter } from './hooks/useGetEntriesByEmitter';
import { getInterval } from './helpers/interval';
import containerShipAnimation from 'Resources/json/sailplan-vessel-animation.json';
import Lottie from 'lottie-react';
import { generateColors } from './helpers/color';
import { ColoredEmitter } from './interfaces/util';
import { setIsLoading } from 'Services/redux/reports';
import { useAppDispatch } from 'Services/redux';
import { UnitOption, generateUnitOptions } from './helpers/unit';

type MonthOption = (typeof MONTH_OPTIONS)[number];
type YearOption = { label: string; year: number };

type ReportsPageContentProps = {
  emitters: Emitter[];
};

export const ReportsPageContent = forwardRef<PdfExportRef, ReportsPageContentProps>(
  ({ emitters }, pdfRef) => {
    const dispatch = useAppDispatch();

    const initialMonthOption = useMemo(() => {
      const currentMonth = new Date().getMonth();
      return MONTH_OPTIONS[currentMonth];
    }, []);

    const yearOptions = useMemo(() => {
      const START_YEAR = 2020;
      const currentYear = new Date().getFullYear();

      const options: YearOption[] = [];

      for (let i = START_YEAR; i <= currentYear; i++) {
        options.push({ label: String(i), year: i });
      }

      return options;
    }, []);

    const [selectedEmissionOption, setSelectedEmissionOption] = useState<EmissionOption>(
      EMISSION_OPTIONS[0]
    );
    const [selectedMonthOption, setSelectedMonthOption] = useState<MonthOption>(initialMonthOption);
    const [selectedYearOption, setSelectedYearOption] = useState<YearOption>(
      yearOptions[yearOptions.length - 1]
    );

    const unitOptions: UnitOption[] = useMemo(
      () => generateUnitOptions(emitters, selectedEmissionOption.propertyNames),
      [emitters, selectedEmissionOption.propertyNames]
    );

    const [selectedUnitOption, setSelectedUnitOption] = useState<UnitOption>();

    useEffect(() => {
      setSelectedUnitOption(unitOptions[0]);
    }, [unitOptions]);

    const handleSelectEmissionOption = useCallback((option: EmissionOption) => {
      setSelectedEmissionOption(option);
    }, []);

    const handleSelectUnitOption = useCallback((option: UnitOption) => {
      setSelectedUnitOption(option);
    }, []);

    const handleSelectMonthOption = useCallback((option: MonthOption) => {
      setSelectedMonthOption(option);
    }, []);

    const handleSelectYearOption = useCallback((option: YearOption) => {
      setSelectedYearOption(option);
    }, []);

    const interval: Required<StaticInterval> = useMemo(() => {
      return getInterval(selectedYearOption.year, selectedMonthOption.monthIndex);
    }, [selectedMonthOption, selectedYearOption]);

    const { entriesByEmitter, isFetching, refetch } = useGetEntriesByEmitter({
      emitters,
      interval,
      propertyNames: selectedEmissionOption.propertyNames,
    });

    useEffect(() => {
      dispatch(setIsLoading(isFetching));
    }, [dispatch, isFetching]);

    useEffect(() => {
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emitters, selectedMonthOption, selectedYearOption]);

    const coloredEmitters: ColoredEmitter[] = useMemo(() => {
      const colors = generateColors(emitters.length);
      return emitters.map((emitter, index) => ({ emitter, color: colors[index] }));
    }, [emitters]);

    return (
      <div className={styles.reportsPageContent}>
        <div className={styles.header}>Emissions Reporting (monthly)</div>
        <div className={styles.body}>
          <div className={styles.control}>
            <Select
              options={EMISSION_OPTIONS}
              selectedOption={selectedEmissionOption}
              onSelectOption={handleSelectEmissionOption}
              className={styles.select}
            />
            {unitOptions.length > 0 && selectedUnitOption && (
              <Select
                options={unitOptions}
                selectedOption={selectedUnitOption}
                onSelectOption={handleSelectUnitOption}
                className={styles.select}
              />
            )}
            <Select
              options={MONTH_OPTIONS}
              selectedOption={selectedMonthOption}
              onSelectOption={handleSelectMonthOption}
              className={styles.select}
            />
            <Select
              options={yearOptions}
              selectedOption={selectedYearOption}
              onSelectOption={handleSelectYearOption}
              className={styles.select}
            />
          </div>
          <div className={styles.content}>
            {!emitters.length ? (
              <div className={styles.emptyPlaceholderContainer}>
                <div className={styles.emptyPlaceholder}>
                  <Lottie animationData={containerShipAnimation} loop={true} />
                  <p>No vessels selected</p>
                </div>
              </div>
            ) : isFetching ? (
              <SummarySectionLoader />
            ) : (
              <>
                <SummarySection
                  coloredEmitters={coloredEmitters}
                  entriesByEmitter={entriesByEmitter}
                  conversion={selectedUnitOption?.conversion}
                />
                <div className={styles.chartSectionGroup}>
                  {coloredEmitters.map((coloredEmitter) => (
                    <DetailSection
                      key={coloredEmitter.emitter.id}
                      header={selectedEmissionOption.label}
                      coloredEmitter={coloredEmitter}
                      entries={entriesByEmitter[coloredEmitter.emitter.id] ?? []}
                      conversion={selectedUnitOption?.conversion}
                    />
                  ))}
                </div>
                <PdfReport
                  ref={pdfRef}
                  emissionName={selectedEmissionOption.label}
                  interval={interval}
                  coloredEmitters={coloredEmitters}
                  entriesByEmitter={entriesByEmitter}
                  conversion={selectedUnitOption?.conversion}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);
