import styles from '../../../LayerSelector.module.scss';
import { LayerSelectorMenuSection } from '../../LayerSelectorMenuSection';
import { showsEcaLayerSelector, setShowsEcaLayer } from 'Services/redux/map';
import { CheckBoxOption } from '../../Options';
import { LayersAvailability } from '../type';
import { getAqiSectionOptions } from '../helpers';

type DefaultMenuProps = {
  layersAvailability: LayersAvailability;
};

export const DefaultMenu: React.FC<DefaultMenuProps> = ({ layersAvailability }) => {
  const aqiSectionOptions = getAqiSectionOptions(layersAvailability);

  return (
    <div className={styles.menu}>
      <LayerSelectorMenuSection title="Charts">
        <CheckBoxOption
          label="ECAs"
          checkedSelector={showsEcaLayerSelector}
          checkedAction={setShowsEcaLayer}
        />
      </LayerSelectorMenuSection>
      {!!aqiSectionOptions.length && (
        <LayerSelectorMenuSection title="AQI">{aqiSectionOptions}</LayerSelectorMenuSection>
      )}
    </div>
  );
};
