import { appConfiguration } from 'Config';
import { useEffect } from 'react';
import { NodeServerObserver } from '..';
import { MessageTest } from '../interfaces';
import { useNodeServerObserver } from './useNodeServerObserver';

type MessageTestArgumentTypes<F extends MessageTest> = ConstructorParameters<F> extends [
  observer: NodeServerObserver,
  ...args: infer A
]
  ? A
  : never;

export function useMessageTest<T extends MessageTest>(
  MessageTestClass: T,
  ...args: MessageTestArgumentTypes<T>
) {
  const observer = useNodeServerObserver();

  if (observer.acceptIncomingMessages && appConfiguration.simulateSocket) {
    observer.acceptIncomingMessages = false;
  }

  useEffect(() => {
    if (!appConfiguration.simulateSocket) return;

    const messageTest = new MessageTestClass(observer, ...args);
    messageTest.run();

    return () => messageTest.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MessageTestClass, observer, ...args]);
}
