import { useEffect } from 'react';
import { Source, Layer, LayerProps } from 'react-map-gl';
import { useAppDispatch } from 'Services/redux';
import { setIsLoadingPositionHistoryLayer } from 'Services/redux/map';
import { Vessel } from 'Models';
import { MapLayerProps } from '../../../interfaces';
import { useGetPositionHistory } from './hooks/useGetPositionHistory';

import { MapRef } from 'react-map-gl';

const BASE_LAYER_PROPS: LayerProps = {
  type: 'line',
  paint: {
    'line-color': '#0093DD',
    'line-width': 2,
    'line-dasharray': [4, 4],
  },
  layout: {
    'line-cap': 'butt',
  },
};

export type PositionHistoryLayerProps = {
  vessel: Vessel;
  mapRef: MapRef;
} & MapLayerProps;

export const PositionHistoryLayer: React.FC<PositionHistoryLayerProps> = ({
  vessel,
  id,
  beforeId,
  mapRef,
}) => {
  const dispatch = useAppDispatch();

  const { entriesAsGeoJson, isFetching } = useGetPositionHistory(vessel, mapRef);

  useEffect(() => {
    dispatch(setIsLoadingPositionHistoryLayer(isFetching));

    return () => {
      dispatch(setIsLoadingPositionHistoryLayer(false));
    };
  }, [isFetching, dispatch]);

  const layerProps = { id, ...BASE_LAYER_PROPS };

  if (beforeId) {
    layerProps.beforeId = beforeId;
  }

  return (
    <Source id={id} type="geojson" data={entriesAsGeoJson}>
      <Layer {...layerProps} />
    </Source>
  );
};
