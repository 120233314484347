import styles from './MenuPickerPopup.module.scss';
import { forwardRef, MouseEventHandler, useCallback } from 'react';
import clsx from 'clsx';

type MenuPickerPopupProps = {
  items: string[];
  selectedIndex?: number;
  onSelect: (selectedIndex: number) => void;
  onClickItem: () => void;
  className?: string;
};

export const MenuPickerPopup = forwardRef<HTMLDivElement, MenuPickerPopupProps>(
  ({ items, selectedIndex, onSelect, onClickItem, className }, ref) => {
    const handleClickButton: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        const menuIndexString = event.currentTarget.dataset.menuIndex;

        if (typeof menuIndexString !== 'string') return;

        const menuIndex = parseInt(menuIndexString);

        onSelect(menuIndex);
        onClickItem();
      },
      [onSelect, onClickItem]
    );

    return (
      <div className={clsx(styles.menuPickerPopup, className)} ref={ref}>
        {items.map((item, index) => (
          <button
            key={index}
            className={clsx(styles.menu, index === selectedIndex && styles.selected)}
            onClick={handleClickButton}
            data-menu-index={index}
          >
            {item}
          </button>
        ))}
      </div>
    );
  }
);
