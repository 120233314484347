import styles from './OptionPopup.module.scss';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { CSSProperties, useEffect, useLayoutEffect, useRef, useState } from 'react';

enum PopupDirection {
  Up,
  Down,
}

type OptionPopupProps = {
  options: string[];
  selectedIndex?: number;
  onSelectOption: (index: number) => void;
  onClickOutside: () => void;
  sourceRef: React.RefObject<HTMLElement>;
};

export const OptionPopup: React.FC<OptionPopupProps> = ({
  options,
  selectedIndex,
  onSelectOption,
  onClickOutside,
  sourceRef,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const popup = popupRef.current;
      const source = sourceRef.current;

      if (
        !popup ||
        !(e.target instanceof Element) ||
        popup.contains(e.target) ||
        (source && source.contains(e.target))
      ) {
        return;
      }

      onClickOutside();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, sourceRef]);

  const [popupLayout, setPopupLayout] = useState<CSSProperties>({});
  const [popupDirection, setPopupDirection] = useState(PopupDirection.Down);

  useLayoutEffect(() => {
    const source = sourceRef.current;
    const popup = popupRef.current;

    if (!source || !popup) return;

    const sourceBounds = source.getBoundingClientRect();
    const popupBounds = popup.getBoundingClientRect();
    const popupRight = sourceBounds.left + popupBounds.width;
    const popupBottom = sourceBounds.top + popupBounds.height;

    const RIGHT_INSET = 20;
    const BOTTOM_INSET = 20;

    const overflowsX = popupRight - window.innerWidth + RIGHT_INSET;
    const overflowsY = popupBottom - window.innerHeight + BOTTOM_INSET;

    const correction = Math.min(0, -overflowsX);
    const popupLeft = sourceBounds.left + correction;

    const arrowLeft = sourceBounds.left + sourceBounds.width / 2 - popupLeft;

    const direction = overflowsY > 0 ? PopupDirection.Up : PopupDirection.Down;

    const layout = {
      position: 'absolute',
      left: popupLeft,
      top:
        direction === PopupDirection.Down
          ? sourceBounds.bottom + 10
          : sourceBounds.top - 10 - popupBounds.height,
      zIndex: 1000,
      '--arrow-left': `${arrowLeft}px`,
    };

    setPopupLayout(layout as CSSProperties);
    setPopupDirection(direction);
  }, [sourceRef]);

  return createPortal(
    <div
      ref={popupRef}
      className={clsx(
        styles.optionPopup,
        popupDirection === PopupDirection.Down ? styles.down : styles.up
      )}
      style={popupLayout}
    >
      <ul>
        {options.map((option, index) => (
          <li
            key={index}
            className={clsx(styles.option, index === selectedIndex && styles.selected)}
            onClick={() => onSelectOption(index)}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>,
    document.getElementById('portal') as HTMLElement
  );
};
