import { SectionConfig } from '../interfaces';

export const config: SectionConfig[] = [
  {
    display: 'GPS',
    children: [
      { display: 'Speed', fields: [{ names: ['sog', 'stwTrans', 'stwLong'], sensorType: 'gps' }] },
      { display: 'Direction', fields: [{ names: ['cog', 'heading'], sensorType: 'gps' }] },
      { display: 'Position', fields: [{ names: ['latitude', 'longitude'], sensorType: 'gps' }] },
      {
        display: 'Other',
        fields: [
          {
            excludeNames: ['sog', 'stwTrans', 'stwLong', 'cog', 'heading', 'latitude', 'longitude'],
            sensorType: 'gps',
          },
        ],
      },
    ],
    requiredAdd: {
      fields: [{ names: ['latitude', 'longitude', 'cog'], sensorType: 'gps' }],
    },
    onAddAny: {
      fields: [{ names: ['systemTime'], sensorType: 'gps' }],
    },
  },
  {
    display: 'Stability',
    children: [
      { display: 'Pitch', fields: [{ names: ['pitch'], sensorType: 'imu' }] },
      { display: 'Roll', fields: [{ names: ['roll'], sensorType: 'imu' }] },
      { display: 'Heel', fields: [{ names: ['heel'], sensorType: 'imu' }] },
      { display: 'Trim', fields: [{ names: ['trim'], sensorType: 'imu' }] },
      {
        display: 'Other',
        fields: [{ excludeNames: ['pitch', 'roll', 'heel', 'trim'], sensorType: 'imu' }],
      },
    ],
    onAddAny: { fields: [{ names: ['latitude', 'longitude', 'systemTime'], sensorType: 'imu' }] },
  },
  {
    display: 'Observed Weather',
    children: [
      { display: 'Air Temp', fields: [{ names: ['airTemperature'], sensorType: 'weather' }] },
      {
        display: 'Atmospheric Pressure',
        fields: [{ names: ['atmosphericPressure'], sensorType: 'weather' }],
      },
      {
        display: 'Wind Conditions',
        fields: [{ names: ['trueWindSpeed', 'trueWindDirection'], sensorType: 'weather' }],
      },
      {
        display: 'Other',
        fields: [
          {
            excludeNames: [
              'airTemperature',
              'atmosphericPressure',
              'trueWindSpeed',
              'trueWindDirection',
            ],
            sensorType: 'weather',
          },
        ],
      },
    ],
    onAddAny: {
      fields: [{ names: ['latitude', 'longitude', 'systemTime'], sensorType: 'weather' }],
    },
  },
  {
    display: 'Emissions',
    children: [
      {
        display: 'CO2',
        fields: [
          {
            sensorType: 'emissionsV2',
            names: ['CO2', 'CO2MassFlow', 'CO2Flow'],
          },
          {
            sensorType: 'derived',
            names: ['CO2Average'],
          },
          {
            sensorType: 'engine',
            names: ['CO2MassFlowEstimated', 'systemTime', 'latitude', 'longitude'],
          },
          {
            sensorType: 'virt',
            names: ['CO2MassFlowFromConc_est'],
          },
        ],
      },
      {
        display: 'CH4',
        fields: [
          {
            sensorType: 'emissionsV2',
            names: ['CH4Dual', 'CH4MassFlow', 'CH4Flow'],
          },
        ],
      },
      {
        display: 'CO',
        fields: [
          {
            sensorType: 'emissionsV2',
            names: ['CO', 'COMassFlow', 'COFlow'],
          },
        ],
      },
      {
        display: 'SO2',
        fields: [
          {
            names: ['SO2', 'SO2Dual', 'SO2CO2Ratio', 'SO2MassFlow', 'SO2Flow'],
            sensorType: 'emissionsV2',
          },
        ],
      },
      {
        display: 'NOX',
        fields: [
          {
            sensorType: 'emissionsV2',
            names: ['NOXDual', 'NOXMassFlow', 'NOXFlow'],
          },
        ],
      },
      {
        display: 'Flow',
        fields: [
          {
            names: ['flow'],
            sensorType: 'emissionsV2',
          },
          {
            names: ['flow_est'],
            sensorType: 'virt',
          },
        ],
      },
      {
        display: 'Other',
        fields: [
          {
            sensorType: 'emissionsV2',
            excludeNames: [
              'CO2',
              'CO2MassFlow',
              'CO2Flow',
              'CH4Dual',
              'CH4MassFlow',
              'CH4Flow',
              'CO',
              'COMassFlow',
              'COFlow',
              'SO2',
              'SO2Dual',
              'SO2CO2Ratio',
              'SO2MassFlow',
              'SO2Flow',
              'NOXDual',
              'NOXMassFlow',
              'NOXFlow',
              'flow',
            ],
          },
          {
            sensorType: 'derived',
            excludeNames: ['CO2Average'],
          },
          {
            sensorType: 'engine',
            excludeNames: ['CO2MassFlowEstimated', 'systemTime', 'latitude', 'longitude'],
          },
          {
            sensorType: 'virt',
            excludeNames: ['CO2MassFlowFromConc_est', 'flow_est'],
          },
        ],
      },
    ],
    onAddAny: {
      fields: [
        {
          sensorType: 'emissionsV2',
          names: ['latitude', 'longitude', 'systemTime'],
        },
        {
          sensorType: 'derived',
          names: ['latitude', 'longitude', 'systemTime'],
        },
      ],
    },
  },
  {
    display: 'Generator',
    children: [
      { display: 'Load', fields: [{ names: ['load'], sensorType: 'generator' }] },
      {
        display: 'Power',
        fields: [
          {
            names: [
              'apparentPower',
              'activePower',
              'reactivePower',
              'phasesVoltageAvg',
              'phasesCurrentAvg',
              'phase1Voltage',
              'phase2Voltage',
              'phase3Voltage',
              'phase1Current',
              'phase2Current',
              'phase3Current',
            ],
            sensorType: 'generator',
          },
        ],
      },
      {
        display: 'Other',
        fields: [
          {
            excludeNames: [
              'load',
              'apparentPower',
              'activePower',
              'reactivePower',
              'phasesVoltageAvg',
              'phasesCurrentAvg',
              'phase1Voltage',
              'phase2Voltage',
              'phase3Voltage',
              'phase1Current',
              'phase2Current',
              'phase3Current',
            ],
            sensorType: 'generator',
          },
        ],
      },
    ],
    onAddAny: {
      fields: [{ names: ['latitude', 'longitude', 'systemTime'], sensorType: 'generator' }],
    },
  },
  {
    display: 'Air Quality',
    children: [
      { display: 'PM10 AQI', fields: [{ names: ['pm10Aqi'], sensorType: 'airQuality' }] },
      { display: 'PM25 AQI', fields: [{ names: ['pm25Aqi'], sensorType: 'airQuality' }] },
      {
        display: 'Other',
        fields: [
          {
            excludeNames: ['pm10Aqi', 'pm25Aqi'],
            sensorType: 'airQuality',
          },
        ],
      },
    ],
    onAddAny: {
      fields: [{ names: ['latitude', 'longitude', 'systemTime'], sensorType: 'airQuality' }],
    },
  },
  {
    display: 'Fuel/Engine',
    children: [
      {
        display: 'Air Intake Pressure',
        fields: [
          { names: ['airIntakePressure', 'gasPressure', 'fuelInPressure'], sensorType: 'engine' },
        ],
      },
      {
        display: 'Fuel Flow & Consumption',
        fields: [
          {
            names: [
              'fuelInFlow',
              'fuelOutFlow',
              'fuelConsumption',
              'lngMassFlow',
              'fuelInFlowMass',
              'gasMode',
              'mgoMode',
              'operational',
            ],
            sensorType: 'engine',
          },
        ],
      },
      {
        display: 'Fuel Temp',
        fields: [{ names: ['fuelInTemp', 'fuelOutTemp', 'mgoTemp'], sensorType: 'engine' }],
      },
      {
        display: 'Engine Speed',
        fields: [
          {
            names: ['rpm', 'turboSpeed', 'engineSpeed', 'actualEngineSpeed'],
            sensorType: 'engine',
          },
        ],
      },
      {
        display: 'Other',
        fields: [
          {
            excludeNames: [
              'airIntakePressure',
              'gasPressure',
              'fuelInPressure',
              'fuelInFlow',
              'fuelOutFlow',
              'fuelConsumption',
              'lngMassFlow',
              'fuelInFlowMass',
              'gasMode',
              'mgoMode',
              'operational',
              'fuelInTemp',
              'fuelOutTemp',
              'mgoTemp',
              'rpm',
              'turboSpeed',
              'engineSpeed',
              'actualEngineSpeed',
            ],
            sensorType: 'engine',
          },
        ],
      },
    ],
    onAddAny: {
      fields: [{ names: ['latitude', 'longitude', 'systemTime'], sensorType: 'engine' }],
    },
  },
];
