import styles from './DashboardPage.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState, RefObject } from 'react';
import { useAppDispatch, useAppSelector } from 'Services/redux';
import {
  isLoadingLayersSelector,
  selectedAqiLayerSelector,
  selectedElementSelector,
  setIsLoadingVesselLayer,
} from 'Services/redux/map';
import { sessionUserSelector } from 'Services/redux/session';
import {
  AlertsBar,
  EmitterDetailsBar,
  LayerSelector,
  LoadingIndicator,
  Map,
  SettingsBar,
  SideBar,
  EmittersSocketTester,
  ControlBar,
  SIDE_BAR_WIDTH,
} from './components';
import { AnimatePresence, motion, useMotionValue, useMotionValueEvent } from 'framer-motion';
import { useSyncedEmitters } from 'Services/emitters';
import { ControlBarProvider } from './components/ControlBar/contexts';
import { getAbsoluteInterval, generateStaticInterval, getInterval } from 'Helpers/interval';
import { DEFAULT_INTERVAL, SensorName } from 'Constants';
import {
  generateTimelineBarInterval,
  generateTimelineBarSampleRateMs,
  getTimelineBarThumbPositionInUrl,
} from 'Helpers/timeline';
import { Toaster, ToasterProvider } from 'Components/Toaster';
import { AqiLegend } from './components/Map/components';
import { getPropertiesWithNames } from 'Helpers/sensors';
import { RedesignedEmitterDetailsBar } from './components/RedesignedEmitterDetailsBar/RedesignedEmitterDetailsBar';
import { Vessel } from 'Models';

const sideBarVariants = {
  expanded: { x: 0 },
  collapsed: { x: '-100%' },
};

const rightBarVariants = {
  collapsed: { left: '100%', right: 'auto' },
  expanded: { left: 'auto', right: 0 },
};

enum RightBarType {
  EmitterDetails,
  RedesignedEmitterDetails,
  Alerts,
  Settings,
}

const DashboardPageContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLeftBarExpanded, setIsLeftBarExpanded] = useState(true);
  const [visibleRightBarType, setVisibleRightBarType] = useState<RightBarType | undefined>();

  const isLoadingLayers = useAppSelector(isLoadingLayersSelector);
  const selectedElement = useAppSelector(selectedElementSelector);

  const { emitters, isFetching } = useSyncedEmitters();

  const scaleControlRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    dispatch(setIsLoadingVesselLayer(isFetching));

    return () => {
      dispatch(setIsLoadingVesselLayer(false));
    };
  }, [dispatch, isFetching]);

  const selectedEmitter = useMemo(
    () =>
      emitters && selectedElement
        ? emitters.find((e) => e.id === selectedElement.value.id)
        : undefined,
    [emitters, selectedElement]
  );

  const currentUser = useAppSelector(sessionUserSelector);

  const userIsManager = useMemo(
    () =>
      currentUser?.roles.find((role) =>
        ['manager', 'adminRead', 'adminReadWrite'].includes(role.name)
      ) ?? false,
    [currentUser?.roles]
  );

  const leftBarRef = useRef<HTMLDivElement>(null);

  const [leftBarWidth, setLeftBarWidth] = useState(0);
  const [rightBarWidth, setRightBarWidth] = useState(0);

  const [rightBar, setRightBar] = useState<HTMLDivElement | null>();

  const topRightAccessoryRef = useRef<HTMLDivElement>(null);
  const bottomRightAccessoryRef = useRef<HTMLDivElement>(null);

  const rightBarRef = (element: HTMLDivElement | null) => {
    setRightBar(element);
    setRightBarWidth(element?.offsetWidth ?? 0);
  };

  const leftBarXMotionValue = useMotionValue(0);
  const rightBarLeftMotionValue = useMotionValue(undefined);

  const contentInsetLeftMotionValue = useMotionValue(SIDE_BAR_WIDTH);
  const contentInsetRightMotionValue = useMotionValue(0);

  useMotionValueEvent(leftBarXMotionValue, 'change', (val) => {
    const leftBarWidth = leftBarRef.current?.offsetWidth ?? 0;

    const percentage = val as unknown as string;
    const decimal = parseFloat(percentage.replace('%', '')) / 100;
    const leftBarX = leftBarWidth * decimal;

    contentInsetLeftMotionValue.set(leftBarWidth + leftBarX);
  });

  useMotionValueEvent(rightBarLeftMotionValue, 'change', (val) => {
    if (typeof val === 'number') {
      setRightAccessoryPosition({ left: val, rightAccessoryRef: topRightAccessoryRef });
      setRightAccessoryPosition({ left: val, rightAccessoryRef: bottomRightAccessoryRef });

      contentInsetRightMotionValue.set(window.innerWidth - val);

      const scaleControl = scaleControlRef.current;
      if (scaleControl) {
        const xTranslation = val - window.innerWidth;
        scaleControl.style.transform = `translateX(${xTranslation}px)`;
      }
    }
  });

  useMotionValueEvent(rightBarLeftMotionValue, 'animationComplete', () => {
    const isRightBarVisible = visibleRightBarType !== undefined;

    if (isRightBarVisible) {
      if (rightBar) {
        setRightAccessoryPosition({
          left: rightBar.offsetLeft,
          rightAccessoryRef: topRightAccessoryRef,
        });
      }
    } else {
      setRightAccessoryPosition({
        left: window.innerWidth,
        rightAccessoryRef: topRightAccessoryRef,
      });
    }
  });

  useEffect(() => {
    setLeftBarWidth(isLeftBarExpanded ? leftBarRef.current?.offsetWidth ?? 0 : 0);
  }, [isLeftBarExpanded]);

  useEffect(() => {
    setVisibleRightBarType(selectedElement && RightBarType.EmitterDetails);
  }, [selectedElement]);

  useEffect(() => {
    if (visibleRightBarType === undefined) {
      setRightBarWidth(0);
    }
  }, [visibleRightBarType]);

  const handleClickExpandButtonOnSideBar = useCallback(() => {
    setIsLeftBarExpanded(true);
  }, []);

  const handleClickCollapseButtonOnSideBar = useCallback(() => {
    setIsLeftBarExpanded(false);
  }, []);

  const handleClickExpandAlertButtonOnSideBar = useCallback(() => {
    setVisibleRightBarType(RightBarType.Alerts);
  }, []);

  const handleClickCollapseButtonOnDetailsBar = useCallback(() => {
    setVisibleRightBarType(undefined);
  }, []);

  const handleClickCollapseButtonOnAlertsBar = useCallback(() => {
    setVisibleRightBarType(undefined);
  }, []);

  const handleClickExpandButtonOnSettingsBar = useCallback(() => {
    if (userIsManager) {
      setVisibleRightBarType(RightBarType.Settings);
    }
  }, [userIsManager]);

  const handleClickCollapseButtonOnSettingsBar = useCallback(() => {
    setVisibleRightBarType(undefined);
  }, []);

  const selectedAqiLayer = useAppSelector(selectedAqiLayerSelector);

  const shouldShowAqiLegend = useMemo(() => {
    const hasAirQualityProperties = !!getPropertiesWithNames(
      [
        SensorName.AirQuality.PropertyName.PM25Aqi,
        SensorName.AirQuality.PropertyName.PM10Aqi,
        SensorName.AirQuality.PropertyName.NO2Aqi,
      ],
      selectedEmitter?.sensors ?? []
    ).length;

    return selectedAqiLayer && hasAirQualityProperties;
  }, [selectedAqiLayer, selectedEmitter?.sensors]);

  return (
    <div className={styles.dashboardPage}>
      {emitters && <EmittersSocketTester emitters={emitters} />}
      <motion.div
        className={styles.leftBar}
        animate={isLeftBarExpanded ? 'expanded' : 'collapsed'}
        variants={sideBarVariants}
        transition={{ duration: 0.5 }}
        ref={leftBarRef}
        style={{ x: leftBarXMotionValue }}
      >
        <SideBar
          emitters={emitters}
          showsExpandButton={!isLeftBarExpanded}
          showsCollapseButton={isLeftBarExpanded}
          onClickExpandButton={handleClickExpandButtonOnSideBar}
          onClickCollapseButton={handleClickCollapseButtonOnSideBar}
          onClickExpandAlertButton={handleClickExpandAlertButtonOnSideBar}
          onClickExpandSettingsButton={handleClickExpandButtonOnSettingsBar}
          settingsIsEnabled={!!userIsManager}
        />
        <div className={styles.topLeftAccessory}>{!!shouldShowAqiLegend && <AqiLegend />}</div>
      </motion.div>
      <AnimatePresence mode="wait">
        {visibleRightBarType === RightBarType.EmitterDetails && selectedEmitter && (
          <>
            {selectedEmitter.id === 'disney-4' || selectedEmitter.id === 'disney-3' ? (
              <motion.div
                key={RightBarType.EmitterDetails}
                className={styles.rightBar}
                initial="collapsed"
                animate="expanded"
                exit="collapsed"
                variants={rightBarVariants}
                transition={{ duration: 0.5 }}
                style={{ left: rightBarLeftMotionValue }}
                ref={rightBarRef}
              >
                <RedesignedEmitterDetailsBar
                  key={selectedEmitter.id}
                  emitter={selectedEmitter as Vessel}
                  onClickCollapseButton={handleClickCollapseButtonOnDetailsBar}
                />
              </motion.div>
            ) : (
              <motion.div
                key={RightBarType.RedesignedEmitterDetails}
                className={styles.rightBar}
                initial="collapsed"
                animate="expanded"
                exit="collapsed"
                variants={rightBarVariants}
                transition={{ duration: 0.5 }}
                style={{ left: rightBarLeftMotionValue }}
                ref={rightBarRef}
              >
                <EmitterDetailsBar
                  key={selectedEmitter.id}
                  emitter={selectedEmitter}
                  onClickCollapseButton={handleClickCollapseButtonOnDetailsBar}
                />
              </motion.div>
            )}
          </>
        )}
        {visibleRightBarType === RightBarType.Alerts && (
          <motion.div
            key={RightBarType.Alerts}
            className={styles.rightBar}
            initial="collapsed"
            animate="expanded"
            exit="collapsed"
            variants={rightBarVariants}
            transition={{ duration: 0.5 }}
            style={{ left: rightBarLeftMotionValue }}
            ref={rightBarRef}
          >
            <AlertsBar onClickCollapseButton={handleClickCollapseButtonOnAlertsBar} />
          </motion.div>
        )}

        {visibleRightBarType === RightBarType.Settings && (
          <motion.div
            key={RightBarType.Settings}
            className={styles.rightBar}
            initial="collapsed"
            animate="expanded"
            exit="collapsed"
            variants={rightBarVariants}
            transition={{ duration: 0.5 }}
            style={{ left: rightBarLeftMotionValue }}
            ref={rightBarRef}
          >
            <SettingsBar onClickCollapseButton={handleClickCollapseButtonOnSettingsBar} />
          </motion.div>
        )}
      </AnimatePresence>
      <Map
        emitters={emitters}
        inset={{ top: 0, right: rightBarWidth, bottom: 0, left: leftBarWidth }}
        scaleControlRef={scaleControlRef}
      />
      <motion.div
        className={styles.controlBarContainer}
        style={{ left: contentInsetLeftMotionValue, right: contentInsetRightMotionValue }}
      >
        <ControlBar />
      </motion.div>
      <div className={styles.topRightAccessory} ref={topRightAccessoryRef}>
        <LayerSelector emitters={emitters ?? []} />
      </div>
      <div className={styles.bottomRightAccessory} ref={bottomRightAccessoryRef}>
        <LoadingIndicator hidden={!isLoadingLayers} />
      </div>
      <Toaster />
    </div>
  );
};

const setRightAccessoryPosition = ({
  left,
  rightAccessoryRef,
  type = 'fixed',
}: {
  left: number | undefined;
  rightAccessoryRef: RefObject<HTMLDivElement>;
  type?: 'fixed' | 'absolute';
}) => {
  const topRightAccessory = rightAccessoryRef.current;

  if (!topRightAccessory) return;

  if (type === 'absolute') {
    const right = window.innerWidth - (left ?? window.innerWidth);

    topRightAccessory.style.marginRight = right + 'px';

    return;
  }

  const right = window.innerWidth - (left ?? window.innerWidth) + 20;

  topRightAccessory.style.right = right + 'px';
};

const initialInterval = getInterval(window.location.href) ?? DEFAULT_INTERVAL;
const initialAbsoluteInterval = getAbsoluteInterval(generateStaticInterval(initialInterval));
const initialTimelineBarSampleRateMs = generateTimelineBarSampleRateMs(initialAbsoluteInterval);
const initialTimelineBarInterval = generateTimelineBarInterval(
  initialAbsoluteInterval,
  initialTimelineBarSampleRateMs
);

const thumbPosition = getTimelineBarThumbPositionInUrl(window.location.href);

export const DashboardPage: React.FC = () => {
  return (
    <ControlBarProvider
      initialInterval={initialInterval}
      initialTimelineBarInterval={initialTimelineBarInterval}
      initialTimelineBarSampleRateMs={initialTimelineBarSampleRateMs}
      initialThumbPosition={thumbPosition ?? 1.0}
    >
      <ToasterProvider>
        <DashboardPageContent />
      </ToasterProvider>
    </ControlBarProvider>
  );
};
