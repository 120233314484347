import styles from '../../../LayerSelector.module.scss';
import { LayerSelectorMenuSection } from '../../LayerSelectorMenuSection';
import {
  showsEcaLayerSelector,
  setShowsEcaLayer,
  showsAisLayerSelector,
  setShowsAisLayer,
} from 'Services/redux/map';
import { SensorName } from 'Constants';
import { CheckBoxOption } from '../../Options';
import { LayersAvailability } from '../type';
import { getAqiSectionOptions } from '../helpers';

type DockMenuProps = {
  layersAvailability: LayersAvailability;
};

export const DockMenu: React.FC<DockMenuProps> = ({ layersAvailability }) => {
  const aqiSectionOptions = getAqiSectionOptions(layersAvailability);

  return (
    <div className={styles.menu}>
      {layersAvailability.hasAisPositionalSensors && (
        <LayerSelectorMenuSection title="Navigation">
          <CheckBoxOption
            key={SensorName.AISPositional.name}
            label="AIS"
            checkedSelector={showsAisLayerSelector}
            checkedAction={setShowsAisLayer}
          />
        </LayerSelectorMenuSection>
      )}
      <LayerSelectorMenuSection title="Charts">
        <CheckBoxOption
          label="ECAs"
          checkedSelector={showsEcaLayerSelector}
          checkedAction={setShowsEcaLayer}
        />
      </LayerSelectorMenuSection>
      {!!aqiSectionOptions.length && (
        <LayerSelectorMenuSection title="AQI">{aqiSectionOptions}</LayerSelectorMenuSection>
      )}
    </div>
  );
};
