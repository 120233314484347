// From AIS information website
// @see: https://navcen.uscg.gov/ais-class-a-reports
//
// True heading     Degrees (0-359); 511 = not available = default
// COG              Degrees (0-359); 511 = not available = default
// SOG              Knots (0-62); 63 = not available = default

export const UNAVAILABLE_TRUE_HEADING = 511;
export const UNAVAILABLE_COG = 511;
export const UNAVAILABLE_SOG = 63;
