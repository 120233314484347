import styles from './CheckBox.module.scss';
import { ReactComponent as IconCheck } from './resources/graphics/icon-check.svg';
import clsx from 'clsx';

type CheckBoxProps = {
  checked?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const CheckBox: React.FC<CheckBoxProps> = ({
  checked: isChecked = false,
  disabled: isDisabled = false,
  onClick,
}) => {
  return (
    <div
      className={clsx(styles.checkbox, {
        [styles.checked]: isChecked,
        [styles.disabled]: isDisabled,
      })}
      onClick={onClick}
    >
      {isChecked && <IconCheck />}
    </div>
  );
};
