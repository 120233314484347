/* eslint-disable import/first */

import styles from './Map.module.scss';
import {
  Attributes,
  cloneElement,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

// react-map-gl does not render map in the production build
// but works fine on development
// @see: https://github.com/visgl/react-map-gl/issues/1266

import mapboxgl, { FlyToOptions, LngLat, PaddingOptions } from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it

(mapboxgl as any).workerClass =
  /* eslint-disable-next-line import/no-webpack-loader-syntax */
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import MapboxMap, { MapLayerMouseEvent, MapRef, ViewStateChangeEvent } from 'react-map-gl';
import { useAppDispatch, useAppSelector } from 'Services/redux';
import {
  selectedElementSelector,
  showsAisLayerSelector,
  showsDockLayerSelector,
  showsVesselLayerSelector,
  showsPositionHistoryLayerSelector,
  showsEcaLayerSelector,
  selectedEmissionLayerSelector,
  setSelectedElement,
  showsSelectionSelector,
  setShowsSelection,
  showsAlertLayerSelector,
  selectedAqiLayerSelector,
} from 'Services/redux/map';
import {
  calculateBounds,
  decodeGeocode,
  getBounds,
  getElement,
  getEmitterInUrl,
  isAisElement,
  isAlertElement,
  isDockElement,
  isVesselElement,
  updateEmitterInUrl,
  updateGeocodeInUrl,
} from './helpers';
import { useMapRef } from './hooks';
import { MapElement, MapFeature, MapLayerProps } from './interfaces';
import { Coordinate, Dock, Emitter, Vessel } from 'Models';
import { LayerId } from './constants';
import { mapboxConfiguration } from 'Config';
import {
  AisLayer,
  AisPopup,
  AlertLayer,
  AlertPopup,
  AqiLayer,
  DockLayer,
  DockPopup,
  EcaLayer,
  EmissionLayer,
  PositionHistoryLayer,
  VesselLayer,
  VesselPopup,
} from './components';
import { isDock, isVessel } from 'Helpers/emitter';

const PADDING = 220.0;
const DEFAULT_INSET: MapInset = { top: 0, right: 0, bottom: 0, left: 0 };

const FLY_ANIMATION_KEY = '__fly_animation__';

export type MapInset = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type MapProps = {
  emitters?: Emitter[];
  inset?: MapInset;
  scaleControlRef: React.MutableRefObject<HTMLElement | null>;
};

const scaleControl = new mapboxgl.ScaleControl({
  maxWidth: 250,
  unit: 'metric',
});

export const Map: React.FC<MapProps> = ({ emitters, inset = DEFAULT_INSET, scaleControlRef }) => {
  const mapRef = useMapRef();
  const { geocode: geocodeString } = useParams();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [cursor, setCursor] = useState<string>('auto');

  const flyingToOptionsRef = useRef<FlyToOptions>();

  const dispatch = useAppDispatch();

  const selectedElement = useAppSelector(selectedElementSelector);
  const [hoveredElement, setHoveredElement] = useState<MapElement | undefined>();

  const showsSelection = useAppSelector(showsSelectionSelector);

  const showsVesselLayer = useAppSelector(showsVesselLayerSelector);
  const showsDockLayer = useAppSelector(showsDockLayerSelector);
  const showsAisLayer = useAppSelector(showsAisLayerSelector);
  const showsPositionHistoryLayer = useAppSelector(showsPositionHistoryLayerSelector);
  const showsEcaLayer = useAppSelector(showsEcaLayerSelector);
  const showsAlertLayer = useAppSelector(showsAlertLayerSelector);
  const selectedEmissionLayer = useAppSelector(selectedEmissionLayerSelector);
  const selectedAqiLayer = useAppSelector(selectedAqiLayerSelector);

  const needsFocusInitialEmitterRef = useRef(true);

  useEffect(() => {
    if (!needsFocusInitialEmitterRef.current || !emitters) return;

    const emitter = getEmitterInUrl(window.location.href, emitters);

    if (emitter) {
      if (isVessel(emitter)) {
        dispatch(setSelectedElement({ value: emitter, type: 'vessel' }));
      } else if (isDock(emitter)) {
        dispatch(setSelectedElement({ value: emitter, type: 'dock' }));
      }
    }
  }, [emitters, dispatch]);

  const geocode = useMemo(() => {
    if (!geocodeString) {
      return;
    }

    return geocodeString ? decodeGeocode(geocodeString) : undefined;
  }, [geocodeString]);

  const padding: PaddingOptions = useMemo(
    () => ({
      top: PADDING + inset.top,
      bottom: PADDING + inset.bottom,
      left: PADDING + inset.left,
      right: PADDING + inset.right,
    }),
    [inset.top, inset.bottom, inset.left, inset.right]
  );

  const selectedEmitter = useMemo(
    () => selectedElement && emitters && emitters.find((e) => e.id === selectedElement.value.id),
    [emitters, selectedElement]
  );

  const selectedVessel = useMemo(
    () => (selectedEmitter && isVessel(selectedEmitter) ? selectedEmitter : undefined),
    [selectedEmitter]
  );

  const hoveredVessel = useMemo(() => {
    if (hoveredElement && isVesselElement(hoveredElement)) {
      return (
        emitters &&
        emitters.find((e): e is Vessel => isVessel(e) && e.id === hoveredElement.value.id)
      );
    }
  }, [emitters, hoveredElement]);

  const hoveredAlert = useMemo(() => {
    if (hoveredElement && isAlertElement(hoveredElement)) {
      return hoveredElement.value;
    }
  }, [hoveredElement]);

  const selectedDock = useMemo(
    () => (selectedEmitter && isDock(selectedEmitter) ? selectedEmitter : undefined),
    [selectedEmitter]
  );

  const hoveredDock = useMemo(() => {
    if (hoveredElement && isDockElement(hoveredElement)) {
      return (
        emitters && emitters.find((e): e is Dock => isDock(e) && e.id === hoveredElement.value.id)
      );
    }
  }, [emitters, hoveredElement]);

  const hoveredAis = useMemo(() => {
    if (hoveredElement && isAisElement(hoveredElement)) {
      return hoveredElement.value;
    }
  }, [hoveredElement]);

  const focusMap = useCallback(
    (emitter: Emitter) => {
      const coordinate = emitter.coordinate;

      if (coordinate) {
        const map = mapRef.current;

        if (!map) return;

        const flyToOptions = {
          center: { lng: coordinate.longitude, lat: coordinate.latitude },
          zoom: 12.0,
          duration: 1500,
          padding,
        };

        const mapCenter = map.getCenter();
        const flyCenter = new LngLat(flyToOptions.center.lng, flyToOptions.center.lat);
        const distance = mapCenter.distanceTo(flyCenter);

        // We don't need to fly if the fly end center is too close
        // with the current map center.
        if (distance < 5) return;

        flyingToOptionsRef.current = flyToOptions;

        map.flyTo(flyToOptions, {
          [FLY_ANIMATION_KEY]: true,
        });
      }
    },
    [mapRef, padding]
  );

  //Add distance scale
  useEffect(() => {
    if (!mapRef.current || !isMapLoaded) return;

    if (!mapRef.current.hasControl(scaleControl)) {
      mapRef.current.addControl(scaleControl, 'bottom-right');

      const scaleControlElement = document.getElementsByClassName('mapboxgl-ctrl-scale')[0] as
        | HTMLElement
        | undefined;

      scaleControlRef.current = scaleControlElement ?? null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, isMapLoaded]);

  useEffect(() => {
    if (!needsFocusInitialEmitterRef.current || !emitters) return;

    if (!geocode) {
      if (emitters.length === 1) {
        focusMap(emitters[0]);
      } else {
        if (!mapRef.current) return;

        const emitterCoordinates = emitters
          .map((emitter) => emitter.coordinate)
          .filter((coordinate): coordinate is Coordinate => !!coordinate);

        const bounds = getBounds(emitterCoordinates);
        const paddingAppliedBounds = calculateBounds(mapRef.current, bounds, padding);

        // IMPORTANT
        // Do not use fitBounds padding options. Such as the following:
        // `mapRef.current?.fitBounds(bounds, { padding })`
        //
        // The combination of padding and bounds sometimes will produce
        // an invalid bounds that can't be displayed by the map.
        // There's no way to catch the error either causing the map
        // camera to point on unknown bounds.
        //
        // In order to avoid this, we calculate the bounds manually.
        mapRef.current.fitBounds(paddingAppliedBounds);
      }
    }

    needsFocusInitialEmitterRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitters, mapRef, geocode]);

  useEffect(() => {
    if (selectedEmitter) {
      focusMap(selectedEmitter);
      updateEmitterInUrl(selectedEmitter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmitter?.id]);

  useEffect(() => {
    if (flyingToOptionsRef.current) {
      mapRef.current?.flyTo({ ...flyingToOptionsRef.current, padding });
    } else {
      mapRef.current?.easeTo({ padding, duration: 500 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [padding]);

  const handleClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const clickedFeature = (event.features && event.features[0]) as MapFeature | undefined;
      if (clickedFeature) {
        const element = getElement(clickedFeature.properties.element);

        // AIS & Alert can only be hovered
        if (element.type !== 'ais' && element.type !== 'alert') {
          dispatch(setSelectedElement(element));
          dispatch(setShowsSelection(true));
        }
      } else {
        dispatch(setShowsSelection(false));
      }
    },
    [dispatch]
  );

  const handleMoveEnd = useCallback((event: ViewStateChangeEvent) => {
    updateGeocodeInUrl(event.viewState);

    if ((event as any)[FLY_ANIMATION_KEY]) {
      flyingToOptionsRef.current = undefined;
    }
  }, []);

  const handleLoad = useCallback(() => {
    setIsMapLoaded(true);
  }, []);

  const handleClosePopup = useCallback(() => {
    dispatch(setShowsSelection(false));
  }, [dispatch]);

  const visibleLayers = useMemo(() => {
    const layers: ReactElement<MapLayerProps & Attributes>[] = [];

    const pushLayer = (layer: ReactElement<MapLayerProps>) => {
      const newProps: MapLayerProps & Attributes = { ...layer.props };
      newProps.key = layer.props.id;
      newProps.beforeId = layers[layers.length - 1]?.props.id;

      layers.push(cloneElement(layer, newProps));
    };

    const docks = emitters?.filter((e): e is Dock => isDock(e));

    if (showsVesselLayer) {
      const vessels = emitters?.filter((e): e is Vessel => isVessel(e));
      pushLayer(
        <VesselLayer id={LayerId.vessel} vessels={vessels} selectedVessel={selectedVessel} />
      );
    }

    if (showsDockLayer) {
      pushLayer(<DockLayer docks={docks} id={LayerId.dock} selectedDock={selectedDock} />);
    }

    if (showsAlertLayer && selectedVessel) {
      pushLayer(<AlertLayer id={LayerId.alert} vessel={selectedVessel} />);
    }

    if (selectedEmitter) {
      if (showsAisLayer) pushLayer(<AisLayer id={LayerId.ais} emitter={selectedEmitter} />);
    }

    if (selectedVessel) {
      if (showsPositionHistoryLayer && mapRef.current)
        pushLayer(
          <PositionHistoryLayer
            key={LayerId.positionHistory + selectedVessel.id}
            id={LayerId.positionHistory}
            vessel={selectedVessel}
            mapRef={mapRef.current}
          />
        );

      if (selectedEmissionLayer && mapRef.current)
        pushLayer(
          <EmissionLayer
            key={LayerId.emission + selectedVessel.id}
            id={LayerId.emission}
            vessel={selectedVessel}
            emissionType={selectedEmissionLayer}
            mapRef={mapRef.current}
          />
        );
    }

    if (selectedAqiLayer && mapRef.current) {
      pushLayer(<AqiLayer id={LayerId.aqi} docks={docks ?? []} aqiType={selectedAqiLayer} />);
    }

    if (showsEcaLayer) pushLayer(<EcaLayer id={LayerId.eca} />);

    return layers;
  }, [
    showsVesselLayer,
    showsDockLayer,
    showsAlertLayer,
    selectedVessel,
    selectedEmitter,
    showsEcaLayer,
    emitters,
    selectedDock,
    showsAisLayer,
    showsPositionHistoryLayer,
    mapRef,
    selectedEmissionLayer,
    selectedAqiLayer,
  ]);

  const interactiveLayerIds = useMemo(() => {
    const allInteractiveLayerIds = [LayerId.vessel, LayerId.dock, LayerId.ais, LayerId.alert];

    return visibleLayers
      .filter((layer) => allInteractiveLayerIds.includes(layer.props.id))
      .map((layer) => layer.props.id);
  }, [visibleLayers]);

  const handleMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!isMapLoaded) return;

      const features = mapRef.current?.queryRenderedFeatures(event.point, {
        layers: interactiveLayerIds,
      });

      if (features && features.length) {
        setCursor('pointer');

        const element = getElement(features[0]?.properties?.element);
        element && setHoveredElement(element);
      } else {
        setCursor('auto');
        setHoveredElement(undefined);
      }
    },
    [interactiveLayerIds, isMapLoaded, mapRef]
  );

  return (
    <div className={styles.mapContainer}>
      <MapboxMap
        ref={mapRef as Ref<MapRef>}
        initialViewState={{
          longitude: geocode?.longitude ?? -102.4,
          latitude: geocode?.latitude ?? 37.8,
          zoom: geocode?.zoom ?? 6,
        }}
        style={{
          width: '100%',
          height: '100%',
        }}
        mapStyle="mapbox://styles/mapbox/dark-v10"
        mapboxAccessToken={mapboxConfiguration.accessToken}
        cursor={cursor}
        dragRotate={false}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onMoveEnd={handleMoveEnd}
        onLoad={handleLoad}
        interactiveLayerIds={isMapLoaded ? interactiveLayerIds : []}
        fadeDuration={0}
      >
        {isMapLoaded && (
          <>
            {visibleLayers}

            {showsSelection && showsVesselLayer && selectedVessel && (
              <VesselPopup
                vessel={selectedVessel}
                showsCloseButton={true}
                onClose={handleClosePopup}
              />
            )}

            {showsVesselLayer &&
              hoveredVessel &&
              (hoveredVessel.id !== selectedVessel?.id || !showsSelection) && (
                <VesselPopup vessel={hoveredVessel} showsCloseButton={false} />
              )}

            {showsAlertLayer && hoveredAlert && selectedVessel && (
              <AlertPopup alert={hoveredAlert} />
            )}

            {showsSelection && showsDockLayer && selectedDock && (
              <DockPopup dock={selectedDock} showsCloseButton={true} onClose={handleClosePopup} />
            )}

            {showsDockLayer &&
              hoveredDock &&
              (hoveredDock.id !== selectedDock?.id || !showsSelection) && (
                <DockPopup dock={hoveredDock} showsCloseButton={false} />
              )}

            {showsAisLayer && hoveredAis && <AisPopup ais={hoveredAis} />}
          </>
        )}
      </MapboxMap>
    </div>
  );
};
