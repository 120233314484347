import styles from './ExportSensorModal.module.scss';
import { useCallback, useMemo, useState } from 'react';
import { Button } from 'Components/Button';
import { Modal } from 'Components/Modal';
import { StepBar } from 'Components/StepBar';
import { AbsoluteInterval, SensorName, StaticInterval } from 'Constants';
import { Emitter, Property } from 'Models';
import { StepSensor } from './steps/StepSensor/StepSensor';
// import { StepEngine } from './steps/StepEngine/StepEngine';
// import { StepProperties } from './steps/StepProperties/StepProperties';
import { StepInterval } from './steps/StepInterval/StepInterval';
import { EngineOption, PropertyOption, SensorOption } from './interfaces';
import { /*getEngineOptions,*/ getPropertyOptions, getSensorOptions } from './helpers/options';
import { areDatesMoreThanAYearApart } from './helpers/date';
import { urlConfiguration } from 'Config';
import { useToastPresenter } from 'Components/Toaster';
import { DownloadInProgressError, exportEmitter } from 'Networking/http';
import { useMutation } from '@tanstack/react-query';
import { CheckBox } from 'Components/CheckBox';
import { Option } from 'Components/Option';

type ExportSensorModalProps = {
  emitter: Emitter;
  onClose: () => void;
};

export const ExportSensorModal: React.FC<ExportSensorModalProps> = ({ emitter, onClose }) => {
  const [stepIndex, setStepIndex] = useState(0);

  const [selectedSensorOption, setSelectedSensorOption] = useState<SensorOption>();
  const [selectedEngineOption, setSelectedEngineOption] = useState<EngineOption>();
  const [selectedPropertyOptions, setSelectedPropertyOptions] = useState<PropertyOption[]>([]);
  const [interval, setInterval] = useState<Partial<StaticInterval>>({
    earliest: undefined,
    latest: undefined,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const sensorOptions = useMemo(() => getSensorOptions(emitter), [emitter]);

  // const engineOptions = useMemo(
  //   () =>
  //     selectedSensorOption ? getEngineOptions({ sensorOption: selectedSensorOption, emitter }) : [],
  //   [emitter, selectedSensorOption]
  // );

  const propertyOptions: PropertyOption[] = useMemo(
    () =>
      selectedSensorOption
        ? getPropertyOptions({
            sensorOption: selectedSensorOption,
            engineOption: selectedEngineOption,
            emitter,
          })
        : [],
    [emitter, selectedEngineOption, selectedSensorOption]
  );

  const handleSelectSensorOption = useCallback((sensorOption: SensorOption) => {
    setSelectedSensorOption(sensorOption);
    setSelectedEngineOption(undefined);
    setSelectedPropertyOptions([]);
  }, []);

  // const handleSelectEngineOption = useCallback((engineOption: EngineOption) => {
  //   setSelectedEngineOption(engineOption);
  //   setSelectedPropertyOptions([]);
  // }, []);

  // const handleTogglePropertyOption = useCallback((propertyOption: PropertyOption) => {
  //   setSelectedPropertyOptions((propertyOptions) => {
  //     const exists = propertyOptions.some(
  //       (o) => o.property.name.name === propertyOption.property.name.name
  //     );

  //     return exists
  //       ? propertyOptions.filter((o) => o.property.name.name !== propertyOption.property.name.name)
  //       : [...propertyOptions, propertyOption];
  //   });
  // }, []);

  const handleChangeInterval = useCallback((interval: Partial<StaticInterval>) => {
    setInterval(interval);
  }, []);

  const steps = useMemo(
    () => [
      {
        id: 'sensor',
        title: 'Select Dataset',
        modalSubtitle: 'Select the dataset to export',
        height: 500,
        component: (
          <StepSensor
            emitter={emitter}
            sensorOptions={sensorOptions}
            selectedSensorOption={selectedSensorOption}
            onSelectSensorOption={handleSelectSensorOption}
          />
        ),
      },
      // ...(!!engineOptions.length
      //   ? [
      //       {
      //         id: 'engine',
      //         title: 'Select Engine',
      //         modalSubtitle: 'Choose the engine',
      //         height: 480,
      //         component: (
      //           <StepEngine
      //             engineOptions={engineOptions}
      //             selectedEngineOption={selectedEngineOption}
      //             onSelectEngineOption={handleSelectEngineOption}
      //           />
      //         ),
      //       },
      //     ]
      //   : []),
      // {
      //   id: 'properties',
      //   title: 'Select Properties',
      //   modalSubtitle: 'Assign users full or partial access to available data',
      //   height: 680,
      //   component: (
      //     <StepProperties
      //       propertyOptions={propertyOptions}
      //       selectedPropertyOptions={selectedPropertyOptions}
      //       onTogglePropertyOption={handleTogglePropertyOption}
      //     />
      //   ),
      // },
      {
        id: 'interval',
        title: 'Select Dates',
        modalSubtitle: 'Select a date range to limit the report to a specified range',
        height: 480,
        component: <StepInterval interval={interval} onSelectInterval={handleChangeInterval} />,
      },
    ],
    [
      emitter,
      handleChangeInterval,
      handleSelectSensorOption,
      interval,
      selectedSensorOption,
      sensorOptions,
    ]
  );

  const stepTitles = steps.map((step) => step.title);

  const handleClickBackButton = useCallback(() => {
    setStepIndex((stepIndex) => stepIndex - 1);
  }, []);

  const handleClickNextButton = useCallback(() => setStepIndex(stepIndex + 1), [stepIndex]);
  const exportEmitterMutation = useMutation(exportEmitter);

  const toastPresenter = useToastPresenter();

  const handleClickSubmit = useCallback(async () => {
    setIsSubmitting(true);

    // const properties: Property[] = selectedPropertyOptions
    //   .map((o) => o.property)
    //   .concat(propertyOptions.filter((o) => o.required).map((o) => o.property));

    const properties = emitter.sensors.reduce<Property[]>((acc, sensor) => {
      // Only include virt sensor
      if (sensor.name === SensorName.Virt.name) {
        return acc.concat(sensor.properties);
      }

      return acc;
    }, []);

    try {
      try {
        const { secret } = await exportEmitterMutation.mutateAsync({
          properties: properties,
          interval: interval as AbsoluteInterval,
        });

        window.location.href = `${urlConfiguration.api}/export/${secret}`;

        setIsSubmitting(false);
        onClose();
      } catch (err) {
        if (err instanceof DownloadInProgressError) {
          toastPresenter.present({
            level: 'danger',
            title: 'Only one download at a time is permitted.',
          });
        }

        setIsSubmitting(false);
      }
    } catch (err) {
      setIsSubmitting(false);
    }
  }, [emitter.sensors, exportEmitterMutation, interval, onClose, toastPresenter]);

  const shouldEnableNextButton = useMemo(() => {
    const currentStep = steps[stepIndex];

    switch (currentStep.id) {
      case 'sensor':
        return !!selectedSensorOption;
      case 'engine':
        return !!selectedEngineOption;
      case 'properties':
        return !!selectedPropertyOptions.length;
      case 'interval':
        return (
          interval.earliest &&
          interval.latest &&
          new Date(interval.earliest).getTime() <= new Date(interval.latest).getTime() &&
          !areDatesMoreThanAYearApart(new Date(interval.earliest), new Date(interval.latest))
        );
      default:
        throw new Error('Invalid step id');
    }
  }, [
    interval.earliest,
    interval.latest,
    selectedEngineOption,
    selectedPropertyOptions.length,
    selectedSensorOption,
    stepIndex,
    steps,
  ]);

  const isOnLastStep = useMemo(() => stepIndex === steps.length - 1, [stepIndex, steps.length]);

  const [chooseAll, setChooseAll] = useState(false);

  const handleClickChooseAll = useCallback(() => {
    const newChooseAll = !chooseAll;
    setChooseAll(newChooseAll);

    if (newChooseAll) {
      setSelectedPropertyOptions(propertyOptions);
    } else {
      setSelectedPropertyOptions([]);
    }
  }, [chooseAll, propertyOptions]);

  return (
    <Modal
      title="Export"
      subtitle="Fill out the information below"
      height={steps[stepIndex].height}
      body={
        <div className={styles.exportSensorModalBody}>
          <StepBar titles={stepTitles} activeIndex={stepIndex} />
          <div className={styles.stepContent}>{steps[stepIndex].component}</div>
        </div>
      }
      footer={
        <div className={styles.exportSensorModalFooter}>
          <span className={styles.stepNumber}>
            Step {stepIndex + 1} of {steps.length}
          </span>
          <span className={styles.stepControl}>
            {steps[stepIndex].id === 'properties' && (
              <Option label="Choose all" onClick={handleClickChooseAll}>
                <CheckBox checked={chooseAll} />
              </Option>
            )}
            {stepIndex === 0 ? (
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
            ) : (
              <Button color="secondary" onClick={handleClickBackButton} disabled={isSubmitting}>
                Back
              </Button>
            )}
            <Button
              onClick={isOnLastStep ? handleClickSubmit : handleClickNextButton}
              disabled={!shouldEnableNextButton}
              isLoading={isSubmitting}
            >
              {isOnLastStep ? 'Export' : 'Next'}
            </Button>
          </span>
        </div>
      }
      onClose={onClose}
    />
  );
};
