import styles from './LoadingIndicator.module.scss';
import BarLoader from 'react-spinners/BarLoader';
import clsx from 'clsx';

type LoadingIndicatorProps = {
  hidden: boolean;
};

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ hidden }) => {
  return (
    <div className={clsx(styles.loadingIndicator, { [styles.hidden]: hidden })}>
      <BarLoader color="#fff" />
    </div>
  );
};
