import { AbsoluteInterval } from 'Constants';
import { Property } from 'Models';
import { OptionalTuple } from 'Types/utility';
import { PropertyValue, Sample, SensorRecords } from './interfaces';
import { belongsToSameSensor, generateProperyByName } from 'Helpers/sensors';
import { getAccessTokenSilently } from 'Services/auth0';
import { generateSensorPayloads } from './helpers';
import axios from 'axios';
import { urlConfiguration } from 'Config';

export interface FetchLatestSampleArgument {
  properties: Property[];
  interval: AbsoluteInterval;
}

export interface FetchLatestSampleOptions {
  includeIsSatcomm: boolean;
  includeErrors: boolean;
}

const DEFAULT_OPTIONS: FetchLatestSampleOptions = {
  includeIsSatcomm: true,
  includeErrors: false,
};

async function fetchLatestSample<Values extends Array<PropertyValue>>(
  arg: FetchLatestSampleArgument,
  options: FetchLatestSampleOptions = DEFAULT_OPTIONS
) {
  const properties = arg.properties;

  console.assert(properties.length > 0, "Properties can't be empty");
  console.assert(belongsToSameSensor(properties), 'Properties must belong to the same sensor');

  const token = await getAccessTokenSilently();
  const systemTimeProperty = generateProperyByName(properties[0], 'systemTime');
  const isSatcommProperty = generateProperyByName(properties[0], 'metadata.isSatcomm');
  const errorCodesProperty = generateProperyByName(properties[0], 'errorCodes');

  const requestedProperties = [...properties, systemTimeProperty];

  if (options.includeIsSatcomm) {
    requestedProperties.push(isSatcommProperty);
  }

  if (options.includeErrors) {
    requestedProperties.push(errorCodesProperty);
  }

  if (!requestedProperties.length) {
    return null;
  }

  const { data: sensorsRecords } = await axios.post<SensorRecords[]>(
    `${urlConfiguration.api}/sensors`,
    generateRequestBody(arg),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const record = sensorsRecords[0]?.records?.[0];

  if (!record) {
    return null;
  }

  const values = properties.map((p) => record[p.name.hash]) as Values;
  const timestamp = record[systemTimeProperty.name.hash];
  const isSatcomm = record[isSatcommProperty.name.hash] as boolean;
  const errorCodes = (record[errorCodesProperty.name.hash] as number[] | null) ?? [];

  return [...values, timestamp, isSatcomm, errorCodes] as Sample<OptionalTuple<Values>>;
}

fetchLatestSample.generateQueryKey = ({ properties, interval }: FetchLatestSampleArgument) => {
  const propertiesHash = properties
    .map((p) => p.hash)
    .sort()
    .join();

  return [
    'samples',
    'latest',
    propertiesHash,
    { earliest: interval.earliest, latest: interval.latest },
  ];
};

function generateRequestBody({ properties, interval }: FetchLatestSampleArgument) {
  return {
    earliest: interval.earliest,
    latest: interval.latest,
    aggregation: 'first',
    sensors: generateSensorPayloads(properties),
  };
}

export { fetchLatestSample };
