import { Coordinate } from 'Models';

export const areCoodinatesEqual = (firstCoordinate: Coordinate, secondCoordinate: Coordinate) => {
  return (
    firstCoordinate.latitude === secondCoordinate.latitude &&
    firstCoordinate.longitude === secondCoordinate.longitude
  );
};

export const isValidCoordinate = (coordinate: Coordinate) => {
  return (
    coordinate.latitude >= -90 &&
    coordinate.latitude <= 90 &&
    coordinate.longitude >= -180 &&
    coordinate.longitude <= 180
  );
};
