import { apiApi } from 'Services/redux/api/api.api';
import { Property } from 'Models';
import { getSensorRecordsRequestBody } from './util';

export type Aggregation = 'first' | 'avg' | 'sum' | 'min' | 'max';
export type SampleUnit = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month';

export type VariableProperty = {
  property: Property;
  variants: {
    alias: string;
    aggregation: Aggregation;
  }[];
};

export type GetInvariableSensorsRecordsQueryArgument = {
  properties: Property[];
  earliest: string;
  latest: string;
  aggregation?: Aggregation;
  sample?: {
    unit: SampleUnit;
    rate: number;
  };
  densify?: boolean;
};

export type GetVariableSensorsRecordsQueryArgument = {
  properties: VariableProperty[];
  earliest: string;
  latest: string;
  sample?: {
    unit: SampleUnit;
    rate: number;
  };
  densify?: boolean;
};

export type GetSensorsRecordsQueryArgument =
  | GetInvariableSensorsRecordsQueryArgument
  | GetVariableSensorsRecordsQueryArgument;

export type SensorRecords = {
  sensorId: string;
  records: Record<string, PropertyValue>[];
};

export type PropertyValue = string | number | boolean | undefined | null;

export const sensorApi = apiApi.injectEndpoints({
  endpoints: (builder) => ({
    getSensorsRecords: builder.query<SensorRecords[], GetSensorsRecordsQueryArgument>({
      query: (arg) => ({
        url: 'sensors',
        method: 'POST',
        body: getSensorRecordsRequestBody(arg),
      }),
    }),
  }),
});

export const { useGetSensorsRecordsQuery, useLazyGetSensorsRecordsQuery } = sensorApi;

export const getAlias = (property: Property, aggregation: Aggregation) => {
  return `${property.name.hash}${property.engineId ? `-${property.engineId}` : ''}-${aggregation}`;
};
