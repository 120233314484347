import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'Models/user';

type Session = {
  user?: User;
};

const defaultState: Session = {};

export const sessionSlice = createSlice({
  name: 'session',
  initialState: defaultState,
  reducers: {
    setSessionUser: (state, action: PayloadAction<Session['user']>) => {
      state.user = action.payload;
    },
    clearSession: (state) => {
      state.user = undefined;
    },
  },
});

const { reducer, actions } = sessionSlice;

export const { setSessionUser, clearSession } = actions;
export const sessionReducer = reducer;
