import styles from './MapPopup.module.scss';
import { Popup, PopupProps } from 'react-map-gl';
import { ReactComponent as IconCross } from 'Resources/graphics/icon-cross.svg';

export type MapPopupProps = Omit<PopupProps, 'onClose'> & { onClose?: () => void };

export const MapPopup: React.FC<MapPopupProps> = (props) => {
  const popupProps: PopupProps = { ...props, onClose: undefined };

  return (
    <Popup
      {...popupProps}
      anchor="left"
      offset={[18, 0]}
      closeButton={false}
      closeOnClick={props.closeOnClick ?? false}
      className={styles.mapPopup}
    >
      {props.closeButton && (
        <button className={styles.closeButton} onClick={props.onClose}>
          <IconCross />
        </button>
      )}
      {props.children}
    </Popup>
  );
};
