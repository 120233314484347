import { useEffect, useMemo, useRef, useState } from 'react';
import { Source, Layer, LayerProps } from 'react-map-gl';
import { FeatureCollection, Point } from 'geojson';
import IconAisVessel from './resources/graphics/icon-ais-vessel.png';
import IconAisStationary from './resources/graphics/icon-ais-stationary.png';
import { Ais, AisStatus, Emitter, Vessel } from 'Models';
import { useGetAisListQuery } from './hooks';
import { isVessel } from 'Helpers/emitter';
import { setIsLoadingAisLayer } from 'Services/redux/map';
import { useAppDispatch } from 'Services/redux';
import { MapFeature, MapLayerProps } from '../../../interfaces';
import { useMapImage } from '../../../hooks';
import { useObserveAis } from './hooks';
import { useGetEmittersQuery } from 'Services/redux/api/sensorGroup';
import { isAisMapCompatible } from './helpers';
import { AisRecord } from './interfaces';
import { UNAVAILABLE_COG, UNAVAILABLE_TRUE_HEADING } from 'Constants';
import { AisMessageTest, useMessageTest } from 'Networking/socket';
import React from 'react';

const AIS_VESSEL_IMAGE_KEY = 'icon-ais-vessel';
const AIS_STATIONARY_IMAGE_KEY = 'icon-ais-stationary';

const STATIONARY_STATUSES = [
  AisStatus.AtAnchor,
  AisStatus.NotUnderCommand,
  AisStatus.RestrictedManeuverability,
  AisStatus.Moored,
  AisStatus.Aground,
];

const BASE_LAYER_PROPS: LayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-size': 0.5,
    'icon-allow-overlap': true,
    'icon-rotate': ['get', 'rotation'],
  },
  paint: {
    'icon-opacity': [
      'max',
      0.2,
      ['-', 1, ['/', ['-', Date.now(), ['get', 'systemTime', ['get', 'data']]], 900000]],
    ],
  },
};

const EMPTY_EMITTERS: Emitter[] = [];

const getFeature = (ais: Ais): MapFeature => {
  let rotation = 0;

  if (ais.trueHeading !== undefined && ais.trueHeading !== UNAVAILABLE_TRUE_HEADING) {
    rotation = ais.trueHeading;
  } else if (ais.cog !== undefined && ais.cog !== UNAVAILABLE_COG) {
    rotation = ais.cog;
  }

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      coordinates: [ais.coordinate!.longitude, ais.coordinate!.latitude],
    },
    id: ais.mmsi,
    properties: {
      element: JSON.stringify({
        value: ais,
        type: 'ais',
      }),
      icon: STATIONARY_STATUSES.includes(ais.status ?? (-1 as any))
        ? AIS_STATIONARY_IMAGE_KEY
        : AIS_VESSEL_IMAGE_KEY,
      rotation,
    },
  };
};

type AisLayerProps = {
  emitter: Emitter;
} & MapLayerProps;

const RawAisLayer: React.FC<AisLayerProps> = ({ emitter, id, beforeId }) => {
  useMapImage(IconAisVessel, AIS_VESSEL_IMAGE_KEY);
  useMapImage(IconAisStationary, AIS_STATIONARY_IMAGE_KEY);

  const {
    aisList: initialAisList,
    isFetching: isFetchingAisList,
    isLoading: isLoadingAisList,
  } = useGetAisListQuery({
    emitter,
  });

  const { data: emitters = EMPTY_EMITTERS, isFetching: isFetchingEmitters } = useGetEmittersQuery();

  const [aisRecord, setAisRecord] = useState<AisRecord>({});

  const emitterMmsis = useMemo(
    () =>
      emitters.reduce<Vessel['mmsi'][]>(
        (acc, emitter) =>
          isVessel(emitter) ? (emitter.mmsi ? acc.concat(emitter.mmsi) : acc) : acc,
        []
      ),
    [emitters]
  );

  useEffect(() => {
    setAisRecord({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitter.id]);

  useEffect(() => {
    const initialAisRecord = initialAisList.reduce<AisRecord>(
      (acc, ais) => (isAisMapCompatible(ais) ? { ...acc, [ais.mmsi]: ais } : acc),
      {}
    );

    setAisRecord(initialAisRecord);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAisList, isFetchingAisList]);

  const { current: aisRemovalTimeouts } = useRef(
    new Map<NonNullable<Ais['mmsi']>, NodeJS.Timeout>()
  );

  const removeAis = (ais: Ais) => {
    if (typeof ais.mmsi !== 'number') {
      return;
    }

    unscheduleAisRemoval(ais);

    setAisRecord((aisRecord) => {
      const newAisRecord = { ...aisRecord };
      delete newAisRecord[ais.mmsi!];

      return newAisRecord;
    });
  };

  const scheduleAisRemoval = (ais: Ais) => {
    if (typeof ais.mmsi !== 'number' || !ais.systemTime) {
      return;
    }

    unscheduleAisRemoval(ais);

    const expiredTime = new Date(ais.systemTime);
    expiredTime.setMinutes(expiredTime.getMinutes() + 15);

    const ms = expiredTime.getTime() - new Date().getTime();

    if (ms > 0) {
      const timeout = setTimeout(() => removeAis(ais), ms);
      aisRemovalTimeouts.set(ais.mmsi, timeout);
    }
  };

  const unscheduleAisRemoval = (ais: Ais) => {
    if (typeof ais.mmsi !== 'number') {
      return;
    }

    if (aisRemovalTimeouts.has(ais.mmsi!)) {
      const timeout = aisRemovalTimeouts.get(ais.mmsi);
      clearTimeout(timeout);
      aisRemovalTimeouts.delete(ais.mmsi);
    }
  };

  const rescheduleAisRemovals = () => {
    Array.from(aisRemovalTimeouts.values()).forEach((timeout) => {
      clearTimeout(timeout);
    });
    aisRemovalTimeouts.clear();

    Object.values(aisRecord).forEach((ais) => scheduleAisRemoval(ais));
  };

  useObserveAis(emitter, (observedAis) => {
    if (isAisMapCompatible(observedAis) && !isFetchingAisList) {
      setAisRecord((aisRecord) => ({
        ...aisRecord,
        [observedAis.mmsi]: { ...(aisRecord[observedAis.mmsi] ?? {}), ...observedAis },
      }));
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => rescheduleAisRemovals(), [aisRecord]);

  const dispatch = useAppDispatch();

  const isLoading = useMemo(
    () => isFetchingAisList || isFetchingEmitters,
    [isFetchingAisList, isFetchingEmitters]
  );

  useEffect(() => {
    dispatch(setIsLoadingAisLayer(isLoading));
    return () => {
      dispatch(setIsLoadingAisLayer(false));
    };
  }, [dispatch, isLoading]);

  useMessageTest(AisMessageTest, emitter, initialAisList);

  const memoizedFeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: !isLoading
        ? Object.values(aisRecord)
            .filter((ais) => ais.mmsi && !emitterMmsis.includes(ais.mmsi) && ais.coordinate)
            .map((ais) => getFeature(ais))
        : [],
    } as FeatureCollection<Point>;
  }, [aisRecord, emitterMmsis, isLoading]);

  const layerProps = { id, ...BASE_LAYER_PROPS };

  if (beforeId) {
    layerProps.beforeId = beforeId;
  }

  return (
    <Source id={id} type="geojson" data={memoizedFeatureCollection}>
      <Layer {...layerProps} />
    </Source>
  );
};

export const AisLayer = React.memo(RawAisLayer);
