import { useEffect } from 'react';
import { SensorName } from 'Constants';
import { getFirstPropertyWithName, getFirstSensorWithName } from 'Helpers/sensors';
import { useNodeServerObserver } from 'Networking/socket';
import { Ais, Coordinate, Emitter } from 'Models';
import { isValidCoordinate } from 'Helpers/coordinate';

export const useObserveAis = (emitter: Emitter, listener: (ais: Ais) => void) => {
  const observer = useNodeServerObserver();

  useEffect(() => {
    const aisPositionalSensor = getFirstSensorWithName(SensorName.AISPositional, emitter);

    if (!aisPositionalSensor) return;

    const latitudeProperty = getFirstPropertyWithName(
      SensorName.AISPositional.PropertyName.Latitude,
      aisPositionalSensor
    );

    const longitudeProperty = getFirstPropertyWithName(
      SensorName.AISPositional.PropertyName.Longitude,
      aisPositionalSensor
    );

    const trueHeadingProperty = getFirstPropertyWithName(
      SensorName.AISPositional.PropertyName.TrueHeading,
      aisPositionalSensor
    );

    const cogProperty = getFirstPropertyWithName(
      SensorName.AISPositional.PropertyName.Cog,
      aisPositionalSensor
    );

    const mmsiProperty = getFirstPropertyWithName(
      SensorName.AISPositional.PropertyName.Mmsi,
      aisPositionalSensor
    );

    const statusProperty = getFirstPropertyWithName(
      SensorName.AISPositional.PropertyName.Status,
      aisPositionalSensor
    );

    const systemTimeProperty = getFirstPropertyWithName(
      SensorName.AISPositional.PropertyName.SystemTime,
      aisPositionalSensor
    );

    if (
      !latitudeProperty ||
      !longitudeProperty ||
      !trueHeadingProperty ||
      !cogProperty ||
      !mmsiProperty ||
      !statusProperty ||
      !systemTimeProperty
    ) {
      return;
    }

    const aisProperties = [
      latitudeProperty,
      longitudeProperty,
      trueHeadingProperty,
      cogProperty,
      mmsiProperty,
      statusProperty,
      systemTimeProperty,
    ];

    const subscription = observer.subscribe(aisProperties, (container) => {
      const latitude = container.getValueForProperty(latitudeProperty);
      const longitude = container.getValueForProperty(longitudeProperty);
      const trueHeading = container.getValueForProperty(trueHeadingProperty);
      const cog = container.getValueForProperty(cogProperty);
      const mmsi = container.getValueForProperty(mmsiProperty);
      const status = container.getValueForProperty(statusProperty);
      const systemTime = container.getValueForProperty(systemTimeProperty);

      const coordinate: Coordinate | undefined =
        typeof latitude === 'number' && typeof longitude === 'number'
          ? { latitude, longitude }
          : undefined;

      if (coordinate && !isValidCoordinate(coordinate)) {
        return;
      }

      if (typeof systemTime !== 'string') {
        return;
      }

      const ais: Ais = {
        coordinate,
        trueHeading: typeof trueHeading === 'number' ? trueHeading : undefined,
        cog: typeof cog === 'number' ? cog : undefined,
        mmsi: typeof mmsi === 'number' ? mmsi : undefined,
        status: typeof status === 'number' ? status : undefined,
        systemTime,
      };

      listener(ais);
    });

    return () => {
      observer.unsubscribe(subscription);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitter, observer]);
};
