import { useMemo } from 'react';
import { getFirstSensorWithName } from 'Helpers/sensors';
import { Coordinate, Sensor, Vessel } from 'Models';
import { SensorName, UNAVAILABLE_SOG } from 'Constants';
import { FetchMultiSampleSeriesArgument, Sample, fetchMultiSampleSeries } from 'Networking/http';
import { Degrees, Knots } from 'Interfaces';
import { VesselPose } from '../interface';
import { OptionalTuple } from 'Types/utility';
import { useQuery } from '@tanstack/react-query';

type SampleValues = [latitude: Degrees, longitude: Degrees, cog: Degrees, sog: Knots];
type ResponseSample = Sample<OptionalTuple<SampleValues>>;

export interface PoseSeriesByVessel {
  [vesselId: Vessel['id']]: VesselPose[];
}

interface UsePoseSeriesByVesselArgument {
  vessels: Vessel[];
  earliest: string;
  latest: string;
  sampleRateMs: number;
}

interface UsePoseSeriesByVesselOptions {
  enabled: boolean;
}

export const usePoseSeriesByVessel = (
  { vessels, earliest, latest, sampleRateMs }: UsePoseSeriesByVesselArgument,
  { enabled }: UsePoseSeriesByVesselOptions = { enabled: true }
) => {
  const gpsSensors = useMemo(
    () =>
      vessels
        ?.map((vessel) => getFirstSensorWithName(SensorName.GPS, vessel))
        ?.filter((sensor): sensor is Sensor<'gps'> => !!sensor) ?? [],
    [vessels]
  );

  const arg: FetchMultiSampleSeriesArgument = {
    propertyNames: [
      SensorName.GPS.PropertyName.Latitude,
      SensorName.GPS.PropertyName.Longitude,
      SensorName.GPS.PropertyName.COG,
      SensorName.GPS.PropertyName.SOG,
    ],
    sensors: gpsSensors,
    interval: { earliest, latest },
    aggregation: 'first',
    sample: { unit: 'second', rate: Math.floor(sampleRateMs / 1000) },
    densify: true,
  };

  const query = useQuery(
    fetchMultiSampleSeries.generateQueryKey(arg),
    () => fetchMultiSampleSeries<SampleValues>(arg),
    { enabled }
  );

  const poseSeriesByVessel = useMemo(() => {
    if (!query.data) return undefined;

    const res: PoseSeriesByVessel = {};

    for (let vesselIndex = 0; vesselIndex < query.data.length; vesselIndex++) {
      const vessel = vessels[vesselIndex];
      const samples = query.data[vesselIndex];

      const poseSeries: VesselPose[] = [];

      for (let sampleIndex = 0; sampleIndex < samples.length; sampleIndex++) {
        const timestamp = samples[sampleIndex][4];

        const validCoordinateSample = findNearestValidSample(
          samples,
          sampleIndex,
          (sample) => !!getCoordinate(sample)
        );

        const validCogSample = findNearestValidSample(
          samples,
          sampleIndex,
          (sample) => !!getCog(sample)
        );

        poseSeries.push({
          coordinate: validCoordinateSample && getCoordinate(validCoordinateSample),
          rotation: validCogSample && getCog(validCogSample),
          timestamp,
        });
      }

      if (poseSeries.length) {
        res[vessel.id] = poseSeries;
      }
    }

    return res;
  }, [query.data, vessels]);

  return { poseSeriesByVessel, query };
};

function findNearestValidSample(
  samples: ResponseSample[],
  startingIndex: number,
  validator: (sample: ResponseSample) => boolean
): ResponseSample | undefined {
  for (let index = startingIndex; index < samples.length - 1; index++) {
    const sample = samples[index];
    if (validator(sample)) return sample;
  }

  if (startingIndex > 0) {
    for (let index = startingIndex - 1; index >= 0; index--) {
      const sample = samples[index];
      if (validator(sample)) return sample;
    }
  }

  return undefined;
}

function getCoordinate(sample: ResponseSample): Coordinate | undefined {
  const [latitude, longitude] = sample;

  return typeof latitude === 'number' && typeof longitude === 'number'
    ? { latitude, longitude }
    : undefined;
}

function getCog(sample: ResponseSample): Degrees | undefined {
  const [, , cog, sog] = sample;

  if (typeof sog !== 'number') {
    return cog;
  }

  if (sog !== UNAVAILABLE_SOG && sog > 0.5) {
    return cog;
  }

  return undefined;
}
