import { Sample } from 'Networking/http';

// 3 is reserverd for timestamp, isSatcomm and errors.
const METADATA_LENGTH = 3;

type PropertyValue = string | number | boolean | undefined | null;

export function mergeSamples<T extends Array<PropertyValue>>(
  a: Sample<T> | undefined | null,
  b: Sample<T> | undefined | null
): Sample<T> | undefined | null {
  if (!a && !b) {
    return undefined;
  }

  if (a && !b) {
    return a;
  }

  if (!a && b) {
    return b;
  }

  const firstTimestamp = a![a!.length - 1] as string;
  const secondTimestamp = b![b!.length - 1] as string;

  return new Date(firstTimestamp) > new Date(secondTimestamp) ? a : b;
}

export function fill<T extends PropertyValue[]>(
  samples: Array<Sample<T>>,
  strategy: 'forward' | 'backward'
): Array<Sample<T>> {
  if (samples.length === 0) {
    return [];
  }

  const operatingSamples = strategy === 'backward' ? samples.slice() : samples.slice().reverse();

  const valuesLength = operatingSamples.length - METADATA_LENGTH;
  const lastKnownValues: { [index: number]: PropertyValue } = {};

  const filledSamples: Array<Sample<T>> = [];

  for (const sample of operatingSamples) {
    const values: PropertyValue[] = [];

    for (let i = 0; i < valuesLength; i++) {
      let value: PropertyValue = sample[i] as PropertyValue;

      if (value !== undefined && value !== null) {
        lastKnownValues[i] = value;
      } else {
        value = lastKnownValues[i];
      }

      values.push(value);
    }

    const filledSample: Sample<T> = [...values, ...sample.slice(-METADATA_LENGTH)] as Sample<T>;

    filledSamples.push(filledSample);
  }

  return strategy === 'backward' ? filledSamples : filledSamples.reverse();
}

export function values<T extends PropertyValue[]>(sample: Sample<T>): T {
  return sample.slice(0, -METADATA_LENGTH) as T;
}
