import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { Alert, Emitter } from 'Models';
import { AlertsCount } from 'Models';
import { alertsApi as baseAlertsApi } from '../alerts.api';
import { transformIntoAlert, transformIntoAlertsCount } from './transformers';
import {
  AlertsCount as AlertsCountRecord,
  AlertResponse as AlertsRecordResponse,
} from './interfaces';

export type GetAlertsArguments = {
  pageSize: number;
  type: 'unread' | 'read' | 'all';
  earliest?: string;
  latest?: string;
  emitter?: Emitter;
};

export type GetAlertsCountsArguments = { emitters: Emitter[] };

export type DismissAlertArguments = { alert: Alert };

export type AlertsResponse = { alerts: Alert[]; next: string };

export const alertsApi = baseAlertsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAlerts: builder.query<AlertsResponse, GetAlertsArguments>({
      query: (arg) => {
        const params: FetchArgs['params'] = {
          pageSize: arg.pageSize,
          set: arg.type,
        };

        if (arg.earliest) params['earliest'] = arg.earliest;
        if (arg.latest) params['latest'] = arg.latest;
        if (arg.emitter?.id) params['sensorGroupId'] = arg.emitter.id;

        return {
          url: `v2/alerts/`,
          params,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const args: Record<string, any> = { ...queryArgs };
        delete args.emitter;

        if (queryArgs.emitter) {
          args.emitterId = queryArgs.emitter.id;
        }

        return JSON.stringify(args);
      },
      transformResponse: (alertsRecordResponse: AlertsRecordResponse) => ({
        alerts: alertsRecordResponse.alerts.map(transformIntoAlert),
        next: alertsRecordResponse.next,
      }),
    }),
    getAlertsCounts: builder.query<AlertsCount[], GetAlertsCountsArguments>({
      query: ({ emitters }) => ({
        url: 'alerts/count',
        params: {
          sensorGroups: emitters.map((e) => e.id).join(','),
        },
      }),
      transformResponse: (alertsCountRecords: AlertsCountRecord[]) =>
        alertsCountRecords.map(transformIntoAlertsCount),
    }),
    dismissAlert: builder.mutation<any, DismissAlertArguments>({
      query: ({ alert }) => ({
        url: `alerts`,
        params: {
          id: alert.id,
        },
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetAlertsQuery,
  useLazyGetAlertsQuery,
  useGetAlertsCountsQuery,
  useDismissAlertMutation,
} = alertsApi;
