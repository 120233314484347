import { motion } from 'framer-motion';
import styles from './ViewModeSwitch.module.scss';

const SPRING = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
};

export type ViewMode = 'replay' | 'live';

type ViewModeSwitchProps = {
  mode: ViewMode;
  onToggle: () => void;
};

export const ViewModeSwitch: React.FC<ViewModeSwitchProps> = ({ mode, onToggle }) => {
  return (
    <div className={styles.viewModeSwitch} data-is-on={mode === 'live'} onClick={onToggle}>
      <motion.div className={styles.handle} layout transition={SPRING} />
    </div>
  );
};
