import { DateRange } from 'Components/DateTimeRangePickerModal/types';
import { TimeInputValue } from 'Components/TimeInput';
import { CalendarBodyProps } from './CalendarBody';

export interface Cell {
  label?: string;
  date?: Date;
  isHighlighted: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  isPreviewed: boolean;
}

export interface Row {
  cells: Cell[];
}

export function buildRows({
  minDate,
  maxDate,
  visibleMonth,
  visibleYear,
  selectedDates,
  highlightedDateRange,
  previewedDateRange,
}: Pick<
  CalendarBodyProps,
  | 'minDate'
  | 'maxDate'
  | 'visibleMonth'
  | 'visibleYear'
  | 'selectedDates'
  | 'highlightedDateRange'
  | 'previewedDateRange'
>): Row[] {
  const NUMBER_OF_ROWS = 6;
  const NUMBER_OF_COLUMNS = 7;

  const rows: Row[] = [];

  const numberOfDaysInMonth = getNumberOfDays(visibleMonth, visibleYear);

  const addCellInLatestRow = function (date?: Date) {
    const cell: Cell = date
      ? {
          label: String(date.getDate()),
          date,
          isSelected: matchOneDate(date, selectedDates),
          isHighlighted: highlightedDateRange
            ? isDateWithinDateRange(date, highlightedDateRange)
            : false,
          isPreviewed: previewedDateRange ? isDateWithinDateRange(date, previewedDateRange) : false,
          isDisabled: !isDateBetweenMinDateAndMaxDate(date, minDate, maxDate),
        }
      : { isHighlighted: false, isSelected: false, isPreviewed: false, isDisabled: true };

    rows[rows.length - 1].cells.push(cell);
  };

  for (let dayOfMonth = 1; dayOfMonth <= numberOfDaysInMonth; dayOfMonth++) {
    const date = new Date(visibleYear, visibleMonth, dayOfMonth, 0, 0, 0, 0);
    const column = date.getDay();

    if (dayOfMonth === 1 && column !== 0) {
      rows.push({ cells: [] });

      for (let i = 0; i < column; i++) {
        addCellInLatestRow();
      }
    }

    if (column === 0) {
      rows.push({ cells: [] });
    }

    addCellInLatestRow(date);

    if (dayOfMonth === numberOfDaysInMonth) {
      if (column < NUMBER_OF_COLUMNS - 1) {
        for (let i = column; i < NUMBER_OF_COLUMNS; i++) {
          addCellInLatestRow();
        }
      }

      const remainingRows = NUMBER_OF_ROWS - rows.length;

      if (remainingRows > 0) {
        for (let i = 0; i < remainingRows; i++) {
          rows.push({ cells: [] });

          for (let i = 0; i < NUMBER_OF_COLUMNS; i++) {
            addCellInLatestRow();
          }
        }
      }
    }
  }

  return rows;
}

function getNumberOfDays(month: number, year: number): number {
  return new Date(year, month + 1, 0).getDate();
}

function matchOneDate(date: Date, dates: Date[]): boolean {
  return dates.some((d) => areDatesEqual(date, d));
}

function areDatesEqual(firstDate: Date, secondDate: Date): boolean {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
}

function isDateWithinDateRange(date: Date, dateRange: [earliest: Date, latest: Date]): boolean {
  const flattenEarliest = new Date(
    dateRange[0].getFullYear(),
    dateRange[0].getMonth(),
    dateRange[0].getDate(),
    0,
    0,
    0,
    0
  );
  const flattenLatest = new Date(
    dateRange[1].getFullYear(),
    dateRange[1].getMonth(),
    dateRange[1].getDate(),
    0,
    0,
    0,
    0
  );
  return date >= flattenEarliest && date <= flattenLatest;
}

function isDateBetweenMinDateAndMaxDate(
  date: Date,
  minDate: Date | undefined,
  maxDate: Date | undefined
) {
  if (minDate && date < minDate) {
    return false;
  }

  if (maxDate && date > maxDate) {
    return false;
  }

  return true;
}

export function getTimeInputValue(date: Date): TimeInputValue {
  return { hour: date.getHours(), minute: date.getMinutes() };
}

export function mergeDateRange(
  dateRange: DateRange,
  startTimeInputValue: TimeInputValue,
  endTimeInputValue: TimeInputValue
): DateRange {
  const earliest = new Date(dateRange[0].getTime());
  earliest.setHours(startTimeInputValue.hour);
  earliest.setMinutes(startTimeInputValue.minute);

  const latest = new Date(dateRange[1].getTime());
  latest.setHours(endTimeInputValue.hour);
  latest.setMinutes(endTimeInputValue.minute);

  return [earliest, latest];
}
