import { Connection as ConnectionRecord } from '../interfaces';
import { Connection } from 'Models';

export const transformIntoConnection = (record: ConnectionRecord): Connection => ({
  name: record.name,
  emitterId: record.sensorGroupId,
  type: transformConnection(record.status),
});

const transformConnection = (connection: ConnectionRecord['status']): Connection['type'] => {
  switch (connection) {
    case 'lte':
    case 'satcomm':
    case 'offline':
      return connection;
    default:
      return 'unknown';
  }
};
