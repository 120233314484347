import { motion } from 'framer-motion';

type PitchGraphViewProps = {
  pitch?: number;
  animate: boolean;
};

export const PitchGraphView: React.FC<PitchGraphViewProps> = ({ pitch, animate }) => {
  return typeof pitch === 'number' ? (
    <svg
      width="158"
      height="158"
      viewBox="0 0 158 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M145.492 56.5586C99.8237 74.224 38.0275 69.6986 12.8379 65.2277L13.9829 78.9675C16.6 79.4582 22.9137 80.7013 27.2319 81.7482C31.5501 82.795 31.9754 85.1831 32.3026 86.8188L33.4475 94.9972C46.4785 94.7791 78.6252 94.2775 102.964 94.0158C127.303 93.7541 133.388 91.5623 135.187 90.4173C142.515 86.7534 145.11 66.3182 145.492 56.5586Z"
        stroke="#ABB3C7"
        strokeLinecap="round"
        strokeLinejoin="round"
        animate={{ rotate: pitch }}
        transition={{ duration: animate ? 2.0 : 0, ease: 'easeInOut' }}
      />
      <mask id="mask0_415_7150" maskUnits="userSpaceOnUse" x="0" y="75" width="158" height="83">
        <rect y="75.05" width="158" height="82.95" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_415_7150)">
        <motion.path
          d="M145.492 56.5586C99.8237 74.224 38.0275 69.6986 12.8379 65.2277L13.9829 78.9675C16.6 79.4582 22.9137 80.7013 27.2319 81.7482C31.5501 82.795 31.9754 85.1831 32.3026 86.8188L33.4475 94.9972C46.4785 94.7791 78.6252 94.2775 102.964 94.0158C127.303 93.7541 133.388 91.5623 135.187 90.4173C142.515 86.7534 145.11 66.3182 145.492 56.5586Z"
          fill="#B3E5FF"
          stroke="#0093DD"
          strokeLinecap="round"
          strokeLinejoin="round"
          animate={{ rotate: pitch }}
          transition={{ duration: animate ? 2.0 : 0, ease: 'easeInOut' }}
        />
      </g>
      <line x1="79.5" y1="2.18558e-08" x2="79.5" y2="158" stroke="black" />
      <line y1="74.55" x2="158" y2="74.55" stroke="black" />
    </svg>
  ) : (
    <svg
      width="158"
      height="158"
      viewBox="0 0 158 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M145.492 56.5586C99.8237 74.224 38.0275 69.6986 12.8379 65.2277L13.9829 78.9675C16.6 79.4582 22.9137 80.7013 27.2319 81.7482C31.5501 82.795 31.9754 85.1831 32.3026 86.8188L33.4475 94.9972C46.4785 94.7791 78.6252 94.2775 102.964 94.0158C127.303 93.7541 133.388 91.5623 135.187 90.4173C142.515 86.7534 145.11 66.3182 145.492 56.5586Z"
          stroke="#ABB3C7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.3">
        <line x1="79.5" y1="2.18558e-08" x2="79.5" y2="158" stroke="black" strokeDasharray="2 2" />
        <line y1="74.55" x2="158" y2="74.55" stroke="black" strokeDasharray="2 2" />
      </g>
    </svg>
  );
};
