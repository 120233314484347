import { Property } from 'Models';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { urlConfiguration } from 'Config';
import { useNodeServerObserver } from 'Networking/socket';
import axios from 'axios';
import { getAccessTokenSilently } from 'Services/auth0';
import { group, aggregationFns, keyFns, filterByTimeRange } from 'Helpers/array';
import { belongsToSameSensor } from 'Helpers/sensors';
import { MapRef } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { calculateBounds } from '../helpers';
import { SpatiotemporlarEntry } from '../interfaces';
import { AbsoluteInterval } from 'Constants';
import { generateStaticInterval } from 'Helpers/interval';
import { useViewMode } from '../../ControlBar/hooks';

type FilterOps = '$gt' | '$gte' | '$lt' | '$lte' | '$eq';

type QueryFilter = Record<string, Partial<Record<FilterOps, number>>>;

type CachedResponse = {
  sampleRate: number;
  earliest: string;
  latest: string;
  bounds: mapboxgl.LngLatBounds;
  entries: SpatiotemporlarEntry[];
  sensorId: string;
};

type CacheQuery = {
  earliest: string;
  latest: string;
  bounds: mapboxgl.LngLatBounds;
  sampleRate: number;
  sensorId: string;
};

type CachePartialHit = CacheQuery & {
  type: 'partial';
  match: CachedResponse;
};

type CacheHit = CacheQuery & {
  type: 'hit';
  match: CachedResponse;
};

type CacheMiss = CacheQuery & {
  type: 'miss';
};
function calculateScreenBounds(map: MapRef) {
  const padding = map.getPadding();
  const bounds = map.getBounds();

  return calculateBounds(map, bounds, {
    top: padding.top * -1,
    bottom: padding.bottom * -1,
    left: padding.left * -1,
    right: padding.right * -1,
  });
}

//We are using a mode in mapbox that allows for rendering multiple copies of the map on the screen at once
//this being the case, the longitude on the map can go way over 180 or below -180. This function will let
//us convert bounds from a multiple world projection to a single world projection (between 180 and -180.)
//The latitude is bounded and does not need any special treatment.
function toSingleWorldBounds(bounds: mapboxgl.LngLatBounds) {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const neWrapped = bounds.getNorthEast().wrap();
  const swWrapped = bounds.getSouthWest().wrap();
  const diffLng = ne.lng - sw.lng;
  const isWholeWorld = diffLng >= 360;

  return new mapboxgl.LngLatBounds(
    new mapboxgl.LngLat(isWholeWorld ? -179.9999 : swWrapped.lng, swWrapped.lat),
    new mapboxgl.LngLat(isWholeWorld ? 179.9999 : neWrapped.lng, neWrapped.lat)
  );
}

//square the size of the boundbox so the user can move around a
//bit without loading from the api

function toBufferedSingleWorldBounds(bounds: mapboxgl.LngLatBounds) {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const lngDiff = ne.lng - sw.lng;
  const latDiff = ne.lat - sw.lat;

  const singleWorldBounds = toSingleWorldBounds(
    new mapboxgl.LngLatBounds(
      [sw.lng - lngDiff / 2, Math.max(sw.lat - latDiff / 2, -90)],
      [ne.lng + lngDiff / 2, Math.min(ne.lat + latDiff / 2, 90)]
    )
  );

  return singleWorldBounds;
}

//Return the sample rate that's nearest in proximity to the given sample rate
function findWithNearestSampleRate(
  entries: CachedResponse[],
  sampleRate: number
): CachedResponse | undefined {
  const nearest = entries.sort(
    (a, b) => Math.abs(a.sampleRate - sampleRate) - Math.abs(b.sampleRate - sampleRate)
  );
  return nearest.at(0);
}

function findTemporalMatches(
  earliest: string,
  latest: string,
  entries: CachedResponse[]
): CachedResponse[] {
  return entries.filter(
    (c) =>
      earliest < c.latest && earliest >= c.earliest && latest > c.earliest && latest <= c.latest
  );
}

//bounds are assumed to have already been run throught the singleWorld function
function containedInBounds(bounds: mapboxgl.LngLatBounds, point: mapboxgl.LngLat) {
  const intersectsMapEdge = bounds.getEast() < bounds.getWest();

  //  For some reason, when coordinates are manuually set inside a mapBox latLng object,
  // there is sometimes  a small amount of error in the decimal places. This function
  // remove smaller decimal places to account for this.
  const fix = (n: number) => +n.toFixed(6);

  if (intersectsMapEdge) {
    return (
      (point.lat <= bounds.getNorthEast().lat &&
        fix(point.lng) <= fix(bounds.getNorthEast().lng) &&
        point.lat >= bounds.getSouthWest().lat &&
        fix(point.lng) >= -180) ||
      (point.lat <= bounds.getNorthEast().lat &&
        fix(point.lng) <= 180 &&
        point.lat >= bounds.getSouthWest().lat &&
        fix(point.lng) >= fix(bounds.getSouthWest().lng))
    );
  } else {
    return (
      point.lat <= bounds.getNorthEast().lat &&
      fix(point.lng) <= fix(bounds.getNorthEast().lng) &&
      point.lat >= bounds.getSouthWest().lat &&
      fix(point.lng) >= fix(bounds.getSouthWest().lng)
    );
  }
}

function findSpatialMatches(
  requestedBounds: mapboxgl.LngLatBounds,
  cachedApiResponses: CachedResponse[]
): CachedResponse[] {
  const matches: CachedResponse[] = [];
  //Since all cached items are in single world bounds format
  const singleWorldBounds = toSingleWorldBounds(requestedBounds);

  for (const c of cachedApiResponses) {
    if (
      containedInBounds(c.bounds, singleWorldBounds.getNorthEast()) &&
      containedInBounds(c.bounds, singleWorldBounds.getSouthWest())
    ) {
      matches.push(c);
    }
  }
  return matches;
}

type FetchDataArgs = {
  bounds: mapboxgl.LngLatBounds;
  sampleRate: number;
  earliest: string;
  latest: string;
  latitude: Property;
  longitude: Property;
  systemTime: Property;
  queryFilter?: QueryFilter;
  property?: Property;
};

//short alias names to reduce payload size
type SensorRecords = {
  sensorId: string;
  records: {
    a: number;
    b: number;
    c: string;
    d: number | undefined;
  }[];
};

//if crossed anti meridian need to adjust the bounds and do two queries and merge the results.
async function fetchData({
  bounds,
  sampleRate,
  earliest,
  latest,
  latitude,
  longitude,
  systemTime,
  queryFilter,
  property,
}: FetchDataArgs): Promise<SpatiotemporlarEntry[]> {
  const token = await getAccessTokenSilently();

  const bboxParams = [];
  const isOverPrimeMeridian = bounds.getEast() < bounds.getWest();
  if (isOverPrimeMeridian) {
    bboxParams.push({
      bbox: [-180, bounds.getNorthWest().lat, bounds.getSouthEast().lng, bounds.getSouthEast().lat],
    });
    bboxParams.push({
      bbox: [bounds.getNorthWest().lng, bounds.getNorthWest().lat, 180, bounds.getSouthEast().lat],
    });
  } else {
    bboxParams.push({
      bbox: [
        bounds.getNorthWest().lng,
        bounds.getNorthWest().lat,
        bounds.getSouthEast().lng,
        bounds.getSouthEast().lat,
      ],
    });
  }

  const properties = [latitude, longitude, systemTime];

  const aliasMap: { name: string; alias: string }[] = [
    {
      name: 'latitude',
      alias: 'a',
    },
    {
      name: 'longitude',
      alias: 'b',
    },
    {
      name: 'systemTime',
      alias: 'c',
    },
  ];

  if (property) {
    properties.push(property);
    aliasMap.push({
      name: property.name.name,
      alias: 'd',
    });
  }

  console.assert(belongsToSameSensor(properties), 'all properties mush belong to the same sensor');

  const filter = queryFilter ? { filter: queryFilter } : {};
  const { sensorId, sensorGroupId } = properties[0];

  const responses = await Promise.all(
    bboxParams.map((bboxParam) =>
      axios.post<SensorRecords[]>(
        `${urlConfiguration.api}/sensors`,
        {
          earliest,
          latest,
          sampleRate,
          sampleUnit: 'second',
          aggregation: 'first',
          ...bboxParam,
          sensors: [
            {
              properties: aliasMap,
              sensorGroupId,
              id: sensorId,
              ...filter,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    )
  );

  const entries: SpatiotemporlarEntry[] = [];
  for (const response of responses) {
    if (!response.data.length) {
      continue;
    }
    for (const r of response.data[0].records) {
      entries.push({
        latitude: r.a,
        longitude: r.b,
        timestamp: r.c,
        value: property ? r.d : undefined,
      });
    }
  }
  return entries;
}

function normalize(
  sampleRateSeconds: number,
  records: SpatiotemporlarEntry[]
): SpatiotemporlarEntry[] {
  const groupedRecords = group(records, {
    keyFn: keyFns.dateTrunc(sampleRateSeconds * 1000),
    aggregationFn: aggregationFns.first,
  });

  return Object.values(groupedRecords)
    .flat()
    .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
}

function toSampleRateSeconds(zoom: number) {
  if (zoom > 10) {
    return 1 * 60;
  } else if (zoom > 7.4) {
    return 3 * 60;
  } else if (zoom > 6) {
    return 7 * 60;
  } else if (zoom > 4) {
    return 20 * 60;
  } else if (zoom > 2) {
    return 60 * 60;
  } else {
    return 9 * 60 * 60;
  }
}

type QueryCacheArgs = {
  bounds: mapboxgl.LngLatBounds;
  sensorId: string;
  sampleRate: number;
  earliest: string;
  latest: string;
  cachedApiRecords: CachedResponse[];
};

function queryApiCache({
  earliest,
  latest,
  sampleRate,
  cachedApiRecords,
  bounds,
  sensorId,
}: QueryCacheArgs) {
  const miss: CacheMiss = {
    type: 'miss',
    earliest,
    latest,
    bounds,
    sampleRate,
    sensorId,
  };

  const sensorIdMatches = cachedApiRecords.filter((r) => r.sensorId === sensorId);
  const temporalMatches = findTemporalMatches(earliest, latest, sensorIdMatches);
  const bboxMatches = findSpatialMatches(bounds, temporalMatches);
  const bestMatch = findWithNearestSampleRate(bboxMatches, sampleRate);

  if (bestMatch && sampleRate >= bestMatch.sampleRate) {
    return {
      type: 'hit',
      earliest,
      latest,
      bounds,
      sampleRate,
      match: bestMatch,
      sensorId,
    } as CacheHit;
  } else if (bestMatch && sampleRate < bestMatch.sampleRate) {
    return {
      type: 'partial',
      earliest,
      latest,
      bounds,
      sampleRate: bestMatch.sampleRate,
      match: bestMatch,
      sensorId,
    } as CachePartialHit;
  }
  return miss;
}

type UseSpatioTemporalCacheArgs = {
  interval: AbsoluteInterval;
  mapRef: MapRef;
  queryFilter?: QueryFilter;
  latitudeProperty?: Property;
  longitudeProperty?: Property;
  systemTimeProperty?: Property;
  property?: Property;
};

//The goal here is to lazy load data at a downsampled quality
export const useSpatioTemporalCache = ({
  interval,
  mapRef,
  queryFilter,
  latitudeProperty,
  longitudeProperty,
  systemTimeProperty,
  property,
}: UseSpatioTemporalCacheArgs) => {
  const [currentRecords, setCurrentRecords] = useState<SpatiotemporlarEntry[]>([]);
  const [cachedApiRecords, setCachedApiRecords] = useState<CachedResponse[]>([]);
  const [largestSampleRateSecs, setLargestSampleRateSecs] = useState<number>(0);
  const [realtimeEntries, setRealtimeEntries] = useState<SpatiotemporlarEntry[]>([]);
  const [bounds, setBounds] = useState(calculateScreenBounds(mapRef));
  const [zoom, setZoom] = useState(mapRef.getZoom());

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const nodeServerObserver = useNodeServerObserver();
  const sampleRate = useMemo(() => toSampleRateSeconds(zoom), [zoom]);

  const { earliest, latest } = interval;

  useEffect(() => {
    mapRef.on('moveend', (e) => {
      setBounds(calculateScreenBounds(mapRef));
    });

    mapRef.on('zoomend', (e) => {
      setZoom(e.target.getZoom());
      setBounds(calculateScreenBounds(mapRef));
    });
  }, [mapRef]);

  const queryRealtimeCache = useCallback(() => {
    return realtimeEntries.filter((e) =>
      containedInBounds(
        toBufferedSingleWorldBounds(bounds),
        new mapboxgl.LngLat(e.longitude, e.latitude)
      )
    );
  }, [bounds, realtimeEntries]);

  const cacheRecords = useCallback(
    (
      entries: SpatiotemporlarEntry[],
      earliest: string,
      latest: string,
      sampleRate: number,
      bounds: mapboxgl.LngLatBounds,
      sensorId: string
    ) => {
      setCachedApiRecords((prev) =>
        //ensure cache cannot grow infinitly
        prev.slice(-100).concat({
          sampleRate,
          bounds,
          earliest,
          latest,
          entries,
          sensorId,
        })
      );
    },
    []
  );

  const viewMode = useViewMode();

  useEffect(() => {
    if (!latitudeProperty || !longitudeProperty || !systemTimeProperty) {
      return;
    }
    const properties = [latitudeProperty, longitudeProperty, systemTimeProperty];

    if (property) {
      properties.push(property);
    }

    const subscription = nodeServerObserver.subscribe(properties, (container) => {
      const current = generateStaticInterval(interval);

      const entry = {
        value: property ? container.getValueForProperty(property) : null,
        timestamp: container.getValueForProperty(systemTimeProperty),
        latitude: container.getValueForProperty(latitudeProperty),
        longitude: container.getValueForProperty(longitudeProperty),
      } as SpatiotemporlarEntry;

      setRealtimeEntries((entries) => {
        const newEntries = filterByTimeRange(entries.concat(entry), {
          earliest: current.earliest,
          latest: viewMode === 'replay' ? current.latest : undefined,
        });
        return newEntries;
      });
    });

    return () => nodeServerObserver.unsubscribe(subscription);
  }, [
    property,
    nodeServerObserver,
    interval,
    latitudeProperty,
    longitudeProperty,
    systemTimeProperty,
    realtimeEntries,
    viewMode,
  ]);

  //Automatic check, api pull and caching of data
  useEffect(() => {
    if (!latitudeProperty || !longitudeProperty || !systemTimeProperty || isFetching) {
      return;
    }

    const queryBounds = toBufferedSingleWorldBounds(bounds);

    const cachedResult = queryApiCache({
      bounds: queryBounds,
      sampleRate,
      earliest,
      latest,
      cachedApiRecords,
      sensorId: latitudeProperty.sensorId,
    });

    const fetchArgs = {
      bounds: queryBounds,
      sampleRate,
      earliest,
      latest,
      latitude: latitudeProperty,
      longitude: longitudeProperty,
      systemTime: systemTimeProperty,
      queryFilter,
      property,
    };

    //replay can cause this condition easily
    if (earliest === latest) {
      setCurrentRecords([]);
    } else if (cachedResult.type === 'miss' || cachedResult.type === 'partial') {
      setIsFetching(true);
      fetchData(fetchArgs)
        .then((entries) => {
          cacheRecords(
            entries,
            earliest,
            latest,
            sampleRate,
            queryBounds,
            latitudeProperty.sensorId
          );
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetching,
    sampleRate,
    bounds,
    earliest,
    latest,
    property,
    latitudeProperty,
    longitudeProperty,
    systemTimeProperty,
  ]);

  //Automatic query for data from cache and return to downstream components
  useEffect(() => {
    //just need this to get the sensorId
    if (!latitudeProperty) {
      return;
    }

    const cachedResult = queryApiCache({
      bounds: toBufferedSingleWorldBounds(bounds),
      sampleRate,
      earliest,
      latest,
      cachedApiRecords,
      sensorId: latitudeProperty.sensorId,
    });

    if (cachedResult.type === 'hit') {
      setCurrentRecords(cachedResult.match.entries);
      setLargestSampleRateSecs(cachedResult.match.sampleRate);
    } else if (cachedResult.type === 'partial') {
      setCurrentRecords((records) => records.concat(cachedResult.match.entries));
      setLargestSampleRateSecs((existingSampleRate) =>
        Math.max(existingSampleRate, cachedResult.match.sampleRate)
      );
    }
  }, [bounds, cachedApiRecords, earliest, latest, latitudeProperty, sampleRate]);

  const normalizedEntries = useMemo(() => {
    const { earliest, latest } = generateStaticInterval(interval);
    const fullyQualifiedLatest = latest || new Date().toISOString();
    const entries = queryRealtimeCache();

    return normalize(
      sampleRate,
      filterByTimeRange(currentRecords.concat(entries), {
        earliest,
        latest: viewMode === 'replay' ? fullyQualifiedLatest : new Date().toISOString(),
      })
    );
  }, [interval, queryRealtimeCache, sampleRate, currentRecords, viewMode]);

  return {
    isFetching,
    entries: normalizedEntries,
    largestSampleRateMs: largestSampleRateSecs * 1000,
  };
};
