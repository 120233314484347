import { AbsoluteInterval, Interval } from 'Constants';
import { ViewMode } from './components/ViewModeSelector/components';
import { areIntervalsEqual, inferViewMode } from 'Helpers/interval';

type IntervalObserver = (interval: Interval) => void;
type TimelineBarIntervalObserver = (interval: AbsoluteInterval) => void;
type TimelineBarSampleRateMsObserver = (sampleRateMs: number) => void;
type ViewModeObserver = (viewMode: ViewMode) => void;
type ThumbPositionObserver = (position: number) => void;

export class ControlBarManager {
  private intervalObservers: Set<IntervalObserver> = new Set();
  private timelineBarIntervalObservers: Set<TimelineBarIntervalObserver> = new Set();
  private timelineBarSampleRateMsObservers: Set<TimelineBarSampleRateMsObserver> = new Set();
  private viewModeObservers: Set<ViewModeObserver> = new Set();
  private thumbPositionObservers: Set<ThumbPositionObserver> = new Set();
  private releasedThumbPositionObservers: Set<ThumbPositionObserver> = new Set();

  private releasedThumbPosition: number;

  constructor(
    private interval: Interval,
    private timelineBarInterval: AbsoluteInterval,
    private timelineBarSampleRateMs: number,
    private thumbPosition: number
  ) {
    this.releasedThumbPosition = this.thumbPosition;
  }

  setInterval(interval: Interval) {
    if (areIntervalsEqual(this.interval, interval)) {
      return;
    }

    const oldViewMode = this.getViewMode();

    this.interval = interval;

    for (const intervalObserver of this.intervalObservers) {
      intervalObserver(interval);
    }

    const newViewMode = this.getViewMode();

    if (oldViewMode !== newViewMode) {
      for (const viewModeObserver of this.viewModeObservers) {
        viewModeObserver(newViewMode);
      }
    }
  }

  getInterval(): Interval {
    return this.interval;
  }

  observeInterval(observer: IntervalObserver) {
    this.intervalObservers.add(observer);
  }

  unobserveInterval(observer: IntervalObserver) {
    this.intervalObservers.delete(observer);
  }

  setTimelineBarInterval(interval: AbsoluteInterval) {
    if (areIntervalsEqual(this.timelineBarInterval, interval)) {
      return;
    }

    this.timelineBarInterval = interval;

    for (const timelineBarIntervalObserver of this.timelineBarIntervalObservers) {
      timelineBarIntervalObserver(interval);
    }
  }

  getTimelineBarInterval(): AbsoluteInterval {
    return this.timelineBarInterval;
  }

  observeTimelineBarInterval(observer: TimelineBarIntervalObserver) {
    this.timelineBarIntervalObservers.add(observer);
  }

  unobserveTimelineBarInterval(observer: TimelineBarIntervalObserver) {
    this.timelineBarIntervalObservers.delete(observer);
  }

  setTimelineBarSampleRateMs(sampleRateMs: number) {
    const newSampleRateMs = Math.floor(sampleRateMs);

    if (this.timelineBarSampleRateMs === newSampleRateMs) {
      return;
    }

    this.timelineBarSampleRateMs = newSampleRateMs;

    for (const timelineBarSampleRateMsObserver of this.timelineBarSampleRateMsObservers) {
      timelineBarSampleRateMsObserver(newSampleRateMs);
    }
  }

  getTimelineBarSampleRateMs() {
    return this.timelineBarSampleRateMs;
  }

  observeTimelineBarSampleRateMs(observer: TimelineBarSampleRateMsObserver) {
    this.timelineBarSampleRateMsObservers.add(observer);
  }

  unobserveTimelineBarSampleRateMs(observer: TimelineBarSampleRateMsObserver) {
    this.timelineBarSampleRateMsObservers.delete(observer);
  }

  observeViewMode(observer: ViewModeObserver) {
    this.viewModeObservers.add(observer);
  }

  unobserveViewMode(observer: ViewModeObserver) {
    this.viewModeObservers.delete(observer);
  }

  observeThumbPosition(observer: ThumbPositionObserver) {
    this.thumbPositionObservers.add(observer);
  }

  unobserveThumbPosition(observer: ThumbPositionObserver) {
    this.thumbPositionObservers.delete(observer);
  }

  getThumbPosition(): number {
    return this.thumbPosition;
  }

  setThumbPosition(position: number) {
    if (this.thumbPosition === position) {
      return;
    }

    this.thumbPosition = position;

    for (const thumbPositionObserver of this.thumbPositionObservers) {
      thumbPositionObserver(position);
    }
  }

  observeReleasedThumbPosition(observer: ThumbPositionObserver) {
    this.releasedThumbPositionObservers.add(observer);
  }

  unobserveReleasedThumbPosition(observer: ThumbPositionObserver) {
    this.releasedThumbPositionObservers.delete(observer);
  }

  getReleasedThumbPosition(): number {
    return this.releasedThumbPosition;
  }

  setReleasedThumbPosition(position: number) {
    if (this.releasedThumbPosition === position) {
      return;
    }

    this.releasedThumbPosition = position;

    for (const releasedThumbPositionObserver of this.releasedThumbPositionObservers) {
      releasedThumbPositionObserver(position);
    }
  }

  getViewMode(): ViewMode {
    return inferViewMode(this.interval);
  }
}
