import { useEffect, useMemo } from 'react';
import { SensorName } from 'Constants';
import { getFirstPropertyWithName, getFirstSensorWithName } from 'Helpers/sensors';
import { Coordinate, Emitter, Property } from 'Models';
import { CoordinatePropertiesByEmitter } from '../interfaces';
import { useNodeServerObserver } from 'Networking/socket';

export const useObserveEmitterCoordinate = (
  emitters: Emitter[],
  listener: (coordinate: Coordinate, timestamp: string, emitter: Emitter) => void
) => {
  const emittersCoordinateProperties = useMemo(
    () => getEmitterCoordinateProperties(emitters),
    [emitters]
  );

  const requiredProperties = useMemo(
    () =>
      Object.values(emittersCoordinateProperties).reduce<Property[]>(
        (acc, ecp) => acc.concat(Object.values(ecp)),
        []
      ),
    [emittersCoordinateProperties]
  );

  const observer = useNodeServerObserver();

  useEffect(() => {
    if (!Object.keys(emittersCoordinateProperties).length) {
      return;
    }

    const subscription = observer.subscribe(requiredProperties, (container) => {
      for (const emitter of emitters) {
        const coordinateProperties = emittersCoordinateProperties[emitter.id];

        if (coordinateProperties) {
          const latitude = container.getValueForProperty(coordinateProperties.latitudeProperty);
          const longitude = container.getValueForProperty(coordinateProperties.longitudeProperty);
          const systemTime = container.getValueForProperty(coordinateProperties.systemTimeProperty);

          if (
            typeof latitude === 'number' &&
            typeof longitude === 'number' &&
            typeof systemTime === 'string'
          ) {
            listener({ latitude, longitude }, systemTime, emitter);
          }
        }
      }
    });

    return () => observer.unsubscribe(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitters, emittersCoordinateProperties, observer, requiredProperties]);
};

function getEmitterCoordinateProperties(emitters: Emitter[]) {
  return emitters.reduce<CoordinatePropertiesByEmitter>((acc, emitter) => {
    const gpsSensor = getFirstSensorWithName(SensorName.GPS, emitter);
    const latitudeProperty =
      gpsSensor && getFirstPropertyWithName(SensorName.GPS.PropertyName.Latitude, gpsSensor);
    const longitudeProperty =
      gpsSensor && getFirstPropertyWithName(SensorName.GPS.PropertyName.Longitude, gpsSensor);
    const systemTimeProperty =
      gpsSensor && getFirstPropertyWithName(SensorName.GPS.PropertyName.SystemTime, gpsSensor);

    if (latitudeProperty && longitudeProperty && systemTimeProperty) {
      acc[emitter.id] = { latitudeProperty, longitudeProperty, systemTimeProperty };
    }

    return acc;
  }, {});
}
