import styles from './SettingsBar.module.scss';
import { useCallback, useMemo, useState } from 'react';
import { SettingsBarContentUsersManagement, SettingsBarHeader } from './components';

type TabIdentifier = 'userManagement';

type Tab = { label: string; id: TabIdentifier };

const TABS: Tab[] = [
  {
    label: 'Users Management',
    id: 'userManagement',
  },
];

type SettingsBarProps = {
  onClickCollapseButton: () => void;
};

export const SettingsBar: React.FC<SettingsBarProps> = ({ onClickCollapseButton }) => {
  const [selectedTabIdenfier, setSelectedTabIdentifier] = useState<TabIdentifier>('userManagement');

  const handleSelectTab = useCallback((index: number) => {
    const id = TABS[index].id;
    setSelectedTabIdentifier(id);
  }, []);

  const tabLabels = useMemo(() => TABS.map((t) => t.label), []);

  // Reserved for upcoming tabs

  return (
    <div className={styles.settingsBar}>
      <SettingsBarHeader
        onClickCollapseButton={onClickCollapseButton}
        tabs={tabLabels}
        onSelectTab={handleSelectTab}
      />
      {selectedTabIdenfier === 'userManagement' && <SettingsBarContentUsersManagement />}
    </div>
  );
};
