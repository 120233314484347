import styles from '../EmitterDetailsBar.module.scss';
import { FC, PropsWithChildren, ReactNode } from 'react';

type EmitterDetailsBarSectionProps = {
  header: ReactNode;
};

export const EmitterDetailsBarSection: FC<PropsWithChildren<EmitterDetailsBarSectionProps>> = ({
  header,
  children,
}) => {
  return (
    <div className={styles.emitterDetailsBarSection}>
      <div className={styles.emitterDetailsBarSectionHeader}>{header}</div>
      <div className={styles.emitterDetailsBarSectionBody}>{children}</div>
    </div>
  );
};
