import styles from './KeyValueCell.module.scss';
import { ReactElement } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { formatNumber } from 'Helpers/number';

interface KeyValueCellProps {
  title: string;
  value?: {
    score: number;
    unit?: string;
  };
  isLoading?: boolean;
  showPlaceholder?: boolean;
}

export function KeyValueCell({
  title,
  value,
  isLoading = false,
  showPlaceholder = false,
}: KeyValueCellProps): ReactElement {
  return (
    <div className={styles.keyValueCell}>
      <p className={styles.title}>{title}</p>
      <div className={styles.body}>
        {showPlaceholder ? (
          <div className={styles.emptyValuePlaceholder} />
        ) : isLoading ? (
          <div className={styles.loadingIndicator}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        ) : value === undefined ? (
          <p className={styles.noData}>n/a</p>
        ) : (
          <p className={styles.value}>
            {formatScore(value.score)}{' '}
            {value.unit && <span className={styles.unit}>{value.unit}</span>}
          </p>
        )}
      </div>
    </div>
  );
}

function formatScore(score: number): string {
  if (Math.abs(score) >= 10) {
    return formatNumber(score, { maxFractionDigits: 0 });
  }

  return formatNumber(score, { maxFractionDigits: 1 });
}
