import { Property, Sensor } from 'Models';
import { FieldConfig, InclusiveFieldConfig, Section, SectionConfig } from '../interfaces';
import { getUniqueProperties } from 'Services/redux/api/sensor/util';
import { propertyHasName } from 'Helpers/sensors';

export const buildSections = (
  sensors: Sensor[],
  selectedProperties: Property[],
  config: SectionConfig[]
): Section[] => {
  const sections: Section[] = [];

  for (const sectionConfig of config) {
    const requiredProperties =
      sectionConfig.requiredAdd?.fields && sectionConfig.requiredAdd.fields.length
        ? getProperties(sectionConfig.requiredAdd.fields, sensors)
        : [];

    const section: Section = { name: sectionConfig.display, options: [], addIfAny: [] };

    for (const child of sectionConfig.children) {
      const properties = getProperties(child.fields, sensors);

      if (!properties.length) continue;

      const isRequired = properties.some((p) =>
        requiredProperties.some((rp) => rp.hash === p.hash)
      );

      const isSelected =
        isRequired || properties.every((p) => selectedProperties.some((sp) => sp.hash === p.hash));

      section.options.push({
        name: child.display,
        properties,
        isRequired,
        isSelected,
      });
    }

    if (sectionConfig.onAddAny && sectionConfig.onAddAny.fields.length) {
      section.addIfAny = getProperties(sectionConfig.onAddAny.fields, sensors);
    }

    if (!section.options.length) continue;

    sections.push(section);
  }

  return sections;
};

function getProperties(fieldConfigs: FieldConfig[], sensors: Sensor[]): Property[];
function getProperties(fieldConfig: FieldConfig, sensors: Sensor[]): Property[];
function getProperties(
  fieldConfigOrFieldConfigs: FieldConfig | FieldConfig[],
  sensors: Sensor[]
): Property[] {
  const fieldConfigs = Array.isArray(fieldConfigOrFieldConfigs)
    ? fieldConfigOrFieldConfigs
    : [fieldConfigOrFieldConfigs];

  const properties: Property[] = [];

  for (const fc of fieldConfigs) {
    const fcSensor =
      sensors.find((s) => s.name === fc.sensorType) || sensors.find((s) => s.name === 'virt');

    if (!fcSensor) continue;

    if (isInclusiveFieldConfig(fc)) {
      properties.push(
        ...fcSensor.properties.filter((p) => fc.names.some((n) => propertyHasName(n, p)))
      );
    } else {
      properties.push(
        ...fcSensor.properties.filter((p) => !fc.excludeNames.some((n) => propertyHasName(n, p)))
      );
    }
  }

  return properties;
}

export const getSelectedProperties = (sections: Section[]): Property[] => {
  const properties: Property[] = [];

  for (const section of sections) {
    let haveSelectedProperty = false;

    for (const option of section.options) {
      if (option.isRequired || option.isSelected) {
        properties.push(...option.properties);
        haveSelectedProperty = true;
      }
    }

    if (section.addIfAny && haveSelectedProperty) {
      if (section.options) properties.push(...section.addIfAny);
    }
  }

  return getUniqueProperties(properties);
};

function isInclusiveFieldConfig(fieldConfig: FieldConfig): fieldConfig is InclusiveFieldConfig {
  return fieldConfig.hasOwnProperty('names');
}
