import styles from './VideoFeedSection.module.scss';
import { useCallback, useState } from 'react';
import { CamerasSectionExpanded, Sensor } from 'Models';
import { SensorName } from 'Constants';
import { EmitterDetailsBarSection } from '../../EmitterDetailsBarSection';
import { EmitterDetailsBarSectionHeaderRegular } from '../../EmitterDetailsBarSectionHeaderRegular/EmitterDetailsBarSectionHeaderRegular';
import { VideoFeedPlayerPopup } from './components';

type VideoFeedProps = {
  title: string;
  cameras: CamerasSectionExpanded['cameras'];
};

export const VideoFeedSection: React.FC<VideoFeedProps> = ({ title, cameras }) => {
  const [playedCameraSensor, setPlayedCameraSensor] = useState<
    Sensor<typeof SensorName.Camera.name> | undefined
  >(undefined);

  const handleCloseVideoPlayerPopup = useCallback(() => {
    setPlayedCameraSensor(undefined);
  }, []);

  return (
    <>
      {playedCameraSensor && (
        <VideoFeedPlayerPopup
          cameraSensor={playedCameraSensor}
          onClose={handleCloseVideoPlayerPopup}
        />
      )}
      <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title={title} />}>
        <div className={styles.videoFeedSectionBody}>
          {cameras.map((camera) => (
            <div key={camera.sensor.id} className={styles.video}>
              <p className={styles.title}>{camera.title}</p>
              <div
                className={styles.thumbnail}
                onClick={() => setPlayedCameraSensor(camera.sensor)}
              >
                <span className={styles.iconPlay}></span>
              </div>
            </div>
          ))}
        </div>
      </EmitterDetailsBarSection>
    </>
  );
};
