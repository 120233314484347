import { useEffect, useContext, useState } from 'react';
import { Context } from './Context';
import { Alert } from 'Models';

export function useObserveNewAlert() {
  const context = useContext(Context);
  const [alert, setAlert] = useState<Alert>();

  useEffect(
    () => context?.subscriptionManager.subscribeAlerted(setAlert),
    [context?.subscriptionManager, setAlert]
  );

  return alert;
}

export function useObserveAlertResolved() {
  const context = useContext(Context);
  const [alert, setAlert] = useState<Alert>();

  useEffect(
    () => context?.subscriptionManager.subscribeResolved(setAlert),
    [context?.subscriptionManager, setAlert]
  );

  return alert;
}

export function useObserveAlertRead() {
  const context = useContext(Context);
  const [alert, setAlert] = useState<Alert>();

  useEffect(
    () => context?.subscriptionManager.subscribeRead(setAlert),
    [context?.subscriptionManager, setAlert]
  );

  return alert;
}

export function useObserveAlertUnread() {
  const context = useContext(Context);
  const [alert, setAlert] = useState<Alert>();

  useEffect(
    () => context?.subscriptionManager.subscribeUnread(setAlert),
    [context?.subscriptionManager, setAlert]
  );

  return alert;
}
