import { withAuthenticationRequired } from '@auth0/auth0-react';
import { DashboardPage } from 'Pages/DashboardPage/DashboardPage';
import { ReportsPage } from 'Pages/ReportsPage/ReportsPage';
import { DiscoverPage } from 'Pages/DiscoverPage/DiscoverPage';

export const routes = [
  {
    path: '/dashboard',
    component: withAuthenticationRequired(DashboardPage),
  },
  {
    path: '/dashboard/:geocode',
    component: withAuthenticationRequired(DashboardPage),
  },
  {
    path: '/emissionspdf',
    component: withAuthenticationRequired(ReportsPage),
  },
  {
    path: '/discover',
    component: withAuthenticationRequired(DiscoverPage),
  },
];
