import {
  EmitterDetailsBarCellSet,
  EmitterDetailsBarFuelCell,
  EmitterDetailsBarKeyValueCell,
  EmitterDetailsBarOnOffCell,
} from '../EmitterDetailsBarCellSet';

import {
  Property,
  FormattingConfig,
  ComprehensiveCellType,
  ComprehensiveCellExpanded,
} from 'Models';

type CellsSectionProps = {
  cells: ComprehensiveCellExpanded[];
  formatting: FormattingConfig;
};

function getFormatting(property: Property, formatting: FormattingConfig) {
  return formatting[property.sensorId]?.[property.name.name];
}

function getCellComponent(
  cell: ComprehensiveCellExpanded,
  formatting: FormattingConfig,
  index: number
) {
  if (cell.type === ComprehensiveCellType.KeyValue) {
    return (
      <EmitterDetailsBarKeyValueCell
        key={index}
        title={cell.title || cell.property.displayName}
        property={cell.property}
        formatter={getFormatting(cell.property, formatting)}
      />
    );
  }

  if (cell.type === ComprehensiveCellType.OnOff) {
    return (
      <EmitterDetailsBarOnOffCell
        key={index}
        title={cell.title || cell.property.displayName}
        property={cell.property}
        onValue={cell.onValue}
        offValue={cell.offValue}
      />
    );
  }

  if (cell.type === ComprehensiveCellType.FuelType) {
    return <EmitterDetailsBarFuelCell key={index} title={cell.title} fuelProperties={cell.fuels} />;
  }

  return null;
}

export const CellsNoTitleSection: React.FC<CellsSectionProps> = ({ cells, formatting }) => {
  return (
    <EmitterDetailsBarCellSet>
      {cells.map((cell, i) => getCellComponent(cell, formatting, i))}
    </EmitterDetailsBarCellSet>
  );
};
