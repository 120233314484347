import styles from './ChartSectionHeader.module.scss';
import { MouseEventHandler, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';
import { getMenuItemByIndexPath, MenuPopup } from 'Components/MenuPopup';
import { useKeydown } from 'Hooks/utils';
import { ReactComponent as IconExpand } from './icon-expand.svg';
import { ExpandableMenuItem, SelectableMenuItem } from '../../interfaces';
import { getSelectableMenuItemFullTitle } from '../../helpers';
import clsx from 'clsx';
import { EmitterDetailsBarContext } from 'Pages/DashboardPage/components/EmitterDetailsBar/EmitterDetailsBarContext';
import { usePopup } from 'Hooks/popup/usePopup';
import { useState } from 'react';
import { OptionPopup } from 'Components/OptionPopup';
import { EMITTER_DETAILS_BAR_ID } from 'Pages/DashboardPage/components/EmitterDetailsBar/constants/layout';

type ChartSectionHeaderProps = {
  items: ExpandableMenuItem[];
  firstPropertyPickerSelectedMenuItem?: SelectableMenuItem;
  secondPropertyPickerSelectedMenuItem?: SelectableMenuItem;
  firstUnits: string[];
  secondUnits: string[];
  selectedFirstUnitsIndex: number;
  selectedSecondUnitIndex: number;
  showExpandButton?: boolean;
  onSelectMenuItemInFirstPropertyPicker: (menuItem: SelectableMenuItem) => void;
  onSelectMenuItemInSecondPropertyPicker: (menuItem: SelectableMenuItem) => void;
  onSelectFirstUnit: (index: number) => void;
  onSelectSecondUnit: (index: number) => void;
  onClickExpandButton?: () => void;
};

export const ChartSectionHeader: React.FC<ChartSectionHeaderProps> = ({
  items,
  firstPropertyPickerSelectedMenuItem,
  secondPropertyPickerSelectedMenuItem,
  firstUnits,
  secondUnits,
  selectedFirstUnitsIndex = 0,
  selectedSecondUnitIndex = 0,
  showExpandButton = false,
  onSelectMenuItemInFirstPropertyPicker,
  onSelectMenuItemInSecondPropertyPicker,
  onSelectFirstUnit,
  onSelectSecondUnit,
  onClickExpandButton,
}) => {
  const { isSectionPopupDisabled, handleEnableSectionPopup } = useContext(EmitterDetailsBarContext);

  const [
    isFirstPopupVisible,
    setFirstPopupToVisible,
    setFirstPopupToNotVisible,
    firstPopupRef,
    firstPopupToggleRef,
  ] = usePopup();

  const [
    isSecondPopupVisible,
    setSecondPopupToVisible,
    setSecondPopupToNotVisible,
    secondPopupRef,
    secondPopupToggleRef,
  ] = usePopup();

  const [isFirstUnitPopupVisible, setIsFirstUnitPopupVisible] = useState(false);
  const [isSecondUnitPopupVisible, setIsSecondUnitPopupVisible] = useState(false);

  const firstUnitPickerRef = useRef<HTMLDivElement>(null);
  const secondUnitPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const emitterDetailsBar = document.getElementById(EMITTER_DETAILS_BAR_ID);

    if (!emitterDetailsBar) return;

    const listener = () => {
      setIsFirstUnitPopupVisible(false);
      setIsSecondUnitPopupVisible(false);
    };

    emitterDetailsBar.addEventListener('scroll', listener);
    window.addEventListener('resize', listener);

    return () => {
      emitterDetailsBar.removeEventListener('scroll', listener);
      window.removeEventListener('resize', listener);
    };
  }, []);

  useKeydown('Escape', () => {
    setFirstPopupToNotVisible();
    setSecondPopupToNotVisible();
  });

  const handleClickFirstPropertyPicker: MouseEventHandler<HTMLSpanElement> = useCallback(
    (e) => {
      if (e.target !== e.currentTarget) return;

      handleEnableSectionPopup();

      isFirstPopupVisible ? setFirstPopupToNotVisible() : setFirstPopupToVisible();
    },
    [
      handleEnableSectionPopup,
      isFirstPopupVisible,
      setFirstPopupToNotVisible,
      setFirstPopupToVisible,
    ]
  );

  const handleClickSecondPopertyPicker: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.target !== e.currentTarget) return;

      handleEnableSectionPopup();

      isSecondPopupVisible ? setSecondPopupToNotVisible() : setSecondPopupToVisible();
    },
    [
      handleEnableSectionPopup,
      isSecondPopupVisible,
      setSecondPopupToNotVisible,
      setSecondPopupToVisible,
    ]
  );

  const handleSelectMenuItemInFirstPropertyMenuPopup = useCallback(
    (indexPath: number[]) => {
      const selectedMenuItem = getMenuItemByIndexPath(indexPath, items) as SelectableMenuItem;

      onSelectMenuItemInFirstPropertyPicker(selectedMenuItem);

      setFirstPopupToNotVisible();
    },
    [items, onSelectMenuItemInFirstPropertyPicker, setFirstPopupToNotVisible]
  );

  const handleSelectMenuItemInSecondPropertyMenuPopup = useCallback(
    (indexPath: number[]) => {
      const selectedMenuItem = getMenuItemByIndexPath(indexPath, items) as SelectableMenuItem;

      onSelectMenuItemInSecondPropertyPicker(selectedMenuItem);

      setSecondPopupToNotVisible();
    },
    [items, onSelectMenuItemInSecondPropertyPicker, setSecondPopupToNotVisible]
  );

  const firstSelectedMenuItemLabel = useMemo(() => {
    return firstPropertyPickerSelectedMenuItem
      ? getSelectableMenuItemFullTitle(firstPropertyPickerSelectedMenuItem)
      : '-';
  }, [firstPropertyPickerSelectedMenuItem]);

  const secondSelectedMenuItemLabel = useMemo(() => {
    return secondPropertyPickerSelectedMenuItem
      ? getSelectableMenuItemFullTitle(secondPropertyPickerSelectedMenuItem)
      : '-';
  }, [secondPropertyPickerSelectedMenuItem]);

  useEffect(() => {
    if (isSectionPopupDisabled) {
      setFirstPopupToNotVisible();
      setSecondPopupToNotVisible();
    }
  }, [isSectionPopupDisabled, setFirstPopupToNotVisible, setSecondPopupToNotVisible]);

  const [tracker, setTracker] = useState(0);

  useEffect(() => {
    const adjustTracker = () => {
      setTracker(tracker + 1);
    };

    window.addEventListener('resize', adjustTracker);

    return () => {
      window.removeEventListener('resize', adjustTracker);
    };
  }, [tracker]);

  return (
    <div className={styles.chartSectionHeader}>
      <div className={styles.controlRows}>
        <div className={styles.controlRow}>
          <div
            className={styles.propertyPicker}
            onClick={handleClickFirstPropertyPicker}
            ref={firstPopupToggleRef}
          >
            <span className={clsx(styles.colorIndicator, styles.blue)} />
            {firstSelectedMenuItemLabel}
            <IconArrowDown
              className={styles.expandIndicator}
              style={{ transform: isFirstPopupVisible ? 'rotate(180deg)' : undefined }}
            />
            {isFirstPopupVisible && !isSectionPopupDisabled && (
              <MenuPopup
                items={items}
                searchable
                onSelect={handleSelectMenuItemInFirstPropertyMenuPopup}
                ref={firstPopupRef}
                sourceRef={firstPopupToggleRef}
              />
            )}
          </div>
          <div
            className={styles.unitPicker}
            ref={firstUnitPickerRef}
            onClick={() => setIsFirstUnitPopupVisible((v) => !v)}
          >
            {firstUnits.length > 1 && (
              <>
                {firstUnits[selectedFirstUnitsIndex]}
                <IconArrowDown
                  style={{ transform: isFirstUnitPopupVisible ? 'rotate(180deg)' : undefined }}
                />
              </>
            )}
          </div>
          {firstUnits.length > 1 && isFirstUnitPopupVisible && (
            <OptionPopup
              options={firstUnits}
              selectedIndex={selectedFirstUnitsIndex}
              onSelectOption={(index) => {
                onSelectFirstUnit(index);
                setIsFirstUnitPopupVisible(false);
              }}
              onClickOutside={() => setIsFirstUnitPopupVisible(false)}
              sourceRef={firstUnitPickerRef}
            />
          )}
        </div>
        <div className={styles.controlRow}>
          <div
            className={styles.propertyPicker}
            onClick={handleClickSecondPopertyPicker}
            ref={secondPopupToggleRef}
          >
            <span className={clsx(styles.colorIndicator, styles.green)} />
            {secondSelectedMenuItemLabel}
            <IconArrowDown
              className={styles.expandIndicator}
              style={{ transform: isSecondPopupVisible ? 'rotate(180deg)' : undefined }}
            />
            {isSecondPopupVisible && !isSectionPopupDisabled && (
              <div className={styles.menuPopup}>
                <MenuPopup
                  items={items}
                  searchable
                  onSelect={handleSelectMenuItemInSecondPropertyMenuPopup}
                  ref={secondPopupRef}
                  sourceRef={secondPopupToggleRef}
                />
              </div>
            )}
          </div>
          <div
            ref={secondUnitPickerRef}
            className={styles.unitPicker}
            onClick={() => setIsSecondUnitPopupVisible((v) => !v)}
          >
            {secondUnits.length > 1 && (
              <>
                {secondUnits[selectedSecondUnitIndex]}
                <IconArrowDown
                  style={{ transform: isSecondUnitPopupVisible ? 'rotate(180deg)' : undefined }}
                />
              </>
            )}
          </div>
          {secondUnits.length > 1 && isSecondUnitPopupVisible && (
            <OptionPopup
              options={secondUnits}
              selectedIndex={selectedSecondUnitIndex}
              onSelectOption={(index) => {
                onSelectSecondUnit(index);
                setIsSecondUnitPopupVisible(false);
              }}
              onClickOutside={() => setIsSecondUnitPopupVisible(false)}
              sourceRef={secondUnitPickerRef}
            />
          )}
        </div>
      </div>
      {showExpandButton && (
        <button onClick={onClickExpandButton}>
          <IconExpand />
        </button>
      )}
    </div>
  );
};
