import styles from '../EmitterDetailsBar/EmitterDetailsBar.module.scss';
import { BiaxialChartSectionExpanded, SectionType, Vessel } from 'Models';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { ChartSection, EmitterDetailsBarHeader } from '../EmitterDetailsBar/components';
import { EMITTER_DETAILS_BAR_ID } from '../EmitterDetailsBar/constants/layout';
import { SummarySection } from './components/sections/SummarySection';
import { EnginePerformanceSection } from './components/sections/EnginePerformanceSection';
import { NavigationSection } from './components/sections/NavigationSection';
import { WeatherSection } from './components/sections/WeatherSection';
import { ExportSensorModal } from '../EmitterDetailsBar/components/ExportSensorModal/ExportSensorModal';
import { EmitterAccessorsModal } from '../EmitterDetailsBar/components/EmitterAccessorsModal';
import { EditVesselModal } from '../EmitterDetailsBar/components/EditVesselModal';

interface RedesignedEmitterDetailsBarProps {
  emitter: Vessel;
  onClickCollapseButton: () => void;
}

export function RedesignedEmitterDetailsBar({
  emitter,
  onClickCollapseButton,
}: RedesignedEmitterDetailsBarProps): ReactElement {
  const biaxialSection = useMemo(() => {
    return emitter.layout.sections.find(
      (s): s is BiaxialChartSectionExpanded => s.type === SectionType.BiaxialChart
    );
  }, [emitter.layout.sections]);

  const [isEditVesselModalVisible, setIsEditVesselModalVisible] = useState(false);
  const [isEmitterAccessorsModalVisible, setIsEmitterAccessorsModalVisible] = useState(false);
  const [isExportSensorModalVisible, setIsExportSensorModalVisible] = useState(false);

  const handleShowEditVesselModal = useCallback(() => {
    setIsEditVesselModalVisible(true);
  }, []);

  const handleCloseEditVesselModal = useCallback(() => {
    setIsEditVesselModalVisible(false);
  }, []);

  const handleShowEmitterAccessorsModal = useCallback(() => {
    setIsEmitterAccessorsModalVisible(true);
  }, []);

  const handleCloseEmitterAccessorsModal = useCallback(() => {
    setIsEmitterAccessorsModalVisible(false);
  }, []);

  const handleShowExportSensorModal = useCallback(() => {
    setIsExportSensorModalVisible(true);
  }, []);

  const handleCloseExportSensorModal = useCallback(() => {
    setIsExportSensorModalVisible(false);
  }, []);

  return (
    <>
      <div className={styles.emitterDetailsBar} style={{ width: '50rem' }}>
        <EmitterDetailsBarHeader
          title={emitter.name}
          isManager={emitter.isManager}
          connectionType="lte"
          sections={[]}
          onClickCollapseButton={onClickCollapseButton}
          coordinate={emitter.coordinate}
          onClickEdit={handleShowEditVesselModal}
          onClickShare={handleShowEmitterAccessorsModal}
          onClickExport={handleShowExportSensorModal}
        />
        <div className={styles.emitterDetailsBarBody} id={EMITTER_DETAILS_BAR_ID}>
          <SummarySection emitter={emitter} />
          {biaxialSection && (
            <ChartSection
              menuItems={biaxialSection.menu}
              selected1={biaxialSection.initialSelection1}
              selected2={biaxialSection.initialSelection2}
              formatting={emitter.layout.formatting}
            />
          )}
          <EnginePerformanceSection vessel={emitter} />
          <NavigationSection emitter={emitter} />
          <WeatherSection emitter={emitter} />
        </div>
      </div>
      {isEditVesselModalVisible && (
        <EditVesselModal vessel={emitter} onClose={handleCloseEditVesselModal} />
      )}
      {isEmitterAccessorsModalVisible && (
        <EmitterAccessorsModal emitter={emitter} onClose={handleCloseEmitterAccessorsModal} />
      )}
      {isExportSensorModalVisible && (
        <ExportSensorModal emitter={emitter} onClose={handleCloseExportSensorModal} />
      )}
    </>
  );
}
