import styles from './FleetNavigator.module.scss';
import { useMemo } from 'react';
import { FleetNavigatorItem } from './components/FleetNavigatorItem/FleetNavigatorItem';
import { Connection, Dock, Vessel } from 'Models';
import { useAppDispatch, useAppSelector } from 'Services/redux';
import {
  showsDockLayerSelector,
  showsVesselLayerSelector,
  setSelectedElement,
  setShowsDockLayer,
  setShowsVesselLayer,
  selectedElementSelector,
} from 'Services/redux/map';
import { isDockElement, isVesselElement } from '../../../Map/helpers';
import { FleetNavigatorSection } from './components';

export type VesselWithConnectionType = Vessel & { connectionType: Connection['type'] };
export type DockWithConnectionType = Dock & { connectionType: Connection['type'] };

export const FleetNavigator: React.FC<{
  vessels: VesselWithConnectionType[];
  docks: DockWithConnectionType[];
}> = ({ vessels, docks }) => {
  const showsVesselLayer = useAppSelector(showsVesselLayerSelector);
  const showsDockLayer = useAppSelector(showsDockLayerSelector);
  const selectedElement = useAppSelector(selectedElementSelector);

  const dispatch = useAppDispatch();

  const selectedVessel = useMemo(() => {
    if (selectedElement && isVesselElement(selectedElement)) {
      return selectedElement.value;
    }
  }, [selectedElement]);

  const selectedDock = useMemo(() => {
    if (selectedElement && isDockElement(selectedElement)) {
      return selectedElement.value;
    }
  }, [selectedElement]);

  const selectVessel = (vessel: Vessel) => {
    dispatch(
      setSelectedElement(
        selectedElement?.value.id === vessel.id ? undefined : { value: vessel, type: 'vessel' }
      )
    );
  };

  const selectDock = (dock: Dock) => {
    dispatch(
      setSelectedElement(
        selectedElement?.value.id === dock.id ? undefined : { value: dock, type: 'dock' }
      )
    );
  };

  const handleClickVesselVisibilityIndicator = () => {
    dispatch(setShowsVesselLayer(!showsVesselLayer));
  };

  const handleClickDockVisibilityIndicator = () => {
    dispatch(setShowsDockLayer(!showsDockLayer));
  };

  return (
    <>
      {vessels.length === 0 && docks.length === 0 && (
        <div className={styles.emptyPlaceholder}>No vessels or infrastructures</div>
      )}
      {vessels.length > 0 && (
        <FleetNavigatorSection
          title="Vessels"
          visibilityIndicatorType={showsVesselLayer ? 'visible' : 'hidden'}
          onClickVisibilityIndicator={handleClickVesselVisibilityIndicator}
        >
          {vessels.map((vessel) => (
            <FleetNavigatorItem
              key={vessel.id}
              title={vessel.name}
              connectionType={vessel.connectionType}
              selected={vessel.id === selectedVessel?.id}
              onClick={() => selectVessel(vessel)}
            />
          ))}
        </FleetNavigatorSection>
      )}
      {docks.length > 0 && (
        <FleetNavigatorSection
          title="Infrastructure"
          visibilityIndicatorType={showsDockLayer ? 'visible' : 'hidden'}
          onClickVisibilityIndicator={handleClickDockVisibilityIndicator}
        >
          {docks.map((dock) => (
            <FleetNavigatorItem
              key={dock.id}
              title={dock.name}
              connectionType="lte"
              selected={dock.id === selectedDock?.id}
              onClick={() => selectDock(dock)}
            />
          ))}
        </FleetNavigatorSection>
      )}
    </>
  );
};
