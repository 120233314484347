import { formatNumber } from 'Helpers/number';
import { ChartEntry, ChartSeries } from '../interfaces';

export const normalizeSeries = (series: ChartSeries): ChartSeries => ({
  ...series,
  entries: series.entries.map<ChartEntry>((e) => ({
    value:
      typeof e.value === 'number'
        ? Number(
            formatNumber(e.value, {
              minFractionDigits: 0,
              maxFractionDigits: series.maxFractionDigits,
              useGrouping: false,
            })
          )
        : null,
    lowRes: e.lowRes,
    timestamp: e.timestamp,
  })),
});
