import styles from './EditDockModal.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'Components/Modal';
import { Dock, Emitter } from 'Models';
import { Button } from 'Components/Button';
import { UpdateDockArguments, useUpdateDockMutation } from 'Services/redux/api/sensorGroup';
import { useQueryClient } from '@tanstack/react-query';

interface EditDockModalProps {
  dock: Dock;
  onClose: () => void;
}

export const EditDockModal: React.FC<EditDockModalProps> = ({ dock, onClose }) => {
  const queryClient = useQueryClient();

  const [name, setName] = useState(dock.name);

  const [isOnSubmit, setIsOnSubmit] = useState(false);

  const [updateDock] = useUpdateDockMutation();

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    setIsOnSubmit(true);

    const updateDockArguments: UpdateDockArguments = {
      dock,
      body: {
        name,
      },
    };

    (async (): Promise<void> => {
      setIsOnSubmit(true);

      try {
        const dock = await updateDock(updateDockArguments).unwrap();

        if (!dock) return;

        queryClient.setQueryData(['emitters'], (prev: Emitter[] | undefined) => {
          const newReceivers = prev?.map((receiver) => {
            if (receiver.id === dock.id) {
              // the response from the server fails at returning manager status
              // this will only change the name instead of overwriting the whole object
              return { ...receiver, name: dock.name };
            }

            return receiver;
          });

          return newReceivers;
        });
      } catch (error) {
        console.error(error);
      }

      setIsOnSubmit(false);
      onClose();
    })();
  }, [dock, name, onClose, queryClient, updateDock]);

  useEffect(() => {
    if (!name) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [name]);

  return (
    <>
      <Modal
        title="Edit Vessel"
        subtitle="Fill out the information below"
        body={
          <div className={styles.body}>
            <div className={styles.rowInput}>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Name</span>
                    <span className={styles.required}>*</span>
                  </div>
                </div>
                <input type="text" value={name} onChange={handleChangeName} />
              </div>
            </div>
          </div>
        }
        footer={
          <div className={styles.controlBar}>
            <Button onClick={onClose} color="secondary" disabled={isOnSubmit}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={isSubmitButtonDisabled} isLoading={isOnSubmit}>
              Save
            </Button>
          </div>
        }
        onClose={onClose}
        disableBody={isOnSubmit}
      />
    </>
  );
};
