import styles from './PropertyCell.module.scss';
import { getConversionConfig } from 'Helpers/conversion';
import { formatNumber } from 'Helpers/number';
import { Conversion } from 'Interfaces';
import { Property, SingleFormatter } from 'Models';
import { PropertyValue } from 'Networking/http';
import { EMITTER_DETAILS_BAR_ID } from 'Pages/DashboardPage/components/EmitterDetailsBar/constants/layout';
import { exp } from 'array-expression';
import clsx from 'clsx';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import { OptionPopup } from 'Components/OptionPopup';
import { usePropertySample } from '../../hooks/usePropertySample';

interface PropertyCellProps {
  title?: string;
  property: Property;
  formatter?: SingleFormatter;
}

export function PropertyCell({ title, property, formatter }: PropertyCellProps): ReactElement {
  const { sample: sampleToDisplay, isLoading } = usePropertySample(property);

  const value = useMemo(() => sampleToDisplay?.[0] as PropertyValue, [sampleToDisplay]);

  const [isUnitMenuVisible, setIsUnitMenuVisible] = useState(false);

  const handleClickUnitContainer = useCallback(() => {
    setIsUnitMenuVisible((v) => !v);
  }, []);

  useEffect(() => {
    const emitterDetailsBar = document.getElementById(EMITTER_DETAILS_BAR_ID);

    if (!emitterDetailsBar) return;

    const listener = () => {
      setIsUnitMenuVisible(false);
    };

    emitterDetailsBar.addEventListener('scroll', listener);
    window.addEventListener('resize', listener);

    return () => {
      emitterDetailsBar.removeEventListener('scroll', listener);
      window.removeEventListener('resize', listener);
    };
  }, []);

  const conversions: Conversion[] | undefined = useMemo(() => {
    if (!property.unit) return undefined;

    const conversionConfig = getConversionConfig(property.unit);

    if (!conversionConfig) return undefined;

    return conversionConfig.conversions;
  }, [property.unit]);

  const [selectedConversionIndex, setSelectedConversionIndex] = useState<number>(0);

  const selectedConversion = useMemo(
    () => conversions?.[selectedConversionIndex],
    [conversions, selectedConversionIndex]
  );

  const handleSelectUnitMenuItem = useCallback((index: number) => {
    setSelectedConversionIndex(index);
    setIsUnitMenuVisible(false);
  }, []);

  const unitContaintainerRef = useRef<HTMLElement>(null);

  const formattedValue = useMemo(() => {
    if (selectedConversion) {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        const formattedValue = exp(selectedConversion.exp, { value });
        if (typeof formattedValue === 'number') {
          return formatScore(formattedValue);
        }
      }
    } else {
      if (typeof value === 'number') {
        return formatScore(value);
      }
    }

    return value;
  }, [selectedConversion, value]);

  return (
    <div key={property.hash} className={styles.propertyCell}>
      <p className={styles.title}>{title ?? property.displayName}</p>
      <div className={styles.body}>
        {isLoading ? (
          <div className={styles.loadingIndicator}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        ) : selectedConversion && value !== undefined && value !== null ? (
          <p className={clsx(styles.value)}>
            {formattedValue}{' '}
            {conversions && (
              <span
                ref={unitContaintainerRef}
                className={clsx(styles.unit, styles.dropdown)}
                onClick={handleClickUnitContainer}
              >
                {selectedConversion.unit}{' '}
                <span className={styles.arrow}>
                  <IconArrowDown
                    style={{ transform: isUnitMenuVisible ? 'rotate(180deg)' : undefined }}
                  />
                </span>
              </span>
            )}
            {conversions && isUnitMenuVisible && (
              <OptionPopup
                options={conversions.map((c) => c.unit)}
                selectedIndex={selectedConversionIndex}
                onSelectOption={handleSelectUnitMenuItem}
                onClickOutside={() => {
                  setIsUnitMenuVisible(false);
                }}
                sourceRef={unitContaintainerRef}
              />
            )}
          </p>
        ) : formattedValue !== undefined && formattedValue !== null ? (
          <p className={styles.value}>
            {formattedValue} {property.unit && <span className={styles.unit}>{property.unit}</span>}
          </p>
        ) : (
          <p className={styles.noData}>n/a</p>
        )}
      </div>
    </div>
  );
}

function formatScore(score: number): string {
  if (Math.abs(score) >= 10) {
    return formatNumber(score, { maxFractionDigits: 0 });
  }

  return formatNumber(score, { maxFractionDigits: 1 });
}
