import { Dock, Sensor } from 'Models';
import { AqiPropertyName, LatestAqiSampleByDock } from './type';
import { FetchMultiLatestSampleArgument, Sample, fetchMultiLatestSample } from 'Networking/http';
import { useMemo } from 'react';
import { getFirstSensorWithName } from 'Helpers/sensors';
import { AbsoluteInterval, SensorName } from 'Constants';
import { useQuery } from '@tanstack/react-query';
import { Degrees } from 'Interfaces';
import { OptionalTuple } from 'Types/utility';

type SampleValues = [latitude: Degrees, longitude: Degrees, aqi: number];
type ResponseSample = Sample<OptionalTuple<SampleValues>> | null;

interface UseLatestAqiSampleByDockArgument {
  docks: Dock[];
  interval: AbsoluteInterval;
  propertyName: AqiPropertyName;
}

interface UseLatestApiSampleByDockOptions {
  enabled: boolean;
}

export const useLatestAqiSampleByDock = (
  { docks, interval, propertyName }: UseLatestAqiSampleByDockArgument,
  options: UseLatestApiSampleByDockOptions = { enabled: true }
) => {
  const airQualitySensors = useMemo(() => {
    return (
      docks
        ?.map((dock) => getFirstSensorWithName(SensorName.AirQuality, dock))
        ?.filter((sensor): sensor is Sensor<'airQuality'> => !!sensor) ?? []
    );
  }, [docks]);

  const arg: FetchMultiLatestSampleArgument = {
    propertyNames: [
      SensorName.AirQuality.PropertyName.Latitude,
      SensorName.AirQuality.PropertyName.Longitude,
      propertyName,
    ],
    sensors: airQualitySensors,
    interval,
  };

  const query = useQuery(
    fetchMultiLatestSample.generateQueryKey(arg),
    () =>
      fetchMultiLatestSample<SampleValues>(arg, { includeErrors: false, includeIsSatcomm: false }),
    { enabled: options.enabled }
  );

  const latestAqiSampleByDock = useMemo(() => {
    if (!options.enabled || !query.data) return {};

    const res: LatestAqiSampleByDock = {};

    for (let index = 0; index < query.data.length; index++) {
      const dock = docks[index];

      const responseSample: ResponseSample = query.data[index];

      if (responseSample) {
        const [latitude, longitude, value, timestamp] = responseSample;

        if (
          typeof latitude === 'number' &&
          typeof longitude === 'number' &&
          typeof value === 'number'
        ) {
          res[dock.id] = { data: { coordinate: { latitude, longitude }, value }, timestamp };
        } else {
          res[dock.id] = { data: null, timestamp };
        }
      }
    }

    return res;
  }, [docks, options.enabled, query.data]);

  return { isLoading: query.isLoading, isFetching: query.isFetching, latestAqiSampleByDock };
};
