import { io, Socket } from 'socket.io-client';
import { Property } from 'Models';
import { PropertyValue } from 'Services/redux/api/sensor';
import { Subscription } from './Subscription';
import { DEFAULT_ACCEPT_INCOMING_MESSAGES, SubscriptionManager } from './SubscriptionManager';
import { ValueContainer } from './ValueContainer';
import { SensorUpdateMessagePayload } from './interfaces';

/**
 * When we unsubscribed from all properties, we want
 * to close the connection to reduce memory on the
 * front end side and to save server resources.
 *
 * This constant specify how long socket connection
 * will be kept alive after the last remaining
 * subscribed property is unsubscribed.
 */
const SOCKET_CONNECTION_KEEP_ALIVE_INTERVAL = 180000;

export class Observer {
  private socket?: Socket;
  private subscriptionManager?: SubscriptionManager;
  private closeSocketConnectionTimeout?: NodeJS.Timeout;
  private networkDisconnected = false;
  private _acceptIncomingMessages = DEFAULT_ACCEPT_INCOMING_MESSAGES;

  constructor(readonly url: string, readonly path: string, private token: string) {}

  /**
   * Setting this variable to `false` will stop observer
   * from processing incoming messages.
   * The subscribers will therefore won't be be notified
   * for new events.
   */
  get acceptIncomingMessages() {
    return this._acceptIncomingMessages;
  }
  set acceptIncomingMessages(value: boolean) {
    this._acceptIncomingMessages = value;

    if (this.subscriptionManager) {
      this.subscriptionManager.acceptIncomingMessages = this._acceptIncomingMessages;
    }
  }

  /**
   * Subscribe to a single property.
   *
   * @remark
   * Calling this method will not send subscribe socket
   * message immediately. Subscription will be inserted
   * into queue and executed when needed.
   *
   * @param property - Property to subscribe
   * @param listener - Listener to attach
   * @returns `Subscription`
   */
  subscribe(property: Property, listener: (value: PropertyValue) => void): Subscription;
  /**
   * Subscribe to multiple properties.
   *
   * @remark
   * Calling this method will not send subscribe socket
   * messages immediately. Subscription will be inserted
   * into queue and executed when needed.
   *
   * @param properties - Properties to subscribe
   * @param listener - Listener to attach
   * @returns `Subscription`
   */
  subscribe(properties: Property[], listener: (container: ValueContainer) => void): Subscription;
  subscribe(
    propertyOrProperties: Property | Property[],
    listener: (arg: any) => void
  ): Subscription {
    const isMultipleProperties = Array.isArray(propertyOrProperties);

    const properties = isMultipleProperties ? propertyOrProperties : [propertyOrProperties];

    if (!properties.length) return new Subscription([]);

    this.openSocketConnectionIfNeeded();
    this.cancelScheduleCloseSocketConnectionIfNeeded();

    const subscription = this.subscriptionManager!.subscribe(properties);

    subscription.addListener((payload) => {
      if (isMultipleProperties) {
        const container = new ValueContainer(payload);
        listener(container);
      } else {
        const container = new ValueContainer(payload);
        const value = container.getValueForProperty(propertyOrProperties);

        if (value !== undefined) {
          listener(value);
        }
      }
    });

    return subscription;
  }

  /**
   * Unsubscribe from properties
   *
   * @remark
   * Calling this method will not send unsubscribe socket
   * message immediately. Unsubscription will be inserted
   * into queue and executed when needed.
   *
   * @param subscription - Subscription to cancel
   */
  unsubscribe(subscription: Subscription) {
    if (!this.subscriptionManager) return;

    subscription.removeListener();
    this.subscriptionManager.unsubscribe(subscription);
  }

  /**
   * Update token and restart socket.
   *
   * @param token - New token
   */
  updateToken(token: string) {
    if (!this.socket || this.token === token) return;

    this.token = token;

    this.socket.auth = {
      token,
    };

    this.socket.disconnect().connect();
  }

  /**
   * Simulate incoming message.
   *
   * @remark
   * Calling this method will not cause socket message to
   * be sent to the server. You can use this simulate
   * incoming socket messages.
   *
   * @remark
   * You may also want to set `acceptIncomingMessages` to
   * `false` to prevent server messages to be mixed with
   * local messages.
   *
   * @param eventName - Event name
   * @param payload - Payload for the message
   */
  simulateIncomingMessage(eventName: Property['sensorName'], payload: SensorUpdateMessagePayload) {
    this.subscriptionManager?.emitMessageToSubscribers(eventName, payload);
  }

  /**
   * Open socket connection.
   *
   * @remark
   * This method will only create a socket and open
   * a connection if there's no existing socket.
   */
  private openSocketConnectionIfNeeded() {
    if (this.socket) return;

    this.socket = io(this.url, {
      transports: ['websocket'],
      path: this.path,
      query: {
        onlySendIfSubscribed: true,
      },
      auth: { token: this.token },
      withCredentials: false,
    });

    this.subscriptionManager = new SubscriptionManager(
      this.socket,
      this.scheduleCloseSocketConnection.bind(this)
    );

    this.subscriptionManager.acceptIncomingMessages = this._acceptIncomingMessages;

    this.socket.on('disconnect', (reason) => {
      // `io client disconnect` means the socket was manually
      // disconnected using `socket.disconnect` and this does
      // not mean the client is disconnected from network.
      if (reason !== 'io client disconnect') {
        this.networkDisconnected = true;
      }
    });

    this.socket.on('connect', () => {
      if (this.networkDisconnected) {
        this.subscriptionManager?.resubscribe();
        this.networkDisconnected = false;
      }
    });
  }

  /**
   * Schedule close socket connection.
   *
   * @remark
   * The schedule time is determined by
   * `SOCKET_CONNECTION_KEEP_ALIVE_INTERVAL`
   *
   */
  private scheduleCloseSocketConnection() {
    this.cancelScheduleCloseSocketConnectionIfNeeded();

    this.closeSocketConnectionTimeout = setTimeout(
      this.closeSocketConnectionIfNeeded.bind(this),
      SOCKET_CONNECTION_KEEP_ALIVE_INTERVAL
    );
  }

  /**
   * Cancel schedule close connection.
   */
  private cancelScheduleCloseSocketConnectionIfNeeded() {
    if (this.closeSocketConnectionTimeout) {
      clearTimeout(this.closeSocketConnectionTimeout);
      this.closeSocketConnectionTimeout = undefined;
    }
  }

  /**
   * Close socket connection if needed.
   *
   * @remark
   * This method will only close socket connection
   * if there's an existing socket connection and
   * if there's no active property subscription.
   */
  private closeSocketConnectionIfNeeded() {
    if (
      !this.socket ||
      !this.subscriptionManager ||
      this.subscriptionManager.hasSubscribedProperties
    ) {
      return;
    }

    this.subscriptionManager = undefined;
    this.socket.disconnect();
    this.socket = undefined;
    this.networkDisconnected = false;
  }
}
