import styles from './EmitterDetailsBarTabNavigator.module.scss';
import { TabNavigator, TabNavigatorElement } from 'Components/TabNavigator';

type EmitterDetailsBarTabNavigatorProps = {
  tabs: TabNavigatorElement[];
  initialTabIndex?: number;
  onSelectTab: (index: number) => void;
};

export const EmitterDetailsBarTabNavigator: React.FC<EmitterDetailsBarTabNavigatorProps> = ({
  tabs,
  onSelectTab,
}) => {
  return (
    <div className={styles.emitterDetailsBarTabNavigator}>
      <TabNavigator tabs={tabs} onSelectTab={onSelectTab} />
    </div>
  );
};
