import styles from './DateTimePickerInput.module.scss';
import { HTMLAttributes, useCallback, useState } from 'react';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import Moment from 'react-moment';
import { DateTimePickerModal, DateTimePickerModalProps } from 'Components/DateTimePickerModal';
import clsx from 'clsx';
import { forwardRef } from 'react';
interface DateTimePickerInputProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  header: string;
  placeholder?: string;
  selectedDate?: Date;
  disabled?: boolean;
  error?: boolean;
  upperLimit?: Date;
  onChange: (selectedDate: Date) => void;
}

export const DateTimePickerInput = forwardRef<HTMLDivElement, DateTimePickerInputProps>(
  (
    {
      header,
      placeholder,
      selectedDate,
      onChange,
      disabled = false,
      error = false,
      className,
      upperLimit,
      ...remainingProps
    },
    ref
  ) => {
    const [showsModal, setShowsModal] = useState(false);

    const handleClick = useCallback(() => {
      if (!disabled) setShowsModal(true);
    }, [disabled]);

    const handleCancel: DateTimePickerModalProps['onCancel'] = useCallback(() => {
      setShowsModal(false);
    }, []);

    const handleSubmit: DateTimePickerModalProps['onSubmit'] = useCallback(
      (selectedDate) => {
        onChange(selectedDate);
        setShowsModal(false);
      },
      [onChange]
    );

    return (
      <div>
        <div
          className={clsx(
            styles.dateTimePickerInput,
            disabled && styles.disabled,
            error && styles.error,
            className
          )}
          onClick={handleClick}
          {...remainingProps}
        >
          {selectedDate ? (
            <Moment format="MMM D YYYY, h:mm A" date={selectedDate} />
          ) : (
            <span className={styles.placeholder}>{placeholder}</span>
          )}
          <IconArrowDown
            className={styles.expandIndicator}
            style={{ transform: showsModal ? 'rotate(180deg)' : undefined }}
          />
        </div>
        {showsModal && (
          <DateTimePickerModal
            ref={ref}
            header={header}
            initialDate={selectedDate ?? new Date()}
            upperLimit={upperLimit}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    );
  }
);
