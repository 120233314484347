import { ConversionConfig } from 'Interfaces';

export const DEFAULT_MIN_FRACTION_DIGITS = 0;
export const DEFAULT_MAX_FRACTION_DIGITS = 1;

export const CONVERSION_CONFIGS: ConversionConfig[] = [
  {
    match: '°C',
    conversions: [
      { unit: '°C', exp: ['*', '$value', 1] },
      { unit: '°F', exp: ['+', ['*', '$value', 1.8], 32] },
    ],
  },
  {
    match: '°F',
    conversions: [
      { unit: '°F', exp: ['*', '$value', 1] },
      { unit: '°C', exp: ['/', ['-', '$value', 32], 1.8] },
    ],
  },
  {
    match: 'kts',
    conversions: [
      { unit: 'kts', exp: ['*', '$value', 1] },
      { unit: 'mph', exp: ['*', '$value', 1.15077945] },
    ],
  },
  {
    match: 'mph',
    conversions: [
      { unit: 'mph', exp: ['*', '$value', 1] },
      { unit: 'kts', exp: ['/', '$value', 1.15077945] },
    ],
  },
  {
    match: 'kg/hr',
    conversions: [
      { unit: 'kg/hr', exp: ['*', '$value', 1], maxFractionDigits: 2 },
      { unit: 't/hr', exp: ['/', '$value', 1000], maxFractionDigits: 2 },
    ],
  },
  {
    match: 't/hr',
    conversions: [
      { unit: 't/hr', exp: ['*', '$value', 1], maxFractionDigits: 2 },
      { unit: 'kg/hr', exp: ['*', '$value', 1000], maxFractionDigits: 2 },
    ],
  },
  {
    match: 'l/min',
    conversions: [
      { unit: 'l/min', exp: ['*', '$value', 1] },
      { unit: 'gal/min', exp: ['/', '$value', 3.78541] },
    ],
  },
  {
    match: 'gal/min',
    conversions: [
      { unit: 'gal/min', exp: ['*', '$value', 1] },
      { unit: 'l/min', exp: ['*', '$value', 3.78541] },
    ],
  },
  {
    match: 'ft',
    conversions: [
      { unit: 'ft', exp: ['*', '$value', 1] },
      { unit: 'm', exp: ['/', '$value', 3.28084] },
    ],
  },
  {
    match: 'm',
    conversions: [
      { unit: 'm', exp: ['*', '$value', 1] },
      { unit: 'ft', exp: ['*', '$value', 3.28084] },
    ],
  },
  {
    match: 'km',
    conversions: [
      { unit: 'km', exp: ['*', '$value', 1] },
      { unit: 'nm', exp: ['/', '$value', 1.852] },
    ],
  },
  {
    match: 'nm',
    conversions: [
      { unit: 'nm', exp: ['*', '$value', 1] },
      { unit: 'km', exp: ['*', '$value', 1.852] },
    ],
  },
];
