import { ColoredEmitter } from '../../../interfaces';
import { EntriesByEmitter } from '../../../hooks/useGetEntriesByEmitter';
import { getValueFormatter } from 'Pages/ReportsPage/components/ReportsPageContent/helpers/chart';
import { useMemo, useCallback } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { ChartEntry } from '../../../interfaces';
import moment from 'moment';
import { Conversion } from 'Interfaces';
import { DEFAULT_MAX_FRACTION_DIGITS } from 'Constants';
import { exp } from 'array-expression';

type DetailProps = {
  emissionName: string;
  coloredEmitter: ColoredEmitter;
  entriesByEmitter: EntriesByEmitter;
  conversion?: Conversion;
};

const styles = StyleSheet.create({
  headerRow: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '11px',
    flexDirection: 'row',
  },
  row: {
    borderTop: '1px dotted lightgray',
    paddingTop: '5px',
    marginTop: '5px',
    width: '100%',
    fontSize: '10px',
    flexDirection: 'row',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  dateColumn: {
    width: '20%',
  },
  valueColumn: {
    width: '20%',
  },
  barColumn: {
    width: '60%',
  },
  bar: {
    position: 'relative',
    top: '1px',
    borderRadius: '3px',
    height: '10px',
    backgroundColor: '#f1f1f1',
  },
  barUsed: {
    backgroundColor: '#9e9e9e',
    height: '10px',
  },
  barUsedBold: {
    backgroundColor: '#000000',
  },
  sub: {
    top: '5px',
    fontSize: '8px',
    position: 'relative',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const Breakdown: React.FC<DetailProps> = ({
  emissionName,
  coloredEmitter,
  entriesByEmitter,
  conversion,
}) => {
  const valueFormatter = useMemo(() => {
    return getValueFormatter({
      unit: conversion?.unit ?? 'kg',
      maximumFractionDigits: conversion?.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
    });
  }, [conversion?.maxFractionDigits, conversion?.unit]);

  const entries = entriesByEmitter[coloredEmitter.emitter.id];

  const formattedEntries: ChartEntry[] = useMemo(() => {
    if (!conversion) return entries;

    return entries.map((e) => {
      if (e.value !== null) {
        const formattedValue = exp(conversion.exp, { value: e.value }) as number;
        return { value: formattedValue, timestamp: e.timestamp };
      }
      return e;
    });
  }, [conversion, entries]);

  const topEntries = useMemo(() => {
    return [...formattedEntries]
      .filter((entry) => entry.value)
      .sort((a, b) => {
        if (!a.value) return -1;
        if (!b.value) return -1;
        if (a.value > b.value) {
          return -1;
        }
        if (a.value < b.value) {
          return 1;
        }
        return 0;
      })
      .slice(0, 3);
  }, [formattedEntries]);

  const isTop = useCallback(
    (entry: ChartEntry) => {
      return topEntries.includes(entry);
    },
    [topEntries]
  );

  const Header = useCallback(
    () =>
      topEntries.length ? (
        <View style={styles.headerRow}>
          <View style={styles.dateColumn}>
            <Text>Vessel</Text>
          </View>
          <View style={[styles.valueColumn, styles.title]}>
            <Text>{emissionName}</Text>
          </View>
          <View style={styles.barColumn}></View>{' '}
        </View>
      ) : (
        <Text>No data available</Text>
      ),
    [emissionName, topEntries.length]
  );

  return (
    <View>
      <Header />
      {topEntries.length ? (
        formattedEntries.map((entry, index) => {
          const topRow = isTop(entry) ? [styles.bold] : [];
          const topBar = isTop(entry) ? [styles.barUsedBold] : [];
          const max = topEntries?.at(0)?.value;
          return (
            <View style={[styles.row, ...topRow]} key={index}>
              <View style={styles.dateColumn}>
                <Text>{moment(entry.timestamp).format('MMM Do')}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text>{entry.value ? valueFormatter(entry.value) : 'N/A'}</Text>
              </View>
              <View style={styles.barColumn}>
                <View style={styles.bar}>
                  <View
                    style={[
                      styles.barUsed,
                      ...topBar,
                      { width: `${((entry.value ?? 0) / (max || 1)) * 100}%` },
                    ]}
                  ></View>
                </View>
              </View>
            </View>
          );
        })
      ) : (
        <></>
      )}
    </View>
  );
};
