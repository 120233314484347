import { EventListener, EventEmitterPayload } from './interfaces';

export class EventEmitter {
  private listener?: EventListener;

  /**
   * Add listener for events.
   *
   * @param listener - Listener to attach
   */
  addListener(listener: EventListener) {
    this.listener = listener;
  }

  /**
   * Remove listener
   */
  removeListener() {
    this.listener = undefined;
  }

  /**
   * Emit event to listener
   *
   * @param payload - Payload to emit
   */
  emit(payload: EventEmitterPayload) {
    this.listener?.(payload);
  }
}
