import { createContext } from 'react';
import { Observer } from './Observer';

export interface IContext {
  observer: Observer;
}

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <NodeServerProvider>.');
};

const initialContext = {
  observer: stub,
} as unknown as IContext;

export const Context = createContext(initialContext);
