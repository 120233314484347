import styles from './PropertySelector.module.scss';
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { ExpandableMenuItem, SelectableMenuItem } from '../../../../interfaces';
// import { getMenuItemByIndexPath } from 'Components/MenuPopup';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import { getSelectableMenuItemFullTitle } from '../../../../helpers';
import { useKeydown } from 'Hooks/utils';
import clsx from 'clsx';

type PropertySelectorProps = {
  indicatorColor: 'blue' | 'green';
  items: ExpandableMenuItem[];
  selectedItem?: SelectableMenuItem;
  onSelectMenuItem: (menuItem: SelectableMenuItem) => void;
};

export const PropertySelector: React.FC<PropertySelectorProps> = ({
  indicatorColor,
  items,
  selectedItem,
  onSelectMenuItem,
}) => {
  const propertySelectorRef = useRef<HTMLDivElement>(null);
  const propertyMenuPopupRef = useRef<HTMLDivElement>(null);
  const [isPropertyMenuPopupVisible, setIsPropertyMenuPopupVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!(e.target instanceof Element) || e.target.contains(propertySelectorRef.current)) return;

      const propertyMenuPopup = propertyMenuPopupRef.current;

      if (propertyMenuPopup && propertyMenuPopup.contains(e.target)) return;

      setIsPropertyMenuPopupVisible(false);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useKeydown('Escape', () => {
    setIsPropertyMenuPopupVisible(false);
  });

  const handleClickPropertySelector: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    if (e.target !== e.currentTarget) return;

    setIsPropertyMenuPopupVisible((isVisible) => !isVisible);
  }, []);

  // const handleSelectMenuItemInPropertyMenuPopup = useCallback(
  //   (indexPath: number[]) => {
  //     const selectedMenuItem = getMenuItemByIndexPath(indexPath, items) as SelectableMenuItem;

  //     onSelectMenuItem(selectedMenuItem);

  //     setIsPropertyMenuPopupVisible(false);
  //   },
  //   [items, onSelectMenuItem]
  // );

  return (
    <div className={styles.propertySelectorContainer}>
      <div
        className={clsx(styles.propertySelector, { [styles.focused]: isPropertyMenuPopupVisible })}
        onClick={handleClickPropertySelector}
        ref={propertySelectorRef}
      >
        <div className={styles.content}>
          <div className={styles.title}>
            <span
              className={clsx(styles.colorIndicator, {
                [styles.blue]: indicatorColor === 'blue',
                [styles.green]: indicatorColor === 'green',
              })}
            />
            {selectedItem ? (
              getSelectableMenuItemFullTitle(selectedItem)
            ) : (
              <span className={styles.placeholder}>No selection</span>
            )}
          </div>
          <IconArrowDown
            style={{ transform: isPropertyMenuPopupVisible ? 'rotate(180deg)' : undefined }}
          />
        </div>
      </div>
      {/* {isPropertyMenuPopupVisible && (
        <div className={styles.menuPopup} ref={propertyMenuPopupRef}>
          <MenuPopup items={items} searchable onSelect={handleSelectMenuItemInPropertyMenuPopup} />
        </div>
      )} */}
    </div>
  );
};
