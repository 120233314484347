import { sensorStreamApi } from 'Services/redux/sensorStream/sensorStream.api';
import { Connection } from 'Models';
import { Connection as ConnectionRecord } from './interfaces';
import { transformIntoConnection } from './transformers';

export const activityApi = sensorStreamApi.injectEndpoints({
  endpoints: (builder) => ({
    getConnections: builder.query<Connection[], void>({
      query: () => ({
        url: 'activity/connections',
      }),
      transformResponse: (response: ConnectionRecord[]) => response.map(transformIntoConnection),
    }),
  }),
});

export const { useGetConnectionsQuery } = activityApi;
