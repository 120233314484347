import styles from './AlertsBarContent.module.scss';
import { useEffect } from 'react';
import { Alert } from 'Models';
import { useCallback } from 'react';
import { useState } from 'react';
import { useDismissAlertMutation, useLazyGetAlertsQuery } from 'Services/redux/alerts/alerts';
import { Hashable, OrderedSet } from 'Stdlibs/OrderedSet/OrderedSet';
import PuffLoader from 'react-spinners/PuffLoader';
import { AlertsDataRow } from './components/AlertsDataRow/AlertsDataRow';
import {
  useObserveNewAlert,
  useObserveAlertResolved,
  useObserveAlertUnread,
} from 'Networking/socket/alerts';

const PAGE_SIZE = 20;

type AlertsBarContentUnreadProps = {
  onDismissAlert: (alert: Alert) => void;
};

type HashableAlert = Alert & Hashable;

export const AlertsBarContentUnread: React.FC<AlertsBarContentUnreadProps> = ({
  onDismissAlert,
}) => {
  const [alerts, setAlerts] = useState(new OrderedSet<HashableAlert>());
  const [latest, setLatest] = useState<string>();

  const newAlert = useObserveNewAlert();
  const resolvedAlert = useObserveAlertResolved();
  const unreadAlert = useObserveAlertUnread();

  useEffect(() => {
    if (!unreadAlert) {
      return;
    }
    setAlerts((previousAlerts) =>
      previousAlerts.concat(
        new OrderedSet({
          ...unreadAlert,
          hash: unreadAlert.id,
        })
      )
    );
  }, [unreadAlert]);

  useEffect(() => {
    if (!newAlert) {
      return;
    }
    setAlerts(
      (previousAlerts) =>
        new OrderedSet(
          {
            ...newAlert,
            hash: newAlert.id,
          },
          ...previousAlerts
        )
    );
  }, [newAlert]);

  useEffect(() => {
    if (!resolvedAlert) {
      return;
    }
    setAlerts(
      (previousAlerts) =>
        new OrderedSet(
          {
            ...resolvedAlert,
            hash: resolvedAlert.id,
          },
          ...previousAlerts
        )
    );
  }, [resolvedAlert]);

  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

  const [getAlertsQuery] = useLazyGetAlertsQuery();
  const [dismissAlertMutation] = useDismissAlertMutation();

  const [scrollableElement, setScrollableElement] = useState<HTMLDivElement>();
  const [lastItem, setLastItem] = useState<HTMLDivElement>();

  const bodyRef = (element: HTMLDivElement) => {
    setScrollableElement(element);
  };

  const lastRowRef = (element: HTMLDivElement) => {
    setLastItem(element);
  };

  const fetchNextPage = useCallback(() => {
    if (isLoadingNextPage) return;

    setIsLoadingNextPage(true);

    (async (): Promise<void> => {
      const { data: response } = await getAlertsQuery({
        pageSize: PAGE_SIZE,
        type: 'unread',
        latest,
      });

      if (!response) {
        setIsLoadingNextPage(false);
        return;
      }

      setAlerts((previousAlerts) =>
        previousAlerts.concat(new OrderedSet(...response.alerts.map((a) => ({ ...a, hash: a.id }))))
      );

      setLatest(response.next);

      setIsLoadingNextPage(false);
    })();
  }, [getAlertsQuery, latest, isLoadingNextPage]);

  const handleDismissAlertsDataRow = useCallback(
    (alert: Alert) => {
      dismissAlertMutation({ alert });

      setAlerts((previousAlerts) => previousAlerts.filter((a) => a.id !== alert.id));

      onDismissAlert(alert);
    },
    [dismissAlertMutation, onDismissAlert]
  );

  useEffect(() => {
    fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const operatingLastItem = lastItem;

    if (!scrollableElement || !operatingLastItem) return;

    const callback: IntersectionObserverCallback = (entries) => {
      const observedEntry = entries.find((e) => e.target === operatingLastItem);

      if (!observedEntry || !observedEntry.isIntersecting) return;

      fetchNextPage();
    };

    const intersectionObserver = new IntersectionObserver(callback, {
      root: scrollableElement,
    });

    intersectionObserver.observe(operatingLastItem);

    return () => {
      intersectionObserver.unobserve(operatingLastItem);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableElement, lastItem]);

  return (
    <div className={styles.alertsBarContent}>
      <div className={styles.head}>
        <div className={styles.status} />
        <div className={styles.summary}>
          <span>Summary</span>
        </div>
        <div className={styles.source}>
          <span>Source</span>
        </div>
        <div className={styles.date}>
          <span>Date</span>
        </div>
      </div>

      <div className={styles.body} ref={bodyRef}>
        {alerts.map((alert, index) => (
          <AlertsDataRow
            key={alert.id}
            alert={alert}
            showsControl={true}
            onDismiss={handleDismissAlertsDataRow}
            ref={index === alerts.length - 1 ? lastRowRef : undefined}
          />
        ))}
        {isLoadingNextPage && (
          <div className={styles.loadingNextPageIndicatorRow}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        )}
      </div>
    </div>
  );
};
