import { Dock, EmitterType } from 'Models';
import { SensorGroup, SensorGroupMetadataDock } from '../interfaces';
import { transformIntoSensors } from './sensor.transformer';
import { expandLayout } from './layout.transformer';
import { SensorName } from 'Constants';
import { getSensorsWithName } from 'Helpers/sensors';

export const transformIntoDock = (record: SensorGroup<SensorGroupMetadataDock>): Dock => {
  const sensors = record.sensorSchema ? transformIntoSensors(record.sensorSchema, record._id) : [];

  const dock = {
    id: record._id,
    type: record.groupType as EmitterType,
    coordinate: record.position && {
      latitude: record.position.coordinates[1],
      longitude: record.position.coordinates[0],
    },
    name: record.name,
    namespace: record.ns,
    layout: expandLayout(record.layout, sensors),
    sensors,
    inProgress: !!record.inProgress,
    isManager: !!record.isManager,
    isReadAll: !!record.isReadAll,
  } as Dock;

  const hasGpsSensor = getSensorsWithName(SensorName.GPS, dock).length > 0;
  const hasAqiSensor = getSensorsWithName(SensorName.AirQuality, dock).length > 0;

  dock.usesUnifiedSensor = !(hasGpsSensor || hasAqiSensor);

  return dock;
};
