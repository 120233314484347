import styles from './AlertsBarContent.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { Alert } from 'Models';
import { useLazyGetAlertsQuery } from 'Services/redux/alerts/alerts';
import { Hashable, OrderedSet } from 'Stdlibs/OrderedSet/OrderedSet';
import PuffLoader from 'react-spinners/PuffLoader';
import { AlertsDataRow } from './components/AlertsDataRow/AlertsDataRow';
import { useObserveAlertRead } from 'Networking/socket/alerts';

const PAGE_SIZE = 20;

type HashableAlert = Alert & Hashable;

export const AlertsBarContentRead: React.FC = () => {
  const [alerts, setAlerts] = useState(new OrderedSet<HashableAlert>());
  const [latest, setLatest] = useState<string>();
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

  const [getAlertsQuery] = useLazyGetAlertsQuery();

  const [scrollableElement, setScrollableElement] = useState<HTMLDivElement>();
  const [lastItem, setLastItem] = useState<HTMLDivElement>();

  const readAlert = useObserveAlertRead();

  useEffect(() => {
    if (!readAlert) {
      return;
    }
    setAlerts(
      (previousAlerts) =>
        new OrderedSet(
          {
            ...readAlert,
            hash: readAlert.id,
          },
          ...previousAlerts
        )
    );
  }, [readAlert]);

  const bodyRef = (element: HTMLDivElement) => {
    setScrollableElement(element);
  };

  const lastRowRef = (element: HTMLDivElement) => {
    setLastItem(element);
  };

  const fetchNextPage = useCallback(() => {
    if (isLoadingNextPage) return;

    setIsLoadingNextPage(true);

    (async (): Promise<void> => {
      const { data: response } = await getAlertsQuery({
        pageSize: PAGE_SIZE,
        type: 'read',
        latest,
      });

      if (!response) {
        setIsLoadingNextPage(false);
        return;
      }

      setAlerts((previousAlerts) =>
        previousAlerts.concat(new OrderedSet(...response.alerts.map((a) => ({ ...a, hash: a.id }))))
      );

      setLatest(response.next);

      setIsLoadingNextPage(false);
    })();
  }, [getAlertsQuery, latest, isLoadingNextPage]);

  useEffect(() => {
    fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const operatingLastItem = lastItem;

    if (!scrollableElement || !operatingLastItem) return;

    const callback: IntersectionObserverCallback = (entries) => {
      const observedEntry = entries.find((e) => e.target === operatingLastItem);

      if (!observedEntry || !observedEntry.isIntersecting) return;

      fetchNextPage();
    };

    const intersectionObserver = new IntersectionObserver(callback, {
      root: scrollableElement,
    });

    intersectionObserver.observe(operatingLastItem);

    return () => {
      intersectionObserver.unobserve(operatingLastItem);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableElement, lastItem]);

  return (
    <div className={styles.alertsBarContent}>
      <div className={styles.head}>
        <div className={styles.status} />
        <div className={styles.summary}>
          <span>Summary</span>
        </div>
        <div className={styles.source}>
          <span>Source</span>
        </div>
        <div className={styles.date}>
          <span>Date</span>
        </div>
      </div>

      <div className={styles.body} ref={bodyRef}>
        {alerts.map((alert, index) => (
          <AlertsDataRow
            key={alert.id}
            alert={alert}
            showsControl={false}
            ref={index === alerts.length - 1 ? lastRowRef : undefined}
          />
        ))}
        {isLoadingNextPage && (
          <div className={styles.loadingNextPageIndicatorRow}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        )}
      </div>
    </div>
  );
};
