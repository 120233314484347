import { Emitter, Sensor } from 'Models';
import { SensorName } from 'Constants';
import { SensorUpdateMessagePayload } from '../interfaces';
import { BaseMessageTest } from '../BaseMessageTest';
import { getFirstSensorWithName } from 'Helpers/sensors';
import { NodeServerObserver } from '..';

type WeatherSensor = Sensor<typeof SensorName.Weather.name>;

export class WeatherMessageTest extends BaseMessageTest {
  constructor(protected observer: NodeServerObserver, private emitters: Emitter[]) {
    super(SensorName.Weather.name, observer, 3000);
  }

  protected getMessagePayloads(cycleCount: number): SensorUpdateMessagePayload[] {
    return this.emitters.reduce<SensorUpdateMessagePayload[]>((acc, emitter) => {
      const weatherSensor = getFirstSensorWithName(SensorName.Weather, emitter);

      return weatherSensor
        ? acc.concat(this.generatePayloadForEmitter(weatherSensor, emitter, cycleCount))
        : acc;
    }, []);
  }

  private generatePayloadForEmitter(
    sensor: WeatherSensor,
    emitter: Emitter,
    cycleCount: number
  ): SensorUpdateMessagePayload {
    return {
      metadata: { sensorId: sensor.id, ns: emitter.namespace, isSatcomm: false },
      latitude: emitter.coordinate && emitter.coordinate.latitude + cycleCount * 0.0007,
      longitude: emitter.coordinate && emitter.coordinate.longitude + cycleCount * 0.0007,
      trueWindSpeed: Math.random() * 20,
      trueWindDirection: Math.random() * 359.9,
      airTemperature: Math.random() * 50,
      atmosphericPressure: Math.random() * 1500,
      relativeHumidity: Math.random() * 60,
      rainVolume: Math.random() * 10,
      uv: Math.random() * 10,
      systemTime: new Date().toISOString(),
    };
  }
}
