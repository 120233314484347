import Moment from 'react-moment';
import { Ais } from 'Models';
import { Flag, Iso3166Part1Alpha2Identifier } from 'Components/Flag';
import { MapPopup, styles } from 'Components/MapPopup';
import { DMSCoordinate } from 'Components/DMSCoordinate';

type AisPopupProps = {
  ais: Ais;
};

export const AisPopup: React.FC<AisPopupProps> = ({ ais }) => {
  if (!ais.coordinate) {
    return null;
  }

  const normalizedName = (ais.name || 'AIS Vessel').replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );

  return (
    <MapPopup
      longitude={ais.coordinate.longitude}
      latitude={ais.coordinate.latitude}
      closeButton={false}
    >
      <div className={styles.section}>
        <p>
          {ais.flag && (
            <span className={styles.flag}>
              <Flag identifier={ais.flag as Iso3166Part1Alpha2Identifier} />
            </span>
          )}
          <span className={styles.title}>{normalizedName}</span>
        </p>
      </div>
      <hr />
      <div className={styles.section}>
        {ais.coordinate && <DMSCoordinate coordinate={ais.coordinate} />}
        {ais.callsign && (
          <p>
            Callsign:&nbsp;<strong>{ais.callsign}</strong>
          </p>
        )}
        {typeof ais.mmsi === 'number' && (
          <p>
            MMSI:&nbsp;<strong>{ais.mmsi}</strong>
          </p>
        )}
        {ais.imo && (
          <p>
            IMO:&nbsp;<strong>{ais.imo}</strong>
          </p>
        )}
        {ais.sog !== undefined && (
          <p>
            SOG:&nbsp;<strong>{ais.sog}kts</strong>
          </p>
        )}
        {ais.cog !== undefined && (
          <p>
            COG:&nbsp;<strong>{ais.cog}&#176;</strong>
          </p>
        )}
        {ais.trueHeading && (
          <p>
            Heading:&nbsp;<strong>{ais.trueHeading}&#176;</strong>
          </p>
        )}
        {ais.destination && (
          <p>
            Dest:&nbsp;<strong>{ais.destination}</strong>
          </p>
        )}
        {ais.eta && (
          <p>
            ETA:&nbsp;
            <strong>
              <Moment format="M/d/y, HH:mm">{ais.eta}</Moment>
            </strong>
          </p>
        )}
        {ais.maxDraught !== undefined && (
          <p>
            Max Draft:&nbsp;<strong>{ais.maxDraught}m</strong>
          </p>
        )}
        {ais.systemTime && (
          <p>
            Updated:&nbsp;
            <strong>
              <Moment format="M/D/y, HH:mm">{ais.systemTime}</Moment>
            </strong>
          </p>
        )}
      </div>
    </MapPopup>
  );
};
