import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TimeInputValue } from './types';
import { decodeTimeText, generateTimeText, getTimeTextFromValue } from './helpers';

type TimeInputProps = {
  value?: TimeInputValue;
  onChange: (value?: TimeInputValue) => void;
  className?: string;
};

export const TimeInput: React.FC<TimeInputProps> = ({ value, onChange, className }) => {
  const [text, setText] = useState(getTimeTextFromValue(value));

  useEffect(() => {
    if (value) {
      setText(getTimeTextFromValue(value));
    }
  }, [value]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // Do not use setText((text) => {}) to avoid
      // event.currentTarget.value is caled in different
      // event loop cycle.
      const newText = generateTimeText(event.currentTarget.value, text);

      setText(newText);

      const value = decodeTimeText(newText);

      onChange(value);
    },
    [onChange, text]
  );

  return <input value={text} className={className} placeholder="HH : MM" onChange={handleChange} />;
};
