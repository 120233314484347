import { Source, Layer, LayerProps } from 'react-map-gl';
import { FeatureCollection, Polygon } from 'geojson';
import eca from './resources/json/eca.json';
import { MapLayerProps } from '../../../interfaces';

const BASE_LAYER_PROPS: LayerProps = {
  type: 'line',
  paint: {
    'line-color': '#00FF00',
    'line-width': 2,
  },
};

export const EcaLayer: React.FC<MapLayerProps> = ({ id, beforeId }) => {
  const layerProps = { id, ...BASE_LAYER_PROPS };

  if (beforeId) {
    layerProps.beforeId = beforeId;
  }

  return (
    <Source id={id} type="geojson" data={eca as FeatureCollection<Polygon>}>
      <Layer {...layerProps} />
    </Source>
  );
};
