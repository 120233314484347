import styles from '../SideBar.module.scss';
import { useMemo } from 'react';
import Clock from 'react-live-clock';
import moment from 'moment-timezone';
import { useControlBarManager, useTimelineThumb, useViewMode } from '../../ControlBar/hooks';
import { calculatePositionDate } from 'Helpers/timeline';

const ianaTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
const timezoneAbbreviation = moment().tz(ianaTimezone).format('z');

export const SideBarClock: React.FC = () => {
  const controlBarManager = useControlBarManager();
  const viewMode = useViewMode();
  const { thumbPosition } = useTimelineThumb();

  const thumbPositionDate = useMemo(
    () => calculatePositionDate(thumbPosition, controlBarManager.getTimelineBarInterval()),
    [controlBarManager, thumbPosition]
  );

  const formattedThumbPositionTime = useMemo(
    () => moment(thumbPositionDate).format(`HH:mm:ss [${timezoneAbbreviation}]`),
    [thumbPositionDate]
  );

  const formattedThumbPositionDate = useMemo(
    () => moment(thumbPositionDate).format('MMMM D, yyyy'),
    [thumbPositionDate]
  );

  return (
    <div className={styles.sideBarClock}>
      <div className={styles.sideBarContent}>
        <p className={styles.sideBarClockTime}>
          {viewMode === 'replay' ? (
            formattedThumbPositionTime
          ) : (
            <Clock format={`HH:mm:ss [${timezoneAbbreviation}]`} ticking={true} />
          )}
        </p>
        <p className={styles.sideBarClockDate}>
          {viewMode === 'replay' ? (
            formattedThumbPositionDate
          ) : (
            <Clock format={'MMMM D, yyyy'} ticking={true} />
          )}
        </p>
      </div>
    </div>
  );
};
