import styles from './StabilitySection.module.scss';
import { Property } from 'Models';
import { PitchColumn, RollColumn } from './components';
import { EmitterDetailsBarSection } from '../../EmitterDetailsBarSection';
import { EmitterDetailsBarSectionHeaderRegular } from '../../EmitterDetailsBarSectionHeaderRegular/EmitterDetailsBarSectionHeaderRegular';

export type StabilitySectionProps = {
  title: string;
  roll: Property;
  pitch: Property;
};

export const StabilitySection: React.FC<StabilitySectionProps> = ({ title, roll, pitch }) => {
  return (
    <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title={title} />}>
      <div className={styles.stabilitySectionBody}>
        <RollColumn property={roll} />
        <PitchColumn property={pitch} />
      </div>
    </EmitterDetailsBarSection>
  );
};
