import { Coordinate } from './coordinate';

export interface Alert {
  id: string;
  sensorGroupName: string;
  message: string;
  isRead: boolean;
  explanations: AlertExplanation[];
  level: AlertLevel;
  ruleId: string;
  lastCoordinate?: Coordinate;
  updatedAt: string;
  createdAt: string;
  resolvedAt?: string;
}

export enum AlertLevel {
  Unknown = -1,
  Low = 1,
  Medium,
  High,
}

interface AlertExplanation {
  message: string;
  source: string;
  occuredAt?: string;
  occursAt?: string;
  forcastedAt?: string;
}
