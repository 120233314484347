import { ChartEntry } from 'Components/BiaxialChart';
import { PropertyFrame } from 'Hooks/queries';

export const getChartEntry = (propertyFrame: PropertyFrame): ChartEntry => {
  return {
    value: propertyFrame.value as number,
    lowRes: (propertyFrame.isSatcomm as boolean) ?? false,
    timestamp: propertyFrame.date,
  };
};

export const getChartEntries = (propertyFrames: PropertyFrame[]): ChartEntry[] => {
  return propertyFrames.map(getChartEntry);
};
