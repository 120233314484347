import styles from './DateTimePicker.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { DatePicker, DatePickerProps } from 'Components/DatePicker';
import { TimePicker, TimePickerProps } from 'Components/TimePicker';

export type DateTimePickerProps = {
  initialDate: Date;
  upperLimit?: Date;
  onChange: (selectedDate: Date) => void;
};

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  initialDate,
  upperLimit,
  onChange,
}) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);

  useEffect(() => {
    if (selectedDate.getTime() === initialDate.getTime()) {
      return;
    }

    onChange(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const handleDatePickerChange: DatePickerProps['onChange'] = useCallback((selectedDate) => {
    setSelectedDate((date) => {
      const modifiedDate = new Date(date.getTime());

      modifiedDate.setFullYear(selectedDate.getFullYear());

      // Be careful that we have to set month first then date.
      // Otherwise if we set date 31 to the month that only
      // has 30 day, the date will be set to 1 instead because
      // it performs autocorrect to the next month.
      modifiedDate.setMonth(selectedDate.getMonth());
      modifiedDate.setDate(selectedDate.getDate());

      return modifiedDate;
    });
  }, []);

  const handleTimePickerChange: TimePickerProps['onChange'] = useCallback((selectedTime) => {
    setSelectedDate((selectedDate) => {
      const modifiedDate = new Date(selectedDate.getTime());
      modifiedDate.setHours(selectedTime.getHours());
      modifiedDate.setMinutes(selectedTime.getMinutes());
      modifiedDate.setSeconds(selectedTime.getSeconds());

      return modifiedDate;
    });
  }, []);

  return (
    <div className={styles.dateTimePicker}>
      <div className={styles.dateColumn}>
        <DatePicker
          initialDate={initialDate}
          onChange={handleDatePickerChange}
          upperLimit={upperLimit}
        />
      </div>
      <div className={styles.timeColumn}>
        <TimePicker initialTime={initialDate} onChange={handleTimePickerChange} />
      </div>
    </div>
  );
};
