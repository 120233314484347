export function getAnchoredDate(date: Date, intervalMs: number) {
  const ANCHOR_DATE = new Date('2000-01-01T00:00:00.000Z');
  const diff = date.getTime() - ANCHOR_DATE.getTime();

  const normalizedInterval = Math.floor(intervalMs / 1000) * 1000;

  return new Date(
    ANCHOR_DATE.getTime() + normalizedInterval * Math.floor(diff / normalizedInterval)
  );
}
