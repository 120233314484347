import styles from './UserInfoPopup.module.scss';
import { User } from 'Models';
import { capitalize } from 'Helpers/capitalize';
import { forwardRef, useMemo } from 'react';
import { Namespace } from 'Services/redux/api/users';

type UserInfoPopupProps = {
  user: User;
  namespaceAccess: Namespace[];
};

export const UserInfoPopup = forwardRef<HTMLDivElement, UserInfoPopupProps>(
  ({ user, namespaceAccess }, ref) => {
    const rolesElements = useMemo(
      () =>
        user.roles.map((role) => {
          const displayName = namespaceAccess.find(
            (namespace) => namespace.code === role.namespace
          )?.displayName;

          if (!displayName) return null; // type guard

          return (
            <span className={styles.role}>
              {capitalize(displayName)} {capitalize(role.name)}
            </span>
          );
        }),
      [namespaceAccess, user.roles]
    );

    return (
      <div className={styles.userInfoPopup} ref={ref}>
        <p className={styles.name}>
          {user.firstName} {user.lastName}
        </p>
        <p className={styles.email}>{user.email}</p>
        {!!rolesElements.length && <div className={styles.roles}>{rolesElements}</div>}
        <p className={styles.altId}>{user.altId}</p>
      </div>
    );
  }
);
