import { SensorName } from 'Constants';
import { NodeServerObserver, SensorUpdateMessagePayload } from '.';

type EventName = typeof SensorName[keyof typeof SensorName]['name'];

export abstract class BaseMessageTest {
  private cycleCount = 0;
  private timer?: NodeJS.Timer;

  constructor(
    protected eventName: EventName,
    protected observer: NodeServerObserver,
    protected interval: number
  ) { }

  run() {
    if (this.timer) return;

    this.timer = setInterval(() => {
      const messagePayloads = this.getMessagePayloads(++this.cycleCount);
      messagePayloads.forEach(
        this.observer.simulateIncomingMessage.bind(this.observer, this.eventName)
      );
    }, this.interval);
  }

  stop() {
    if (!this.timer) return;

    clearInterval(this.timer);
    this.timer = undefined;
  }

  // Overridables
  protected getMessagePayloads(cycleCount: number): SensorUpdateMessagePayload[] {
    throw new Error('You need to override getMessagePayloads');
  }
}
