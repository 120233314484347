import styles from './Modal.module.scss';
import { ReactNode, useCallback } from 'react';
import { ReactComponent as IconCross } from './resources/graphics/icon-cross.svg';
import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';
import clsx from 'clsx';

type ModalProps = {
  title: string;
  subtitle: string;
  body: ReactNode;
  footer?: ReactNode;
  width?: number;
  height?: number;
  onClose: () => void;
  disableCloseButton?: boolean;
  disableBody?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  title,
  subtitle,
  body,
  footer,
  width,
  height,
  onClose,
  disableCloseButton = false,
  disableBody = false,
}) => {
  const handleClickCloseButton = useCallback(() => {
    if (!disableCloseButton) onClose();
  }, [disableCloseButton, onClose]);

  return createPortal(
    <div className={styles.modalWrapper}>
      <motion.div
        animate={{ height }}
        className={styles.modal}
        style={{
          width: typeof width === 'number' ? `${width}px` : undefined,
          height: typeof height === 'number' ? `${height}px` : undefined,
        }}
      >
        <div className={styles.header}>
          <div className={styles.headerText}>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
          <button
            className={styles.closeButton}
            onClick={handleClickCloseButton}
            disabled={disableCloseButton}
          >
            <IconCross />
          </button>
        </div>
        <div className={clsx(styles.body, disableBody && styles.disableScroll)}>
          <div className={clsx(styles.overlayWrapper, disableBody && styles.disableBody)}>
            {body}
          </div>
        </div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </motion.div>
    </div>,
    document.getElementById('portal') as HTMLElement
  );
};
