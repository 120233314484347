import styles from './EmitterDetailsBarHeader.module.scss';
import { FC, useCallback, useMemo } from 'react';
import { ReactComponent as IconArrowClose } from './resources/graphics/icon-arrow-close.svg';
import { ReactComponent as IconDots } from './resources/graphics/icon-dots.svg';
// import { SectionNavigator } from 'Components/SectionNavigator';
import { DMSCoordinate } from 'Components/DMSCoordinate';
import { usePopup } from 'Hooks/popup/usePopup';
import { MenuPickerPopup } from 'Components/MenuPickerPopup';
import { Connection, Coordinate } from 'Models';
import clsx from 'clsx';

type MenuItemIdentifier = 'edit' | 'share' | 'export';
type MenuItem = { label: string; id: MenuItemIdentifier };

function getMenuItems(isManager: boolean): MenuItem[] {
  return [
    ...(isManager
      ? [
          { label: 'Edit', id: 'edit' } as MenuItem,
          { label: 'Manage Access', id: 'share' } as MenuItem,
        ]
      : []),
    { label: 'Export', id: 'export' },
  ];
}

type EmitterDetailsBarHeaderProps = {
  title: string;
  isManager?: boolean;
  connectionType?: Connection['type'];
  sections: string[];
  onClickCollapseButton: () => void;
  coordinate?: Coordinate;
  onClickEdit: () => void;
  onClickShare: () => void;
  onClickExport: () => void;
};

export const EmitterDetailsBarHeader: FC<EmitterDetailsBarHeaderProps> = ({
  title,
  connectionType,
  sections,
  isManager,
  onClickCollapseButton,
  coordinate,
  onClickEdit,
  onClickShare,
  onClickExport,
}) => {
  const menuItems = useMemo(() => getMenuItems(isManager ?? false), [isManager]);
  const items = menuItems.map((i) => i.label);

  const [isPopupVisible, setPopupToVisible, setPopupToNotVisible, popupRef, toggleRef] = usePopup();

  const handleClickIconDots = useCallback(() => {
    isPopupVisible ? setPopupToNotVisible() : setPopupToVisible();
  }, [isPopupVisible, setPopupToVisible, setPopupToNotVisible]);

  const handleSelectMenuItem = useCallback(
    (selectedIndex: number) => {
      const selectedMenuItem = menuItems[selectedIndex];

      if (selectedMenuItem.id === 'edit') {
        onClickEdit();
      } else if (selectedMenuItem.id === 'share') {
        onClickShare();
      } else if (selectedMenuItem.id === 'export') {
        onClickExport();
      }
    },
    [menuItems, onClickEdit, onClickExport, onClickShare]
  );

  return (
    <div className={styles.detailsBarHeader}>
      <div className={styles.title}>
        <h3>{title}</h3>
        {connectionType && connectionType === 'satcomm' && (
          <span className={styles.connectionLabel}>
            <span className={clsx(styles.indicator, styles.lowData)} />
            Low data mode
          </span>
        )}
        {connectionType && connectionType === 'offline' && (
          <span className={styles.connectionLabel}>
            <span className={clsx(styles.indicator, styles.offline)} />
            Offline
          </span>
        )}
      </div>
      {coordinate && <DMSCoordinate coordinate={coordinate} className={styles.coordinate} />}

      <div className={styles.options}>
        <button ref={toggleRef} onClick={handleClickIconDots}>
          <IconDots />
        </button>
        {isPopupVisible && (
          <div ref={popupRef}>
            <MenuPickerPopup
              ref={popupRef}
              items={items}
              onSelect={handleSelectMenuItem}
              onClickItem={setPopupToNotVisible}
            />
          </div>
        )}

        <button onClick={onClickCollapseButton}>
          <IconArrowClose />
        </button>
      </div>
      <div className={styles.sectionNavigatorContainer}>
        {/* <SectionNavigator sections={sections} onSelectSection={(selectedIndex) => {}} /> */}
      </div>
    </div>
  );
};
