import styles from './ChartSection.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EmitterDetailsBarSection } from '../../EmitterDetailsBarSection';
import { ChartSectionHeader } from './components/ChartSectionHeader/ChartSectionHeader';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { ExpandableMenuItem, SelectableMenuItem } from './interfaces';
import { FormattingConfig, SingleFormatter } from 'Models';
import { getSelectableMenuItemFullTitle } from './helpers';
import { ChartSectionModal } from './components';
import { MAX_NUMBER_OF_FRAMES } from './constants';
import { ChartSeries, ResponsiveBiaxialChart } from 'Components/BiaxialChart';
import { useChartEntries } from './hooks/useChartEntries';
import PuffLoader from 'react-spinners/PuffLoader';
import { exp } from 'array-expression';
import { getConversionConfig } from 'Helpers/conversion';
import { Conversion } from 'Interfaces';
import { DEFAULT_MAX_FRACTION_DIGITS, DEFAULT_MIN_FRACTION_DIGITS } from 'Constants';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import { calculatePositionDate } from 'Helpers/timeline';

const REFETCH_ENTRIES_INTERVAL = 600000;

type ChartSectionProps = {
  menuItems: ExpandableMenuItem[];
  selected1?: SelectableMenuItem;
  selected2?: SelectableMenuItem;
  formatting: FormattingConfig;
};

export const ChartSection: React.FC<ChartSectionProps> = ({
  menuItems,
  selected1,
  selected2,
  formatting,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [firstSelectedMenuItem, setFirstSelectedMenuItem] = useState<
    SelectableMenuItem | undefined
  >(selected1);
  const [secondSelectedMenuItem, setSecondSelectedMenuItem] = useState<
    SelectableMenuItem | undefined
  >(selected2);

  const refetchEntriesTimerRef = useRef<NodeJS.Timer>();

  const {
    absoluteInterval: { earliest, latest },
    interval,
    refresh,
  } = useSelectedInterval();

  useEffect(refresh, [firstSelectedMenuItem, secondSelectedMenuItem, refresh]);

  const needsRealtimeData = useMemo(() => {
    const TWO_DAYS = 2 * 24 * 60 * 60 * 1000;
    return new Date(latest).getTime() - new Date(earliest).getTime() <= TWO_DAYS;
  }, [earliest, latest]);

  useEffect(() => {
    if (!needsRealtimeData) {
      refetchEntriesTimerRef.current = setInterval(() => {
        if (!needsRealtimeData) refresh();
      }, REFETCH_ENTRIES_INTERVAL);
    }

    return () => {
      if (refetchEntriesTimerRef.current) clearInterval(refetchEntriesTimerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsRealtimeData]);

  const menuItemsWithUnits = useMemo(() => {
    const withUnits: ExpandableMenuItem[] = [];
    for (const item of menuItems) {
      const itemCopy: ExpandableMenuItem = { ...item, subitems: [] };
      for (const subitem of item.subitems) {
        itemCopy.subitems.push({
          ...subitem,
          title: `${subitem.title}`,
        });
      }
      withUnits.push(itemCopy);
    }
    return withUnits;
  }, [menuItems]);

  const selectableMenuItems = useMemo(
    () => menuItems.reduce<SelectableMenuItem[]>((acc, curr) => acc.concat(curr.subitems), []),
    [menuItems]
  );

  useEffect(() => {
    setFirstSelectedMenuItem(selected1);
    setSecondSelectedMenuItem(selected2);
  }, [selected1, selected2, selectableMenuItems]);

  const firstConversions: Conversion[] | undefined = useMemo(() => {
    if (!firstSelectedMenuItem) {
      return undefined;
    }

    if (firstSelectedMenuItem.property.unit) {
      const conversionConfig = getConversionConfig(firstSelectedMenuItem.property.unit);
      return conversionConfig?.conversions;
    }

    return undefined;
  }, [firstSelectedMenuItem]);

  const secondConversions: Conversion[] | undefined = useMemo(() => {
    if (!secondSelectedMenuItem) {
      return undefined;
    }

    if (secondSelectedMenuItem.property.unit) {
      const conversionConfig = getConversionConfig(secondSelectedMenuItem.property.unit);
      return conversionConfig?.conversions;
    }

    return undefined;
  }, [secondSelectedMenuItem]);

  const [firstConversionSelectedIndex, setFirstConversionSelectedIndex] = useState(0);
  const [secondConversionSelectedIndex, setSecondConversionSelectedIndex] = useState(0);

  const firstSelectedConversion: Conversion | undefined = useMemo(() => {
    return firstConversions?.[firstConversionSelectedIndex];
  }, [firstConversionSelectedIndex, firstConversions]);

  const secondSelectedConversion: Conversion | undefined = useMemo(() => {
    return secondConversions?.[secondConversionSelectedIndex];
  }, [secondConversionSelectedIndex, secondConversions]);

  const { entries: firstEntries, isFetching: isFetchingFirstEntries } = useChartEntries({
    property: firstSelectedMenuItem?.property,
    maxNumberOfFrames: MAX_NUMBER_OF_FRAMES,
    interval,
    observeNewFrames: needsRealtimeData,
  });

  const { entries: secondEntries, isFetching: isFetchingSecondEntries } = useChartEntries({
    property: secondSelectedMenuItem?.property,
    maxNumberOfFrames: MAX_NUMBER_OF_FRAMES,
    interval,
    observeNewFrames: needsRealtimeData,
  });

  const firstChartSeries: ChartSeries | undefined = useMemo(() => {
    if (!firstSelectedMenuItem) return undefined;

    const format = getFormatting(firstSelectedMenuItem, formatting);

    const ret = {
      id: firstSelectedMenuItem.id,
      name: getSelectableMenuItemFullTitle(firstSelectedMenuItem),
      unit: firstSelectedConversion?.unit ?? firstSelectedMenuItem.property.unit,
      entries: firstEntries.slice(),
      maxFractionDigits: firstSelectedConversion?.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
      minFractionDigits: firstSelectedConversion?.maxFractionDigits ?? DEFAULT_MIN_FRACTION_DIGITS,
      defaultLowerDomainY: format?.defaultLowerDomainY,
      defaultUpperDomainY: format?.defaultUpperDomainY,
      color: '#0093dd',
    };

    if (firstSelectedConversion && format) {
      const nonGlobalConversion = format.units?.find(
        (u) => u.unit === firstSelectedConversion.unit
      );

      if (nonGlobalConversion) {
        if (typeof nonGlobalConversion.defaultLowerDomainY === 'number') {
          ret.defaultLowerDomainY = nonGlobalConversion.defaultLowerDomainY;
        }

        if (typeof nonGlobalConversion.defaultUpperDomainY === 'number') {
          ret.defaultUpperDomainY = nonGlobalConversion.defaultUpperDomainY;
        }
      }
    }

    if (firstSelectedConversion) {
      ret.entries = ret.entries.map((e) => ({
        ...e,
        value:
          typeof e.value === 'number'
            ? Number(exp(firstSelectedConversion.exp, { value: e.value }))
            : e.value,
      }));
    }

    return ret;
  }, [firstEntries, firstSelectedConversion, firstSelectedMenuItem, formatting]);

  const secondChartSeries: ChartSeries | undefined = useMemo(() => {
    if (!secondSelectedMenuItem) return undefined;

    const format = getFormatting(secondSelectedMenuItem, formatting);

    const ret = {
      id: secondSelectedMenuItem.id,
      name: getSelectableMenuItemFullTitle(secondSelectedMenuItem),
      unit: secondSelectedConversion?.unit ?? secondSelectedMenuItem.property.unit,
      entries: secondEntries.slice(),
      maxFractionDigits: secondSelectedConversion?.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
      minFractionDigits: secondSelectedConversion?.maxFractionDigits ?? DEFAULT_MIN_FRACTION_DIGITS,
      defaultLowerDomainY: format?.defaultLowerDomainY,
      defaultUpperDomainY: format?.defaultUpperDomainY,
      color: '#00dd8d',
    };

    if (secondSelectedConversion && format) {
      const nonGlobalConversion = format.units?.find(
        (u) => u.unit === secondSelectedConversion.unit
      );

      if (nonGlobalConversion) {
        if (typeof nonGlobalConversion.defaultLowerDomainY === 'number') {
          ret.defaultLowerDomainY = nonGlobalConversion.defaultLowerDomainY;
        }

        if (typeof nonGlobalConversion.defaultUpperDomainY === 'number') {
          ret.defaultUpperDomainY = nonGlobalConversion.defaultUpperDomainY;
        }
      }
    }

    if (secondSelectedConversion) {
      ret.entries = ret.entries.map((e) => ({
        ...e,
        value:
          typeof e.value === 'number'
            ? Number(exp(secondSelectedConversion.exp, { value: e.value }))
            : e.value,
      }));
    }

    return ret;
  }, [secondSelectedMenuItem, formatting, secondSelectedConversion, secondEntries]);

  const isFetching = useMemo(
    () => isFetchingFirstEntries || isFetchingSecondEntries,
    [isFetchingFirstEntries, isFetchingSecondEntries]
  );

  const isDataEmpty = useMemo(
    () =>
      !firstEntries.filter((e) => typeof e.value === 'number').length &&
      !secondEntries.filter((e) => typeof e.value === 'number').length,
    [firstEntries, secondEntries]
  );

  const handleSelectMenuItemInFirstPropertyPicker = useCallback((menuItem: SelectableMenuItem) => {
    setFirstSelectedMenuItem(menuItem);
  }, []);

  const handleSelectMenuItemInSecondPropertyPicker = useCallback((menuItem: SelectableMenuItem) => {
    setSecondSelectedMenuItem(menuItem);
  }, []);

  const handleClickExpandButton = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const handleClickCloseButtonInModal = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const { thumbPosition } = useTimelineThumb();
  const controlBarManager = useControlBarManager();
  const viewMode = useViewMode();

  const thumbPositionDate = useMemo(
    () => calculatePositionDate(thumbPosition, controlBarManager.getTimelineBarInterval()),
    [controlBarManager, thumbPosition]
  );

  const linePointerTimestamp = useMemo(() => {
    if (isFetching || isDataEmpty) return undefined;
    return viewMode === 'replay' ? thumbPositionDate : undefined;
  }, [isDataEmpty, isFetching, thumbPositionDate, viewMode]);

  return (
    <>
      {isExpanded && (
        <ChartSectionModal
          items={menuItems}
          firstPropertyPickerSelectedMenuItem={firstSelectedMenuItem}
          secondPropertyPickerSelectedMenuItem={secondSelectedMenuItem}
          onSelectMenuItemInFirstPropertyPicker={handleSelectMenuItemInFirstPropertyPicker}
          onSelectMenuItemInSecondPropertyPicker={handleSelectMenuItemInSecondPropertyPicker}
          onClose={handleClickCloseButtonInModal}
        />
      )}
      <EmitterDetailsBarSection
        header={
          <ChartSectionHeader
            items={menuItemsWithUnits}
            firstPropertyPickerSelectedMenuItem={firstSelectedMenuItem}
            secondPropertyPickerSelectedMenuItem={secondSelectedMenuItem}
            firstUnits={
              firstConversions?.map((c) => c.unit) ??
              (firstSelectedMenuItem?.property.unit ? [firstSelectedMenuItem.property.unit] : [])
            }
            secondUnits={
              secondConversions?.map((c) => c.unit) ??
              (secondSelectedMenuItem?.property.unit ? [secondSelectedMenuItem.property.unit] : [])
            }
            selectedFirstUnitsIndex={firstConversionSelectedIndex}
            selectedSecondUnitIndex={secondConversionSelectedIndex}
            showExpandButton={false}
            onSelectFirstUnit={setFirstConversionSelectedIndex}
            onSelectSecondUnit={setSecondConversionSelectedIndex}
            onSelectMenuItemInFirstPropertyPicker={handleSelectMenuItemInFirstPropertyPicker}
            onSelectMenuItemInSecondPropertyPicker={handleSelectMenuItemInSecondPropertyPicker}
            onClickExpandButton={handleClickExpandButton}
          />
        }
      >
        <div className={styles.chartContainer}>
          {isFetching ? (
            <div className={styles.loadingIndicator}>
              <PuffLoader color="#44ABDF" size={40} />
            </div>
          ) : isDataEmpty ? (
            <div className={styles.placeholder}>No data available</div>
          ) : (
            <ResponsiveBiaxialChart
              firstSeries={firstChartSeries}
              secondSeries={secondChartSeries}
              linePointerTimestamp={linePointerTimestamp}
            />
          )}
        </div>
      </EmitterDetailsBarSection>
    </>
  );
};

function getFormatting(
  menuItem: SelectableMenuItem,
  formatting: FormattingConfig
): SingleFormatter | undefined {
  return formatting[menuItem.property.sensorId]?.[menuItem.property.name.name];
}
