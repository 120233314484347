import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { Property } from 'Models';
import {
  FetchSampleSeriesQueryArgument,
  PropertyValue,
  Sample,
  fetchLatestSample,
  fetchSampleSeries,
} from 'Networking/http';
import { useEffect, useMemo } from 'react';
import { useControlBarManager, useTimelineThumb, useViewMode } from '../../ControlBar/hooks';
import { useQuery } from '@tanstack/react-query';
import { generateTimelineBarDataInterval, getNewDataIndex } from 'Helpers/timeline';
import { useLatestObservedSample } from 'Networking/socket';
import { fill, mergeSamples } from 'Helpers/sample';

export interface useGranularPropertiesSumSampleResult<T extends PropertyValue> {
  sample: Sample<[T]> | null | undefined;
  isLoading: boolean;
}

export function useGranularPropertiesSumSample<T extends PropertyValue>(
  properties: Property[]
): useGranularPropertiesSumSampleResult<T> {
  const { absoluteInterval, refresh } = useSelectedInterval();

  const propertiesHash = properties
    .map((p) => p.hash)
    .sort()
    .join();

  useEffect(refresh, [propertiesHash, refresh]);

  const controlBarManager = useControlBarManager();

  const dataInterval = useMemo(() => {
    return generateTimelineBarDataInterval(
      absoluteInterval,
      controlBarManager.getTimelineBarSampleRateMs()
    );
  }, [absoluteInterval, controlBarManager]);

  const latestSampleQueryArg = { properties, interval: dataInterval };
  const latestSampleQuery = useQuery(
    fetchLatestSample.generateQueryKey(latestSampleQueryArg),
    () => fetchLatestSample(latestSampleQueryArg),
    { enabled: !!properties.length }
  );

  const sampleSeriesQueryArg: FetchSampleSeriesQueryArgument = {
    properties: properties,
    interval: dataInterval,
    aggregation: 'first',
    sample: {
      unit: 'second',
      rate: Math.floor(controlBarManager.getTimelineBarSampleRateMs() / 1000),
    },
    densify: true,
  };

  const sampleSeriesQuery = useQuery(
    fetchSampleSeries.generateQueryKey(sampleSeriesQueryArg),
    () => fetchSampleSeries(sampleSeriesQueryArg),
    { enabled: !!properties.length }
  );

  const sample = latestSampleQuery.data;
  const observedSample = useLatestObservedSample(properties);

  const mergedSample = useMemo(
    () => mergeSamples(sample, observedSample),
    [sample, observedSample]
  );

  const viewMode = useViewMode();
  const { thumbPosition } = useTimelineThumb();

  const historicalSamples = useMemo(
    () => sampleSeriesQuery.data && fill(sampleSeriesQuery.data, 'forward'),
    [sampleSeriesQuery.data]
  );

  const historicalSample = useMemo(() => {
    const samples = historicalSamples;

    if (samples && samples.length) {
      const dataIndex = getNewDataIndex(
        thumbPosition,
        dataInterval,
        controlBarManager.getTimelineBarSampleRateMs()
      );
      const invertedIndex = Math.min(
        Math.max(samples.length - 1 - dataIndex, 0),
        samples.length - 1
      );
      return samples[invertedIndex];
    }

    return undefined;
  }, [controlBarManager, dataInterval, historicalSamples, thumbPosition]);

  const sampleToDisplay = useMemo(() => {
    return viewMode === 'replay' ? historicalSample : mergedSample;
  }, [historicalSample, mergedSample, viewMode]);

  return {
    sample: sampleToDisplay as Sample<[T]> | null | undefined,
    isLoading: latestSampleQuery.isLoading,
  };
}
