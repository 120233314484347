import { useMemo } from 'react';
import { Property } from 'Models';
import {
  GetSensorsRecordsQueryArgument,
  PropertyValue,
  SensorRecords,
  useGetSensorsRecordsQuery,
  VariableProperty,
} from 'Services/redux/api/sensor';
import { createPropertyName, generatePropertyHash } from 'Helpers/sensors';
import { isVariableSensorRecordsArgument } from 'Services/redux/api/sensor/util';
import { SubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState';

type RecordKey = Property['name']['hash'] | string;

export type PropertyFrame = {
  value: PropertyValue;
  date: string;
  isSatcomm: PropertyValue;
  errors: number[] | null;
};

const EMPTY_PROPERTIES_FRAMES: FramesByProperty = {};
const EMPTY_FRAMES: PropertyFrame[] = [];

export type FramesByProperty = Record<RecordKey, PropertyFrame[]>;

export const useGetPropertiesFramesQuery = (
  arg: GetSensorsRecordsQueryArgument,
  includeErrors?: boolean,
  options?: SubscriptionOptions
) => {
  const argWithTimestamps = useMemo(() => {
    if (isVariableSensorRecordsArgument(arg)) {
      const seenSensorIds: Record<string, boolean> = {};

      const metadataProperties: VariableProperty[] = [];

      for (const variableProperty of arg.properties) {
        if (seenSensorIds.hasOwnProperty(variableProperty.property.sensorId)) {
          continue;
        }

        seenSensorIds[variableProperty.property.sensorId] = true;

        for (const variableProperty of arg.properties) {
          if (seenSensorIds.hasOwnProperty(variableProperty.property.sensorId)) {
            continue;
          }

          seenSensorIds[variableProperty.property.sensorId] = true;

          const systemTimeProperty = getPropertyByName(variableProperty.property, 'systemTime');
          const errorCodesProperty = getPropertyByName(variableProperty.property, 'errorCodes');
          const isSatcommProperty = getPropertyByName(
            variableProperty.property,
            'metadata.isSatcomm'
          );

          if (errorCodesProperty && includeErrors) {
            metadataProperties.push({
              property: errorCodesProperty,
              variants: [
                {
                  alias: errorCodesProperty.name.hash,
                  aggregation: 'max',
                },
              ],
            });
          }

          if (isSatcommProperty) {
            metadataProperties.push({
              property: isSatcommProperty,
              variants: [
                {
                  alias: isSatcommProperty.name.hash,
                  aggregation: 'min',
                },
              ],
            });
          }

          metadataProperties.push({
            property: systemTimeProperty,
            variants: [
              {
                alias: systemTimeProperty.name.hash,
                aggregation: 'first',
              },
            ],
          });
        }
      }

      return { ...arg, properties: arg.properties.concat(metadataProperties) };
    } else {
      const seenSensorIds: Record<string, boolean> = {};
      const metadataProperties: Property[] = [];
      for (const property of arg.properties) {
        if (seenSensorIds.hasOwnProperty(property.sensorId)) {
          continue;
        }

        seenSensorIds[property.sensorId] = true;

        const systemTimeProperty = getPropertyByName(property, 'systemTime');
        const errorCodesProperty = getPropertyByName(property, 'errorCodes');
        const isSatcommProperty = getPropertyByName(property, 'metadata.isSatcomm');

        if (errorCodesProperty && includeErrors) {
          metadataProperties.push(errorCodesProperty);
        }

        if (isSatcommProperty) {
          metadataProperties.push(isSatcommProperty);
        }

        metadataProperties.push(systemTimeProperty);
      }

      return {
        ...arg,
        properties: arg.properties.concat(metadataProperties),
      };
    }
  }, [arg, includeErrors]);

  const ret = useGetSensorsRecordsQuery(argWithTimestamps, {
    ...options,
    selectFromResult: ({
      currentData,
      isUninitialized,
      isLoading,
      isFetching,
      isSuccess,
      isError,
      error,
    }) => {
      const recordsBySensors = currentData ?? [];

      const propertiesFrames = isVariableSensorRecordsArgument(arg)
        ? getFramesByVariableProperty(arg.properties, recordsBySensors)
        : getFramesByProperty(arg.properties, recordsBySensors);

      return {
        propertiesFrames: Object.keys(propertiesFrames).length
          ? propertiesFrames
          : EMPTY_PROPERTIES_FRAMES,
        isUninitialized,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
      };
    },
  });

  return ret;
};

const getFramesByProperty = (
  properties: Property[],
  recordsBySensors: SensorRecords[]
): FramesByProperty => {
  const framesByProperty: FramesByProperty = {};

  for (const recordsBySensor of recordsBySensors) {
    for (const property of properties) {
      if (recordsBySensor.sensorId === property.sensorId) {
        framesByProperty[property.hash] = recordsBySensor.records.reduce<PropertyFrame[]>(
          (acc, record) => {
            const value = record[property.name.hash];
            const date = record[getPropertyByName(property, 'systemTime').name.hash];
            const errorCodes = record[getPropertyByName(property, 'errorCodes').name.hash];
            const isSatcomm = record[getPropertyByName(property, 'metadata.isSatcomm').name.hash];

            if (date) {
              const errors = (errorCodes as unknown as number[]) ?? [];
              const frame: PropertyFrame = {
                value: value === undefined ? null : value,
                date: date as string,
                errors: errors.length ? errors : null,
                isSatcomm: (isSatcomm as boolean) ?? null,
              };

              return acc.concat(frame);
            }

            return acc;
          },
          []
        );
      }
    }
  }

  return framesByProperty;
};

const getFramesByVariableProperty = (
  variableProperties: VariableProperty[],
  recordsBySensors: SensorRecords[]
): FramesByProperty => {
  const framesByProperty: FramesByProperty = {};

  for (const recordsBySensor of recordsBySensors) {
    for (const variableProperty of variableProperties) {
      if (recordsBySensor.sensorId === variableProperty.property.sensorId) {
        for (const variant of variableProperty.variants) {
          framesByProperty[variant.alias] = recordsBySensor.records.reduce<PropertyFrame[]>(
            (acc, record) => {
              const value = record[variant.alias];
              const date =
                record[getPropertyByName(variableProperty.property, 'systemTime').name.hash];
              const errorCodes =
                record[getPropertyByName(variableProperty.property, 'errorCodes').name.hash];
              const isSatcomm =
                record[
                  getPropertyByName(variableProperty.property, 'metadata.isSatcomm').name.hash
                ];
              if (date) {
                const errors = (errorCodes as number[] | null) ?? [];
                const frame: PropertyFrame = {
                  value: value === undefined ? null : value,
                  date: date as string,
                  errors: errors.length ? errors : null,
                  isSatcomm: (isSatcomm as boolean) ?? null,
                };

                return acc.concat(frame);
              }

              return acc;
            },
            []
          );
        }
      }
    }
  }

  return framesByProperty;
};

export const getFramesForProperty = (
  property: Property,
  propertiesFrames: FramesByProperty
): PropertyFrame[] => {
  return propertiesFrames[property.hash] ?? EMPTY_FRAMES;
};

const getPropertyByName = (
  property: Property,
  name: 'metadata.isSatcomm' | 'systemTime' | 'errorCodes'
): Property => {
  const path = property.name.path.replace(/\/[^/]*$/, name);

  const propertyName = createPropertyName(name, path);

  return {
    name: propertyName,
    displayName: 'Time',
    sensorGroupId: property.sensorGroupId,
    sensorId: property.sensorId,
    sensorName: property.sensorName,
    sensorDisplayName: property.sensorDisplayName,
    sampleRate: property.sampleRate,
    hash: generatePropertyHash(property.sensorId, propertyName),
  };
};
