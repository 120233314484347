import { CompliantPopupItem } from 'Components/CompliantPopup';

// Check password
export function checkPassword(password?: string): CompliantPopupItem[] | undefined {
  if (!password) return;

  return [
    {
      level: password !== password.toLowerCase(),
      message: 'At least 1 capital letter',
    },
    {
      level: containsNumbers(password),
      message: 'At least 1 number',
    },
    {
      level: containsSpecialCharacters(password),
      message: 'At least 1 special character',
    },
    {
      level: password.length >= 8,
      message: 'More than 8 characters',
    },
  ];
}

export function hasNonNumericCharacters(str: string) {
  return /\D/.test(str);
}

export function isPhoneNumberInvalid(str: string) {
  return !/^(\+)?([0-9]*)$/.test(str);
}

// Contains numbers
export function containsNumbers(password: string): boolean {
  return /\d/.test(password);
}

// Contains special characters
export function containsSpecialCharacters(password: string): boolean {
  const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

  return format.test(password);
}

// Is Email format
export function isEmailFormat(text: string): boolean {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(text);
}
