// Here we create strong-typed system for each sensor name and
// its corresponding properties.
//
// Due to the database schema in the backend, sensor is designed
// to contain another sensor. We call child sensors, properties.
//
// A property can be accessed like:
// const propertyName = SensorName.GPS.PropertyName.Latitude;
//
// Circular reference is not allowed in Redux. So instead of
// storing the parent object, we store the path.
//

import { createPropertyName } from 'Helpers/sensors';

export interface ISensorName<Name extends string = string> {
  name: Name;
  PropertyName: {
    [name: string]: IPropertyName;
  };
}

export interface IPropertyName<Name extends string = string> {
  name: Name;
  path: string;
  hash: string;
}

const unifiedSensorName = {
  name: 'virt',
  PropertyName: {
    // GPS
    COG: {
      name: 'cog',
    } as IPropertyName<'cog'>,
    SOG: {
      name: 'sog',
    } as IPropertyName<'sog'>,
    STW: {
      name: 'stw',
    } as IPropertyName<'stw'>,
    Heading: {
      name: 'heading',
    } as IPropertyName<'heading'>,

    // IMU
    Pitch: {
      name: 'pitch',
    } as IPropertyName<'pitch'>,
    Roll: {
      name: 'roll',
    } as IPropertyName<'roll'>,
    Heave: {
      name: 'heave',
    } as IPropertyName<'heave'>,
    Offset: {
      name: 'offset',
    } as IPropertyName<'offset'>,
    RollPeriod: {
      name: 'rollPeriod',
    } as IPropertyName<'rollPeriod'>,

    // Weather
    TrueWindSpeed: {
      name: 'trueWindSpeed',
    } as IPropertyName<'trueWindSpeed'>,
    TrueWindDirection: {
      name: 'trueWindDirection',
    } as IPropertyName<'trueWindDirection'>,
    RelativeWindSpeed: {
      name: 'relativeWindSpeed',
    } as IPropertyName<'relativeWindSpeed'>,
    RelativeWindDirection: {
      name: 'relativeWindDirection',
    } as IPropertyName<'relativeWindDirection'>,
    AirTemperature: {
      name: 'airTemperature',
    } as IPropertyName<'airTemperature'>,
    SeawaterTemperature: {
      name: 'seawaterTemp',
    } as IPropertyName<'seawaterTemp'>,
    UV: {
      name: 'uv',
    } as IPropertyName<'uv'>,
    RainVolume: {
      name: 'rainVolume',
    } as IPropertyName<'rainVolume'>,
    RelativeHumidity: {
      name: 'relativeHumidity',
    } as IPropertyName<'relativeHumidity'>,
    AtmosphericPressure: {
      name: 'atmosphericPressure',
    } as IPropertyName<'atmosphericPressure'>,

    // Derived Emission
    NOXDualAverage: {
      name: 'NOXDualAverage',
    } as IPropertyName<'NOXDualAverage'>,
    CO2Average: {
      name: 'CO2Average',
    } as IPropertyName<'CO2Average'>,
    SO2Average: {
      name: 'SO2Average',
    } as IPropertyName<'SO2Average'>,
    CH4DualAverage: {
      name: 'CH4DualAverage',
    } as IPropertyName<'CH4DualAverage'>,
    H2OAverage: {
      name: 'H2OAverage',
    } as IPropertyName<'H2OAverage'>,
    Score: {
      name: 'score',
    } as IPropertyName<'score'>,

    // EmissionV2
    NOXMassFlow: {
      name: 'NOXMassFlow',
    } as IPropertyName<'NOXMassFlow'>,
    CO2MassFlow: {
      name: 'CO2MassFlow',
    } as IPropertyName<'CO2MassFlow'>,
    SO2MassFlow: {
      name: 'SO2MassFlow',
    } as IPropertyName<'SO2MassFlow'>,
    CH4MassFlow: {
      name: 'CH4MassFlow',
    } as IPropertyName<'CH4MassFlow'>,
    COMassFlow: {
      name: 'COMassFlow',
    } as IPropertyName<'COMassFlow'>,
    NOX: {
      name: 'NOXDual',
    } as IPropertyName<'NOXDual'>,
    CO2: {
      name: 'CO2',
    } as IPropertyName<'CO2'>,
    CO: {
      name: 'CO',
    } as IPropertyName<'CO'>,
    SO2: {
      name: 'SO2',
    } as IPropertyName<'SO2'>,
    CH4: {
      name: 'CH4Dual',
    } as IPropertyName<'CH4Dual'>,
    H2O: {
      name: 'H2O',
    } as IPropertyName<'H2O'>,
    Flow: {
      name: 'flow',
    } as IPropertyName<'flow'>,
    Dust: {
      name: 'dust',
    } as IPropertyName<'dust'>,
    
    CO2MassPerDay: {
      name: 'CO2MassPerDay',
    } as IPropertyName<'CO2MassPerDay'>,
    NOXMassPerDay: {
      name: 'NOXMassPerDay',
    } as IPropertyName<'NOXMassPerDay'>,
    SO2MassPerDay: {
      name: 'SO2MassPerDay',
    } as IPropertyName<'SO2MassPerDay'>,
    CH4MassPerDay: {
      name: 'CH4MassPerDay',
    } as IPropertyName<'CH4MassPerDay'>,

    // Virt
    CO2MassFlowFromConc: {
      name: 'CO2MassFlowFromConc_est',
    } as IPropertyName<'CO2MassFlowFromConc_est'>,

    CO2Mass24HrsDailyAvg: {
      name: 'CO2Mass24HrsDailyAvg',
    } as IPropertyName<'CO2Mass24HrsDailyAvg'>,

    CO2MassAvgPerNm: {
      name: 'CO2MassAvgPerNm',
    } as IPropertyName<'CO2MassAvgPerNm'>,

    FuelPerDay: {
      name: 'estimatedFuelMass24HrsDailyAverage',
    } as IPropertyName<'estimatedFuelMass24HrsDailyAverage'>,
    
    FuelPerNM: {
      name: 'fuelMassAvgPerNm',
    } as IPropertyName<'fuelMassAvgPerNm'>,
    SFOC: {
      name: 'sfoc',
    } as IPropertyName<'sfoc'>,

    // Generator
    ActivePower: {
      name: 'activePower',
    } as IPropertyName<'activePower'>,
    ApparentPower: {
      name: 'apparentPower',
    } as IPropertyName<'apparentPower'>,
    Load: {
      name: 'load',
    } as IPropertyName<'load'>,
    Phase1Current: {
      name: 'phase1Current',
    } as IPropertyName<'phase1Current'>,
    Phase2Current: {
      name: 'phase2Current',
    } as IPropertyName<'phase2Current'>,
    Phase3Current: {
      name: 'phase3Current',
    } as IPropertyName<'phase3Current'>,
    Phase1Voltage: {
      name: 'phase1Voltage',
    } as IPropertyName<'phase1Voltage'>,
    Phase2Voltage: {
      name: 'phase2Voltage',
    } as IPropertyName<'phase2Voltage'>,
    Phase3Voltage: {
      name: 'phase3Voltage',
    } as IPropertyName<'phase3Voltage'>,
    ReactivePower: {
      name: 'reactivePower',
    } as IPropertyName<'reactivePower'>,
    RPM: {
      name: 'rpm',
    } as IPropertyName<'rpm'>,

    // Engine
    FuelConsumption: {
      name: 'fuelConsumption',
    } as IPropertyName<'fuelConsumption'>,
    FuelMassPerDay: {
      name: 'fuelMassPerDay',
    } as IPropertyName<'fuelMassPerDay'>,
    SupplyMassFlowRate: {
      name: 'supplyMassFlowRate',
    } as IPropertyName<'supplyMassFlowRate'>,
    SupplyMasFlowRate: {
      name: 'supplyMasFlowRate',
    } as IPropertyName<'supplyMasFlowRate'>,
    TotalFuelConsumption: {
      name: 'totalFuelConsumption',
    } as IPropertyName<'totalFuelConsumption'>,
    FuelOutTemperature: {
      name: 'fuelOutTemp',
    } as IPropertyName<'fuelOutTemp'>,
    FuelInFlow: {
      name: 'fuelInFlow',
    } as IPropertyName<'fuelInFlow'>,
    FuelOutFlow: {
      name: 'fuelOutFlow',
    } as IPropertyName<'fuelOutFlow'>,
    CO2MassFlowEstimated: {
      name: 'CO2MassFlowEstimated',
    } as IPropertyName<'CO2MassFlowEstimated'>,
    InGasMode: {
      name: 'inGasMode',
    } as IPropertyName<'inGasMode'>,
    InMGOMode: {
      name: 'inMgoMode',
    } as IPropertyName<'inMgoMode'>,
    FlowDifferential: {
      name: 'flowDifferential',
    } as IPropertyName<'flowDifferential'>,

    // Air Quality
    PM25Aqi: {
      name: 'pm25Aqi',
    } as IPropertyName<'pm25Aqi'>,
    PM10Aqi: {
      name: 'pm10Aqi',
    } as IPropertyName<'pm10Aqi'>,
    NO2Aqi: {
      name: 'NO2Aqi',
    } as IPropertyName<'NO2Aqi'>,

    Latitude: {
      name: 'latitude',
    } as IPropertyName<'latitude'>,
    Longitude: {
      name: 'longitude',
    } as IPropertyName<'longitude'>,
    Metadata: {
      name: 'metadata',
    } as IPropertyName<'metadata'>,
    IsSatcomm: {
      name: 'metadata.isSatcomm',
    } as IPropertyName<'metadata.isSatcomm'>,
    Errors: {
      name: 'errorCodes',
    } as IPropertyName<'errorCodes'>,
    SystemTime: {
      name: 'systemTime',
    } as IPropertyName<'systemTime'>,
  },
};

const aisStaticSensorName = {
  name: 'aisStatic',
  PropertyName: {
    Stern: {
      name: 'sternMeters',
    } as IPropertyName<'sternMeters'>,
    Starboard: {
      name: 'starboardMeters',
    } as IPropertyName<'starboardMeters'>,
    Port: {
      name: 'portMeters',
    } as IPropertyName<'portMeters'>,
    Bow: {
      name: 'bowMeters',
    } as IPropertyName<'bowMeters'>,
    MaxDraughtMeters: {
      name: 'maxDraughtMeters',
    } as IPropertyName<'maxDraughtMeters'>,
    Eta: {
      name: 'eta',
    } as IPropertyName<'eta'>,
    Name: {
      name: 'name',
    } as IPropertyName<'name'>,
    Imo: {
      name: 'imo',
    } as IPropertyName<'imo'>,
    Callsign: {
      name: 'callsign',
    } as IPropertyName<'callsign'>,
    Destination: {
      name: 'destination',
    } as IPropertyName<'destination'>,
    Flag: {
      name: 'flag',
    } as IPropertyName<'flag'>,
    Mmsi: {
      name: 'mmsi',
    } as IPropertyName<'mmsi'>,
    Metadata: {
      name: 'metadata',
    } as IPropertyName<'metadata'>,
    SystemTime: {
      name: 'systemTime',
    } as IPropertyName<'systemTime'>,
    IsSatcomm: {
      name: 'metadata.isSatcomm',
    } as IPropertyName<'metadata.isSatcomm'>,
    Errors: {
      name: 'errorCodes',
    } as IPropertyName<'errorCodes'>,
  },
} as const;

const aisPositionalSensorName = {
  name: 'aisPositional',
  PropertyName: {
    Status: {
      name: 'status',
    } as IPropertyName<'status'>,
    Type: {
      name: 'type',
    } as IPropertyName<'type'>,
    TrueHeading: {
      name: 'trueHeading',
    } as IPropertyName<'trueHeading'>,
    Cog: {
      name: 'cog',
    } as IPropertyName<'cog'>,
    Sog: {
      name: 'sog',
    } as IPropertyName<'sog'>,
    Latitude: {
      name: 'latitude',
    } as IPropertyName<'latitude'>,
    Longitude: {
      name: 'longitude',
    } as IPropertyName<'longitude'>,
    Mmsi: {
      name: 'mmsi',
    } as IPropertyName<'mmsi'>,
    Metadata: {
      name: 'metadata',
    } as IPropertyName<'metadata'>,
    SystemTime: {
      name: 'systemTime',
    } as IPropertyName<'systemTime'>,
    IsSatcomm: {
      name: 'metadata.isSatcomm',
    } as IPropertyName<'metadata.isSatcomm'>,
    Errors: {
      name: 'errorCodes',
    } as IPropertyName<'errorCodes'>,
  },
} as const;

const gpsSensorName = {
  name: 'gps',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const imuSensorName = {
  name: 'imu',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const weatherSensorName = {
  name: 'weather',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const derivedEmissionSensorName = {
  name: 'derived',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const emissionV2SensorName = {
  name: 'emissionsV2',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const virtSensorName = {
  name: 'virt',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const generatorSensorName = {
  name: 'generator',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const engineSensorName = {
  name: 'engine',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const airQualitySensorName = {
  name: 'airQuality',
  PropertyName: Object.assign({}, unifiedSensorName.PropertyName),
} as const;

const cameraSensorName = {
  name: 'camera',
  PropertyName: {},
} as const;

export const SensorName = {
  GPS: gpsSensorName,
  AISPositional: aisPositionalSensorName,
  AISStatic: aisStaticSensorName,
  IMU: imuSensorName,
  Weather: weatherSensorName,
  DerivedEmission: derivedEmissionSensorName,
  EmissionV2: emissionV2SensorName,
  Generator: generatorSensorName,
  Engine: engineSensorName,
  AirQuality: airQualitySensorName,
  Virt: virtSensorName,
  Camera: cameraSensorName,
};

Object.values(SensorName).forEach((s) => addPath(s as ISensorName));

function addPath(sensorName: ISensorName) {
  Object.values(sensorName.PropertyName).forEach((propertyName) => {
    const path = `${sensorName.name}/${propertyName.name}`.replace(/\./g, '/');

    const generatedPropertyName = createPropertyName(propertyName.name, path);

    propertyName.path = generatedPropertyName.path;
    propertyName.hash = generatedPropertyName.hash;
  });
}
