import { Property } from 'Models';
import { ShareEmitterAccessArgument, UpdateEmitterAccessArgument } from '../sensorSchemas.api';
import { getUniqueProperties } from '../../sensor/util';

export const getShareEmitterAccessRequestBody = ({
  emitter,
  userIds,
  earliest,
  latest,
  properties,
  isReadAll,
}: ShareEmitterAccessArgument) => {
  const body: Record<string, any> = {
    restrictions: getRestrictionsPayload(earliest, latest),
    userIds,
    isManager: false,
    isReadAll: !!isReadAll,
    sensorGroupId: emitter.id,
  };

  if (properties) {
    body.sensors = getSensorsPayload(properties);
  }

  return body;
};

export const getUpdateEmitterAccessRequestBody = ({
  emitter,
  earliest,
  latest,
  properties,
  isReadAll,
}: Omit<UpdateEmitterAccessArgument, 'userAccess'>) => {
  const body: Record<string, any> = {
    restrictions: getRestrictionsPayload(earliest, latest),
    isManager: false,
    isReadAll: !!isReadAll,
    sensorGroupId: emitter.id,
  };

  if (properties) {
    body.sensors = getSensorsPayload(properties);
  }

  return body;
};

type SensorPayload = {
  id: string;
  properties: string[];
};

function getSensorsPayload(properties: Property[]) {
  const sensorPayloads: SensorPayload[] = [];

  const uniqueProperties = getUniqueProperties(properties);

  for (const property of uniqueProperties) {
    const existingSensorPayload = sensorPayloads.find((p) => p.id === property.sensorId);

    if (existingSensorPayload) {
      existingSensorPayload.properties.push(property.name.name);
    } else {
      sensorPayloads.push({ id: property.sensorId, properties: [property.name.name] });
    }
  }

  return sensorPayloads;
}

function getRestrictionsPayload(earliest?: string, latest?: string) {
  let restrictions: [{ earliest: string; latest?: string }] | [] = [];

  if (earliest && latest) {
    restrictions = [{ earliest, latest }];
  } else if (earliest) {
    restrictions = [{ earliest }];
  }

  return restrictions;
}
