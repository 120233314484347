import { SensorOption } from '../interfaces';

export const allSensorOptions: SensorOption[] = [
  {
    name: 'GPS',
    scopes: [
      {
        sensorName: 'gps',
        excludePropertyNames: ['errorCodes', 'metadata.isSatcomm'],
        requiredPropertyNames: ['systemTime'],
      },
    ],
  },
  {
    name: 'Stability',
    scopes: [
      {
        sensorName: 'imu',
        excludePropertyNames: ['errorCodes', 'metadata.isSatcomm'],
        requiredPropertyNames: ['systemTime'],
      },
    ],
  },
  {
    name: 'Observed Weather',
    scopes: [
      {
        sensorName: 'weather',
        excludePropertyNames: ['errorCodes', 'metadata.isSatcomm'],
        requiredPropertyNames: ['systemTime'],
      },
    ],
  },
  {
    name: 'Emissions',
    scopes: [
      {
        sensorName: 'emissionsV2',
        excludePropertyNames: ['errorCodes', 'metadata.isSatcomm'],
        requiredPropertyNames: ['systemTime'],
      },
      {
        sensorName: 'virt',
        excludePropertyNames: ['errorCodes', 'metadata.isSatcomm', 'systemTime'],
        requiredPropertyNames: [],
      },
    ],
  },
  {
    name: 'Generator',
    scopes: [
      {
        sensorName: 'generator',
        excludePropertyNames: ['errorCodes', 'metadata.isSatcomm'],
        requiredPropertyNames: ['systemTime'],
      },
    ],
  },
  {
    name: 'Air Quality',
    scopes: [
      {
        sensorName: 'airQuality',
        excludePropertyNames: ['errorCodes', 'metadata.isSatcomm'],
        requiredPropertyNames: ['systemTime'],
      },
    ],
  },
  {
    name: 'Fuel',
    scopes: [
      {
        sensorName: 'engine',
        excludePropertyNames: ['errorCodes', 'metadata.isSatcomm'],
        requiredPropertyNames: ['systemTime'],
      },
    ],
  },
];
