import styles from './TimelineBar.module.scss';
import { TimelineBarThumb, TimelineBarTick, TimelineBarTickAnchorPoint } from './components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generateIntermediaryTickTimestamps } from './helpers';
import { Tick } from './interfaces';
import moment from 'moment';
import { TimelineBarTooltip } from './components/TimelinebarTooltip';
import { calculatePositionDate } from 'Helpers/timeline';

const ianaTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
const timezoneAbbreviation = moment().tz(ianaTimezone).format('z');

const DEFAULT_SPACING_SCALE = 0.25;

type TimelineBarProps = {
  earliest: string;
  latest: string;
  thumbPosition: number;
  onChangeThumbPosition: (position: number, released: boolean) => void;
};

export const TimelineBar: React.FC<TimelineBarProps> = ({
  earliest,
  latest,
  thumbPosition,
  onChangeThumbPosition,
}) => {
  const timelineBarRef = useRef<HTMLDivElement>(null);

  const firstIntermediaryTickRef = useRef<HTMLDivElement | null>();
  const lastIntermediaryTickRef = useRef<HTMLDivElement | null>();

  const [spacingScale, setSpacingScale] = useState(DEFAULT_SPACING_SCALE);

  const [firstIntermediaryTickAnchorPoint, setFirstIntermediaryTickAnchorPoint] =
    useState<TimelineBarTickAnchorPoint>('center');
  const [lastInternediaryTickAnchorPoint, setLastIntermediaryTickAnchorPoint] =
    useState<TimelineBarTickAnchorPoint>('center');

  const ticks: Tick[] = useMemo(() => {
    const earliestMillis = new Date(earliest).getTime();
    const latestMillis = new Date(latest).getTime();

    const timestamps = generateIntermediaryTickTimestamps({
      earliest: earliestMillis,
      latest: latestMillis,
      spacingScale,
    });

    const span = latestMillis - earliestMillis;

    return timestamps.map<Tick>((millis) => ({
      position: (millis - earliestMillis) / span,
      label: formatTimestamp(millis),
    }));
  }, [earliest, latest, spacingScale]);

  const layoutTickLabels = useCallback(() => {
    if (!timelineBarRef.current) return;

    const firstIntermediaryTick = firstIntermediaryTickRef.current;
    const lastIntermediaryTick = lastIntermediaryTickRef.current;

    const firstIntermediaryTickLabel = firstIntermediaryTick?.children?.[0];
    const lastIntermediaryTickLabel = lastIntermediaryTick?.children?.[0];

    if (firstIntermediaryTick && firstIntermediaryTickLabel) {
      if (firstIntermediaryTick.offsetLeft - firstIntermediaryTickLabel.clientWidth / 2 < 0) {
        setFirstIntermediaryTickAnchorPoint('left');
      } else {
        setFirstIntermediaryTickAnchorPoint('center');
      }
    }

    if (lastIntermediaryTick && lastIntermediaryTickLabel) {
      if (
        lastIntermediaryTick.offsetLeft + lastIntermediaryTickLabel.clientWidth / 2 >
        timelineBarRef.current.clientWidth
      ) {
        setLastIntermediaryTickAnchorPoint('right');
      } else {
        setLastIntermediaryTickAnchorPoint('center');
      }
    }
  }, []);

  useEffect(() => {
    layoutTickLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticks]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const timelineBar = entries[0];

      if (!timelineBar) return;

      if (timelineBar.target.clientWidth <= 640) {
        setSpacingScale(0.4);
      } else {
        setSpacingScale(DEFAULT_SPACING_SCALE);
      }

      layoutTickLabels();
    });

    if (timelineBarRef.current) {
      resizeObserver.observe(timelineBarRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isScrubbing, setIsScrubbing] = useState(false);

  useEffect(() => {
    const timelineBar = timelineBarRef.current;

    function onPointerUp(event: PointerEvent) {
      event.preventDefault();

      if (!timelineBar) return;

      setIsScrubbing(false);

      document.removeEventListener('pointermove', onPointerMove);

      const position = calculateThumbPosition(event.clientX, timelineBar);

      onChangeThumbPosition(position, true);

      document.removeEventListener('pointerup', onPointerUp);
    }

    function onPointerDown(event: PointerEvent) {
      event.preventDefault();

      if (!timelineBar) return;

      setIsScrubbing(true);

      document.addEventListener('pointermove', onPointerMove);

      const position = calculateThumbPosition(event.clientX, timelineBar);

      onChangeThumbPosition(position, false);

      document.addEventListener('pointerup', onPointerUp);
    }

    function onPointerMove(event: PointerEvent) {
      event.preventDefault();

      if (!timelineBar) return;

      const position = calculateThumbPosition(event.clientX, timelineBar);

      onChangeThumbPosition(position, false);
    }

    if (!timelineBar) {
      return;
    }

    timelineBar.onpointerdown = onPointerDown;

    return () => {
      timelineBar.onpointerdown = null;
      document.removeEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointerup', onPointerUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hoveredPosition, setHoveredPosition] = useState<number | undefined>();
  const isHovering = useMemo(() => hoveredPosition !== undefined, [hoveredPosition]);

  const handlePointerMoveOnTimelineBar = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const timelineBar = timelineBarRef.current;
    if (!timelineBar) return;

    const position = calculateThumbPosition(event.clientX, timelineBar);

    setHoveredPosition(position);
  }, []);

  const handlePointerLeaveOnTimelineBar = useCallback(() => {
    setHoveredPosition(undefined);
  }, []);

  const tooltipData = useMemo(() => {
    const position = isScrubbing ? thumbPosition : hoveredPosition;

    if (position === undefined) {
      return undefined;
    }

    const date = calculatePositionDate(position, { earliest, latest });
    const formattedDate = moment(date).format(`MMM DD HH:mm:ss [${timezoneAbbreviation}]`);

    return { position, formattedDate };
  }, [isScrubbing, thumbPosition, hoveredPosition, earliest, latest]);

  return (
    <div className={styles.timelineBarContainer}>
      <div
        ref={timelineBarRef}
        className={styles.timelineBar}
        onPointerMove={handlePointerMoveOnTimelineBar}
        onPointerLeave={handlePointerLeaveOnTimelineBar}
      >
        <div className={styles.timelineBarLine}>
          <TimelineBarTick position={0} />
          {ticks.map((tick, index) => (
            <TimelineBarTick
              ref={(node) => {
                if (index === 0) {
                  firstIntermediaryTickRef.current = node;
                } else if (index === ticks.length - 1) {
                  lastIntermediaryTickRef.current = node;
                }
              }}
              key={index}
              position={tick.position}
              label={tick.label}
              labelAnchorPoint={
                index === 0
                  ? firstIntermediaryTickAnchorPoint
                  : index === ticks.length - 1
                  ? lastInternediaryTickAnchorPoint
                  : 'center'
              }
            />
          ))}
          <TimelineBarTick position={1} />
        </div>
        {(isScrubbing || isHovering) && tooltipData && (
          <TimelineBarTooltip position={tooltipData.position}>
            {tooltipData.formattedDate}
          </TimelineBarTooltip>
        )}
        <TimelineBarThumb position={thumbPosition} />
      </div>
    </div>
  );
};

function formatTimestamp(timestamp: number) {
  return moment(timestamp).format('MM/DD HH:mm');
}

function calculateThumbPosition(pointerClientX: number, timelineBar: HTMLDivElement) {
  const timelineBarBoundingClientRect = timelineBar.getBoundingClientRect();
  const position = Math.min(
    1,
    Math.max(
      0,
      (pointerClientX - timelineBarBoundingClientRect.left) / timelineBarBoundingClientRect.width
    )
  );

  return position;
}
