import styles from './TimePicker.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as IconArrowUp } from './resources/graphics/icon-arrow-up.svg';
import { ReactComponent as IconArrowDown } from './resources/graphics/icon-arrow-down.svg';
import clsx from 'clsx';

type Meridiem = 'AM' | 'PM';

export type TimePickerProps = {
  initialTime: Date;
  onChange: (selectedTime: Date) => void;
};

export const TimePicker: React.FC<TimePickerProps> = ({ initialTime, onChange }) => {
  const [selectedHours, setSelectedHours] = useState(
    initialTime.getHours() % 12 ? initialTime.getHours() % 12 : 12
  );
  const [selectedMinutes, setSelectedMinutes] = useState(initialTime.getMinutes());
  const [selectedMeridiem, setSelectedMeridiem] = useState<Meridiem>(
    initialTime.getHours() <= 12 ? 'AM' : 'PM'
  );

  const selectedTime = useMemo(() => {
    const hours =
      selectedMeridiem === 'AM'
        ? selectedHours === 12
          ? 0
          : selectedHours
        : selectedHours !== 12
        ? selectedHours + 12
        : selectedHours;

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(selectedMinutes);
    date.setSeconds(0);

    return date;
  }, [selectedHours, selectedMinutes, selectedMeridiem]);

  useEffect(() => {
    if (selectedTime.getTime() === initialTime.getTime()) {
      return;
    }

    onChange(selectedTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime]);

  const handleClickIncrementHoursButton = useCallback(() => {
    setSelectedHours((selectedHours) => {
      return selectedHours + 1 > 12 ? 1 : selectedHours + 1;
    });
  }, []);

  const handleClickDecrementHoursButton = useCallback(() => {
    setSelectedHours((selectedHours) => {
      return selectedHours - 1 < 1 ? 12 : selectedHours - 1;
    });
  }, []);

  const handleClickIncrementMinutesButton = useCallback(() => {
    setSelectedMinutes((selectedMinutes) => {
      return selectedMinutes + 1 > 59 ? 0 : selectedMinutes + 1;
    });
  }, []);

  const handleClickDecrementMinutesButton = useCallback(() => {
    setSelectedMinutes((selectedMinutes) => {
      return selectedMinutes - 1 < 0 ? 59 : selectedMinutes - 1;
    });
  }, []);

  const handleClickToggleMeridiemButton = useCallback(() => {
    setSelectedMeridiem((selectedMeridiem) => {
      return selectedMeridiem === 'AM' ? 'PM' : 'AM';
    });
  }, []);

  const formattedHours = useMemo(() => {
    return selectedHours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }, [selectedHours]);

  const formattedMinutes = useMemo(() => {
    return selectedMinutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }, [selectedMinutes]);

  return (
    <div className={styles.timePicker}>
      <div className={clsx(styles.timePickerSegment, styles.hour)}>
        <button onClick={handleClickIncrementHoursButton}>
          <IconArrowUp />
        </button>
        <span>{formattedHours}</span>
        <button onClick={handleClickDecrementHoursButton}>
          <IconArrowDown />
        </button>
      </div>
      <div className={styles.timePickerColon}>:</div>
      <div className={clsx(styles.timePickerSegment, styles.minute)}>
        <button onClick={handleClickIncrementMinutesButton}>
          <IconArrowUp />
        </button>
        <span>{formattedMinutes}</span>
        <button onClick={handleClickDecrementMinutesButton}>
          <IconArrowDown />
        </button>
      </div>
      <div className={clsx(styles.timePickerSegment, styles.timezone)}>
        <button onClick={handleClickToggleMeridiemButton}>
          <IconArrowUp />
        </button>
        <span>{selectedMeridiem}</span>
        <button onClick={handleClickToggleMeridiemButton}>
          <IconArrowDown />
        </button>
      </div>
    </div>
  );
};
