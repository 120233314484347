import styles from './CalendarBody.module.scss';
import { useMemo } from 'react';
import { buildRows } from './helpers';
import { DateRange } from 'Components/DateTimeRangePickerModal/types';
import clsx from 'clsx';

export type CalendarBodyProps = {
  maxDate?: Date;
  minDate?: Date;
  visibleMonth: number;
  visibleYear: number;
  selectedDates: Date[];
  highlightedDateRange?: DateRange;
  previewedDateRange?: DateRange;
  onSelectDate: (date: Date) => void;
  onHoverDate: (date: Date) => void;
  onUnhoverDate: (date: Date) => void;
};

export const CalendarBody: React.FC<CalendarBodyProps> = ({
  maxDate,
  minDate,
  visibleMonth,
  visibleYear,
  selectedDates,
  highlightedDateRange,
  previewedDateRange,
  onSelectDate,
  onHoverDate,
  onUnhoverDate,
}) => {
  const rows = useMemo(
    () =>
      buildRows({
        maxDate,
        minDate,
        visibleMonth,
        visibleYear,
        selectedDates,
        highlightedDateRange,
        previewedDateRange,
      }),
    [
      highlightedDateRange,
      maxDate,
      minDate,
      previewedDateRange,
      selectedDates,
      visibleMonth,
      visibleYear,
    ]
  );

  return (
    <div className={styles.calendarBody}>
      {rows.map((row, index) => (
        <div key={index} className={styles.row}>
          {row.cells.map(
            ({ label, date, isSelected, isHighlighted, isPreviewed, isDisabled }, index) =>
              label === undefined ? (
                <span key={index} className={clsx(styles.cell, styles.hidden)}></span>
              ) : (
                <div
                  key={index}
                  className={clsx(styles.cell, {
                    [styles.selected]: isSelected,
                    [styles.highlighted]: isHighlighted && !isPreviewed,
                    [styles.previewed]: isPreviewed,
                    [styles.disabled]: isDisabled,
                  })}
                  onClick={() => !isDisabled && date && onSelectDate(date)}
                  onMouseEnter={() => !isDisabled && date && onHoverDate(date)}
                  onMouseLeave={() => !isDisabled && date && onUnhoverDate(date)}
                >
                  {label ?? ''}
                </div>
              )
          )}
        </div>
      ))}
    </div>
  );
};
