import { AbsoluteInterval } from 'Constants';
import { getAnchoredDate } from './date';
import { appConfiguration } from 'Config';

const Parameter = {
  TimelineBarThumbPosition: 'p',
};

export const generateTimelineBarInterval = (
  absoluteInterval: AbsoluteInterval,
  sampleRateMs: number
): AbsoluteInterval => {
  const anchoredEarliest = getAnchoredDate(
    new Date(absoluteInterval.earliest),
    sampleRateMs
  ).toISOString();

  return { earliest: anchoredEarliest, latest: absoluteInterval.latest };
};

export const generateTimelineBarDataInterval = (
  timelineBarInterval: AbsoluteInterval,
  sampleRateMs: number
): AbsoluteInterval => {
  return {
    earliest: new Date(
      new Date(timelineBarInterval.earliest).getTime() - sampleRateMs / 2
    ).toISOString(),
    latest: timelineBarInterval.latest,
  };
};

export const generateTimelineBarSampleRateMs = (absoluteInterval: AbsoluteInterval) => {
  const earliestDate = new Date(absoluteInterval.earliest);
  const latestDate = new Date(absoluteInterval.latest);

  const sampleRateMs =
    (latestDate.getTime() - earliestDate.getTime()) / (appConfiguration.timelineBarSnapPoints - 1);

  return Math.floor(sampleRateMs);
};

export const getTimelineBarThumbPositionInUrl = (urlString: string): number | undefined => {
  const url = new URL(urlString);
  const positionString = url.searchParams.get(Parameter.TimelineBarThumbPosition);

  return positionString ? parseFloat(positionString) : undefined;
};

export const updateTimelineBarThumbPositionInUrl = (position: number) => {
  const newUrl = new URL(window.location.href);

  newUrl.searchParams.set(Parameter.TimelineBarThumbPosition, String(position));
  window.history.replaceState(window.history.state, '', newUrl);
};

export const getNewDataIndex = (
  thumbPosition: number,
  timelineBarInterval: AbsoluteInterval,
  sampleRateMs: number
): number => {
  const earliestMs = new Date(timelineBarInterval.earliest).getTime();
  const latestMs = new Date(timelineBarInterval.earliest).getTime();

  // floating point error might happen. This is why we need to round the
  // number. However, the latest position should not be included, otherwise
  // it'll be round down.
  // See the following diagram:
  //
  // |----|----|----|----|-|
  // 0    1    2    3    4 5
  //
  // Because the latest point have different spacing, if we round it
  // it'll most likely to round down instead of up,
  if (thumbPosition >= 1.0) {
    return Math.ceil(latestMs / sampleRateMs);
  }

  const thumbPositionDate = calculatePositionDate(thumbPosition, timelineBarInterval);
  const thumbPositionMs = new Date(thumbPositionDate).getTime();

  // The round here is intended, don't use ceil or floor.
  return Math.round((thumbPositionMs - earliestMs) / sampleRateMs);
};

// @deprecated - DO NOT USE!
// Will be removed in future update.
export const getDataIndex = (
  thumbPositionDate: string,
  dataInterval: AbsoluteInterval,
  sampleRateMs: number
) => {
  const earliestMs = new Date(dataInterval.earliest).getTime();
  const latestMs = new Date(dataInterval.latest).getTime();
  const intervalMs = earliestMs - latestMs;
  const thumbPositionMs = new Date(thumbPositionDate).getTime();
  const dataPosition = (thumbPositionMs - earliestMs) / intervalMs;

  const dataIndex = Math.ceil((dataPosition * intervalMs) / sampleRateMs);

  return dataIndex;
};

export const calculatePositionDate = (position: number, timelineBarInterval: AbsoluteInterval) => {
  const earliestMillis = new Date(timelineBarInterval.earliest).getTime();
  const latestMillis = new Date(timelineBarInterval.latest).getTime();
  const positionMillis = earliestMillis + (latestMillis - earliestMillis) * position;

  return new Date(positionMillis).toISOString();
};
