import { useMemo } from 'react';
import { Ais, AisPositional, AisStatic, Emitter, Property, Sensor } from 'Models';
import { useGetSensorsRecordsQuery } from 'Services/redux/api/sensor';
import { SensorName } from 'Constants';
import { getFirstSensorWithName } from 'Helpers/sensors';
import {
  transformIntoAisPositional,
  transformIntoAisStatic,
} from 'Services/redux/api/sensor/transformers';
import { isValidCoordinate } from 'Helpers/coordinate';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import { calculatePositionDate } from 'Helpers/timeline';

const EMPTY_AIS: Ais[] = [];

export const useGetAisListQuery = ({ emitter }: { emitter: Emitter }) => {
  const controlBarManager = useControlBarManager();

  const { releasedThumbPosition } = useTimelineThumb();

  const releasedThumbPositionDate = useMemo(
    () => calculatePositionDate(releasedThumbPosition, controlBarManager.getTimelineBarInterval()),
    [controlBarManager, releasedThumbPosition]
  );

  //TODO: hack until vessel position issue is resolved
  const adjustedReleasedThumbPositionDate = new Date(
    new Date(releasedThumbPositionDate).getTime() + controlBarManager.getTimelineBarSampleRateMs()
  ).toISOString();

  const viewMode = useViewMode();

  const actualLatest = useMemo(() => {
    return viewMode === 'live' ? new Date().toISOString() : adjustedReleasedThumbPositionDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedReleasedThumbPositionDate, viewMode, emitter.id]);

  const earliest = useMemo(() => {
    const startDate = new Date(actualLatest);
    startDate.setMinutes(startDate.getMinutes() - 15);
    return startDate.toISOString();
  }, [actualLatest]);

  const aisStaticSensor = useMemo(
    () => getFirstSensorWithName(SensorName.AISStatic, emitter),
    [emitter]
  );

  const aisPositionalSensor = useMemo(
    () => getFirstSensorWithName(SensorName.AISPositional, emitter),
    [emitter]
  );

  const sensors = useMemo(
    () => [aisStaticSensor, aisPositionalSensor].filter((s) => !!s) as Sensor[],
    [aisStaticSensor, aisPositionalSensor]
  );

  const properties = useMemo(
    () => sensors.reduce<Property[]>((res, sensor) => res.concat(sensor.properties), []),
    [sensors]
  );

  const hasNecessaryArgument = aisPositionalSensor && properties.length;

  const ret = useGetSensorsRecordsQuery(
    {
      properties,
      earliest,
      latest: actualLatest,
      aggregation: 'first',
    },
    {
      skip: !properties.length,
      selectFromResult: ({
        currentData,
        isUninitialized,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
      }) => {
        let aisList: Ais[] = [];

        if (hasNecessaryArgument) {
          const aisStatics =
            (
              aisStaticSensor &&
              currentData?.find((sensorRecords) => sensorRecords.sensorId === aisStaticSensor.id)
            )?.records.map(transformIntoAisStatic) ?? [];

          const aisPositionals =
            currentData
              ?.find((sensorRecords) => sensorRecords.sensorId === aisPositionalSensor.id)
              ?.records.map(transformIntoAisPositional) ?? [];

          aisList = getAisList(aisStatics, aisPositionals);
        }

        return {
          aisList: aisList.length ? aisList : EMPTY_AIS,
          isUninitialized,
          isLoading,
          isFetching,
          isSuccess,
          isError,
          error,
        };
      },
    }
  );

  return ret;
};

const getAisList = (aisStatics: AisStatic[], aisPositionals: AisPositional[]): Ais[] => {
  return aisPositionals
    .map((p) => ({ ...aisStatics.find((s) => s.mmsi === p.mmsi), ...p }))
    .filter((a) => a.coordinate && isValidCoordinate(a.coordinate));
};
