import { SensorName } from 'Constants';
import { Property, Sensor } from '../Models';
import { Expression } from 'array-expression';

export type Section = {
  title?: string;
  type: SectionType;
};

export type SinglePropertyLayout = {
  name: string;
  sensorId: string;
};

export enum SectionType {
  Imu = 'imu',
  Cameras = 'cameras',
  Imply = 'imply',
  MultiImply = 'multiImply',
  Multi = 'multi',
  LoadProfile = 'loadProfile',
  Tabbed = 'tabbed',
  CustomCells = 'cells',
  CustomCellsNoTitle = 'cellsNoTitle',
  ComprehensiveCells = 'comprehensiveCells',
  ComprehensiveCellsNoTitle = 'comprehensiveCellsNoTitle',
  BiaxialChart = 'biaxialChart',
  Option = 'option',
}

export enum ComprehensiveCellType {
  KeyValue = 'keyValue',
  OnOff = 'onOff',
  FuelType = 'fuelType',
}

export type ImuSection = {
  title?: string;
  type: SectionType.Imu;
  roll: SinglePropertyLayout;
  pitch: SinglePropertyLayout;
};

export type ImuSectionExpanded = {
  title: string;
  type: SectionType.Imu;
  roll: Property;
  pitch: Property;
};

export type CamerasSection = {
  title: string;
  type: SectionType.Cameras;
  cameras: { sensorId: Sensor['id']; title: string }[];
};

export type CamerasSectionExpanded = {
  title: string;
  type: SectionType.Cameras;
  cameras: { sensor: Sensor<typeof SensorName.Camera.name>; title: string }[];
};

export type ImplySection = {
  title?: string;
  type: SectionType.Imply;
  sensorId: string;
};

export type MultiImplySection = {
  title?: string;
  type: SectionType.MultiImply;
  sensorIds: string[];
};

export type LoadProfileSection = {
  title?: string;
  type: SectionType.LoadProfile;
  sensorIds: string[];
};

export type LoadProfileSectionExpanded = {
  title: string;
  type: SectionType.LoadProfile;
  sensors: Sensor[];
};

export type TabbedSection = {
  title: string;
  type: SectionType.Tabbed;
  sections: Section[];
};

export type TabbedSectionExpanded = {
  title: string;
  type: SectionType.Tabbed;
  sections: TitledSectionTypeExpanded[];
};

export type CustomCellsSection = {
  title: string;
  type: SectionType.CustomCells;
  properties: SinglePropertyLayout[];
};

export type CustomCellsNoTitleSection = {
  type: SectionType.CustomCellsNoTitle;
  properties: SinglePropertyLayout[];
};

export type ComprehensiveCellsSection = {
  title: string;
  type: SectionType.ComprehensiveCells;
  cells: ComprehensiveCell[];
};

export type ComprehensiveCellsNoTitleSection = {
  type: SectionType.ComprehensiveCellsNoTitle;
  cells: ComprehensiveCell[];
};

export type KeyValueCell = {
  type: ComprehensiveCellType.KeyValue;
  title?: string;
  property: SinglePropertyLayout;
};

export type OnOffCell = {
  type: ComprehensiveCellType.OnOff;
  title?: string;
  property: SinglePropertyLayout;
  onValue: string | number | boolean;
  offValue: string | number | boolean;
};

export type FuelTypeCell = {
  type: ComprehensiveCellType.FuelType;
  title: string;
  fuels: SinglePropertyLayout[];
};

export type KeyValueCellExpanded = {
  type: ComprehensiveCellType.KeyValue;
  title?: string;
  property: Property;
};

export type OnOffCellExpanded = {
  type: ComprehensiveCellType.OnOff;
  title?: string;
  property: Property;
  onValue: string | number | boolean;
  offValue: string | number | boolean;
};

export type FuelTypeCellExpanded = {
  type: ComprehensiveCellType.FuelType;
  title: string;
  fuels: Property[];
};

export type ComprehensiveCellsSectionExpanded = {
  type: SectionType.ComprehensiveCells;
  title: string;
  cells: ComprehensiveCellExpanded[];
};

export type ComprehensiveCellsNoTitleSectionExpanded = {
  type: SectionType.ComprehensiveCellsNoTitle;
  cells: ComprehensiveCellExpanded[];
};

export type OptionSection = {
  title: string;
  type: SectionType.Option;
  sections: Section[];
};

export type OptionSectionExpanded = {
  title: string;
  type: SectionType.Option;
  sections: SectionTypeExpanded[];
};

export type MultiSection = {
  title: string;
  type: SectionType.Multi;
  sections: Section[];
};

export type MultiSectionExpanded = {
  title: string;
  type: SectionType.Multi;
  sections: SectionTypeExpanded[];
};

export type BiaxialChartMenuItemImply = {
  title?: string;
  sensorId: string;
};

export type BiaxialChartMenuItemMultiImply = {
  title: string;
  sensorIds: string[];
};

export type BiaxialChartMenuItemChild = {
  title?: string;
  property: SinglePropertyLayout;
};

export type BiaxialChartMenuItemParent = {
  title: string;
  subitems: BiaxialChartMenuItem[];
};

export type BiaxialChartMenuItemExpandable = {
  id: string;
  title: string;
  subitems: BiaxialChartMenuItemSelectable[];
};

export type BiaxialChartMenuItemSelectable = {
  id: string;
  title: string;
  property: Property;
  parentTitle: string;
};

export type BiaxialChartMenuItem =
  | BiaxialChartMenuItemParent
  | BiaxialChartMenuItemChild
  | BiaxialChartMenuItemMultiImply
  | BiaxialChartMenuItemImply;

export type BiaxialChartSection = Section & {
  type: SectionType.BiaxialChart;
  menu: BiaxialChartMenuItem[];
  initialSelection1?: SinglePropertyLayout;
  initialSelection2?: SinglePropertyLayout;
};

export type BiaxialChartSectionExpanded = {
  type: SectionType.BiaxialChart;
  title: string;
  menu: BiaxialChartMenuItemExpandable[];
  initialSelection1?: BiaxialChartMenuItemSelectable;
  initialSelection2?: BiaxialChartMenuItemSelectable;
};

export type VarsConfig = {
  [variableName: string]: string;
};

export type SingleFormatter = {
  defaultLowerDomainY?: number;
  defaultUpperDomainY?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  units?: {
    unit: string;
    formatter?: Expression;
    defaultLowerDomainY?: number;
    defaultUpperDomainY?: number;
    minFractionDigits?: number;
    maxFractionDigits?: number;
    default?: boolean;
  }[];
};

export type FormattingConfig = {
  [sensorId: string]: {
    [propertyName: string]: SingleFormatter;
  };
};

export type ComprehensiveCell = KeyValueCell | OnOffCell | FuelTypeCell;
export type ComprehensiveCellExpanded =
  | KeyValueCellExpanded
  | OnOffCellExpanded
  | FuelTypeCellExpanded;

export type SectionTypeExpanded =
  | MultiSectionExpanded
  | ComprehensiveCellsSectionExpanded
  | ComprehensiveCellsNoTitleSectionExpanded
  | TabbedSectionExpanded
  | LoadProfileSectionExpanded
  | ImuSectionExpanded
  | CamerasSectionExpanded
  | BiaxialChartSectionExpanded
  | OptionSectionExpanded;

export type TitledSectionTypeExpanded =
  | MultiSection
  | ComprehensiveCellsSectionExpanded
  | TabbedSectionExpanded
  | LoadProfileSectionExpanded
  | ImuSectionExpanded
  | CamerasSectionExpanded
  | BiaxialChartSectionExpanded
  | OptionSectionExpanded;
