import styles from './SideBar.module.scss';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as Logo } from 'Resources/graphics/logo.svg';
import { ReactComponent as IconRewind } from './resources/graphics/icon-rewind.svg';
import { ReactComponent as IconWarning } from './resources/graphics/icon-warning.svg';
import { ReactComponent as IconPerson } from './resources/graphics/icon-person.svg';
import { ReactComponent as IconReport } from './resources/graphics/icon-report.svg';
import { ReactComponent as IconFastForward } from './resources/graphics/icon-fast-forward.svg';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'Services/redux';
import { clearSession, sessionUserSelector } from 'Services/redux/session';
import { useGetConnectionsQuery } from 'Services/redux/sensorStream/activity';
import { usePopup } from 'Hooks/popup/usePopup';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DockWithConnectionType,
  FleetNavigator,
  FleetNavigatorLoader,
  SideBarClock,
  UserMenu,
  ReportMenu,
  VesselWithConnectionType,
} from './components';
import { Dialog } from 'Components/Dialog';
import { Dock, Emitter, Vessel } from 'Models';
import { isDock, isVessel } from 'Helpers/emitter';

export const SIDE_BAR_WIDTH = 205.0;

type SideBarProps = {
  emitters?: Emitter[];
  showsExpandButton: boolean;
  showsCollapseButton: boolean;
  onClickExpandButton: () => void;
  onClickCollapseButton: () => void;
  onClickExpandAlertButton: () => void;
  onClickExpandSettingsButton: () => void;
  settingsIsEnabled: boolean;
};

export const SideBar: React.FC<SideBarProps> = ({
  emitters,
  showsExpandButton,
  showsCollapseButton,
  onClickCollapseButton,
  onClickExpandButton,
  onClickExpandAlertButton,
  onClickExpandSettingsButton,
  settingsIsEnabled,
}) => {
  const vessels = useMemo(
    () => emitters?.filter((e): e is Vessel => isVessel(e)) ?? [],
    [emitters]
  );
  const docks = useMemo(() => emitters?.filter((e): e is Dock => isDock(e)) ?? [], [emitters]);

  const currentUser = useAppSelector(sessionUserSelector);

  const { data: connections, isLoading: isLoadingConnections } = useGetConnectionsQuery();

  const { logout } = useAuth0();
  const [isProcessingLogout, setIsProcessingLogout] = useState(false);

  const dispatch = useAppDispatch();

  const userFullName = useMemo(
    () => [currentUser?.firstName, currentUser?.lastName].filter((a) => a).join(' ') || '-',
    [currentUser?.firstName, currentUser?.lastName]
  );

  const vesselsWithConnectionType: VesselWithConnectionType[] = useMemo(
    () =>
      vessels.map((vessel) => ({
        ...vessel,
        connectionType:
          connections?.find((sensorResponse) => sensorResponse.emitterId === vessel.id)?.type ??
          'unknown',
      })),
    [connections, vessels]
  );

  const docksWithConnectionType: DockWithConnectionType[] = useMemo(
    () =>
      docks.map((dock) => ({
        ...dock,
        connectionType:
          connections?.find((sensorResponse) => sensorResponse.emitterId === dock.id)?.type ??
          'unknown',
      })),
    [connections, docks]
  );

  const [showsConfirmLogout, setShowsConfirmLogout] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const handleOnClickLogout = useCallback(() => {
    setShowsConfirmLogout(true);
  }, []);

  const handleProceedToLogout = useCallback(() => {
    setIsProcessingLogout(true);
    logout({ returnTo: window.location.origin });
    dispatch(clearSession);
  }, [logout, dispatch]);

  const handleCancelLogout = useCallback(() => {
    setShowsConfirmLogout(false);
  }, []);

  const handleOnClickHelp = useCallback(() => {
    setShowHelpDialog(true);
  }, []);

  const handleCancelHelpDialog = useCallback(() => {
    setShowHelpDialog(false);
  }, []);

  const [isUserMenuVisible, setUserMenuToVisible, setUserMenuToNotVisible, userMenuRef, toggleRef] =
    usePopup();

  const [
    isReportMenuVisible,
    setReportMenuToVisible,
    setReportMenuToNotVisible,
    reportMenuRef,
    reportToggleRef,
  ] = usePopup();

  const handleOnClickReportMenu = useCallback(() => {
    if (isReportMenuVisible) {
      setReportMenuToNotVisible();
    } else {
      setReportMenuToVisible();
    }
  }, [isReportMenuVisible, setReportMenuToNotVisible, setReportMenuToVisible]);

  const handleOnClickProfileMenu = useCallback(() => {
    isUserMenuVisible ? setUserMenuToNotVisible() : setUserMenuToVisible();
  }, [isUserMenuVisible, setUserMenuToNotVisible, setUserMenuToVisible]);

  return (
    <div className={styles.sideBar} style={{ width: `${SIDE_BAR_WIDTH / 16}rem` }}>
      <button
        className={clsx(styles.expandButton, { [styles.hidden]: !showsExpandButton })}
        onClick={onClickExpandButton}
      >
        <IconFastForward />
      </button>
      <div className={styles.sideBarHeader}>
        <div className={styles.yellowBar} />
        <div className={styles.blueBar} />
        <div className={styles.logoContainer}>
          <div className={styles.logoContent}>
            <Logo />
            <sub>Early access</sub>
          </div>
        </div>
        <button
          className={clsx(styles.collapseButton, { [styles.hidden]: !showsCollapseButton })}
          onClick={onClickCollapseButton}
        >
          <IconRewind />
        </button>
      </div>
      <div className={styles.sideBarBody}>
        {!emitters || isLoadingConnections ? (
          <FleetNavigatorLoader />
        ) : (
          <FleetNavigator vessels={vesselsWithConnectionType} docks={docksWithConnectionType} />
        )}
      </div>
      <div>
        <div className={styles.sideBarMenu} onClick={onClickExpandAlertButton}>
          <span className={styles.sideBarMenuIcon}>
            <IconWarning />
          </span>
          Alerts
        </div>

        <div className={styles.sideBarMenu} onClick={handleOnClickReportMenu} ref={reportToggleRef}>
          <span className={styles.sideBarMenuIcon}>
            <IconReport />
          </span>
          Insights
          {isReportMenuVisible && (
            <ReportMenu ref={reportMenuRef} onClickItem={handleOnClickReportMenu} />
          )}
        </div>
        <div className={styles.sideBarMenu} onClick={handleOnClickProfileMenu} ref={toggleRef}>
          <span className={styles.sideBarMenuIcon}>
            <IconPerson />
          </span>
          {userFullName}
          {isUserMenuVisible && (
            <UserMenu
              ref={userMenuRef}
              onClickSettings={settingsIsEnabled ? onClickExpandSettingsButton : undefined}
              onClickLogout={handleOnClickLogout}
              onClickHelp={handleOnClickHelp}
            />
          )}
        </div>
        {showsConfirmLogout && (
          <Dialog
            level="info"
            title="Are you sure to log out?"
            message="You will be redirected to the login page."
            cancelButtonTitle="Cancel"
            submitButtonTitle="Log Out"
            onSelectCancel={handleCancelLogout}
            onSelectSubmit={handleProceedToLogout}
            isSubmitting={isProcessingLogout}
          />
        )}

        {showHelpDialog && (
          <Dialog
            level="info"
            title={`Contact Support`}
            message="If you require any assistance or have encountered any issues, please don't hesitate to reach out to us at support@sailplan.com. Our dedicated support team is here to help you promptly and efficiently. Feel free to email us anytime, and we'll be more than happy to assist you."
            cancelButtonTitle="Ok"
            onSelectCancel={handleCancelHelpDialog}
          />
        )}
        <SideBarClock />
      </div>
    </div>
  );
};
