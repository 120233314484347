import { useMemo } from 'react';
import { StyleSheet, Text, View, Link } from '@react-pdf/renderer';
import { ColoredEmitter } from '../../../interfaces';
import { getValueFormatter } from 'Pages/ReportsPage/components/ReportsPageContent/helpers/chart';
import { EntriesByEmitter } from '../../../hooks/useGetEntriesByEmitter';
import { Conversion } from 'Interfaces';
import { exp } from 'array-expression';
import { DEFAULT_MAX_FRACTION_DIGITS } from 'Constants';

type SummaryProps = {
  emissionName: string;
  coloredEmitters: ColoredEmitter[];
  entriesByEmitter: EntriesByEmitter;
  conversion?: Conversion;
};

const styles = StyleSheet.create({
  headerRow: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '11px',
    flexDirection: 'row',
  },
  row: {
    borderTop: '1px dotted lightgray',
    paddingTop: '5px',
    marginTop: '5px',
    width: '100%',
    flexDirection: 'row',
  },
  vesselColumn: {
    width: '27%',
  },
  valueColumn: {
    width: '20%',
  },
  barColumn: {
    width: '53%',
  },
  bar: {
    position: 'relative',
    top: '1px',
    borderRadius: '3px',
    height: '11px',
    backgroundColor: '#f1f1f1',
  },
  barUsed: {
    backgroundColor: '#9e9e9e',
    height: '11px',
  },
  barUsedBold: {
    backgroundColor: '#000000',
  },
  sub: {
    top: '5px',
    fontSize: '8px',
    position: 'relative',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const Summary: React.FC<SummaryProps> = ({
  emissionName,
  coloredEmitters,
  entriesByEmitter,
  conversion,
}) => {
  const entries = useMemo(() => {
    const summedEntries = [];
    for (const { emitter, color } of coloredEmitters) {
      const records = entriesByEmitter[emitter.id];

      let sumValue: number = 0;

      for (const record of records) {
        if (typeof record.value === 'number') {
          if (typeof sumValue === 'number') {
            sumValue += record.value;
          } else {
            sumValue = record.value;
          }
        }
      }

      if (conversion) {
        sumValue = exp(conversion.exp, { value: sumValue }) as number;
      }

      summedEntries.push({
        name: emitter.name,
        id: emitter.id,
        value: sumValue,
        fill: color,
      });
    }
    return summedEntries;
  }, [coloredEmitters, conversion, entriesByEmitter]);

  const maxValue = useMemo(() => {
    let max = 0;
    for (const entry of entries) {
      if (entry.value && entry.value > max) {
        max = entry.value;
      }
    }
    return max;
  }, [entries]);

  const valueFormatter = useMemo(() => {
    return getValueFormatter({
      unit: conversion?.unit ?? 'kg',
      maximumFractionDigits: conversion?.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
    });
  }, [conversion?.maxFractionDigits, conversion?.unit]);

  return (
    <View>
      <View style={styles.headerRow}>
        <View style={styles.vesselColumn}>
          <Text>Vessel</Text>
        </View>
        <View style={[styles.valueColumn, styles.title]}>
          <Text>{emissionName}</Text>
        </View>
        <View style={styles.barColumn}></View>
      </View>
      {entries.map((entry) => (
        <View style={styles.row} key={entry.id}>
          <View style={styles.vesselColumn}>
            <Link src={`#${entry.id}`}>{entry.name}</Link>
          </View>
          <View style={styles.valueColumn}>
            <Text>{entry.value ? valueFormatter(entry.value) : 'N/A'}</Text>
          </View>
          <View style={styles.barColumn}>
            <View style={styles.bar}>
              <View
                style={[styles.barUsed, { width: `${(entry.value / (maxValue || 1)) * 100}%` }]}
              ></View>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};
