import { AbsoluteInterval, IPropertyName } from 'Constants';
import {
  generateProperyByName,
  getFirstPropertyWithName,
  getPropertiesWithNames,
  haveSameName,
} from 'Helpers/sensors';
import { Property, Sensor } from 'Models';
import { getAccessTokenSilently } from 'Services/auth0';
import axios from 'axios';
import { PropertyValue, Sample, SensorRecords } from './interfaces';
import { generateSensorPayloads } from './helpers';
import { urlConfiguration } from 'Config';
import { OptionalTuple } from 'Types/utility';

export interface FetchMultiLatestSampleArgument {
  propertyNames: IPropertyName[];
  sensors: Sensor[];
  interval: AbsoluteInterval;
}

interface FetchMultiLatestSampleOptions {
  includeIsSatcomm: boolean;
  includeErrors: boolean;
}

const DEFAULT_OPTIONS: FetchMultiLatestSampleOptions = {
  includeIsSatcomm: true,
  includeErrors: false,
};

async function fetchMultiLatestSample<Values extends Array<PropertyValue>>(
  { propertyNames, sensors, interval }: FetchMultiLatestSampleArgument,
  options: FetchMultiLatestSampleOptions = DEFAULT_OPTIONS
) {
  console.assert(haveSameName(sensors), 'sensors must have same name');

  const appendedMultiProperties = sensors.map((sensor) => {
    const properties = propertyNames.map((propertyName) =>
      getFirstPropertyWithName(propertyName, sensor)
    );

    if (properties.length > 0) {
      const siblingProperty = properties.find((property) => !!property) as Property;
      const systemTimeProperty = generateProperyByName(siblingProperty, 'systemTime');

      properties.push(systemTimeProperty);

      if (options.includeIsSatcomm) {
        const isSatcommProperty = generateProperyByName(siblingProperty, 'metadata.isSatcomm');
        properties.push(isSatcommProperty);
      }

      if (options.includeErrors) {
        const errorCodesProperty = generateProperyByName(siblingProperty, 'errorCodes');
        properties.push(errorCodesProperty);
      }
    }

    return properties;
  });

  const requestedProperties = appendedMultiProperties.reduce<Property[]>(
    (acc, properties) => acc.concat(properties.filter((p): p is Property => !!p)),
    []
  );

  if (!requestedProperties.length) {
    return null;
  }

  const token = await getAccessTokenSilently();

  const { data: sensorsRecords } = await axios.post<SensorRecords[]>(
    `${urlConfiguration.api}/sensors`,
    generateRequestBody({ properties: requestedProperties, interval }),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const multiLatestSample = sensors.map((sensor, index) => {
    const record = sensorsRecords.find((sensorRecords) => sensorRecords.sensorId === sensor.id)
      ?.records?.[0];

    if (!record) {
      return null;
    }

    const appendedProperties = appendedMultiProperties[index];

    return appendedProperties.map((p) => p && record[p.name.hash]) as Sample<OptionalTuple<Values>>;
  });

  return multiLatestSample;
}

fetchMultiLatestSample.generateQueryKey = ({
  propertyNames,
  sensors,
  interval,
}: FetchMultiLatestSampleArgument) => {
  const properties = getPropertiesWithNames(propertyNames, sensors);

  const propertiesHash = properties
    .map((p) => p.hash)
    .sort()
    .join();

  return [
    'multiLatestSamples',
    propertiesHash,
    { earliest: interval.earliest, latest: interval.latest },
  ];
};

function generateRequestBody({
  properties,
  interval,
}: {
  properties: Property[];
  interval: AbsoluteInterval;
}) {
  return {
    earliest: interval.earliest,
    latest: interval.latest,
    aggregation: 'first',
    sensors: generateSensorPayloads(properties),
  };
}

export { fetchMultiLatestSample };
