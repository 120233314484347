import style from './AlertsBar.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { Emitter } from 'Models';
import { useGetAlertsCountsQuery } from 'Services/redux/alerts/alerts';
import { useGetEmittersQuery } from 'Services/redux/api/sensorGroup';
import { AlertsCount } from 'Models';
import { TabNavigatorComprehensiveElement } from 'Components/TabNavigator';
import { AlertsBarContentRead, AlertsBarContentUnread, AlertsBarHeader } from './components';

export type DisplayMode = 'unread' | 'read';

const EMPTY_EMITTERS: Emitter[] = [];
const EMPTY_ALERTS_COUNTS: AlertsCount[] = [];

type AlertBarProps = {
  onClickCollapseButton: () => void;
};

type Tab = TabNavigatorComprehensiveElement & { displayMode: DisplayMode };

export const AlertsBar: React.FC<AlertBarProps> = ({ onClickCollapseButton }) => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>('unread');

  const [visualUnreadAlertCount, setVisualUnreadAlertCount] = useState(0);

  const { data: emitters = EMPTY_EMITTERS } = useGetEmittersQuery();

  const { data: unreadAlertsCounts = EMPTY_ALERTS_COUNTS } = useGetAlertsCountsQuery(
    {
      emitters,
    },
    { skip: !emitters.length }
  );

  const [tabs, setTabs] = useState<Tab[]>([
    {
      title: 'Unread',
      badge: undefined,
      displayMode: 'unread',
    },
    {
      title: 'Read',
      displayMode: 'read',
    },
  ]);

  useEffect(() => {
    const total = unreadAlertsCounts.reduce((acc, curr) => acc + curr.count, 0);
    setVisualUnreadAlertCount(total);
  }, [unreadAlertsCounts]);

  useEffect(() => {
    setTabs((previousTabs) =>
      previousTabs.map((tab) =>
        tab.displayMode === 'unread'
          ? { ...tab, badge: visualUnreadAlertCount ? String(visualUnreadAlertCount) : undefined }
          : tab
      )
    );
  }, [visualUnreadAlertCount]);

  const handleSelectTab = useCallback(
    (selectedIndex: number) => {
      const displayMode = tabs[selectedIndex].displayMode;

      setDisplayMode(displayMode);
    },
    [tabs]
  );

  const handleDismissAlert = useCallback(() => {
    setVisualUnreadAlertCount((prev) => Math.max(prev - 1, 0));
  }, []);

  return (
    <div className={style.alertsBar}>
      <AlertsBarHeader
        tabs={tabs}
        onSelectTab={handleSelectTab}
        onClickCollapseButton={onClickCollapseButton}
      />
      {displayMode === 'unread' ? (
        <AlertsBarContentUnread onDismissAlert={handleDismissAlert} />
      ) : (
        <AlertsBarContentRead />
      )}
    </div>
  );
};
