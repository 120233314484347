import { Socket } from 'socket.io-client';
import { Property } from 'Models';
import { Subscription } from './Subscription';
import { MessageDispatcher } from './MessageDispatcher';
import {
  EventEmitterPayload,
  EventHandler,
  PropertySubscription,
  SensorUpdateMessagePayload,
} from './interfaces';
import { PropertyValue } from 'Services/redux/api/sensor';
import { getUniqueProperties } from 'Services/redux/api/sensor/util';
import { get } from 'lodash';

/**
 * When we unsubscribed from a property, we want to
 * retain subscription to that property for a while.
 * A component can be unmounted and mounted back.
 * During this scenario, another subscribe socket
 * message is not needed.
 *
 * This constant specify how long property
 * subscription will be retained after it was
 * unsubscribed.
 */
const PROPERTY_SUBSCRIPTION_KEEP_ALIVE_INTERVAL = 60000;

export const DEFAULT_ACCEPT_INCOMING_MESSAGES = true;

interface PropertySubscriptions {
  [propertyHash: Property['hash']]: PropertySubscription;
}

interface EventHandlers {
  [eventName: Property['sensorName']]: EventHandler;
}
export class SubscriptionManager {
  private activeSubscriptions: Subscription[] = [];
  private propertySubscriptions: PropertySubscriptions = {};
  private messageDispatcher: MessageDispatcher;
  private eventHandlers: EventHandlers = {};

  private _acceptIncomingMessages = DEFAULT_ACCEPT_INCOMING_MESSAGES;

  /**
   * Create SubscriptionManager instance.
   *
   * @param socket - Socket to perform subscription
   * and unsubscription
   */
  constructor(readonly socket: Socket, private onNoSubscribedProperties: () => void) {
    this.messageDispatcher = new MessageDispatcher(socket);
  }

  /**
   * Setting this variable to `false` will stop
   * `SubscriptionManager` from processing incoming
   * messages.
   */
  get acceptIncomingMessages() {
    return this._acceptIncomingMessages;
  }
  set acceptIncomingMessages(value: boolean) {
    if (this._acceptIncomingMessages !== value) {
      this._acceptIncomingMessages = value;
      this.buildEventHandlers();
    }
  }

  /**
   * Get whether there's an active subscription.
   *
   * @returns `true` it there's active subscription,
   * `false` otherwise.
   */
  get hasSubscribedProperties() {
    return Object.keys(this.propertySubscriptions).length !== 0;
  }

  /**
   * Subscribe to properties
   *
   * @remark
   * Calling this method will not send subscribe socket
   * message immediately. Messages will be dispatched
   * through queue.
   *
   * @param properties - Properties to subscribe
   * @returns `Subscription`
   */
  subscribe(properties: Property[]): Subscription {
    const uniqueProperties = getUniqueProperties(properties);

    const subscribedProperties = Object.values(this.propertySubscriptions).map((s) => s.property);

    const nonSubscribedProperties = uniqueProperties.filter(
      (p) => !subscribedProperties.some((asp) => asp.hash === p.hash)
    );

    uniqueProperties.forEach(this.cancelScheduleUnsubscribeFromProperty.bind(this));

    for (const property of nonSubscribedProperties) {
      const propertySubscription = this.propertySubscriptions[property.hash];

      if (propertySubscription) {
        propertySubscription.numberOfSubscriptions += 1;
      } else {
        this.createPropertySubscription(property);
      }
    }

    const subscription = new Subscription(uniqueProperties);

    this.activeSubscriptions.push(subscription);
    this.messageDispatcher.queueSendSubscribeMessage(nonSubscribedProperties);

    this.buildEventHandlers();

    return subscription;
  }

  /**
   * Resubscribe to subscribed properties
   *
   * @remark
   * This method will find the current subscribed properties
   * and send subscribe socket message for those properties.
   * This can be useful when the client is disconnected and
   * we the client needs to resend the subscribe socket
   * message.
   */
  resubscribe() {
    const subscribedProperties = getUniqueProperties(
      this.activeSubscriptions.reduce<Property[]>((acc, s) => acc.concat(s._properties), [])
    );

    this.messageDispatcher.queueSendSubscribeMessage(subscribedProperties);
  }

  /**
   * Cancel subscription.
   *
   * @remark
   * Calling this method will not send unsubscribe socket
   * message immediately.
   * Only when a property subscribers counter goes to 0,
   * the unsubscribe socket message will be sent through
   * queue for that property.
   *
   * @param subscription - Subscription to cancel
   */
  unsubscribe(subscription: Subscription) {
    const subscriptionIndex = this.activeSubscriptions.indexOf(subscription);

    if (subscriptionIndex === -1) return;

    this.activeSubscriptions.splice(subscriptionIndex, 1);

    for (const property of subscription._properties) {
      const propertySubscription = this.propertySubscriptions[property.hash];

      if (propertySubscription) {
        if (propertySubscription.numberOfSubscriptions > 1) {
          propertySubscription.numberOfSubscriptions -= 1;
        } else {
          propertySubscription.numberOfSubscriptions = 0;
          this.scheduleUnsubscribeFromProperty(property);
        }
      }
    }
  }

  /**
   * Emit message to subscribers.
   *
   * @param eventName - Event name
   * @param payload - Payload for the message
   */
  emitMessageToSubscribers(eventName: Property['sensorName'], payload: SensorUpdateMessagePayload) {
    for (const subscription of this.activeSubscriptions) {
      if (subscription.interestedInEvent(eventName)) {
        const eventEmitterPayload = this.getEventEmitterPayload(subscription, payload);
        if (eventEmitterPayload) subscription.emit(eventEmitterPayload);
      }
    }
  }

  /**
   * Schedule unsubscribe from property
   *
   * @param property - Property to unsubscribe
   */
  private scheduleUnsubscribeFromProperty(property: Property) {
    const propertySubscription = this.getPropertySubscription(property);

    if (propertySubscription && propertySubscription.unsubscribeTimeout === undefined) {
      propertySubscription.unsubscribeTimeout = setTimeout(() => {
        this.unsubscribeFromPropertyIfNeeded(property);
      }, PROPERTY_SUBSCRIPTION_KEEP_ALIVE_INTERVAL);
    }
  }

  /**
   * Cancel schedule unsubscribe from property.
   *
   * @param property - Property for cancelling unsubscribe
   */
  private cancelScheduleUnsubscribeFromProperty(property: Property) {
    const propertySubscription = this.getPropertySubscription(property);

    if (propertySubscription?.unsubscribeTimeout) {
      clearTimeout(propertySubscription.unsubscribeTimeout);
      propertySubscription.unsubscribeTimeout = undefined;
    }
  }

  /**
   * Unsubscribe from property if needed
   *
   * @remark
   * This method will only unsubscribe if number of
   * subscribers for the property is 0.
   *
   * @param property - Property to unsubscribe
   */
  private unsubscribeFromPropertyIfNeeded(property: Property) {
    const propertySubscription = this.getPropertySubscription(property);

    if (!propertySubscription || propertySubscription.numberOfSubscriptions > 0) return;

    delete this.propertySubscriptions[property.hash];

    this.buildEventHandlers();

    this.messageDispatcher.queueSendUnsubscribeMessage([property]);

    if (!this.hasSubscribedProperties) this.onNoSubscribedProperties();
  }

  /**
   * Get property subscription.
   *
   * @param property - Property for property subscription
   * @returns `PropertySubscription` or `undefined`
   */
  private getPropertySubscription(property: Property): PropertySubscription | undefined {
    return this.propertySubscriptions[property.hash];
  }

  /**
   * Create property subscription
   * @param property - Property for property subscription
   * @returns `PropertySubscription`
   */
  private createPropertySubscription(property: Property): PropertySubscription {
    return (this.propertySubscriptions[property.hash] = { property, numberOfSubscriptions: 1 });
  }

  /**
   * Build the necessary socket event handlers.
   */
  private buildEventHandlers() {
    if (!this.acceptIncomingMessages) {
      Object.keys(this.eventHandlers).forEach(this.stopHandlingSocketEventsForEventName.bind(this));
      return;
    }

    const propertySubscriptions = Object.values(this.propertySubscriptions);

    type SensorName = Property['sensorName'];

    const eventNames: SensorName[] = Object.keys(
      propertySubscriptions.reduce<{ [sensorName: SensorName]: true }>(
        (acc, subscription) => ({ ...acc, [subscription.property.sensorName]: true }),
        {}
      )
    );

    const existingEventNames = Object.keys(this.eventHandlers);

    const eventNamesToHandle: SensorName[] = eventNames.filter(
      (n) => !existingEventNames.includes(n)
    );
    const eventNamesToUnhandle: SensorName[] = existingEventNames.filter(
      (n) => !eventNames.includes(n)
    );

    eventNamesToUnhandle.forEach(this.stopHandlingSocketEventsForEventName.bind(this));
    eventNamesToHandle.forEach(this.startHandlingSocketEventsForEventName.bind(this));
  }

  /**
   * Start handling to socket events for sensor name.
   *
   * @param eventName
   */
  private startHandlingSocketEventsForEventName(eventName: Property['sensorName']) {
    if (this.eventHandlers[eventName]) return;

    const eventHandler: EventHandler = (payload: SensorUpdateMessagePayload) => {
      if ((payload.errorCodes ?? []).length === 0) {
        this.emitMessageToSubscribers(eventName, payload);
      }
    };

    this.socket.on(eventName, (this.eventHandlers[eventName] = eventHandler));
  }

  /**
   * Stop handling to socket events for sensor name.
   *
   * @param eventName
   */
  private stopHandlingSocketEventsForEventName(eventName: Property['sensorName']) {
    if (!this.eventHandlers[eventName]) return;

    this.socket.off(eventName, this.eventHandlers[eventName]);
    delete this.eventHandlers[eventName];
  }

  /**
   * Get event emitter payload.
   *
   * @param subscription
   * @param payload
   * @returns `EventEmitterPayload` | `undefined`
   */
  private getEventEmitterPayload(
    subscription: Subscription,
    payload: SensorUpdateMessagePayload
  ): EventEmitterPayload | undefined {
    const ret: EventEmitterPayload = {};

    for (const property of subscription._properties) {
      const propertyName = property.name.name;

      if (property.sensorId === payload.metadata.sensorId) {
        const value = get(payload, propertyName) as PropertyValue;

        if (value !== undefined) {
          ret[property.hash] = value;
        }
      }
    }

    return !Object.keys(ret).length ? undefined : ret;
  }
}
