import styles from './LoadingPage.module.scss';
import Lottie from 'lottie-react';
import containerShipAnimation from './resources/json/container-ship-animation.json';

export const LoadingPage: React.FC = () => {
  return (
    <div className={styles.loadingPage}>
      <div className={styles.animationContainer}>
        <Lottie animationData={containerShipAnimation} loop={true} />
      </div>
    </div>
  );
};
