const densifyAnchorPoint = new Date('2000-01-01T00:00:00.000Z').getTime();

export const removeNulls = <S>(value: S | undefined): value is S => value != null;

export interface TimeseriesEntry {
  timestamp: string;
}

export interface NumberValueEntry {
  value: number | null;
}

type IntervalFilterOpts = {
  earliest: string;
  latest?: string;
};

export const filterByTimeRange = <T extends TimeseriesEntry>(
  entries: T[],
  opts: IntervalFilterOpts
) => {
  if (opts.latest) {
    const latest = opts.latest;
    return entries.filter((e: T) => e.timestamp >= opts.earliest && e.timestamp <= latest);
  } else {
    return entries.filter((e: T) => e.timestamp >= opts.earliest);
  }
};

function getAnchoredTimestamp(timestamp: string, intervalMs: number) {
  let currentTimestamp = new Date(timestamp).getTime();

  const diff = currentTimestamp - densifyAnchorPoint;

  return new Date(densifyAnchorPoint + intervalMs * Math.floor(diff / intervalMs));
}

export const densify = <T extends TimeseriesEntry>(entries: T[], intervalMs: number): T[] => {
  if (entries.length <= 1) return entries;

  const result: T[] = [];

  let currentTimestamp = getAnchoredTimestamp(entries[0].timestamp, intervalMs).getTime();

  const lastTimestamp = new Date(entries[entries.length - 1].timestamp).getTime();

  while (currentTimestamp <= lastTimestamp) {
    const timestamp = new Date(currentTimestamp).toISOString();
    const match = entries.find((entry) => entry.timestamp === timestamp);
    result.push(
      match
        ? match
        : ({
            timestamp,
          } as T)
    );
    currentTimestamp += intervalMs;
  }

  return result;
};

type KeyFunction<T> = (entry: T) => string;
type AggregationFunction<T> = (entry: T[]) => T | undefined;

type GroupOpts<T> = {
  keyFn: KeyFunction<T>;
  aggregationFn?: AggregationFunction<T>;
};

export const group = <T>(entries: T[], opts: GroupOpts<T>) => {
  const out: Record<string, T[]> = {};
  for (const e of entries) {
    const key = opts.keyFn(e);
    if (out[key]) {
      if (opts.aggregationFn) {
        out[key] = [opts.aggregationFn(out[key].concat(e))].filter(removeNulls);
      } else {
        out[key].push(e);
      }
    } else {
      out[key] = [e];
    }
  }
  return out;
};

//Use part of a datetime string as a grouping key
const dateTrunc =
  <T extends TimeseriesEntry>(intervalMs: number): KeyFunction<T> =>
  (entry: T) => {
    return getAnchoredTimestamp(entry.timestamp, intervalMs).toISOString();
  };

// Take the first item in the group as the final result of the aggregation
const first = <T>(entries: T[]) => {
  return entries.at(0);
};

// Take the average of all items in the group as the final result of the aggregation
const avg = (entries: NumberValueEntry[]) => {
  const outEntry = entries.at(0) ? { ...entries.at(0) } : undefined;

  if (!outEntry) return null;

  for (const e of entries) {
    if (typeof e.value === 'number') {
      if (typeof outEntry.value === 'number') {
        outEntry.value = (outEntry.value + e.value) / 2;
      } else {
        outEntry.value = e.value;
      }
    }
  }

  return outEntry;
};

export const keyFns = {
  dateTrunc,
};

export const aggregationFns = {
  first,
  avg,
};
