import { Emitter, Sensor } from 'Models';
import { SensorName } from 'Constants';
import { SensorUpdateMessagePayload } from '../interfaces';
import { BaseMessageTest } from '../BaseMessageTest';
import { getFirstSensorWithName } from 'Helpers/sensors';
import { NodeServerObserver } from '..';

type DerivedEmissionSensor = Sensor<typeof SensorName.DerivedEmission.name>;

export class DerivedEmissionMessageTest extends BaseMessageTest {
  constructor(protected observer: NodeServerObserver, private emitters: Emitter[]) {
    super(SensorName.DerivedEmission.name, observer, 3000);
  }

  protected getMessagePayloads(cycleCount: number): SensorUpdateMessagePayload[] {
    return this.emitters.reduce<SensorUpdateMessagePayload[]>((acc, emitter) => {
      const derivedEmissionSensor = getFirstSensorWithName(SensorName.DerivedEmission, emitter);

      return derivedEmissionSensor
        ? acc.concat(this.generatePayloadForEmitter(derivedEmissionSensor, emitter, cycleCount))
        : acc;
    }, []);
  }

  private generatePayloadForEmitter(
    sensor: DerivedEmissionSensor,
    emitter: Emitter,
    cycleCount: number
  ): SensorUpdateMessagePayload {
    return {
      metadata: { sensorId: sensor.id, ns: emitter.namespace, isSatcomm: false },
      latitude: emitter.coordinate && emitter.coordinate.latitude + cycleCount * 0.0007,
      longitude: emitter.coordinate && emitter.coordinate.longitude + cycleCount * 0.0007,
      H2OAverage: Math.random() * 50.0,
      CO2Average: Math.random() * 100.0,
      CH4DualAverage: Math.random() * 100.0,
      NOXDualAverage: Math.random() * 100.0,
      SO2Average: Math.random() * 100.0,
      score: Math.random() * 12.0,
      systemTime: new Date().toISOString(),
    };
  }
}
