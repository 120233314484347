import { Ais, Alert, Dock, Vessel } from 'Models';
import { MapElement, MapFeature } from '../interfaces';

export const getElement = (propertiesElement: MapFeature['properties']['element']) => {
  return typeof propertiesElement === 'string'
    ? (JSON.parse(propertiesElement) as MapElement)
    : propertiesElement;
};

export const isVesselElement = (
  element: MapElement
): element is { value: Vessel; type: 'vessel' } => {
  return element.type === 'vessel';
};

export const isAlertElement = (element: MapElement): element is { value: Alert; type: 'alert' } => {
  return element.type === 'alert';
};

export const isDockElement = (element: MapElement): element is { value: Dock; type: 'dock' } => {
  return element.type === 'dock';
};

export const isAisElement = (element: MapElement): element is { value: Ais; type: 'ais' } => {
  return element.type === 'ais';
};

export const areSameElement = (firstElement: MapElement, secondElement: MapElement) => {
  return (
    firstElement.type === secondElement.type && firstElement.value.id === secondElement.value.id
  );
};
