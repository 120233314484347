import styles from './Flag.module.scss';
import { ReactComponent as BD } from './BD.svg';
import { ReactComponent as BE } from './BE.svg';
import { ReactComponent as BF } from './BF.svg';
import { ReactComponent as BG } from './BG.svg';
import { ReactComponent as BA } from './BA.svg';
import { ReactComponent as BB } from './BB.svg';
import { ReactComponent as BM } from './BM.svg';
import { ReactComponent as BN } from './BN.svg';
import { ReactComponent as BO } from './BO.svg';
import { ReactComponent as BH } from './BH.svg';
import { ReactComponent as BI } from './BI.svg';
import { ReactComponent as BJ } from './BJ.svg';
import { ReactComponent as BT } from './BT.svg';
import { ReactComponent as JM } from './JM.svg';
import { ReactComponent as BW } from './BW.svg';
import { ReactComponent as WS } from './WS.svg';
import { ReactComponent as BR } from './BR.svg';
import { ReactComponent as BS } from './BS.svg';
import { ReactComponent as JE } from './JE.svg';
import { ReactComponent as BY } from './BY.svg';
import { ReactComponent as BZ } from './BZ.svg';
import { ReactComponent as RU } from './RU.svg';
import { ReactComponent as RW } from './RW.svg';
import { ReactComponent as RS } from './RS.svg';
import { ReactComponent as TL } from './TL.svg';
import { ReactComponent as TM } from './TM.svg';
import { ReactComponent as TJ } from './TJ.svg';
import { ReactComponent as RO } from './RO.svg';
import { ReactComponent as GW } from './GW.svg';
import { ReactComponent as GT } from './GT.svg';
import { ReactComponent as GR } from './GR.svg';
import { ReactComponent as GQ } from './GQ.svg';
import { ReactComponent as JP } from './JP.svg';
import { ReactComponent as GY } from './GY.svg';
import { ReactComponent as GG } from './GG.svg';
import { ReactComponent as GE } from './GE.svg';
import { ReactComponent as GD } from './GD.svg';
import { ReactComponent as GB } from './GB.svg';
import { ReactComponent as GA } from './GA.svg';
import { ReactComponent as SV } from './SV.svg';
import { ReactComponent as GN } from './GN.svg';
import { ReactComponent as GM } from './GM.svg';
import { ReactComponent as GI } from './GI.svg';
import { ReactComponent as GH } from './GH.svg';
import { ReactComponent as OM } from './OM.svg';
import { ReactComponent as TN } from './TN.svg';
import { ReactComponent as JO } from './JO.svg';
import { ReactComponent as HR } from './HR.svg';
import { ReactComponent as HT } from './HT.svg';
import { ReactComponent as HU } from './HU.svg';
import { ReactComponent as HK } from './HK.svg';
import { ReactComponent as HN } from './HN.svg';
import { ReactComponent as VE } from './VE.svg';
import { ReactComponent as PR } from './PR.svg';
import { ReactComponent as PW } from './PW.svg';
import { ReactComponent as PT } from './PT.svg';
import { ReactComponent as PY } from './PY.svg';
import { ReactComponent as IQ } from './IQ.svg';
import { ReactComponent as PA } from './PA.svg';
import { ReactComponent as PF } from './PF.svg';
import { ReactComponent as PG } from './PG.svg';
import { ReactComponent as PE } from './PE.svg';
import { ReactComponent as PK } from './PK.svg';
import { ReactComponent as PH } from './PH.svg';
import { ReactComponent as PL } from './PL.svg';
import { ReactComponent as ZM } from './ZM.svg';
import { ReactComponent as EE } from './EE.svg';
import { ReactComponent as EG } from './EG.svg';
import { ReactComponent as ZA } from './ZA.svg';
import { ReactComponent as EC } from './EC.svg';
import { ReactComponent as IT } from './IT.svg';
import { ReactComponent as VN } from './VN.svg';
import { ReactComponent as SB } from './SB.svg';
import { ReactComponent as ET } from './ET.svg';
import { ReactComponent as SO } from './SO.svg';
import { ReactComponent as ZW } from './ZW.svg';
import { ReactComponent as SA } from './SA.svg';
import { ReactComponent as ES } from './ES.svg';
import { ReactComponent as ER } from './ER.svg';
import { ReactComponent as ME } from './ME.svg';
import { ReactComponent as MD } from './MD.svg';
import { ReactComponent as MG } from './MG.svg';
import { ReactComponent as MA } from './MA.svg';
import { ReactComponent as MC } from './MC.svg';
import { ReactComponent as UZ } from './UZ.svg';
import { ReactComponent as MM } from './MM.svg';
import { ReactComponent as ML } from './ML.svg';
import { ReactComponent as MO } from './MO.svg';
import { ReactComponent as MN } from './MN.svg';
import { ReactComponent as MK } from './MK.svg';
import { ReactComponent as MU } from './MU.svg';
import { ReactComponent as MT } from './MT.svg';
import { ReactComponent as MW } from './MW.svg';
import { ReactComponent as MV } from './MV.svg';
import { ReactComponent as MS } from './MS.svg';
import { ReactComponent as MR } from './MR.svg';
import { ReactComponent as IM } from './IM.svg';
import { ReactComponent as UG } from './UG.svg';
import { ReactComponent as TZ } from './TZ.svg';
import { ReactComponent as MY } from './MY.svg';
import { ReactComponent as MX } from './MX.svg';
import { ReactComponent as IL } from './IL.svg';
import { ReactComponent as FR } from './FR.svg';
import { ReactComponent as SH } from './SH.svg';
import { ReactComponent as FI } from './FI.svg';
import { ReactComponent as FJ } from './FJ.svg';
import { ReactComponent as FK } from './FK.svg';
import { ReactComponent as FM } from './FM.svg';
import { ReactComponent as NI } from './NI.svg';
import { ReactComponent as NL } from './NL.svg';
import { ReactComponent as NO } from './NO.svg';
import { ReactComponent as NA } from './NA.svg';
import { ReactComponent as VU } from './VU.svg';
import { ReactComponent as NE } from './NE.svg';
import { ReactComponent as NG } from './NG.svg';
import { ReactComponent as NZ } from './NZ.svg';
import { ReactComponent as NP } from './NP.svg';
import { ReactComponent as CI } from './CI.svg';
import { ReactComponent as CH } from './CH.svg';
import { ReactComponent as CO } from './CO.svg';
import { ReactComponent as CN } from './CN.svg';
import { ReactComponent as CM } from './CM.svg';
import { ReactComponent as CL } from './CL.svg';
import { ReactComponent as CA } from './CA.svg';
import { ReactComponent as CG } from './CG.svg';
import { ReactComponent as CF } from './CF.svg';
import { ReactComponent as CD } from './CD.svg';
import { ReactComponent as CZ } from './CZ.svg';
import { ReactComponent as CY } from './CY.svg';
import { ReactComponent as CR } from './CR.svg';
import { ReactComponent as CV } from './CV.svg';
import { ReactComponent as CU } from './CU.svg';
import { ReactComponent as SZ } from './SZ.svg';
import { ReactComponent as SY } from './SY.svg';
import { ReactComponent as KG } from './KG.svg';
import { ReactComponent as KE } from './KE.svg';
import { ReactComponent as SR } from './SR.svg';
import { ReactComponent as KH } from './KH.svg';
import { ReactComponent as KN } from './KN.svg';
import { ReactComponent as KM } from './KM.svg';
import { ReactComponent as ST } from './ST.svg';
import { ReactComponent as SK } from './SK.svg';
import { ReactComponent as KR } from './KR.svg';
import { ReactComponent as SI } from './SI.svg';
import { ReactComponent as KP } from './KP.svg';
import { ReactComponent as KW } from './KW.svg';
import { ReactComponent as SN } from './SN.svg';
import { ReactComponent as SM } from './SM.svg';
import { ReactComponent as SL } from './SL.svg';
import { ReactComponent as SC } from './SC.svg';
import { ReactComponent as KZ } from './KZ.svg';
import { ReactComponent as KY } from './KY.svg';
import { ReactComponent as SG } from './SG.svg';
import { ReactComponent as SE } from './SE.svg';
import { ReactComponent as SD } from './SD.svg';
import { ReactComponent as DO } from './DO.svg';
import { ReactComponent as DM } from './DM.svg';
import { ReactComponent as DJ } from './DJ.svg';
import { ReactComponent as DK } from './DK.svg';
import { ReactComponent as VG } from './VG.svg';
import { ReactComponent as DE } from './DE.svg';
import { ReactComponent as YE } from './YE.svg';
import { ReactComponent as DZ } from './DZ.svg';
import { ReactComponent as US } from './US.svg';
import { ReactComponent as UY } from './UY.svg';
import { ReactComponent as LB } from './LB.svg';
import { ReactComponent as LC } from './LC.svg';
import { ReactComponent as LA } from './LA.svg';
import { ReactComponent as TW } from './TW.svg';
import { ReactComponent as TT } from './TT.svg';
import { ReactComponent as TR } from './TR.svg';
import { ReactComponent as LK } from './LK.svg';
import { ReactComponent as LI } from './LI.svg';
import { ReactComponent as LV } from './LV.svg';
import { ReactComponent as TO } from './TO.svg';
import { ReactComponent as LT } from './LT.svg';
import { ReactComponent as LU } from './LU.svg';
import { ReactComponent as LR } from './LR.svg';
import { ReactComponent as LS } from './LS.svg';
import { ReactComponent as TH } from './TH.svg';
import { ReactComponent as TG } from './TG.svg';
import { ReactComponent as TD } from './TD.svg';
import { ReactComponent as TC } from './TC.svg';
import { ReactComponent as LY } from './LY.svg';
import { ReactComponent as VC } from './VC.svg';
import { ReactComponent as AE } from './AE.svg';
import { ReactComponent as AD } from './AD.svg';
import { ReactComponent as AG } from './AG.svg';
import { ReactComponent as AF } from './AF.svg';
import { ReactComponent as AI } from './AI.svg';
import { ReactComponent as IS } from './IS.svg';
import { ReactComponent as IR } from './IR.svg';
import { ReactComponent as AM } from './AM.svg';
import { ReactComponent as AL } from './AL.svg';
import { ReactComponent as AO } from './AO.svg';
import { ReactComponent as AR } from './AR.svg';
import { ReactComponent as AU } from './AU.svg';
import { ReactComponent as AT } from './AT.svg';
import { ReactComponent as AW } from './AW.svg';
import { ReactComponent as IN } from './IN.svg';
import { ReactComponent as AX } from './AX.svg';
import { ReactComponent as AZ } from './AZ.svg';
import { ReactComponent as IE } from './IE.svg';
import { ReactComponent as ID } from './ID.svg';
import { ReactComponent as UA } from './UA.svg';
import { ReactComponent as QA } from './QA.svg';
import { ReactComponent as MZ } from './MZ.svg';

const FlagIcon = {
  BGD: BD,
  BEL: BE,
  BFA: BF,
  BGR: BG,
  BIH: BA,
  BRB: BB,
  BMU: BM,
  BRN: BN,
  BOL: BO,
  BHR: BH,
  BDI: BI,
  BEN: BJ,
  BTN: BT,
  JAM: JM,
  BWA: BW,
  WSM: WS,
  BRA: BR,
  BHS: BS,
  JEY: JE,
  BLR: BY,
  BLZ: BZ,
  RUS: RU,
  RWA: RW,
  SRB: RS,
  TLS: TL,
  TKM: TM,
  TJK: TJ,
  ROU: RO,
  GNB: GW,
  GTM: GT,
  GRC: GR,
  GNQ: GQ,
  JPN: JP,
  GUY: GY,
  GGY: GG,
  GEO: GE,
  GRD: GD,
  GBR: GB,
  GAB: GA,
  SLV: SV,
  GIN: GN,
  GMB: GM,
  GIB: GI,
  GHA: GH,
  OMN: OM,
  TUN: TN,
  JOR: JO,
  HRV: HR,
  HTI: HT,
  HUN: HU,
  HKG: HK,
  HND: HN,
  VEN: VE,
  PRI: PR,
  PLW: PW,
  PRT: PT,
  PRY: PY,
  IRQ: IQ,
  PAN: PA,
  PYF: PF,
  PNG: PG,
  PER: PE,
  PAK: PK,
  PHL: PH,
  POL: PL,
  ZMB: ZM,
  EST: EE,
  EGY: EG,
  ZAF: ZA,
  ECU: EC,
  ITA: IT,
  VNM: VN,
  SLB: SB,
  ETH: ET,
  SOM: SO,
  ZWE: ZW,
  SAU: SA,
  ESP: ES,
  ERI: ER,
  MNE: ME,
  MDA: MD,
  MDG: MG,
  MAR: MA,
  MCO: MC,
  UZB: UZ,
  MMR: MM,
  MLI: ML,
  MAC: MO,
  MNG: MN,
  MKD: MK,
  MUS: MU,
  MLT: MT,
  MWI: MW,
  MDV: MV,
  MSR: MS,
  MRT: MR,
  IMN: IM,
  UGA: UG,
  TZA: TZ,
  MYS: MY,
  MEX: MX,
  ISR: IL,
  FRA: FR,
  SHN: SH,
  FIN: FI,
  FJI: FJ,
  FLK: FK,
  FSM: FM,
  NIC: NI,
  NLD: NL,
  NOR: NO,
  NAM: NA,
  VUT: VU,
  NER: NE,
  NGA: NG,
  NZL: NZ,
  NPL: NP,
  CIV: CI,
  CHE: CH,
  COL: CO,
  CHN: CN,
  CMR: CM,
  CHL: CL,
  CAN: CA,
  COG: CG,
  CAF: CF,
  COD: CD,
  CZE: CZ,
  CYP: CY,
  CRI: CR,
  CPV: CV,
  CUB: CU,
  SWZ: SZ,
  SYR: SY,
  KGZ: KG,
  KEN: KE,
  SUR: SR,
  KHM: KH,
  KNA: KN,
  COM: KM,
  STP: ST,
  SVK: SK,
  KOR: KR,
  SVN: SI,
  PRK: KP,
  KWT: KW,
  SEN: SN,
  SMR: SM,
  SLE: SL,
  SYC: SC,
  KAZ: KZ,
  CYM: KY,
  SGP: SG,
  SWE: SE,
  SDN: SD,
  DOM: DO,
  DMA: DM,
  DJI: DJ,
  DNK: DK,
  VGB: VG,
  DEU: DE,
  YEM: YE,
  DZA: DZ,
  USA: US,
  URY: UY,
  LBN: LB,
  LCA: LC,
  LAO: LA,
  TWN: TW,
  TTO: TT,
  TUR: TR,
  LKA: LK,
  LIE: LI,
  LVA: LV,
  TON: TO,
  LTU: LT,
  LUX: LU,
  LBR: LR,
  LSO: LS,
  THA: TH,
  TGO: TG,
  TCD: TD,
  TCA: TC,
  LBY: LY,
  VCT: VC,
  ARE: AE,
  AND: AD,
  ATG: AG,
  AFG: AF,
  AIA: AI,
  ISL: IS,
  IRN: IR,
  ARM: AM,
  ALB: AL,
  AGO: AO,
  ARG: AR,
  AUS: AU,
  AUT: AT,
  ABW: AW,
  IND: IN,
  ALA: AX,
  AZE: AZ,
  IRL: IE,
  IDN: ID,
  UKR: UA,
  QAT: QA,
  MOZ: MZ,
};

export type Iso3166Part1Alpha2Identifier = keyof typeof FlagIcon;

type FlagProps = {
  /** ISO 3166-1 alpha 2 identifier */
  identifier: Iso3166Part1Alpha2Identifier;
};

export const Flag: React.FC<FlagProps> = ({ identifier }) => {
  const Icon = FlagIcon[identifier];

  return (
    <span className={styles.flag}>
      {Icon ? <Icon /> : <span className={styles.flagPlaceholder}>{identifier}</span>}
    </span>
  );
};
