import { useMemo } from 'react';
import { Source, Layer, LayerProps } from 'react-map-gl';
import { FeatureCollection, Point } from 'geojson';
import IconVessel from './resources/graphics/icon-vessel.png';
import { Vessel } from 'Models';
import IconVesselHighlighted from './resources/graphics/icon-vessel-highlighted.png';
import { useMapImage } from '../../../hooks';
import { MapFeature, MapLayerProps } from '../../../interfaces';

const VESSEL_IMAGE_KEY = 'icon-vessel';
const VESSEL_HIGHLIGHTED_IMAGE_KEY = 'icon-vessel-highlighted';

const BASE_LAYER_PROPS: LayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': ['case', ['get', 'highlighted'], VESSEL_HIGHLIGHTED_IMAGE_KEY, VESSEL_IMAGE_KEY],
    'icon-size': 0.5,
    'icon-allow-overlap': true,
    'icon-rotate': ['get', 'rotation'],
    'symbol-z-order': 'source',
  },
};

const getFeature = (vessel: Vessel, highlighted: boolean): MapFeature | undefined =>
  vessel.coordinate && {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [vessel.coordinate.longitude, vessel.coordinate.latitude],
    },
    id: vessel.id,
    properties: {
      element: JSON.stringify({
        value: vessel,
        type: 'vessel',
      }),
      rotation: vessel.rotation ?? 0,
      highlighted,
    },
  };

type VesselLayerProps = {
  vessels?: Vessel[];
  selectedVessel?: Vessel;
} & MapLayerProps;

export const VesselLayer: React.FC<VesselLayerProps> = ({
  vessels,
  selectedVessel,
  id,
  beforeId,
}) => {
  useMapImage(IconVessel, VESSEL_IMAGE_KEY);
  useMapImage(IconVesselHighlighted, VESSEL_HIGHLIGHTED_IMAGE_KEY);

  const memoizedFeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: (vessels ?? [])
        .sort((a, b) =>
          a.id === selectedVessel?.id && b.id !== selectedVessel?.id
            ? 1
            : a.id !== selectedVessel?.id && b.id === selectedVessel?.id
            ? -1
            : 0
        )
        .map((vessel) => getFeature(vessel, vessel.id === selectedVessel?.id))
        .filter((mf): mf is MapFeature => !!mf),
    } as FeatureCollection<Point>;
  }, [vessels, selectedVessel?.id]);

  const layerProps = { id, ...BASE_LAYER_PROPS };

  if (beforeId) {
    layerProps.beforeId = beforeId;
  }

  return (
    <Source id={id} type="geojson" data={memoizedFeatureCollection}>
      <Layer {...layerProps} />
    </Source>
  );
};
