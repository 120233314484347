import styles from './ContentTable.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { User } from 'Models/user';
import { PuffLoader } from 'react-spinners';
import { Namespace, useDeleteUserMutation, useLazyGetUsersQuery } from 'Services/redux/api/users';
import { ContentRow } from './ContentRow';
import { EditUserModal } from './EditUserModal';
import { Dialog } from 'Components/Dialog';

const PAGE_SIZE = 20;

type ContentTableProps = {
  namespace: string;
  newUser?: User;
  currentUserNamespace: string;
  namespacesAccess: Namespace[];
};

export const ContentTable: React.FC<ContentTableProps> = ({
  newUser,
  namespace,
  currentUserNamespace,
  namespacesAccess,
}) => {
  const [users, setUsers] = useState<User[]>([]);
  const [next, setNext] = useState<string>();
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [getUsersQuery] = useLazyGetUsersQuery();

  const [deleteUser] = useDeleteUserMutation();

  const [scrollableElement, setScrollableElement] = useState<HTMLDivElement>();
  const [lastItem, setLastItem] = useState<HTMLDivElement>();

  const [isEditUserModalShown, setIsEditUserModalShown] = useState(false);

  const [onEditUser, setOnEditUser] = useState<User>();

  const [onDeleteUser, setOnDeleteUser] = useState<User>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnCloseEditUserModal = useCallback(() => {
    setIsEditUserModalShown(false);
    setOnEditUser(undefined);
  }, []);

  const handleOnClickEditUser = useCallback((user: User) => {
    setOnEditUser(user);
    setIsEditUserModalShown(true);
  }, []);

  const handleOnClickSaveEditUser = useCallback(
    (user: User) => {
      setUsers((prevUsers) =>
        prevUsers
          .map((prevUser) => (prevUser.altId === user.altId ? user : prevUser))
          .filter((prevUser) => prevUser.namespace === namespace)
      );
    },
    [namespace]
  );

  const handleShowConfirmDeleteUser = useCallback((user: User) => {
    setOnDeleteUser(user);
    setIsSubmitting(false);
  }, []);

  const handleCancelDeleteUser = useCallback(() => {
    setOnDeleteUser(undefined);
    setIsSubmitting(false);
  }, []);

  const handleDeleteUser = useCallback(
    (user: User): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        setIsSubmitting(true);

        const response = await deleteUser(user);

        if ('error' in response) {
          // will be replaced with redux dispatch error
        } else {
          deleteUserInUsers(user);
          setIsSubmitting(false);
          resolve();
        }
      });
    },
    [deleteUser]
  );

  const handleOnClickDeleteUser = useCallback(async () => {
    if (!onDeleteUser) return;

    await handleDeleteUser(onDeleteUser);

    setOnDeleteUser(undefined);
  }, [handleDeleteUser, onDeleteUser]);

  const deleteUserInUsers = (deletedUser: User) => {
    setUsers((prev) => prev.filter((user) => user.altId !== deletedUser.altId));
  };

  const bodyRef = (element: HTMLDivElement) => {
    setScrollableElement(element);
  };

  const lastRowRef = (element: HTMLDivElement) => {
    setLastItem(element);
  };

  const fetchNextPage = useCallback(() => {
    if (isLoadingNextPage) return;

    setIsLoadingNextPage(true);

    (async (): Promise<void> => {
      const { data: response } = await getUsersQuery({
        namespace,
        pageSize: PAGE_SIZE,
        next,
      });

      if (!response) {
        setIsLoadingNextPage(false);
        return;
      }

      setUsers((previousUsers) => [...new Set(previousUsers.concat(response.users))]);

      setNext(response.next);

      setIsLoadingNextPage(false);
    })();
  }, [getUsersQuery, isLoadingNextPage, next, namespace]);

  useEffect(() => {
    if (!scrollableElement || !lastItem) return;

    const callback: IntersectionObserverCallback = (entries) => {
      const observedEntry = entries[0];

      if (observedEntry.isIntersecting && next) {
        fetchNextPage();
      }
    };

    const intersectionObserver = new IntersectionObserver(callback, {
      root: scrollableElement,
    });

    intersectionObserver.observe(lastItem);

    return () => {
      intersectionObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableElement, lastItem]);

  // Reset users and next point when the namespace changes
  useEffect(() => {
    setUsers([]);
    setNext(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namespace]);

  useEffect(() => {
    if (!newUser || newUser.namespace !== namespace) return;

    setUsers((prevUsers) => {
      const operatingUsers = [...prevUsers];
      operatingUsers.unshift(newUser);

      return operatingUsers;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUser]);

  // After the next got triggered by reset, fetch the users.
  useEffect(() => {
    !users.length && fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next]);

  return (
    <>
      <div className={styles.contentTable}>
        <div className={styles.head}>
          <div className={styles.userProfile}>
            <span>Name</span>
          </div>
          <div className={styles.namespace}>
            <span>Owner</span>
          </div>
          <div className={styles.role}>
            <span>Role</span>
          </div>
          <div className={styles.option} />
        </div>
        <div className={styles.body} ref={bodyRef}>
          {users.map((user, index) => (
            <ContentRow
              key={user.id}
              user={user}
              ref={index === users.length - 1 ? lastRowRef : undefined}
              onClickEdit={handleOnClickEditUser}
              onClickDelete={handleShowConfirmDeleteUser}
              namespacesAccess={namespacesAccess}
            />
          ))}
          {isLoadingNextPage && (
            <div className={styles.loadingNextPageIndicatorRow}>
              <PuffLoader color="#44ABDF" size={40} />
            </div>
          )}
        </div>
      </div>
      {isEditUserModalShown && onEditUser && (
        <EditUserModal
          user={onEditUser}
          onSave={handleOnClickSaveEditUser}
          onClose={handleOnCloseEditUserModal}
          currentUserNamespace={currentUserNamespace}
        />
      )}
      {onDeleteUser && (
        <Dialog
          level="danger"
          title={`Are you sure you want to delete ${onDeleteUser.email}?`}
          message="This action is irreversible."
          cancelButtonTitle="Cancel"
          submitButtonTitle="Delete user"
          onSelectCancel={handleCancelDeleteUser}
          onSelectSubmit={handleOnClickDeleteUser}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};
