import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { User } from 'Models/user';
import { apiApi } from 'Services/redux/api/api.api';
import { CurrentUserResponse, UsersResponse as UsersRecordResponse } from './interfaces';
import { transformIntoPermission, transformIntoUser } from './transformers';

export type GetUsersArguments = {
  namespace?: string;
  pageSize: number;
  next?: string;
};

export type UsersReponse = { users: User[]; next: string };

export type Namespace = {
  displayName: string;
  code: string;
};

export type RolesAndNameSpacesResponse = { roles: string[]; namespaces: Namespace[] };

export type CreateUserRole = {
  name: string;
  namespace: string;
};

export type CreateUserArguments = {
  body: {
    email: string;
    firstName: string;
    lastName: string;
    namespace: string;
    password?: string;
    roles: CreateUserRole[];
  };
  params: {
    skipPasswordEmail: boolean;
  };
};

export type CreateUserResponse = User;

export type EditUserArguments = {
  id: string;
  body: {
    email: string;
    firstName: string;
    lastName: string;
    namespace: string;
    password?: string;
    roles: CreateUserRole[];
  };
  params: {
    skipPasswordEmail: boolean;
  };
};

export type EditUserResponse = CreateUserResponse;

export type DeleteUserArguments = User;

export const usersApi = apiApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<UsersReponse, GetUsersArguments | void>({
      query: (arg) => {
        if (!arg) {
          return { url: 'users' };
        }

        const params: FetchArgs['params'] = {
          namespace: arg.namespace,
          pageSize: arg.pageSize,
        };

        if (arg.next) params['next'] = arg.next;

        return {
          url: 'users',
          params,
        };
      },
      transformResponse: (usersRecordResponse: UsersRecordResponse) => ({
        users: usersRecordResponse.users.map(transformIntoUser),
        next: usersRecordResponse.next,
      }),
    }),

    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: 'user/me',
      }),
      transformResponse: (response: CurrentUserResponse) => {
        const user = transformIntoUser(response.user);
        return { ...user, permissions: response.permissions.map(transformIntoPermission) };
      },
    }),

    getRolesAndNamespaces: builder.query<RolesAndNameSpacesResponse, void>({
      query: () => ({
        url: 'users/management',
      }),
    }),

    createUser: builder.mutation<CreateUserResponse, CreateUserArguments>({
      query: ({ body, params }) => {
        return {
          url: `users`,
          method: 'POST',
          body: body,
          params,
        };
      },
    }),

    editUser: builder.mutation<EditUserResponse, EditUserArguments>({
      query: ({ id, body, params }) => {
        return {
          url: `users/${id}`,
          method: 'PUT',
          body,
          params,
        };
      },
    }),

    deleteUser: builder.mutation<void, DeleteUserArguments>({
      query: (user) => {
        return {
          url: `users/${user.altId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetCurrentUserQuery,
  useGetRolesAndNamespacesQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
} = usersApi;
