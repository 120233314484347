import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import styles from '../../TimelineBar.module.scss';

type TimelineBarTooltipProps = {
  position: number;
};

export const TimelineBarTooltip: React.FC<PropsWithChildren<TimelineBarTooltipProps>> = ({
  position,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [correctionOffset, setCorrectionOffset] = useState<number>(0);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();

    const SAFE_AREA_INSET = 20;
    const maxRight = window.innerWidth - SAFE_AREA_INSET;
    const maxLeft = SAFE_AREA_INSET;

    const overflowRight = rect.right - maxRight;
    const overflowLeft = maxLeft - rect.left;

    if (overflowRight > 0) {
      setCorrectionOffset(-Math.max(0, overflowRight));
    } else if (overflowLeft > 0) {
      setCorrectionOffset(Math.max(0, overflowLeft));
    } else {
      setCorrectionOffset(0);
    }
  }, [position]);

  return (
    <div
      ref={containerRef}
      className={styles.timelineBarTooltip}
      style={{ left: `${position * 100}%` }}
    >
      <div className={styles.content} style={{ transform: `translateX(${correctionOffset}px)` }}>
        {children}
      </div>
    </div>
  );
};
