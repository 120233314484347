import { DynamicInterval, Interval, StaticInterval } from 'Constants';
import { ViewMode } from 'Pages/DashboardPage/components/ControlBar/components/ViewModeSelector/components';

export const encodeInterval = (interval: Interval) => {
  if (typeof interval === 'string') {
    return interval;
  }

  return `${new Date(interval.earliest).getTime()}-${
    interval.latest ? new Date(interval.latest).getTime() : ''
  }`;
};

export const decodeInterval = (str: string): Interval | undefined => {
  const match = /^(\d+)?-(\d+)?$/.exec(str);

  if (match) {
    const earliestMillis = +match[1];
    const latestMillis = +match[2];

    if (!isNaN(earliestMillis) && !isNaN(latestMillis)) {
      return {
        earliest: new Date(earliestMillis).toISOString(),
        latest: new Date(latestMillis).toISOString(),
      };
    } else if (!isNaN(earliestMillis)) {
      return {
        earliest: new Date(earliestMillis).toISOString(),
      };
    } else {
      return undefined;
    }
  }

  const possibleIntervals = Object.values(DynamicInterval) as string[];

  if (possibleIntervals.includes(str)) {
    return str as Interval;
  }
};

export const updateIntervalInUrl = (interval: Interval) => {
  const params = new URLSearchParams(window.location.search);
  const intervalString = encodeInterval(interval);

  params.set('interval', intervalString);

  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};

export const getInterval = (url: string): Interval | undefined => {
  const params = new URL(url).searchParams;

  const intervalString = params.get('interval');

  if (!intervalString) {
    return;
  }

  return decodeInterval(intervalString);
};

export function generateStaticInterval(interval: Interval): StaticInterval {
  if (isDynamicInterval(interval)) {
    const endDate = new Date();
    const startDate = new Date(endDate.getTime());

    switch (interval) {
      case DynamicInterval.Last30Minutes:
        startDate.setMinutes(startDate.getMinutes() - 30);
        break;
      case DynamicInterval.Last1Hour:
        startDate.setHours(startDate.getHours() - 1);
        break;
      case DynamicInterval.Last12Hours:
        startDate.setHours(startDate.getHours() - 12);
        break;
      case DynamicInterval.Last24Hours:
        startDate.setHours(startDate.getHours() - 24);
        break;
      case DynamicInterval.Last2Days:
        startDate.setDate(startDate.getDate() - 2);
        break;
      case DynamicInterval.Last7Days:
        startDate.setDate(startDate.getDate() - 7);
        break;
      case DynamicInterval.Last10Days:
        startDate.setDate(startDate.getDate() - 10);
        break;
      case DynamicInterval.Last14Days:
        startDate.setDate(startDate.getDate() - 14);
        break;
      case DynamicInterval.Last1Month:
        startDate.setDate(startDate.getMonth() - 1);
        break;
      case DynamicInterval.Last3Months:
        startDate.setDate(startDate.getMonth() - 3);
        break;
      default:
        const _exhaustiveCheck: never = interval;
        throw new Error(_exhaustiveCheck);
    }

    return {
      earliest: startDate.toISOString(),
    };
  }

  return interval;
}

export function getAbsoluteInterval(staticInterval: StaticInterval) {
  return {
    earliest: staticInterval.earliest,
    latest: staticInterval.latest ?? new Date().toISOString(),
  };
}

export const areIntervalsEqual = (interval1: Interval, interval2: Interval) => {
  if (isDynamicInterval(interval1) && isDynamicInterval(interval2)) {
    return interval1 === interval2;
  }

  if (!isDynamicInterval(interval1) && !isDynamicInterval(interval2)) {
    return interval1.earliest === interval2.earliest && interval1.latest === interval2.latest;
  }

  return false;
};

export const isDynamicInterval = (interval: Interval): interval is DynamicInterval => {
  return typeof interval === 'string';
};

export const inferViewMode = (interval: Interval): ViewMode => {
  return isDynamicInterval(interval) || interval.latest === undefined ? 'live' : 'replay';
};
