import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { useEffect, useRef } from 'react';
import { default as videojs, VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

type VideoPlayerProps = {
  options?: VideoJsPlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
};

export const VideoPlayer = forwardRef<VideoJsPlayer, VideoPlayerProps>(
  ({ options, onReady }, ref) => {
    const videoRef = useRef<HTMLDivElement>(null);
    const playerRef = useRef<VideoJsPlayer | undefined>(undefined);

    useEffect(() => {
      if (!videoRef.current) {
        return;
      }

      if (!playerRef.current) {
        const videoElement = document.createElement('video-js');

        videoElement.classList.add('vjs-big-play-centered');
        videoRef.current.appendChild(videoElement);

        const player = (playerRef.current = videojs(videoElement, options, () => {
          onReady && onReady(player);
        }));
      }
    }, [options, videoRef, onReady]);

    useEffect(() => {
      const player = playerRef.current;

      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = undefined;
        }
      };
    }, [playerRef]);

    useImperativeHandle(ref, () => playerRef.current as VideoJsPlayer);

    const style = options?.fill === true ? { width: '100%', height: '100%' } : {};

    return (
      // wrap the player in a div with a `data-vjs-player` attribute
      // so videojs won't create additional wrapper in the DOM
      // see https://github.com/videojs/video.js/pull/3856
      <div ref={videoRef} data-vjs-player style={{ ...style }}></div>
    );
  }
);
