import { AbsoluteInterval } from 'Constants';
import { useEffect, useState } from 'react';
import { useControlBarManager } from './useControlBarManager';

/**
 * @warning
 * Component that uses this hook will be rendered frequently
 * because of the nature of the timeline bar interval that keeps
 * changing over time in live mode.
 *
 * Only use this hook if you really need to access the timeline
 * bar interval in realtime. If you need to only access the
 * interval one time, use `useSelectedIntervals` instead and
 * use the selected interval to calculate timeline bar interval
 * using `generateTimelineBarInterval` helper function.
 */
export const useTimelineInterval = () => {
  const manager = useControlBarManager();

  const [timelineBarInterval, setTimelineBarInterval] = useState(manager.getTimelineBarInterval());

  useEffect(() => {
    const timelineBarIntervalObserver = (interval: AbsoluteInterval) => {
      setTimelineBarInterval(interval);
    };

    manager.observeTimelineBarInterval(timelineBarIntervalObserver);

    return () => manager.unobserveTimelineBarInterval(timelineBarIntervalObserver);
  }, [manager]);

  return timelineBarInterval;
};
