import styles from './CalendarHeader.module.scss';

export const CalendarHeader: React.FC = () => {
  return (
    <div className={styles.calendarHeader}>
      <span className={styles.calendarHeaderCell}>Sun</span>
      <span className={styles.calendarHeaderCell}>Mon</span>
      <span className={styles.calendarHeaderCell}>Tue</span>
      <span className={styles.calendarHeaderCell}>Wed</span>
      <span className={styles.calendarHeaderCell}>Thu</span>
      <span className={styles.calendarHeaderCell}>Fri</span>
      <span className={styles.calendarHeaderCell}>Sat</span>
    </div>
  );
};
