import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { apiApi } from './api/api.api';
import { sensorStreamApi } from './sensorStream/sensorStream.api';
import { alertsApi } from './alerts/alerts.api';
import { mapReducer, mapSlice } from './map';
import { reportsReducer, reportsSlice } from './reports';
import { sessionReducer, sessionSlice } from './session';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

export const store = configureStore({
  reducer: {
    [apiApi.reducerPath]: apiApi.reducer,
    [sensorStreamApi.reducerPath]: sensorStreamApi.reducer,
    [alertsApi.reducerPath]: alertsApi.reducer,
    [sessionSlice.name]: sessionReducer,
    [mapSlice.name]: mapReducer,
    [reportsSlice.name]: reportsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiApi.middleware)
      .concat(sensorStreamApi.middleware)
      .concat(alertsApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
