import styles from './VideoPlayerPopup.module.scss';
import { ReactComponent as IconCrossLarge } from 'Resources/graphics/icon-cross-large.svg';
import { VideoPlayer } from 'Components/VideoPlayer';
import { useEffect, useRef } from 'react';
import { VideoJsPlayer } from 'video.js';

const LOAD_START_EVENT = 'loadstart';

type VideoPlayerPopupProps = {
  title: string;
  url?: string;
  errorMessage?: string;
  onClose?: () => void;
};

export const VideoPlayerPopup: React.FC<VideoPlayerPopupProps> = ({
  title,
  url,
  errorMessage,
  onClose,
}) => {
  const playerRef = useRef<VideoJsPlayer>(null);

  useEffect(() => {
    const player = playerRef.current;

    if (!player || !url) return;

    const manifestUrl = new URL(url);

    const loadStartListener = () => {
      const tech = player.tech(true) as any;

      tech.vhs.xhr.beforeRequest = (options: any) => {
        const mediaSegmentUrl = new URL(options.uri);
        mediaSegmentUrl.search = manifestUrl.search;

        options.uri = mediaSegmentUrl.toString();
      };
    };

    player.on(LOAD_START_EVENT, loadStartListener);
    player.src(url);
    player.play();

    return () => {
      player.off(LOAD_START_EVENT, loadStartListener);
    };
  }, [url]);

  return (
    <div className={styles.videoPlayerPopupOverlay}>
      <div className={styles.videoPlayerPopup}>
        {!errorMessage && (
          <VideoPlayer
            ref={playerRef}
            options={{
              responsive: true,
              liveui: true,
              fill: true,
              controls: true,
              bigPlayButton: false,
              disablePictureInPicture: true,
            }}
          />
        )}
        {errorMessage && !url && <p className={styles.errorMessage}>{errorMessage}</p>}
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <button className={styles.closeButton} onClick={onClose}>
            <IconCrossLarge />
          </button>
        </div>
      </div>
    </div>
  );
};
