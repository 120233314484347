import styles from './StepInterval.module.scss';
import { useShareEmitterContext } from '../../../hooks';
import { DateTimePickerInput } from 'Components/DateTimePickerInput';
import { Option } from 'Components/Option';
import { CheckBox } from 'Components/CheckBox';
import { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { UserAccess } from 'Models';
import { getIntervalFromUserAccess } from './helpers/user-access';

type StepIntervalProps = {
  userAccess?: UserAccess;
};

export const StepInterval: React.FC<StepIntervalProps> = ({ userAccess }) => {
  const { interval, setInterval, noIntervalRestriction, setNoIntervalRestriction } =
    useShareEmitterContext();

  useEffect(() => {
    if (!interval.earliest && !interval.latest && userAccess) {
      const preselectedInterval = getIntervalFromUserAccess(userAccess);

      if (preselectedInterval) {
        setInterval(preselectedInterval);
      } else {
        setNoIntervalRestriction(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess]);

  const startDate = useMemo(
    () => (interval.earliest ? new Date(interval.earliest) : undefined),
    [interval]
  );
  const endDate = useMemo(
    () => (interval.latest ? new Date(interval.latest) : undefined),
    [interval]
  );

  const startDateError = useMemo(
    () =>
      !noIntervalRestriction && startDate && endDate && startDate.getTime() >= endDate.getTime()
        ? 'Start date cannot be after the end date'
        : undefined,
    [noIntervalRestriction, startDate, endDate]
  );

  const dateSelectorMaxEndDate = useMemo(() => {
    const d = new Date();
    d.setFullYear(d.getFullYear() + 1);
    return d;
  }, []);

  const endDateError = useMemo(
    () =>
      !noIntervalRestriction && startDate && endDate && startDate.getTime() >= endDate.getTime()
        ? 'End date cannot be before the start date'
        : undefined,
    [noIntervalRestriction, startDate, endDate]
  );

  const handleChangeStartDate = useCallback(
    (date: Date) => {
      setInterval((interval) => ({ ...interval, earliest: date.toISOString() }));
    },
    [setInterval]
  );

  const handleChangeEndDate = useCallback(
    (date: Date) => {
      setInterval((interval) => ({ ...interval, latest: date.toISOString() }));
    },
    [setInterval]
  );

  const handleClickNoDateRestrictionOption = useCallback(() => {
    setNoIntervalRestriction((noIntervalRestriction) => !noIntervalRestriction);
  }, [setNoIntervalRestriction]);

  return (
    <div className={styles.stepInterval}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={clsx('form-control', noIntervalRestriction && 'disabled')}>
            <label>Start Date</label>
            <DateTimePickerInput
              header="Select start date"
              selectedDate={startDate}
              onChange={handleChangeStartDate}
              className="expanded"
              disabled={noIntervalRestriction}
              upperLimit={dateSelectorMaxEndDate}
              error={!!startDateError}
            />
            {!!startDateError && <span className="error-info">{startDateError}</span>}
          </div>
        </div>
        <div className={styles.column}>
          <div className={clsx('form-control', noIntervalRestriction && 'disabled')}>
            <label>End Date (optional)</label>
            <DateTimePickerInput
              header="Select end date"
              selectedDate={endDate}
              onChange={handleChangeEndDate}
              className="expanded"
              disabled={noIntervalRestriction}
              upperLimit={dateSelectorMaxEndDate}
              error={!!endDateError}
            />
            {!!endDateError && <span className="error-info">{endDateError}</span>}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <Option label="No date restriction" onClick={handleClickNoDateRestrictionOption}>
          <CheckBox checked={noIntervalRestriction} />
        </Option>
      </div>
    </div>
  );
};
