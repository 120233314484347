import styles from './StepInterval.module.scss';
import { useCallback, useMemo } from 'react';
import { DateTimePickerInput } from 'Components/DateTimePickerInput';
import { StaticInterval } from 'Constants';
import { areDatesMoreThanAYearApart } from '../../helpers/date';
import clsx from 'clsx';

type StepIntervalProps = {
  interval: Partial<StaticInterval>;
  onSelectInterval: (interval: Partial<StaticInterval>) => void;
};

export const StepInterval: React.FC<StepIntervalProps> = ({ interval, onSelectInterval }) => {
  const startDate = useMemo(
    () => (interval.earliest ? new Date(interval.earliest) : undefined),
    [interval]
  );
  const endDate = useMemo(
    () => (interval.latest ? new Date(interval.latest) : undefined),
    [interval]
  );

  const startDateError = useMemo(() => {
    if (startDate && endDate) {
      if (startDate.getTime() >= endDate.getTime()) {
        return 'Start date cannot be after the end date';
      }

      if (areDatesMoreThanAYearApart(startDate, endDate)) {
        return 'Dates cannot be more than 1 year apart';
      }
    }

    return undefined;
  }, [startDate, endDate]);

  const endDateError = useMemo(() => {
    if (startDate && endDate) {
      if (startDate.getTime() >= endDate.getTime()) {
        return 'End date cannot be before the start date';
      }

      if (areDatesMoreThanAYearApart(startDate, endDate)) {
        return 'Dates cannot be more than 1 year apart';
      }
    }

    return undefined;
  }, [startDate, endDate]);

  const handleChangeStartDate = useCallback(
    (date: Date) => {
      onSelectInterval({ ...interval, earliest: date.toISOString() });
    },
    [interval, onSelectInterval]
  );

  const handleChangeEndDate = useCallback(
    (date: Date) => {
      onSelectInterval({ ...interval, latest: date.toISOString() });
    },
    [interval, onSelectInterval]
  );

  return (
    <div className={styles.stepInterval}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={clsx('form-control')}>
            <label>Start Date</label>
            <DateTimePickerInput
              header="Select start date"
              selectedDate={startDate}
              onChange={handleChangeStartDate}
              className="expanded"
              error={!!startDateError}
            />
            {!!startDateError && <span className="error-info">{startDateError}</span>}
          </div>
        </div>
        <div className={styles.column}>
          <div className={clsx('form-control')}>
            <label>End Date</label>
            <DateTimePickerInput
              header="Select end date"
              selectedDate={endDate}
              onChange={handleChangeEndDate}
              className="expanded"
              error={!!endDateError}
            />
            {!!endDateError && <span className="error-info">{endDateError}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
