import { Toast } from './interfaces';

type ToastObserver = (toasts: Toast[]) => void;
export class ToastCoordinator {
  private toasts: Toast[] = [];
  private observers: Set<ToastObserver> = new Set();

  push(toast: Toast) {
    if (this.toasts.some((t) => t.id === toast.id)) {
      return;
    }

    this.toasts = [toast, ...this.toasts];
    this.emit();

    setTimeout(() => {
      this.remove(toast.id);
    }, toast.removeAfter || 4000);
  }

  remove(id: Toast['id']) {
    const toast = this.toasts.find((t) => t.id === id);

    if (!toast) {
      return;
    }

    this.update({ ...toast, visible: false });

    setTimeout(() => {
      this.toasts = this.toasts.filter((t) => t.id !== id);
      this.emit();
    }, 2000);
  }

  update(toast: Toast) {
    this.toasts = this.toasts.map((t) => (t.id === toast.id ? toast : t));
    this.emit();
  }

  observe(observer: ToastObserver) {
    this.observers.add(observer);
  }

  unobserve(observer: ToastObserver) {
    this.observers.delete(observer);
  }

  private emit() {
    for (const observer of this.observers) {
      observer(this.toasts);
    }
  }
}
