import { Property } from 'Models';
import { EventEmitter } from './EventEmitter';

/**
 * Subscription instance serves as proxy to pass
 * socketIO event to subscriber.
 *
 * socketIO workflow involving subscribing to
 * event using socket.on and unsubcribing using
 * socket.off. Forgetting to call socket.off
 * can cause memory leak.
 *
 * User should not decide when they have to
 * unsubscribe from an event.
 * Take an example of `gps` event. Even though
 * one component no longer wants to listen for
 * `gps` event but other componts might still
 * want to listen to `gps` event.
 *
 * This implies that subscribing and unsubscribing
 * should be handled automatically by subscription
 * manager. This subscription manager serves as
 * garbage collector.
 *
 * @see SubscriptionManager
 */
export class Subscription extends EventEmitter {
  private _eventInterests: { [eventName: Property['sensorName']]: true } = {};

  constructor(readonly _properties: Property[]) {
    super();
    Object.freeze(this._properties);

    this._eventInterests = _properties.reduce(
      (acc, property) => ({ ...acc, [property.sensorName]: true }),
      {}
    );

    Object.freeze(this._eventInterests);
  }

  interestedInEvent(eventName: Property['sensorName']) {
    return !!this._eventInterests[eventName];
  }
}
