import { ReactElement, useMemo } from 'react';
import { KeyValueCell } from '../../../../KeyValueCell';
import { IPropertyName } from 'Constants';
import { Sensor } from 'Models';
import { usePropertiesSumSample } from 'Pages/DashboardPage/components/RedesignedEmitterDetailsBar/hooks/usePropertiesSumSample';

interface SummaryPropertiesSumCellProps {
  title: string;
  sensor: Sensor;
  propertyName: IPropertyName;
}

export function SummarySectionPropertiesSumCell({
  title,
  sensor,
  propertyName,
}: SummaryPropertiesSumCellProps): ReactElement {
  const {
    sample: sampleToDisplay,
    isLoading,
    properties,
  } = usePropertiesSumSample(sensor, propertyName);

  const value = useMemo(() => {
    if (properties.length === 0) {
      return undefined;
    }

    let sumValue: number | undefined;

    for (let i = 0; i < properties.length; i++) {
      const v = sampleToDisplay?.[i];
      if (typeof v === 'number') {
        if (typeof sumValue === 'number') {
          sumValue += v;
        } else {
          sumValue = v;
        }
      }
    }

    if (sumValue === undefined) {
      return undefined;
    } else {
      return { score: sumValue, unit: properties[0].unit };
    }
  }, [properties, sampleToDisplay]);

  return (
    <KeyValueCell
      title={title}
      value={value}
      isLoading={isLoading}
      showPlaceholder={properties.length === 0}
    />
  );
}
