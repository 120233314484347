import styles from './EmitterSelector.module.scss';
import { EmitterSelectorLoader } from './components';
import { Switch } from 'Components/Switch';
import { EmitterOption } from 'Pages/ReportsPage/interfaces';
import clsx from 'clsx';

type EmitterSelectorProps = {
  emitterOptions: EmitterOption[];
  showPlaceholder: boolean;
  enableAllEmitter: boolean;
  onToggleAllEmitterOption: () => void;
  onToggleEmitterOption: (index: number) => void;
};

export const EmitterSelector: React.FC<EmitterSelectorProps> = ({
  emitterOptions,
  showPlaceholder,
  enableAllEmitter,
  onToggleAllEmitterOption,
  onToggleEmitterOption,
}) => {
  return (
    <div className={styles.emitterSelector}>
      {showPlaceholder ? (
        <EmitterSelectorLoader />
      ) : (
        <>
          <div className={styles.emitterSelectorHeader}>
            <div className={styles.emitterSelectorHeaderContent}>
              Vessels
              <div className={styles.emitterSelectorHeaderAccessory}>
                <Switch on={enableAllEmitter} onToggle={onToggleAllEmitterOption} />
              </div>
            </div>
            <hr />
          </div>
          <div className={styles.emitterSelectorBody}>
            {emitterOptions.map((emitterOption, index) => (
              <div
                key={emitterOption.emitter.id}
                className={clsx(
                  styles.emitterSelectorItem,
                  emitterOption.isDisabled && styles.disabled
                )}
              >
                {emitterOption.emitter.name}
                <div className={styles.emitterSelectorItemAccessory}>
                  <Switch
                    on={emitterOption.isSelected}
                    disabled={emitterOption.isDisabled}
                    onToggle={() => {
                      onToggleEmitterOption(index);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
