import { IPropertyName } from 'Constants';
import { SelectableMenuItem } from '../interfaces';

export const getSelectableMenuItemFullTitle = (selectableMenuItem: SelectableMenuItem) => {
  return selectableMenuItem.parentTitle
    ? `${selectableMenuItem.parentTitle} / ${selectableMenuItem.title}`
    : selectableMenuItem.title;
};

export const getSelectableMenuItemForPropertyName = (
  propertyName: IPropertyName,
  selectableMenuItems: SelectableMenuItem[]
): SelectableMenuItem | undefined => {
  const matchingMenuItems: SelectableMenuItem[] = [];

  for (const menuItem of selectableMenuItems) {
    const property = menuItem.property;

    if (property && property.name.hash === propertyName.hash) {
      matchingMenuItems.push(menuItem);
    }
  }

  // If the property comes from engine sensor, use the one with the lowest engine id
  return matchingMenuItems.sort((a, b) => {
    const firstProperty = a.property;
    const secondProperty = b.property;

    if (typeof firstProperty.engineId === 'number' && typeof secondProperty.engineId === 'number') {
      return firstProperty.engineId - secondProperty.engineId;
    }

    return 0;
  })[0];
};

export const getMenuItemRecommendation = ({
  propertyName,
  selectableMenuItems,
  avoidMenuItem,
}: {
  propertyName?: IPropertyName;
  selectableMenuItems: SelectableMenuItem[];
  avoidMenuItem?: SelectableMenuItem;
}): SelectableMenuItem | undefined => {
  const recommendedMenuItem: SelectableMenuItem | undefined =
    (propertyName && getSelectableMenuItemForPropertyName(propertyName, selectableMenuItems)) ??
    selectableMenuItems[0];

  if (!recommendedMenuItem) return undefined;

  if (!avoidMenuItem || recommendedMenuItem.id !== avoidMenuItem.id) return recommendedMenuItem;

  const recommendedMenuItemIndex = getSelectableMenuItemIndex(
    recommendedMenuItem,
    selectableMenuItems
  ) as number;

  const avoidMenuItemIndex = getSelectableMenuItemIndex(avoidMenuItem, selectableMenuItems);

  let alternativeRecommendedMenuItemIndex: number | undefined;

  if (!avoidMenuItemIndex || recommendedMenuItemIndex === avoidMenuItemIndex) {
    if (recommendedMenuItemIndex < selectableMenuItems.length - 1) {
      alternativeRecommendedMenuItemIndex = recommendedMenuItemIndex + 1;
    } else if (recommendedMenuItemIndex >= 1) {
      alternativeRecommendedMenuItemIndex = recommendedMenuItemIndex - 1;
    }
  }

  if (alternativeRecommendedMenuItemIndex !== undefined) {
    return selectableMenuItems[alternativeRecommendedMenuItemIndex];
  }

  return undefined;
};

export const getSelectableMenuItemIndex = (
  itemToFind: SelectableMenuItem,
  selectableMenuItems: SelectableMenuItem[]
): number | undefined => {
  const index = selectableMenuItems.findIndex((i) => i.id === itemToFind.id);

  return index === -1 ? undefined : index;
};
