import { Auth0ContextInterface } from '@auth0/auth0-react';

type IGetAccessTokenSilently = Auth0ContextInterface['getAccessTokenSilently'];
type ILoginWithRedirect = Auth0ContextInterface['loginWithRedirect'];

let _getAccessTokenSilently: IGetAccessTokenSilently | undefined;
let _loginWithRedirect: ILoginWithRedirect | undefined;

export const getAccessTokenSilently: IGetAccessTokenSilently = async (options) => {
  if (!_getAccessTokenSilently) throw new Error('You forgot to call setGetAccessTokenSilently');

  try {
    return (await _getAccessTokenSilently(options)) as any;
  } catch (error) {
    console.error(error);

    if (_loginWithRedirect) {
      _loginWithRedirect();
    }
  }
};

export const setGetAccessTokenSilently = (f: IGetAccessTokenSilently) => {
  _getAccessTokenSilently = f;
};

export const setLoginWithRedirect = (f: ILoginWithRedirect) => {
  _loginWithRedirect = f;
};
