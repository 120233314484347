import styles from './AlertPopup.module.scss';
import { Alert } from 'Models';
import { MapPopup } from 'Components/MapPopup';
import { ReactComponent as IconDangerHigh } from './resources/graphics/icon-danger-high.svg';
import { ReactComponent as IconDangerMedium } from './resources/graphics/icon-danger-medium.svg';
import { ReactComponent as IconDangerLow } from './resources/graphics/icon-danger-low.svg';
import { ReactComponent as IconDangerUnknown } from './resources/graphics/icon-danger-unknown.svg';
import Moment from 'react-moment';
import moment from 'moment';
import { useMemo } from 'react';
import { DMSCoordinate } from 'Components/DMSCoordinate';

const getIcon = (level: number) => {
  switch (level) {
    case 3:
      return <IconDangerHigh />;
    case 2:
      return <IconDangerMedium />;
    case 1:
      return <IconDangerLow />;
    default:
      return <IconDangerUnknown />;
  }
};

type AlertPopupProps = {
  alert: Alert;
};

export const AlertPopup: React.FC<AlertPopupProps> = ({ alert }) => {
  const timezoneAbbreviation = useMemo(() => {
    const ianaTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
    return moment().tz(ianaTimezone).format('z');
  }, []);

  if (!alert.lastCoordinate) {
    return null;
  }

  return (
    <MapPopup longitude={alert.lastCoordinate.longitude} latitude={alert.lastCoordinate.latitude}>
      <div className={styles.alertPopup}>
        <p className={styles.title}>
          {getIcon(alert.level)}
          <span>{alert.sensorGroupName}</span>
        </p>
        <div className={styles.alertContent}>
          {alert.lastCoordinate && <DMSCoordinate coordinate={alert.lastCoordinate} />}
          <p className={styles.description}>{alert.message}</p>

          {alert?.explanations.map((e) => <p className={styles.description}>{e.message}</p>) ??
            null}

          <div className={styles.date}>
            <p>Occurred on:</p>
            <p>
              <span>
                <Moment format="MMM D YYYY, h:mm" date={alert.createdAt} />
              </span>{' '}
              <span>{timezoneAbbreviation}</span>
            </p>
          </div>
          {alert.isRead && alert.resolvedAt && (
            <div className={styles.date}>
              <p>Resolved at:</p>
              <p>
                <span>
                  <Moment format="MMM D YYYY, h:mm" date={alert.resolvedAt} />
                </span>{' '}
                <span>{timezoneAbbreviation}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </MapPopup>
  );
};
