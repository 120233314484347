import styles from './ControlBar.module.scss';
import { ReactComponent as PlusIcon } from './resources/graphics/icon-plus.svg';

type ControlBarProps = {
  onClickInviteNewUsers: () => void;
};

export const ControlBar: React.FC<ControlBarProps> = ({ onClickInviteNewUsers }) => {
  return (
    <div className={styles.controlBar}>
      <button className={styles.inviteButton} onClick={onClickInviteNewUsers}>
        <PlusIcon />
        <span>Add user</span>
      </button>
    </div>
  );
};
