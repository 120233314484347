import * as adler32 from 'adler-32';
import { Emitter, EngineProperty, EngineSensor, Property, Sensor } from 'Models';
import { IPropertyName, ISensorName, SensorName } from 'Constants';

export function propertyHasName(name: string, property: Property): boolean {
  const lastNamePart = property.name.name.split('.').at(-1);
  return lastNamePart === name;
}

export function getPropertiesWithSuffix(
  propertyName: IPropertyName,
  sensorOrSensors: Sensor | Sensor[]
): Property[] {
  const sensors = Array.isArray(sensorOrSensors) ? sensorOrSensors : [sensorOrSensors];

  const ret: Property[] = [];

  for (const s of sensors) {
    for (const p of s.properties) {
      if (propertyHasName(propertyName.name, p)) {
        ret.push(p);
      }
    }
  }
  return ret;
}

export function getFirstSensorWithName<T extends ISensorName['name']>(
  sensorName: ISensorName<T>,
  emitter: Emitter
): Sensor<T> | undefined;
export function getFirstSensorWithName<T extends ISensorName['name']>(
  sensorName: ISensorName<T>,
  emitters: Emitter[]
): Sensor<T> | undefined;
export function getFirstSensorWithName<T extends ISensorName['name']>(
  sensorName: ISensorName<T>,
  emitterOrEmitters: Emitter | Emitter[]
): Sensor<T> | undefined {
  const emitters = Array.isArray(emitterOrEmitters) ? emitterOrEmitters : [emitterOrEmitters];
  return getSensorsWithName(sensorName, emitters)[0];
}

export function getSensorsWithName<T extends ISensorName['name']>(
  sensorName: ISensorName<T>,
  emitter: Emitter
): Sensor<T>[];
export function getSensorsWithName<T extends ISensorName['name']>(
  sensorName: ISensorName<T>,
  emitters: Emitter[]
): Sensor<T>[];
export function getSensorsWithName<T extends ISensorName['name']>(
  sensorName: ISensorName<T>,
  emitterOrEmitters: Emitter | Emitter[]
): Sensor<T>[] {
  const emitters = Array.isArray(emitterOrEmitters) ? emitterOrEmitters : [emitterOrEmitters];

  return getSensorsWithNames([sensorName], emitters) as Sensor<T>[];
}

export function getSensorsWithNames(sensorNames: ISensorName[], emitter: Emitter): Sensor[];
export function getSensorsWithNames(sensorNames: ISensorName[], emitters: Emitter[]): Sensor[];
export function getSensorsWithNames(
  sensorNames: ISensorName[],
  emitterOrEmitters: Emitter | Emitter[]
): Sensor[] {
  const emitters = Array.isArray(emitterOrEmitters) ? emitterOrEmitters : [emitterOrEmitters];

  return emitters.reduce<Sensor[]>(
    (res, emitter) => [
      ...res,
      ...emitter.sensors.filter((s) => {
        return sensorNames.some((sn) => {
          if (emitter.usesUnifiedSensor) {
            if (
              sn.name !== SensorName.AISStatic.name &&
              sn.name !== SensorName.AISPositional.name &&
              sn.name !== SensorName.Camera.name
            ) {
              return s.name === SensorName.Virt.name;
            }
          }

          return sn.name === s.name;
        });
      }),
    ],
    []
  );
}

export function getFirstPropertyWithName<
  U extends ISensorName['name'],
  T extends IPropertyName['name']
>(propertyName: IPropertyName<T>, sensor: Sensor<U>): Property<U, T> | undefined;
export function getFirstPropertyWithName<T extends IPropertyName['name']>(
  propertyName: IPropertyName<T>,
  sensors: Sensor[]
): Property<T> | undefined;
export function getFirstPropertyWithName<T extends IPropertyName['name']>(
  propertyName: IPropertyName<T>,
  sensorOrSensors: Sensor | Sensor[]
): Property<T> | undefined {
  const sensors = Array.isArray(sensorOrSensors) ? sensorOrSensors : [sensorOrSensors];
  return getPropertiesWithName(propertyName, sensors)[0];
}

export function getPropertiesWithName<
  U extends ISensorName['name'],
  T extends IPropertyName['name']
>(propertyName: IPropertyName<T>, sensor: Sensor<U>): Property<U, T>[];
export function getPropertiesWithName<T extends IPropertyName['name']>(
  propertyName: IPropertyName<T>,
  sensors: Sensor[]
): Property<T>[];
export function getPropertiesWithName<T extends IPropertyName['name']>(
  propertyName: IPropertyName,
  sensorOrSensors: Sensor | Sensor[]
): Property<T>[] {
  const sensors = Array.isArray(sensorOrSensors) ? sensorOrSensors : [sensorOrSensors];

  return getPropertiesWithNames([propertyName], sensors) as Property<T>[];
}

export function getPropertiesWithNames<U extends ISensorName['name']>(
  propertyNames: IPropertyName[],
  sensor: Sensor<U>
): Property<U>[];
export function getPropertiesWithNames(
  propertyNames: IPropertyName[],
  sensors: Sensor[]
): Property[];
export function getPropertiesWithNames(
  propertyNames: IPropertyName[],
  sensorOrSensors: Sensor | Sensor[]
): Property[] {
  const sensors = Array.isArray(sensorOrSensors) ? sensorOrSensors : [sensorOrSensors];

  return sensors.reduce<Property[]>(
    (res, sensor) => [
      ...res,
      ...sensor.properties.filter((p) =>
        propertyNames.some((pn) => propertyNameRepresentsProperty(pn, p))
      ),
    ],
    []
  );
}

export function propertyNameRepresentsProperty(propertyName: IPropertyName, property: Property) {
  const realName = property.name.name.split('.').slice(-1)[0];
  return propertyName.name === realName;
}

export const isEngineSensor = (sensor: Sensor): sensor is EngineSensor => {
  return typeof sensor.engineId === 'number';
};

export const isEngineProperty = (property: Property): property is EngineProperty => {
  return typeof property.engineId === 'number';
};

export function createPropertyName<T extends ISensorName['name']>(
  name: T,
  path: string
): IPropertyName<T> {
  return {
    name,
    path,
    hash: String(adler32.str(path)),
  };
}

export const generatePropertyHash = (sensorId: string, propertyName: IPropertyName) => {
  return String(adler32.str(`${sensorId}-${propertyName.hash}`));
};

export const getUniqueProperties = (properties: Property[]) => {
  const seenHashes: Record<string, boolean> = {};

  return properties.filter((p) => {
    let hash = p.hash;
    return seenHashes.hasOwnProperty(hash) ? false : (seenHashes[hash] = true);
  });
};

export const generateProperyByName = (
  property: Property,
  name: 'systemTime' | 'metadata.isSatcomm' | 'errorCodes'
): Property => {
  const path = property.name.path.replace(/\/[^/]*$/, name);

  const propertyName = createPropertyName(name, path);

  return {
    name: propertyName,
    displayName: 'Time',
    sensorGroupId: property.sensorGroupId,
    sensorId: property.sensorId,
    sensorName: property.sensorName,
    sensorDisplayName: property.sensorDisplayName,
    sampleRate: property.sampleRate,
    hash: generatePropertyHash(property.sensorId, propertyName),
  };
};

export const belongsToSameSensor = (properties: Property[]) => {
  if (!properties.length) {
    return true;
  }

  if (properties.length === 1) {
    return true;
  }

  const sensorId = properties[0].sensorId;

  return properties.every((p) => p.sensorId === sensorId);
};

export const haveSameName = (sensors: Sensor[]) => {
  if (!sensors.length) {
    return true;
  }

  if (sensors.length === 1) {
    return true;
  }

  // TODO: Name should be ISensorName, currently it's not by mistake
  const sensorName = sensors[0].name;

  return sensors.every((s) => {
    if (s.name === sensorName) {
      return true;
    }

    // Virt is an exception
    if (s.name === 'virt' || sensorName === 'virt') {
      return true;
    }

    return false;
  });
};
