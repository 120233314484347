import styles from '../../EmitterDetailsBar.module.scss';

type EmitterDetailsBarSectionHeaderRegularProps = {
  title: string;
};

export const EmitterDetailsBarSectionHeaderRegular: React.FC<
  EmitterDetailsBarSectionHeaderRegularProps
> = ({ title }) => {
  return (
    <div className={styles.emitterDetailsBarSectionHeaderRegular}>
      <h4>{title}</h4>
    </div>
  );
};
