import { Emitter, Sensor } from 'Models';
import { SensorName } from 'Constants';
import { SensorUpdateMessagePayload } from '../interfaces';
import { BaseMessageTest } from '../BaseMessageTest';
import { isVessel } from 'Helpers/emitter';
import { getFirstSensorWithName } from 'Helpers/sensors';
import { NodeServerObserver } from '..';

type GpsSensor = Sensor<typeof SensorName.GPS.name>;

export class GpsMessageTest extends BaseMessageTest {
  constructor(protected observer: NodeServerObserver, private emitters: Emitter[]) {
    super(SensorName.GPS.name, observer, 3000);
  }

  protected getMessagePayloads(cycleCount: number): SensorUpdateMessagePayload[] {
    return this.emitters.reduce<SensorUpdateMessagePayload[]>((acc, emitter) => {
      const gpsSensor = getFirstSensorWithName(SensorName.GPS, emitter);

      return gpsSensor
        ? acc.concat(this.generatePayloadForEmitter(gpsSensor, emitter, cycleCount))
        : acc;
    }, []);
  }

  private generatePayloadForEmitter(
    sensor: GpsSensor,
    emitter: Emitter,
    cycleCount: number
  ): SensorUpdateMessagePayload {
    const payload: SensorUpdateMessagePayload = {
      metadata: { sensorId: sensor.id, ns: emitter.namespace, isSatcomm: false },
      latitude: emitter.coordinate && emitter.coordinate.latitude + cycleCount * 0.0007,
      longitude: emitter.coordinate && emitter.coordinate.longitude + cycleCount * 0.0007,
      systemTime: new Date().toISOString(),
    };

    if (isVessel(emitter)) {
      payload.cog = Math.random() * 359.9;
      payload.sog = Math.random() * 25;
    }

    return payload;
  }
}
