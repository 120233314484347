import { useEffect, useState } from 'react';
import { useControlBarManager } from './useControlBarManager';
import { ViewMode } from '../components/ViewModeSelector/components';

export const useViewMode = () => {
  const manager = useControlBarManager();
  const [viewMode, setViewMode] = useState(manager.getViewMode());

  useEffect(() => {
    const observer = (viewMode: ViewMode) => {
      setViewMode(viewMode);
    };

    manager.observeViewMode(observer);

    return () => manager.unobserveViewMode(observer);
  }, [manager]);

  return viewMode;
};
