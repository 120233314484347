import {
  VarsConfig,
  ImplySection,
  MultiImplySection,
  ImuSection,
  ImuSectionExpanded,
  Section,
  EmitterLayout,
  MultiSection,
  MultiSectionExpanded,
  BiaxialChartMenuItemParent,
  BiaxialChartMenuItemChild,
  BiaxialChartMenuItemImply,
  BiaxialChartSectionExpanded,
  BiaxialChartMenuItemMultiImply,
  BiaxialChartMenuItemExpandable,
  BiaxialChartMenuItemSelectable,
  LoadProfileSection,
  LoadProfileSectionExpanded,
  CustomCellsSection,
  CustomCellsNoTitleSection,
  SectionType,
  Sensor,
  TabbedSection,
  TabbedSectionExpanded,
  Property,
  BiaxialChartMenuItem,
  BiaxialChartSection,
  SectionTypeExpanded,
  CamerasSectionExpanded,
  CamerasSection,
  ComprehensiveCellsSection,
  ComprehensiveCellExpanded,
  ComprehensiveCell,
  KeyValueCell,
  KeyValueCellExpanded,
  OnOffCell,
  OnOffCellExpanded,
  FuelTypeCell,
  FuelTypeCellExpanded,
  ComprehensiveCellsSectionExpanded,
  ComprehensiveCellsNoTitleSection,
  ComprehensiveCellsNoTitleSectionExpanded,
  ComprehensiveCellType,
  OptionSectionExpanded,
  OptionSection,
} from 'Models';

import { SensorGroupLayout } from '../interfaces';
import { IPropertyName, SectionConfig, SectionConfigType, SensorName } from 'Constants';
import { removeNulls } from 'Helpers/array';

type Expander = Record<
  SectionType,
  (
    section: Section,
    authorized: Sensor[],
    vars: VarsConfig,
    parentSectionType?: SectionType
  ) => SectionTypeExpanded | undefined
>;

const expanders = {
  [SectionType.BiaxialChart]: expandBiaxialChartSection,
  [SectionType.CustomCells]: expandCustomCellsSection,
  [SectionType.CustomCellsNoTitle]: expandCustomCellsNoTitleSection,
  [SectionType.ComprehensiveCells]: expandComprehensiveCellsSection,
  [SectionType.ComprehensiveCellsNoTitle]: expandComprehensiveCellsNoTitleSection,
  [SectionType.Imply]: expandImplySection,
  [SectionType.LoadProfile]: expandLoadProfileSection,
  [SectionType.MultiImply]: expandMultiImplySection,
  [SectionType.Multi]: expandMultiSection,
  [SectionType.Tabbed]: expandTabbedSection,
  [SectionType.Imu]: expandImuSection,
  [SectionType.Cameras]: expandCamerasSection,
  [SectionType.Option]: expandOptionSection,
} as Expander;

function getSensorType(sensorId: string): SectionConfigType | undefined {
  return sensorId.split('-').at(2) as SectionConfigType;
}

function findSensorById(sensorId: string, authorized: Sensor[]) {
  return authorized.find((s) => s.id === sensorId);
}

function findPropertyInSensors(
  name: string,
  sensorId: string,
  sensors: Sensor[]
): Property | undefined {
  const sensor = findSensorById(sensorId, sensors);
  if (!sensor) {
    return;
  }

  return sensor.properties.find((p) => p.name.name === name);
}

function findPropertyInSensor(property: IPropertyName, sensor: Sensor): Property | undefined {
  return sensor.properties.find((p) => p.name.name === property.name);
}

function replaceVars(vars: VarsConfig, value?: string): string {
  if (!value) {
    return '';
  }
  return vars[value] ? vars[value] : value;
}

function getTitle(section: Section, authorized: Sensor[], sensorId?: string): string {
  if (section.title) {
    return section.title;
  }
  const sensor = authorized.find((s) => s.id === sensorId);

  if (sensor) {
    return sensor.displayName;
  }

  return '';
}

function findPropertyIneMenu(
  emitterConfigSection: BiaxialChartMenuItemExpandable[],
  sensorId: string,
  name: string
): BiaxialChartMenuItemSelectable | undefined {
  return emitterConfigSection
    .flatMap((s) => s.subitems)
    .find((s) => s.property.sensorId === sensorId && s.property.name.name === name);
}

function getSelectedProperties(
  emitterConfigSection: BiaxialChartMenuItemExpandable[],
  section: BiaxialChartSection
): (BiaxialChartMenuItemSelectable | undefined)[] {
  const selected = [];
  if (section.initialSelection1) {
    const { name, sensorId } = section.initialSelection1;
    selected.push(findPropertyIneMenu(emitterConfigSection, sensorId, name));
  } else {
    selected.push(emitterConfigSection.at(0)?.subitems.at(0));
  }

  if (section.initialSelection2) {
    const { name, sensorId } = section.initialSelection2;
    selected.push(findPropertyIneMenu(emitterConfigSection, sensorId, name));
  } else {
    selected.push(emitterConfigSection.at(1)?.subitems.at(0));
  }

  return selected;
}

function expandMenuItemImply(
  item: BiaxialChartMenuItemImply,
  sensors: Sensor[],
  vars: VarsConfig,
  parentTitle?: string
): BiaxialChartMenuItemExpandable | undefined {
  const ignored = ['systemTime', 'latitude', 'longitude', 'metadata.isSatcomm', 'errorCodes'];
  const sensor = findSensorById(item.sensorId, sensors);
  if (!sensor) {
    return;
  }
  const graphableProperties = sensor.properties.filter((p) => !ignored.includes(p.name.name));

  if (!graphableProperties.length) {
    return;
  }

  const title = replaceVars(vars, item.title || sensor.displayName);
  return {
    id: title,
    title,
    subitems: graphableProperties.map((p) => ({
      id: p.hash,
      title: replaceVars(vars, p.displayName),
      property: p,
      parentTitle: parentTitle ?? '',
    })),
  };
}

function expandMenuItemMultiImply(
  item: BiaxialChartMenuItemMultiImply,
  sensors: Sensor[],
  vars: VarsConfig,
  parentTitle?: string
): BiaxialChartMenuItemExpandable | undefined {
  const subitems = [];
  const title = replaceVars(vars, item.title);
  for (const s of item.sensorIds) {
    const implyItem = expandMenuItemImply({ title, sensorId: s }, sensors, vars, parentTitle);

    if (!implyItem) {
      continue;
    }

    const implyItemSubitems = implyItem?.subitems ?? [];
    subitems.push(...implyItemSubitems);
  }

  if (subitems.length) {
    return {
      id: title,
      title,
      subitems,
    };
  }
}

function expandMenuItemChild(
  item: BiaxialChartMenuItemChild,
  authorized: Sensor[],
  vars: VarsConfig,
  parentTitle: string
): BiaxialChartMenuItemSelectable | undefined {
  const sensor = findSensorById(item.property.sensorId, authorized);
  if (!sensor) {
    return;
  }
  const graphableProperty = sensor.properties.find((p) => p.name.name === item.property.name);

  if (!graphableProperty) {
    return;
  }

  return {
    id: graphableProperty.hash,
    title: replaceVars(vars, item.title || graphableProperty.displayName || sensor.displayName),
    property: graphableProperty,
    parentTitle,
  };
}

function expandMenuItemParent(
  item: BiaxialChartMenuItemParent,
  authorized: Sensor[],
  vars: VarsConfig
): BiaxialChartMenuItemExpandable | undefined {
  const title = replaceVars(vars, item.title);
  const subitems = item.subitems
    .map((i) => expandMenuItemSelectable(i, authorized, vars, title))
    .filter(removeNulls);

  if (subitems.length) {
    return {
      id: item.title,
      title,
      subitems,
    };
  }
}

function expandMenuItemExpandable(
  item: BiaxialChartMenuItem,
  authorized: Sensor[],
  vars: VarsConfig
): BiaxialChartMenuItemExpandable | undefined {
  const isParent = 'subitems' in item;
  const isImply = 'sensorId' in item;
  const isMultiImply = 'sensorIds' in item;
  const title = replaceVars(vars, item.title);
  if (isParent) {
    return expandMenuItemParent(item as BiaxialChartMenuItemParent, authorized, vars);
  } else if (isImply) {
    return expandMenuItemImply(item as BiaxialChartMenuItemImply, authorized, vars, title);
  } else if (isMultiImply) {
    return expandMenuItemMultiImply(
      item as BiaxialChartMenuItemMultiImply,
      authorized,
      vars,
      title
    );
  }
}

function expandMenuItemSelectable(
  item: BiaxialChartMenuItem,
  authorized: Sensor[],
  vars: VarsConfig,
  parentTitle: string
): BiaxialChartMenuItemSelectable | undefined {
  const isChild = 'property' in item;
  if (isChild) {
    return expandMenuItemChild(item as BiaxialChartMenuItemChild, authorized, vars, parentTitle);
  }
}

function expandImplySection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig,
  parentSectionType?: SectionType
): SectionTypeExpanded | undefined {
  const resolvedSection = section as ImplySection;
  const sensorType = getSensorType(resolvedSection.sensorId);
  const emitterConfigSection = [];

  if (!sensorType) {
    return;
  }

  if (!SectionConfig[sensorType]) {
    return;
  }

  const authorizedSensor = authorized.find((s) => s.id === resolvedSection.sensorId);

  if (!authorizedSensor) {
    return;
  }

  const properties = SectionConfig[sensorType];
  for (const property of properties) {
    const authorizedProperty = findPropertyInSensor(property, authorizedSensor);
    if (authorizedProperty) {
      emitterConfigSection.push(authorizedProperty);
    }
  }

  if (!emitterConfigSection.length) {
    return;
  }

  if (sensorType === 'imu') {
    const roll = findPropertyInSensor(SensorName.IMU.PropertyName.Roll, authorizedSensor);
    const pitch = findPropertyInSensor(SensorName.IMU.PropertyName.Pitch, authorizedSensor);

    return expandImuSection(
      {
        roll: roll && {
          sensorId: roll.sensorId,
          name: roll.name.name,
        },
        pitch: pitch && {
          sensorId: pitch.sensorId,
          name: pitch.name.name,
        },
        ...section,
      } as ImuSection,
      authorized,
      vars
    );
  }

  if (parentSectionType === SectionType.Tabbed) {
    return {
      title: replaceVars(vars, getTitle(section, authorized)),
      type: SectionType.Multi,
      sections: [
        {
          type: SectionType.ComprehensiveCellsNoTitle,
          cells: emitterConfigSection.map((p) => ({
            type: ComprehensiveCellType.KeyValue,
            property: p,
          })),
        },
      ],
    } as MultiSectionExpanded;
  }

  return {
    title: replaceVars(vars, getTitle(section, [authorizedSensor], authorizedSensor.id)),
    type: SectionType.ComprehensiveCells,
    cells: emitterConfigSection.map((p) => ({ type: ComprehensiveCellType.KeyValue, property: p })),
  };
}

function expandCustomCellsSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig,
  parentSectionType?: SectionType
): ComprehensiveCellsSectionExpanded | MultiSectionExpanded | undefined {
  const resolvedSection = section as CustomCellsSection;

  const comprehensiveCellsSection: ComprehensiveCellsSection = {
    title: resolvedSection.title,
    type: SectionType.ComprehensiveCells,
    cells: resolvedSection.properties.map((property) => ({
      type: ComprehensiveCellType.KeyValue,
      property,
    })),
  };

  return expandComprehensiveCellsSection(
    comprehensiveCellsSection,
    authorized,
    vars,
    parentSectionType
  );
}

function expandCustomCellsNoTitleSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig,
  parentSectionType?: SectionType
): ComprehensiveCellsNoTitleSectionExpanded | undefined {
  const resolvedSection = section as CustomCellsNoTitleSection;

  const comprehensiveCellsNoTitleSection: ComprehensiveCellsNoTitleSection = {
    type: SectionType.ComprehensiveCellsNoTitle,
    cells: resolvedSection.properties.map((property) => ({
      type: ComprehensiveCellType.KeyValue,
      property,
    })),
  };

  return expandComprehensiveCellsNoTitleSection(
    comprehensiveCellsNoTitleSection,
    authorized,
    vars,
    parentSectionType
  );
}

function expandComprehensiveCellsSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig,
  parentSectionType?: SectionType
): ComprehensiveCellsSectionExpanded | MultiSectionExpanded | undefined {
  const resolvedSection = section as ComprehensiveCellsSection;
  const validExpandedCells: ComprehensiveCellExpanded[] = [];

  for (const cell of resolvedSection.cells) {
    const expandedCell = expandComprehensiveCell(cell, authorized, vars);
    if (expandedCell) validExpandedCells.push(expandedCell);
  }

  if (!validExpandedCells.length) return undefined;

  if (parentSectionType === SectionType.Tabbed) {
    return {
      title: replaceVars(vars, getTitle(section, authorized)),
      type: SectionType.Multi,
      sections: [
        {
          type: SectionType.ComprehensiveCellsNoTitle,
          cells: validExpandedCells,
        },
      ],
    };
  }

  return {
    title: replaceVars(vars, getTitle(section, authorized)),
    type: SectionType.ComprehensiveCells,
    cells: validExpandedCells,
  };
}

function expandComprehensiveCellsNoTitleSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig,
  parentSectionType?: SectionType
): ComprehensiveCellsNoTitleSectionExpanded | undefined {
  const resolvedSection = section as ComprehensiveCellsNoTitleSection;
  const validExpandedCells: ComprehensiveCellExpanded[] = [];

  for (const cell of resolvedSection.cells) {
    const expandedCell = expandComprehensiveCell(cell, authorized, vars);
    if (expandedCell) validExpandedCells.push(expandedCell);
  }

  if (!validExpandedCells.length) return undefined;

  return {
    type: resolvedSection.type,
    cells: validExpandedCells,
  };
}

type Expanded<C extends ComprehensiveCell> = C extends KeyValueCell
  ? KeyValueCellExpanded
  : C extends OnOffCell
  ? OnOffCellExpanded
  : C extends FuelTypeCell
  ? FuelTypeCellExpanded
  : never;

function expandComprehensiveCell<C extends ComprehensiveCell>(
  cell: C,
  authorized: Sensor[],
  vars: VarsConfig
): Expanded<C> | undefined {
  switch (cell.type) {
    case 'keyValue':
      return expandKeyValueCell(cell, authorized, vars) as Expanded<C>;
    case 'onOff':
      return expandOnOffCell(cell, authorized, vars) as Expanded<C>;
    case 'fuelType':
      return expandFuelTypeCell(cell, authorized, vars) as Expanded<C>;
    default:
      return undefined;
  }
}

function expandKeyValueCell(
  cell: KeyValueCell,
  authorized: Sensor[],
  vars: VarsConfig
): KeyValueCellExpanded | undefined {
  const property = findPropertyInSensors(cell.property.name, cell.property.sensorId, authorized);

  if (!property) return undefined;

  return {
    type: ComprehensiveCellType.KeyValue,
    property,
  };
}

function expandOnOffCell(
  cell: OnOffCell,
  authorized: Sensor[],
  vars: VarsConfig
): OnOffCellExpanded | undefined {
  const property = findPropertyInSensors(cell.property.name, cell.property.sensorId, authorized);

  if (!property) return undefined;

  return {
    title: replaceVars(vars, cell.title),
    type: ComprehensiveCellType.OnOff,
    property,
    onValue: cell.onValue,
    offValue: cell.offValue,
  };
}

function expandFuelTypeCell(
  cell: FuelTypeCell,
  authorized: Sensor[],
  vars: VarsConfig
): FuelTypeCellExpanded | undefined {
  const properties: FuelTypeCellExpanded['fuels'] = [];

  for (const fuel of cell.fuels) {
    const property = findPropertyInSensors(fuel.name, fuel.sensorId, authorized);
    if (property) properties.push(property);
  }

  if (!properties.length) return undefined;

  return {
    title: replaceVars(vars, cell.title),
    type: ComprehensiveCellType.FuelType,
    fuels: properties,
  };
}

function expandMultiSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig
): MultiSection | undefined {
  const resolvedSection = section as MultiSection;
  const sections = resolvedSection?.sections
    .map((s) => expanders[s.type](s, authorized, vars, SectionType.Multi))
    .filter(removeNulls);

  if (sections && sections.length) {
    return {
      title: replaceVars(vars, getTitle(section, authorized)),
      type: SectionType.Multi,
      sections,
    };
  }
}

function expandMultiImplySection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig
): MultiSection | undefined {
  const resolvedSection = section as MultiImplySection;
  const sections = resolvedSection?.sensorIds
    .map((s) =>
      expandImplySection(
        {
          sensorId: s,
          type: SectionType.Imply,
        } as ImplySection,
        authorized,
        vars,
        SectionType.Multi
      )
    )
    .filter(removeNulls);

  if (sections && sections.length) {
    return {
      title: replaceVars(vars, getTitle(section, authorized)),
      type: SectionType.Multi,
      sections,
    };
  }
}

function expandOptionSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig
): OptionSectionExpanded | undefined {
  const resolvedSection = section as OptionSection;
  const sections = resolvedSection?.sections
    .map((s) => expanders[s.type](s, authorized, vars, SectionType.Multi))
    .filter(removeNulls);

  if (sections && sections.length) {
    return {
      title: replaceVars(vars, getTitle(section, authorized)),
      type: SectionType.Option,
      sections,
    };
  }
}

function expandTabbedSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig
): TabbedSectionExpanded | undefined {
  const resolvedSection = section as TabbedSection;
  const sections = [];
  for (const s of resolvedSection?.sections) {
    if (!expanders[s.type]) {
      continue;
    }
    const expandedSection = expanders[s.type](s, authorized, vars, SectionType.Tabbed);
    if (expandedSection && 'title' in expandedSection) {
      sections.push(expandedSection);
    }
  }

  if (sections.length) {
    return {
      title: replaceVars(vars, getTitle(section, authorized)),
      type: SectionType.Tabbed,
      sections,
    };
  }
}

function expandBiaxialChartSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig
): BiaxialChartSectionExpanded {
  const resolvedSection = section as BiaxialChartSection;
  const title = replaceVars(vars, getTitle(resolvedSection, authorized));
  const emitterConfigSection = [];

  for (const i of resolvedSection.menu) {
    const expandedItem = expandMenuItemExpandable(i, authorized, vars);
    if (expandedItem) {
      emitterConfigSection.push(expandedItem);
    }
  }

  const selectedProperties = getSelectedProperties(emitterConfigSection, resolvedSection);

  return {
    title,
    type: resolvedSection.type,
    menu: emitterConfigSection,
    initialSelection1: selectedProperties.at(0),
    initialSelection2: selectedProperties.at(1),
  };
}

function expandImuSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig
): ImuSectionExpanded | undefined {
  const resolvedSection = section as ImuSection;

  const roll = findPropertyInSensors('roll', resolvedSection.roll.sensorId, authorized);
  const pitch = findPropertyInSensors('pitch', resolvedSection.pitch.sensorId, authorized);

  if (!pitch || !roll) {
    return;
  }

  return {
    title: replaceVars(vars, getTitle(resolvedSection, authorized) || 'Stability'),
    type: SectionType.Imu,
    roll,
    pitch,
  };
}

function expandCamerasSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig
): CamerasSectionExpanded | undefined {
  const resolvedSection = section as CamerasSection;
  const cameras: CamerasSectionExpanded['cameras'] = [];

  for (const camera of resolvedSection.cameras) {
    const sensor = findSensorById(camera.sensorId, authorized);
    if (sensor && sensor.name === SensorName.Camera.name) {
      cameras.push({ sensor: sensor as any, title: camera.title });
    }
  }

  return {
    title: replaceVars(vars, getTitle(resolvedSection, authorized) || 'Cameras'),
    type: SectionType.Cameras,
    cameras,
  };
}

function expandLoadProfileSection(
  section: Section,
  authorized: Sensor[],
  vars: VarsConfig
): LoadProfileSectionExpanded | undefined {
  const resolvedSection = section as LoadProfileSection;
  const sensors = authorized.filter((s) => resolvedSection.sensorIds.includes(s.id));
  if (sensors.length) {
    return {
      title: replaceVars(vars, section.title) || 'Operational Load Profile',
      type: resolvedSection.type,
      sensors,
    };
  }
}

export function expandLayout(
  layout: SensorGroupLayout,
  authorizedSensors: Sensor[]
): EmitterLayout {
  if (!layout) {
    return {
      vars: {},
      formatting: {},
      sections: [],
    };
  }

  const expandedSections = [];
  const vars = layout.vars ?? {};
  for (const s of layout.sections) {
    const expander = expanders[s.type];
    if (!expander) {
      continue;
    }
    const expandedSection = expander(s, authorizedSensors, vars);

    if (expandedSection) {
      expandedSections.push(expandedSection);
    }
  }

  return {
    vars,
    formatting: layout.formatting ?? {},
    sections: expandedSections,
  };
}
