import { Emitter } from 'Models';
import {
  DerivedEmissionMessageTest,
  EmissionV2MessageTest,
  GeneratorMessageTest,
  GpsMessageTest,
  ImuMessageTest,
  WeatherMessageTest,
  useMessageTest,
} from 'Networking/socket';

type SocketTesterProps = {
  emitters: Emitter[];
};

export const EmittersSocketTester: React.FC<SocketTesterProps> = ({ emitters }) => {
  useMessageTest(GpsMessageTest, emitters);
  useMessageTest(DerivedEmissionMessageTest, emitters);
  useMessageTest(EmissionV2MessageTest, emitters);
  useMessageTest(ImuMessageTest, emitters);
  useMessageTest(GeneratorMessageTest, emitters);
  useMessageTest(WeatherMessageTest, emitters);

  return null;
};
