import styles from './ChartSectionModal.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Chart, ChartEntry, ChartSeries } from 'Components/Chart';
import { PropertySelector } from './components/PropertySelector';
import { ReactComponent as IconCross } from './icon-cross.svg';
import { useGetPropertiesFramesQuery } from 'Hooks/queries';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import PuffLoader from 'react-spinners/PuffLoader';
import { Seconds } from 'Interfaces';
import { Property } from 'Models';
import { getChartEntries, getChartEntry, getMinSampleRate } from './helpers/chart';
import { getSelectableMenuItemFullTitle } from '../../helpers';
import { ExpandableMenuItem, SelectableMenuItem } from '../../interfaces';
import { getAlias, VariableProperty } from 'Services/redux/api/sensor';
import { useObservePropertyFrame } from '../../hooks/useObservePropertyFrame';
import { MAX_NUMBER_OF_FRAMES } from './constants';

const CHART_SYNC_ID = '__chartSectionModal_chart_syncId__';

type ChartSectionModalProps = {
  items: ExpandableMenuItem[];
  firstPropertyPickerSelectedMenuItem?: SelectableMenuItem;
  secondPropertyPickerSelectedMenuItem?: SelectableMenuItem;
  onSelectMenuItemInFirstPropertyPicker: (menuItem: SelectableMenuItem) => void;
  onSelectMenuItemInSecondPropertyPicker: (menuItem: SelectableMenuItem) => void;
  onClose: () => void;
};

export const ChartSectionModal: React.FC<ChartSectionModalProps> = ({
  items,
  firstPropertyPickerSelectedMenuItem,
  secondPropertyPickerSelectedMenuItem,
  onSelectMenuItemInFirstPropertyPicker,
  onSelectMenuItemInSecondPropertyPicker,
  onClose,
}) => {
  const {
    absoluteInterval: { earliest, latest },
    refresh,
  } = useSelectedInterval();

  useEffect(refresh, [
    firstPropertyPickerSelectedMenuItem,
    secondPropertyPickerSelectedMenuItem,
    refresh,
  ]);

  const firstProperty: VariableProperty | undefined = useMemo(() => {
    if (!firstPropertyPickerSelectedMenuItem) return undefined;

    const property = firstPropertyPickerSelectedMenuItem.property;

    return {
      property,
      variants: [
        {
          alias: getAlias(property, 'min'),
          aggregation: 'min',
        },
        {
          alias: getAlias(property, 'max'),
          aggregation: 'max',
        },
        {
          alias: getAlias(property, 'avg'),
          aggregation: 'avg',
        },
      ],
    };
  }, [firstPropertyPickerSelectedMenuItem]);

  const secondProperty: VariableProperty | undefined = useMemo(() => {
    if (!secondPropertyPickerSelectedMenuItem) return undefined;

    const property = secondPropertyPickerSelectedMenuItem.property;

    return {
      property,
      variants: [
        {
          alias: getAlias(property, 'min'),
          aggregation: 'min',
        },
        {
          alias: getAlias(property, 'max'),
          aggregation: 'max',
        },
        {
          alias: getAlias(property, 'avg'),
          aggregation: 'avg',
        },
      ],
    };
  }, [secondPropertyPickerSelectedMenuItem]);

  const selectedProperties = useMemo(
    () => [firstProperty, secondProperty].filter((p): p is VariableProperty => !!p),
    [firstProperty, secondProperty]
  );

  const minSampleRate = useMemo(() => {
    const properties = [
      firstPropertyPickerSelectedMenuItem?.property,
      secondPropertyPickerSelectedMenuItem?.property,
    ].filter((p): p is Property => !!p);

    return getMinSampleRate(properties);
  }, [
    firstPropertyPickerSelectedMenuItem?.property,
    secondPropertyPickerSelectedMenuItem?.property,
  ]);

  const sampleRate: Seconds = useMemo(() => {
    const deltaSeconds = (new Date(latest).getTime() - new Date(earliest).getTime()) / 1000;

    return Math.max(Math.floor(deltaSeconds / MAX_NUMBER_OF_FRAMES), minSampleRate);
  }, [earliest, latest, minSampleRate]);

  const needsRealtimeData = useMemo(
    () => minSampleRate === sampleRate,
    [minSampleRate, sampleRate]
  );

  const { propertiesFrames, isFetching } = useGetPropertiesFramesQuery({
    properties: selectedProperties,
    earliest,
    latest,
    sample: {
      unit: 'second',
      rate: sampleRate,
    },
    densify: true,
  });

  const [firstMinChartEntries, setFirstMinChartEntries] = useState<ChartEntry[]>([]);
  const [firstMaxChartEntries, setFirstMaxChartEntries] = useState<ChartEntry[]>([]);
  const [firstAvgChartEntries, setFirstAvgChartEntries] = useState<ChartEntry[]>([]);

  const [secondMinChartEntries, setSecondMinChartEntries] = useState<ChartEntry[]>([]);
  const [secondMaxChartEntries, setSecondMaxChartEntries] = useState<ChartEntry[]>([]);
  const [secondAvgChartEntries, setSecondAvgChartEntries] = useState<ChartEntry[]>([]);

  useEffect(() => {
    if (!isFetching && firstPropertyPickerSelectedMenuItem) {
      const minPropertyFrames =
        propertiesFrames[getAlias(firstPropertyPickerSelectedMenuItem.property, 'min')] ?? [];
      const maxPropertyFrames =
        propertiesFrames[getAlias(firstPropertyPickerSelectedMenuItem.property, 'max')] ?? [];
      const avgPropertyFrames =
        propertiesFrames[getAlias(firstPropertyPickerSelectedMenuItem.property, 'avg')] ?? [];

      setFirstMinChartEntries(getChartEntries(minPropertyFrames));
      setFirstMaxChartEntries(getChartEntries(maxPropertyFrames));
      setFirstAvgChartEntries(getChartEntries(avgPropertyFrames));
    } else {
      setFirstMinChartEntries([]);
      setFirstMaxChartEntries([]);
      setFirstAvgChartEntries([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, firstPropertyPickerSelectedMenuItem]);

  useEffect(() => {
    if (!isFetching && secondPropertyPickerSelectedMenuItem) {
      const minPropertyFrames =
        propertiesFrames[getAlias(secondPropertyPickerSelectedMenuItem.property, 'min')] ?? [];
      const maxPropertyFrames =
        propertiesFrames[getAlias(secondPropertyPickerSelectedMenuItem.property, 'max')] ?? [];
      const avgPropertyFrames =
        propertiesFrames[getAlias(secondPropertyPickerSelectedMenuItem.property, 'avg')] ?? [];

      setSecondMinChartEntries(getChartEntries(minPropertyFrames));
      setSecondMaxChartEntries(getChartEntries(maxPropertyFrames));
      setSecondAvgChartEntries(getChartEntries(avgPropertyFrames));
    } else {
      setSecondMinChartEntries([]);
      setSecondMaxChartEntries([]);
      setSecondAvgChartEntries([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, secondPropertyPickerSelectedMenuItem]);

  useObservePropertyFrame(
    firstPropertyPickerSelectedMenuItem?.property,
    (propertyFrame) => {
      if (isFetching) return;

      const chartEntry = getChartEntry(propertyFrame);
      setFirstAvgChartEntries((curr) =>
        (curr.length < MAX_NUMBER_OF_FRAMES ? curr : curr.slice(1)).concat(chartEntry)
      );
    },
    { skip: !needsRealtimeData || isFetching }
  );

  useObservePropertyFrame(
    secondPropertyPickerSelectedMenuItem?.property,
    (propertyFrame) => {
      if (isFetching) return;

      const chartEntry = getChartEntry(propertyFrame);
      setSecondAvgChartEntries((curr) =>
        (curr.length < MAX_NUMBER_OF_FRAMES ? curr : curr.slice(1)).concat(chartEntry)
      );
    },
    { skip: !needsRealtimeData || isFetching }
  );

  const firstChartSeries = useMemo(() => {
    const series: ChartSeries[] = [];

    if (firstPropertyPickerSelectedMenuItem) {
      series.push({
        id: getAlias(firstPropertyPickerSelectedMenuItem.property, 'max'),
        name: `Max ${getSelectableMenuItemFullTitle(firstPropertyPickerSelectedMenuItem)}`,
        unit: firstPropertyPickerSelectedMenuItem.property.unit,
        entries: needsRealtimeData ? firstAvgChartEntries : firstMaxChartEntries,
        maximumFractionDigits: 1,
        color: '#00F4F4',
        dashed: true,
        yAxis: {
          useExistingForSeriesId: getAlias(firstPropertyPickerSelectedMenuItem.property, 'min'),
        },
      });

      series.push({
        id: getAlias(firstPropertyPickerSelectedMenuItem.property, 'avg'),
        name: `Avg ${getSelectableMenuItemFullTitle(firstPropertyPickerSelectedMenuItem)}`,
        unit: firstPropertyPickerSelectedMenuItem.property.unit,
        entries: firstAvgChartEntries,
        maximumFractionDigits: 1,
        color: '#0093dd',
        yAxis: {
          useExistingForSeriesId: getAlias(firstPropertyPickerSelectedMenuItem.property, 'min'),
        },
      });

      series.push({
        id: getAlias(firstPropertyPickerSelectedMenuItem.property, 'min'),
        name: `Min ${getSelectableMenuItemFullTitle(firstPropertyPickerSelectedMenuItem)}`,
        unit: firstPropertyPickerSelectedMenuItem.property.unit,
        entries: needsRealtimeData ? firstAvgChartEntries : firstMinChartEntries,
        maximumFractionDigits: 1,
        color: '#B0AEFF',
        dashed: true,
        yAxis: { createWithOrientation: 'left' },
      });
    }

    return series;
  }, [
    firstPropertyPickerSelectedMenuItem,
    needsRealtimeData,
    firstAvgChartEntries,
    firstMaxChartEntries,
    firstMinChartEntries,
  ]);

  const secondChartSeries = useMemo(() => {
    const series: ChartSeries[] = [];

    if (secondPropertyPickerSelectedMenuItem) {
      series.push({
        id: getAlias(secondPropertyPickerSelectedMenuItem.property, 'max'),
        name: `Max ${getSelectableMenuItemFullTitle(secondPropertyPickerSelectedMenuItem)}`,
        unit: secondPropertyPickerSelectedMenuItem.property.unit,
        entries: needsRealtimeData ? secondAvgChartEntries : secondMaxChartEntries,
        maximumFractionDigits: 1,
        color: '#8CFA00',
        dashed: true,
        yAxis: {
          useExistingForSeriesId: getAlias(secondPropertyPickerSelectedMenuItem.property, 'min'),
        },
      });

      series.push({
        id: getAlias(secondPropertyPickerSelectedMenuItem.property, 'avg'),
        name: `Avg ${getSelectableMenuItemFullTitle(secondPropertyPickerSelectedMenuItem)}`,
        unit: secondPropertyPickerSelectedMenuItem.property.unit,
        entries: secondAvgChartEntries,
        maximumFractionDigits: 1,
        color: '#00dd8d',
        yAxis: {
          useExistingForSeriesId: getAlias(secondPropertyPickerSelectedMenuItem.property, 'min'),
        },
      });

      series.push({
        id: getAlias(secondPropertyPickerSelectedMenuItem.property, 'min'),
        name: `Min ${getSelectableMenuItemFullTitle(secondPropertyPickerSelectedMenuItem)}`,
        unit: secondPropertyPickerSelectedMenuItem.property.unit,
        entries: needsRealtimeData ? secondAvgChartEntries : secondMinChartEntries,
        maximumFractionDigits: 1,
        color: '#82CAFF',
        dashed: true,
        yAxis: { createWithOrientation: 'left' },
      });
    }

    return series;
  }, [
    secondPropertyPickerSelectedMenuItem,
    needsRealtimeData,
    secondAvgChartEntries,
    secondMaxChartEntries,
    secondMinChartEntries,
  ]);

  const isFirstDataEmpty = useMemo(
    () =>
      !firstMinChartEntries.filter((e) => typeof e.value === 'number').length &&
      !firstMaxChartEntries.filter((e) => typeof e.value === 'number').length &&
      !firstAvgChartEntries.filter((e) => typeof e.value === 'number').length,
    [firstAvgChartEntries, firstMaxChartEntries, firstMinChartEntries]
  );

  const isSecondDataEmpty = useMemo(
    () =>
      !secondMinChartEntries.filter((e) => typeof e.value === 'number').length &&
      !secondMaxChartEntries.filter((e) => typeof e.value === 'number').length &&
      !secondAvgChartEntries.filter((e) => typeof e.value === 'number').length,
    [secondAvgChartEntries, secondMaxChartEntries, secondMinChartEntries]
  );

  return (
    <div className={styles.chartSectionModalOverlay}>
      <div className={styles.chartSectionModal}>
        <div className={styles.chartSectionModalHeader}>
          Chart Details
          <button onClick={onClose}>
            <IconCross />
          </button>
        </div>
        <div className={styles.chartSectionModalBody}>
          <div className={styles.controls}>
            <div className={styles.control}>
              <PropertySelector
                indicatorColor="blue"
                items={items}
                selectedItem={firstPropertyPickerSelectedMenuItem}
                onSelectMenuItem={onSelectMenuItemInFirstPropertyPicker}
              />
            </div>
            <div className={styles.control}>
              <PropertySelector
                indicatorColor="green"
                items={items}
                selectedItem={secondPropertyPickerSelectedMenuItem}
                onSelectMenuItem={onSelectMenuItemInSecondPropertyPicker}
              />
            </div>
          </div>
          <div className={styles.charts}>
            {isFetching ? (
              <div className={styles.loadingIndicator}>
                <PuffLoader color="#44ABDF" size={40} />
              </div>
            ) : isFirstDataEmpty && isSecondDataEmpty ? (
              <div className={styles.placeholder}>No data available</div>
            ) : (
              <>
                <div className={styles.chart}>
                  {isFirstDataEmpty ? (
                    <div className={styles.placeholder}>No data available</div>
                  ) : (
                    <Chart series={firstChartSeries} syncId={CHART_SYNC_ID} />
                  )}
                </div>
                <div className={styles.chart}>
                  {isSecondDataEmpty ? (
                    <div className={styles.placeholder}>No data available</div>
                  ) : (
                    <Chart series={secondChartSeries} syncId={CHART_SYNC_ID} />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
