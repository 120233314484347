import styles from './EditVesselModal.module.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'Components/Modal';
import { Emitter, Vessel } from 'Models';
import { hasNonNumericCharacters, isEmailFormat, isPhoneNumberInvalid } from 'Helpers/validator';
import { Dialog, DialogDetails } from 'Components/Dialog';
import { Button } from 'Components/Button';
import { UpdateVesselArguments, useUpdateVesselMutation } from 'Services/redux/api/sensorGroup';
import { motion, AnimatePresence } from 'framer-motion';
import { useQueryClient } from '@tanstack/react-query';

let phoneErrorTimeout: NodeJS.Timeout;
let mmsiErrorTimeout: NodeJS.Timeout;

interface EditVesselModalProps {
  vessel: Vessel;
  onClose: () => void;
}

export const EditVesselModal: React.FC<EditVesselModalProps> = ({ vessel, onClose }) => {
  const queryClient = useQueryClient();

  const [name, setName] = useState(vessel.name);
  const [phone, setPhone] = useState<string | undefined>(vessel.phone);
  const [email, setEmail] = useState<string | undefined>(vessel.email);
  const [captain, setCaptain] = useState<string | undefined>(vessel.captain);
  const [mmsi, setMmsi] = useState<string | undefined>(String(vessel.mmsi ?? ''));

  const [phoneErrorNotification, setPhoneErrorNotification] = useState<string>();
  const [mmsiErrorNotification, setMmsiErrorNotification] = useState<string>();

  const [isOnSubmit, setIsOnSubmit] = useState(false);

  const [dialogDetails, setDialogDetails] = useState<DialogDetails>();

  const [updateVessel] = useUpdateVesselMutation();

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleChangePhone = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (isPhoneNumberInvalid(e.target.value)) {
      if (e.target.value.split('+').length > 2 || e.target.value.endsWith('+')) {
        setPhoneErrorNotification('+ sign is only allowed at the beginning');
      } else {
        setPhoneErrorNotification('Only numbers and + sign are allowed');
      }

      clearTimeout(phoneErrorTimeout);
      phoneErrorTimeout = setTimeout(() => {
        setPhoneErrorNotification(undefined);
      }, 2000);
    } else {
      setPhone(e.target.value);
    }
  }, []);

  const handleChangeEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handleChangeCaptain = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCaptain(e.target.value);
  }, []);

  const handleChangeMmsi = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.charAt(0) === '0') {
      setMmsiErrorNotification('First character cannot be 0');

      clearTimeout(mmsiErrorTimeout);
      mmsiErrorTimeout = setTimeout(() => {
        setMmsiErrorNotification(undefined);
      }, 2000);
    } else if (hasNonNumericCharacters(e.target.value)) {
      setMmsiErrorNotification('Only numbers are allowed');

      clearTimeout(mmsiErrorTimeout);
      mmsiErrorTimeout = setTimeout(() => {
        setMmsiErrorNotification(undefined);
      }, 2000);
    } else {
      setMmsi(e.target.value);
    }
  }, []);

  const emailErrorMessage = useMemo(() => {
    if (email && !isEmailFormat(email)) return 'Invalid email address';
  }, [email]);

  const handleSubmit = useCallback(() => {
    setIsOnSubmit(true);

    const updateVesselArguments: UpdateVesselArguments = {
      vessel,
      body: {
        name,
        phone,
        email,
        captain,
        mmsi: Number(mmsi),
      },
    };

    (async (): Promise<void> => {
      setIsOnSubmit(true);

      try {
        const vessel = await updateVessel(updateVesselArguments).unwrap();
        if (!vessel) return;

        queryClient.setQueryData(['emitters'], (prev: Emitter[] | undefined) => {
          const newReceivers = prev?.map((receiver) => {
            if (receiver.id === vessel.id) {
              // the response from the server fails at returning manager status
              // this will only change the fields that are updated instead of overwriting the whole object
              return {
                ...receiver,
                name: vessel.name,
                phone: vessel.phone,
                email: vessel.email,
                captain: vessel.captain,
                mmsi: vessel.mmsi,
              };
            }

            return receiver;
          });

          return newReceivers;
        });
      } catch (error) {
        console.error(error);
      }

      setIsOnSubmit(false);
      onClose();
    })();
  }, [vessel, name, phone, email, captain, mmsi, onClose, updateVessel, queryClient]);

  const handleCheckSubmit = useCallback(() => {
    const emptyFields = [];

    !phone && emptyFields.push('Phone');
    !email && emptyFields.push('Email');
    !captain && emptyFields.push('Captain');
    !mmsi && emptyFields.push('MMSI');

    const emptyFieldsNotification = emptyFields.join(', ');

    if (emptyFields.length) {
      setDialogDetails({
        level: 'info',
        title: 'Save vessel and ignore empty fields?',
        message: `Some fields are empty. The empty field${
          emptyFields.length > 1 ? 's are' : 'is'
        } "${emptyFieldsNotification}".`,
        cancelButtonTitle: 'Cancel',
        submitButtonTitle: 'Save vessel',
      });
      return;
    }

    handleSubmit();
  }, [captain, email, phone, handleSubmit, mmsi]);

  const handleCancelSubmit = useCallback(() => {
    setDialogDetails(undefined);
  }, []);

  const handleKeepOnSubmit = useCallback(() => {
    setDialogDetails(undefined);
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    if (!name || emailErrorMessage) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [name, emailErrorMessage]);

  return (
    <>
      <Modal
        title="Edit Vessel"
        subtitle="Fill out the information below"
        body={
          <div className={styles.body}>
            <div className={styles.rowInput}>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Name</span>
                    <span className={styles.required}>*</span>
                  </div>
                </div>
                <input type="text" value={name} onChange={handleChangeName} />
              </div>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Phone</span>
                  </div>
                  <AnimatePresence>
                    {phoneErrorNotification && (
                      <motion.span
                        className={styles.errorMessage}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        {phoneErrorNotification}
                      </motion.span>
                    )}
                  </AnimatePresence>
                </div>
                <input type="text" value={phone} onChange={handleChangePhone} />
              </div>
            </div>

            <div className={styles.rowInput}>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Email</span>
                  </div>
                  <AnimatePresence>
                    {emailErrorMessage && (
                      <motion.span
                        className={styles.errorMessage}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        {emailErrorMessage}
                      </motion.span>
                    )}
                  </AnimatePresence>
                </div>
                <input type="email" value={email} onChange={handleChangeEmail} />
              </div>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>Captain</span>
                  </div>
                </div>
                <input type="text" value={captain} onChange={handleChangeCaptain} />
              </div>
            </div>

            <div className={styles.rowInput}>
              <div className={styles.input}>
                <div className={styles.headline}>
                  <div className={styles.title}>
                    <span>MMSI</span>
                  </div>
                  <AnimatePresence>
                    {mmsiErrorNotification && (
                      <motion.span
                        className={styles.errorMessage}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        {mmsiErrorNotification}
                      </motion.span>
                    )}
                  </AnimatePresence>
                </div>
                <input type="text" value={mmsi} onChange={handleChangeMmsi} />
              </div>
            </div>
          </div>
        }
        footer={
          <div className={styles.controlBar}>
            <Button onClick={onClose} color="secondary" disabled={isOnSubmit}>
              Cancel
            </Button>
            <Button
              onClick={handleCheckSubmit}
              disabled={isSubmitButtonDisabled}
              isLoading={isOnSubmit}
            >
              Save
            </Button>
          </div>
        }
        onClose={onClose}
        disableBody={isOnSubmit}
      />
      {dialogDetails && (
        <Dialog
          {...dialogDetails}
          onSelectCancel={handleCancelSubmit}
          onSelectSubmit={handleKeepOnSubmit}
        />
      )}
    </>
  );
};
