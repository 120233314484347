import 'Resources/scss/app.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'Services/redux';
import { NodeServerProvider } from 'Networking/socket';
import { AlertsProvider } from 'Networking/socket/alerts';
import { App } from './App';
import { auth0Configuration, urlConfiguration } from 'Config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { appConfiguration } from 'Config';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

export const BootstrappedApp: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Auth0Provider
        domain={auth0Configuration.domain as string}
        clientId={auth0Configuration.clientId as string}
        audience={auth0Configuration.audience as string}
        redirectUri={window.location.origin}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <Provider store={store}>
          <NodeServerProvider url={urlConfiguration.api as string} path="/socket">
            <AlertsProvider url={urlConfiguration.alert as string} path="/socket">
              <BrowserRouter>
                <App />
                {appConfiguration.debug && <ReactQueryDevtools />}
              </BrowserRouter>
            </AlertsProvider>
          </NodeServerProvider>
        </Provider>
      </Auth0Provider>
    </QueryClientProvider>
  );
};
