import { useEffect, useRef, useState } from 'react';
import { belongsToSameSensor, generateProperyByName } from 'Helpers/sensors';
import { Property } from 'Models';
import { PropertyValue, Sample } from 'Networking/http';
import { ValueContainer } from '../ValueContainer';
import { useNodeServerObserver } from './useNodeServerObserver';
import { OptionalTuple } from 'Types/utility';

type UseLatestObservedSampleOptions = { merge: boolean; includeErrors: boolean };

export function useLatestObservedSample<Values extends Array<PropertyValue>>(
  properties: (Property | undefined)[],
  options: UseLatestObservedSampleOptions = { merge: true, includeErrors: false }
): Sample<OptionalTuple<Values>> | undefined {
  const valueContainerRef = useRef<ValueContainer>();
  const [sample, setSample] = useState<Sample<Values> | undefined>();

  const definedProperties = properties.filter((p): p is Property => !!p);

  console.assert(properties.length > 0, "Properties can't be empty");
  console.assert(
    belongsToSameSensor(definedProperties),
    'Properties must belong to the same sensor'
  );

  const propertiesHash = definedProperties
    .map((p) => p.hash)
    .sort()
    .join();

  const observer = useNodeServerObserver();

  useEffect(() => {
    valueContainerRef.current = new ValueContainer();

    if (!definedProperties.length) return;

    const systemTimeProperty = generateProperyByName(definedProperties[0], 'systemTime');
    const isSatcommProperty = generateProperyByName(definedProperties[0], 'metadata.isSatcomm');
    const errorCodesProperty = generateProperyByName(definedProperties[0], 'errorCodes');

    const subscribedProperties = [...definedProperties, systemTimeProperty, isSatcommProperty];

    if (options.includeErrors) {
      subscribedProperties.push(errorCodesProperty);
    }

    const subscription = observer.subscribe(
      [...definedProperties, systemTimeProperty],
      (newValueContainer) => {
        if (!valueContainerRef.current) return;

        if (options.merge) {
          valueContainerRef.current = valueContainerRef.current.merge(newValueContainer);
        }

        const valueContainer = valueContainerRef.current;
        const values = properties.map((p) => p && valueContainer.getValueForProperty(p));
        const timestamp = valueContainer.getValueForProperty(systemTimeProperty);
        const isSatcomm = valueContainer.getValueForProperty(isSatcommProperty);
        const errorCodes =
          (valueContainer.getValueForProperty(errorCodesProperty) as number[] | null) ?? [];

        if (!timestamp) return;

        setSample([...values, timestamp, isSatcomm, errorCodes] as Sample<Values>);
      }
    );

    return () => observer.unsubscribe(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesHash]);

  return sample;
}
