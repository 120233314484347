import styles from './Tooltip.module.scss';
import { TooltipProps as RechartTooltipProps } from 'recharts';
import Moment from 'react-moment';

type TooltipProps = RechartTooltipProps<number, string> & { header: string };

export const Tooltip: React.FC<TooltipProps> = ({ active, payload, header, formatter }) => {
  if (!active || !payload?.length) return null;
  const firstPayload = payload[0];

  return (
    <div className={styles.tooltip}>
      <h4 className={styles.header}>{header}</h4>
      <hr />
      <p>
        {typeof firstPayload.value === 'number' && (
          <span style={{ color: firstPayload.color }}>
            {(formatter as any)(firstPayload.value)}
          </span>
        )}
        <Moment format="MMM DD, YYYY" className={styles.timestamp}>
          {firstPayload.payload.timestamp}
        </Moment>
      </p>
    </div>
  );
};
