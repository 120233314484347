import styles from './OperationalProfileChart.module.scss';
import { useMemo } from 'react';
import {
  Cell,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';
import { TICK_FONT_FAMILY, TICK_FONT_SIZE, TICK_FONT_WEIGHT } from './constants';

export interface OperationalProfile {
  enginesOnline: number;
  hours: number;
}

type OperationalProfilesChartProps = {
  operationalProfiles: OperationalProfile[];
  duration: number;
};

export const OperationalProfilesChart: React.FC<OperationalProfilesChartProps> = ({
  operationalProfiles,
  duration,
}) => {
  const durationInHours = useMemo(() => duration / 1000 / 60 / 60, [duration]);

  const operationalProfilesWithPercentage = useMemo(
    () =>
      operationalProfiles.map(({ enginesOnline, hours }) => ({
        enginesOnline,
        percentage: hours / durationInHours,
      })),
    [durationInHours, operationalProfiles]
  );

  const barColors = useMemo(
    () => operationalProfiles.map((_, index) => getBarColor(index, operationalProfiles.length)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [operationalProfiles.length]
  );

  return (
    <div className={styles.operationalProfileChart}>
      <ResponsiveContainer>
        <BarChart data={operationalProfilesWithPercentage}>
          <CartesianGrid stroke="#d3d9e7" vertical={false} />
          <XAxis
            dataKey="enginesOnline"
            axisLine={{ stroke: '#d3d9e7' }}
            tickLine={{ stroke: '#d3d9e7' }}
            tick={{
              fontFamily: TICK_FONT_FAMILY,
              fontSize: TICK_FONT_SIZE,
              fontWeight: TICK_FONT_WEIGHT,
              fill: '#000',
            }}
            tickFormatter={xAxisTickFormatter}
          />
          <YAxis
            axisLine={{ stroke: '#d3d9e7' }}
            tickLine={{ stroke: '#d3d9e7' }}
            tick={{
              fontFamily: TICK_FONT_FAMILY,
              fontSize: TICK_FONT_SIZE,
              fontWeight: TICK_FONT_WEIGHT,
              fill: '#000',
            }}
            domain={[0, 1]}
            allowDecimals={false}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            contentStyle={{ border: '1px solid #d3d9e7' }}
            formatter={yAxisTickFormatter as any}
            labelFormatter={xAxisTickFormatter}
          />
          <Bar dataKey="percentage" fill="#8884d8" maxBarSize={80}>
            {barColors.map((_, index) => (
              <Cell key={index} fill={barColors[index]} focusable={false} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

function xAxisTickFormatter(enginesOnline: number) {
  return `${enginesOnline} Engines Online`;
}

function yAxisTickFormatter(percentage: number) {
  return `${(percentage * 100).toFixed(0)}%`;
}

function getBarColor(index: number, numberOfBars: number) {
  const START_HUE = 200;
  const END_HUE = 158;

  let hue: number = START_HUE;

  if (index > 0) {
    const hueIncrement = (END_HUE - START_HUE) / (numberOfBars - 1);
    hue = START_HUE + index * hueIncrement;
  }

  return `hsl(${hue}deg 100% 43%)`;
}
