import { useEffect } from 'react';

export const useKeydown = (code: string, onKeydown: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === code) onKeydown(event);
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [code, onKeydown]);
};
