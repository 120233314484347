import { Dock, Property } from 'Models';
import { AqiPropertyName, AqiSample } from './type';
import { SensorName } from 'Constants';
import { getFirstPropertyWithName, getFirstSensorWithName } from 'Helpers/sensors';
import { useEffect, useMemo } from 'react';
import { useNodeServerObserver } from 'Networking/socket';

interface AqiPropertiesByDock {
  [dockId: Dock['id']]: AqiProperties;
}

interface AqiProperties {
  latitudeProperty: Property<
    typeof SensorName.AirQuality.name,
    typeof SensorName.AirQuality.PropertyName.Latitude.name
  >;
  longitudeProperty: Property<
    typeof SensorName.AirQuality.name,
    typeof SensorName.AirQuality.PropertyName.Longitude.name
  >;
  systemTimeProperty: Property<
    typeof SensorName.AirQuality.name,
    typeof SensorName.AirQuality.PropertyName.SystemTime.name
  >;
  valueProperty: Property<typeof SensorName.AirQuality.name, AqiPropertyName['name']>;
}

interface UseObserveAqiSampleOptions {
  enabled: boolean;
}

export const useObserveAqiSample = (
  docks: Dock[],
  valuePropertyName: AqiPropertyName,
  listener: (dock: Dock, sample: AqiSample) => void,
  options: UseObserveAqiSampleOptions = { enabled: true }
) => {
  const docksAirQualityProperties = useMemo(
    () => getDockAqiProperties(docks, valuePropertyName),
    [docks, valuePropertyName]
  );

  const requiredProperties = useMemo(
    () =>
      Object.values(docksAirQualityProperties).reduce<Property[]>(
        (acc, dap) => acc.concat(Object.values(dap)),
        []
      ),
    [docksAirQualityProperties]
  );

  const observer = useNodeServerObserver();

  useEffect(() => {
    if (!Object.keys(docksAirQualityProperties).length) {
      return;
    }

    if (!options.enabled) {
      return;
    }

    const subscription = observer.subscribe(requiredProperties, (container) => {
      if (!options.enabled) {
        return;
      }

      for (const dock of docks) {
        const coordinateProperties = docksAirQualityProperties[dock.id];

        if (coordinateProperties) {
          const latitude = container.getValueForProperty(coordinateProperties.latitudeProperty);
          const longitude = container.getValueForProperty(coordinateProperties.longitudeProperty);
          const systemTime = container.getValueForProperty(coordinateProperties.systemTimeProperty);
          const value = container.getValueForProperty(coordinateProperties.valueProperty);

          if (
            typeof latitude === 'number' &&
            typeof longitude === 'number' &&
            typeof systemTime === 'string' &&
            typeof value === 'number'
          ) {
            const sample: AqiSample = {
              timestamp: systemTime,
              data: { coordinate: { latitude, longitude }, value },
            };

            listener(dock, sample);
          }
        }
      }
    });

    return () => observer.unsubscribe(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docks, docksAirQualityProperties, observer, requiredProperties]);
};

function getDockAqiProperties(docks: Dock[], valuePropertyName: AqiPropertyName) {
  return docks.reduce<AqiPropertiesByDock>((acc, dock) => {
    const airQualitySensor = getFirstSensorWithName(SensorName.AirQuality, dock);
    const latitudeProperty =
      airQualitySensor &&
      getFirstPropertyWithName(SensorName.AirQuality.PropertyName.Latitude, airQualitySensor);
    const longitudeProperty =
      airQualitySensor &&
      getFirstPropertyWithName(SensorName.AirQuality.PropertyName.Longitude, airQualitySensor);
    const systemTimeProperty =
      airQualitySensor &&
      getFirstPropertyWithName(SensorName.AirQuality.PropertyName.SystemTime, airQualitySensor);
    const valueProperty =
      airQualitySensor && getFirstPropertyWithName(valuePropertyName, airQualitySensor);

    if (latitudeProperty && longitudeProperty && systemTimeProperty && valueProperty) {
      acc[dock.id] = { latitudeProperty, longitudeProperty, systemTimeProperty, valueProperty };
    }

    return acc;
  }, {});
}
