import styles from '../../../ReportsPageContent.module.scss';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { TICK_FONT_FAMILY, TICK_FONT_SIZE, TICK_FONT_WEIGHT } from '../../../constants/chart';
import { getValueFormatter } from 'Pages/ReportsPage/components/ReportsPageContent/helpers/chart';
import { calculateTickLabelWidth } from './helpers/chart';
import { BAR_SIZE, BAR_SPACING, BOTTOM_TICK_HEIGHT, BOTTOM_TICK_MARGIN } from './constants/chart';
import { EntriesByEmitter } from '../../../hooks/useGetEntriesByEmitter';
import { ColoredEmitter } from '../../../interfaces/util';
import { Conversion } from 'Interfaces';
import { exp } from 'array-expression';
import { DEFAULT_MAX_FRACTION_DIGITS } from 'Constants';

type SummarySectionProps = {
  coloredEmitters: ColoredEmitter[];
  entriesByEmitter: EntriesByEmitter;
  conversion?: Conversion;
};

export const SummarySection: React.FC<SummarySectionProps> = ({
  coloredEmitters,
  entriesByEmitter,
  conversion,
}) => {
  const entries = useMemo(() => {
    return coloredEmitters.map(({ emitter, color }) => {
      const entries = entriesByEmitter[emitter.id];

      let sumValue: number | null = null;

      for (const entry of entries) {
        if (typeof entry.value === 'number') {
          if (typeof sumValue === 'number') {
            sumValue += entry.value;
          } else {
            sumValue = entry.value;
          }
        }
      }

      if (conversion && sumValue !== null) {
        sumValue = exp(conversion.exp, { value: sumValue }) as number;
      }

      return {
        name: emitter.name,
        value: sumValue,
        fill: color,
      };
    });
  }, [coloredEmitters, conversion, entriesByEmitter]);

  const height = useMemo(() => {
    return entries.length * (BAR_SIZE + BAR_SPACING + BAR_SPACING) + BOTTOM_TICK_HEIGHT;
  }, [entries]);

  const paddingLeft = useMemo(() => {
    return calculateTickLabelWidth({ categories: entries.map((entry) => entry.name) });
  }, [entries]);

  const valueFormatter = useMemo(() => {
    return getValueFormatter({
      unit: conversion?.unit ?? 'kg',
      maximumFractionDigits: conversion?.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
    });
  }, [conversion?.maxFractionDigits, conversion?.unit]);

  return (
    <section className={styles.chartSection}>
      <div className={styles.header}>
        <h3 className={styles.title}>Summary</h3>
        <div className={styles.legendContainer}>
          {coloredEmitters.map((coloredEmitter) => (
            <span key={coloredEmitter.emitter.id} className={styles.legend}>
              <span className={styles.dot} style={{ backgroundColor: coloredEmitter.color }} />
              {coloredEmitter.emitter.name}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.body}>
        <ResponsiveContainer width="100%" height={height}>
          <BarChart data={entries} layout="vertical">
            <CartesianGrid stroke="#d3d9e7" horizontal={false} />
            <YAxis
              type="category"
              width={paddingLeft}
              dataKey="name"
              axisLine={{ stroke: '#d3d9e7' }}
              tickLine={{ stroke: '#d3d9e7' }}
              tick={{
                fontFamily: TICK_FONT_FAMILY,
                fontSize: TICK_FONT_SIZE,
                fontWeight: TICK_FONT_WEIGHT,
                fill: '#000',
              }}
            />
            <XAxis
              type="number"
              axisLine={{ stroke: '#d3d9e7' }}
              tickLine={{ stroke: '#d3d9e7' }}
              tick={{
                fontFamily: TICK_FONT_FAMILY,
                fontSize: TICK_FONT_SIZE,
                fontWeight: TICK_FONT_WEIGHT,
                fill: '#000',
              }}
              tickMargin={BOTTOM_TICK_MARGIN}
              tickFormatter={valueFormatter}
            />
            <Bar dataKey="value" fill="#8884d8" barSize={24}>
              <LabelList
                dataKey="value"
                position="top"
                fontFamily={TICK_FONT_FAMILY}
                fontSize={TICK_FONT_SIZE}
                fontWeight={TICK_FONT_WEIGHT}
                formatter={valueFormatter}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};
