import { Ais, Vessel } from 'Models';
import { transformIntoEmitter } from './emitter.transformer';
import { resolveEmitterRotation } from 'Services/emitters/helpers';
import { transformIntoAis } from './ais.transformer';

export const transformIntoVessel = (record: Record<string, any>): Vessel => {
  const ais: Ais | undefined = record.metadata.ais && transformIntoAis(record.metadata.ais);

  return {
    ...transformIntoEmitter(record),
    lastCog: record.metadata.lastCog,
    lastSog: record.metadata.lastSog,
    systemTime: record.metadata.systemTime,
    phone: record.metadata.phone,
    mmsi: record.metadata.mmsi ? Number(record.metadata.mmsi) : undefined,
    email: record.metadata.email,
    captain: record.metadata.captain,
    rotation: resolveEmitterRotation({
      sog: record.metadata.lastSog,
      cog: record.metadata.lastCog,
      trueHeading: ais?.trueHeading,
    }),
    hardware: record.hardware,
    ais,
  };
};
