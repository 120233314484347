import styles from './TopBar.module.scss';
import React, { useCallback, useMemo } from 'react';
import { ReactComponent as PlusIcon } from './resources/graphics/icon-plus.svg';
import { ReactComponent as IconArrowDown } from './resources/graphics/icon-arrow-down.svg';
import { usePopup } from 'Hooks/popup/usePopup';
import { SelectPopup } from 'Components/SelectPopup';
import { Namespace } from 'Services/redux/api/users';

type TopBarProps = {
  onClickAddNewUser: () => void;
  namespaces?: Namespace[];
  onSelectTab: (index: number) => void;
  currentTabIndex?: number;
};

export const TopBar: React.FC<TopBarProps> = ({
  onClickAddNewUser,
  namespaces,
  onSelectTab,
  currentTabIndex,
}) => {
  const tabs = useMemo(() => {
    return namespaces?.map((namespace) => namespace.displayName);
  }, [namespaces]);

  const [
    isSelectPopupVisible,
    setSelectPopupToVisible,
    setSelectPopupToNotVisible,
    selectPopupRef,
    toggleRef,
  ] = usePopup();

  const handleOnSelectTab = useCallback(
    (index: number) => {
      onSelectTab(index);
      setSelectPopupToNotVisible();
    },
    [onSelectTab, setSelectPopupToNotVisible]
  );

  const handleOnClickFilter = useCallback(() => {
    isSelectPopupVisible ? setSelectPopupToNotVisible() : setSelectPopupToVisible();
  }, [isSelectPopupVisible, setSelectPopupToVisible, setSelectPopupToNotVisible]);

  return (
    <div className={styles.topBar}>
      <div className={styles.userNavigator}>
        {tabs && tabs.length > 1 && currentTabIndex !== undefined && (
          <div className={styles.tabFilter}>
            <div>
              <div className={styles.namespace} onClick={handleOnClickFilter} ref={toggleRef}>
                <span className={styles.title}>{tabs[currentTabIndex]}</span>
                <IconArrowDown
                  className={styles.expandIndicator}
                  style={{ transform: isSelectPopupVisible ? 'rotate(180deg)' : undefined }}
                />
              </div>
              {isSelectPopupVisible && (
                <div className={styles.selectTabPopup} ref={selectPopupRef}>
                  <SelectPopup
                    items={tabs}
                    onSelect={handleOnSelectTab}
                    selectedItemIndex={currentTabIndex}
                    searchable
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <button className={styles.addUser} onClick={onClickAddNewUser}>
        <PlusIcon />
        <span>Add new user</span>
      </button>
    </div>
  );
};
