import { apiApi } from 'Services/redux/api/api.api';
import { Dock, Emitter, Vessel } from 'Models';
import { SensorGroup } from './interfaces';
import { transformIntoEmitter } from './transformers';
import { isDock, isVessel } from 'Helpers/emitter';

export type UpdateVesselArguments = {
  vessel: Vessel;
  body: {
    name?: string;
    phone?: string;
    email?: string;
    captain?: string;
    mmsi?: number;
  };
};

export type UpdateDockArguments = {
  dock: Dock;
  body: {
    name: string;
  };
};

const editVesselBodyTransform = (body: UpdateVesselArguments['body']) => ({
  name: body.name,
  metadata: {
    captain: body.captain,
    email: body.email,
    phone: body.phone,
    mmsi: body.mmsi,
  },
});

export const sensorGroupApi = apiApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmitters: builder.query<Emitter[], void>({
      query: () => ({
        url: 'sensorGroups',
      }),
      transformResponse: (sensorGroups: SensorGroup<unknown>[]) =>
        sensorGroups.map((s) => transformIntoEmitter(s)).filter((s): s is Emitter => !!s),
    }),
    updateVessel: builder.mutation<Vessel | undefined, UpdateVesselArguments>({
      query: ({ vessel, body }) => {
        return {
          url: `sensorGroups/${vessel.id}`,
          method: 'PUT',
          body: editVesselBodyTransform(body),
        };
      },
      transformResponse: (emitterRecord: SensorGroup<unknown>) => {
        const emitter = transformIntoEmitter(emitterRecord);

        if (!emitter || !isVessel(emitter)) return;

        return emitter;
      },
      async onQueryStarted({ vessel, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedVessel } = await queryFulfilled;

          if (!updatedVessel) return;

          dispatch(
            sensorGroupApi.util.updateQueryData('getEmitters', undefined, (draftEmitters) => {
              // Any is required because Typescript excessive depth bug
              const index = (draftEmitters as any).findIndex((e: any) => e.id === updatedVessel.id);
              if (index !== -1) (draftEmitters as any).splice(index, 1, updatedVessel);
            })
          );
        } catch {}
      },
    }),
    updateDock: builder.mutation<Dock | undefined, UpdateDockArguments>({
      query: ({ dock, body }) => {
        return {
          url: `sensorGroups/${dock.id}`,
          method: 'PUT',
          body: {
            name: body.name,
          },
        };
      },
      transformResponse: (emitterRecord: SensorGroup<unknown>) => {
        const emitter = transformIntoEmitter(emitterRecord);

        if (!emitter || !isDock(emitter)) return;

        return emitter;
      },
    }),
  }),
});

export const {
  useGetEmittersQuery,
  useLazyGetEmittersQuery,
  useUpdateVesselMutation,
  useUpdateDockMutation,
} = sensorGroupApi;
