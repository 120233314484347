import styles from './StepSensor.module.scss';
import { RadioButton } from 'Components/RadioButton';
import { SensorOption } from '../../interfaces';
import { Emitter } from 'Models';
import { useMemo } from 'react';
import { getFirstSensorWithName } from 'Helpers/sensors';
import { ISensorName } from 'Constants';

type StepSensorProps = {
  emitter: Emitter;
  sensorOptions: SensorOption[];
  selectedSensorOption?: SensorOption;
  onSelectSensorOption: (sensorOption: SensorOption) => void;
};

export const StepSensor: React.FC<StepSensorProps> = ({
  emitter,
  sensorOptions,
  selectedSensorOption,
  onSelectSensorOption,
}) => {
  return (
    <div className={styles.stepSensor}>
      {sensorOptions.map((sensorOption) => (
        <SensorOptionComponent
          key={sensorOption.name}
          emitter={emitter}
          sensorOption={sensorOption}
          selected={sensorOption.name === selectedSensorOption?.name}
          onSelect={() => onSelectSensorOption(sensorOption)}
        />
      ))}
    </div>
  );
};

type SensorOptionProps = {
  emitter: Emitter;
  sensorOption: SensorOption;
  selected: boolean;
  onSelect: () => void;
};

const SensorOptionComponent: React.FC<SensorOptionProps> = ({
  emitter,
  sensorOption,
  selected,
  onSelect,
}) => {
  const sensorDisplayName = useMemo(() => {
    let displayName: string | undefined;

    for (const scope of sensorOption.scopes) {
      const sensorName: ISensorName = {
        name: scope.sensorName,
        PropertyName: undefined as never,
      };

      const matchingSensor = getFirstSensorWithName(sensorName, emitter);
      if (matchingSensor) {
        displayName = matchingSensor.displayName;
        break;
      }
    }

    return displayName;
  }, [emitter, sensorOption.scopes]);

  return (
    <div key={sensorOption.name} className={styles.option} onClick={onSelect}>
      <RadioButton selected={selected} />
      <span>{sensorDisplayName ?? sensorOption.name}</span>
    </div>
  );
};
