import { Emitter, Property, UserAccess } from 'Models';

export const getPropertiesFromUserAccess = (
  userAccess: UserAccess,
  emitter: Emitter
): Property[] => {
  const properties: Property[] = [];

  for (const { sensorId, properties: propertyNames } of userAccess.sensors) {
    const sensor = emitter.sensors.find((s) => s.id === sensorId);

    if (sensor) {
      const foundProperties = sensor.properties.filter((p) => propertyNames.includes(p.name.name));
      properties.push(...foundProperties);
    }
  }

  return properties;
};
