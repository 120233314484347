import { Socket, io } from 'socket.io-client';
import { Alert } from 'Models';
import { transformIntoAlert } from 'Services/redux/alerts/alerts/transformers';
import {
    AlertStateChangeEvent
} from 'Services/redux/alerts//alerts/interfaces';

export class SubscriptionManager {
    private socket: Socket;
    constructor(readonly url: string, readonly path: string, private token: string) {
        this.socket = io(url, {
            transports: ['websocket'],
            path: path,
            auth: {
                token
            },
            withCredentials: false,
        });
    }

    subscribeResolved(cb: (alert: Alert) => void): () => void {
        const fn = (r: AlertStateChangeEvent) => cb(transformIntoAlert(r.alert))
        this.socket.on('alert-resolved', fn)
        return () => this.socket.off('alert-resolved', fn)
    }

    subscribeAlerted(cb: (alert: Alert) => void): () => void {
        const fn = (r: AlertStateChangeEvent) => cb(transformIntoAlert(r.alert))
        this.socket.on('alert', fn)
        return () => this.socket.off('alert', fn)
    }

    subscribeRead(cb: (alert: Alert) => void): () => void {
        const fn = (r: AlertStateChangeEvent) => cb(transformIntoAlert(r.alert))
        this.socket.on('alert-read', fn)
        return () => this.socket.off('alert-read', fn)
    }

    subscribeUnread(cb: (alert: Alert) => void): () => void {
        const fn = (r: AlertStateChangeEvent) => cb(transformIntoAlert(r.alert))
        this.socket.on('alert-unread', fn)
        return () => this.socket.off('alert-unread', fn)
    }

    updateToken(token: string) {
        this.socket.auth = {
            token,
        };

        if (token !== this.socket.auth.token) {
            this.socket.disconnect().connect();
        }
    }
}