import { IPropertyName, SensorName } from 'Constants';

export interface EmissionOption {
  label: string;
  propertyNames: IPropertyName[];
}

export const EMISSION_OPTIONS: EmissionOption[] = [
  {
    label: 'CO2',
    propertyNames: [
      SensorName.EmissionV2.PropertyName.CO2MassFlow,
      SensorName.Virt.PropertyName.CO2MassFlowFromConc,
    ],
  },
  {
    label: 'CO',
    propertyNames: [SensorName.EmissionV2.PropertyName.COMassFlow],
  },
  {
    label: 'CH4',
    propertyNames: [SensorName.EmissionV2.PropertyName.CH4MassFlow],
  },
  {
    label: 'SO2',
    propertyNames: [SensorName.EmissionV2.PropertyName.SO2MassFlow],
  },
  {
    label: 'NOX',
    propertyNames: [SensorName.EmissionV2.PropertyName.NOXMassFlow],
  },
];
