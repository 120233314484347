import { Emitter, Vessel, Property } from 'Models';
import {
  EmitterDetailsBarSection,
  EmitterDetailsBarSectionHeaderRegular,
} from 'Pages/DashboardPage/components/EmitterDetailsBar/components';
import { EmitterDetailsBarCellSet } from 'Pages/DashboardPage/components/EmitterDetailsBar/components/EmitterDetailsBarCellSet';
import { useMemo } from 'react';
import { SummarySectionPropertiesSumCell } from './components/SummarySectionPropertiesSumCell';
import { getFirstSensorWithName, getPropertiesWithName } from 'Helpers/sensors';
import { SensorName } from 'Constants';
import { KeyValueCell } from '../../KeyValueCell';
import { SummarySectionPowerFactorCell } from './components/SummarySectionPowerFactorCell';
import { SummarySectionFuelMassFlowCell } from './components/SummarySectionFuelMassFlowCell';
import { PropertyCell } from '../../PropertyCell';

interface SummarySectionProps {
  emitter: Emitter;
}

export function SummarySection({ emitter }: SummarySectionProps) {
  const sensor = useMemo(() => {
    return getFirstSensorWithName(SensorName.Virt, emitter);
  }, [emitter]);

  const hasCo2MassFlowProperties = useMemo(() => {
    return (
      getPropertiesWithName(SensorName.EmissionV2.PropertyName.CO2MassFlow, sensor as any).length >
      0
    );
  }, [sensor]);

  const hasSogProperty = useMemo(() => {
    return getPropertiesWithName(SensorName.GPS.PropertyName.SOG, sensor as any).length > 0;
  }, [sensor]);

  const hasActivePowerProperties = useMemo(() => {
    return (
      getPropertiesWithName(SensorName.Engine.PropertyName.ActivePower, sensor as any).length > 0
    );
  }, [sensor]);

  const hasReactivePowerProperties = useMemo(() => {
    return (
      getPropertiesWithName(SensorName.Engine.PropertyName.ReactivePower, sensor as any).length > 0
    );
  }, [sensor]);

  const co2PerDayProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(SensorName.Virt.PropertyName.CO2Mass24HrsDailyAvg, sensor as any)[0];
  }, [sensor]);

  const co2PerNMProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(SensorName.Virt.PropertyName.CO2MassAvgPerNm, sensor as any)[0];
  }, [sensor]);

  const fuelPerDayProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(SensorName.Virt.PropertyName.FuelPerDay, sensor as any)[0];
  }, [sensor]);

  const fuelPerNMProperty: Property | undefined = useMemo(() => {
    return getPropertiesWithName(SensorName.Virt.PropertyName.FuelPerNM, sensor as any)[0];
  }, [sensor]);


  if (!sensor) {
    return null;
  }

  return (
    <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title="Summary" />}>
      <EmitterDetailsBarCellSet>
        <KeyValueCell title="CII YTD" showPlaceholder={true} />

        {hasCo2MassFlowProperties ? (
          <SummarySectionPropertiesSumCell
            title="CO2 Mass Flow"
            sensor={sensor}
            propertyName={SensorName.EmissionV2.PropertyName.CO2MassFlow}
          />
        ) : (
          <KeyValueCell title="CO2 Mass Flow" />
        )}

       {co2PerDayProperty ? (
        <PropertyCell title="CO2 per day" key={co2PerDayProperty.hash} property={co2PerDayProperty} />
       ): 
       (
        <KeyValueCell title="CO2 per day" showPlaceholder={true} />
       )}

      {co2PerNMProperty ? (
        <PropertyCell title="CO2 per Nm" key={co2PerNMProperty.hash} property={co2PerNMProperty} />
       ): 
       (
        <KeyValueCell title="CO2 per NM" showPlaceholder={true} />
       )}

        <KeyValueCell title="SFOC" showPlaceholder={true} />

        <SummarySectionFuelMassFlowCell title="Fuel mass flow" vessel={emitter as Vessel} />

        {fuelPerDayProperty ? (
        <PropertyCell title="Fuel per day" key={fuelPerDayProperty.hash} property={fuelPerDayProperty} />
       ): 
       (<KeyValueCell title="Fuel per day" showPlaceholder={true} />)}
        {fuelPerNMProperty ? (
        <PropertyCell title="Fuel per NM" key={fuelPerNMProperty.hash} property={fuelPerNMProperty} />
       ): 
       (<KeyValueCell title="Fuel per NM" showPlaceholder={true} />)}

        {hasSogProperty ? (
          <SummarySectionPropertiesSumCell
            title="Vessel SOG"
            sensor={sensor}
            propertyName={SensorName.GPS.PropertyName.SOG}
          />
        ) : (
          <KeyValueCell title="SOG" />
        )}

        <KeyValueCell title="Propulsion RPM" showPlaceholder={true} />

        {hasActivePowerProperties ? (
          <SummarySectionPropertiesSumCell
            title="Active power"
            sensor={sensor}
            propertyName={SensorName.Engine.PropertyName.ActivePower}
          />
        ) : (
          <KeyValueCell title="Active power" />
        )}

        {hasActivePowerProperties && hasReactivePowerProperties ? (
          <SummarySectionPowerFactorCell title="Power Factor" sensor={sensor} />
        ) : (
          <KeyValueCell title="Power Factor" />
        )}
      </EmitterDetailsBarCellSet>
    </EmitterDetailsBarSection>
  );
}
