import { Ais } from 'Models';

export const transformIntoAis = (record: Record<string, any>): Ais => ({
  id: String(record.mmsi),
  cog: record.cog,
  coordinate: { latitude: record.latitude as number, longitude: record.longitude as number },
  mmsi: record.mmsi,
  trueHeading: record.trueHeading,
  callsign: record.callsign,
  destination: record.destination,
  eta: record.eta,
  flag: record.flag,
  imo: record.imo,
  maxDraught: record.maxDraughtMeters,
  name: record.name,
  type: record.type,
  sog: record.cog,
  status: record.status,
  systemTime: record.systemTime,
});
