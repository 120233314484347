import { Dock } from 'Models';
import { AqiPropertyName, LatestAqiSampleByDock } from './type';
import { useLatestAqiSampleByDock } from './useLatestAqiSampleByDock';
import { useObserveAqiSample } from './useObserveAqiSample';
import { useEffect, useState } from 'react';
import { AbsoluteInterval } from 'Constants';

interface UseSyncedLatestAqiSampleByDockArgument {
  docks: Dock[];
  interval: AbsoluteInterval;
  propertyName: AqiPropertyName;
}

interface UseSyncedLatestApiSampleByDockOptions {
  enabled: boolean;
}

export const useSyncedLatestAqiSampleByDock = (
  arg: UseSyncedLatestAqiSampleByDockArgument,
  options: UseSyncedLatestApiSampleByDockOptions = { enabled: true }
) => {
  const { isLoading, isFetching, latestAqiSampleByDock } = useLatestAqiSampleByDock(arg, {
    enabled: options.enabled,
  });
  const [syncedLatestAqiSampleByDock, setSyncedLatestAqiSampleByDock] =
    useState<LatestAqiSampleByDock>({});

  useEffect(() => {
    setSyncedLatestAqiSampleByDock(latestAqiSampleByDock);
  }, [latestAqiSampleByDock]);

  useObserveAqiSample(
    arg.docks,
    arg.propertyName,
    (dock, sample) => {
      setSyncedLatestAqiSampleByDock((syncedLatestAqiSampleByDock) => {
        if (isFetching) {
          return syncedLatestAqiSampleByDock;
        }

        const latestAqiSample = syncedLatestAqiSampleByDock[dock.id];

        if (!latestAqiSample) {
          return syncedLatestAqiSampleByDock;
        }

        if (sample.timestamp <= latestAqiSample.timestamp) {
          return syncedLatestAqiSampleByDock;
        }

        return {
          ...syncedLatestAqiSampleByDock,
          [dock.id]: sample,
        };
      });
    },
    {
      enabled: options.enabled,
    }
  );

  return { isLoading, isFetching, syncedLatestAqiSampleByDock };
};
