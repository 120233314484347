import styles from './EmitterDetailsBarMenu.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import { OptionPopup } from 'Components/OptionPopup';
import { EMITTER_DETAILS_BAR_ID } from '../../constants/layout';

type EmitterDetailsBarMenuProps = {
  menuItems: string[];
  selectedMenuItemIndex: number;
  onSelectMenuItem(index: number): void;
};

export const EmitterDetailsBarMenu: React.FC<EmitterDetailsBarMenuProps> = ({
  menuItems,
  selectedMenuItemIndex,
  onSelectMenuItem,
}) => {
  const toggleRef = useRef<HTMLDivElement>(null);
  const [isOptionPopupVisible, setIsOptionPopupVisible] = useState(false);

  const handleClickToggle = useCallback(() => {
    setIsOptionPopupVisible((isOptionPopupVisible) => !isOptionPopupVisible);
  }, []);

  const handleSelectOptionPopupOption = useCallback(
    (index: number) => {
      onSelectMenuItem(index);
      setIsOptionPopupVisible(false);
    },
    [onSelectMenuItem]
  );

  const handleClickOutsidePopupOption = useCallback(() => {
    setIsOptionPopupVisible(false);
  }, []);

  useEffect(() => {
    const emitterDetailsBar = document.getElementById(EMITTER_DETAILS_BAR_ID);

    if (!emitterDetailsBar) return;

    const listener = () => {
      setIsOptionPopupVisible(false);
    };

    emitterDetailsBar.addEventListener('scroll', listener);
    window.addEventListener('resize', listener);

    return () => {
      emitterDetailsBar.removeEventListener('scroll', listener);
      window.removeEventListener('resize', listener);
    };
  }, []);

  return (
    <div className={styles.emitterDetailsBarMenu}>
      <div className={styles.menuItemSelector}>
        <div className={styles.title} ref={toggleRef} onClick={handleClickToggle}>
          {menuItems[selectedMenuItemIndex]}
          <IconArrowDown
            style={{ transform: isOptionPopupVisible ? 'rotate(180deg)' : undefined }}
          />
        </div>
        {isOptionPopupVisible && (
          <OptionPopup
            options={menuItems}
            selectedIndex={selectedMenuItemIndex}
            onSelectOption={handleSelectOptionPopupOption}
            onClickOutside={handleClickOutsidePopupOption}
            sourceRef={toggleRef}
          />
        )}
      </div>
    </div>
  );
};
