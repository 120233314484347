import { urlConfiguration } from 'Config';
import { AbsoluteInterval } from 'Constants';
import { getUniqueProperties } from 'Helpers/sensors';
import { Property, Sensor } from 'Models';
import { MutationFunction } from 'Networking/http/interfaces';
import { getAccessTokenSilently } from 'Services/auth0';
import axios from 'axios';

export class DownloadInProgressError extends Error {}

export interface UseExportEmitterMutationArgument {
  properties: Property[];
  interval: AbsoluteInterval;
}

export interface UseExportEmitterMutationResponse {
  secret: string;
}

const exportEmitter: MutationFunction<
  UseExportEmitterMutationArgument,
  UseExportEmitterMutationResponse
> = async ({ properties, interval }) => {
  if (!properties.length) {
    throw Error('Properties cannot be empty.');
  }

  const emitterId = properties[0].sensorGroupId;

  if (!properties.every((p) => p.sensorGroupId === emitterId)) {
    throw Error('Properties must belong to the same sensorGroup');
  }

  const token = await getAccessTokenSilently();

  try {
    const { data } = await axios.post<{ secret: string }>(
      `${urlConfiguration.api}/sensorGroups/${emitterId}/export`,
      {
        earliest: interval.earliest,
        latest: interval.latest,
        sensors: generateSensorPayloads(properties),
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.data.code === 'DOWNLOAD_IN_PROGRESS') {
        throw new DownloadInProgressError();
      }

      throw err;
    } else {
      throw err;
    }
  }
};

interface SensorPayload {
  sensorId: Sensor['id'];
  properties: string[];
}

function generateSensorPayloads(properties: Property[]): SensorPayload[] {
  const sensorPayloads: SensorPayload[] = [];

  const uniqueProperties = getUniqueProperties(properties);

  for (const property of uniqueProperties) {
    const prop = property.name.name;

    const existingSensorPayload = sensorPayloads.find((p) => p.sensorId === property.sensorId);

    if (existingSensorPayload) {
      existingSensorPayload.properties.push(prop);
    } else {
      sensorPayloads.push({
        sensorId: property.sensorId,
        properties: [prop],
      });
    }
  }

  return sensorPayloads;
}

export { exportEmitter };
