import {
  TICK_FONT_FAMILY,
  TICK_FONT_SIZE,
  TICK_FONT_WEIGHT,
} from 'Pages/ReportsPage/components/ReportsPageContent/constants/chart';

export const calculateTickLabelWidth = ({ categories }: { categories: string[] }) => {
  const context = document.createElement('canvas').getContext('2d')!;
  context.font = `${TICK_FONT_WEIGHT} ${TICK_FONT_SIZE} ${TICK_FONT_FAMILY}`;

  const TICK_LINE_WIDTH = 8;
  const width = Math.max(...categories.map((category) => context.measureText(category).width));

  return width + TICK_LINE_WIDTH;
};
