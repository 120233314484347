import { urlConfiguration } from 'Config';
import { SensorName } from 'Constants';
import { Sensor } from 'Models';
import { getAccessTokenSilently } from 'Services/auth0';
import { FetchFunction } from '../interfaces';
import axios from 'axios';

export class CameraUnreachableError extends Error {}

export interface FetchVideoUrlArgument {
  cameraSensor: Sensor<typeof SensorName.Camera.name>;
}

const fetchVideoUrl: FetchFunction<FetchVideoUrlArgument, string> = async ({ cameraSensor }) => {
  const token = await getAccessTokenSilently();
  const url = `${urlConfiguration.sensorStream}/video/${cameraSensor.id}`;

  return axios
    .get<string>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        credentials: 'url',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 500) {
        throw new CameraUnreachableError();
      }

      throw err;
    });
};

fetchVideoUrl.generateQueryKey = ({ cameraSensor }: FetchVideoUrlArgument) => {
  return [`cameras/${cameraSensor.id}`];
};

export { fetchVideoUrl };
