import { Degrees, Knots, Meters } from 'Interfaces';
import { Coordinate } from './coordinate';

export type Ais = AisStatic & AisPositional;

export interface AisPositional {
  id?: string;
  cog?: Degrees;
  coordinate?: Coordinate;
  mmsi?: number;
  trueHeading?: Degrees;
  type?: string;
  sog?: Knots;
  status?: AisStatus;
  systemTime?: string;
}

export interface AisStatic {
  id?: string;
  name?: string;
  callsign?: string;
  destination?: string;
  eta?: string;
  flag?: string;
  imo?: string;
  mmsi?: number;
  maxDraught?: Meters;
  systemTime?: string;
}

export interface AisPositional {
  id?: string;
  accuracy?: boolean;
  cog?: Degrees;
  coordinate?: Coordinate;
  mmsi?: number;
  systemTime?: string;
  trueHeading?: Degrees;
  type?: string;
  sog?: Knots;
  status?: AisStatus;
}

export enum AisStatus {
  UnderWayUsingEngine = 0,
  AtAnchor,
  NotUnderCommand,
  RestrictedManeuverability,
  ConstrainedByHerDraught,
  Moored,
  Aground,
  EngagedInFishing,
  UnderWaySailing,
}
