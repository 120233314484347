import { useEffect } from 'react';
import { PropertyFrame } from 'Hooks/queries';
import { Property } from 'Models';
import { useNodeServerObserver } from 'Networking/socket';
import { createPropertyName, generatePropertyHash } from 'Helpers/sensors';

export const useObservePropertyFrame = (
  property: Property | undefined,
  listener: (propertyFrame: PropertyFrame) => void,
  options: { skip: boolean } = { skip: false }
) => {
  const observer = useNodeServerObserver();

  useEffect(() => {
    if (!property || options.skip) return;

    const systemTimeProperty = getPropertyByName(property, 'systemTime');
    const errorCodes = getPropertyByName(property, 'errorCodes');
    const isSatcomm = getPropertyByName(property, 'metadata.isSatcomm');

    const subscribedProperties = [property, systemTimeProperty];

    if (errorCodes) {
      subscribedProperties.push(errorCodes);
    }

    if (isSatcomm) {
      subscribedProperties.push(isSatcomm);
    }

    const subscription = observer.subscribe(subscribedProperties, (container) => {
      const value = container.getValueForProperty(property);
      const systemTime = container.getValueForProperty(systemTimeProperty);

      if (typeof value === 'number' && typeof systemTime === 'string') {
        const propertyFrame: PropertyFrame = {
          value,
          date: systemTime,
          isSatcomm: isSatcomm && container.getValueForProperty(isSatcomm),
          errors: errorCodes ? (container.getValueForProperty(errorCodes) as number[] | null) : [],
        };
        listener(propertyFrame);
      }
    });

    return () => observer.unsubscribe(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer, property, options.skip]);
};

const getPropertyByName = (
  property: Property,
  name: 'metadata.isSatcomm' | 'systemTime' | 'errorCodes'
): Property => {
  const path = property.name.path.replace(/\/[^/]*$/, name);

  const propertyName = createPropertyName(name, path);

  return {
    name: propertyName,
    displayName: 'Time',
    sensorGroupId: property.sensorGroupId,
    sensorId: property.sensorId,
    sensorName: property.sensorName,
    sensorDisplayName: property.sensorDisplayName,
    sampleRate: property.sampleRate,
    hash: generatePropertyHash(property.sensorId, propertyName),
  };
};
