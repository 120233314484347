import { ParentSize } from '@visx/responsive';
import { BiaxialChart } from './BiaxialChart';
import { ChartSeries } from './interfaces';

type ResponsiveBiaxialChartProps = {
  firstSeries?: ChartSeries;
  secondSeries?: ChartSeries;
  linePointerTimestamp?: string;
};

export const ResponsiveBiaxialChart: React.FC<ResponsiveBiaxialChartProps> = ({
  firstSeries,
  secondSeries,
  linePointerTimestamp,
}) => {
  return (
    <ParentSize>
      {(parent) => (
        <BiaxialChart
          width={parent.width}
          height={parent.height}
          firstSeries={firstSeries}
          secondSeries={secondSeries}
          linePointerTimestamp={linePointerTimestamp}
        />
      )}
    </ParentSize>
  );
};
