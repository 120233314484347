import { useCallback } from 'react';
import ReactSelect, { SingleValue } from 'react-select';
import { stylesConfig } from './constants';

type SelectProps<T> = {
  options: T[];
  selectedOption: T;
  onSelectOption: (option: T) => void;
  className?: string;
};

export function Select<T>({ options, selectedOption, onSelectOption, className }: SelectProps<T>) {
  const handleChange = useCallback(
    (selectedOption: SingleValue<T>) => {
      if (selectedOption) onSelectOption(selectedOption);
    },
    [onSelectOption]
  );

  return (
    <ReactSelect
      value={selectedOption}
      options={options}
      onChange={handleChange}
      styles={stylesConfig}
      className={className}
    />
  );
}
