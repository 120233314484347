import { SensorName } from 'Constants';
import { getFirstPropertyWithName } from 'Helpers/sensors';
import { Emitter, Property } from 'Models';
import {
  EmitterDetailsBarSection,
  EmitterDetailsBarSectionHeaderRegular,
} from 'Pages/DashboardPage/components/EmitterDetailsBar/components';
import { ReactElement, useMemo } from 'react';
import { PropertyCell } from '../../PropertyCell';
import { EmitterDetailsBarCellSet } from 'Pages/DashboardPage/components/EmitterDetailsBar/components/EmitterDetailsBarCellSet';
import { KeyValueCell } from '../../KeyValueCell';

interface NavigationSectionProps {
  emitter: Emitter;
}

export function NavigationSection({ emitter }: NavigationSectionProps): ReactElement {
  const sogProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(SensorName.GPS.PropertyName.SOG, emitter.sensors);
  }, [emitter.sensors]);

  const stwProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(SensorName.GPS.PropertyName.STW, emitter.sensors);
  }, [emitter.sensors]);

  const cogProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(SensorName.GPS.PropertyName.COG, emitter.sensors);
  }, [emitter.sensors]);

  const headingProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(SensorName.GPS.PropertyName.Heading, emitter.sensors);
  }, [emitter.sensors]);

  return (
    <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title="Navigation" />}>
      <EmitterDetailsBarCellSet>
        {sogProperty ? (
          <PropertyCell title="SOG" property={sogProperty} />
        ) : (
          <KeyValueCell title="SOG" />
        )}
        {stwProperty ? (
          <PropertyCell title="STW" property={stwProperty} />
        ) : (
          <KeyValueCell title="STW" />
        )}
        {cogProperty ? (
          <PropertyCell title="COG" property={cogProperty} />
        ) : (
          <KeyValueCell title="COG" />
        )}
        {headingProperty ? (
          <PropertyCell title="Heading" property={headingProperty} />
        ) : (
          <KeyValueCell title="Heading" />
        )}
      </EmitterDetailsBarCellSet>
    </EmitterDetailsBarSection>
  );
}
