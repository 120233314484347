import { useCallback, useEffect, useState } from 'react';
import { User } from 'Models/user';
import { useAppSelector } from 'Services/redux';
import { Namespace, useGetRolesAndNamespacesQuery } from 'Services/redux/api/users';
import { sessionUserSelector } from 'Services/redux/session';
import { ContentTable, CreateUserModal, TopBar } from './components';

export const SettingsBarContentUsersManagement: React.FC = () => {
  const [isCreateUserModalShown, setIsCreateUserModalShown] = useState(false);
  const [newSavedUser, setNewSavedUser] = useState<User>();
  const [currentTab, setCurrentTab] = useState<string>();
  const [namespacesAccess, setNamespacesAccess] = useState<Namespace[]>();
  const [currentUserNamespace, setCurrentUserNamespace] = useState<string>();

  const currentUser = useAppSelector(sessionUserSelector);

  const {
    data: rolesAndNamespacesResponse,
    error: rolesAndNamespacesError,
    isLoading: isLoadingRolesAndNameSpaces,
  } = useGetRolesAndNamespacesQuery();

  const handleOnSelectTab = useCallback(
    (index: number) => {
      if (!namespacesAccess) return;

      setCurrentTab(namespacesAccess[index].code);
    },
    [namespacesAccess]
  );

  const handleOnCloseCreateUserModal = useCallback(() => {
    setIsCreateUserModalShown(false);
  }, []);

  const handleOnClickAddNewUser = useCallback(() => {
    setIsCreateUserModalShown(true);
  }, []);

  const handleOnSaveNewUser = useCallback((savedUser: User) => {
    setCurrentTab(savedUser.namespace);
    setNewSavedUser(savedUser);
    setIsCreateUserModalShown(false);
  }, []);

  useEffect(() => {
    if (
      isLoadingRolesAndNameSpaces ||
      rolesAndNamespacesError ||
      !rolesAndNamespacesResponse ||
      !currentUser
    ) {
      return;
    }

    setCurrentUserNamespace(currentUser.namespace);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, rolesAndNamespacesResponse]);

  useEffect(() => {
    if (!rolesAndNamespacesResponse || !currentUserNamespace) return;

    if (currentUserNamespace === 'sailplan') {
      setNamespacesAccess(rolesAndNamespacesResponse.namespaces);
      setCurrentTab(currentUserNamespace);
      return;
    }

    const userNamespaceAccess = rolesAndNamespacesResponse.namespaces.find(
      (namespace) => namespace.code === currentUserNamespace
    );

    userNamespaceAccess && setNamespacesAccess([userNamespaceAccess]);

    setCurrentTab(currentUserNamespace);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserNamespace]);

  return (
    <>
      <TopBar
        onClickAddNewUser={handleOnClickAddNewUser}
        namespaces={namespacesAccess}
        onSelectTab={handleOnSelectTab}
        currentTabIndex={
          currentTab
            ? namespacesAccess?.findIndex((namespaceAccess) => namespaceAccess.code === currentTab)
            : undefined
        }
      />
      {currentTab && namespacesAccess?.length && currentUserNamespace && (
        <ContentTable
          namespace={currentTab}
          currentUserNamespace={currentUserNamespace}
          newUser={newSavedUser}
          namespacesAccess={namespacesAccess}
        />
      )}
      {isCreateUserModalShown && namespacesAccess && currentUserNamespace && (
        <CreateUserModal
          onSave={handleOnSaveNewUser}
          onClose={handleOnCloseCreateUserModal}
          currentTab={currentTab ?? namespacesAccess[0].code}
          currentUserNamespace={currentUserNamespace}
        />
      )}
    </>
  );
};
