import { apiApi } from 'Services/redux/api/api.api';
import { OperationalProfile, Sensor } from 'Models';
import { uniq } from 'lodash';

export type GetOperationalProfileArgument = {
  generatorSensors: Sensor[];
  earliest: string;
  latest: string;
};

export const enginesApi = apiApi.injectEndpoints({
  endpoints: (builder) => ({
    getOperationalProfiles: builder.query<OperationalProfile[], GetOperationalProfileArgument>({
      query: ({ generatorSensors, earliest, latest }) => {
        const sensorIds = uniq(generatorSensors.map((s) => s.id)).join(',');
        const sensorGroupIds = uniq(generatorSensors.map((s) => s.sensorGroupId)).join(',');

        return {
          url: 'engines/online',
          params: {
            sensorIds,
            sensorGroupIds,
            earliest,
            latest,
          },
        };
      },
    }),
  }),
});

export const { useGetOperationalProfilesQuery } = enginesApi;
