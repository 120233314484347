export const formatNumber = (
  value: number,
  {
    minFractionDigits,
    maxFractionDigits,
    useGrouping,
  }: { minFractionDigits?: number; maxFractionDigits?: number; useGrouping?: boolean }
) => {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
    useGrouping: useGrouping,
  });

  return numberFormatter.format(value).replace(/^-0$/, '0');
};
