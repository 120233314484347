export function generateColors(numColors: number) {
  if (numColors === 0) return [];

  const BASE_HUE = 200;
  const SPREAD = [42, 104];

  const hues = [BASE_HUE];

  for (let i = 1; i < numColors; i++) {
    const spread = SPREAD[(i - 1) % SPREAD.length];
    const newHue = (hues[i - 1] - spread + 360) % 360;
    hues.push(newHue);
  }

  return hues.map((hue) => `hsl(${hue}, 100%, 43%)`);
}
