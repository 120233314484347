import { ToastCoordinator } from './ToastCoordinator';
import { Toast } from './interfaces';

type ToastPresentationConfiguration = Pick<Toast, 'level' | 'title' | 'removeAfter'> & {
  id?: Toast['id'];
};

let count = 0;
export class ToastPresenter {
  constructor(private readonly coordinator: ToastCoordinator) {}

  present(config: ToastPresentationConfiguration): Toast['id'] {
    const toast = this.createToast(config);
    this.coordinator.push(toast);

    return toast.id;
  }

  dismiss(id: NonNullable<ToastPresentationConfiguration['id']>) {
    this.coordinator.remove(id);
  }

  private createToast(config: ToastPresentationConfiguration): Toast {
    const id = config.id || this.generateId();
    return { ...config, id, visible: true, createdAt: new Date() };
  }

  private generateId(): string {
    return String(count++);
  }
}
