import styles from './Chart.module.scss';
import { Fragment, useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartDateTick } from './ChartDateTick';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import {
  ChartDomain,
  ChartEntryValue,
  ChartSeries,
  ChartSeriesYAxis,
  ChartSeriesYAxisCreate,
  MergedChartEntry,
  TimestampEntries,
} from './interfaces';
import { TOOLTIP_LABEL_FORMATTER, calculateTickLabelWidth } from './helpers';
import { TICK_FONT_FAMILY, TICK_FONT_SIZE, TICK_FONT_WEIGHT } from './constants';
import { formatNumber } from 'Helpers/number';

type ChartProps = {
  series: ChartSeries[];
  syncId?: CategoricalChartProps['syncId'];
};

export const Chart: React.FC<ChartProps> = ({ series, syncId }) => {
  const unitDomains = useMemo(
    () =>
      series.reduce<{
        [unit: NonNullable<ChartSeries['unit']>]: ChartDomain;
      }>((acc, series) => {
        if (!series.unit || !series.entries.length) return acc;

        const values = series.entries.map((e) => e.value);

        const existingUnitDomain = acc[series.unit];

        return {
          ...acc,
          [series.unit]: [
            existingUnitDomain ? Math.min(...values, existingUnitDomain[0]) : Math.min(...values),
            existingUnitDomain ? Math.max(...values, existingUnitDomain[1]) : Math.max(...values),
          ],
        };
      }, {}),
    [series]
  );

  const seriesDomains = useMemo(
    () =>
      series.reduce<{
        [series: ChartSeries['id']]: ChartDomain;
      }>((acc, series) => {
        const unitDomain = series.unit ? unitDomains[series.unit] : undefined;

        let seriesDomain: ChartDomain;

        if (unitDomain) {
          seriesDomain = unitDomain;
        } else if (series.entries.length) {
          const values = series.entries.map((e) => e.value);
          seriesDomain = [Math.min(...values), Math.max(...values)];
        } else {
          return acc;
        }

        const PADDING = 0.1;

        const paddedSeriesDomain = [
          Math.ceil(Math.max(seriesDomain[0] - PADDING * seriesDomain[1], 0)),
          Math.floor(seriesDomain[1] * (1 + PADDING)),
        ];

        return { ...acc, [series.id]: paddedSeriesDomain as ChartDomain };
      }, {}),
    [series, unitDomains]
  );

  const yAxisWidths = useMemo(
    () =>
      series.reduce<{ [seriesId: ChartSeries['id']]: number }>(
        (acc, series) => ({
          ...acc,
          [series.id]: seriesDomains[series.id]
            ? calculateTickLabelWidth({
                domain: seriesDomains[series.id],
                maximumFractionDigits: series.maximumFractionDigits,
                unit: series.unit,
              })
            : 0,
        }),
        {}
      ),
    [series, seriesDomains]
  );

  const tickFormatters = useMemo(
    () =>
      series.reduce<{
        [seriesId: ChartSeries['id']]: (value: ChartEntryValue) => string;
      }>((acc, series) => {
        const formatter = (value: ChartEntryValue) => {
          return formatNumber(value, {
            minFractionDigits: 0,
            maxFractionDigits: series.maximumFractionDigits,
          });
        };

        return { ...acc, [series.id]: formatter };
      }, {}),
    [series]
  );

  const tooltipValueFormatter = useMemo(() => {
    const formatter: Formatter<ChartEntryValue | string, string> = (value, _name, _item, index) => {
      const s = series[index];

      return (
        formatNumber(value as ChartEntryValue, {
          minFractionDigits: 0,
          maxFractionDigits: s.maximumFractionDigits,
        }) + s.unit ?? ''
      );
    };

    return formatter;
  }, [series]);

  const mergedEntries: MergedChartEntry[] = useMemo(() => {
    const timestampEntries: TimestampEntries = {};

    for (const s of series) {
      for (const entry of s.entries) {
        const mergedChartEntry: MergedChartEntry = {
          ...(timestampEntries[entry.timestamp] ?? {}),
          [s.id]: entry.value,
          timestamp: entry.timestamp,
        };
        timestampEntries[entry.timestamp] = mergedChartEntry;
      }
    }

    for (const timestamp in timestampEntries) {
      const entry = timestampEntries[timestamp];

      for (const s of series) {
        entry[s.id] = entry[s.id] ?? null;
      }
    }

    return Object.values(timestampEntries).sort((a, b) => a.timestamp - b.timestamp);
  }, [series]);

  return (
    <div className={styles.chart}>
      <ResponsiveContainer>
        <LineChart margin={{ bottom: 17, top: 14 }} syncId={syncId} data={mergedEntries}>
          <CartesianGrid stroke="#d3d9e7" vertical={false} />
          {series.map((s) => (
            <Fragment key={s.id}>
              <Line
                name={s.name}
                type="monotone"
                stroke={s.color}
                dot={false}
                strokeWidth={2}
                strokeOpacity={1}
                dataKey={s.id}
                yAxisId={shouldCreateAxis(s.yAxis) ? s.id : s.yAxis.useExistingForSeriesId}
                strokeDasharray={s.dashed ? '3 3' : undefined}
                isAnimationActive={false}
              />
              {shouldCreateAxis(s.yAxis) && (
                <YAxis
                  yAxisId={s.id}
                  orientation={s.yAxis.createWithOrientation}
                  axisLine={{ stroke: '#d3d9e7' }}
                  tickLine={{ stroke: '#d3d9e7' }}
                  width={yAxisWidths[s.id]}
                  tick={{
                    fontFamily: TICK_FONT_FAMILY,
                    fontSize: TICK_FONT_SIZE,
                    fontWeight: TICK_FONT_WEIGHT,
                    fill: '#000',
                  }}
                  unit={s.unit}
                  allowDecimals={true}
                  tickFormatter={tickFormatters[s.id]}
                  domain={seriesDomains[s.id]}
                />
              )}
            </Fragment>
          ))}
          <XAxis
            dataKey="timestamp"
            axisLine={{ stroke: '#d3d9e7' }}
            tickLine={{ stroke: '#d3d9e7' }}
            tick={<ChartDateTick />}
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            contentStyle={{ border: '1px solid #d3d9e7' }}
            labelFormatter={TOOLTIP_LABEL_FORMATTER}
            formatter={tooltipValueFormatter}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const shouldCreateAxis = (axis: ChartSeriesYAxis): axis is ChartSeriesYAxisCreate => {
  return (axis as ChartSeriesYAxisCreate).createWithOrientation !== undefined;
};
