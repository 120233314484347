import styles from './UserMenu.module.scss';
import { ReactComponent as IconSettings } from './resources/graphics/icon-settings.svg';
import { ReactComponent as IconLogout } from './resources/graphics/icon-logout.svg';
import { ReactComponent as IconHelp } from './resources/graphics/icon-help.svg';
import { forwardRef } from 'react';

type UserMenuProps = {
  onClickSettings?: () => void;
  onClickLogout: () => void;
  onClickHelp: () => void;
};

export const UserMenu = forwardRef<HTMLDivElement, UserMenuProps>(
  ({ onClickSettings, onClickLogout, onClickHelp }, ref) => {
    return (
      <div className={styles.userMenu} ref={ref}>
        <div className={styles.leftTriangle} />
        <ul>
          {onClickSettings && (
            <li onClick={onClickSettings}>
              <div>
                <IconSettings />
              </div>
              <span>Settings</span>
            </li>
          )}
          <li onClick={onClickHelp}>
            <div>
              <IconHelp />
            </div>
            <span>Help</span>
          </li>
          <li className={styles.logout} onClick={onClickLogout}>
            <div>
              <IconLogout />
            </div>
            <span>Log Out</span>
          </li>
        </ul>
      </div>
    );
  }
);
