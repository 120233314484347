import { extent } from 'd3-array';
import { getChartEntryValue } from './chart-entry';
import { ChartEntry } from '../interfaces';

function max(n1: number, n2?: number): number {
  if (!n2 && n2 !== 0) {
    return n1
  }

  return Math.max(n1, n2);
}

function min(n1: number, n2?: number): number {
  if (!n2 && n2 !== 0) {
    return n1
  }

  return Math.min(n1, n2);
}

export const getDomain = (entries: Required<ChartEntry>[], defaultLowerDomainY?: number, defaultUpperDomainY?: number): [min: number, max: number] => {
  const defaultDomain = extent(entries, getChartEntryValue) as [min: number, max: number];

  return [
    min(defaultDomain[0], defaultLowerDomainY),
    max(defaultDomain[1], defaultUpperDomainY)
  ]
};
