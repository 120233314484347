import { SensorName } from 'Constants';
import { getFirstPropertyWithName } from 'Helpers/sensors';
import { Emitter, Property } from 'Models';
import {
  EmitterDetailsBarSection,
  EmitterDetailsBarSectionHeaderRegular,
} from 'Pages/DashboardPage/components/EmitterDetailsBar/components';
import { ReactElement, useMemo } from 'react';
import { PropertyCell } from '../../PropertyCell';
import { EmitterDetailsBarCellSet } from 'Pages/DashboardPage/components/EmitterDetailsBar/components/EmitterDetailsBarCellSet';
import { KeyValueCell } from '../../KeyValueCell';

interface WeatherSectionProps {
  emitter: Emitter;
}

export function WeatherSection({ emitter }: WeatherSectionProps): ReactElement {
  const trueWindSpeedProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(SensorName.Weather.PropertyName.TrueWindSpeed, emitter.sensors);
  }, [emitter.sensors]);

  const trueWindDirectionProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(
      SensorName.Weather.PropertyName.TrueWindDirection,
      emitter.sensors
    );
  }, [emitter.sensors]);

  const relativeWindSpeedProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(
      SensorName.Weather.PropertyName.RelativeWindSpeed,
      emitter.sensors
    );
  }, [emitter.sensors]);

  const relativeWindDirectionProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(
      SensorName.Weather.PropertyName.RelativeWindDirection,
      emitter.sensors
    );
  }, [emitter.sensors]);

  const airTemperatureProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(
      SensorName.Weather.PropertyName.AirTemperature,
      emitter.sensors
    );
  }, [emitter.sensors]);

  const seawaterTemperatureProperty: Property | undefined = useMemo(() => {
    return getFirstPropertyWithName(
      SensorName.Weather.PropertyName.SeawaterTemperature,
      emitter.sensors
    );
  }, [emitter.sensors]);

  return (
    <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title="Weather" />}>
      <EmitterDetailsBarCellSet>
        {trueWindSpeedProperty ? (
          <PropertyCell title="Wind speed" property={trueWindSpeedProperty} />
        ) : relativeWindSpeedProperty ? (
          <PropertyCell title="Relative Wind speed" property={relativeWindSpeedProperty} />
        ) : (
          <KeyValueCell title="Wind speed" />
        )}
        {trueWindDirectionProperty ? (
          <PropertyCell title="Wind direction" property={trueWindDirectionProperty} />
        ) : relativeWindDirectionProperty ? (
          <PropertyCell title="Relative Wind direction" property={relativeWindDirectionProperty} />
        ) : (
          <KeyValueCell title="Wind direction" />
        )}
        {airTemperatureProperty ? (
          <PropertyCell title="Air temperature" property={airTemperatureProperty} />
        ) : (
          <KeyValueCell title="Air temperature" />
        )}
        {seawaterTemperatureProperty ? (
          <PropertyCell title="Sea temperature" property={seawaterTemperatureProperty} />
        ) : (
          <KeyValueCell title="Sea temperature" />
        )}
      </EmitterDetailsBarCellSet>
    </EmitterDetailsBarSection>
  );
}
