import { ChartEntry } from 'Components/Chart';
import { PropertyFrame } from 'Hooks/queries';
import { Property } from 'Models';
import { DEFAULT_SAMPLE_RATE } from 'Services/redux/api/sensorGroup/transformers';

// This is for Recharts
export const getChartEntry = (propertyFrame: PropertyFrame): ChartEntry => {
  return {
    value: propertyFrame.value as number,
    timestamp: new Date(propertyFrame.date).getTime(),
  };
};

// This is for Recharts
export const getChartEntries = (propertyFrames: PropertyFrame[]): ChartEntry[] => {
  return propertyFrames.map(getChartEntry).sort((a, b) => a.timestamp - b.timestamp);
};

export const getMinSampleRate = (properties: Property[]) => {
  if (!properties.length) return DEFAULT_SAMPLE_RATE;

  return Math.max(...properties.map((p) => p.sampleRate));
};
