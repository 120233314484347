import ContentLoader from 'react-content-loader';

export const EmitterSelectorLoader: React.FC = () => (
  <ContentLoader width="224" height="278" viewBox="0 0 224 278" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176 236C169.373 236 164 241.373 164 248C164 254.627 169.373 260 176 260H192C198.627 260 204 254.627 204 248C204 241.373 198.627 236 192 236H176ZM176 240C171.582 240 168 243.582 168 248C168 252.418 171.582 256 176 256C180.418 256 184 252.418 184 248C184 243.582 180.418 240 176 240Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176 180C169.373 180 164 185.373 164 192C164 198.627 169.373 204 176 204H192C198.627 204 204 198.627 204 192C204 185.373 198.627 180 192 180H176ZM176 184C171.582 184 168 187.582 168 192C168 196.418 171.582 200 176 200C180.418 200 184 196.418 184 192C184 187.582 180.418 184 176 184Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176 124C169.373 124 164 129.373 164 136C164 142.627 169.373 148 176 148H192C198.627 148 204 142.627 204 136C204 129.373 198.627 124 192 124H176ZM176 128C171.582 128 168 131.582 168 136C168 140.418 171.582 144 176 144C180.418 144 184 140.418 184 136C184 131.582 180.418 128 176 128Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176 68C169.373 68 164 73.3726 164 80C164 86.6274 169.373 92 176 92H192C198.627 92 204 86.6274 204 80C204 73.3726 198.627 68 192 68H176ZM176 72C171.582 72 168 75.5817 168 80C168 84.4183 171.582 88 176 88C180.418 88 184 84.4183 184 80C184 75.5817 180.418 72 176 72Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176 10C169.373 10 164 15.3726 164 22C164 28.6274 169.373 34 176 34H192C198.627 34 204 28.6274 204 22C204 15.3726 198.627 10 192 10H176ZM176 14C171.582 14 168 17.5817 168 22C168 26.4183 171.582 30 176 30C180.418 30 184 26.4183 184 22C184 17.5817 180.418 14 176 14Z"
    />
    <rect x="20" y="239" width="86" height="18" />
    <rect x="20" y="183" width="86" height="18" />
    <rect x="20" y="127" width="86" height="18" />
    <rect x="20" y="71" width="86" height="18" />
    <rect x="20" y="43" width="184" height="1" />
    <rect x="20" y="14" width="67" height="15" />
  </ContentLoader>
);
