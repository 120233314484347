import styles from './SettingsBarHeader.module.scss';
import { ReactComponent as IconArrowClose } from './resources/graphics/icon-arrow-close.svg';
import { ReactComponent as IconDots } from './resources/graphics/icon-dots.svg';
import { TabNavigator, TabNavigatorElement } from 'Components/TabNavigator';

type SettingsBarHeaderProps = {
  onClickCollapseButton: () => void;
  tabs: TabNavigatorElement[];
  onSelectTab: (index: number) => void;
};

export const SettingsBarHeader: React.FC<SettingsBarHeaderProps> = ({
  onClickCollapseButton,
  tabs,
  onSelectTab,
}) => {
  return (
    <div className={styles.settingsBarHeader}>
      <h3>Settings</h3>
      <div className={styles.options}>
        <button>
          <IconDots />
        </button>
        <button onClick={onClickCollapseButton}>
          <IconArrowClose />
        </button>
      </div>
      <div className={styles.tabNavigatorContainer}>
        <TabNavigator
          tabs={tabs}
          normalTextColor="rgba(255, 255, 255, 0.6)"
          selectedTextColor="#fff"
          underlineColor="rgba(255, 255, 255, 0.7)"
          onSelectTab={onSelectTab}
        />
      </div>
    </div>
  );
};
