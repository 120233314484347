import { createContext } from 'react';
import { ControlBarManager } from '../ControlBarManager';

export interface IControlBarContext {
  manager: ControlBarManager;
}

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ControlBarProvider>.');
};

const initialContext = {
  manager: stub,
} as unknown as IControlBarContext;

export const ControlBarContext = createContext(initialContext);
