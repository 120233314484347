import styles from './DateTimePickerModal.module.scss';
import { forwardRef, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'Components/Button';
import { DateTimePicker, DateTimePickerProps } from 'Components/DateTimePicker';
import { ReactComponent as IconCrossLarge } from 'Resources/graphics/icon-cross-large.svg';
import Moment from 'react-moment';

export type DateTimePickerModalProps = {
  header: string;
  initialDate: Date;
  upperLimit?: Date;
  onCancel?: () => void;
  onSubmit: (selectedDate: Date) => void;
};

export const DateTimePickerModal = forwardRef<HTMLDivElement, DateTimePickerModalProps>(
  ({ header, initialDate, onCancel, onSubmit, upperLimit }, ref) => {
    const [selectedDate, setSelectedDate] = useState(initialDate);

    const handleDateTimePickerChange: DateTimePickerProps['onChange'] = useCallback(
      (selectedDate) => {
        setSelectedDate(selectedDate);
      },
      []
    );

    return createPortal(
      <div className={styles.dateTimePickerModalOverlay} ref={ref}>
        <div className={styles.dateTimePickerModal}>
          <button className={styles.closeButton} onClick={onCancel}>
            <IconCrossLarge />
          </button>
          <div className={styles.header}>
            <p className={styles.title}>{header}</p>
            <p className={styles.subtitle}>
              <Moment format="MMMM Do YYYY, h:mm A" date={selectedDate} />
            </p>
          </div>
          <div className={styles.body}>
            <DateTimePicker
              initialDate={initialDate}
              onChange={handleDateTimePickerChange}
              upperLimit={upperLimit}
            />
          </div>
          <div className={styles.footer}>
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSubmit(selectedDate);
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>,
      document.getElementById('portal') as HTMLElement
    );
  }
);
