import { Dock } from 'Models';
import { MapPopup, styles } from 'Components/MapPopup';
import { DMSCoordinate } from 'Components/DMSCoordinate';

type DockPopupProps = {
  dock: Dock;
  showsCloseButton: boolean;
  onClose?: () => void;
};

export const DockPopup: React.FC<DockPopupProps> = ({ dock, showsCloseButton, onClose }) => {
  if (!dock.coordinate) {
    return null;
  }

  return (
    <MapPopup
      longitude={dock.coordinate.longitude}
      latitude={dock.coordinate.latitude}
      closeButton={showsCloseButton}
      onClose={onClose}
    >
      <div className={styles.section}>
        <p>
          <span className={styles.title}>{dock.name}</span>
        </p>
      </div>
      <hr />
      <div className={styles.section}>
        {dock.coordinate && <DMSCoordinate coordinate={dock.coordinate} />}
      </div>
    </MapPopup>
  );
};
