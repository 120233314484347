import style from '../../AlertsBarContent.module.scss';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, AlertLevel } from 'Models';
import { ReactComponent as IconDots } from './resources/graphics/icon-dots.svg';
import clsx from 'clsx';
import Moment from 'react-moment';
import { AlertRowMenu } from './components';

type AlertsDataRowProps = {
  alert: Alert;
  showsControl: boolean;
  onDismiss?: (alert: Alert) => void;
};

export const AlertsDataRow = forwardRef<HTMLDivElement, AlertsDataRowProps>(
  ({ alert, showsControl, onDismiss }, ref) => {
    const indicatorColor = useMemo(() => getIndicatorColor(alert.level), [alert.level]);
    const title = alert.message ?? '-';
    const description = alert.explanations[0]?.message ?? '-';
    const source = alert.sensorGroupName;

    const menuRef = useRef<HTMLDivElement>(null);

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    useEffect(() => {
      const handleClickOutside = (e: MouseEvent) => {
        const menu = menuRef.current;

        if (!menu || !(e.target instanceof Element) || menu.contains(e.target)) {
          return;
        }

        setIsMenuVisible(false);
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const handleOnClickDismissMenu = () => {
      if (onDismiss) onDismiss(alert);
    };

    const handleOnClickIconDots = () => {
      setIsMenuVisible(true);
    };

    return (
      <div className={style.dataRow} ref={ref}>
        <div className={style.status}>
          <div className={clsx(style.statusDot, style[indicatorColor])} />
        </div>
        <div className={style.summary}>
          <p className={style.title}>{title}</p>
          <p className={style.description}>{description}</p>
        </div>
        <div className={style.source}>
          <span>{source}</span>
        </div>
        <div className={style.date}>
          <span>
            {alert.updatedAt ? (
              <>
                <Moment format="MMM D YYYY" date={alert.updatedAt} />
                <br />
                <Moment format="h:mm a" date={alert.updatedAt} />
              </>
            ) : (
              '-'
            )}
          </span>
        </div>
        {showsControl && (
          <div className={style.menu}>
            <IconDots onClick={handleOnClickIconDots} className={style.iconDots} />
            {isMenuVisible && (
              <AlertRowMenu ref={menuRef} onClickDismiss={handleOnClickDismissMenu} />
            )}
          </div>
        )}
      </div>
    );
  }
);

type IndicationColor = 'red' | 'yellow' | 'blue' | 'grey';

const getIndicatorColor = (level: AlertLevel): IndicationColor => {
  switch (level) {
    case AlertLevel.Low:
      return 'blue';
    case AlertLevel.Medium:
      return 'yellow';
    case AlertLevel.High:
      return 'red';
    case AlertLevel.Unknown:
      return 'grey';
    default:
      const _exhaustiveCheck: never = level;
      throw new Error(_exhaustiveCheck);
  }
};
