import { useEffect, useState } from 'react';
import { useControlBarManager } from './useControlBarManager';
import { useViewMode } from './useViewMode';

export const useTimelineThumb = () => {
  const manager = useControlBarManager();

  const viewMode = useViewMode();

  const [thumbPosition, setThumbPosition] = useState(manager.getThumbPosition());
  const [releasedThumbPosition, setReleasedThumbPosition] = useState(
    manager.getReleasedThumbPosition()
  );

  useEffect(() => {
    const thumbPositionObserver = (position: number) => {
      setThumbPosition(position);
    };

    const releasedThumbPositionObserver = (position: number) => {
      setReleasedThumbPosition(position);
    };

    manager.observeThumbPosition(thumbPositionObserver);
    manager.observeReleasedThumbPosition(releasedThumbPositionObserver);

    return () => {
      manager.unobserveThumbPosition(thumbPositionObserver);
      manager.unobserveReleasedThumbPosition(releasedThumbPositionObserver);
    };
  }, [manager]);

  return {
    thumbPosition: viewMode === 'live' ? 1.0 : thumbPosition,
    releasedThumbPosition: viewMode === 'live' ? 1.0 : releasedThumbPosition,
  };
};
