import styles from './Toaster.module.scss';
import { useToastCoordinator, useToasts } from './hooks';
import { ToastWrapper } from './components/ToastWrapper';
import { Toast } from './components/Toast';
import { Toast as IToast } from './interfaces/toast';

const DEFAULT_WIDTH = 400;
const DEFAULT_GUTTER = 8;

type ToasterProps = {
  width?: number;
  gutter?: number;
  style?: React.CSSProperties;
};

export const Toaster: React.FC<ToasterProps> = ({
  width = DEFAULT_WIDTH,
  gutter = DEFAULT_GUTTER,
  style,
}) => {
  const coordinator = useToastCoordinator();
  const toasts = useToasts();

  return (
    <div className={styles.toaster} style={style}>
      {toasts.map((toast, index) => {
        const offsetY = calculateOffsetY(toasts, index, gutter);

        return (
          <ToastWrapper
            key={toast.id}
            offsetY={offsetY}
            onHeightUpdate={(height) => {
              if (height !== toast.height) {
                coordinator.update({ ...toast, height });
              }
            }}
          >
            <Toast
              level={toast.level}
              title={toast.title}
              width={width}
              visible={toast.visible}
              onDismiss={() => {
                coordinator.remove(toast.id);
              }}
            />
          </ToastWrapper>
        );
      })}
    </div>
  );
};

function calculateOffsetY(toasts: IToast[], index: number, gutter: number) {
  return toasts
    .slice(0, index)
    .reduce((acc, toast) => acc + (toast.visible ? (toast.height || 0) + gutter : 0), 0);
}
