import { TickRendererProps } from '@visx/axis';
import { Group } from '@visx/group';
import { TICK_FONT_FAMILY, TICK_FONT_SIZE, TICK_FONT_WEIGHT } from '../constants';
import moment from 'moment';

export type TimestampTickProps = TickRendererProps & { textAnchor: 'start' | 'middle' | 'end' };

export const TimestampTick: React.FC<TimestampTickProps> = ({
  x,
  y,
  formattedValue: timestamp,
  textAnchor,
}) => {
  const hour = moment(timestamp).format('HH:mm');
  const date = moment(timestamp).format('MMM DD');

  return (
    <Group>
      <text
        x={x}
        y={y + 11}
        fontFamily={TICK_FONT_FAMILY}
        fontSize={TICK_FONT_SIZE}
        fontWeight={TICK_FONT_WEIGHT}
        textAnchor={textAnchor}
      >
        {hour}
      </text>
      <text
        x={x}
        y={y + 15 + 15}
        fontFamily={TICK_FONT_FAMILY}
        fontSize={TICK_FONT_SIZE}
        fontWeight={TICK_FONT_WEIGHT}
        textAnchor={textAnchor}
      >
        {date}
      </text>
    </Group>
  );
};
