import styles from './StepProperties.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Emitter, UserAccess } from 'Models';
import { buildSections, getSelectedProperties } from './helpers/section';
import { getPropertiesFromUserAccess } from './helpers/user-access';
import { config } from './constants';
import { Section } from './interfaces';
import { StepPropertiesAccordion } from './components';
import { useShareEmitterContext } from '../../../hooks';
import { getUniqueProperties } from 'Services/redux/api/sensor/util';

type StepPropertiesProps = {
  emitter: Emitter;
  userAccess?: UserAccess;

  // Visually select all
  selectAll: boolean;
};

export const StepProperties: React.FC<StepPropertiesProps> = ({
  emitter,
  userAccess,
  selectAll,
}) => {
  const { properties: selectedProperties, setProperties: setSelectedProperties } =
    useShareEmitterContext();

  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    let preselectedProperties = userAccess ? getPropertiesFromUserAccess(userAccess, emitter) : [];

    preselectedProperties = getUniqueProperties(preselectedProperties.concat(selectedProperties));

    const sections = buildSections(emitter.sensors, preselectedProperties, config);

    setSections(sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitter, userAccess]);

  useEffect(() => {
    const selectedProperties = getSelectedProperties(sections);
    setSelectedProperties(selectedProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const handleToggleOption = useCallback((sectionIndex: number, optionIndex: number) => {
    setSections((sections) => {
      return sections.map((section, index) =>
        index === sectionIndex
          ? {
              ...section,
              options: section.options.map((option, index) =>
                optionIndex === index ? { ...option, isSelected: !option.isSelected } : option
              ),
            }
          : section
      );
    });
  }, []);

  const displaySections = useMemo(() => {
    if (selectAll) {
      return sections.map((section) => ({
        ...section,
        options: section.options.map((o) => ({ ...o, isSelected: true, isRequired: true })),
      }));
    }

    return sections;
  }, [sections, selectAll]);

  return (
    <div className={styles.stepProperties}>
      <div className={styles.stepPropertiesContent}>
        {displaySections.map((section, index) => (
          <StepPropertiesAccordion
            key={index}
            section={section}
            onToggleOption={(optionIndex) => handleToggleOption(index, optionIndex)}
          />
        ))}
      </div>
    </div>
  );
};
