import styles from './UserAccessesTable.module.scss';
import { useCallback, useEffect, useRef } from 'react';
import { UserAccessesTableRow } from './components';
import PuffLoader from 'react-spinners/PuffLoader';
import { UserAccess } from 'Models';

type UserAccessesTableProps = {
  userAccesses: UserAccess[];
  onScrollToBottom?: () => void;
  showsLoadingNextPageRow?: boolean;
  onSelectEdit: (index: number) => void;
  onSelectRevoke: (index: number) => void;
};

export const UserAccessesTable: React.FC<UserAccessesTableProps> = ({
  userAccesses,
  onScrollToBottom,
  showsLoadingNextPageRow = false,
  onSelectEdit,
  onSelectRevoke,
}) => {
  const intersectionObserverRef = useRef<IntersectionObserver>();

  const scrollableContainerRef = (scrollableContainer: HTMLDivElement) => {
    if (!scrollableContainer || intersectionObserverRef.current) {
      return;
    }

    const onIntersect: IntersectionObserverCallback = ([entry]) => {
      if (entry?.isIntersecting) {
        onScrollToBottom?.();
      }
    };

    intersectionObserverRef.current = new IntersectionObserver(onIntersect, {
      root: scrollableContainer,
    });
  };

  useEffect(() => {
    return () => {
      intersectionObserverRef.current?.disconnect();
    };
  }, []);

  const lastItemRef = useCallback((lastItem: HTMLDivElement) => {
    if (!lastItem || !intersectionObserverRef.current) return;

    intersectionObserverRef.current.disconnect();
    intersectionObserverRef.current.observe(lastItem);
  }, []);

  return (
    <div className={styles.usersTable}>
      <div className={styles.tableHeader}>
        <div className={styles.name}>
          <span>Name</span>
        </div>
        <div className={styles.email}>
          <span>Email</span>
        </div>
        <div className={styles.dateCreated}>
          <span>Date Created</span>
        </div>
      </div>
      <div className={styles.tableContent} ref={scrollableContainerRef}>
        {userAccesses.map((userAccess, index) => (
          <UserAccessesTableRow
            key={userAccess.id}
            ref={index === userAccesses.length - 1 ? lastItemRef : undefined}
            userAccess={userAccess}
            onSelectEdit={() => onSelectEdit(index)}
            onSelectRevoke={() => onSelectRevoke(index)}
          />
        ))}
        {showsLoadingNextPageRow && (
          <div className={styles.loadingNextPageIndicatorRow}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        )}
      </div>
    </div>
  );
};
