import { RootState } from 'Services/redux';

export const showsSelectionSelector = (state: RootState) => state.map.showsSelection;

export const showsVesselLayerSelector = (state: RootState) => state.map.showsVesselLayer;
export const showsDockLayerSelector = (state: RootState) => state.map.showsDockLayer;
export const showsAisLayerSelector = (state: RootState) => state.map.showsAisLayer;
export const showsPositionHistoryLayerSelector = (state: RootState) =>
  state.map.showsPositionHistoryLayer;
export const showsEmissionLayerSelector = (state: RootState) => !!state.map.selectedEmissionLayer;
export const showsAqiLayerSelector = (state: RootState) => !!state.map.selectedAqiLayer;
export const showsEcaLayerSelector = (state: RootState) => state.map.showsEcaLayer;
export const showsAlertLayerSelector = (state: RootState) => state.map.showsAlertLayer;

export const selectedEmissionLayerSelector = (state: RootState) => state.map.selectedEmissionLayer;
export const selectedAqiLayerSelector = (state: RootState) => state.map.selectedAqiLayer;

export const isLoadingLayersSelector = (state: RootState) =>
  [
    state.map.isLoadingVesselLayer,
    state.map.isLoadingDockLayer,
    state.map.isLoadingAisLayer,
    state.map.isLoadingPositionHistoryLayer,
    state.map.isLoadingEmissionLayer,
    state.map.isLoadingAqiLayer,
    state.map.isLoadingAlertLayer,
  ].includes(true);

export const selectedElementSelector = (state: RootState) => state.map.selectedElement;
