import styles from './OperationalProfileSection.module.scss';
import { Connection, OperationalProfile, Sensor } from 'Models';
import { useMemo, useEffect } from 'react';
import { PuffLoader } from 'react-spinners';
import { EmitterDetailsBarSection } from '../../EmitterDetailsBarSection';
import { EmitterDetailsBarSectionHeaderRegular } from '../../EmitterDetailsBarSectionHeaderRegular';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { OperationalProfilesChart } from './components';
import { useGetOperationalProfilesQuery } from 'Services/redux/api/engines';

const EMPTY_OPERATIONAL_PROFILES: OperationalProfile[] = [];

type OperationalProfileSectionProps = {
  title: string;
  generatorSensors: Sensor[];
  connectionType?: Connection['type'];
};

export const OperationalProfileSection: React.FC<OperationalProfileSectionProps> = ({
  title,
  generatorSensors,
  connectionType,
}) => {
  const {
    absoluteInterval: { earliest, latest },
    refresh,
  } = useSelectedInterval();

  useEffect(refresh, [generatorSensors, refresh]);

  const duration = useMemo(
    () => new Date(latest).getTime() - new Date(earliest).getTime(),
    [earliest, latest]
  );

  const { data: operationalProfiles = EMPTY_OPERATIONAL_PROFILES, isFetching } =
    useGetOperationalProfilesQuery({
      generatorSensors,
      earliest,
      latest,
    });

  return (
    <EmitterDetailsBarSection header={<EmitterDetailsBarSectionHeaderRegular title={title} />}>
      <div className={styles.chartContainer}>
        {connectionType === 'satcomm' ? (
          <div className={styles.placeholder}>
            Operational profile is not available in low data mode
          </div>
        ) : isFetching ? (
          <div className={styles.loadingIndicator}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        ) : !operationalProfiles.length ? (
          <div className={styles.placeholder}>No data available</div>
        ) : (
          <OperationalProfilesChart operationalProfiles={operationalProfiles} duration={duration} />
        )}
      </div>
    </EmitterDetailsBarSection>
  );
};
