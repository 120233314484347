import { ChartEntry } from 'Components/BiaxialChart';
import { getFramesForProperty, useLazyGetPropertiesFramesQuery } from 'Hooks/queries';
import { Property } from 'Models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getChartEntries, getChartEntry, normalize } from '../helpers';
import { useObservePropertyFrame } from './useObservePropertyFrame';
import { useControlBarManager } from 'Pages/DashboardPage/components/ControlBar/hooks';
import { Interval } from 'Constants';
import { generateStaticInterval, getAbsoluteInterval } from 'Helpers/interval';

interface UseChartEntriesArgument {
  property?: Property;
  maxNumberOfFrames: number;
  interval: Interval;
  observeNewFrames: boolean;
}

interface UseChartEntriesArgumentReturnValue {
  entries: ChartEntry[];
  isFetching: boolean;
}

export const useChartEntries = ({
  property,
  maxNumberOfFrames,
  interval,
  observeNewFrames,
}: UseChartEntriesArgument): UseChartEntriesArgumentReturnValue => {
  const entriesRef = useRef<ChartEntry[]>([]);
  const [entries, setEntries] = useState<ChartEntry[]>([]);

  // We need to use ref to connect state to ref. This is
  // because the callback in setTimeout can't access the
  // most recent entries state value.
  //
  // @see: https://github.com/facebook/react/issues/14010
  // @see: https://felixgerschau.com/react-hooks-settimeout/
  useEffect(() => {
    entriesRef.current = entries;
  }, [entries]);

  const controlBarManager = useControlBarManager();

  const insertEmptyEntryTimeoutRef = useRef<NodeJS.Timeout>();

  const [{ propertiesFrames, isFetching }, getPropertiesFramesQuery] =
    useLazyGetPropertiesFramesQuery();

  const [cycleFlag, setCycleFlag] = useState(true);

  const { earliest, latest } = useMemo(() => {
    return getAbsoluteInterval(generateStaticInterval(interval));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, cycleFlag]);

  useEffect(() => {
    const listener = () => {
      setCycleFlag((prevCycleFlag) => !prevCycleFlag);
    };

    window.addEventListener('focus', listener);

    return () => {
      window.removeEventListener('focus', listener);
    };
  }, []);

  const sampleRate = useMemo(() => {
    if (!property) return;

    const deltaSeconds = (new Date(latest).getTime() - new Date(earliest).getTime()) / 1000;
    const minSampleRate = Math.floor(deltaSeconds / maxNumberOfFrames);

    return Math.max(property.sampleRate, minSampleRate);
  }, [earliest, latest, maxNumberOfFrames, property]);

  useEffect(() => {
    if (!property) return;

    getPropertiesFramesQuery({
      properties: [property],
      earliest,
      latest,
      aggregation: 'avg',
      sample: {
        unit: 'second',
        rate: sampleRate!,
      },
      densify: true,
    });
  }, [earliest, latest, property, getPropertiesFramesQuery, sampleRate]);

  const setNormalizedEntries = useCallback(
    (entries: ChartEntry[]) => {
      // Recalculate absolute interval
      const domain = getAbsoluteInterval(generateStaticInterval(interval));

      setEntries(
        normalize(entries, {
          interval: sampleRate! * 1000,
          domain,
          maxLength: maxNumberOfFrames,
        })
      );
    },
    [interval, maxNumberOfFrames, sampleRate]
  );

  const insertNormalizedEntry = useCallback(
    (entry: ChartEntry) => {
      // Recalculate absolute interval
      const domain = getAbsoluteInterval(generateStaticInterval(interval));

      setEntries((entries) =>
        normalize(entries.concat(entry), {
          interval: sampleRate! * 1000,
          domain,
          maxLength: maxNumberOfFrames,
        })
      );
    },
    [interval, maxNumberOfFrames, sampleRate]
  );

  const cancelScheduleInsertEmptyEntry = useCallback(() => {
    if (insertEmptyEntryTimeoutRef.current) {
      clearTimeout(insertEmptyEntryTimeoutRef.current);
      insertEmptyEntryTimeoutRef.current = undefined;
    }
  }, []);

  const scheduleInsertEmptyEntry = useCallback(() => {
    cancelScheduleInsertEmptyEntry();

    if (!property || !observeNewFrames) return;

    const THRESHOLD = 60000;
    const interval = property.sampleRate * 1000;

    insertEmptyEntryTimeoutRef.current = setTimeout(function insertEmptyEntryTimeout() {
      if (!observeNewFrames || isFetching) return;

      if (controlBarManager.getViewMode() === 'live') {
        const emptyEntry: ChartEntry = {
          value: null,
          timestamp: new Date().toISOString(),
          lowRes: false,
        };

        insertNormalizedEntry(emptyEntry);
      }

      insertEmptyEntryTimeoutRef.current = setTimeout(insertEmptyEntryTimeout, interval);
    }, interval + THRESHOLD);
  }, [
    cancelScheduleInsertEmptyEntry,
    controlBarManager,
    insertNormalizedEntry,
    isFetching,
    observeNewFrames,
    property,
  ]);

  useEffect(() => {
    return () => {
      cancelScheduleInsertEmptyEntry();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, earliest, latest, observeNewFrames]);

  useEffect(() => {
    if (!property || isFetching) {
      setEntries([]);
      return;
    }

    const propertyFrames = getFramesForProperty(property, propertiesFrames);
    const entries = getChartEntries(propertyFrames);

    setNormalizedEntries(entries);
    scheduleInsertEmptyEntry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earliest, latest, property, sampleRate, isFetching, maxNumberOfFrames, setNormalizedEntries]);

  useObservePropertyFrame(
    property,
    (propertyFrame) => {
      if (!property || isFetching || !observeNewFrames) return;

      const entry = getChartEntry(propertyFrame);

      insertNormalizedEntry(entry);
      scheduleInsertEmptyEntry();
    },
    { skip: !observeNewFrames || isFetching }
  );

  return { entries, isFetching };
};
