import styles from './Accordion.module.scss';
import { HTMLAttributes, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import clsx from 'clsx';
import { motion } from 'framer-motion';

type AccordionProps = {
  header: string;
  rightAccessory?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  header,
  rightAccessory,
  className,
  children,
  ...remainingProps
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleClickHeader = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, []);

  return (
    <div className={clsx(styles.accordion, className)} {...remainingProps}>
      <div className={styles.header} onClick={handleClickHeader}>
        <div className={styles.title}>
          <IconArrowDown style={{ transform: isExpanded ? 'rotate(180deg)' : undefined }} />
          <span>{header}</span>
        </div>
        {rightAccessory && <div>{rightAccessory}</div>}
      </div>
      <motion.div className={styles.body} animate={{ height: isExpanded ? 'auto' : 0 }}>
        {children}
      </motion.div>
    </div>
  );
};
