import styles from './OptionSectionHeader.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as IconArrowDown } from 'Resources/graphics/icon-arrow-down.svg';
import { OptionPopup } from 'Components/OptionPopup';
import { EMITTER_DETAILS_BAR_ID } from 'Pages/DashboardPage/components/EmitterDetailsBar/constants/layout';

type OptionSectionHeaderProps = {
  title: string;
  options: string[];
  selectedOptionIndex?: number;
  onSelectOption: (index: number) => void;
};

export const OptionSectionHeader: React.FC<OptionSectionHeaderProps> = ({
  title,
  options,
  selectedOptionIndex,
  onSelectOption,
}) => {
  const toggleRef = useRef<HTMLDivElement>(null);
  const [isOptionPopupVisible, setIsOptionPopupVisible] = useState(false);

  const handleClickToggle = useCallback(() => {
    setIsOptionPopupVisible((isOptionPopupVisible) => !isOptionPopupVisible);
  }, []);

  const handleClickOutsidePopupOption = useCallback(() => {
    setIsOptionPopupVisible(false);
  }, []);

  const handleSelectOptionPopupOption = useCallback(
    (index: number) => {
      onSelectOption(index);
      setIsOptionPopupVisible(false);
    },
    [onSelectOption]
  );

  useEffect(() => {
    const emitterDetailsBar = document.getElementById(EMITTER_DETAILS_BAR_ID);

    if (!emitterDetailsBar) return;

    const listener = () => {
      setIsOptionPopupVisible(false);
    };

    emitterDetailsBar.addEventListener('scroll', listener);
    window.addEventListener('resize', listener);

    return () => {
      emitterDetailsBar.removeEventListener('scroll', listener);
      window.removeEventListener('resize', listener);
    };
  }, []);

  return (
    <div className={styles.optionSectionHeader}>
      <h4>{title}</h4>
      {!!options.length && selectedOptionIndex !== undefined && (
        <>
          <span className={styles.optionToggle} onClick={handleClickToggle} ref={toggleRef}>
            {options[selectedOptionIndex]}
            <IconArrowDown
              style={{ transform: isOptionPopupVisible ? 'rotate(180deg)' : undefined }}
            />
          </span>
          {isOptionPopupVisible && (
            <OptionPopup
              options={options}
              selectedIndex={selectedOptionIndex}
              onSelectOption={handleSelectOptionPopupOption}
              onClickOutside={handleClickOutsidePopupOption}
              sourceRef={toggleRef}
            />
          )}
        </>
      )}
    </div>
  );
};
