import { Emitter, Property, User } from 'Models';
import { apiApi } from '../api.api';
import { UserAccess } from 'Models';
import { UserAccess as UserAccessRecord } from './interfaces';
import { transformIntoUserAccess } from './transformers/user-access.transformer';
import { getShareEmitterAccessRequestBody, getUpdateEmitterAccessRequestBody } from './helpers';

export type GetEmitterAccessors = {
  emitter: Emitter;
};

export type ShareEmitterAccessArgument = {
  emitter: Emitter;
  userIds: User['altId'][];
  earliest?: string;
  latest?: string;
  properties?: Property[];
  isReadAll?: boolean;
};

export type UpdateEmitterAccessArgument = {
  userAccess: UserAccess;
  emitter: Emitter;
  earliest?: string;
  latest?: string;
  properties?: Property[];
  isReadAll?: boolean;
};

export type RevokeEmitterAccess = {
  userAccess: UserAccess;
};

export const sensorSchemasApi = apiApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmitterAccessors: builder.query<UserAccess[], GetEmitterAccessors>({
      query: ({ emitter }) => {
        return { url: `sensorSchemas/sensorGroup/${emitter.id}` };
      },
      providesTags: ['sensorSchemas'],
      transformResponse: (records: UserAccessRecord[]) => {
        // Backend sometimes return record without user object in it.
        // This is a bug in the backend. We'll do filter for the time being.
        return records
          .filter((r) => !!r.user)
          .map(transformIntoUserAccess)
          .reverse();
      },
    }),
    shareEmitter: builder.mutation<void, ShareEmitterAccessArgument>({
      query: (arg) => ({
        url: 'sensorSchemas/share',
        method: 'PUT',
        body: getShareEmitterAccessRequestBody(arg),
      }),
      invalidatesTags: ['sensorSchemas'],
    }),
    updateEmitterAccess: builder.mutation<void, UpdateEmitterAccessArgument>({
      query: ({ userAccess, ...arg }) => ({
        url: `sensorSchemas/${userAccess.id}`,
        method: 'PUT',
        body: getUpdateEmitterAccessRequestBody(arg),
      }),
      invalidatesTags: ['sensorSchemas'],
    }),
    revokeEmitterAccess: builder.mutation<void, RevokeEmitterAccess>({
      query: ({ userAccess }) => ({
        url: `sensorSchemas/${userAccess.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['sensorSchemas'],
    }),
  }),
});

export const {
  useGetEmitterAccessorsQuery,
  useShareEmitterMutation,
  useUpdateEmitterAccessMutation,
  useRevokeEmitterAccessMutation,
} = sensorSchemasApi;
