import { LngLat, LngLatBounds, PaddingOptions, Point } from 'mapbox-gl';
import { Coordinate } from 'Models';
import { MapRef } from 'react-map-gl';

export const getBounds = (coordinates: Coordinate[], paddingScale: number = 0): LngLatBounds => {
  const possibleMinLatitude = -89.9999;
  const possibleMaxLatitude = 89.9999;
  const possibleMinLongitude = -179.9999;
  const possibleMaxLongitude = 179.9999;

  let minLatitude = possibleMaxLatitude;
  let maxLatitude = possibleMinLatitude;
  let minLongitude = possibleMaxLongitude;
  let maxLongitude = possibleMinLongitude;

  for (const coordinate of coordinates) {
    minLatitude = coordinate.latitude < minLatitude ? coordinate.latitude : minLatitude;
    maxLatitude = coordinate.latitude > maxLatitude ? coordinate.latitude : maxLatitude;

    minLongitude = coordinate.longitude < minLongitude ? coordinate.longitude : minLongitude;
    maxLongitude = coordinate.longitude > maxLongitude ? coordinate.longitude : maxLongitude;
  }

  if (minLatitude > maxLatitude) {
    const tempMinLatitude = minLatitude;
    minLatitude = maxLatitude;
    maxLatitude = tempMinLatitude;
  }

  if (minLongitude > maxLongitude) {
    const tempMinLongitude = minLongitude;
    minLongitude = maxLongitude;
    maxLongitude = tempMinLongitude;
  }

  const horizontalPadding = (maxLongitude - minLongitude) * paddingScale;
  const verticalPadding = (maxLatitude - minLatitude) * paddingScale;

  minLatitude = Math.max(minLatitude - verticalPadding, possibleMinLatitude);
  maxLatitude = Math.min(maxLatitude + verticalPadding, possibleMaxLatitude);
  minLongitude = Math.max(minLongitude - horizontalPadding, possibleMinLongitude);
  maxLongitude = Math.min(maxLongitude + horizontalPadding, possibleMaxLongitude);

  return new LngLatBounds(
    new LngLat(minLongitude, maxLatitude),
    new LngLat(maxLongitude, minLatitude)
  );
};

export const calculateBounds = (
  map: MapRef,
  boundsToFit: LngLatBounds,
  padding: PaddingOptions
) => {
  const southWest = boundsToFit.getSouthWest();
  const northEast = boundsToFit.getNorthEast();

  const projectedSouthWest = map.project(southWest);
  const projectedNorthEast = map.project(northEast);

  const paddingAppliedProjectedSouthWest = new Point(
    projectedSouthWest.x + padding.left,
    projectedSouthWest.y - padding.bottom
  );

  const paddingAppliedProjectedNorthEast = new Point(
    projectedNorthEast.x - padding.right,
    projectedNorthEast.y + padding.top
  );

  return new LngLatBounds(
    map.unproject(paddingAppliedProjectedSouthWest),
    map.unproject(paddingAppliedProjectedNorthEast)
  );
};
