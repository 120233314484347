import { Vessel } from 'Models';
import { Feature, FeatureCollection, Point } from 'geojson';
import { Source, Layer, LayerProps } from 'react-map-gl';
import { useAppDispatch } from 'Services/redux';
import { setIsLoadingEmissionLayer } from 'Services/redux/map';
import { useEffect, useMemo } from 'react';
import { MapLayerProps } from '../../../interfaces';
import { DerivedEmissionsEntry } from './interfaces';
import { useGetEmissionsQuery, SupportedEmissions } from './hooks';
import { MapRef } from 'react-map-gl';

const BASE_LAYER_PROPS: LayerProps = {
  type: 'heatmap',
  paint: {
    'heatmap-weight': [
      'interpolate',
      ['exponential', 1],
      ['get', 'value'],
      2.0,
      0,
      7.0,
      0.6,
      10,
      1.0,
    ],
    'heatmap-intensity': 2.0,
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(255, 255, 255, 0)',
      0.01,
      'rgba(0, 76, 205, 0.8)', // Good
      0.2,
      'rgb(0, 196, 214)', // Moderate
      0.4,
      'rgb(42, 150, 84)', // Unhealthy for sensitive groups
      0.6,
      'rgb(255, 255, 25)', // Unhealthy
      0.8,
      'rgb(255, 120, 25)', // Very Unhealthy
      1,
      'rgb(178, 24, 43)', // Hazardous
    ],
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      2,
      5,
      3,
      8,
      4,
      10,
      5,
      11,
      10,
      13,
      20,
      15,
      55,
      16,
      100,
    ],
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 1, 0.8, 13, 0.8],
  },
};

const getFeature = (entry: DerivedEmissionsEntry): Feature<Point> => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [entry.longitude, entry.latitude],
  },
  properties: {
    value: entry.value,
  },
});

type EmissionLayerProps = {
  vessel: Vessel;
  emissionType: SupportedEmissions;
  mapRef: MapRef;
} & MapLayerProps;

export const EmissionLayer: React.FC<EmissionLayerProps> = ({
  vessel,
  emissionType,
  mapRef,
  id,
  beforeId,
}) => {
  const { entries, isFetching } = useGetEmissionsQuery({
    vessel,
    mapRef,
    emissionType,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsLoadingEmissionLayer(isFetching));

    return () => {
      dispatch(setIsLoadingEmissionLayer(false));
    };
  }, [isFetching, dispatch]);

  const memoizedFeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: entries.map((e) => getFeature(e)),
    } as FeatureCollection<Point>;
  }, [entries]);

  const layerProps = { id, ...BASE_LAYER_PROPS };

  if (beforeId) {
    layerProps.beforeId = beforeId;
  }

  return (
    <Source id={id} type="geojson" data={memoizedFeatureCollection}>
      <Layer {...layerProps} />
    </Source>
  );
};
