import styles from '../../../../SideBar.module.scss';
import clsx from 'clsx';
import { Connection } from 'Models/connection';

type FleetNavigatorItemProps = {
  title: string;
  selected?: boolean;
  onClick: () => void;
  connectionType: Connection['type'];
};

export const FleetNavigatorItem: React.FC<FleetNavigatorItemProps> = ({
  title,
  selected: isSelected = false,
  onClick,
  connectionType,
}) => {
  return (
    <div
      className={clsx(styles.fleetNavigatorItem, { [styles.selected]: isSelected })}
      onClick={onClick}
    >
      <span
        className={clsx(styles.statusIndicator, {
          [styles.offline]: connectionType === 'offline' || connectionType === 'unknown',
          [styles.lowData]: connectionType === 'satcomm',
        })}
      />
      <span>{title}</span>
    </div>
  );
};
