import { Dispatch, createContext } from 'react';
import { StaticInterval } from 'Constants';
import { Property, User } from 'Models';

export interface IShareEmitterContext {
  users: User[];
  interval: Partial<StaticInterval>;
  noIntervalRestriction: boolean;
  properties: Property[];
  setUsers: Dispatch<React.SetStateAction<User[]>>;
  setInterval: Dispatch<React.SetStateAction<Partial<StaticInterval>>>;
  setNoIntervalRestriction: Dispatch<React.SetStateAction<boolean>>;
  setProperties: Dispatch<React.SetStateAction<Property[]>>;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ShareEmitterContext.Provider>.');
};

const initialContext: IShareEmitterContext = {
  users: [],
  interval: { earliest: undefined, latest: undefined },
  noIntervalRestriction: false,
  properties: [],
  setUsers: stub,
  setInterval: stub,
  setNoIntervalRestriction: stub,
  setProperties: stub,
};

export const ShareEmitterContext = createContext(initialContext);
