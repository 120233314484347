import styles from './ContentRow.module.scss';
import { User } from 'Models/user';
import { forwardRef, useCallback, useMemo } from 'react';
import { ReactComponent as IconDots } from './resources/graphics/icon-dots.svg';
import { MenuPickerPopup } from 'Components/MenuPickerPopup';
import { usePopup } from 'Hooks/popup/usePopup';
import { Namespace } from 'Services/redux/api/users';
import { UserInfoPopup } from './components/UserInfoPopup/UserInfoPopup';
import clsx from 'clsx';

type ContentRowProps = {
  user: User;
  onClickEdit: (user: User) => void;
  onClickDelete: (user: User) => void;
  namespacesAccess: Namespace[];
};

type MenuItemIdentifier = 'edit' | 'delete';

type MenuItem = { label: string; id: MenuItemIdentifier };

const MENU_ITEMS: MenuItem[] = [
  { label: 'Edit', id: 'edit' },
  { label: 'Delete', id: 'delete' },
];

export const ContentRow = forwardRef<HTMLDivElement, ContentRowProps>(
  ({ user, onClickEdit, onClickDelete, namespacesAccess }, ref) => {
    const [isMenuVisible, setMenuToVisible, setMenuToNotVisible, menuRef] = usePopup();

    const [
      isUserInfoPopupVisible,
      setUserInfoPopupToVisible,
      setUserInfoPopupToNotVisible,
      userInfoPopupRef,
      toggleUserInfoPopupRef,
    ] = usePopup();

    const handleClickName = useCallback(() => {
      isUserInfoPopupVisible ? setUserInfoPopupToNotVisible() : setUserInfoPopupToVisible();
    }, [isUserInfoPopupVisible, setUserInfoPopupToVisible, setUserInfoPopupToNotVisible]);

    const namespaceDisplay = useMemo(
      () => namespacesAccess.find((namespace) => namespace.code === user.namespace)?.displayName,
      [namespacesAccess, user.namespace]
    );

    const handleSelectMenuItem = useCallback(
      (selectedIndex: number) => {
        const selectedMenuItem = MENU_ITEMS[selectedIndex];

        if (selectedMenuItem.id === 'edit') {
          onClickEdit(user);
        } else if (selectedMenuItem.id === 'delete') {
          onClickDelete(user);
        }
      },
      [user, onClickEdit, onClickDelete]
    );

    const menuItemLabels = useMemo(() => MENU_ITEMS.map((i) => i.label), []);

    const userRoles = useMemo(() => {
      if (user.roles.find((role) => role.name === 'adminRead')) {
        return 'Admin (read only)';
      }

      if (user.roles.find((role) => role.name === 'adminReadWrite')) {
        return 'Admin';
      }

      if (user.roles.find((role) => role.name === 'manager')) {
        return 'Manager';
      }

      if (user.roles.find((role) => role.name === 'viewer')) {
        return 'Viewer';
      }
    }, [user.roles]);

    return (
      <div className={styles.row} ref={ref}>
        <div className={styles.userProfile}>
          <div className={styles.nameWrapper}>
            <p
              className={clsx(styles.name, isUserInfoPopupVisible && styles.selected)}
              onClick={handleClickName}
              ref={toggleUserInfoPopupRef}
            >
              {user.firstName} {user.lastName}
            </p>
            {isUserInfoPopupVisible && (
              <UserInfoPopup
                user={user}
                ref={userInfoPopupRef}
                namespaceAccess={namespacesAccess}
              />
            )}
          </div>
          <p className={styles.email}>{user.email}</p>
        </div>
        <div className={styles.namespace}>
          <span>{namespaceDisplay}</span>
        </div>
        <div className={styles.role}>{userRoles}</div>
        <div className={styles.option}>
          <div className={styles.menu}>
            <IconDots onClick={setMenuToVisible} className={styles.iconDots} />
            {isMenuVisible && (
              <MenuPickerPopup
                ref={menuRef}
                items={menuItemLabels}
                onSelect={handleSelectMenuItem}
                onClickItem={setMenuToNotVisible}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);
