import { useAuth0 } from '@auth0/auth0-react';
import { LoadingPage } from 'Pages/LoadingPage/LoadingPage';
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { routes } from 'Routes';
import {
  getAccessTokenSilently,
  setGetAccessTokenSilently,
  setLoginWithRedirect,
} from 'Services/auth0';
import { useAppDispatch, useAppSelector } from 'Services/redux';
import { useLazyGetCurrentUserQuery } from 'Services/redux/api/users';
import { sessionUserSelector, setSessionUser } from 'Services/redux/session';
import rg4js from 'raygun4js';

export const App: React.FC = () => {
  const [token, setToken] = useState<string>();

  const {
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect: originalLoginWithRedirect,
    getAccessTokenSilently: originalGetAccessTokenSilently,
  } = useAuth0();
  const [getCurrentUserQuery] = useLazyGetCurrentUserQuery();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoginWithRedirect(originalLoginWithRedirect);
    setGetAccessTokenSilently(originalGetAccessTokenSilently);
  }, [originalGetAccessTokenSilently, originalLoginWithRedirect]);

  useEffect(() => {
    if (isLoading || error) {
      return;
    }

    (async (): Promise<void> => {
      if (!isAuthenticated) {
        await originalLoginWithRedirect();
      } else {
        const token = await getAccessTokenSilently();
        setToken(token);

        const { data: currentUser } = await getCurrentUserQuery();
        if (currentUser) {
          rg4js('setUser', { identifier: currentUser.altId });
          rg4js('logContentsOfXhrCalls', true);
        }
        dispatch(setSessionUser(currentUser));
      }
    })();
  }, [isAuthenticated, isLoading, error, originalLoginWithRedirect, dispatch, getCurrentUserQuery]);

  const currentUser = useAppSelector(sessionUserSelector);

  if (!token || !currentUser) {
    return <LoadingPage />;
  }

  return (
    <Routes>
      {routes.map((route, index) => {
        const Component = route.component;
        return <Route key={index} path={route.path} element={<Component />} />;
      })}
      <Route path="*" element={<Navigate to="dashboard" replace />} />
    </Routes>
  );
};
