import { AisPositional, AisStatic, Coordinate } from 'Models';
import { SensorName } from 'Constants';

export const transformIntoAisStatic = (record: Record<string, unknown>): AisStatic => {
  const mmsi = record[SensorName.AISStatic.PropertyName.Mmsi.hash] as number;

  return {
    id: String(mmsi),
    name: record[SensorName.AISStatic.PropertyName.Name.hash] as string,
    callsign: record[SensorName.AISStatic.PropertyName.Callsign.hash] as string,
    destination: record[SensorName.AISStatic.PropertyName.Destination.hash] as string,
    eta: record[SensorName.AISStatic.PropertyName.Eta.hash] as string,
    flag: record[SensorName.AISStatic.PropertyName.Flag.hash] as string,
    imo: record[SensorName.AISStatic.PropertyName.Imo.hash] as string,
    mmsi,
    maxDraught: record[SensorName.AISStatic.PropertyName.MaxDraughtMeters.hash] as number,
    systemTime: record[SensorName.AISStatic.PropertyName.SystemTime.hash] as string,
  };
};

export const transformIntoAisPositional = (record: Record<string, unknown>): AisPositional => {
  const mmsi = record[SensorName.AISPositional.PropertyName.Mmsi.hash] as number;

  const latitude = record[SensorName.AISPositional.PropertyName.Latitude.hash] as
    | number
    | undefined;
  const longitude = record[SensorName.AISPositional.PropertyName.Longitude.hash] as
    | number
    | undefined;

  let coordinate: Coordinate | undefined;

  if (latitude && longitude) {
    coordinate = { latitude: latitude, longitude };
  }

  return {
    id: String(mmsi),
    cog: record[SensorName.AISPositional.PropertyName.Cog.hash] as number,
    coordinate,
    mmsi,
    trueHeading: record[SensorName.AISPositional.PropertyName.TrueHeading.hash] as number,
    type: record[SensorName.AISPositional.PropertyName.Type.hash] as string,
    sog: record[SensorName.AISPositional.PropertyName.Sog.hash] as number,
    status: record[SensorName.AISPositional.PropertyName.Status.hash] as number,
    systemTime: record[SensorName.AISPositional.PropertyName.SystemTime.hash] as string,
  };
};
