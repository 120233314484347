import styles from '../../ReportsPageContent.module.scss';
import ContentLoader from 'react-content-loader';

export const SummarySectionLoader: React.FC = () => (
  <div className={styles.chartSection}>
    <div style={{ position: 'relative' }}>
      <ContentLoader
        width={315}
        height={17}
        fill="none"
        style={{ marginLeft: 'auto', position: 'absolute', top: 0, right: 0 }}
      >
        <circle cx="4" cy="8.5" r="4" fill="#D9D9D9" />
        <rect x="14" width="75" height="17" fill="#D9D9D9" />
        <circle cx="117" cy="8.5" r="4" fill="#D9D9D9" />
        <rect x="127" width="75" height="17" fill="#D9D9D9" />
        <circle cx="230" cy="8.5" r="4" fill="#D9D9D9" />
        <rect x="240" width="75" height="17" fill="#D9D9D9" />
      </ContentLoader>
      <ContentLoader width="100%" height="302" viewBox="0 0 100% 302" fill="none">
        <rect width="74" height="17" fill="#D9D9D9" />
        <rect y="69" width="80" height="14" fill="#D9D9D9" />
        <rect y="147" width="80" height="14" fill="#D9D9D9" />
        <rect y="225" width="80" height="14" fill="#D9D9D9" />
        <rect x="96" y="37" width="1" height="245" fill="#D9D9D9" />
        <rect x="96" y="288" width="56" height="14" fill="#D9D9D9" />
        <rect x="25.57%" y="37" width="1" height="245" fill="#D9D9D9" />
        <rect x="25.57%" y="288" width="56" height="14" fill="#D9D9D9" />
        <rect x="40.43%" y="37" width="1" height="245" fill="#D9D9D9" />
        <rect x="40.43%" y="288" width="56" height="14" fill="#D9D9D9" />
        <rect x="55.30%" y="37" width="1" height="245" fill="#D9D9D9" />
        <rect x="55.30%" y="288" width="56" height="14" fill="#D9D9D9" />
        <rect x="70.16%" y="37" width="1" height="245" fill="#D9D9D9" />
        <rect x="70.16%" y="288" width="56" height="14" fill="#D9D9D9" />
        <rect x="85.02%" y="37" width="1" height="245" fill="#D9D9D9" />
        <rect x="85.02%" y="288" width="56" height="14" fill="#D9D9D9" />
        <rect x="96" y="271" width="100%" height="1" />
        <rect x="97" y="64" width="50%" height="24" fill="#D9D9D9" />
        <rect x="97" y="142" width="60%" height="24" fill="#D9D9D9" />
        <rect x="97" y="220" width="39%" height="24" fill="#D9D9D9" />
      </ContentLoader>
    </div>
  </div>
);
