import { AbsoluteInterval } from 'Constants';

type Entry = { timestamp: string; value: number | null; lowRes: boolean };
type Group = Entry & { count: number };

const densifyAnchorPoint = new Date('2000-01-01T00:00:00.000Z').getTime();

function getAnchoredTimestamp(timestamp: string, interval: number) {
  let currentTimestamp = new Date(timestamp).getTime();

  const diff = currentTimestamp - densifyAnchorPoint;

  return new Date(densifyAnchorPoint + interval * Math.floor(diff / interval));
}

function group(entries: Entry[], interval: number): Entry[] {
  const groups: { [timestamp: string]: Group } = {};

  entries.forEach((entry) => {
    const groupTimestamp = getAnchoredTimestamp(entry.timestamp, interval).toISOString();
    const groupKey = `${groupTimestamp}-${entry.lowRes}`;

    if (!groups[groupKey]) {
      groups[groupKey] = {
        timestamp: groupTimestamp,
        value: null,
        lowRes: entry.lowRes,
        count: 0,
      };
    }
    groups[groupKey].value =
      typeof groups[groupKey].value === 'number'
        ? groups[groupKey].value! + (entry.value ?? 0)
        : entry.value;

    if (typeof entry.value === 'number') groups[groupKey].count++;
  });

  return Object.values(groups).map((group) => {
    return {
      lowRes: group.lowRes,
      timestamp: group.timestamp,
      value: typeof group.value === 'number' ? group.value / group.count : null,
    };
  });
}

function removeDuplicateTimestamps(entries: Entry[]) {
  const out: Record<string, Entry> = {};
  for (const entry of entries) {
    const existing = out[entry.timestamp];
    if (existing) {
      const withValue = [existing, entry].filter((e) => typeof e.value === 'number');
      //Always perfer the hiRes entry with a value if it exists
      const hiResWithValue = withValue.find((e) => !e.lowRes);
      const loResWithValue = withValue.find((e) => e.lowRes);
      out[entry.timestamp] = hiResWithValue || loResWithValue || existing;
    } else {
      out[entry.timestamp] = entry;
    }
  }
  return Object.values(out);
}

function densify(entries: Entry[], interval: number): Entry[] {
  if (entries.length <= 1) return entries;

  const result: Entry[] = [];

  let currentTimestamp = getAnchoredTimestamp(entries[0].timestamp, interval).getTime();

  const lastTimestamp = new Date(entries[entries.length - 1].timestamp).getTime();

  while (currentTimestamp <= lastTimestamp) {
    const timestamp = new Date(currentTimestamp).toISOString();
    const match = entries.find((entry) => entry.timestamp === timestamp);
    result.push({
      timestamp,
      value: match ? match.value : null,
      lowRes: match?.lowRes ?? false,
    });
    currentTimestamp += interval;
  }

  return result;
}

export function normalize(
  entries: Entry[],
  {
    interval,
    domain,
    maxLength,
  }: { interval: number; domain: AbsoluteInterval; maxLength?: number }
): Entry[] {
  const sortedEntries = entries.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  const grouped = group(sortedEntries, interval);
  const cleaned = removeDuplicateTimestamps(grouped);
  const densified = densify(cleaned, interval);

  if (densified.length <= 1) return densified;

  const earliestDate = new Date(domain.earliest);
  const latestDate = new Date(domain.latest);

  return (typeof maxLength === 'number' ? densified.slice(-maxLength) : densified).filter(
    (e) =>
      new Date(e.timestamp).getTime() >= earliestDate.getTime() &&
      new Date(e.timestamp).getTime() <= latestDate.getTime()
  );
}
