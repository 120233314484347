import { lookerConfiguration } from 'Config/looker.config';
import styles from './DiscoverPage.module.scss';
import { LookerEmbedCookielessSessionData, LookerEmbedSDK } from '@looker/embed-sdk';
import { getAccessTokenSilently } from 'Services/auth0';
import { useEffect, useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';

export const DiscoverPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      let apiToken: string | null | undefined;
      let navigationToken: string | null | undefined;
      LookerEmbedSDK.initCookieless(
        process.env.REACT_APP_LOOKER_HOST!,
        async () => {
          try {
            const resp = await fetch(`${process.env.REACT_APP_API_URL}/looker/aquireSession`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              method: 'GET',
              credentials: 'include',
            });
            if (!resp.ok) {
              console.error('acquire embed session failed', { resp });
              throw new Error(`acquire embed session failed`);
            }
            const resp_as_json = (await resp.json()) as LookerEmbedCookielessSessionData;
            apiToken = resp_as_json.api_token;
            navigationToken = resp_as_json.navigation_token;

            return resp_as_json;
          } catch (error: any) {
            console.error(error);
            throw new Error(`acquire embed session failed`);
          }
        },
        async () => {
          try {
            const resp = await fetch(`${process.env.REACT_APP_API_URL}/looker/generateTokens`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/json',
              },
              method: 'PUT',
              credentials: 'include',
              body: JSON.stringify({ api_token: apiToken, navigation_token: navigationToken }),
            });
            if (!resp.ok) {
              if (resp.status === 400) {
                return { session_reference_token_ttl: 0 };
              }
              console.error('generate tokens failed', { resp });
              throw new Error(`generate tokens failed`);
            }
            const resp_as_json = (await resp.json()) as LookerEmbedCookielessSessionData;
            apiToken = resp_as_json.api_token;
            navigationToken = resp_as_json.navigation_token;
            return resp_as_json;
          } catch (error: any) {
            console.error(error);
            throw new Error(`generate tokens failed`);
          }
        }
      );

      await LookerEmbedSDK.createDashboardWithId(lookerConfiguration.dashboardId)
        .withDialogScroll()
        .withDynamicIFrameHeight()
        .withAllowAttr('fullscreen')
        .appendTo('#looker-container')
        .build()
        .connect()
        .then((dashboard) => {
          const iframe = document.querySelector('#looker-container iframe') as HTMLIFrameElement;
          if (iframe) {
            iframe.style.width = '100%'; // Ensuring the iframe is full width
          }
        });

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="looker-container" style={{ height: '100vh' }}>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <BounceLoader color="#44ABDF" size={80} />
        </div>
      )}
    </div>
  );
};
