import { useEffect, useState } from 'react';
import { useToastCoordinator } from './useToastCoordinator';
import { Toast } from '../interfaces/toast';

export const useToasts = () => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const coordinator = useToastCoordinator();

  useEffect(() => {
    const observer = (toasts: Toast[]) => {
      setToasts(toasts);
    };
    coordinator.observe(observer);

    return () => coordinator.unobserve(observer);
  }, [coordinator]);

  return toasts;
};
