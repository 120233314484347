import styles from './RadioButton.module.scss';
import clsx from 'clsx';

type RadioButtonProps = {
  selected?: boolean;
  onClick?: () => void;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
  selected: isSelected = false,
  onClick,
}) => {
  return (
    <div className={clsx(styles.radioButton, { [styles.selected]: isSelected })} onClick={onClick}>
      {isSelected && <div className={styles.dot}></div>}
    </div>
  );
};
