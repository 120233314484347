import { Emitter } from 'Models';
import { EngineOption, PropertyOption, SensorOption } from '../interfaces';
import { allSensorOptions } from '../constants/sensor-options';

export function getSensorOptions(emitter: Emitter) {
  return allSensorOptions.filter((sensorOption) =>
    emitter.sensors.some((sensor) =>
      sensorOption.scopes.some((scope) => scope.sensorName === sensor.name)
    )
  );
}

export function getEngineOptions({
  sensorOption,
  emitter,
}: {
  sensorOption: SensorOption;
  emitter: Emitter;
}): EngineOption[] {
  const sensors = emitter.sensors.filter((sensor) =>
    sensorOption.scopes.some((scope) => scope.sensorName === sensor.name)
  );

  const engineIds = sensors
    .map((sensor) => sensor.engineId)
    .filter((engineId): engineId is number => typeof engineId === 'number');

  const uniqueEngineIds = Array.from(new Set(engineIds));

  const engineOptions: EngineOption[] = [];

  for (const engineId of uniqueEngineIds) {
    const variableKey = `^Variable${engineId}`;
    const engineName = emitter.layout.vars[variableKey];

    let numberOfOtherEngines = 0;

    if (engineName) {
      engineOptions.push({ engineId, name: engineName });
    } else {
      // This should not happen, but just in case.
      numberOfOtherEngines++;
      engineOptions.push({ engineId, name: `Other engine ${numberOfOtherEngines}` });
    }
  }

  return engineOptions;
}

export function getPropertyOptions({
  sensorOption,
  engineOption,
  emitter,
}: {
  sensorOption: SensorOption;
  engineOption?: EngineOption;
  emitter: Emitter;
}): PropertyOption[] {
  const propertyOptions: PropertyOption[] = [];

  for (const scope of sensorOption.scopes) {
    const sensor = emitter.sensors.find(
      (sensor) => sensor.name === scope.sensorName && sensor.engineId === engineOption?.engineId
    );

    if (!sensor) continue;

    const properties = scope.excludePropertyNames
      ? sensor.properties.filter(
          (property) => !scope.excludePropertyNames!.includes(property.name.name)
        )
      : sensor.properties;

    const requiredPropertyNames = scope.requiredPropertyNames ?? [];

    propertyOptions.push(
      ...properties.map((property) => ({
        property,
        required: requiredPropertyNames.some((n) => property.name.name === n),
      }))
    );
  }

  return propertyOptions.sort((a, b) => (a.required ? 1 : -1));
}
