import styles from '../../../LayerSelector.module.scss';
import { LayerSelectorMenuSection } from '../../LayerSelectorMenuSection';
import {
  showsAisLayerSelector,
  showsEcaLayerSelector,
  showsPositionHistoryLayerSelector,
  setShowsAisLayer,
  setShowsEcaLayer,
  setShowsPositionHistoryLayer,
  setSelectedEmissionLayer,
  selectedEmissionLayerSelector,
  AvailableMapEmissionName,
  showsAlertLayerSelector,
  setShowsAlertLayer,
} from 'Services/redux/map';
import { SensorName } from 'Constants';
import { CheckBoxOption, RadioButtonOption } from '../../Options';
import { LayersAvailability } from '../type';
import { getAqiSectionOptions } from '../helpers';

type VesselMenuProps = {
  layersAvailability: LayersAvailability;
};

export const VesselMenu: React.FC<VesselMenuProps> = ({ layersAvailability }) => {
  const navigationSectionOptions = getNavigationSectionOptions(layersAvailability);
  const emissionSectionOptions = getEmissionSectionOptions(layersAvailability);
  const aqiSectionOptions = getAqiSectionOptions(layersAvailability);

  return (
    <div className={styles.menu}>
      {!!navigationSectionOptions.length && (
        <LayerSelectorMenuSection title="Navigation">
          {navigationSectionOptions}
        </LayerSelectorMenuSection>
      )}
      <LayerSelectorMenuSection title="Charts">
        <CheckBoxOption
          label="ECAs"
          checkedSelector={showsEcaLayerSelector}
          checkedAction={setShowsEcaLayer}
        />
      </LayerSelectorMenuSection>
      {!!emissionSectionOptions.length && (
        <LayerSelectorMenuSection title="Emission">
          {emissionSectionOptions}
        </LayerSelectorMenuSection>
      )}
      {!!aqiSectionOptions.length && (
        <LayerSelectorMenuSection title="AQI">{aqiSectionOptions}</LayerSelectorMenuSection>
      )}
    </div>
  );
};

const getNavigationSectionOptions = (layersAvalability: LayersAvailability) => {
  const options: JSX.Element[] = [];

  if (layersAvalability.hasGpsSensors) {
    options.push(
      <CheckBoxOption
        key={SensorName.GPS.name}
        label="Position History"
        checkedSelector={showsPositionHistoryLayerSelector}
        checkedAction={setShowsPositionHistoryLayer}
      />
    );
  }

  if (layersAvalability.hasAisPositionalSensors) {
    options.push(
      <CheckBoxOption
        key={SensorName.AISPositional.name}
        label="AIS"
        checkedSelector={showsAisLayerSelector}
        checkedAction={setShowsAisLayer}
      />
    );
  }

  options.push(
    <CheckBoxOption
      key="alerts"
      label="Alerts"
      checkedSelector={showsAlertLayerSelector}
      checkedAction={setShowsAlertLayer}
    />
  );

  return options;
};

const getEmissionSectionOptions = (layersAvalability: LayersAvailability) => {
  const options: JSX.Element[] = [];

  if (layersAvalability.hasCo2Property) {
    options.push(
      <RadioButtonOption<AvailableMapEmissionName>
        key={SensorName.DerivedEmission.PropertyName.CO2Average.hash}
        identifier="CO2Average"
        label="CO2"
        selectedIdentifierSelector={selectedEmissionLayerSelector}
        selectIdentifierAction={setSelectedEmissionLayer}
      />
    );
  }

  if (layersAvalability.hasCh4Property) {
    options.push(
      <RadioButtonOption<AvailableMapEmissionName>
        key={SensorName.DerivedEmission.PropertyName.CH4DualAverage.hash}
        identifier="CH4DualAverage"
        label="CH4"
        selectedIdentifierSelector={selectedEmissionLayerSelector}
        selectIdentifierAction={setSelectedEmissionLayer}
      />
    );
  }

  if (layersAvalability.hasSo2Property) {
    options.push(
      <RadioButtonOption<AvailableMapEmissionName>
        key={SensorName.DerivedEmission.PropertyName.SO2Average.hash}
        identifier="SO2Average"
        label="SO2"
        selectedIdentifierSelector={selectedEmissionLayerSelector}
        selectIdentifierAction={setSelectedEmissionLayer}
      />
    );
  }

  if (layersAvalability.hasNoxProperty) {
    options.push(
      <RadioButtonOption<AvailableMapEmissionName>
        key={SensorName.DerivedEmission.PropertyName.NOXDualAverage.hash}
        identifier="NOXDualAverage"
        label="NOX"
        selectedIdentifierSelector={selectedEmissionLayerSelector}
        selectIdentifierAction={setSelectedEmissionLayer}
      />
    );
  }

  if (options.length) {
    options.unshift(
      <RadioButtonOption<AvailableMapEmissionName>
        key="no_emission"
        identifier={undefined}
        label="None"
        selectedIdentifierSelector={selectedEmissionLayerSelector}
        selectIdentifierAction={setSelectedEmissionLayer}
      />
    );
  }

  return options;
};
