import { Emitter, Sensor } from 'Models';
import { SensorName } from 'Constants';
import { SensorUpdateMessagePayload } from '../interfaces';
import { BaseMessageTest } from '../BaseMessageTest';
import { getSensorsWithName } from 'Helpers/sensors';
import { NodeServerObserver } from '..';

type GeneratorSensor = Sensor<typeof SensorName.Generator.name>;

export class GeneratorMessageTest extends BaseMessageTest {
  constructor(protected observer: NodeServerObserver, private emitters: Emitter[]) {
    super(SensorName.Generator.name, observer, 3000);
  }

  protected getMessagePayloads(cycleCount: number): SensorUpdateMessagePayload[] {
    return this.emitters.reduce<SensorUpdateMessagePayload[]>((acc, emitter) => {
      const generatorSensors = getSensorsWithName(SensorName.Generator, emitter);

      return generatorSensors.length
        ? acc.concat(
            generatorSensors.map((sensor) =>
              this.generatePayloadForEmitter(sensor, emitter, cycleCount)
            )
          )
        : acc;
    }, []);
  }

  private generatePayloadForEmitter(
    sensor: GeneratorSensor,
    emitter: Emitter,
    cycleCount: number
  ): SensorUpdateMessagePayload {
    return {
      metadata: { sensorId: sensor.id, ns: emitter.namespace, isSatcomm: false },
      latitude: emitter.coordinate && emitter.coordinate.latitude + cycleCount * 0.0007,
      longitude: emitter.coordinate && emitter.coordinate.longitude + cycleCount * 0.0007,
      bus: 'Starboard',
      frequency: Math.random() * 90,
      activePower: Math.random() * 900,
      reactivePower: Math.random() * 900,
      apparentPower: Math.random() * 900,
      powerFactor: 0,
      load: Math.random() * 90,
      systemTime: new Date().toISOString(),
    };
  }
}
