import { useEffect, useState } from 'react';
import { Option } from 'Components/SelectInput';
import { useGetRolesAndNamespacesQuery } from 'Services/redux/api/users';
import { capitalize } from 'Helpers/capitalize';

type useRolesAndNamespacesReturn = {
  namespacesOptions?: Option[];
  rolesOptions?: Option[];
  isLoading: boolean;
  isError: boolean;
};

export const useRolesAndNamespaces = (): useRolesAndNamespacesReturn => {
  const { data: response, isError, isLoading } = useGetRolesAndNamespacesQuery();

  const [namespacesOptions, setNamespacesOptions] = useState<Option[]>();
  const [rolesOptions, setRolesOptions] = useState<Option[]>();

  useEffect(() => {
    if (!isError && !isLoading && response) {
      const namespacesCollection = response.namespaces.map(
        (namespace): Option => ({
          value: namespace.code,
          label: namespace.displayName,
        })
      );

      const rolesCollection = response.roles.map(
        (role): Option => ({
          value: role,
          label: capitalize(role),
        })
      );

      setNamespacesOptions(namespacesCollection);
      setRolesOptions(rolesCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return {
    namespacesOptions,
    rolesOptions,
    isLoading,
    isError,
  };
};
