import { SectionTypeExpanded } from 'Models';
import { ReactElement, useMemo, useState } from 'react';
import { EmitterDetailsBarSection } from '../../EmitterDetailsBarSection';
import { OptionSectionHeader } from './components/OptionSectionHeader';
import { EmitterDetailsBarMenu } from '../../EmitterDetailsBarMenu';

type OptionSectionProps = {
  title: string;
  sections: SectionTypeExpanded[];
  componentForSection: (section: SectionTypeExpanded) => ReactElement | undefined;
  containedWithinSection?: boolean;
};

type SectionWithTitle = {
  title: string;
};

export const OptionSection: React.FC<OptionSectionProps> = ({
  title,
  sections,
  componentForSection,
  containedWithinSection = false,
}) => {
  const sectionsWithTitle: SectionWithTitle[] = useMemo(() => {
    const ret: SectionWithTitle[] = [];

    for (const section of sections) {
      const title = (section as any)['title'];
      if (typeof title === 'string' && title) {
        ret.push(section as any);
      }
    }

    return ret;
  }, [sections]);

  const options = useMemo(() => sectionsWithTitle.map((s) => s.title), [sectionsWithTitle]);

  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | undefined>(
    sectionsWithTitle.length > 0 ? 0 : undefined
  );

  const component: ReactElement | undefined = useMemo(() => {
    if (selectedOptionIndex === undefined) {
      return;
    }

    const section = sectionsWithTitle[selectedOptionIndex] as SectionTypeExpanded;

    return componentForSection(section);
  }, [componentForSection, sectionsWithTitle, selectedOptionIndex]);

  return containedWithinSection ? (
    <>
      <EmitterDetailsBarMenu
        menuItems={options}
        selectedMenuItemIndex={selectedOptionIndex ?? 0}
        onSelectMenuItem={setSelectedOptionIndex}
      />
      {component}
    </>
  ) : (
    <EmitterDetailsBarSection
      header={
        <OptionSectionHeader
          title={title}
          options={options}
          selectedOptionIndex={selectedOptionIndex}
          onSelectOption={setSelectedOptionIndex}
        />
      }
    >
      {component}
    </EmitterDetailsBarSection>
  );
};
