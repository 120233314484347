import styles from './EmitterDetailsBarFuelCell.module.scss';
import { useMemo, useEffect } from 'react';
import { Property } from 'Models';
import PuffLoader from 'react-spinners/PuffLoader';
import { fill, mergeSamples, values } from 'Helpers/sample';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import { generateTimelineBarDataInterval, getNewDataIndex } from 'Helpers/timeline';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { useQuery } from '@tanstack/react-query';
import { useLatestObservedSample } from 'Networking/socket';
import {
  FetchSampleSeriesQueryArgument,
  fetchLatestSample,
  fetchSampleSeries,
} from 'Networking/http';
import { SensorName } from 'Constants';

export type EmitterDetailsBarFuelCellProps = {
  title: string;
  fuelProperties: Property[];
};

export const EmitterDetailsBarFuelCell: React.FC<EmitterDetailsBarFuelCellProps> = ({
  title,
  fuelProperties,
}) => {
  const { absoluteInterval, refresh } = useSelectedInterval();

  useEffect(refresh, [fuelProperties, refresh]);

  const controlBarManager = useControlBarManager();

  const dataInterval = useMemo(() => {
    return generateTimelineBarDataInterval(
      absoluteInterval,
      controlBarManager.getTimelineBarSampleRateMs()
    );
  }, [absoluteInterval, controlBarManager]);

  const latestSampleQueryArg = { properties: fuelProperties, interval: dataInterval };
  const latestSampleQuery = useQuery(fetchLatestSample.generateQueryKey(latestSampleQueryArg), () =>
    fetchLatestSample(latestSampleQueryArg)
  );

  const sampleSeriesQueryArg: FetchSampleSeriesQueryArgument = {
    properties: fuelProperties,
    interval: dataInterval,
    aggregation: 'first',
    sample: {
      unit: 'second',
      rate: Math.floor(controlBarManager.getTimelineBarSampleRateMs() / 1000),
    },
    densify: true,
  };

  const sampleSeriesQuery = useQuery(fetchSampleSeries.generateQueryKey(sampleSeriesQueryArg), () =>
    fetchSampleSeries(sampleSeriesQueryArg)
  );

  const sample = latestSampleQuery.data;
  const observedSample = useLatestObservedSample(fuelProperties);

  const mergedSample = useMemo(
    () => mergeSamples(sample, observedSample),
    [sample, observedSample]
  );

  const viewMode = useViewMode();
  const { thumbPosition } = useTimelineThumb();

  const historicalSamples = useMemo(
    () => sampleSeriesQuery.data && fill(sampleSeriesQuery.data, 'forward'),
    [sampleSeriesQuery.data]
  );

  const historicalSample = useMemo(() => {
    const samples = historicalSamples;

    if (samples && samples.length) {
      const dataIndex = getNewDataIndex(
        thumbPosition,
        dataInterval,
        controlBarManager.getTimelineBarSampleRateMs()
      );
      const invertedIndex = Math.min(
        Math.max(samples.length - 1 - dataIndex, 0),
        samples.length - 1
      );
      return samples[invertedIndex];
    }

    return undefined;
  }, [controlBarManager, dataInterval, historicalSamples, thumbPosition]);

  const sampleToDisplay = useMemo(() => {
    return viewMode === 'replay' ? historicalSample : mergedSample;
  }, [historicalSample, mergedSample, viewMode]);

  const formattedValue = useMemo(() => {
    if (!sampleToDisplay) return undefined;

    const propertyValues = values(sampleToDisplay);
    const onProperties: Property[] = [];

    for (let i = 0; i < fuelProperties.length; i++) {
      const propertyValue = propertyValues[i];

      if (propertyValue === 1) {
        onProperties.push(fuelProperties[i]);
      }
    }

    if (!onProperties.length) return undefined;

    return onProperties
      .map((p) => getMode(p))
      .filter((mode) => !!mode)
      .join(', ');
  }, [fuelProperties, sampleToDisplay]);

  const propertiesHash = useMemo(() => {
    return fuelProperties
      .map((p) => p.hash)
      .sort()
      .join();
  }, [fuelProperties]);

  return (
    <div key={propertiesHash} className={styles.emitterDetailsBarFuelCell}>
      <p className={styles.title}>{title}</p>
      <div className={styles.body}>
        {latestSampleQuery.isLoading ? (
          <div className={styles.loadingIndicator}>
            <PuffLoader color="#44ABDF" size={40} />
          </div>
        ) : formattedValue !== undefined && formattedValue !== null ? (
          <p className={styles.value}>{formattedValue}</p>
        ) : (
          <p className={styles.noData}>n/a</p>
        )}
      </div>
    </div>
  );
};

function getMode(property: Property): string | undefined {
  switch (property.name.hash) {
    case SensorName.Engine.PropertyName.InGasMode.hash:
      return 'LNG';
    case SensorName.Engine.PropertyName.InMGOMode.hash:
      return 'MGO';
    default:
      return undefined;
  }
}
