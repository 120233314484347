import styles from './StepEmail.module.scss';
import { useCallback, useMemo } from 'react';
import { useShareEmitterContext } from '../../../hooks';
import { MultiEmailInput } from './components/MultiEmailInput';
import { useGetUsersQuery } from 'Services/redux/api/users';
import PuffLoader from 'react-spinners/PuffLoader';
import { Emitter, UserAccess } from 'Models';
import { useGetEmitterAccessorsQuery } from 'Services/redux/api/sensorSchemas';

const EMPTY_USER_ACCESSES: UserAccess[] = [];

type StepEmailProps = {
  emitter: Emitter;
};

export const StepEmail: React.FC<StepEmailProps> = ({ emitter }) => {
  const { users: selectedUsers, setUsers: setSelectedUsers } = useShareEmitterContext();
  const { data = { users: [] }, isFetching: isFetchingUsers } = useGetUsersQuery({
    pageSize: 1000,
  });

  const { data: existingUserAccesses = EMPTY_USER_ACCESSES, isFetching: isFetchingUserAccesses } =
    useGetEmitterAccessorsQuery({ emitter });

  const availableUsers = useMemo(
    () =>
      data.users.filter((user) =>
        existingUserAccesses.every((userAccess) => userAccess.user.altId !== user.altId)
      ),
    [data, existingUserAccesses]
  );

  const emailOptions = useMemo(() => availableUsers.map((u) => u.email), [availableUsers]);
  const selectedEmails = useMemo(() => selectedUsers.map((user) => user.email), [selectedUsers]);

  const handleChange = useCallback(
    (emails: string[]) => {
      const selectedUsers = emails
        .map((email) => emailOptions.indexOf(email))
        .map((index) => availableUsers[index]);
      setSelectedUsers(selectedUsers);
    },
    [availableUsers, emailOptions, setSelectedUsers]
  );

  return (
    <div className={styles.stepEmail}>
      {isFetchingUsers || isFetchingUserAccesses ? (
        <div className={styles.loadingIndicator}>
          <PuffLoader color="#44ABDF" size={40} />
        </div>
      ) : (
        <div className="form-control">
          <label>Email Addresses</label>
          <MultiEmailInput
            selectedEmails={selectedEmails}
            emailOptions={emailOptions}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};
