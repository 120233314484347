import style from './AlertRowMenu.module.scss';
import { forwardRef } from 'react';

type AlertRowMenuProps = {
  onClickDismiss: () => void;
};

export const AlertRowMenu = forwardRef<HTMLDivElement, AlertRowMenuProps>(
  ({ onClickDismiss }, ref) => {
    return (
      <div className={style.alertRowMenu} ref={ref}>
        <button className={style.dismiss} onClick={onClickDismiss}>
          Dismiss
        </button>
      </div>
    );
  }
);
