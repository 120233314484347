import { Emitter } from 'Models';
import { SensorGroup, SensorGroupMetadataDock, SensorGroupMetadataVessel } from '../interfaces';
import { transformIntoDock } from './dock.transformer';
import { transformIntoVessel } from './vessel.transformer';

export const transformIntoEmitter = (record: SensorGroup<unknown>): Emitter | undefined => {
  if (record.groupType === 'vessel') {
    return transformIntoVessel(record as SensorGroup<SensorGroupMetadataVessel>);
  } else if (record.groupType === 'dock') {
    return transformIntoDock(record as SensorGroup<SensorGroupMetadataDock>);
  }
};
