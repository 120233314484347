import { Emitter } from 'Models';
import { MapGeocode } from '../interfaces';
import { formatNumber } from 'Helpers/number';

const Parameter = {
  Emitter: 'source',
};

export const encodeGeocode = (geocode: MapGeocode) => {
  const coordinateFormatOptions = {
    minFractionDigits: 0,
    maxFractionDigits: 2,
  };

  const zoomFormatOptions = {
    minFractionDigits: 0,
    maxFractionDigits: 7,
  };

  return `@${formatNumber(geocode.latitude, coordinateFormatOptions)},${formatNumber(
    geocode.longitude,
    coordinateFormatOptions
  )},${formatNumber(geocode.zoom, zoomFormatOptions)}z`;
};

export const decodeGeocode = (str: string): MapGeocode | undefined => {
  const match = /@(-?\d*(?:\.\d+)?),(-?\d*(?:\.\d+)?),(\d*(?:\.\d+)?)z/.exec(str);

  if (!match) {
    return;
  }

  const latitude = +match[1];
  const longitude = +match[2];
  const zoom = +match[3];

  if (isNaN(latitude) || isNaN(longitude) || isNaN(zoom)) {
    return;
  }

  return {
    latitude,
    longitude,
    zoom,
  };
};

export const getGeocodeString = (urlString: string) => {
  const match = /@-?\d*(\.\d+)?,-?\d*(\.\d+)?,-?\d*(\.\d+)?z/.exec(urlString);

  if (!match) {
    return;
  }

  return match[0] as string;
};

export const updateGeocodeInUrl = (geocode: MapGeocode) => {
  const url = new URL(window.location.href);
  const urlGeocodeString = getGeocodeString(window.location.href);
  const pathWithoutGeocode = url.pathname.replace(`/${urlGeocodeString}`, '');

  let newUrl = `${pathWithoutGeocode || ''}/${encodeGeocode(geocode)}`;

  if (Array.from(url.searchParams.keys()).length) {
    newUrl += `?${url.searchParams}`;
  }

  /**
   * Don't use navigate method of react-router-dom.
   *
   * We have two different routes for `/dashboard`
   * and `/dashboard/:geocode`. Going to `/dashboard`
   * will trigger `onMoveEnd` event which in the end
   * will call this function (`updateGeocodeInUrl`).
   *
   * If we use `navigate` instead of
   * `window.history.replaceState`, it will trigger
   * rendering because react-router-dom see that
   * we're going to different route.
   */
  window.history.replaceState(window.history.state, '', newUrl);
};

export const getEmitterInUrl = (urlString: string, emitters: Emitter[]): Emitter | undefined => {
  const url = new URL(urlString);
  const emitterId = url.searchParams.get(Parameter.Emitter);

  return emitterId !== null ? emitters.find((e) => e.id === emitterId) : undefined;
};

export const updateEmitterInUrl = (emitter: Emitter | null) => {
  const newUrl = new URL(window.location.href);

  if (emitter) {
    newUrl.searchParams.set(Parameter.Emitter, emitter.id);
  } else {
    newUrl.searchParams.delete(Parameter.Emitter);
  }

  window.history.replaceState(window.history.state, '', newUrl);
};
