import { Emitter, Vessel } from 'Models';
import { useEffect, useMemo, useState } from 'react';
import { useObserveEmitterCoordinate } from './useObserveEmitterCoordinate';
import { isVessel } from 'Helpers/emitter';
import { useObserveVesselRotation } from './useObserveVesselRotation';
import { usePoseSeriesByVessel } from 'Pages/DashboardPage/components/Map/components/Layers/VesselLayer/hooks';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import {
  generateTimelineBarDataInterval,
  generateTimelineBarInterval,
  getNewDataIndex,
} from 'Helpers/timeline';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { useQuery } from '@tanstack/react-query';
import { fetchEmitters } from 'Networking/http';

const EMPTY_EMITTERS: Emitter[] = [];
const EMPTY_VESSELS: Vessel[] = [];

export const useSyncedEmitters = () => {
  const emittersQuery = useQuery(fetchEmitters.generateQueryKey(), () => fetchEmitters());
  const [liveEmitters, setLiveEmitters] = useState<Emitter[] | undefined>();
  const [replayEmitters, setReplayEmitters] = useState<Emitter[] | undefined>();

  useEffect(() => {
    if (emittersQuery.data) {
      setLiveEmitters(emittersQuery.data);
    }
  }, [emittersQuery.data]);

  const controlBarManager = useControlBarManager();
  const { absoluteInterval } = useSelectedInterval();

  const dataInterval = useMemo(
    () =>
      generateTimelineBarDataInterval(
        generateTimelineBarInterval(
          absoluteInterval,
          controlBarManager.getTimelineBarSampleRateMs()
        ),
        controlBarManager.getTimelineBarSampleRateMs()
      ),
    [absoluteInterval, controlBarManager]
  );

  const replayVessels = useMemo(() => {
    return emittersQuery.data?.filter(isVessel);
  }, [emittersQuery.data]);

  const { poseSeriesByVessel, query } = usePoseSeriesByVessel({
    vessels: replayVessels ?? [],
    earliest: dataInterval.earliest,
    latest: dataInterval.latest,
    sampleRateMs: controlBarManager.getTimelineBarSampleRateMs(),
  });

  const { thumbPosition } = useTimelineThumb();

  useEffect(() => {
    if (!poseSeriesByVessel) return;

    const updatedEmitters: Emitter[] | undefined = emittersQuery.data?.map((emitter) => {
      if (!isVessel(emitter)) {
        return emitter;
      }
      const poseSeries = poseSeriesByVessel[emitter.id];

      if (poseSeries) {
        const index = getNewDataIndex(
          thumbPosition,
          controlBarManager.getTimelineBarInterval(),
          controlBarManager.getTimelineBarSampleRateMs()
        );

        const invertedIndex = Math.max(
          Math.min(poseSeries.length - 1 - index, poseSeries.length - 1),
          0
        );

        const pose = poseSeries[invertedIndex];

        return {
          ...emitter,
          coordinate: pose.coordinate,
          rotation: pose.rotation,
          systemTime: pose.timestamp,
        };
      }
      return emitter;
    });
    setReplayEmitters(updatedEmitters);
  }, [controlBarManager, emittersQuery.data, poseSeriesByVessel, thumbPosition]);

  useObserveEmitterCoordinate(liveEmitters ?? EMPTY_EMITTERS, (coordinate, timestamp, emitter) => {
    setLiveEmitters((prevEmitters) => {
      if (!prevEmitters) return prevEmitters;

      const existingEmitterIndex = prevEmitters.findIndex((e) => e.id === emitter.id);
      const existingEmitter = prevEmitters[existingEmitterIndex];

      // We only care for vessel coordinate update
      if (
        existingEmitterIndex !== -1 &&
        isVessel(existingEmitter) &&
        (!existingEmitter.systemTime || new Date(timestamp) >= new Date(existingEmitter.systemTime))
      ) {
        const updatedVessel = { ...existingEmitter, coordinate, systemTime: timestamp };
        const updatedEmitters = [...prevEmitters];
        updatedEmitters[existingEmitterIndex] = updatedVessel;

        return updatedEmitters;
      }

      return prevEmitters;
    });
  });

  const liveVessels = useMemo(() => liveEmitters?.filter(isVessel), [liveEmitters]);

  useObserveVesselRotation(liveVessels ?? EMPTY_VESSELS, (rotation, timestamp, vessel) => {
    setLiveEmitters((prevEmitters) => {
      if (!prevEmitters) return prevEmitters;

      const existingEmitterIndex = prevEmitters.findIndex((e) => e.id === vessel.id);
      const existingEmitter = prevEmitters[existingEmitterIndex];

      if (
        existingEmitterIndex !== -1 &&
        isVessel(existingEmitter) &&
        (!existingEmitter.systemTime || new Date(timestamp) >= new Date(existingEmitter.systemTime))
      ) {
        const updatedVessel: Vessel = { ...existingEmitter, rotation, systemTime: timestamp };
        const updatedEmitters = [...prevEmitters];
        updatedEmitters[existingEmitterIndex] = updatedVessel;

        return updatedEmitters;
      }

      return prevEmitters;
    });
  });

  const viewMode = useViewMode();

  const resolvedEmitters = useMemo(() => {
    return viewMode === 'replay' ? replayEmitters : liveEmitters;
  }, [liveEmitters, replayEmitters, viewMode]);

  return { emitters: resolvedEmitters, isFetching: query.isFetching };
};
