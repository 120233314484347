export const MONTH_OPTIONS = [
  { label: 'January', monthIndex: 0 },
  { label: 'February', monthIndex: 1 },
  { label: 'March', monthIndex: 2 },
  { label: 'April', monthIndex: 3 },
  { label: 'May', monthIndex: 4 },
  { label: 'June', monthIndex: 5 },
  { label: 'July', monthIndex: 6 },
  { label: 'August', monthIndex: 7 },
  { label: 'September', monthIndex: 8 },
  { label: 'October', monthIndex: 9 },
  { label: 'November', monthIndex: 10 },
  { label: 'December', monthIndex: 11 },
];
