import { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { styleConfig } from './constants/config';
import React from 'react';

type Option = { label: string; value: string };

interface MultiEmailInputProps {
  selectedEmails: string[];
  emailOptions: string[];
  placeholder?: string;
  onChange: (selectedEmails: string[]) => void;
}

export const MultiEmailInput: React.FC<MultiEmailInputProps> = React.memo(
  ({ selectedEmails, emailOptions, placeholder = '', onChange }) => {
    const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement>();

    useEffect(() => {
      const portalElement = document.getElementById('portal') as HTMLElement;
      setMenuPortalTarget(portalElement);
    }, []);

    const options: Option[] = emailOptions.map((email) => ({ label: email, value: email }));

    const handleChange = (selectedOptions: MultiValue<Option>) => {
      const emails = selectedOptions.map((option) => option.value);
      onChange(emails);
    };

    return (
      <Select
        isMulti
        value={selectedEmails.map((email) => ({ value: email, label: email }))}
        options={options}
        onChange={handleChange}
        styles={styleConfig}
        placeholder={placeholder}
        menuPortalTarget={menuPortalTarget}
        maxMenuHeight={172}
      />
    );
  }
);

export default MultiEmailInput;
