import { createContext } from 'react';
import { SubscriptionManager } from 'Networking/socket/alerts/SubscriptionManager';

export interface IContext {
  subscriptionManager: SubscriptionManager;
}

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AlertsProvider>.');
};

const initialContext = {
  subscriptionManager: stub,
} as unknown as IContext;

export const Context = createContext(initialContext);
