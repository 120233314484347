import { useEffect, useMemo } from 'react';
import { Source, Layer, LayerProps } from 'react-map-gl';
import { FeatureCollection, Point } from 'geojson';
import IconDock from './resources/graphics/icon-dock.png';
import IconDockHighlighted from './resources/graphics/icon-dock-highlighted.png';
import { Dock } from 'Models';
import { setIsLoadingDockLayer } from 'Services/redux/map';
import { useAppDispatch } from 'Services/redux';
import { MapFeature, MapLayerProps } from '../../../interfaces';
import { useMapImage } from '../../../hooks';

const DOCK_IMAGE_KEY = 'icon-dock';
const DOCK_HIGHLIGHTED_IMAGE_KEY = 'icon-dock-highlighted';

const BASE_LAYER_PROPS: LayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': ['case', ['get', 'highlighted'], DOCK_HIGHLIGHTED_IMAGE_KEY, DOCK_IMAGE_KEY],
    'icon-size': 0.5,
    'icon-allow-overlap': true,
    'symbol-z-order': 'source',
  },
};

const getFeature = (dock: Dock, highlighted: boolean): MapFeature | undefined =>
  dock.coordinate && {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [dock.coordinate.longitude, dock.coordinate.latitude],
    },
    id: dock.id,
    properties: {
      element: JSON.stringify({
        value: dock,
        type: 'dock',
      }),
      highlighted,
    },
  };

type DockLayerProps = {
  docks?: Dock[];
  selectedDock?: Dock;
} & MapLayerProps;

export const DockLayer: React.FC<DockLayerProps> = ({ docks, selectedDock, id, beforeId }) => {
  useMapImage(IconDock, DOCK_IMAGE_KEY);
  useMapImage(IconDockHighlighted, DOCK_HIGHLIGHTED_IMAGE_KEY);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsLoadingDockLayer(!docks));

    return () => {
      dispatch(setIsLoadingDockLayer(false));
    };
  }, [dispatch, docks]);

  const memoizedFeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: (docks ?? [])
        .sort((a, b) =>
          a.id === selectedDock?.id && b.id !== selectedDock?.id
            ? 1
            : a.id !== selectedDock?.id && b.id === selectedDock?.id
            ? -1
            : 0
        )
        .map((d) => getFeature(d, d.id === selectedDock?.id))
        .filter((mf): mf is MapFeature => !!mf),
    } as FeatureCollection<Point>;
  }, [docks, selectedDock]);

  const layerProps = { id, ...BASE_LAYER_PROPS };

  if (beforeId) {
    layerProps.beforeId = beforeId;
  }

  return (
    <Source id={id} type="geojson" data={memoizedFeatureCollection}>
      <Layer {...layerProps} />
    </Source>
  );
};
