import styles from './BiaxialChart.module.scss';
import { useCallback, useMemo } from 'react';
import { Axis, ChartEntry, TooltipData } from './interfaces';
import { ChartSeries } from './interfaces/chart-series';
import { extent } from 'd3-array';
import {
  bisectDate,
  calculateAxisWidth,
  formatTimestamp,
  getChartEntryDate,
  getChartEntryValue,
  getDomain,
  normalizeSeries,
} from './helpers';
import { Group } from '@visx/group';
import { useTooltip, defaultStyles, useTooltipInPortal } from '@visx/tooltip';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Bar, Line, LinePath } from '@visx/shape';
import { localPoint } from '@visx/event';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Dot, TimestampTick, TimestampTickProps } from './components';
import { TICK_FONT_FAMILY, TICK_FONT_SIZE, TICK_FONT_WEIGHT } from './constants';
import { formatNumber } from 'Helpers/number';
import { TimestampTicks } from './components/TimestampTicks';

const MARGIN_TOP = 14;
const MARGIN_BOTTOM = 50;
const SAFE_AXIS_WIDTH = 43;

type BiaxialChartProps = {
  width: number;
  height: number;
  firstSeries?: ChartSeries;
  secondSeries?: ChartSeries;
  linePointerTimestamp?: string;
};

function chooseMinValue(n1: number | undefined, n2: number | undefined): number {
  if (n1 === undefined && n2 !== undefined) {
    return n2;
  }
  if (n2 === undefined && n1 !== undefined) {
    return n1;
  }

  if (n1 !== undefined && n2 !== undefined) {
    return Math.min(n1, n2);
  }
  return 0;
}

function chooseMaxValue(n1: number | undefined, n2: number | undefined): number {
  if (n1 === undefined && n2 !== undefined) {
    return n2;
  }
  if (n2 === undefined && n1 !== undefined) {
    return n1;
  }

  if (n1 !== undefined && n2 !== undefined) {
    return Math.max(n1, n2);
  }
  return 0;
}

// Algorithm:
// hiRes = takeAll where lowRes = false, add emptys where lowRes = true
// lowRes = take where lowRes = true and add emptys where lowRes = false and hasValue,
// and add neighboring left and rights points to lowRes = true points
// Note: entries must be a sorted and deduped by timestamp
function createGraphLines(entries: ChartEntry[]) {
  const hiRes: Record<string, ChartEntry> = {};
  const lowRes: Record<string, ChartEntry> = {};

  let lastWithValue;
  for (let i = 0; i < entries.length; i++) {
    const incommingIsLowRes = entries[i].lowRes;
    const incommingIsHiRes = !incommingIsLowRes;
    const incommingHasValue = typeof entries[i].value === 'number';
    const lastIsLowRes = lastWithValue?.lowRes;
    const lastHasHiResValue = !lastIsLowRes && typeof lastWithValue?.value === 'number';
    const incommingHasHiResValue = incommingIsHiRes && incommingHasValue;
    const emptyEntry = {
      ...entries[i],
      value: null,
    };

    if (incommingIsLowRes && lastHasHiResValue && lastWithValue) {
      lowRes[lastWithValue.timestamp] = lastWithValue;
    } else if (incommingIsHiRes) {
      hiRes[entries[i].timestamp] = entries[i];
    }

    if (incommingIsLowRes) {
      lowRes[entries[i].timestamp] = entries[i];
      hiRes[entries[i].timestamp] = emptyEntry;
    } else if (incommingHasHiResValue && lastIsLowRes) {
      lowRes[entries[i].timestamp] = entries[i];
    } else if (incommingHasHiResValue) {
      lowRes[entries[i].timestamp] = emptyEntry;
    }

    if (incommingHasValue) {
      lastWithValue = entries[i];
    }
  }

  return {
    dottedLine: Object.values(lowRes),
    solidLine: Object.values(hiRes),
  };
}

//Finds all points which do not have a second point needed to create a line
function createGraphPoints(...entrySet: ChartEntry[][]) {
  const points: ChartEntry[] = [];
  for (const entries of entrySet) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const hasValue = typeof entry.value === 'number';
      const noLeftValue = i === 0 || typeof entries[i - 1].value !== 'number';
      const noRightValue = i === entries.length - 1 || typeof entries[i + 1].value !== 'number';

      if (hasValue && noLeftValue && noRightValue) {
        points.push(entry);
      }
    }
  }
  return points;
}

function getTooltipData(x0: Date, series: ChartSeries | undefined) {
  if (series) {
    const index = bisectDate(series.entries, x0, 1);
    const d0 = series.entries[index - 1];
    const d1 = series.entries[index];

    let d = d0;

    if (d1 && getChartEntryDate(d1)) {
      d =
        x0.valueOf() - getChartEntryDate(d0).valueOf() >
        getChartEntryDate(d1).valueOf() - x0.valueOf()
          ? d1
          : d0;
    }
    return d;
  }
}

export const BiaxialChart: React.FC<BiaxialChartProps> = ({
  width,
  height,
  firstSeries: rawFirstSeries,
  secondSeries: rawSecondSeries,
  linePointerTimestamp,
}) => {
  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  } = useTooltip<TooltipData>();

  const { containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const firstSeries = useMemo(
    () => rawFirstSeries && normalizeSeries(rawFirstSeries),
    [rawFirstSeries]
  );

  const secondSeries = useMemo(
    () => rawSecondSeries && normalizeSeries(rawSecondSeries),
    [rawSecondSeries]
  );

  const haveSameUnit = useMemo(
    () =>
      firstSeries &&
      secondSeries &&
      typeof firstSeries.unit === 'string' &&
      typeof secondSeries.unit === 'string' &&
      firstSeries.unit === secondSeries.unit,
    [firstSeries, secondSeries]
  );

  const innerHeight = Math.max(0, height - MARGIN_TOP - MARGIN_BOTTOM);

  const [firstSeriesEntries, secondSeriesEntries] = useMemo(() => {
    const firstSeriesEntries =
      firstSeries?.entries.filter((e): e is Required<ChartEntry> => typeof e.value === 'number') ??
      [];

    const secondSeriesEntries =
      secondSeries?.entries.filter((e): e is Required<ChartEntry> => typeof e.value === 'number') ??
      [];

    if (haveSameUnit) {
      const all = firstSeriesEntries.concat(secondSeriesEntries);
      return [all, all];
    }

    return [
      firstSeriesEntries.length ? firstSeriesEntries : undefined,
      secondSeriesEntries.length ? secondSeriesEntries : undefined,
    ];
  }, [firstSeries, secondSeries, haveSameUnit]);

  const [leftDomain, rightDomain] = useMemo(() => {
    let firstDomain: [number, number] | undefined;
    let secondDomain: [number, number] | undefined;

    if (firstSeriesEntries && firstSeries) {
      firstDomain = getDomain(
        firstSeriesEntries,
        firstSeries.defaultLowerDomainY,
        firstSeries.defaultUpperDomainY
      );
    }

    if (secondSeriesEntries && secondSeries) {
      secondDomain = getDomain(
        secondSeriesEntries,
        secondSeries.defaultLowerDomainY,
        secondSeries.defaultUpperDomainY
      );
    }

    if (haveSameUnit) {
      const domain: [number, number] = [
        chooseMinValue(firstDomain?.at(0), secondDomain?.at(0)),
        chooseMaxValue(firstDomain?.at(1), secondDomain?.at(1)),
      ];
      return [domain, domain];
    }

    return [firstDomain, secondDomain];
  }, [firstSeriesEntries, secondSeriesEntries, firstSeries, secondSeries, haveSameUnit]);

  const leftAxis: Axis | undefined = useMemo(() => {
    if (!firstSeriesEntries || !firstSeries || !leftDomain) return undefined;

    const scale = scaleLinear({
      range: [MARGIN_TOP + innerHeight, MARGIN_TOP],
      domain: leftDomain,
      nice: true,
    });

    const unit = firstSeries.unit;

    const formatter = (value: number) => {
      return (
        formatNumber(value, {
          minFractionDigits: firstSeries.minFractionDigits ?? 0,
          maxFractionDigits: firstSeries.maxFractionDigits,
        }) + (unit ? ' ' + unit : '')
      );
    };

    const width = calculateAxisWidth({
      domain: leftDomain,
      maximumFractionDigits: firstSeries.maxFractionDigits,
      unit,
    });

    return { scale, formatter, width };
  }, [innerHeight, firstSeries, firstSeriesEntries, leftDomain]);

  const rightAxis: Axis | undefined = useMemo(() => {
    if (!secondSeriesEntries || !secondSeries || !rightDomain) return undefined;

    const scale = scaleLinear({
      range: [MARGIN_TOP + innerHeight, MARGIN_TOP],
      domain: rightDomain,
      nice: true,
    });

    const unit = secondSeries.unit;

    const formatter = (value: number) => {
      return (
        formatNumber(value, {
          minFractionDigits: secondSeries.minFractionDigits ?? 0,
          maxFractionDigits: secondSeries.maxFractionDigits,
        }) + (unit ? ' ' + unit : '')
      );
    };

    const width = calculateAxisWidth({
      domain: rightDomain,
      maximumFractionDigits: secondSeries.maxFractionDigits,
      unit,
    });

    return { scale, formatter, width };
  }, [innerHeight, secondSeries, secondSeriesEntries, rightDomain]);

  const tickTextAnchor: TimestampTickProps['textAnchor'] = useMemo(
    () => (leftAxis && rightAxis ? 'middle' : leftAxis ? 'end' : rightAxis ? 'start' : 'middle'),
    [leftAxis, rightAxis]
  );

  const margin = useMemo(
    () => ({
      top: MARGIN_TOP,
      right: rightAxis
        ? tickTextAnchor === 'start'
          ? Math.max(SAFE_AXIS_WIDTH, rightAxis.width)
          : rightAxis.width
        : 0.5,
      bottom: MARGIN_BOTTOM,
      left: leftAxis
        ? tickTextAnchor === 'end'
          ? Math.max(SAFE_AXIS_WIDTH, leftAxis.width)
          : leftAxis.width
        : 0.5,
    }),
    [leftAxis, rightAxis, tickTextAnchor]
  );

  const innerWidth = useMemo(
    () => Math.max(0, width - margin.left - margin.right),
    [margin.left, margin.right, width]
  );

  const dateScale = useMemo(() => {
    const allEntries = (firstSeries?.entries ?? []).concat(secondSeries?.entries ?? []);

    return scaleTime({
      range: [margin.left, margin.left + innerWidth],
      domain: extent(allEntries, getChartEntryDate) as [Date, Date],
    });
  }, [firstSeries?.entries, secondSeries?.entries, margin.left, innerWidth]);

  const handleTooltip = useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const tooltipX = event.clientX - containerBounds.x;
      const tooltipY = event.clientY - containerBounds.y;

      const x0 = dateScale.invert(x);

      const firstEntry = getTooltipData(x0, firstSeries);
      const secondEntry = getTooltipData(x0, secondSeries);

      if (firstEntry || secondEntry) {
        showTooltip({
          tooltipData: {
            secondEntry,
            firstEntry,
          },
          tooltipLeft: tooltipX,
          tooltipTop: tooltipY,
        });
      }
    },
    [containerBounds.x, containerBounds.y, dateScale, firstSeries, secondSeries, showTooltip]
  );

  const {
    dottedLine: firstDottedLine,
    solidLine: firstSolidLine,
    points: firstPoints,
  } = useMemo(() => {
    const lines = createGraphLines(firstSeries?.entries ?? []);
    const points = createGraphPoints(lines.dottedLine, lines.solidLine);

    return { ...lines, points };
  }, [firstSeries]);

  const {
    dottedLine: secondDottedLine,
    solidLine: secondSolidLine,
    points: secondPoints,
  } = useMemo(() => {
    const lines = createGraphLines(secondSeries?.entries ?? []);
    const points = createGraphPoints(lines.dottedLine, lines.solidLine);
    return { ...lines, points };
  }, [secondSeries]);

  const linePointerLeft = useMemo(() => {
    if (!linePointerTimestamp) return undefined;
    const left = dateScale(new Date(linePointerTimestamp));
    return Math.min(margin.left + innerWidth, Math.max(margin.left, left));
  }, [dateScale, innerWidth, linePointerTimestamp, margin.left]);

  return (
    <div className={styles.biaxialChart}>
      <svg width={width} height={height}>
        {(leftAxis || rightAxis) && (
          <GridRows
            scale={leftAxis?.scale ?? rightAxis!.scale}
            left={margin.left}
            width={innerWidth}
            height={innerHeight}
            stroke="#D3D9E7"
            numTicks={5}
          />
        )}
        {!leftAxis && (
          <g>
            <Line
              from={{ x: 0.5, y: margin.top }}
              to={{ x: 0.5, y: innerHeight + margin.top }}
              stroke="#D3D9E7"
              strokeWidth={1}
              pointerEvents="none"
            />
          </g>
        )}
        {!rightAxis && (
          <g>
            <Line
              from={{ x: margin.left + innerWidth - 0, y: margin.top }}
              to={{ x: margin.left + innerWidth - 0, y: innerHeight + margin.top }}
              stroke="#D3D9E7"
              strokeWidth={1}
              pointerEvents="none"
            />
          </g>
        )}
        {leftAxis && (
          <AxisLeft
            left={margin.left}
            scale={leftAxis.scale}
            stroke="#D3D9E7"
            tickStroke="#D3D9E7"
            tickFormat={leftAxis.formatter as any}
            numTicks={5}
            tickLabelProps={() => ({
              textAnchor: 'end',
              fontFamily: TICK_FONT_FAMILY,
              fontSize: TICK_FONT_SIZE,
              fontWeight: TICK_FONT_WEIGHT,
            })}
          />
        )}
        {rightAxis && (
          <AxisRight
            left={margin.left + innerWidth}
            scale={rightAxis.scale}
            stroke="#D3D9E7"
            tickStroke="#D3D9E7"
            tickFormat={rightAxis.formatter as any}
            numTicks={5}
            tickLabelProps={() => ({
              textAnchor: 'start',
              fontFamily: TICK_FONT_FAMILY,
              fontSize: TICK_FONT_SIZE,
              fontWeight: TICK_FONT_WEIGHT,
            })}
          />
        )}
        <AxisBottom
          top={margin.top + innerHeight}
          scale={dateScale}
          stroke="#D3D9E7"
          tickStroke="#D3D9E7"
          numTicks={5}
          tickFormat={(v) => (v as Date).toISOString()}
          tickComponent={(props) => <TimestampTick {...props} textAnchor={tickTextAnchor} />}
          ticksComponent={(props) => <TimestampTicks {...props} />}
        />

        {firstSolidLine && leftAxis && firstSeries && (
          <LinePath
            data={firstSolidLine}
            x={(d) => dateScale(getChartEntryDate(d))}
            y={(d) => leftAxis.scale(getChartEntryValue(d) ?? 0)}
            defined={(d) => typeof getChartEntryValue(d) === 'number'}
            stroke={firstSeries.color}
            strokeWidth={2}
            shapeRendering="geometricPrecision"
          />
        )}

        {firstPoints && leftAxis && firstSeries && (
          <Group>
            {firstPoints.map((point, i) => (
              <Bar
                key={i}
                x={dateScale(getChartEntryDate(point))}
                y={leftAxis.scale(getChartEntryValue(point) ?? 0)}
                width={2.0}
                height={2.0}
                fill={firstSeries.color}
              />
            ))}
          </Group>
        )}

        {firstDottedLine && leftAxis && firstSeries && (
          <LinePath
            data={firstDottedLine}
            x={(d) => dateScale(getChartEntryDate(d))}
            y={(d) => leftAxis.scale(getChartEntryValue(d) ?? 0)}
            defined={(d) => typeof getChartEntryValue(d) === 'number'}
            stroke={firstSeries.color}
            strokeDasharray="6 6"
            strokeWidth={2}
            shapeRendering="geometricPrecision"
          />
        )}

        {secondDottedLine && rightAxis && secondSeries && (
          <LinePath
            data={secondDottedLine}
            x={(d) => dateScale(getChartEntryDate(d))}
            y={(d) => rightAxis.scale(getChartEntryValue(d) ?? 0)}
            defined={(d) => typeof getChartEntryValue(d) === 'number'}
            stroke={secondSeries.color}
            strokeDasharray="6 6"
            strokeWidth={2}
            shapeRendering="geometricPrecision"
          />
        )}

        {secondSolidLine && rightAxis && secondSeries && (
          <LinePath
            data={secondSolidLine}
            x={(d) => dateScale(getChartEntryDate(d))}
            y={(d) => rightAxis.scale(getChartEntryValue(d) ?? 0)}
            defined={(d) => typeof getChartEntryValue(d) === 'number'}
            stroke={secondSeries.color}
            strokeWidth={2}
            shapeRendering="geometricPrecision"
          />
        )}

        {secondPoints && rightAxis && secondSeries && (
          <Group>
            {secondPoints.map((point, i) => (
              <Bar
                key={i}
                x={dateScale(getChartEntryDate(point))}
                y={rightAxis.scale(getChartEntryValue(point) ?? 0)}
                width={2.0}
                height={2.0}
                fill={secondSeries.color}
              />
            ))}
          </Group>
        )}

        {tooltipData && (tooltipData.firstEntry || tooltipData.secondEntry) && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: margin.top }}
              to={{ x: tooltipLeft, y: innerHeight + margin.top }}
              stroke="#D3D9E7"
              strokeWidth={1}
              pointerEvents="none"
            />
            {firstSeries &&
              tooltipData.firstEntry &&
              typeof getChartEntryValue(tooltipData.firstEntry) === 'number' &&
              leftAxis && (
                <Dot
                  cx={dateScale(getChartEntryDate(tooltipData.firstEntry))}
                  cy={leftAxis.scale(getChartEntryValue(tooltipData.firstEntry)!)}
                  color={firstSeries.color}
                />
              )}
            {secondSeries &&
              tooltipData.secondEntry &&
              typeof getChartEntryValue(tooltipData.secondEntry) === 'number' &&
              rightAxis && (
                <Dot
                  cx={dateScale(getChartEntryDate(tooltipData.secondEntry))}
                  cy={rightAxis.scale(getChartEntryValue(tooltipData.secondEntry)!)}
                  color={secondSeries.color}
                />
              )}
          </g>
        )}
        {!!linePointerLeft && (
          <Line
            from={{ x: linePointerLeft, y: margin.top }}
            to={{ x: linePointerLeft, y: innerHeight + margin.top }}
            stroke="#929292"
            strokeWidth={1}
            pointerEvents="none"
          />
        )}
        <Bar
          x={margin.left}
          y={margin.top}
          width={innerWidth}
          height={innerHeight}
          fill="transparent"
          rx={14}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
      </svg>
      {tooltipData && (tooltipData.firstEntry || tooltipData?.secondEntry) && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop - 12}
          left={tooltipLeft}
          offsetLeft={17}
          style={{
            ...defaultStyles,
            padding: 0,
            zIndex: 999,
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          }}
        >
          <div className={styles.tooltipContent}>
            {typeof tooltipData.firstEntry?.value !== 'number' &&
            typeof tooltipData.secondEntry?.value !== 'number' ? (
              <div className={styles.tooltipRow}>
                <p>{formatTimestamp(dateScale.invert(tooltipLeft).toISOString())}</p>
              </div>
            ) : (
              <>
                {firstSeries && typeof tooltipData.firstEntry?.value === 'number' && leftAxis && (
                  <div className={styles.tooltipRow}>
                    <p style={{ color: firstSeries.color }}>
                      <span>{firstSeries.name}: </span>
                      <span>{leftAxis.formatter(tooltipData.firstEntry.value)}</span>
                      <span>{tooltipData.firstEntry.lowRes ? ' - Low Frequency' : ''}</span>
                    </p>
                    <p>{formatTimestamp(tooltipData.firstEntry.timestamp)}</p>
                  </div>
                )}
                {secondSeries &&
                  typeof tooltipData.secondEntry?.value === 'number' &&
                  rightAxis && (
                    <div className={styles.tooltipRow}>
                      <p style={{ color: secondSeries.color }}>
                        <span>{secondSeries.name}: </span>
                        <span>{rightAxis.formatter(tooltipData.secondEntry.value)}</span>
                        <span>{tooltipData.secondEntry.lowRes ? ' - Low Frequency' : ''}</span>
                      </p>
                      <p>{formatTimestamp(tooltipData.secondEntry.timestamp)}</p>
                    </div>
                  )}
              </>
            )}
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
};
