type Hash = string;

export type Hashable = string | number | { hash: Hash };

export class OrderedSet<T extends Hashable> {
  private contentsArray: T[] = [];
  private contentLookup: Record<Hash, T> = {};

  constructor(...items: T[]) {
    this.push(...items);
  }

  get length() {
    return this.contentsArray.length;
  }

  push(...items: T[]) {
    for (const item of items) {
      const hash = this.getHash(item);
      if (!this.contentLookup[hash]) {
        this.contentLookup[hash] = item;
        this.contentsArray.push(item);
      } else {
        this.replace(item, hash);
      }
    }
  }

  private replace(item: T, hash: Hash) {
    const index = this.contentsArray.indexOf(this.contentLookup[hash]);
    if (index !== -1) {
      this.contentsArray.splice(index, 1);
      this.contentsArray.push(item);
      this.contentLookup[hash] = item;
    }
  }

  concat(items: OrderedSet<T>): OrderedSet<T> {
    return new OrderedSet(...this.contentsArray.concat(items.contentsArray));
  }

  map<U>(callbackfn: (value: T, index: number, set: OrderedSet<T>) => U, thisArg?: any): U[] {
    return this.contentsArray.map((value, index) => callbackfn(value, index, this), thisArg);
  }

  filter(
    predicate: (value: T, index: number, orderedSet: OrderedSet<T>) => boolean,
    thisArg?: any
  ): OrderedSet<T> {
    return new OrderedSet<T>(
      ...this.contentsArray.filter((value, index) => predicate(value, index, this), thisArg)
    );
  }

  toArray() {
    return this.contentsArray.slice();
  }

  [Symbol.iterator]() {
    let index = -1;
    let data = this.contentsArray;

    return {
      next: () => ({ value: data[++index], done: !(index in data) }),
    };
  }

  private getHash(item: T): Hash {
    return typeof item === 'string' || typeof item === 'number' ? String(item) : item.hash;
  }
}
