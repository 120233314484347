import { User as UserRecord } from '../interfaces';
import { User } from 'Models/user';

export const transformIntoUser = (record: UserRecord): User => {
  return {
    id: record._id,
    altId: record.altId,
    email: record.email,
    firstName: record.firstName,
    lastName: record.lastName,
    namespace: record.namespace,
    roles: record.roles,
    createdAt: record.createdAt,
    updatedAt: record.updatedAt,
  };
};
