import { useMemo } from 'react';
import moment from 'moment';

export const ChartDateTick = ({ x, y, payload }: any) => {
  const timestamp = payload.value;

  const hour = useMemo(() => moment(timestamp).format('HH:mm'), [timestamp]);
  const date = useMemo(() => moment(timestamp).format('DD MMM'), [timestamp]);

  return (
    <g
      transform={`translate(${x},${y})`}
      style={{
        fontFamily: 'Barlow, Red Hat Display, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: 14,
        fontWeight: 500,
        fill: '#000',
      }}
    >
      <text x={0} y={0} dy={16} textAnchor="end">
        {hour}
      </text>
      <text x={0} y={0} dy={35} textAnchor="end">
        {date}
      </text>
    </g>
  );
};
