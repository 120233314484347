import styles from './AlertsBarHeader.module.scss';
import { TabNavigator, TabNavigatorElement } from 'Components/TabNavigator';
import { ReactComponent as IconArrowClose } from './resources/graphics/icon-arrow-close.svg';
import { ReactComponent as IconDots } from './resources/graphics/icon-dots.svg';

type AlertBarHeaderProps = {
  tabs: TabNavigatorElement[];
  onSelectTab: (selectedIndex: number) => void;
  onClickCollapseButton: () => void;
};

export const AlertsBarHeader: React.FC<AlertBarHeaderProps> = ({
  tabs,
  onSelectTab,
  onClickCollapseButton,
}) => {
  return (
    <div className={styles.alertsBarHeader}>
      <h3>Alert Center</h3>
      <div className={styles.options}>
        <button>
          <IconDots />
        </button>
        <button onClick={onClickCollapseButton}>
          <IconArrowClose />
        </button>
      </div>
      <div className={styles.tabNavigatorContainer}>
        <TabNavigator
          tabs={tabs}
          normalTextColor="rgba(255, 255, 255, 0.6)"
          selectedTextColor="#fff"
          underlineColor="rgba(255, 255, 255, 0.7)"
          onSelectTab={onSelectTab}
        />
      </div>
    </div>
  );
};
