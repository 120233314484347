import { Ais, Emitter, Sensor } from 'Models';
import { SensorName } from 'Constants';
import { SensorUpdateMessagePayload } from '../interfaces';
import { BaseMessageTest } from '../BaseMessageTest';
// import { getFirstSensorWithName } from 'Helpers/sensors';
import { NodeServerObserver } from '..';
import { sample } from 'lodash';
import { getFirstSensorWithName } from 'Helpers/sensors';

const STARTING_MMSI = 1000000;

type AisPositionalSensor = Sensor<typeof SensorName.AISPositional.name>;

interface AisCycles {
  [mmsi: NonNullable<Ais['mmsi']>]: number;
}

export class AisMessageTest extends BaseMessageTest {
  private aisCycles: AisCycles = {};

  constructor(
    protected observer: NodeServerObserver,
    private emitter: Emitter,
    readonly aisList: Ais[]
  ) {
    super(SensorName.AISPositional.name, observer, 3000);

    this.aisCycles = aisList.reduce<AisCycles>(
      (acc, ais) => (typeof ais.mmsi === 'number' ? { ...acc, [ais.mmsi]: 0 } : acc),
      {}
    );
  }

  protected getMessagePayloads(cycleCount: number): SensorUpdateMessagePayload[] {
    const aisPositionalSensor = getFirstSensorWithName(SensorName.AISPositional, this.emitter);

    if (!aisPositionalSensor) return [];

    let payload: SensorUpdateMessagePayload | undefined;

    if ((cycleCount - 1) % 3 === 0) {
      payload = this.generateNewAisPayload(aisPositionalSensor, cycleCount);
    } else {
      payload = this.generateModifiedAisPayload(aisPositionalSensor);
    }

    return payload ? [payload] : [];
  }

  private generateNewAisPayload(
    sensor: AisPositionalSensor,
    cycleCount: number
  ): SensorUpdateMessagePayload | undefined {
    if (!this.emitter.coordinate) return;

    const mmsi = STARTING_MMSI + cycleCount;

    this.aisCycles[mmsi] = 0;

    return {
      metadata: { sensorId: sensor.id, ns: this.emitter.namespace, isSatcomm: false },
      latitude: this.emitter.coordinate.latitude + 0.0007,
      longitude: this.emitter.coordinate.longitude + 0.0007,
      roll: Math.random() * 30.0 * 2 - 30.0,
      type: 1,
      mmsi,
      status: 0,
      cog: Math.random() * 359.9,
      sog: Math.random() * 25,
      accuracy: 0,
      trueHeading: 511,
      distanceKm: Math.random() * 5,
      systemTime: new Date().toISOString(),
    };
  }

  private generateModifiedAisPayload(
    sensor: AisPositionalSensor
  ): SensorUpdateMessagePayload | undefined {
    const mmsiString = sample(Object.keys(this.aisCycles));

    if (!mmsiString) return;

    const mmsi = +mmsiString;

    const aisCycle = this.aisCycles[mmsi] as number;

    const newAisCycle = aisCycle + 1;

    this.aisCycles[mmsi] = newAisCycle;

    return {
      metadata: { sensorId: sensor.id, ns: this.emitter.namespace, isSatcomm: false },
      latitude:
        this.emitter.coordinate && this.emitter.coordinate.latitude + (newAisCycle + 1) * 0.0007,
      longitude:
        this.emitter.coordinate && this.emitter.coordinate.longitude + (newAisCycle + 1) * 0.0007,
      roll: Math.random() * 30.0 * 2 - 30.0,
      type: 1,
      mmsi,
      status: 0,
      cog: Math.random() * 359.9,
      sog: Math.random() * 25,
      accuracy: 0,
      trueHeading: 511,
      distanceKm: Math.random() * 5,
      systemTime: new Date().toISOString(),
    };
  }
}
