import styles from './EmitterDetailsBarCellSet.module.scss';
import { FC, ReactNode } from 'react';

type EmitterDetailsValueCellSetProps = {
  children: ReactNode;
};

export const EmitterDetailsBarCellSet: FC<EmitterDetailsValueCellSetProps> = ({ children }) => {
  return (
    <div className={styles.emitterDetailsBarCellSet}>
      <div className={styles.emitterDetailsCellSetContent}>{children}</div>
    </div>
  );
};
