import styles from './MenuPopup.module.scss';
import {
  CSSProperties,
  ChangeEvent,
  MouseEventHandler,
  RefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as IconArrowRight } from './resources/graphics/icon-arrow-right.svg';
import { ReactComponent as IconMagnifier } from './resources/graphics/icon-magnifier.svg';
import Highlighter from 'react-highlight-words';
import clsx from 'clsx';
import React from 'react';
import { createPortal } from 'react-dom';
import { forwardRef } from 'react';

const positionMenu = (parent: HTMLLIElement, menu: HTMLDivElement) => {
  const parentRect = parent.getBoundingClientRect();
  const spaceRight = window.innerWidth - parentRect.right;

  if (spaceRight < menu.offsetWidth) {
    menu.style.right = '100%';
    menu.style.left = 'auto';
  } else {
    menu.style.right = 'auto';
    menu.style.left = '100%';
  }
};

const PATH_SEPARATOR = '/';

export interface MenuItem {
  title: string;
  subitems?: MenuItem[];
}

interface OperatingMenuItem {
  title: string;
  fullTitle: string;
  indexPathString: string;
  subitems: OperatingMenuItem[];
}

type MenuPopupProps = {
  items: MenuItem[];
  className?: string;
  searchable?: boolean;
  sourceRef: RefObject<HTMLElement>;
  onSelect: (indexPath: number[]) => void;
};

export const MenuPopup = forwardRef<HTMLDivElement, MenuPopupProps>(
  ({ items, searchable = false, sourceRef, onSelect }, ref) => {
    const innerContainerRef = useRef<HTMLDivElement>(null);

    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [hasScroll, setHasScroll] = useState(false);

    const operatingItems: OperatingMenuItem[] = useMemo(
      () => items.map((item, index) => getOperatingMenuItem(item, index)),
      [items]
    );

    const isSearching = useMemo(() => searchQuery.length >= 2, [searchQuery]);

    const searchedItems: OperatingMenuItem[] = useMemo(
      () => (isSearching ? findMatchingItem(searchQuery, operatingItems) : []),
      [isSearching, searchQuery, operatingItems]
    );

    useLayoutEffect(() => {
      if (innerContainerRef.current) {
        setContainerWidth(innerContainerRef.current.getBoundingClientRect().width);
      }
    }, [sourceRef]);

    const handleClickMenuItem: MouseEventHandler<HTMLElement> = (event) => {
      const dataset = event.currentTarget.dataset;

      const indexPathString = dataset.indexPath as string;

      const indexPath = indexPathString.split(PATH_SEPARATOR).map((index) => +index);

      onSelect(indexPath);
    };

    const handleChangeSearchTextInput = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;

      const newSearchQuery = value?.length ?? 0 ? value : '';

      setSearchQuery(newSearchQuery);
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLLIElement>) => {
      const ulElement = e.currentTarget.querySelector('div > ul');
      const divElement = e.currentTarget.querySelector('div');

      if (ulElement) {
        ulElement.scrollTop = 0;
        divElement && positionMenu(e.currentTarget, divElement);

        if (ulElement.scrollHeight > ulElement.clientHeight) {
          setHasScroll(true);
        } else {
          setHasScroll(false);
        }
      }
    };

    const handleScroll = (e: React.MouseEvent<HTMLUListElement>) => {
      const ulElement = e.currentTarget;
      if (ulElement.scrollTop === ulElement.scrollHeight - ulElement.clientHeight) {
        setHasScroll(false);
      } else {
        setHasScroll(true);
      }
    };

    const getElementToRender = ({
      operatingItems,
      searchable,
      isRoot,
    }: {
      operatingItems: OperatingMenuItem[];
      searchable: boolean;
      isRoot: boolean;
    }) => {
      return (
        <div
          className={clsx(styles.menu, {
            [styles.hasSearchControl]: searchable,
            [styles.hasArrow]: isRoot,
            [styles.hasScroll]: hasScroll,
          })}
        >
          {searchable && (
            <div className={styles.searchControl}>
              <div className={styles.searchInput}>
                <IconMagnifier className={styles.searchInputIcon} />
                <input
                  type="text"
                  value={searchQuery}
                  placeholder="Search"
                  onChange={handleChangeSearchTextInput}
                />
              </div>
            </div>
          )}
          <ul onScroll={handleScroll}>
            {isSearching
              ? searchedItems.map((searchedItem) => (
                  <li
                    key={searchedItem.indexPathString}
                    onClick={handleClickMenuItem}
                    data-index-path={searchedItem.indexPathString}
                  >
                    <span>
                      <Highlighter
                        searchWords={[searchQuery]}
                        textToHighlight={searchedItem.fullTitle}
                        highlightClassName={styles.highlight}
                      />
                    </span>
                  </li>
                ))
              : operatingItems.map((operatingItem) => (
                  <li
                    key={operatingItem.indexPathString}
                    onClick={hasSubitems(operatingItem) ? undefined : handleClickMenuItem}
                    data-index-path={operatingItem.indexPathString}
                    onMouseEnter={handleMouseEnter}
                  >
                    <span>{operatingItem.title}</span>
                    {hasSubitems(operatingItem) && (
                      <>
                        <IconArrowRight />
                        {getElementToRender({
                          operatingItems: operatingItem.subitems,
                          searchable: false,
                          isRoot: false,
                        })}
                      </>
                    )}
                  </li>
                ))}
          </ul>
        </div>
      );
    };

    // Calculate position based on source
    const menuContainerStyle: CSSProperties | undefined = useMemo(() => {
      const source = sourceRef.current;

      if (!source) {
        return undefined;
      }

      const boundingRect = source.getBoundingClientRect();
      const rightInset = 20;

      const containerRight = boundingRect.left + containerWidth;
      const overflows = containerRight - window.innerWidth + rightInset;
      const correction = Math.min(0, -overflows);

      return {
        position: 'absolute',
        left: boundingRect.left + correction,
        top: boundingRect.bottom,
        zIndex: 1000,
        '--pointer-correction': `${-correction / 16}rem`,
      };
    }, [containerWidth, sourceRef]);

    return createPortal(
      <div ref={ref} style={menuContainerStyle}>
        <div ref={innerContainerRef}>
          {getElementToRender({
            operatingItems,
            searchable,
            isRoot: true,
          })}
        </div>
      </div>,
      document.getElementById('portal') as HTMLElement
    );
  }
);

const hasSubitems = (
  item: MenuItem
): item is { id: string; title: string; subitems: MenuItem[] } => {
  return !!(item.subitems && item.subitems.length);
};

const findMatchingItem = (query: string, items: OperatingMenuItem[]): OperatingMenuItem[] => {
  const results: OperatingMenuItem[] = [];

  for (const item of items) {
    if (item.subitems.length) {
      const matchedItems = findMatchingItem(query, item.subitems);
      results.push(...matchedItems);
    } else if (item.title.toLowerCase().includes(query.toLowerCase())) {
      results.push(item);
    }
  }

  return results;
};

const getOperatingMenuItem = (
  item: MenuItem,
  index: number,
  parentItem?: OperatingMenuItem
): OperatingMenuItem => {
  const fullTitle = parentItem?.fullTitle ? `${parentItem.fullTitle} / ${item.title}` : item.title;
  const indexPathString = parentItem?.indexPathString
    ? `${parentItem.indexPathString}${PATH_SEPARATOR}${index}`
    : `${index}`;

  const operatingItem: OperatingMenuItem = {
    title: item.title,
    fullTitle,
    indexPathString,
    subitems: [],
  };

  operatingItem.subitems = (item.subitems ?? []).map((subitem, index) =>
    getOperatingMenuItem(subitem, index, operatingItem)
  );

  return operatingItem;
};

export const getMenuItemByIndexPath = (
  indexPath: number[],
  menuItems: MenuItem[]
): MenuItem | undefined => {
  if (indexPath.length === 0) return undefined;

  let ret: MenuItem | undefined;

  for (let step = 0; step < indexPath.length; step++) {
    const index = indexPath[step];

    if (step === 0) {
      ret = menuItems[index];
    } else {
      ret = ret?.subitems?.[index];
    }

    if (!ret) break;
  }

  return ret;
};
