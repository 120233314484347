import { Vessel } from 'Models';
import { Flag, Iso3166Part1Alpha2Identifier } from 'Components/Flag';
import { MapPopup, styles } from 'Components/MapPopup';
import { DMSCoordinate } from 'Components/DMSCoordinate';
import Moment from 'react-moment';

type VesselPopupProps = {
  vessel: Vessel;
  showsCloseButton: boolean;
  onClose?: () => void;
};

export const VesselPopup: React.FC<VesselPopupProps> = ({ vessel, showsCloseButton, onClose }) => {
  if (!vessel.coordinate) {
    return null;
  }

  return (
    <MapPopup
      latitude={vessel.coordinate.latitude}
      longitude={vessel.coordinate.longitude}
      closeButton={showsCloseButton}
      onClose={onClose}
    >
      <div className={styles.section}>
        <p>
          {vessel.ais?.flag && (
            <span className={styles.flag}>
              <Flag identifier={vessel.ais.flag as Iso3166Part1Alpha2Identifier} />
            </span>
          )}
          <span className={styles.title}>{vessel.name}</span>
        </p>
      </div>
      <hr />
      <div className={styles.section}>
        <DMSCoordinate coordinate={vessel.coordinate} />
        {vessel.ais?.destination && (
          <p>
            Dest:&nbsp;<strong>{vessel.ais?.destination}</strong>
          </p>
        )}
        {vessel.phone && (
          <p>
            Phone:&nbsp;<strong>{vessel.phone}</strong>
          </p>
        )}
        {vessel.email && (
          <p>
            Email:&nbsp;<strong>{vessel.email}</strong>
          </p>
        )}
        {vessel.captain && (
          <p>
            Captain:&nbsp;<strong>{vessel.captain}</strong>
          </p>
        )}
        {typeof vessel.mmsi === 'number' && (
          <p>
            MMSI:&nbsp;<strong>{vessel.mmsi}</strong>
          </p>
        )}
        {vessel.systemTime && (
          <p>
            Updated:&nbsp;
            <strong>
              <Moment format="M/D/y, HH:mm:ss">{vessel.systemTime}</Moment>
            </strong>
          </p>
        )}
      </div>
    </MapPopup>
  );
};
