import styles from './ViewModeSelector.module.scss';
import clsx from 'clsx';
import { ViewMode, ViewModeSwitch } from './components';

type ViewModeSelectorProps = {
  mode: ViewMode;
  onToggle: () => void;
};

export const ViewModeSelector: React.FC<ViewModeSelectorProps> = ({ mode, onToggle }) => {
  return (
    <div className={styles.viewModeSelector}>
      <span className={clsx(styles.label, mode === 'replay' && styles.highlighted)}>Replay</span>
      <ViewModeSwitch mode={mode} onToggle={onToggle} />
      <span className={clsx(styles.label, mode === 'live' && styles.highlighted)}>Live</span>
    </div>
  );
};
