import { IPropertyName } from 'Constants';
import { getConversionConfig } from 'Helpers/conversion';
import { getPropertiesWithNames } from 'Helpers/sensors';
import { Conversion } from 'Interfaces';
import { Emitter } from 'Models';

export type UnitOption = { label: string; conversion: Conversion };

export function generateUnitOptions(
  emitters: Emitter[],
  propertyNames: IPropertyName[]
): UnitOption[] {
  const unit = getPossibleUnit(emitters, propertyNames);

  if (unit) {
    const conversionConfig = getConversionConfig(unit);

    if (conversionConfig) {
      return conversionConfig.conversions.map((c) => ({
        // Shlomo requested to remove the /hr part because
        // we already divided the result by 60
        label: c.unit.replace('/hr', ''),
        conversion: { ...c, unit: c.unit.replace('/hr', '') },
      }));
    }
  }

  return [];
}

function getPossibleUnit(emitters: Emitter[], propertyNames: IPropertyName[]): string | undefined {
  for (const emitter of emitters) {
    const properties = getPropertiesWithNames(propertyNames, emitter.sensors);

    for (const property of properties) {
      if (property.unit) {
        return property.unit;
      }
    }
  }

  return undefined;
}
