import { useQuery } from '@tanstack/react-query';
import { AbsoluteInterval, SensorName } from 'Constants';
import { getFirstSensorWithName } from 'Helpers/sensors';
import { Degrees } from 'Interfaces';
import { Dock, Sensor } from 'Models';
import { FetchMultiSampleSeriesArgument, Sample, fetchMultiSampleSeries } from 'Networking/http';
import { OptionalTuple } from 'Types/utility';
import { useMemo } from 'react';
import { AqiPropertyName, AqiSample, AqiSamplesByDock } from './type';
import { fill } from 'Helpers/sample';

type SampleValues = [latitude: Degrees, longitude: Degrees, aqi: number];
type ResponseSample = Sample<OptionalTuple<SampleValues>>;

interface UseAqiSampleSeriesByDockArgument {
  docks: Dock[];
  interval: AbsoluteInterval;
  sampleRateMs: number;
  propertyName: AqiPropertyName;
}

interface UseAqiSampleSeriesByDockOptions {
  enabled: boolean;
}

export const useAqiSampleSeriesByDock = (
  { docks, interval, sampleRateMs, propertyName }: UseAqiSampleSeriesByDockArgument,
  options: UseAqiSampleSeriesByDockOptions = { enabled: true }
) => {
  const airQualitySensors = useMemo(() => {
    return (
      docks
        ?.map((dock) => getFirstSensorWithName(SensorName.AirQuality, dock))
        ?.filter((sensor): sensor is Sensor<'airQuality'> => !!sensor) ?? []
    );
  }, [docks]);

  const arg: FetchMultiSampleSeriesArgument = {
    propertyNames: [
      SensorName.AirQuality.PropertyName.Latitude,
      SensorName.AirQuality.PropertyName.Longitude,
      propertyName,
    ],
    sensors: airQualitySensors,
    interval,
    aggregation: 'first',
    sample: {
      unit: 'second',
      rate: Math.floor(sampleRateMs / 1000),
    },
    densify: true,
  };

  const query = useQuery(
    fetchMultiSampleSeries.generateQueryKey(arg),
    () => fetchMultiSampleSeries<SampleValues>(arg),
    { enabled: options.enabled }
  );

  const aqiSampleSeriesByDock = useMemo(() => {
    if (!options.enabled || !query.data) return {};

    const res: AqiSamplesByDock = {};

    for (let index = 0; index < query.data.length; index++) {
      const dock = docks[index];

      const responseSamples: ResponseSample[] = query.data[index];
      const filledResponseSamples = fill(responseSamples, 'forward');

      const aqiSamples: AqiSample[] = [];

      for (const filledResponseSample of filledResponseSamples) {
        const [latitude, longitude, value, timestamp] = filledResponseSample;

        if (
          typeof latitude === 'number' &&
          typeof longitude === 'number' &&
          typeof value === 'number'
        ) {
          aqiSamples.push({
            timestamp,
            data: {
              coordinate: { latitude, longitude },
              value,
            },
          });
        } else {
          aqiSamples.push({ timestamp, data: null });
        }
      }

      res[dock.id] = aqiSamples;
    }

    return res;
  }, [docks, options.enabled, query.data]);

  return { isLoading: query.isLoading, isFetching: query.isFetching, aqiSampleSeriesByDock };
};
