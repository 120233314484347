import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { urlConfiguration } from 'Config';
import { getAccessTokenSilently } from 'Services/auth0';

const baseQuery = fetchBaseQuery({
  baseUrl: urlConfiguration.api,
  prepareHeaders: async (headers, { getState }) => {
    const token = await getAccessTokenSilently();

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const apiApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithRetry,
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   */
  tagTypes: ['sensor', 'sensorSchemas'],
  endpoints: () => ({}),
});
