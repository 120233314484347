import styles from './SelectInput.module.scss';
import Select from 'react-select';
import { SingleValue, StylesConfig } from 'react-select';
import { forwardRef } from 'react';
import { capitalize } from 'Helpers/capitalize';

export type Option = { value: string; label: string };

export type OptionWithId = Option & { id: string };

type SelectInputProps = {
  value?: OptionWithId | null;
  options?: OptionWithId[];
  onChange?: (value: SingleValue<OptionWithId>) => void;
  placeholder?: string;
  isDisabled?: boolean;
  menuPlacement?: 'top' | 'bottom';
};

export const SelectInput = forwardRef<any, SelectInputProps>((props, ref) => {
  return (
    <div className={styles.selectInput}>
      <Select
        {...props}
        styles={singleSelectStyles}
        ref={ref}
        menuPlacement={props.menuPlacement}
      />
    </div>
  );
});

const singleSelectStyles: StylesConfig<OptionWithId, false> = {
  control: (styles, { isDisabled, menuIsOpen }) => {
    return {
      ...styles,
      border: isDisabled
        ? '1px solid #EAEEF5'
        : menuIsOpen
        ? '1px solid #009fe3'
        : '1px solid #D2D9E8',
      background: isDisabled ? '#fff' : '#fff',
      boxShadow: 'none',
      ':hover': {
        border: menuIsOpen ? '1px solid #009fe3' : '1px solid #9397a1',
      },
      cursor: 'pointer',
      height: `${42 / 16}rem`,
    };
  },
  placeholder: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? '#aaa' : '#000',
    };
  },
  option: (styles, { isSelected, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#c6ecff' : isFocused ? '#f1f3f7' : '#fff',
      color: '#000',
      cursor: 'pointer',
      zIndex: '1000',
      height: `${42 / 16}rem`,
      display: 'flex',
      alignItems: 'center',
      '&:active': {
        backgroundColor: '#f1f3f7',
      },
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: '999',
    };
  },
  indicatorSeparator: (styles) => {
    return {
      ...styles,
      display: 'none',
    };
  },
};

export const selectIdTransformer = (
  selectValue: string,
  selectInputId = 'default'
): OptionWithId => {
  return { value: selectValue, label: capitalize(selectValue), id: selectInputId };
};

export const selectOptionIdTransformer = (
  selectOption: Option,
  selectInputId = 'default'
): OptionWithId => {
  return { value: selectOption.value, label: selectOption.label, id: selectInputId };
};

export const selectIdsTransformer = (
  selectOptions: Option[],
  selectInputId: string = 'default'
): OptionWithId[] => {
  return selectOptions.map((option) => ({
    ...option,
    id: selectInputId,
  }));
};
