import { Vessel } from 'Models';
import { SensorName } from 'Constants';
import { getFirstPropertyWithName, getFirstSensorWithName } from 'Helpers/sensors';
import { useMemo, useEffect } from 'react';
import { MapRef } from 'react-map-gl';
import { useSelectedInterval } from 'Hooks/utils/useSelectedInterval';
import { useSpatioTemporalCache } from '../../../../hooks';
import { DerivedEmissionsEntry } from '../interfaces';
import {
  useControlBarManager,
  useTimelineThumb,
  useViewMode,
} from 'Pages/DashboardPage/components/ControlBar/hooks';
import { calculatePositionDate } from 'Helpers/timeline';

export type SupportedEmissions =
  | 'CO2Average'
  | 'CH4DualAverage'
  | 'H2OAverage'
  | 'NOXDualAverage'
  | 'SO2Average';

const cutOffs: Record<SupportedEmissions, number> = {
  [SensorName.DerivedEmission.PropertyName.CO2Average.name]: 0.1,
  [SensorName.DerivedEmission.PropertyName.CH4DualAverage.name]: 50,
  [SensorName.DerivedEmission.PropertyName.H2OAverage.name]: 1,
  [SensorName.DerivedEmission.PropertyName.NOXDualAverage.name]: 50,
  [SensorName.DerivedEmission.PropertyName.SO2Average.name]: 50,
};

export type GetEmissionsQueryArgs = {
  vessel: Vessel;
  emissionType: SupportedEmissions;
  mapRef: MapRef;
};

export const useGetEmissionsQuery = ({ vessel, emissionType, mapRef }: GetEmissionsQueryArgs) => {
  const controlBarManager = useControlBarManager();

  const { absoluteInterval, refresh } = useSelectedInterval();

  const { thumbPosition } = useTimelineThumb();

  const thumbPositionDate = useMemo(
    () => calculatePositionDate(thumbPosition, controlBarManager.getTimelineBarInterval()),
    [controlBarManager, thumbPosition]
  );

  const adjustedThumbPositionDate = new Date(
    new Date(thumbPositionDate).getTime() + controlBarManager.getTimelineBarSampleRateMs()
  ).toISOString();

  const viewMode = useViewMode();

  const layerInterval =
    viewMode === 'live'
      ? absoluteInterval
      : {
          earliest: absoluteInterval.earliest,
          latest: adjustedThumbPositionDate,
        };

  useEffect(refresh, [emissionType, vessel.id, refresh]);

  useEffect(() => {
    // Wrapping it is necessary to prevent global conflict
    const listener = () => {
      refresh();
    };

    window.addEventListener('focus', listener);
    window.addEventListener('online', listener);

    return () => {
      window.removeEventListener('focus', listener);
      window.removeEventListener('online', listener);
    };
  }, [refresh]);

  const requestParams = useMemo(() => {
    const emissionsSensor = getFirstSensorWithName(SensorName.DerivedEmission, vessel);

    if (!emissionsSensor) {
      return;
    }

    const property = getFirstPropertyWithName(
      SensorName.DerivedEmission.PropertyName[emissionType],
      emissionsSensor
    );

    const latitude = getFirstPropertyWithName(
      SensorName.DerivedEmission.PropertyName.Latitude,
      emissionsSensor
    );
    const longitude = getFirstPropertyWithName(
      SensorName.DerivedEmission.PropertyName.Longitude,
      emissionsSensor
    );
    const systemTime = getFirstPropertyWithName(
      SensorName.DerivedEmission.PropertyName.SystemTime,
      emissionsSensor
    );

    if (latitude && longitude && systemTime && property) {
      return {
        property,
        latitude,
        longitude,
        systemTime,
      };
    }
  }, [emissionType, vessel]);

  if (!requestParams) {
    console.assert('gps sensor is missing or missing required properties');
  }

  const propertyName = requestParams?.property.name.name ?? 'CO2Average';

  const { entries, isFetching } = useSpatioTemporalCache({
    interval: layerInterval,
    mapRef,
    queryFilter: {
      [propertyName]: {
        $gt: cutOffs[propertyName] ?? 0,
      },
    },
    property: requestParams?.property,
    latitudeProperty: requestParams?.latitude,
    longitudeProperty: requestParams?.longitude,
    systemTimeProperty: requestParams?.systemTime,
  });

  return {
    entries: entries as DerivedEmissionsEntry[],
    isFetching,
  };
};
