import styles from './SelectsRow.module.scss';
import { SingleValue } from 'react-select';
import React, { Ref, useCallback, useRef, useState } from 'react';
import { CheckBox } from 'Components/CheckBox';
import { OptionWithId, SelectInput } from 'Components/SelectInput';

type SelectsRowProps = {
  namespaceDisplay: string;
  namespaceCode: string;
  rolesOptions: OptionWithId[];
  onOptionChange: (selectedOption: SingleValue<OptionWithId>) => void;
  onDisableNamespace: (namespace: string) => void;
  menuPlacement?: 'top' | 'bottom';
};

export const SelectsRow: React.FC<SelectsRowProps> = ({
  namespaceDisplay,
  namespaceCode,
  rolesOptions,
  onOptionChange,
  onDisableNamespace,
  menuPlacement,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const selectInputRef: Ref<any> = useRef();

  const handleInputCheckboxChange = useCallback(() => {
    if (!isDisabled) {
      setIsDisabled(true);
      onDisableNamespace(namespaceCode);
      selectInputRef.current.clearValue();
    } else {
      setIsDisabled(false);
    }
  }, [onDisableNamespace, namespaceCode, isDisabled]);

  return (
    <div className={styles.rowSelect}>
      <div className={styles.namespaceCheckbox}>
        <div className={styles.wrapper} onClick={handleInputCheckboxChange}>
          <CheckBox checked={!isDisabled} />
          <span>{namespaceDisplay}</span>
        </div>
      </div>
      <div className={styles.selectPermission}>
        <SelectInput
          ref={selectInputRef}
          options={rolesOptions}
          onChange={onOptionChange}
          placeholder="Select role"
          isDisabled={isDisabled}
          menuPlacement={menuPlacement}
        />
      </div>
    </div>
  );
};
