import { useEffect, useMemo } from 'react';
import { Emitter, Property } from 'Models';
import { getPropertiesWithNames } from 'Helpers/sensors';
import { IPropertyName, StaticInterval } from 'Constants';
import { getUniqueProperties } from 'Services/redux/api/sensor/util';
import { PropertyFrame, getFramesForProperty, useGetPropertiesFramesQuery } from 'Hooks/queries';
import { ChartEntry } from '../interfaces';
import { SubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState';

export type EntriesByEmitter = { [emitterId: Emitter['id']]: ChartEntry[] };

type UseGetEntriesByEmitterArgument = {
  emitters: Emitter[];
  interval: Required<StaticInterval>;
  propertyNames: IPropertyName[];
};

export const useGetEntriesByEmitter = (
  { emitters, interval, propertyNames }: UseGetEntriesByEmitterArgument,
  options?: SubscriptionOptions
) => {
  const propertiesByEmitter = useMemo(() => {
    const ret: { [emitterId: Emitter['id']]: Property[] } = {};

    for (const emitter of emitters) {
      const properties = getPropertiesWithNames(propertyNames, emitter.sensors);

      ret[emitter.id] = properties;
    }

    return ret;
  }, [emitters, propertyNames]);

  const properties = useMemo(
    () => getUniqueProperties(Object.values(propertiesByEmitter).flat()),
    [propertiesByEmitter]
  );

  useEffect(() => {}, []);

  const { propertiesFrames, isFetching, isLoading, isUninitialized, refetch } =
    useGetPropertiesFramesQuery(
      {
        properties,
        earliest: interval.earliest,
        latest: interval.latest,
        aggregation: 'sum',
        sample: {
          unit: 'day',
          rate: 1,
        },
        densify: true,
      },
      false,
      options
    );

  const entriesByEmitter = useMemo(() => {
    const ret: EntriesByEmitter = {};

    for (const emitter of emitters) {
      const properties = propertiesByEmitter[emitter.id] ?? [];

      let summedFrames: PropertyFrame[] = [];

      for (const property of properties) {
        const frames = getFramesForProperty(property, propertiesFrames);
        summedFrames = sumFrames(summedFrames, frames);
      }

      const entries = summedFrames
        .map((frame) => ({
          value: typeof frame.value === 'number' ? frame.value / 60 : null,
          timestamp: new Date(frame.date).getTime(),
        }))
        .sort((a, b) => a.timestamp - b.timestamp);

      ret[emitter.id] = entries;
    }

    return ret;
  }, [emitters, propertiesByEmitter, propertiesFrames]);

  return { entriesByEmitter, isFetching, isLoading, isUninitialized, refetch };
};

function sumFrames(...propertyFramesInput: PropertyFrame[][]): PropertyFrame[] {
  const combinedPropertyFrames: PropertyFrame[] = [];

  propertyFramesInput.forEach((propertyFrames) => {
    propertyFrames.forEach((element) => {
      const existingElementIndex = combinedPropertyFrames.findIndex((e) => e.date === element.date);

      if (existingElementIndex !== -1) {
        if (typeof element.value === 'number') {
          const existingValue = combinedPropertyFrames[existingElementIndex].value;

          if (typeof existingValue === 'number') {
            combinedPropertyFrames[existingElementIndex].value = existingValue + element.value;
          } else {
            combinedPropertyFrames[existingElementIndex].value = element.value;
          }
        }
      } else {
        // If no matching element is found, add a new element to the combinedPropertyFrames
        combinedPropertyFrames.push({
          value: element.value,
          date: element.date,
          errors: element.errors,
          isSatcomm: element.isSatcomm,
        });
      }
    });
  });

  return combinedPropertyFrames;
}
