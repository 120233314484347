import styles from './EmitterAccessorsModal.module.scss';
import { useCallback, useState } from 'react';
import { Emitter, UserAccess } from 'Models';
import { Modal } from 'Components/Modal';
import { UserAccessesTable } from './components/UserAccessesTable';
import { ControlBar } from './components/ControlBar';
import { ShareEmitterAccessModal, SubmitData } from './components/ShareEmitterAccessModal';
import {
  useGetEmitterAccessorsQuery,
  useRevokeEmitterAccessMutation,
  useShareEmitterMutation,
  useUpdateEmitterAccessMutation,
} from 'Services/redux/api/sensorSchemas';
import { Dialog } from 'Components/Dialog';

const EMPTY_USER_ACCESSES: UserAccess[] = [];

type EmitterAccessorsModalProps = {
  emitter: Emitter;
  onClose: () => void;
};

export const EmitterAccessorsModal: React.FC<EmitterAccessorsModalProps> = ({
  emitter,
  onClose,
}) => {
  const [isShareEmitterAccessModalVisible, setIsShareEmitterAccessModalVisible] = useState(false);
  const [editingUserAccess, setEditingUserAccess] = useState<UserAccess>();
  const [revokingUserAccess, setRevokingUserAccess] = useState<UserAccess>();

  const [isSharingEmitterAccess, setIsSharingEmitterAccess] = useState(false);
  const [isUpdatingEmitterAccess, setIsUpdatingEmitterAccess] = useState(false);
  const [isRevokingEmitterAccess, setIsRevokingEmitterAccess] = useState(false);

  const { data: userAccesses = EMPTY_USER_ACCESSES, isFetching } = useGetEmitterAccessorsQuery({
    emitter,
  });
  const [shareEmitter] = useShareEmitterMutation();
  const [updateEmitterAccess] = useUpdateEmitterAccessMutation();
  const [revokeEmitterAccess] = useRevokeEmitterAccessMutation();

  const handleClickInviteNewUsers = useCallback(() => {
    setIsShareEmitterAccessModalVisible(true);
  }, []);

  const handleCloseShareEmitterAccessModal = useCallback(() => {
    setIsShareEmitterAccessModalVisible(false);
  }, []);

  const handleSubmitEditEmitterAccessModal = useCallback(
    async ({ interval, properties, readAll }: SubmitData) => {
      if (!editingUserAccess) return;

      try {
        setIsUpdatingEmitterAccess(true);

        await updateEmitterAccess({
          userAccess: editingUserAccess,
          emitter,
          earliest: interval?.earliest,
          latest: interval?.latest,
          properties: readAll ? undefined : properties,
          isReadAll: readAll,
        }).unwrap();

        setIsUpdatingEmitterAccess(false);
        setEditingUserAccess(undefined);
      } catch (err) {}
    },
    [editingUserAccess, emitter, updateEmitterAccess]
  );

  const handleSelectEditUserAccess = useCallback(
    (index: number) => {
      if (isFetching) return;
      setEditingUserAccess(userAccesses[index]);
    },
    [isFetching, userAccesses]
  );

  const handleCloseEditEmitterAccessModal = useCallback(() => {
    setEditingUserAccess(undefined);
  }, []);

  const handleSelectRevokeUserAccess = useCallback(
    (index: number) => {
      if (isFetching) return;
      setRevokingUserAccess(userAccesses[index]);
    },
    [isFetching, userAccesses]
  );

  const handleSubmitShareEmitterAccessModal = useCallback(
    async ({ users, interval, properties, readAll }: SubmitData) => {
      try {
        setIsSharingEmitterAccess(true);

        await shareEmitter({
          emitter,
          userIds: users.map((user) => user.altId),
          earliest: interval?.earliest,
          latest: interval?.latest,
          properties: readAll ? undefined : properties,
          isReadAll: readAll,
        }).unwrap();

        setIsSharingEmitterAccess(false);
        setIsShareEmitterAccessModalVisible(false);
      } catch (err) {}
    },
    [emitter, shareEmitter]
  );

  const handleCancelRevokeUserAccess = useCallback(() => {
    setRevokingUserAccess(undefined);
  }, []);

  const handleSubmitRevokeUserAccess = useCallback(async () => {
    if (!revokingUserAccess) return;

    try {
      setIsRevokingEmitterAccess(true);

      await revokeEmitterAccess({ userAccess: revokingUserAccess });

      setIsRevokingEmitterAccess(false);
      setRevokingUserAccess(undefined);
    } catch (err) {}
  }, [revokeEmitterAccess, revokingUserAccess]);

  return (
    <>
      {!isShareEmitterAccessModalVisible && !editingUserAccess && (
        <Modal
          title="Access Management"
          subtitle={`Users with access to ${emitter.name}`}
          width={800}
          height={800}
          onClose={onClose}
          body={
            <div className={styles.emitterAccessorsModalBody}>
              <ControlBar onClickInviteNewUsers={handleClickInviteNewUsers} />
              <UserAccessesTable
                userAccesses={userAccesses}
                showsLoadingNextPageRow={isFetching}
                onSelectEdit={handleSelectEditUserAccess}
                onSelectRevoke={handleSelectRevokeUserAccess}
              />
            </div>
          }
        />
      )}
      {isShareEmitterAccessModalVisible && (
        <ShareEmitterAccessModal
          emitter={emitter}
          header={`Authorize access to ${emitter.name}`}
          submitButtonTitle="Submit"
          isSubmitting={isSharingEmitterAccess}
          onClose={handleCloseShareEmitterAccessModal}
          onSubmit={handleSubmitShareEmitterAccessModal}
        />
      )}
      {editingUserAccess && (
        <ShareEmitterAccessModal
          emitter={emitter}
          userAccess={editingUserAccess}
          header={`Modify ${editingUserAccess.user.firstName}'s access to ${emitter.name}`}
          submitButtonTitle="Submit"
          showsStepEmail={false}
          isSubmitting={isUpdatingEmitterAccess}
          onClose={handleCloseEditEmitterAccessModal}
          onSubmit={handleSubmitEditEmitterAccessModal}
        />
      )}
      {revokingUserAccess && (
        <Dialog
          level="danger"
          title={`Are you sure you want to revoke access for ${revokingUserAccess.user.email}?`}
          message="This action is irreversible."
          cancelButtonTitle="Cancel"
          submitButtonTitle="Revoke access"
          onSelectCancel={handleCancelRevokeUserAccess}
          onSelectSubmit={handleSubmitRevokeUserAccess}
          isSubmitting={isRevokingEmitterAccess}
        />
      )}
    </>
  );
};
