import styles from '../LayerSelector.module.scss';
import { FC, PropsWithChildren } from 'react';

type LayerSelectorMenuSectionProps = {
  title: string;
};

export const LayerSelectorMenuSection: FC<PropsWithChildren<LayerSelectorMenuSectionProps>> = ({
  title,
  children,
}) => {
  return (
    <div className={styles.menuSection}>
      <div className={styles.menuSectionHeader}>
        <div className={styles.menuSectionHeaderContent}>{title}</div>
        <hr />
      </div>
      <div className={styles.menuSectionBody}>{children}</div>
    </div>
  );
};
