import styles from './DateTimeRangePickerModal.module.scss';
import { useCallback, useMemo, useState } from 'react';
import { Modal } from 'Components/Modal';
import { DateTimeRangePickerModalBody } from './components/DateTimeRangePickerModalBody/DateTimeRangePickerModalBody';
import { DateRange } from './types';
import { Button } from 'Components/Button';
import { TimeInputValue } from 'Components/TimeInput';
import { getTimeInputValue, mergeDateRange } from './components/CalendarBody/helpers';
import { OptionalTuple } from 'Types/utility';
import moment from 'moment';
import 'moment-timezone';

const DATE_FORMAT = `D/M/YYYY`;
const ianaTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
const timezoneAbbreviation = moment().tz(ianaTimezone).format('z');

type DateTimeRangePickerModalProps = {
  dateRange?: DateRange;
  minDate?: Date;
  maxDate?: Date;
  onSubmit: (dateRange: DateRange) => void;
  onClose: () => void;
};

export const DateTimeRangePickerModal: React.FC<DateTimeRangePickerModalProps> = ({
  dateRange,
  minDate,
  maxDate,
  onSubmit,
  onClose,
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange);
  const [displayedDateRange, setDisplayedDateRange] = useState<
    OptionalTuple<DateRange> | undefined
  >(dateRange);

  const [displayedStartTime, setDisplayedStartTime] = useState<TimeInputValue | undefined>(
    dateRange && getTimeInputValue(dateRange[0])
  );
  const [displayedEndTime, setDisplayedEndTime] = useState<TimeInputValue | undefined>(
    dateRange && getTimeInputValue(dateRange[1])
  );

  const subtitle = useMemo(() => {
    if (!displayedDateRange && !displayedStartTime && !displayedEndTime) {
      return 'No selection';
    }

    const startDate = displayedDateRange?.[0];
    const endDate = displayedDateRange?.[1];

    const startDateText = startDate ? moment(startDate).format(DATE_FORMAT) : '-';
    const endDateText = endDate ? moment(endDate).format(DATE_FORMAT) : '-';
    const startTimeText = displayedStartTime
      ? `${String(displayedStartTime.hour).padStart(2, '0')}:${String(
          displayedStartTime.minute
        ).padStart(2, '0')} ${timezoneAbbreviation}`
      : '-';
    const endTimeText = displayedEndTime
      ? `${String(displayedEndTime.hour).padStart(2, '0')}:${String(
          displayedEndTime.minute
        ).padStart(2, '0')} ${timezoneAbbreviation}`
      : '-';

    return `${startDateText}, ${startTimeText} to ${endDateText}, ${endTimeText}`;
  }, [displayedDateRange, displayedEndTime, displayedStartTime]);

  const handleSelectDateRange = useCallback((dateRange: DateRange) => {
    setSelectedDateRange(dateRange);
  }, []);

  const handleChangeDisplayedDateRange = useCallback((dateRange: OptionalTuple<DateRange>) => {
    setDisplayedDateRange(dateRange);
  }, []);

  const handleChangeStartTime = useCallback((value?: TimeInputValue) => {
    setDisplayedStartTime(value);
  }, []);

  const handleChangeEndTime = useCallback((value?: TimeInputValue) => {
    setDisplayedEndTime(value);
  }, []);

  const handleClickOkButton = useCallback(() => {
    if (selectedDateRange && displayedStartTime && displayedEndTime) {
      const dateRange = mergeDateRange(selectedDateRange, displayedStartTime, displayedEndTime);
      onSubmit(dateRange);
    }
  }, [selectedDateRange, displayedStartTime, displayedEndTime, onSubmit]);

  const startTimeError: string | undefined = useMemo(() => {
    if (selectedDateRange && displayedStartTime && displayedEndTime) {
      const [earliestDate, latestDate] = mergeDateRange(
        selectedDateRange,
        displayedStartTime,
        displayedEndTime
      );

      if (earliestDate.getTime() === latestDate.getTime()) {
        return 'Start date cannot be equal to the end date';
      }

      if (latestDate < earliestDate) {
        return 'Start date cannot be after the end date';
      }

      const diffMillis = latestDate.getTime() - earliestDate.getTime();
      const diffMinutes = diffMillis / 1000 / 60;

      if (diffMinutes < 30) {
        return 'Start date must be at least 30 minutes before the end date';
      }
    }

    return undefined;
  }, [displayedEndTime, displayedStartTime, selectedDateRange]);

  const endTimeError: string | undefined = useMemo(() => {
    if (selectedDateRange && displayedStartTime && displayedEndTime) {
      const [earliestDate, latestDate] = mergeDateRange(
        selectedDateRange,
        displayedStartTime,
        displayedEndTime
      );

      if (earliestDate.getTime() === latestDate.getTime()) {
        return 'End date cannot be equal to the start date';
      }

      if (latestDate < earliestDate) {
        return 'End date cannot be before the start date';
      }

      const diffMillis = latestDate.getTime() - earliestDate.getTime();
      const diffMinutes = diffMillis / 1000 / 60;

      if (diffMinutes < 30) {
        return 'End date must be at least 30 minutes after the start date';
      }
    }

    return undefined;
  }, [displayedEndTime, displayedStartTime, selectedDateRange]);

  return (
    <Modal
      title="Select start date and end date"
      subtitle={subtitle}
      body={
        <DateTimeRangePickerModalBody
          minDate={minDate}
          maxDate={maxDate}
          selectedDateRange={selectedDateRange}
          selectedStartTime={displayedStartTime}
          selectedEndTime={displayedEndTime}
          startTimeError={startTimeError}
          endTimeError={endTimeError}
          onSelectDateRange={handleSelectDateRange}
          onChangeDisplayedDateRange={handleChangeDisplayedDateRange}
          onChangeStartTime={handleChangeStartTime}
          onChangeEndTime={handleChangeEndTime}
        />
      }
      footer={
        <div className={styles.dateTimeRangePickerModalFooter}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={
              !displayedDateRange ||
              displayedDateRange[0] === undefined ||
              displayedDateRange[1] === undefined ||
              !displayedStartTime ||
              !displayedEndTime ||
              startTimeError !== undefined ||
              endTimeError !== undefined
            }
            onClick={handleClickOkButton}
          >
            OK
          </Button>
        </div>
      }
      width={585}
      onClose={onClose}
    />
  );
};
