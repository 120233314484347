import styles from '../../../ReportsPageContent.module.scss';
import { useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { TICK_FONT_FAMILY, TICK_FONT_SIZE, TICK_FONT_WEIGHT } from '../../../constants/chart';
import { TIMESTAMP_FORMATTER, calculateTickLabelWidth, getDomain } from './helpers/chart';
import { BOTTOM_TICK_MARGIN } from './constants/chart';
import { getValueFormatter } from 'Pages/ReportsPage/components/ReportsPageContent/helpers/chart';
import { Tooltip as CustomTooltip } from './components/Tooltip';
import { ChartEntry, ColoredEmitter } from '../../../interfaces';
import { Conversion } from 'Interfaces';
import { exp } from 'array-expression';
import { DEFAULT_MAX_FRACTION_DIGITS } from 'Constants';

const CHART_SYNC_ID = '__detailSection_chart_syncId__';
const UNIT = 'kg';

type DetailSectionProps = {
  header: string;
  coloredEmitter: ColoredEmitter;
  entries: ChartEntry[];
  conversion?: Conversion;
};

export const DetailSection: React.FC<DetailSectionProps> = ({
  header,
  coloredEmitter,
  entries,
  conversion,
}) => {
  const formattedEntries: ChartEntry[] = useMemo(() => {
    if (!conversion) return entries;

    return entries.map((e) => {
      if (e.value !== null) {
        const formattedValue = exp(conversion.exp, { value: e.value }) as number;
        return { value: formattedValue, timestamp: e.timestamp };
      }
      return e;
    });
  }, [conversion, entries]);

  const paddingLeft = useMemo(() => {
    const domain = getDomain(formattedEntries);

    if (!domain) return 0;

    return calculateTickLabelWidth({
      domain,
      maximumFractionDigits: conversion?.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
      unit: conversion?.unit ?? UNIT,
    });
  }, [conversion?.maxFractionDigits, conversion?.unit, formattedEntries]);

  const valueFormatter = useMemo(() => {
    return getValueFormatter({
      unit: conversion?.unit ?? UNIT,
      maximumFractionDigits: conversion?.maxFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS,
    });
  }, [conversion?.maxFractionDigits, conversion?.unit]);

  return (
    <section className={styles.chartSection}>
      <div className={styles.header}>
        <h3 className={styles.title}>{header}</h3>
        <div className={styles.legendContainer}>
          <span className={styles.legend}>
            <span className={styles.dot} style={{ backgroundColor: coloredEmitter.color }} />
            {coloredEmitter.emitter.name}
          </span>
        </div>
      </div>
      <div className={styles.body} style={{ height: `${329 / 16}rem` }}>
        {!formattedEntries.length ? (
          <div className={styles.bodyPlaceholder}>No data for the selected period</div>
        ) : (
          <ResponsiveContainer>
            <LineChart
              data={formattedEntries}
              syncId={CHART_SYNC_ID}
              margin={{ top: 15, bottom: 4 }}
            >
              <CartesianGrid stroke="#d3d9e7" vertical={false} />
              <YAxis
                axisLine={{ stroke: '#d3d9e7' }}
                tickLine={{ stroke: '#d3d9e7' }}
                tick={{
                  fontFamily: TICK_FONT_FAMILY,
                  fontSize: TICK_FONT_SIZE,
                  fontWeight: TICK_FONT_WEIGHT,
                  fill: '#000',
                }}
                domain={['dataMin', 'dataMax']}
                width={paddingLeft}
                tickFormatter={valueFormatter as any}
              />
              <XAxis
                dataKey="timestamp"
                axisLine={{ stroke: '#d3d9e7' }}
                tickLine={{ stroke: '#d3d9e7' }}
                type="number"
                domain={['dataMin', 'dataMax']}
                tick={{
                  fontFamily: TICK_FONT_FAMILY,
                  fontSize: TICK_FONT_SIZE,
                  fontWeight: TICK_FONT_WEIGHT,
                  fill: '#000',
                }}
                tickFormatter={TIMESTAMP_FORMATTER}
                tickMargin={BOTTOM_TICK_MARGIN}
              />
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                content={
                  <CustomTooltip
                    header={coloredEmitter.emitter.name}
                    formatter={valueFormatter as any}
                  />
                }
                isAnimationActive={false}
              />
              <Line stroke={coloredEmitter.color} dot={false} strokeWidth={2} dataKey="value" />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </section>
  );
};
