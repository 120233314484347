import { useEffect, useState, useCallback, useMemo } from 'react';
import { generateStaticInterval, getAbsoluteInterval } from 'Helpers/interval';
import { useControlBarManager } from 'Pages/DashboardPage/components/ControlBar/hooks';
import { Interval } from 'Constants';

export const useSelectedInterval = () => {
  const controlBarManager = useControlBarManager();

  const [interval, setInterval] = useState(controlBarManager.getInterval());

  useEffect(() => {
    const intervalObserver = (interval: Interval) => {
      setInterval(interval);
    };

    controlBarManager.observeInterval(intervalObserver);

    return () => {
      controlBarManager.unobserveInterval(intervalObserver);
    };
  }, [controlBarManager]);

  /**
   * By using flag here, we can avoid race condition
   * This technique was also used in RTK.
   * RTK no longer use this technique given React
   * released a more modern API `useSyncExternalStore`
   * in React 18.
   */
  const [cycleFlag, setCycleFlag] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const staticInterval = useMemo(() => generateStaticInterval(interval), [interval, cycleFlag]);
  const absoluteInterval = useMemo(() => getAbsoluteInterval(staticInterval), [staticInterval]);

  const refresh = useCallback(() => {
    setCycleFlag((prevCycleFlag) => !prevCycleFlag);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, [interval]);

  useEffect(() => {
    const callback = () => {
      refresh();
    };

    window.addEventListener('online', callback);

    return () => window.removeEventListener('online', callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    absoluteInterval,
    staticInterval,
    interval,
    refresh,
  };
};
