import { Property, Sensor } from 'Models';
import {
  Aggregation,
  GetSensorsRecordsQueryArgument,
  GetVariableSensorsRecordsQueryArgument,
  VariableProperty,
} from './sensor.api';

type SensorPayload = {
  id: Sensor['id'];
  sensorGroupId: string;
  properties: {
    alias: string;
    name: Property['name']['name'];
    aggregation?: Aggregation;
  }[];
};

export const getSensorRecordsRequestBody = (arg: GetSensorsRecordsQueryArgument) => {
  const earliest = arg.earliest;
  const latest = arg.latest;
  const sample = arg.sample;
  const densify = arg.densify;

  const body: Record<string, unknown> = {
    earliest,
    latest,
  };

  if (sample) {
    body.sampleUnit = sample.unit;
    body.sampleRate = sample.rate;
  }

  if (typeof densify === 'boolean') {
    body.densify = densify;
  }

  if (isVariableSensorRecordsArgument(arg)) {
    body.sensors = getVariableSensorPayloads(arg.properties);
  } else {
    if (arg.aggregation) {
      body.aggregation = arg.aggregation;
    }

    body.sensors = getInvariableSensorPayloads(arg.properties);
  }

  return body;
};

export const getInvariableSensorPayloads = (properties: Property[]): SensorPayload[] => {
  const sensorPayloads: SensorPayload[] = [];

  const uniqueProperties = getUniqueProperties(properties);

  for (const property of uniqueProperties) {
    const prop = { name: property.name.name, alias: property.name.hash };

    const existingSensorPayload = sensorPayloads.find((p) => p.id === property.sensorId);

    if (existingSensorPayload) {
      existingSensorPayload.properties.push(prop);
    } else {
      sensorPayloads.push({
        id: property.sensorId,
        properties: [prop],
        sensorGroupId: property.sensorGroupId,
      });
    }
  }

  return sensorPayloads;
};

export const getVariableSensorPayloads = (variableProperties: VariableProperty[]) => {
  const sensorPayloads: SensorPayload[] = [];

  const uniqueVariableProperties = getUniqueVariableProperties(variableProperties);

  for (const variableProperty of uniqueVariableProperties) {
    for (const variant of variableProperty.variants) {
      const prop = {
        sensorGroupId: variableProperty.property.sensorGroupId,
        alias: variant.alias,
        name: variableProperty.property.name.name,
        aggregation: variant.aggregation,
      };

      const existingSensorPayload = sensorPayloads.find(
        (p) => p.id === variableProperty.property.sensorId
      );

      if (existingSensorPayload) {
        existingSensorPayload.properties.push(prop);
      } else {
        sensorPayloads.push({
          id: variableProperty.property.sensorId,
          properties: [prop],
          sensorGroupId: variableProperty.property.sensorGroupId,
        });
      }
    }
  }

  return sensorPayloads;
};

export const getUniqueProperties = (properties: Property[]) => {
  const seenHashes: Record<string, boolean> = {};

  return properties.filter((p) => {
    let hash = p.hash;
    return seenHashes.hasOwnProperty(hash) ? false : (seenHashes[hash] = true);
  });
};

export const getUniqueVariableProperties = (variableProperties: VariableProperty[]) => {
  const seenHashes: Record<string, boolean> = {};

  return variableProperties.filter((v) => {
    let hash = v.property.hash;
    return seenHashes.hasOwnProperty(hash) ? false : (seenHashes[hash] = true);
  });
};

export const isVariableSensorRecordsArgument = (
  arg: GetSensorsRecordsQueryArgument
): arg is GetVariableSensorsRecordsQueryArgument => {
  return (arg.properties[0] as VariableProperty)?.variants !== undefined;
};
