import { Alert } from 'Models';
import { AlertLevel } from 'Models/alert';
import {
  Alert as AlertRecord,
  AlertExplanation,
  AlertExplanation as AlertExplanationRecord,
} from '../interfaces';

export const transformIntoAlert = (record: AlertRecord): Alert => ({
  id: record._id,
  sensorGroupName: record.sensorGroupName,
  message: record.message,
  isRead: record.isRead,
  explanations: record.explain.map(transformIntoAlertExplanation),
  level: transformIntoAlertLevel(record.priority),
  ruleId: record.ruleId,
  lastCoordinate: record.lastGpsPosition && {
    latitude: record.lastGpsPosition.coordinates[1],
    longitude: record.lastGpsPosition.coordinates[0],
  },
  updatedAt: record.updatedAt,
  resolvedAt: record.resolvedAt,
  createdAt: record.createdAt,
});

const transformIntoAlertExplanation = (record: AlertExplanationRecord): AlertExplanation => ({
  message: record.message,
  source: record.source,
  occuredAt: record.occuredAt,
  occursAt: record.occuredAt,
  forcastedAt: record.forcastedAt,
});

const transformIntoAlertLevel = (priority: number): AlertLevel => {
  switch (priority) {
    case 1:
      return AlertLevel.Low;
    case 2:
      return AlertLevel.Medium;
    case 3:
      return AlertLevel.High;
    default:
      return AlertLevel.Unknown;
  }
};
