import moment from 'moment';
import { ChartDomain, ChartEntry } from '../../../../interfaces';
import {
  TICK_FONT_FAMILY,
  TICK_FONT_SIZE,
  TICK_FONT_WEIGHT,
} from 'Pages/ReportsPage/components/ReportsPageContent/constants/chart';
import { getValueFormatter } from 'Pages/ReportsPage/components/ReportsPageContent/helpers/chart';

export const TIMESTAMP_FORMATTER = (timestamp: ChartEntry['timestamp']) => {
  return moment(timestamp).format('MMM D');
};

export const getDomain = (entries: ChartEntry[]): ChartDomain | undefined => {
  if (!entries.length) return undefined;

  const filteredEntries = entries.filter(
    (e): e is { value: number; timestamp: ChartEntry['timestamp'] } => typeof e.value === 'number'
  );

  const values = filteredEntries.map((e) => e.value);

  return [Math.min(...values), Math.max(...values)];
};

export const calculateTickLabelWidth = ({
  domain,
  maximumFractionDigits,
  unit,
}: {
  domain: ChartDomain;
  maximumFractionDigits: number;
  unit?: string;
}) => {
  const maxValue = domain[1];

  const format = getValueFormatter({ unit, maximumFractionDigits });

  const text = format(maxValue);

  const context = document.createElement('canvas').getContext('2d')!;
  context.font = `${TICK_FONT_WEIGHT} ${TICK_FONT_SIZE} ${TICK_FONT_FAMILY}`;

  const TICK_LINE_WIDTH = 8;

  return context.measureText(text).width + TICK_LINE_WIDTH;
};
