import { Permission } from 'Models/user';
import { Permission as PermissionRecord } from '../interfaces/permission';

export const transformIntoPermission = (record: PermissionRecord): Permission => ({
  id: record._id,
  altId: record.userId,
  createdAt: record.createdAt,
  createdBy: record.createdBy,
  name: record.name,
  namespace: record.namespace,
  definition: {
    namespace: record.definition.namespace,
  },
});
