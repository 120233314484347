import { forwardRef } from 'react';
import styles from '../../TimelineBar.module.scss';
import clsx from 'clsx';

export type TimelineBarTickAnchorPoint = 'left' | 'center' | 'right';

export type TimelineBarTickProps = {
  label?: string;
  labelAnchorPoint?: TimelineBarTickAnchorPoint;
  position: number;
};

export const TimelineBarTick = forwardRef<HTMLDivElement, TimelineBarTickProps>(
  ({ label, labelAnchorPoint = 'center', position }, ref) => {
    return (
      <div ref={ref} className={styles.timelineBarTick} style={{ left: `${position * 100}%` }}>
        {label && (
          <span
            className={clsx(styles.timelineBarTickLabel, {
              [styles.left]: labelAnchorPoint === 'left',
              [styles.center]: labelAnchorPoint === 'center',
              [styles.right]: labelAnchorPoint === 'right',
            })}
          >
            {label}
          </span>
        )}
      </div>
    );
  }
);
