import { getUniqueProperties } from 'Helpers/sensors';
import { Property, Sensor } from 'Models';

export interface SensorPayload {
  id: Sensor['id'];
  sensorGroupId: string;
  properties: {
    alias: string;
    name: Property['name']['name'];
  }[];
}

export function generateSensorPayloads(properties: Property[]): SensorPayload[] {
  const sensorPayloads: SensorPayload[] = [];

  const uniqueProperties = getUniqueProperties(properties);

  for (const property of uniqueProperties) {
    const prop = { name: property.name.name, alias: property.name.hash };

    const existingSensorPayload = sensorPayloads.find((p) => p.id === property.sensorId);

    if (existingSensorPayload) {
      existingSensorPayload.properties.push(prop);
    } else {
      sensorPayloads.push({
        id: property.sensorId,
        properties: [prop],
        sensorGroupId: property.sensorGroupId,
      });
    }
  }

  return sensorPayloads;
}
