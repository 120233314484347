import clsx from 'clsx';
import styles from './StepBar.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

type StepBarProps = {
  titles: string[];
  activeIndex: number;
};

export const StepBar: React.FC<StepBarProps> = ({ titles, activeIndex }) => {
  return (
    <AnimatePresence>
      <div className={styles.stepBar}>
        {titles.map((title, index) => (
          <motion.div
            key={index}
            className={clsx(styles.stepBarItem, index === activeIndex && styles.active)}
            layout="size"
            layoutRoot={true}
          >
            <span className={styles.number}>{index + 1}.</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className={styles.title}>{title}</span>
          </motion.div>
        ))}
      </div>
    </AnimatePresence>
  );
};
