import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { getAccessTokenSilently } from 'Services/auth0';
import { Context, IContext } from './Context';
import { Observer } from './Observer';

type ProviderProps = {
  url: string;
  path: string;
};

export const Provider: FC<PropsWithChildren<ProviderProps>> = ({ url, path, children }) => {
  const [token, setToken] = useState<string>();
  const [context, setContext] = useState<IContext>();
  const [initialContext] = useState<IContext>({
    observer: new Observer(url, path, ''),
  });

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    })();
  }, []);

  useEffect(() => {
    if (!url || !path || !token) return;

    let currentContext = context;

    if (!currentContext) {
      currentContext = {
        observer: new Observer(url, path, token),
      };

      setContext(currentContext);
    }

    currentContext!.observer.updateToken(token);
  }, [context, url, path, token]);

  return <Context.Provider value={context ?? initialContext}>{children}</Context.Provider>;
};
