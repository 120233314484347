import { urlConfiguration } from 'Config';
import { Emitter } from 'Models';
import { FetchFunction } from 'Networking/http/interfaces';
import { getAccessTokenSilently } from 'Services/auth0';
import axios from 'axios';
import { transformIntoDock, transformIntoVessel } from './transformers';

const fetchEmitters: FetchFunction<void, Emitter[]> = async () => {
  const token = await getAccessTokenSilently();

  const { data: records } = await axios.get<Record<string, any>[]>(
    `${urlConfiguration.api}/sensorGroups`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const emitters: Emitter[] = [];

  for (const record of records) {
    if (record.groupType === 'vessel') {
      emitters.push(transformIntoVessel(record));
    } else if (record.groupType === 'dock') {
      emitters.push(transformIntoDock(record));
    }
  }

  return emitters;
};

fetchEmitters.generateQueryKey = () => ['emitters'];

export { fetchEmitters };
