import { motion } from 'framer-motion';

type RollGraphViewProps = {
  roll?: number;
  animate: boolean;
};

export const RollGraphView: React.FC<RollGraphViewProps> = ({ roll, animate }) => {
  return typeof roll === 'number' ? (
    <svg
      width="158"
      height="158"
      viewBox="0 0 158 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M46.1163 51.2292C41.9007 54.4528 38.3671 55.2588 37.1273 55.2588C36.5536 60.2781 36.0895 69.7066 37.2682 77.5762C37.7879 81.0461 38.627 84.213 39.917 86.5652C45.1864 96.1741 51.0757 100.514 61.3045 104.853C69.4876 108.325 76.6995 111.466 79.2825 112.602H79.3103C81.8933 111.466 89.1052 108.325 97.2883 104.853C107.517 100.514 113.406 96.1741 118.676 86.5652C119.966 84.213 120.805 81.0461 121.325 77.5762C122.503 69.7066 122.039 60.2781 121.466 55.2588C120.226 55.2588 116.692 54.4528 112.477 51.2292C107.207 47.1997 100.388 39.7605 79.3103 39.7605H79.2825C58.2049 39.7605 51.3857 47.1997 46.1163 51.2292Z"
        stroke="#ABB3C7"
        strokeLinecap="round"
        strokeLinejoin="round"
        animate={{ rotate: roll }}
        transition={{ duration: animate ? 2.0 : 0, ease: 'easeInOut' }}
      />
      <mask id="mask0_415_7141" maskUnits="userSpaceOnUse" x="0" y="75" width="158" height="83">
        <rect y="75.05" width="158" height="82.9499" fill="white" />
      </mask>
      <g mask="url(#mask0_415_7141)">
        <motion.path
          d="M46.1163 51.2292C41.9007 54.4528 38.3671 55.2588 37.1273 55.2588C36.5536 60.2781 36.0895 69.7066 37.2682 77.5762C37.7879 81.0461 38.627 84.213 39.917 86.5652C45.1864 96.1741 51.0757 100.514 61.3045 104.853C69.4876 108.325 76.6995 111.466 79.2825 112.602H79.3103C81.8933 111.466 89.1052 108.325 97.2883 104.853C107.517 100.514 113.406 96.1741 118.676 86.5652C119.966 84.213 120.805 81.0461 121.325 77.5762C122.503 69.7066 122.039 60.2781 121.466 55.2588C120.226 55.2588 116.692 54.4528 112.477 51.2292C107.207 47.1997 100.388 39.7605 79.3103 39.7605H79.2825C58.2049 39.7605 51.3857 47.1997 46.1163 51.2292Z"
          fill="#B3E5FF"
          stroke="#0093DD"
          strokeLinecap="round"
          strokeLinejoin="round"
          animate={{ rotate: roll }}
          transition={{ duration: animate ? 2.0 : 0, ease: 'easeInOut' }}
        />
      </g>
      <line x1="79.5" y1="2.18558e-08" x2="79.5" y2="158" stroke="black" />
      <line y1="74.55" x2="158" y2="74.55" stroke="black" />
    </svg>
  ) : (
    <svg
      width="158"
      height="158"
      viewBox="0 0 158 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M46.1163 51.2292C41.9007 54.4528 38.3671 55.2588 37.1273 55.2588C36.5536 60.2781 36.0895 69.7066 37.2682 77.5762C37.7879 81.0461 38.627 84.213 39.917 86.5652C45.1864 96.1741 51.0757 100.514 61.3045 104.853C69.4876 108.325 76.6995 111.466 79.2825 112.602H79.3103C81.8933 111.466 89.1052 108.325 97.2883 104.853C107.517 100.514 113.406 96.1741 118.676 86.5652C119.966 84.213 120.805 81.0461 121.325 77.5762C122.503 69.7066 122.039 60.2781 121.466 55.2588C120.226 55.2588 116.692 54.4528 112.477 51.2292C107.207 47.1997 100.388 39.7605 79.3103 39.7605H79.2825C58.2049 39.7605 51.3857 47.1997 46.1163 51.2292Z"
          stroke="#ABB3C7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.3">
        <line x1="79.5" y1="2.18558e-08" x2="79.5" y2="158" stroke="black" strokeDasharray="2 2" />
        <line y1="74.55" x2="158" y2="74.55" stroke="black" strokeDasharray="2 2" />
      </g>
    </svg>
  );
};
