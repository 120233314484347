import { StaticInterval } from 'Constants';
import { UserAccess } from 'Models';

export const getIntervalFromUserAccess = ({
  restrictions,
}: UserAccess): StaticInterval | undefined => {
  if (!restrictions || !restrictions.length) return undefined;

  const { earliest, latest } = restrictions[0];

  if (
    typeof earliest === 'string' &&
    (typeof latest === 'string' || typeof latest === 'undefined')
  ) {
    return { earliest, latest };
  }

  return undefined;
};
